import BaseComponent from "@/components/base-component";
import { Component, Watch, Prop } from "vue-property-decorator";
import { PropertyDetail } from '@/models';


@Component({
})
export default class AssumptionsComponent extends BaseComponent {
    
    @Prop()
    propertyDetail!: PropertyDetail;
    
}