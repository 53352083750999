import { Component, Prop, Watch } from "vue-property-decorator";
import BaseComponent from "@/components/base-component";
import { User, Language, Customer, Database } from '@/models';
import dataService from '@/services/data-service';
import { mapGetters } from 'vuex';
import { AppRight } from '@/models/AppRight';
import { UserRight } from '@/models/UserRight';
import { GroupRight } from "@/models/GroupRight";
import { AppRightEnum } from "@/constants/fia-constants";

@Component({
    computed: mapGetters({
        languageList: "getLanguages",
        databaseList: "getDatabases",
        currentUser: "getUser"
    })
})
export default class EditUserComponent extends BaseComponent {
    lanuageList!: Language[];
    databaseList!: Database[];
    currentUser!: User;

    @Prop()
    customer!: Customer;

    @Prop()
    editedUser!: User;

    appRights: AppRight[] = [];
    activeDatabases: Database[] = [];
    photoImage: string = "";
    uploadData: any = {};
    headers: object = {};
    table: any[] = [];
    saving: boolean = false;
    userNameLabel = "";
    
    @Watch("editedUser", { immediate: true })
    async editedUserWatcher() {
        if (this.isNew()) {
            this.photoImage = "";
        } else {
            this.photoImage = await this.loadImage(this.editedUser.id, "foto");
        }
    }

    async mounted(): Promise<void> {
        this.appRights = (await dataService.getAppRights().then(x => x.data)).sort((a,b) => a.sortOrder - b.sortOrder);
        this.headers = await dataService.getAuthHeaders();
        this.activeDatabases = [...new Set(this.databaseList)].sort((a,b) => a.name.localeCompare(b.name));
        this.userNameLabel = `${this.translate('Username')} (${this.translate('Email').toLowerCase()})`;
    }

    async loadImage(userId: number, suffix: string): Promise<string> {
        try {
            let imgData = await dataService.getUserImage(userId, 280).then(x => x.data);
            let result = imgData === "" ? "" : "data:image/jpg;base64," + imgData;
            return result;
        } catch (error) {
            return "";
        }
    }

    url(): string {
        return process.env.VUE_APP_FIAAPI_URL + "accounts/importimage"
    }

    setUploadData(file: any, type: string): Promise<any> {
        return new Promise(resolve => {
            this.uploadData = {
                userId: this.editedUser!.id,
                type: type
            };
            this.$nextTick(() => {
                resolve(file)
            });
        });
    }

    async onSuccessPhoto() {
        (<HTMLFormElement>this.$refs.uploadPhoto).clearFiles();
        // this.showSuccessMessage();
        this.photoImage = await this.loadImage(this.editedUser!.id, "foto");
    }

    deleteImage() {
        this.confirm("ConfirmDeleteImage").then(async () => {
            try {
                await dataService.deleteUserImage(this.editedUser!.id)
                    .then(() => this.photoImage = "");
            } catch (error) {
                return Promise.reject(new Error(""));
            }
        }).catch(() => {});
    }

    onError(e: any) {
        this.$notify.error({
            title: this.translate("Error"),
            message: this.translate("ImageUploadError")
        });
    }

    // Returns the GroupRight object for the specified permission and database
    getGroupRight(appId: number, dbId: number): GroupRight | undefined {
        return this.editedUser!.userGroup.groupRights.find(x => x.appRightId === appId && x.databaseId === dbId)
    }

    // Returns the UserRight object for the specified permission and database
    getUserRight(appId: number, dbId: number): UserRight | undefined {
        return this.editedUser!.userRights.find(x => x.groupRight.appRightId === appId && x.groupRight.databaseId === dbId)
    }

    // Returns true if the current user is granted rights on the specified permission and database
    isGranted(appId: number, dbId: number) {
        const userRight = this.getUserRight(appId, dbId);
        if (userRight) {
            return userRight.granted;
        }
        const groupRight = this.getGroupRight(appId, dbId);
        return groupRight ? groupRight.granted : false;
    }

    // Toggles granted flag on the specified permission
    toggleGranted(appId: number, dbId: number) {
        const granted = this.isGranted(appId, dbId);
        const userRight = this.getUserRight(appId, dbId);
        const groupRight = this.getGroupRight(appId, dbId);

        if (userRight) {
            if (userRight.granted === groupRight!.granted) {
                const ix = this.editedUser!.userRights.indexOf(userRight);
                this.editedUser!.userRights.splice(ix, 1);
            } else {
                userRight.granted = !granted;
            }
        } else if (groupRight) {
            const ur: UserRight = { id: 0, groupRight: groupRight, groupRightId: groupRight.id, userId: this.editedUser!.id, granted: !granted };
            this.editedUser!.userRights.push(ur);
        }
    }

    // Gets the translated display name of the permission
    getPermissionDisplayName(appId: any): string {
        const key = "AppRight_" + appId;
        return this.translate(key);
    }

    getPermissionTextClass(appRight: AppRight): string {
        if (appRight.isTopLevel) return "perm-top-level"

        let level = -1;
        let ar: AppRight | undefined = appRight;
        while(ar) {
            ar = this.appRights.find(x => ar && x.id === ar.parentId);
            level++;
        }

        return `perm-sub-${level}`;
    }

    // Returns the permission icon css class for the specified permission and database
    getPermissionIconClass(appId: AppRightEnum, dbId: number): string[] {
        const classes: string[] = ["perm-check-icon"];
        const userRight = this.getUserRight(appId, dbId);
        
        if (userRight) {
            if (userRight.granted) {
                classes.push("color-green");
                classes.push("el-icon-success");
            } else {
                classes.push("color-red");
                classes.push("el-icon-error");
            }
        } else {
            const groupRight = this.getGroupRight(appId, dbId);
            if (groupRight) {
                if (groupRight.granted) {
                    classes.push("color-green");
                    classes.push("el-icon-circle-check");
                } else {
                    classes.push("color-red");
                    classes.push("el-icon-circle-close");
                }
            } else {
                classes.push("el-icon-minus");
            }
        }
       
        return classes;
    }

    // Saves the edited user
    async save() {
        if (this.editedUser) {
            this.saving = true;
            await dataService.saveUser(this.editedUser)
                .then(x => this.$emit("saved", x.data, this.isNew()))
                .finally(() => this.saving = false);
        }
    }

    // Cancel any changes to the edited user
    async cancel(): Promise<void> {
        this.$emit("canceled", this.isNew());
    }

    // Handles user group changed event.
    userGroupChanged(value: number): void {
        if (value != null) {
            this.editedUser!.userRights = [];
            const group = this.customer.userGroups.find(x => x.id === value);
            if (group) {
                this.editedUser.userGroup = group;
            }
        }
    }

    isSysAdmin(): boolean {
        return this.editedUser != null && this.editedUser.userGroup.isSysAdmin;
    }

    isNew(): boolean {
        return this.editedUser!.id === 0; 
    }

    canDeleteImage(): boolean {
        return this.photoImage !== "";
    }
}
