import vue from "vue";
import { Contract, PaymentStream, IndexIncrementStream, AdvancedPercentageIncrementStream, VariableAmountIncrementStream, BasicPercentageStream, VariablePercentage, ShareOfPaymentStream, PercentageIncrementStream, CostIncome, PropertyVersion, PropertyTax, Tax, Property, Recovery, MarketType, MarketRent, VersionPortfolio, Development, Portfolio, Loan, Valuation, ValuationModule } from "@/models";
import { PaymentStreamType, AmortizationTypeEnum, InterestTypeEnum } from "@/constants/fia-constants";
import utils from "@/helpers/utils";
import moment from "moment";
import { PropertyVersionSlim } from '@/models/interfaces/PropertyVersionSlim';
import { ValidationError } from '@/models/interfaces/ValidationError';
import { ValidationRule } from '@/models/interfaces/ValidationRule';
import { CalculationState } from '@/models/interfaces/CalculationState';
import { isNumeric } from '@/helpers/numbers';
import InlineEdit from '@/helpers/inline-edit';
import { BuildingRight } from '@/models/BuildingRight';

export enum Cmp {
    LT,
    LEQ,
    EQ,
    GEQ,
    GT,
    NEQ
}

export default new class ValidationService {

    translate(key: string): string {
        return vue.i18n.translate(key, {}) || "";
    }

    warning(errors: ValidationError[], key: string, ref: string | number, message: string = "", scope?: string) {
        errors.push(<ValidationError>{ key: key, error: this.translate("ValidationWarning") + ": " + message, refId: ref, rule: ValidationRule.Warning, scope: scope })
    }

    required(errors: ValidationError[], value: any, key: string, ref: string | number, nullValue?: string | number, scope?: string) {
        if (value == null || value === "" || value.toString().length == 0 || (nullValue !== undefined && value === nullValue)) {
            errors.push(<ValidationError>{ key: key, error: this.translate("ValidationRequired"), refId: ref, rule: ValidationRule.Required, scope: scope })
        }
    }

    numeric(errors: ValidationError[], value: any, key: string, ref: string | number, scope?: string) {
        if (value && !isNumeric(value)) {
            errors.push(<ValidationError>{ key: key, error: this.translate("ValidationNumeric"), refId: ref, rule: ValidationRule.Numeric, scope: scope })
        }
    }

    numericArray(errors: ValidationError[], value: any, key: string, ref: string | number, scope?: string) {
        if (value && !isNumeric(value.replace(/,/g, ''))) {
            errors.push(<ValidationError>{ key: key, error: this.translate("ValidationNumeric"), refId: ref, rule: ValidationRule.Numeric, scope: scope })
        }
    }

    range(errors: ValidationError[], value: any, key: string, ref: string | number, fromVal: number, toVal: number, percentage: boolean, scope?: string) {
        if (value && (!isNumeric(value) || (value < fromVal || value > toVal))) {
            let err = this.translate("ValidationRange");
            err = err.replace('{0}', (fromVal * (percentage ? 100 : 0)).toString());
            err = err.replace('{1}', (toVal * (percentage ? 100 : 0)).toString());
            errors.push(<ValidationError>{ key: key, error: err, refId: ref, rule: ValidationRule.Range, scope: scope })
        }
    }

    compare(errors: ValidationError[], value1: any, value2: any, operator: Cmp, key: string, ref: string | number, errorKey: string, scope?: string) {
        let result: boolean = false;

        switch (operator) {
            case Cmp.LT:
                result = value1 < value2;
                break;
            case Cmp.LEQ:
                result = value1 <= value2;
                break;
            case Cmp.EQ:
                result = value1 === value2;
                break;
            case Cmp.GEQ:
                result = value1 >= value2;
                break;
            case Cmp.GT:
                result = value1 > value2;
            case Cmp.NEQ:
                result = value1 !== value2;
                break;
        }

        if (!result) {
            errors.push(<ValidationError>{ key: key, refId: ref, error: this.translate(errorKey), rule: ValidationRule.Compare, scope: scope })
        }
    }

    unique(errors: ValidationError[], values: any[], key: string, ref: string | number, scope?: string) {
        let unique = [...new Set(values)];
        if (unique.length != values.length)
            errors.push(<ValidationError> { key: key, error: this.translate('NotUniqueValues'), refId: ref, rule: ValidationRule.Unique, scope: scope });
    }

    validateProperty(property: Property | null, currentErrors: ValidationError[]) {
        let newErrors: ValidationError[] = [];

        if (!property) {
            currentErrors.splice(0, currentErrors.length);
            return;
        }

        // Property
        this.required(newErrors, property.propertyName, "property.propertyName", "");
        this.required(newErrors, property.propertyIdentifier, "property.propertyIdentifier", "");

        if (property.propertyDetail) {
            // General
            let general = property.propertyDetail;
            this.numeric(newErrors, general.gross, 'general.gross', general.id);

            // Shares
            for (let share of property.propertyDetail.propertyShares) {
                this.required(newErrors, share.yearlyRevenue, "shares.share.yearlyRevenue", share.id);
                this.numeric(newErrors, share.yearlyRevenue, "shares.share.yearlyRevenue", share.id);

                for (let joint of share.jointOwnershipProperties) {
                    this.required(newErrors, joint.propertyName, "shares.joint.propertyName", share.id + '|' + joint.id);
                }
            }

            // Rights
            for (let right of property.propertyDetail.propertyRights) {
                this.required(newErrors, right.purpose, "rights.right.purpose", right.id);

                for (let involved of right.involvedProperties) {
                    this.required(newErrors, involved.propertyName, "rights.involved.propertyName", right.id + '|' + involved.id);
                }
            }

            // Ground leases
            for (let groundLease of property.propertyDetail.groundLeases) {
                this.numeric(newErrors, groundLease.yearlyCost, "groundLeases.yearlyCost", groundLease.id);
            }

            // Construction plan
            for (let plan of property.propertyDetail.propertyConstructionPlans) {
                this.required(newErrors, plan.date, "propertyConstructionPlans.date", plan.id);
            }

            // Taxes
            for (let tax of property.propertyDetail.taxInformations) {
                this.numeric(newErrors, tax.baseTax, 'taxes.tax.baseTax', tax.id);
            }
        }

        this.syncErrors(newErrors, currentErrors);
    }

    validateBuildRights(buildingRights: BuildingRight[], currentErrors: ValidationError[]) {
        let newErrors: ValidationError[] = [];
        for (let build of buildingRights) {
                this.required(newErrors, build.purpose, 'builds.build.purpose', build.id);
                this.required(newErrors, build.area, 'builds.build.area', build.id);
                this.numeric(newErrors, build.area, 'builds.build.area', build.id);
                this.compare(newErrors, 0, build.area, Cmp.LEQ, 'builds.build.area', build.id, 'ValidationPositiveZero');
                this.required(newErrors, build.valueM2, 'builds.build.valueM2', build.id);
                this.compare(newErrors, 0, build.valueM2, Cmp.LEQ, 'builds.build.valueM2', build.id, 'ValidationPositiveZero');
                this.numeric(newErrors, build.valueM2, 'builds.build.valueM2', build.id);
        }
        this.syncErrors(newErrors, currentErrors);
    }

    validatePropertyVersion(v: PropertyVersionSlim | null, currentErrors: ValidationError[]) {
        let newErrors: ValidationError[] = [];

        if (!v) {
            currentErrors.splice(0, currentErrors.length);
            return;
        }

        this.required(newErrors, v.versionIdentifier, "versionIdentifier", "");

        if (v.calculationStart > v.calculationEnd) {
            newErrors.push(<ValidationError>{ key: "calculationPeriod", refId: "", error: this.translate("ValidationStartDateLessThanEndDate"), rule: ValidationRule.Complex })
        }

        this.syncErrors(newErrors, currentErrors);
    }

    validatePropertyVersionDetailsMarket(marketRent: MarketRent[] | null, currentErrors: ValidationError[]) {
        let newErrors: ValidationError[] = [];

        if (!marketRent) {
            currentErrors.splice(0, currentErrors.length);
            return;
        }

        for (let market of marketRent) {
            this.required(newErrors, market.rentLevel, "rentLevel", market.id);
            this.numeric(newErrors, market.rentLevel, "rentLevel", market.id);
        }

        this.syncErrors(newErrors, currentErrors);
    }

    validatePropertyVersionDetailsDevelopment(developments: Development[] | null, currentErrors: ValidationError[]) {
        let newErrors: ValidationError[] = [];

        if (!developments) {
            currentErrors.splice(0, currentErrors.length);
            return
        }

        for (let dev of developments) {
            if (dev.calculationType) this.numeric(newErrors, dev.margin, "development.margin", dev.id);
            else {
                if (dev.fixedAdjustments != null) {
                    for (let fixed of dev.fixedAdjustments) {
                        this.required(newErrors, fixed.fixedAdjustment, "development.fixedAdjustments.fixedAdjustment", fixed.id);
                        this.numeric(newErrors, fixed.fixedAdjustment, "development.fixedAdjustments.fixedAdjustment", fixed.id);
                    }
                }
            }
        }

        this.syncErrors(newErrors, currentErrors);
    }

    validatePropertyVersionDetailsPortfolio(portfolio: VersionPortfolio[] | null, currentErrors: ValidationError[]) {
        let newErrors: ValidationError[] = [];

        if (!portfolio) {
            currentErrors.splice(0, currentErrors.length);
            return;
        }

        for (let p of portfolio) {
            this.required(newErrors, p.portfolioId, "versionPortfolios.portfolio.portfolioId", p.id, 0);
            this.required(newErrors, p.share, "versionPortfolios.portfolio.share", p.id);
            this.numeric(newErrors, p.share, "versionPortfolios.portfolio.share", p.id);
        }

        this.syncErrors(newErrors, currentErrors);
    }

    validatePropertyVersionDetailsClassification() {
        let newErrors: ValidationError[];

    }

    validateCalculationState(state: CalculationState | null, currentErrors: ValidationError[]) {
        let newErrors: ValidationError[] = [];

        if (!state) {
            currentErrors.splice(0, currentErrors.length);
            return;
        };

        if (state.calculationValue) {
            this.required(newErrors, state.calculationValue.flowInterestRate, "value.flowInterestRate", "");
            this.numeric(newErrors, state.calculationValue.flowInterestRate, "value.flowInterestRate", "");
            this.required(newErrors, state.calculationValue.restValueInterestRate, "value.restValueInterestRate", "");
            this.numeric(newErrors, state.calculationValue.restValueInterestRate, "value.restValueInterestRate", "");
            this.required(newErrors, state.calculationValue.residualCorrection, "value.residualCorrection", "");
            this.numeric(newErrors, state.calculationValue.residualCorrection, "value.residualCorrection", "");
            this.required(newErrors, state.calculationValue.restValueTurnover, "value.restValueTurnover", "");
            this.numeric(newErrors, state.calculationValue.restValueTurnover, "value.restValueTurnover", "");
        }

        this.required(newErrors, state.costDeductionPercentage, "result.costDeductionPercentage", "");
        this.numeric(newErrors, state.costDeductionPercentage, "result.costDeductionPercentage", "");

        if (state.estimatedMarketValue) {
            // this.required(newErrors, state.estimatedMarketValue.marketValue, "est.marketValue", "");
            this.numeric(newErrors, state.estimatedMarketValue.marketValue, "est.marketValue", "");
            this.required(newErrors, state.estimatedMarketValue.interval, "est.interval", "");
            this.numeric(newErrors, state.estimatedMarketValue.interval, "est.interval", "");
            this.required(newErrors, state.estimatedMarketValue.bondRate, "est.bondRate", "");
            this.numeric(newErrors, state.estimatedMarketValue.bondRate, "est.bondRate", "");
        }
        if (state.rptInvestments) {
            if (state.marketValue == null || state.marketValue == 0) {
                newErrors.push(<ValidationError>{ key: "marketValue", error: this.translate("ValidationMarketValueMinZero"), refId: "marketValue", rule: ValidationRule.Numeric })
            }
        }

        this.numeric(newErrors, state.marketValue, "marketValue", "");
        this.numeric(newErrors, state.transactionCosts, "transactionCosts", "");

        this.syncErrors(newErrors, currentErrors);
    }

    validateTaxes(taxes: Tax[], propertyVersion: PropertyVersion, currentErrors: ValidationError[]) {
        let newErrors: ValidationError[] = [];

        if (!taxes) {
            currentErrors.splice(0, currentErrors.length);
            return;
        }

        for (let t of taxes) {
            // this.compare(newErrors, t.typeOfPaymentId, -1, Cmp.NEQ, "tax4", t.id, "ValidationRequired");

            if (t.taxValuationUnits.length === 0) {
                newErrors.push(<ValidationError>{ key: "tax1", refId: t.id, error: this.translate("ValidationTaxes1"), rule: ValidationRule.Complex })
            }
            else {
                for (let u of t.taxValuationUnits) {
                    let unitRef = t.id + "|" + u.id;
                    this.required(newErrors, u.valuationUnit, "valuationUnit", unitRef);
                    this.required(newErrors, u.propertyTaxTypeId, "propertyTaxTypeId", unitRef);

                    if (u.developmentTypeId == null && u.prognosisParameterId == null) {
                        newErrors.push(<ValidationError>{ key: "tax2", refId: unitRef, error: this.translate("ValidationTaxes2"), rule: ValidationRule.Complex })
                    }

                    if (u.propertyTaxes.length === 0) {
                        newErrors.push(<ValidationError>{ key: "tax3", refId: unitRef, error: this.translate("ValidationTaxes3"), rule: ValidationRule.Complex })
                    }
                    else {
                        let startRate = u.propertyTaxes.find(x => x.startDate <= propertyVersion.calculationStart);
                        if (!startRate) {
                            newErrors.push(<ValidationError>{ key: "tax4", refId: unitRef, error: this.translate("ValidationTaxes4"), rule: ValidationRule.Complex })
                        }

                        for (let r of u.propertyTaxes) {
                            let rateRef = unitRef + '|' + r.id;
                            this.required(newErrors, r.baseTax, "baseTax", rateRef);
                            this.numeric(newErrors, r.baseTax, "baseTax", rateRef);
                            this.required(newErrors, r.conversionFactor, "conversionFactor", rateRef);
                            this.numeric(newErrors, r.conversionFactor, "conversionFactor", rateRef);
                        }
                    }
                }
            }
        }

        this.syncErrors(newErrors, currentErrors);
    }

    validateContract(contract: Contract | null, currentErrors: ValidationError[], refId: any = "") {
        let newErrors: ValidationError[] = [];

        if (!contract) {
            currentErrors.splice(0, currentErrors.length);
            return;
        }

        this.required(newErrors, contract.tenant, "tenant", refId, undefined, "contract")
        this.required(newErrors, contract.propertyIdentifier, "propertyIdentifier", refId, undefined, "contract")
        this.required(newErrors, contract.startDate, "startDate", refId, undefined, "contract")
        this.required(newErrors, contract.endDate, "endDate", refId, undefined, "contract")
        this.numeric(newErrors, contract.terminationMonth, "terminationMonth", refId, "contract")
        this.numeric(newErrors, contract.prolongationMonths, "prolongationMonths", refId, "contract")
        this.range(newErrors, contract.marketRentIndexPercent, "marketRentIndexPercent", refId, 0, 1, true, "contract")

        // The contract start date must be less or equal to the end date
        this.compare(newErrors, contract.startDate, contract.endDate, Cmp.LEQ, "contract3", refId, "ValidationContract3", "contract");

        // Make sure the streams array is sorted on start date before validating
        let sortedStreams = utils.getSortedStreams(contract.paymentStreams);

        // At least one stream
        if (contract.paymentStreams.length == 0) {
            newErrors.push(<ValidationError>{ key: "stream10", refId: refId, error: this.translate("ValidationStream10"), rule: ValidationRule.Complex })
        }
        else {
            // The first streams start date must be less or equal to the contracts start date
            this.compare(newErrors, moment(sortedStreams[0].startDate).startOf("month"), moment(contract.startDate).startOf("month"), Cmp.LEQ, "contract1", sortedStreams[0].id, "ValidationContract1", "contract_streams");

            // The last streams enddate must be greater than or equal to the contracts enddate (take 'until calculation end' in account)
            let lastStream = sortedStreams[sortedStreams.length - 1];
            if (lastStream.endDate)
                this.compare(newErrors, lastStream.endDate, contract.endDate, Cmp.GEQ, "contract2", lastStream.id, "ValidationContract2", "contract_streams");

            // Payment stream validation
            Array.prototype.push.apply(newErrors, this.validatePaymentStreams(sortedStreams, "contract_streams"));
        }

        // Recoveries
        for (let r of contract.recoveries) {
            this.required(newErrors, r.costIncome.description, "recovery.description", r.id, undefined, "recovery");
            this.compare(newErrors, r.startDate, r.endDate, Cmp.LEQ, "recovery1", r.id, "ValidationRecovery1", "recovery");

            if (r.costIncome.paymentStreams.length == 0) {
                newErrors.push(<ValidationError>{ key: "stream10", refId: r.id, error: this.translate("ValidationStream10"), rule: ValidationRule.Complex, scope: "recovery" })
            }
            else {
                // Make sure the streams array is sorted on start date before validating
                let sortedRecStreams = utils.getSortedStreams(r.costIncome.paymentStreams);

                Array.prototype.push.apply(newErrors, this.validatePaymentStreams(sortedRecStreams, "recovery_streams"));
            }
        }

        // Lease areas
        this.validateLeaseAreas(contract, newErrors, "leasearea");

        this.syncErrors(newErrors, currentErrors);
    }

    validateLeaseAreas(contract: Contract, currentErrors: ValidationError[], scope?: string) {
        let hasMainPremisesType = false;

        contract.leaseAreas.forEach(la => {
            if (la.premisesTypeId == contract.mainPremisesTypeId) hasMainPremisesType = true;
            
            this.required(currentErrors, la.contractArea, "leaseArea.contractArea", la.id, undefined, scope);
            this.numeric(currentErrors, la.contractArea, "leaseArea.contractArea", la.id, scope);
            this.numeric(currentErrors, la.numberOf, "leaseArea.numberOf", la.id, scope);
            this.numeric(currentErrors, la.value, "leaseArea.value", la.id, scope);
        });

        if (!hasMainPremisesType) {
            this.warning(currentErrors, "contract.leaseAreas", contract.id, this.translate("LeaseAreaPrimaryPremisesError"), scope);
        }
    }

    validateCostIncome(costIncome: CostIncome | null, currentErrors: ValidationError[]) {
        let newErrors: ValidationError[] = [];

        if (!costIncome) {
            currentErrors.splice(0, currentErrors.length);
            return;
        }

        this.required(newErrors, costIncome.description, "description", "", undefined, "costincome");

        // if type of payment is not set
        if (costIncome.transaction.typeOfPaymentId < 1) {
            newErrors.push(<ValidationError>{ key: "ci-payment", refId: "", error: this.translate('TypeOfPaymentEmpty'), rule: ValidationRule.Required, scope: "costincome" })
        }

        // At least one stream
        if (costIncome.paymentStreams.length == 0) {
            newErrors.push(<ValidationError>{ key: "stream10", refId: "", error: this.translate("ValidationStream10"), rule: ValidationRule.Complex, scope: "costincome_streams" })
        }
        else {
            // Make sure the streams array is sorted on start date before validating
            let sortedStreams = utils.getSortedStreams(costIncome.paymentStreams);

            Array.prototype.push.apply(newErrors, this.validatePaymentStreams(sortedStreams, 'costincome_streams'));
        }

        this.syncErrors(newErrors, currentErrors);
    }

    // Validate payment streams
    // NOTE: the streams array must be sorted on startDate ascending
    validatePaymentStreams(p: PaymentStream[] | null, scope: string): ValidationError[] {
        let tempErrors: ValidationError[] = [];

        if (!p) return [];

        p.forEach((e, i) => {
            let streamRefId = e.id.toString();

            if (e.endDate)
                this.compare(tempErrors, e.startDate, e.endDate, Cmp.LEQ, "stream1", streamRefId, "ValidationStream1");

            let streamStart = moment(e.startDate).startOf("month");
            let streamEnd = moment(<string>e.endDate).startOf("month");

            // If this is not the last stream
            if (i < (p.length - 1)) {
                let nextStream = p[i + 1];
                let nextStreamRefId = nextStream.id.toString();
                let nextStart = moment(nextStream.startDate).startOf("month");

                // No stream overlaps
                let streamMonthDiff = nextStart.diff(streamEnd, "month");
                if (streamMonthDiff < 1) {
                    tempErrors.push({ key: "stream2", refId: streamRefId, error: this.translate("ValidationStream2"), rule: ValidationRule.Complex });
                    tempErrors.push({ key: "stream2", refId: nextStreamRefId, error: this.translate("ValidationStream2"), rule: ValidationRule.Complex });
                }

                // No stream gaps
                if (streamMonthDiff > 1) {
                    tempErrors.push({ key: "stream3", refId: streamRefId, error: this.translate("ValidationStream3"), rule: ValidationRule.Complex });
                    tempErrors.push({ key: "stream3", refId: nextStreamRefId, error: this.translate("ValidationStream3"), rule: ValidationRule.Complex });
                }

                // Only the last stream can be set to 'until calc end'
                if (!e.endDate) {
                    tempErrors.push({ key: "stream11", refId: streamRefId, error: this.translate("ValidationStream11"), rule: ValidationRule.Complex });
                }
            }

            if (utils.isPercentageIncrementStream(e)) {
                let pis = <PercentageIncrementStream>e;
                this.compare(tempErrors, moment(e.startDate).startOf("month"), moment(pis.firstIncrement).startOf("month"), Cmp.LEQ, "stream4", streamRefId, "ValidationStream4");
                this.required(tempErrors, pis.startAmount, "startAmount", streamRefId);
                this.numeric(tempErrors, pis.startAmount, "startAmount", streamRefId);
            }

            switch (e.typeOfPaymentStreamId as PaymentStreamType) {
                case PaymentStreamType.IndexIncrementStream:
                    let ixs = <IndexIncrementStream>e;
                    this.required(tempErrors, ixs.baseNumber, "baseNumber", streamRefId);
                    this.numeric(tempErrors, ixs.baseNumber, "baseNumber", streamRefId);
                    this.required(tempErrors, ixs.indexPercentage, "indexPercentage", streamRefId);
                    this.numeric(tempErrors, ixs.indexPercentage, "indexPercentage", streamRefId);
                    break;

                case PaymentStreamType.AdvancedPercentageStream:
                    let ap = <AdvancedPercentageIncrementStream>e;
                    this.required(tempErrors, ap.margin, "margin", streamRefId);
                    this.numeric(tempErrors, ap.margin, "margin", streamRefId);

                    for (let s of ap.squaremeterPrices) {
                        this.numeric(tempErrors, s.price, "price", streamRefId + '|' + s.premisesTypeId);
                    }

                    break;

                case PaymentStreamType.AdvancedPercentageStreamAdvancedGui:
                    let apa = <AdvancedPercentageIncrementStream>e;

                    break;

                case PaymentStreamType.VariableAmountIncrementStream:
                    let va = <VariableAmountIncrementStream>e;
                    if (va.variableAmounts.length == 0) {
                        tempErrors.push({ key: "stream5", refId: streamRefId, error: this.translate("ValidationStream5"), rule: ValidationRule.Complex });
                        break;
                    }

                    let vaErrorCount = tempErrors.length;
                    for (var k = 0; k < va.variableAmounts.length; k++) {
                        this.required(tempErrors, va.variableAmounts[k].amount, "amount", streamRefId + "|" + va.variableAmounts[k].id.toString());
                        this.numeric(tempErrors, va.variableAmounts[k].amount, "amount", streamRefId + "|" + va.variableAmounts[k].id.toString());
                        for (var j = k + 1; j < va.variableAmounts.length; j++)
                            if (va.variableAmounts[k].startDate == va.variableAmounts[j].startDate && j != k) {
                                tempErrors.push({ key: "stream6", refId: streamRefId + "|" + va.variableAmounts[k].id.toString(), error: this.translate("ValidationStream6"), rule: ValidationRule.Complex });
                                tempErrors.push({ key: "stream6", refId: streamRefId + "|" + va.variableAmounts[j].id.toString(), error: this.translate("ValidationStream6"), rule: ValidationRule.Complex });
                                break;
                            }
                    }

                    // Stream level error for variable amounts 
                    if (tempErrors.length > vaErrorCount)
                        tempErrors.push({ key: "stream7", refId: streamRefId, error: this.translate("ValidationStream7"), rule: ValidationRule.Complex });
                    break;

                case PaymentStreamType.BasicPercentageStream:
                    let bp = <BasicPercentageStream>e;

                    let bpErrorCount = tempErrors.length;
                    let startDateCheck = false;
                    for (let i = 0; i < bp.variablePercentages.length; i++) {
                        this.required(tempErrors, bp.variablePercentages[i].percentage, "percentage", streamRefId + "|" + bp.variablePercentages[i].id.toString());
                        this.numeric(tempErrors, bp.variablePercentages[i].percentage, "percentage", streamRefId + "|" + bp.variablePercentages[i].id.toString());
                        if (bp.variablePercentages[i].startDate <= e.startDate)
                            startDateCheck = true;
                    }

                    // Stream level error for variable percentages (same error text)
                    if (tempErrors.length > bpErrorCount)
                        tempErrors.push({ key: "stream8", refId: streamRefId, error: this.translate("ValidationStream8"), rule: ValidationRule.Complex });

                    // At least one of the variable percentages must have a date less than or equal to the stream start date
                    if (!startDateCheck)
                        tempErrors.push({ key: "stream9", refId: streamRefId, error: this.translate("ValidationStream9"), rule: ValidationRule.Complex });
                    break;

                case PaymentStreamType.ShareOfPaymentStream:
                    let so = <ShareOfPaymentStream>e;
                    this.required(tempErrors, so.percentage, "percentage", streamRefId);
                    this.numeric(tempErrors, so.percentage, "percentage", streamRefId);
                    break;
            }
        });

        // Set scope for all stream errors
        tempErrors.forEach(x => x.scope = scope);

        return tempErrors;
    }

    validatePortfolio(portfolio: Portfolio | null, currentErrors: ValidationError[]) {
        let newErrors: ValidationError[] = [];

        if (!portfolio) {
            currentErrors.splice(0, currentErrors.length);
            return;
        }

        this.required(newErrors, portfolio.name, "name", "")

        for (let version of portfolio.propertyVersionPortfolios) {
            this.required(newErrors, version.share, "propertyVersionPortfolios.share", version.id);
            this.numeric(newErrors, version.share, "propertyVersionPortfolios.share", version.id);
        }

        this.syncErrors(newErrors, currentErrors);
    }

    validateBaseDataLanguage(language: InlineEdit<any> | null, currentErrors: ValidationError[]) {
        let newErrors: ValidationError[] = [];

        if (!language) {
            currentErrors.splice(0, currentErrors.length);
            return;
        }

        // for (let lang of language.items) {
        //     this.required(newErrors, lang.entity.source.name, "languages.langauge.source", lang.entity.id);
        //     this.required(newErrors, lang.entity.destination.name, "languages.langauge.destination", lang.entity.id);
        // }

        this.syncErrors(newErrors, currentErrors);
    }

    validateLoan(loan: Loan | null, currentErrors: ValidationError[]) {
        let newErrors: ValidationError[] = [];

        if(!loan) {
            currentErrors.splice(0, currentErrors.length);
            return;
        }

        this.required(newErrors, loan.loanName, "loan.loanName", "");
        this.numeric(newErrors, loan.loanAmount, "loan.loanAmount", "");
        this.numeric(newErrors, loan.interest.startInterest, "loan.interest.startInterest", "");
        this.required(newErrors, loan.interest.startInterest, "loan.interest.startInterest", "");
        
        this.numeric(newErrors, loan.interest.margin, "loan.interest.margin", "");
        this.required(newErrors, loan.interest.margin, "loan.interest.margin", "");


        this.compare(newErrors, loan.startDate, loan.endDate, Cmp.LEQ, "loan.startDate", "", "ValidationLoan1");
        if (loan.interest.interestRates.length == 0 && loan.interest.interestTypeId != InterestTypeEnum.Dependent) {
            newErrors.push(<ValidationError>{ key: "loan.interest.interestRates", error: this.translate("ErrorMessage_InterestRatesMissing"), refId: "", rule: ValidationRule.Required })
        }

        loan.interest.interestRates.map(rate => {
            // this.compare(newErrors, rate.time, loan.startDate, Cmp.LEQ, "interestRateGrid.items.time" + rate.id, "", "ErrorMessage_InterestRateInvalidDates");
            this.numeric(newErrors, rate.rate, "interestRateGrid.items.rate" + rate.id, "");
        });

        loan.amortization.amortizationAmounts.map(amount => {
            this.numeric(newErrors, amount.yearlyAmount, "amortizationAmountsGrid.items.yearly", "");
            if (loan.amortization.amortizationTypeId == AmortizationTypeEnum.Fixed && amount.percentageOfLoanAmount == null)
                this.required(newErrors, amount.yearlyAmount, "amortizationAmountsGrid.items.yearly", "");
            this.numeric(newErrors, amount.percentageOfLoanAmount, "amortizationAmountsGrid.items.percentage", "");
            if (loan.amortization.amortizationTypeId == AmortizationTypeEnum.Percentual && amount.yearlyAmount == null)
                this.required(newErrors, amount.percentageOfLoanAmount, "amortizationAmountsGrid.items.percentage", "");
        });

        // this.unique(newErrors, loan.amortization.amortizationAmounts.filter(y => y.yearlyAmount != null).map(x => x.time), "amortizationAmountsGrid.items.yearly.times", "");
        // this.unique(newErrors, loan.amortization.amortizationAmounts.filter(y => y.percentageOfLoanAmount != null).map(x => x.time), "mortizationAmountsGrid.items.percentage.times", "");

        this.syncErrors(newErrors, currentErrors);
    }

    validateVDokValuations(valuation: Valuation | null, instances: string[] | null, currentErrors: ValidationError[]) {
        let newErrors: ValidationError[] = [];

        if(!valuation) {
            currentErrors.splice(0, currentErrors.length);
            return;
        }

        this.required(newErrors, valuation.name, "instance.name", "");
        
        let names: string[] = [];
        if (instances) {
            names = instances
            if (valuation.id == 0)
                names!.push(valuation.name)
            
        }
        // this.unique(newErrors, names, "instance.name", "");
        this.syncErrors(newErrors, currentErrors);
    }

    validateVDokModules(valuation: ValuationModule | null, modules: string[] | null, currentErrors: ValidationError[]) {
        let newErrors: ValidationError[] = [];

        if(!valuation) {
            currentErrors.splice(0, currentErrors.length);
            return;
        }

        this.required(newErrors, valuation.name, "module.name", "");
        
        let names: string[] = [];
        if (modules) {
            names = modules
            if (valuation.id == 0)
                names!.push(valuation.name)
            
        }
        this.unique(newErrors, names, "module.name", "");
        this.syncErrors(newErrors, currentErrors);
    }

    syncErrors(newErrors: ValidationError[], oldErrors: ValidationError[]) {
        if (newErrors.length == 0)
            oldErrors.splice(0, oldErrors.length)

        let ixRemove: number[] = [];

        oldErrors.forEach((e, i) => {
            let n = newErrors.find(x => x.key == e.key && x.refId == e.refId && x.rule == e.rule);
            if (!n) ixRemove.push(i);
        });

        ixRemove.reverse().forEach(ix => oldErrors.splice(ix, 1));

        let added: ValidationError[] = [];

        for (let e of newErrors) {
            let o = oldErrors.find(x => x.key == e.key && x.refId == e.refId && x.rule == e.rule);
            if (o) {
                if (o.error !== e.error)
                    o.error = e.error;
            }
            else {
                added.push(e);
            }
        }

        oldErrors.push(...added);
    }
}
