import { Component, Prop, Watch } from "vue-property-decorator";
import VersionHeaderComponent from '@/components/version-header/version-header';
import PortfolioHeaderComponent from '@/components/calculation/portfolio-header/portfolio-header';
import BaseComponent from '@/components/base-component';
import { mapGetters } from 'vuex';
import { Portfolio, PropertyVersion, Currency } from '@/models';
import InvestmentsReportComponent from './investment/investments-report';
import RentCompositionComponent from './rent-composition/rent-composition';
import CashflowComponent from './cashflow/cashflow';
import AreasComponent from './areas/areas';
import dataService from '@/services/data-service';
import { WebReportKPIs } from '@/models/interfaces/web-report/WebReportKPIs';
import { WebReports } from '@/models/interfaces/web-report/WebReports';
import { CalculationState } from '@/models/interfaces/CalculationState';
import ProgressBarComponent from '@/components/common/progress-bar/signalr-progress-bar';
import CalculationResultComponent from '@/components/calculation/calculation-result/calculation-result';
import ReportSettingsComponent from '@/components/calculation/report-settings/report-settings';
import { PropertyVersionSlim } from '@/models/interfaces/PropertyVersionSlim';

@Component({
    components: {
        VersionHeaderComponent,
        PortfolioHeaderComponent,
        InvestmentsReportComponent,
        CashflowComponent,
        RentCompositionComponent,
        ProgressBarComponent,
        CalculationResultComponent,
        ReportSettingsComponent,
        'areas-component': AreasComponent,
    },
    computed: mapGetters({
        propertyVersion: "getPropertyVersion",
        busMessage: "getBusMessage"
    })
})
export default class WebReportsComponent extends BaseComponent {
    loading: boolean = false;
    openTabs = ["Result"];
    propertyVersion!: PropertyVersion;
    reportData: WebReports | null = null;
    requestTimer!: any;
    infoMessage: string = '';
    selectedPropertyVersion: PropertyVersionSlim | null = null;;

    @Prop({default: true})
    rentComposition!: boolean;
    @Prop({default: true})
    cashFlowAnalysis!: boolean;
    @Prop({default: true})
    investments!: boolean;
    @Prop({default: true})
    areas!: boolean;
    @Prop()
    active!: boolean;
    @Prop()
    isVersion!: boolean;
    @Prop()
    isLocked!: boolean;
    @Prop()
    portfolio!: Portfolio;
    @Prop()
    canCalculate!: boolean;
    @Prop()
    calculationState!: CalculationState;

    @Watch("active") activeWatcher() {
        this.refresh();
    }
    @Watch("propertyVersion") versionWatcher() {
        if (this.isVersion) this.refresh();
    }
    @Watch("portfolio") portfolioWatcher() {
        if (!this.isVersion) this.refresh();
    }
    @Watch("openTabs") tabWatcher() {
        this.doLayout();
    }

    mounted() {
        this.refresh();
        this.selectedPropertyVersion = null;
    }

    async refresh() {
        clearTimeout(this.requestTimer);
        this.requestTimer = setTimeout(async () => {
            if ((() => {
                if (!this.active) return true;
                if (this.isVersion && !this.propertyVersion)  return true;
                if (!this.isVersion && !this.portfolio)  return true;
                return false;
            })()) {
                this.reportData = null;
                return;
            }

            if (this.$refs.calcProgressBar) {
                (<ProgressBarComponent> this.$refs.calcProgressBar).progress = 0;
                (<ProgressBarComponent> this.$refs.calcProgressBar).status = null;
            }
            
            this.loading = true;
            let id = this.isVersion ? this.propertyVersion.id : this.portfolio ? this.portfolio.id : 0;
            await dataService.getReportsData(id, !this.isVersion)
                .then(x => {
                    this.reportData = x.data;
                    this.infoMessage = x.data == null ? this.translate('ErrorMessage_NoCalculationsFound') :'';
                })
                .catch(x => 
                    {
                        this.reportData = null
                    })
                .finally(() => { this.loading = false; });
            if (this.reportData === null)  return;
        }, 400);
    }

    reports() {
        return [ this.rentComposition, this.cashFlowAnalysis, this.investments, this.areas];
    }

    portfolioChanged(portfolio: Portfolio) {
        this.portfolio = portfolio;
    }

    stateCurrency() {
        let currencies = this.$store.getters.getCurrencies;
        if (!currencies) return null;
        return (<Currency[]> currencies).find(x => x.id == this.calculationState.currencyId);
    }

    currencyRate() {
        let currencies = this.$store.getters.getCurrencies;
        if (!this.isVersion || !this.propertyVersion || !currencies) return 1;
        let versionCurrency = (<Currency[]> currencies).find(x => x.id == this.propertyVersion.currencyId);
        if (!versionCurrency) return 0;
        let stateCurrency = (<Currency[]> currencies).find(x => x.id == this.calculationState.currencyId);
        if (!stateCurrency) return 0;
        return versionCurrency.rate / stateCurrency.rate;
    }

    calcComponent() {
        return this.isVersion ? (<any>this.$refs.calculationVersionRef) : (<any>this.$refs.calculationPortfolioRef);
    }

    clone(obj: any) {
        return JSON.parse(JSON.stringify(obj));
    }

    doLayout() {
        this.$nextTick(function (){
            const iComp = (<InvestmentsReportComponent>this.$refs.investments);
            if (iComp && this.openTabs.includes('InvestmentCalculation')) {
                iComp.doLayout();
            }
        })
    }

    calculate() {
        this.$emit('calculate', false);
    }
    
    updateSelectedPropertyVersion(newVal : PropertyVersionSlim){
        this.selectedPropertyVersion = newVal;
    }
}