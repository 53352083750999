import BaseComponent from "@/components/base-component";
import { Component, Watch, Prop } from "vue-property-decorator";
import { PropertyRight, InvolvedProperty } from "@/models/";
import { ValidationError } from '@/models/interfaces/ValidationError';
import InlineEdit from '@/helpers/inline-edit';
import { AxiosPromise, AxiosResponse } from 'axios';
import InputTextComponent from '@/components/common/input-text/input-text';
import ValidationTableFeedbackComponent from '@/components/common/validation-table-feedback/validation-table-feedback';
import ValidationErrorComponent from '@/components/common/validation-error/validation-error';
import { BaseViewModel } from "@/models/interfaces/BaseViewModel";

@Component({
    components: {
        InputTextComponent,
        ValidationTableFeedbackComponent,
        ValidationErrorComponent
    }
})
export default class RightsComponent extends BaseComponent {
    @Prop()
    rights!: PropertyRight[];
    @Prop()
    complexErrors!: ValidationError[];
    @Prop()
    isLinked!: boolean;

    @Prop()
    layout!: boolean;

    rightsGrid: InlineEdit<PropertyRight>;
    involvedPropertiesGrid: InlineEdit<InvolvedProperty>;

    @Watch("rights", { immediate: true})
    rightsWatcher() {
        this.rightsGrid.reload();
        this.rightsGrid.readOnly = this.isLinked;
        this.involvedPropertiesGrid.readOnly = this.isLinked;
    }

    @Watch("rightsGrid.currentItem")
    currentRightWatcher() {
        this.involvedPropertiesGrid.reload();
    }

    @Watch("layout")
    layoutWatcher() {
        if (this.layout == true) {
            (<any> this).$refs.rightsTable.doLayout();
            (<any> this).$refs.involvedPropertiesTable.doLayout();
        }
    }

    constructor() {
        super();
        this.rightsGrid = new InlineEdit<PropertyRight> (this, "rightsTable", this.loadRights, undefined, undefined, this.newRight, undefined, this.hasNoErrors);
        this.rightsGrid.sync = true;
        this.involvedPropertiesGrid = new InlineEdit<InvolvedProperty> (this, "involvedPropertiesTable", this.loadInvolvedProperties, undefined, undefined, this.newInvolvedProperty, undefined, this.hasNoErrors);
        this.involvedPropertiesGrid.sync = true;
    }

    loadRights(): AxiosPromise<PropertyRight[]> {
        return Promise.resolve(<AxiosResponse> { data: this.rights });
    }

    newRight(): PropertyRight {
        return <PropertyRight> {
            id: this.getNewId(this.rightsGrid.entities),
            propertyDetailId: 0,
            rightTypeText: "",
            burden: false,
            purpose: "",
            rightTypeId: 1,
            involvedProperties: []
        }
    }

    loadInvolvedProperties(): AxiosPromise<InvolvedProperty[]> {
        return Promise.resolve(<AxiosResponse> { data: this.rightsGrid.currentItem ? this.rightsGrid.currentItem.entity.involvedProperties : [] });
    }

    newInvolvedProperty(): InvolvedProperty {
        return <InvolvedProperty> {
            id: this.getNewId(this.rightsGrid.currentItem!.entity.involvedProperties),
            propertyName: ""
        }
    }

    canAddInvolved(): boolean {
        return !!this.rightsGrid.currentItem && !this.isLinked;
    }

    getInvolvedProperties(right: PropertyRight): string {
        return right.involvedProperties.map(x => x.propertyName).join(", ");
    }

    formatRightType(value: number) {
        if (value == 1)
            return this.translate('Easement');
        return this.translate('UtilityEasement');
    }

    formatBurden(value: boolean): string {
        if (value == true)
            return this.translate('Burden');
        return this.translate('Benefit');
    }

    getInvolvedRefId(right: BaseViewModel<PropertyRight> | null, involvedProperty: BaseViewModel<InvolvedProperty> | null): string {
        return (right ? right.entity.id : "") + "|" + (involvedProperty ? involvedProperty.entity.id : "")
    }
}