import BaseComponent from "@/components/base-component";
import { Component, Watch, Prop } from "vue-property-decorator";
import dataService from '@/services/data-service';
import { PropertyImport } from "@/models/PropertyImport";
import { Property } from "@/models";
import { LinkPropertyDetailsRequest } from "@/models/interfaces/request/LinkPropertyDetailsRequest";
import { ImportPropertyDetailsRequest } from "@/models/interfaces/request/ImportPropertyDetailsRequest";
import { General } from "@/helpers/general";

@Component({
    components: {}
})
export default class PropertyDataImportComponent extends BaseComponent {
    propertyImports: PropertyImport[] = []
    selectedImport: PropertyImport | null = null;
    importsLoading: boolean = false;
    importing: boolean = false;
    linking: boolean = false;
    unlinking: boolean = false;
    importsTable: any;

    @Prop()
    property!: Property;

    @Watch("property", { immediate: true })
    async propertyWatcher() {
        if (this.property) {
            await this.loadPropertyImports();
            if (this.propertyImports.length > 0) {
                this.selectImportItem(this.propertyImports[0].id);
            }
        } else {
            this.propertyImports = [];
        }
    }

    mounted() {
        this.importsTable = (<any>this.$refs).importsTable;
    }

    async loadPropertyImports() {
        try {
            this.importsLoading = true;
            this.propertyImports = await dataService.getPropertyDataImports(this.property.id).then(x => x.data);
        } finally {
            this.importsLoading = false;
        }
    }

    importSelected(item: PropertyImport) {
        this.selectedImport = item;
    }

    selectImportItem(importId: number) {
        let item = this.propertyImports.find(x => x.id === importId);

        if (item) {
            this.selectedImport = item;
            this.$nextTick(() => {
                this.importsTable.setCurrentRow(this.selectedImport);
            })
        }
    }

    async downloadPdf(item: PropertyImport) {
        let response = await dataService.getPropertyPdf(item.id);
        General.downloadFile(response);
    }

    importData() {
        this.confirm("ConfirmPropertyDataImport", "Confirm", "Ok", "Cancel", "warning").then(async () => {
            try {
                this.importing = true;
                let request: ImportPropertyDetailsRequest = { propertyId: this.property.id, propertyName: this.property.providerPropertyName };
                let importId = await dataService.importPropertyDetails(request).then(x => x.data);
                await this.loadPropertyImports();
                this.selectImportItem(importId);
            } catch (error) {
                return Promise.reject(new Error(""));
            } finally {
                this.importing = false;
            }
        }).catch(() => { });
    }

    link() {
        this.confirm("ConfirmPropertyDetailsLink", "Confirm", "Ok", "Cancel", "warning").then(async () => {
            try {
                this.linking = true;
                let request: LinkPropertyDetailsRequest = { propertyId: this.property!.id, importId: this.selectedImport!.id };
                await dataService.linkPropertyDetails(request);
                this.linking = false;
                this.$emit("reloadProperty");
            } catch (error) {
                return Promise.reject(new Error(""));
            } finally {
                this.linking = false;
            }
        }).catch(() => { });
    }

    unlink() {
        this.confirm("ConfirmPropertyDetailsUnlink", "Confirm", "Ok", "Cancel", "info").then(async () => {
            try {
                this.unlinking = true;
                await dataService.unlinkPropertyDetails(this.property.id);
                this.unlinking = false;
                this.$emit("reloadProperty");
            } catch (error) {
                return Promise.reject(new Error(""));
            } finally {
                this.unlinking = false;
            }
        }).catch(() => { });
    }

    canImport(): boolean {
        return this.property && !!this.property.providerPropertyName
    }

    canLink(): boolean {
        return !!this.selectedImport && !this.selectedImport.isLinked;
    }

    canUnlink(): boolean {
        return this.property && !!this.selectedImport && this.selectedImport.isLinked;
    }

    isLinked(): boolean {
        return this.property && !!this.property.propertyImportId
    }

    linkInfo(): string {
        return this.property && this.property.linkedDateUtc ? this.formatYearMonthHourMinute(this.property.linkedDateUtc) : this.translate("NotLinked");
    }
}