import { Component, Prop, Watch } from "vue-property-decorator";
import BaseComponent from "@/components/base-component";
import EditGroupComponent from "@/components/admin/edit-group/edit-group";
import { Customer, User } from '@/models';
import dataService from '@/services/data-service';
import { UserGroup } from "@/models/UserGroup";
import { mapGetters } from "vuex";

@Component({
    components: {
        EditGroupComponent 
    },
    computed: mapGetters({
        currentUser: "getUser"
    })
})
export default class GroupsComponent extends BaseComponent {
    currentUser!: User;
    
    customers: Customer[] = [];
    selectedCustomer: Customer | null = null;
    selectedGroup: UserGroup | null = null;
    groupClone: string = "";

    @Prop()
    isActive!: boolean;
    
    @Watch("isActive", { immediate: true }) 
    async isActiveWatcher() {
        if (this.isActive) {
            await this.loadData(this.selectedGroup);
        }
    }

    // Loads the customer(s) and related groups and sets selected customer/group state
    // All customers are loaded if the current user is SysAdmin
    async loadData(groupToSelect?: UserGroup | null) {
        if (this.currentUser.userGroup.isSysAdmin) {
            this.customers = await dataService.getCustomers().then(x => x.data);
        } else {
            const customer = await dataService.getCustomer().then(x => x.data);
            this.customers = [customer];
        }
        this.selectedCustomer = (this.selectedCustomer ? this.customers.find(x => x.id === this.selectedCustomer!.id) : this.customers[0]) || null;
        if (this.selectedCustomer) {
            const group = groupToSelect ? this.selectedCustomer!.userGroups.find(x => x.id === groupToSelect.id) || null : null;
            this.setSelectedGroup(group);
        }
    }

    // Programmatically set the selected group
    // Also save a clone of the selected group (for dirty change tracking)
    setSelectedGroup(group: UserGroup | null) {
        this.selectedGroup = group;
        this.groupClone = group ? JSON.stringify(group) : "";
        this.setGroupsTableActiveRow(group && group.id !== 0 ? group : null);
    }

    // Called when a user changes the active customer
    customerSelected() {
        this.setSelectedGroup(null);
    }

    // Called when a user selects an item in the groups table
    async groupSelected(group: UserGroup) {
        if (this.selectedGroup && this.isDirty()) {
            try {
                await this.confirm("ConfirmDiscardChanges");
            } catch (error) {
                this.setGroupsTableActiveRow(this.selectedGroup);
                return;
            }
            await this.loadData(group);
        } else {
            this.setSelectedGroup(group);
        }
    }

    // Highlights the specified group in the groups table
    setGroupsTableActiveRow(group?: UserGroup | null) {
        (<any>this.$refs).groupsTable.setCurrentRow(group);
    }

    // Returns true if the edited group is changed
    isDirty(): boolean {
        return JSON.stringify(this.selectedGroup) !== this.groupClone;
    }

    // Creates a new group and sets it in edit mode
    newGroup() {
        const newGroup = {
            id: 0,
            customerId: this.selectedCustomer!.id,
            groupRights: [],
            isAdmin: false,
            isSysAdmin: false,
            userGroupName: ""
        };
        this.setSelectedGroup(newGroup);
    }

    // Called when a group has been saved
    async groupSaved(group: UserGroup) {
        await this.loadData(group);
    }

    // Called when a group edit has beed canceled
    async groupCanceled(isNew: boolean) {
        if (this.selectedGroup && (isNew || this.isDirty())) {
            try {
                await this.confirm("ConfirmDiscardChanges");
                if (isNew) {
                    this.setSelectedGroup(null);
                } else {
                    await this.loadData();
                }
            } catch (error) {
            }
        }
    }

    // Called when a group has been deleted
    async groupDeleted() {
        await this.loadData();
    }

    canChangeCustomer(): boolean {
        return this.currentUser.userGroup.isSysAdmin;
    }
}