import BaseComponent from "@/components/base-component";
import { Component, Watch, Prop } from "vue-property-decorator";
import dataService from "@/services/data-service";
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import InputTextComponent from '@/components/common/input-text/input-text';
import { ValidationError } from '@/models/interfaces/ValidationError';
import validationService from '@/services/validation-service';
import { EditVersionDialogMode } from '@/constants/fia-constants';
import { PropertyVersionSlim } from '@/models/interfaces/PropertyVersionSlim';
import { Property } from '@/models';
import { CopyPropertyVersionRequest } from '@/models/interfaces/request/CopyPropertyVersionRequest';

export interface OpenPropertyVersionEditDialogArgs {
    editMode: EditVersionDialogMode;
    sourceVersionId: number;
    sourcePropertyId: number;
    targetPropertyId?: number;
}

@Component({
    components: {
        YearMonthPickerComponent,
        InputTextComponent
    }
})
export default class EditPropertyVersionDialogComponent extends BaseComponent {
    @Prop()
    args!: OpenPropertyVersionEditDialogArgs;

    editPropertyVersion: PropertyVersionSlim | null = null;
    editPropertyVersionClone: string = "";;
    sourceProperty: Property | null = null;
    targetProperty: Property | null = null;
    complexErrors: ValidationError[] = [];
    visible: boolean = false;
    targetPropertyEnabled: boolean = false;
    title: string = "";
    copyMode: boolean = false;
    processing: boolean = false;

    @Watch("editPropertyVersion", { deep: true })
    editPropertyVersionWatcher() {
        validationService.validatePropertyVersion(this.editPropertyVersion, this.complexErrors);
    }

    @Watch("args")
    async argsWatcher() {
        this.complexErrors = [];
        this.copyMode = this.args.editMode === EditVersionDialogMode.CopyToOther || this.args.editMode === EditVersionDialogMode.CopyToSame;

        try {
            this.sourceProperty = await dataService.getProperty(this.args.sourcePropertyId, false).then(x => x.data);

            switch (this.args.editMode) {
                case EditVersionDialogMode.Edit:
                    this.title = this.translate("EditPropertyVersion");
                    this.targetPropertyEnabled = false;
                    this.editPropertyVersion = await dataService.getPropertyVersionSlim(this.args.sourceVersionId).then(x => x.data);
                    this.editPropertyVersionClone = JSON.stringify(this.editPropertyVersion);
                    break;
                case EditVersionDialogMode.Add:
                    this.title = this.translate("AddPropertyVersion");
                    this.targetPropertyEnabled = false;
                    this.editPropertyVersion = <PropertyVersionSlim>{
                        id: 0,
                        propertyId: this.args.sourcePropertyId,
                        versionIdentifier: "",
                        calculationStart: this.formatIsoDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
                        calculationEnd: this.formatIsoDate(new Date(new Date().getFullYear() + 1, 11, 31)),
                        currencyId: 1,
                        locked: false,
                        propertyName: "",
                        propertyIdentifier: ""
                    };
                    break;
                case EditVersionDialogMode.CopyToSame:
                    this.title = this.translate("CopyPropertyVersion");
                    this.targetPropertyEnabled = true;
                    this.targetProperty = this.sourceProperty;
                    this.editPropertyVersion = await dataService.getPropertyVersionSlim(this.args.sourceVersionId).then(x => x.data);
                    this.editPropertyVersion!.versionIdentifier = "";

                    break;
                case EditVersionDialogMode.CopyToOther:
                    this.title = this.translate("CopyPropertyVersion");
                    this.targetPropertyEnabled = true;
                    this.targetProperty = await dataService.getProperty(<number>this.args.targetPropertyId, false).then(x => x.data);
                    let sourceVersion = await dataService.getPropertyVersionSlim(this.args.sourceVersionId).then(x => x.data);

                    this.editPropertyVersion = <PropertyVersionSlim>{
                        id: 0,
                        propertyId: this.targetProperty!.id,
                        versionIdentifier: "",
                        calculationStart: sourceVersion.calculationStart,
                        calculationEnd: sourceVersion.calculationEnd,
                        currencyId: sourceVersion.currencyId,
                        locked: false,
                        propertyName: "",
                        propertyIdentifier: ""
                    };
                    break;
            }
            this.visible = true;
        } catch (error) {
            return Promise.reject(new Error(""));
        }
    }

    async save() {
        this.processing = true;

        try {
            switch (this.args.editMode) {
                case EditVersionDialogMode.Edit:
                    await this.$store.dispatch("updatePropertyVersion", this.editPropertyVersion);
                    break;
                case EditVersionDialogMode.Add:
                    await this.$store.dispatch("addPropertyVersion", this.editPropertyVersion);
                    break;
                case EditVersionDialogMode.CopyToSame:
                    await this.$store.dispatch("copyPropertyVersion", <CopyPropertyVersionRequest>{ sourcePropertyVersionId: this.args.sourceVersionId, targetPropertyId: this.targetProperty!.id, versionIdentifier: this.editPropertyVersion!.versionIdentifier });
                    break;
                case EditVersionDialogMode.CopyToOther:
                    await this.$store.dispatch("copyPropertyVersion", <CopyPropertyVersionRequest>{ sourcePropertyVersionId: this.args.sourceVersionId, targetPropertyId: this.targetProperty!.id, versionIdentifier: this.editPropertyVersion!.versionIdentifier });
                    break;
            }
        } catch (error) {
            return Promise.reject(new Error(""));
        } finally {
            this.processing = false;
        }

        this.close(false);
    }

    cancel() {
        if (this.args.editMode === EditVersionDialogMode.Edit && JSON.stringify(this.editPropertyVersion) !== this.editPropertyVersionClone) {
            this.confirm('ConfirmDiscardChanges').then(() => {
                this.close(true);
            }).catch(() => {
                return;
            });
        }
        else {
            this.close(true);
        }
    }

    close(canceled: boolean) {
        this.visible = false;
        this.$emit('closed', canceled)
    }
}