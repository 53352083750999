import { Component, Prop, Watch } from "vue-property-decorator";
import BaseComponent from "@/components/base-component";
import EditUserComponent from "@/components/admin/edit-user/edit-user";
import { Customer, User, UserSettings } from '@/models';
import dataService from '@/services/data-service';
import { LanguageEnum } from "@/constants/fia-constants";
import { mapGetters } from "vuex";

@Component({
    components: {
        EditUserComponent
    },
    computed: mapGetters({
        currentUser: "getUser"
    })
})
export default class UsersComponent extends BaseComponent {
    currentUser!: User;

    @Prop()
    isActive!: boolean;

    customers: Customer[] = [];
    selectedCustomer: Customer | null = null;
    users: User[] = [];
    selectedUser: User | null = null;
    filteredUsers: User[] = [];
    filterText: string = "";
    filterInactive: boolean = false;
    userSearching: boolean = false;
    userClone: string = "";
    
    @Watch("isActive", { immediate: true }) 
    async isActiveWatcher() {
        if (this.isActive) {
            await this.getCustomers();
            await this.getUsers(this.selectedUser);
        }
    } 
    
    @Watch("filterText")
    filterNameTextWatcher() {
        this.filterUsers(this.filterText);
    }

    @Watch("filterInactive")
    filterInactiveWatcher() {
        this.filterUsers(this.filterText);
    }

    async getCustomers() {
        if (this.currentUser.userGroup.isSysAdmin) {
            this.customers = await dataService.getCustomers().then(x => x.data);
        } else {
            const customer = await dataService.getCustomer().then(x => x.data);
            this.customers = [customer];
        }
        this.selectedCustomer = (this.selectedCustomer ? this.customers.find(x => x.id === this.selectedCustomer!.id) : this.customers[0]) || null;
    }

    async getUsers(userToSelect?: User | null) {
        this.users = await dataService.getUsers(this.selectedCustomer!.id).then(x => x.data);
        this.filteredUsers = this.users;
        this.filterUsers(this.filterText);
        const user = userToSelect ? this.users.find(x => x.id === userToSelect.id) || null : null;
        this.setSelectedUser(user);
    }

    // Programmatically set the selected user
    // Also save a clone of the selected user (for dirty change tracking)
    setSelectedUser(user: User | null) {
        this.selectedUser = user;
        this.userClone = user ? JSON.stringify(user) : "";
        this.setUsersTableActiveRow(user && user.id !== 0 ? user : null);
    }

    // Called when a user selects an item in the users table
    async userSelected(user: User) {
        if (this.selectedUser && this.isDirty()) {
            try {
                await this.confirm("ConfirmDiscardChanges");
            } catch (error) {
                this.setUsersTableActiveRow(this.selectedUser);
                return;
            }
            await this.getUsers(user);
        } else {
            this.setSelectedUser(user);
        }
    }

    // Highlights the specified user in the users table
    setUsersTableActiveRow(user?: User | null) {
        (<any>this.$refs).usersTable.setCurrentRow(user);
    }

    // Returns true if the edited group is changed
    isDirty(): boolean {
        return JSON.stringify(this.selectedUser) !== this.userClone;
    }

    newUser() {
        const group = this.selectedCustomer!.userGroups.find(x => !x.isAdmin);
        if (!group) return;

        const user: User = {
            id: 0,
            realName: '',
            email: '',
            inactive: false,
            mrics: false,
            databaseId: this.selectedCustomer!.databases[0].id,
            title: null,
            titleEng: null,
            phone: null,
            userSettings: <UserSettings>{},
            officeId: this.selectedCustomer!.offices[0].id,
            customerId: this.selectedCustomer!.id,
            languageId: LanguageEnum.English,
            userGroupId: group!.id,
            userGroup: group,
            userRights: [],
            syncfusionKey: '',
            mapKey: ''
        }
        this.setSelectedUser(user);
    }

    async customerChanged() {
        this.filterText = "";
        await this.getUsers();
    }

    filterUsers(name: string): void {
        this.filteredUsers = this.users.filter(x => {
            const nameFilter = name ? x.realName.toLowerCase().indexOf(name.toLowerCase()) > -1 : true;
            const emailFilter = name ? x.email.toLowerCase().indexOf(name.toLowerCase()) > -1 : true;
            return nameFilter || emailFilter;
        });

        if (!this.filterInactive) {
            this.filteredUsers = this.filteredUsers.filter(x => x.inactive === false);
        }
    }

    getOfficeName(row: User, column: any, cellValue: number): string {
        const office = this.selectedCustomer!.offices.find(x => x.id === cellValue);
        return office ? office.name : this.translate('NotSpecified');
    }

    getUserGroupName(row: User): string {
        return row.userGroup.userGroupName;
    }

    async userSaved(user: User, isNew: boolean) {
        if (isNew) {
            this.filterText = "";
            this.filterInactive = false;
        }
        await this.getUsers(user);
    }

    async userCanceled(isNew: boolean) {
        if (this.selectedUser && (isNew || this.isDirty())) {
            try {
                await this.confirm("ConfirmDiscardChanges");
                if (isNew) {
                    this.setSelectedUser(null);
                } else {
                    await this.getUsers();
                }
            } catch (error) {
            }
        }
    }

    canChangeCustomer(): boolean {
        return this.currentUser.userGroup.isSysAdmin;
    }
}