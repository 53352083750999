import { Component, Watch } from "vue-property-decorator";
import { mapGetters } from 'vuex'
import NotificationsComponent from '@/components/notifications/notifications';
import UserDialogComponent from '@/components/user-dialog/user-dialog';
import { Database, User } from '@/models';
import dataService from '@/services/data-service';
import { LanguageEnum, AppRightEnum } from '@/constants/fia-constants';
import { BusMessage, HttpErrorMessage } from '@/models/messages/messages';
import BaseComponent from '@/components/base-component';
import { getBrowser } from '@/helpers/browser';

@Component({
    components: {
        NotificationsComponent,
        UserDialogComponent
    },
    computed: mapGetters({
        user: "getUser",
        error: "getError",
        busMessage: "getBusMessage"
    })
})
export default class AppComponent extends BaseComponent {
    user!: User;
    error!: any;
    databases: Database[] = [];
    busMessage!: BusMessage<any>;
    userImage: string = 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png';
    userDialogVisible: boolean = false;
    currentYear: number = new Date().getFullYear();

    @Watch("busMessage")
    busMessageWatcher() {
        if (this.busMessage.type === "HttpError") {
            let error = (this.busMessage as BusMessage<HttpErrorMessage>).data.error;
            console.log(error);
            let message = "";
            switch (error.statusCode) {
                case 400:
                    if ((error.data || "").startsWith("BadRequest_")) {
                        message = this.translate(error.data);
                    } else {
                        message = error.data;
                    }
                    break;
                case 500:
                    message = this.translate("AnUnexpectedErrorOccurred");
                    break;
                default:
                    message = this.translate("HttpRequestErrorMessage", { message: error.message, data: error.data });
                    break;
            }

            this.$notify.error({
                title: this.translate("HttpRequestErrorTitle"),
                message: message
            });
        }
    }

    mounted() {
        let browserVersion = getBrowser();
        if (browserVersion.name !== "Chrome" || parseFloat(browserVersion.version) < 69.0) {
            this.$message({
                message: this.translate("UnSupportedBrowserWarning"),
                type: "warning",
            })
        }
        this.databases = this.$store.getters.getDatabases;
        this.getAvatar();
    }

    getCurrentDatabaseName(): string {
        let db = this.databases.find(x => x.id === this.user.databaseId);
        return db ? db.name : "";
    }

    async accountCommand(command: string) {
        switch (command) {
            case "signOut":
                this.$msal.signOut();
                return;
            case "account":
                // this.userDialogVisible = true;
                return;
            case "english":
                await dataService.setLanguage(LanguageEnum.English);
                this.reloadPage();
                return;
            case "swedish":
                await dataService.setLanguage(LanguageEnum.Swedish);
                this.reloadPage();
                return;
        }

        if (command.startsWith("db_")) {
            let databaseId = parseInt(command.substr(3, command.length - 3), 10);

            try {
                await dataService.setDatabase(databaseId);
            } catch (error) {
                return Promise.reject(new Error(""));
            }
            
            this.reloadPage();
        }
    }

    reloadPage() {
        window.location.replace(window.location.origin);
    }

    importExportEnabled(): boolean {
        return this.$store.getters.getAppPermission(AppRightEnum.ImportExport);
    }

    vDokEnabled(): boolean { 
        return this.$store.getters.getAppPermission(AppRightEnum.VDokEditor);
    }

    fiaEnabled(): boolean {
        return this.$store.getters.getAppPermission(AppRightEnum.Fia);
    }

    adminEnabled(): boolean {
        return this.user.userGroup.isAdmin;
    }

    basedataEnabled(): boolean {
        return this.$store.getters.getAppPermission(AppRightEnum.Fia_Basedata_Read) ||
               this.$store.getters.getAppPermission(AppRightEnum.Fia_Basedata_Write);
    }

    isSysAdmin(): boolean {
        return this.user.userGroup.isSysAdmin;
    }

    getAppVersion(): string {
        return process.env.VUE_APP_VERSION || "?";
    }

    isProd(): boolean {
        return process.env.VUE_APP_MODE === "prod"
    }

    getBuildMode(): string {
        return (process.env.VUE_APP_MODE || "?").toUpperCase();
    }

    async getAvatar() {
        let imgData = await dataService.getUserImage(this.$store.getters.getUser.id, 125).then(x => x.data);
        let result = imgData === "" ? "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" : "data:image/jpg;base64," + imgData;
        this.userImage = result;
    }
}
