import { render, staticRenderFns } from "./contract-update-selected.vue?vue&type=template&id=23049f5b&"
import script from "./contract-update-selected.ts?vue&type=script&lang=js&"
export * from "./contract-update-selected.ts?vue&type=script&lang=js&"
import style0 from "./contract-update-selected.vue?vue&type=style&index=0&id=23049f5b&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports