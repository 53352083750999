import BaseComponent from "@/components/base-component";
import { Component } from "vue-property-decorator";
import { mapGetters } from 'vuex'
import { Portfolio } from "@/models/Portfolio";

@Component({
    computed: mapGetters({
        portfolio: 'getPortfolio'
    })
})
export default class PortfolioHeaderComponent extends BaseComponent {
    portfolio!: Portfolio;
}
