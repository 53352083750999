import moment from 'moment';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import BaseComponent from '@/components/base-component';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import ValidationErrorComponent from '@/components/common/validation-error/validation-error';
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import AdvancedPercentageStreamComponent from '@/components/payment-flows/advanced-percentage/advanced-percentage-stream';
import BasicPercentageStreamComponent from '@/components/payment-flows/basic-percentage/basic-percentage-stream';
import InputTextComponent from '@/components/common/input-text/input-text';
import IndexStreamComponent from '@/components/payment-flows/index-stream/index-stream';
import ShareStreamComponent from '@/components/payment-flows/share-stream/share-stream';
import VariableAmountStreamComponent from '@/components/payment-flows/variable-amount-stream/variable-amount-stream';
import {
    DateFormat, FiaServerApi$type, IndexClauseEnum, IndexDenotationEnum, monthList, NewStreamLogic,
    paymentFrequencies, PaymentGroupEnum, PaymentStreamType, PrognosisParameterEnum,
    TypeOfPaymentEnum
} from '@/constants/fia-constants';
import utils from '@/helpers/utils';
import {
    AdvancedPercentageIncrementStream, BasicPercentageStream, Contract, CostIncome, Development,
    DevelopmentType, IndexIncrementStream, MaintenanceCost, PaymentGroup, PaymentStream,
    PercentageIncrementStream, PropertyVersion, Recovery, ShareOfPaymentStream, SquaremeterPrice,
    Transaction, TypeOfPayment, TypeOfPaymentStream, User, VariableAmountIncrementStream
} from '@/models';
import { ValidationError } from '@/models/interfaces/ValidationError';
import paymentflowService, { NewStreamProps } from '@/services/paymentflow-service';

@Component({
    components: {
        YearMonthPickerComponent,
        InputNumericComponent,
        InputTextComponent,
        IndexStreamComponent,
        ShareStreamComponent,
        VariableAmountStreamComponent,
        BasicPercentageStreamComponent,
        AdvancedPercentageStreamComponent,
        ValidationErrorComponent
    },
    computed: mapGetters({
        propertyVersion: "getPropertyVersion",
        developmentTypes: "getDevelopmentTypes",
        typeOfPayments: "getTypeOfPayments",
        paymentGroupsNormal: "getPaymentGroupsNormal",
        typeOfPaymentStreams: "getTypeOfPaymentStreams",
        user: "getUser"
    })
})
export default class PaymentFlowsComponent extends BaseComponent {
    user!: User;
    propertyVersion!: PropertyVersion;
    developmentTypes!: DevelopmentType[];
    typeOfPayments!: TypeOfPayment[];
    paymentGroupsNormal!: PaymentGroup[];
    typeOfPaymentStreams!: TypeOfPaymentStream[];

    @Prop()
    contract!: Contract | null;
    @Prop()
    costIncome!: CostIncome | null;
    @Prop()
    recovery!: Recovery | null;
    @Prop()
    complexErrors!: ValidationError[];
    @Prop()
    typeOfFlow!: 'contract' | 'recovery' | 'costIncome';

    PaymentStreamType = PaymentStreamType; // for references in .vue file
    contractPaymentStreams: PaymentStream[] = [];
    typeOfPaymentStreamsFiltered: TypeOfPaymentStream[] = [];
    selectedStream: PaymentStream | null = null;
    frequencyList = paymentFrequencies;
    monthList = monthList;
    tempEndDate: string | null = "";
    transaction: Transaction | null = null;
    lastEntity: any = null;
    moveStreamDates: number = 3;

    @Watch("contract")
    contractWatcher(newContract: Contract | null, oldContract: Contract | null) {
        this.lastEntity = oldContract;
        this.initialize();
    }

    @Watch("costIncome")
    costIncomeWatcher(newCostIncome: CostIncome | null, oldCostIncome: CostIncome | null) {
        this.lastEntity = oldCostIncome;
        this.initialize();
    }

    @Watch("contract.startDate")
    contractStartWatcher() {
        if (this.contract && this.contract === this.lastEntity) 
            this.updateFirstStreamStartDate(this.contract.startDate);
    }

    @Watch("contract.endDate")
    contractEndWatcher() {
        if (this.contract && this.contract === this.lastEntity)
            this.updateLastStreamEndDate(this.contract.endDate);
    }

    @Watch("recovery.startDate")
    recoveryStartWatcher() {
        if (this.recovery && this.recovery === this.lastEntity)
            this.updateFirstStreamStartDate(this.recovery.startDate);
    }

    @Watch("recovery.endDate")
    recoveryEndWatcher() {
        if (this.recovery && this.recovery === this.lastEntity)
            this.updateLastStreamEndDate(this.recovery.endDate);
    }

    mounted() {
        switch (this.typeOfFlow) {
            case "contract":
                this.typeOfPaymentStreamsFiltered = this.typeOfPaymentStreams.filter(x => x.id != PaymentStreamType.ShareOfPaymentStream);
                break;
            case "costIncome":
                this.typeOfPaymentStreamsFiltered = this.typeOfPaymentStreams.slice(0); // Clone array
                // Add advanced GUI stream type for cost incomes
                this.typeOfPaymentStreamsFiltered.push(<TypeOfPaymentStream>{
                    id: -1,
                    languageId: this.$store.getters.getUser.languageId,
                    name: this.translate('VariableAmountStreamAdvancedGUI')
                })
                break;
            case "recovery":
                this.typeOfPaymentStreamsFiltered = this.typeOfPaymentStreams;
                break;
        }

        this.initialize();
    }

    initialize() {
        switch (this.typeOfFlow) {
            case "contract":
                this.contractPaymentStreams = this.contract!.paymentStreams;
                this.transaction = this.contract!.transaction;
                break;
            case "costIncome":
                this.contractPaymentStreams = this.costIncome!.paymentStreams;
                this.transaction = this.costIncome!.transaction;
                break;
            case "recovery":
                this.contractPaymentStreams = this.recovery!.costIncome.paymentStreams;
                this.transaction = this.recovery!.costIncome!.transaction;
                break;
        }

        this.setActiveStream(this.contractPaymentStreams && this.contractPaymentStreams.length > 0 ? this.contractPaymentStreams[0] : null);

        this.$nextTick(() => {
            (<any>this.$refs).streamsTable.doLayout();
        })
    }

    updateFirstStreamStartDate(startDate: string) {
        if (this.contractPaymentStreams.length === 0 || !startDate) return;

        let firstStream = utils.getFirstStream(this.contractPaymentStreams);
        if (!firstStream.endDate || (this.yearMonthInt(firstStream.endDate) > this.yearMonthInt(startDate))) {
            firstStream.startDate = this.monthStart(startDate);
        }
    }

    updateLastStreamEndDate(endDate: string) {
        if (this.contractPaymentStreams.length === 0 || !endDate) return;
        let lastStream = utils.getLastStream(this.contractPaymentStreams);
        if (!lastStream.endDate) return;
        if (lastStream.startDate && this.yearMonthInt(lastStream.startDate) < this.yearMonthInt(endDate)) {
            lastStream.endDate = this.monthEnd(endDate);
        }
    }

    get calcEnd(): number {
        return (this.selectedStream && this.selectedStream.endDate == null) ? 1 : 0;
    }
    set calcEnd(value: number) {
        if (value == 1) {
            this.tempEndDate = this.selectedStream!.endDate;
            this.selectedStream!.endDate = null;
        }
        else {
            if (this.contract && this.contract.endDate)
                this.selectedStream!.endDate = this.tempEndDate || this.monthEnd(this.contract!.endDate);
            else if (this.selectedStream && this.selectedStream.startDate)
                this.selectedStream!.endDate = this.tempEndDate || this.monthEnd(moment(this.selectedStream.startDate).add(1, "month").toString());
            else
                this.selectedStream!.endDate = this.tempEndDate || this.monthEnd(new Date().toString());
        }
    }

    get typeOfPaymentStream(): PaymentStreamType | null {
        if (this.selectedStream) {
            if (this.isAdvancedExtended(this.selectedStream)) {
                return PaymentStreamType.AdvancedPercentageStreamAdvancedGui;
            } else {
                return this.selectedStream.typeOfPaymentStreamId;
            }
        }
        else {
            return null;
        }
    }
    set typeOfPaymentStream(value: PaymentStreamType | null) {
        if (value)
            this.changeStreamType(value);
    }

    startDateChanged() {
        (<any>this.$refs).streamsTable.sort("startDate", "ascending");
    }

    isAdvancedExtended(stream: PaymentStream): boolean {
        return (stream.typeOfPaymentStreamId === PaymentStreamType.AdvancedPercentageStream &&
            (<AdvancedPercentageIncrementStream>stream).squaremeterPrices.length > 0);
    }

    streamTypeName(stream: PaymentStream): string {
        let translation = "";
        if (this.isAdvancedExtended(stream)) {
            translation = this.translate('VariableAmountStreamAdvancedGUI');
        }
        else {
            translation = this.translateBaseData(stream.typeOfPaymentStreamId, this.baseDataType.TypeOfPaymentStreams)
        }
        return translation;
    }

    formatEndDate(date: string | null): string {
        return date ? this.formatYearMonth(date) : this.translate('CalculationEnd');
    }

    setActiveStream(stream: PaymentStream | null): void {
        this.selectedStream = stream;
        this.$nextTick(() => {
            (<any>this.$refs).streamsTable.setCurrentRow(stream);
        })
    }

    inAdvanceFormatter(data: any) {
        return this.translate(data ? 'Yes' : 'No');
    }

    displayStreamComponent(key: number): boolean {
        return this.selectedStream!.typeOfPaymentStreamId == key;
    }

    streamSelected(value: any) {
        this.selectedStream = value;
    }

    duplicatePreviousStream(): PaymentStream {
        let newStream = undefined;
        
        // clone the last stream
        let lastStream = JSON.parse(JSON.stringify(utils.getLastStream(this.contractPaymentStreams)));

        newStream = lastStream;

        // generate new IDs for the new stream
        newStream.id = this.getNewId(this.contractPaymentStreams);
        newStream.prolongingId = this.getNewId(this.contractPaymentStreams);
        if (newStream.variablePercentages)
            newStream.variablePercentages = [];
        if (newStream.variableAmounts)
            newStream.variableAmounts = [];
        newStream.comment = '';

        let sourceDate = moment(lastStream.endDate ? lastStream.endDate : lastStream.startDate);
        newStream.startDate = sourceDate.add(1, "month").startOf("month").startOf("day").format(DateFormat);
        newStream.endDate = sourceDate.add(1, "month").endOf("month").startOf("day").format(DateFormat);

        if (newStream.firstIncrement) {
            newStream.firstIncrement = sourceDate.add(1, "year").month(0).date(1).format(DateFormat);
        }

        return newStream;
    }

    add(): void {
        let newStream = undefined;
        // No previous streams exists, create new (Index or Shared)
        if (this.contractPaymentStreams.length == 0) {
            newStream = paymentflowService.newStream(<NewStreamProps>{
                context: this,
                newId: this.getNewId(this.contractPaymentStreams),
                typeOfFlow: this.typeOfFlow,
                contract: this.contract,
                recovery: this.recovery,
                costIncome: this.costIncome,
                propertyVersion: this.propertyVersion,
                developmentTypeId: this.propertyVersion.developments.length > 0 ? this.propertyVersion.developments[0].developmentTypeId : null,
                versionPremisesAreas: this.propertyVersion.premisesAreas
            });
        }
        // Streams already exists, create a new stream of the same type as the last stream in the list
        else {
            newStream = this.duplicatePreviousStream();
        }

        this.contractPaymentStreams.push(newStream);
        this.setActiveStream(newStream);
    }

    // Remove any existing streams and add a new stream configured for a vacant contract
    setVacant(contractEndDate: string) {
        if (this.contractPaymentStreams.length > 0) {
            this.contractPaymentStreams.splice(0, this.contractPaymentStreams.length);
        }
        this.add();
        this.changeStreamType(PaymentStreamType.AdvancedPercentageStream);
        const stream = (<AdvancedPercentageIncrementStream> this.selectedStream);
        stream.startAmount = 0;
        stream.endDate = contractEndDate;
        stream.developmentTypeId = null;
        stream.prognosisParameterId = PrognosisParameterEnum.Inflation;
    }

    changeStreamType(newStreamType: PaymentStreamType) {
        const newStream = paymentflowService.changeStreamType(this.selectedStream!, newStreamType, this.propertyVersion, this.typeOfFlow, this.contract, this.recovery, this.costIncome);
        this.contractPaymentStreams.splice(this.contractPaymentStreams.indexOf(<PaymentStream>this.selectedStream), 1, newStream);
        this.setActiveStream(newStream);
    }

    remove() {
        if (!this.selectedStream) return;

        let ix = this.contractPaymentStreams.indexOf(this.selectedStream);

        this.contractPaymentStreams.splice(ix, 1);
        if (this.contractPaymentStreams.length === 0) {
            this.selectedStream = null;
        }
        else {
            if (ix > (this.contractPaymentStreams.length - 1))
                ix = this.contractPaymentStreams.length - 1;
            this.setActiveStream(this.contractPaymentStreams[ix]);
        }
    }

    showCalcEnd(): boolean {
        if (!this.selectedStream || !this.costIncome || this.costIncome.paymentStreams.length === 0) return false;
        return this.selectedStream.id === this.costIncome.paymentStreams[this.costIncome.paymentStreams.length - 1].id;
    }

    canAddStream(): boolean {
        return !this.versionLocked();
    }

    canRemoveStream(): boolean {
        return !this.versionLocked();
    }

    moveStreamDatesLabel() {
        return this.translate('MoveStreamDates').replace('*', this.moveStreamDates.toString()); 
    }

    moveStreamDatesForward() {
        if (!this.contractPaymentStreams || this.contractPaymentStreams.length == 0) {
            return;
        }
        for(let i = 0; i < this.contractPaymentStreams.length; i++) {
            if (this.contract) {
                // If we are moving stream dates for a contract, also change the contract start dates
                if (i == 0) {
                    this.contract.startDate = moment(this.contract.startDate).add(this.moveStreamDates, 'months').startOf('month').format(DateFormat);
                }
                if (i == this.contractPaymentStreams.length-1 && utils.yearMonthEqual(this.contract.endDate, this.contractPaymentStreams[i].endDate)) {
                    this.contract.endDate = moment(this.contract.endDate).add(this.moveStreamDates, 'months').endOf('month').format(DateFormat);
                }
            }

            this.contractPaymentStreams[i].startDate = moment(this.contractPaymentStreams[i].startDate).add(this.moveStreamDates, 'months').startOf('month').format(DateFormat);
            if (this.contractPaymentStreams[i].endDate) {
                this.contractPaymentStreams[i].endDate = moment(this.contractPaymentStreams[i].endDate).add(this.moveStreamDates, 'months').endOf('month').format(DateFormat);
            }
        }
    }

    moveStreamDatesBackwards() {
        if (!this.contractPaymentStreams || this.contractPaymentStreams.length == 0) {
            return;
        }
        for(let i = this.contractPaymentStreams.length-1; i >= 0; i--) {
            if (this.contract) {
                if (i == 0) {
                    this.contract.startDate = moment(this.contract.startDate).subtract(this.moveStreamDates, 'months').startOf('month').format(DateFormat);
                }
                if (i == this.contractPaymentStreams.length-1 && utils.yearMonthEqual(this.contract.endDate, this.contractPaymentStreams[i].endDate)) {
                    this.contract.endDate = moment(this.contract.endDate).subtract(this.moveStreamDates, 'months').endOf('month').format(DateFormat);
                }
            }

            this.contractPaymentStreams[i].startDate = moment(this.contractPaymentStreams[i].startDate).subtract(this.moveStreamDates, 'months').startOf('month').format(DateFormat);
            if (this.contractPaymentStreams[i].endDate) {
                this.contractPaymentStreams[i].endDate = moment(this.contractPaymentStreams[i].endDate).subtract(this.moveStreamDates, 'months').endOf('month').format(DateFormat);
            }
        }
    }
}