import Vue from 'vue';

Vue.filter('capitalize', function (value: any) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('lowercase', function (value: any) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toLowerCase() + value.slice(1)
})

Vue.filter('numeric', function (value: any, decimals: number) {
  return new Intl.NumberFormat(Vue.i18n.locale()!, { style: 'decimal', minimumFractionDigits: decimals, maximumFractionDigits: decimals }).format(value)
})

Vue.filter('unit', function (value: string, unit: string) {
  return value + " " + unit;
})