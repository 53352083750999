import { Component } from "vue-property-decorator";
import BaseComponent from "@/components/base-component";
import UsersComponent from '@/components/admin/users/users';
import GroupsComponent from "./groups/groups";
import { User } from "@/models";

@Component({
    components: {
        UsersComponent,
        GroupsComponent
    }
})
export default class AdminComponent extends BaseComponent {
    currentUser!: User;
    
    activeTab: string = "users";
}