import BaseComponent from "@/components/base-component";
import DataService from "@/services/data-service";
import InlineEdit from "@/helpers/inline-edit";
import { Component, Watch, Prop } from "vue-property-decorator";
import { AxiosPromise, AxiosResponse } from "axios";
import { PrognosisAlternative } from "@/models/PrognosisAlternative";
import { Prognosis } from "@/models/Prognosis";
import { PrognosisParameter } from "@/models/PrognosisParameter";
import { BaseDataType, AppRightEnum } from "@/constants/fia-constants";
import { mapGetters } from 'vuex';
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';

@Component({
    components: {
        YearMonthPickerComponent,
        InputNumericComponent
    },
    computed: mapGetters({
        parameters: "getPrognosisParameters",
        alternatives: "getPrognosisAlternatives"
    })
})
export default class PrognosisComponent extends BaseComponent {
    alternatives!: PrognosisAlternative[];
    parameters!: PrognosisParameter[];
    inactiveParameters: PrognosisParameter[] = [];
    activeParameters: PrognosisParameter[] = [];
    prognosises: Prognosis[] = [];

    prognosisAlternativesGrid: InlineEdit<PrognosisAlternative>;
    prognosisesGrid: InlineEdit<Prognosis>;
    selectedParameter: PrognosisParameter | null = null;
    activeParametersTable: any;

    @Prop()
    layout!: boolean;

    @Watch("layout")
    reloadWatcher() {
        if (this.layout == true) {
            (<any> this).$refs.prognosisAlternativesTable.doLayout();
            (<any> this).$refs.prognosisesTable.doLayout();
            (<any> this).$refs.activeParametersTable.doLayout();
            (<any> this).$refs.parametersTable.doLayout();
        }
    }

    @Watch("prognosisAlternativesGrid.currentItem") 
    async currentItemPrognosisWatcher() {
        if (this.prognosisAlternativesGrid.currentItem) {
            this.setSelectedParameter(null);
            await this.loadPrognoses();
            this.filterParameters();
            await this.prognosisesGrid.reload();
        }
    }

    constructor() {
        super();
        this.prognosisAlternativesGrid = new InlineEdit<PrognosisAlternative>(this, "prognosisAlternativesTable", this.loadPrognosisAlternatives, this.savePrognosisAlternative, this.deletePrognosisAlternative, this.newPrognosisAlternative, undefined, this.hasNoErrors);
        this.prognosisAlternativesGrid.onItemChanged = this.itemChangedProgAlt;
        this.prognosisAlternativesGrid.sync = true;
        this.prognosisAlternativesGrid.readOnly = !this.allowEditAlternatives();
        this.prognosisesGrid = new InlineEdit<Prognosis>(this, "prognosisesTable", this.loadPrognosises, this.savePrognosis, this.deletePrognosis, this.newPrognosis, undefined, this.hasNoErrors);
        this.prognosisesGrid.sync = true;
        this.prognosisesGrid.onItemChanged = this.onPrognosisChanged;
        this.prognosisesGrid.readOnly = !this.allowEditPrognosis();
    }

    async mounted() {
        this.$store.dispatch('setPrognosisLoaded', true);
        this.activeParametersTable = (<any>this.$refs).activeParametersTable;
        await this.prognosisAlternativesGrid.reload();
    }

    async loadPrognoses() {
        if (this.prognosisAlternativesGrid.currentItem) {
            this.prognosises = await DataService.getPrognosesByAlternative(this.prognosisAlternativesGrid.currentItem.entity.id).then(x => x.data);
            await this.prognosisesGrid.reload();
        }
    }

    loadPrognosisAlternatives(): AxiosPromise<PrognosisAlternative[]> {
        return Promise.resolve(<AxiosResponse> { data: this.alternatives ? this.alternatives : [] });
    }

    savePrognosisAlternative(entity: PrognosisAlternative): AxiosPromise<PrognosisAlternative> {
        return DataService.savePrognosisAlternative(entity);
    }

    deletePrognosisAlternative(id: number): AxiosPromise<PrognosisAlternative> {
        return DataService.deletePrognosisAlternative(id);
    }

    newPrognosisAlternative(): PrognosisAlternative {
        return <PrognosisAlternative> { 
            id: 0, 
            name: "", 
            inactive: false, 
            languageId: this.$store.getters.getUser.languageId
        };
    }

    currentPrognosisAlternativeId(): number | null {
        return (this.prognosisAlternativesGrid && this.prognosisAlternativesGrid.currentItem) ? this.prognosisAlternativesGrid.currentItem.entity.id : null;
    }

    loadPrognosises(): AxiosPromise<Prognosis[]> {
        return Promise.resolve(<AxiosResponse> { data: (this.selectedParameter ? this.prognosises.filter(x => x.prognosisParameterId == this.selectedParameter!.id) : [])})
    }

    savePrognosis(entity: Prognosis): AxiosPromise<Prognosis> {
        return DataService.savePrognosis(entity);
    }

    deletePrognosis(id: number): AxiosPromise<Prognosis> {
        return DataService.deletePrognosis(id);
    }

    newPrognosis() {
        return <Prognosis> {
            id: 0,
            development: 0,
            prognosisAlternativeId: this.prognosisAlternativesGrid!.currentItem!.entity.id,
            prognosisParameterId: this.selectedParameter!.id,
            startDate: this.newIsoDateMonth()
        }
    }

    onPrognosisChanged(item: Prognosis, action: string) {
        if (action === 'deleted') {
            if (this.prognosisesGrid.items.length == 0 && this.selectedParameter) {
                this.inactiveParameters.push(this.selectedParameter);
                this.activeParameters.splice(this.activeParameters.indexOf(this.selectedParameter), 1);
                this.selectedParameter = null;
                this.loadPrognoses();
            }
        }
        else if (action === 'added' || action === 'changed') {
            this.loadPrognoses();
        }
    }

    addParameter(item: PrognosisParameter) {
        this.activeParameters.push(item);
        this.inactiveParameters.splice(this.inactiveParameters.indexOf(item), 1);
        this.setSelectedParameter(item);
        this.prognosisesGrid.reload();
    }

    removeParameter(item: PrognosisParameter) {
        this.confirm('ConfirmPrognosisParameterDelete')
            .then(async () => {
                if (this.prognosisesGrid.items.length != 0) {
                    await DataService.deleteByParameterId(item.id, this.prognosisAlternativesGrid.currentItem!.entity.id);
                }
                this.inactiveParameters.push(item);
                this.activeParameters.splice(this.activeParameters.indexOf(item), 1);
                this.selectedParameter = null;
                this.loadPrognoses();                
            }).catch(() => {
                
            });
    }

    filterParameters () {
        let params: number[] = [];
        let activeParams: number[] = [];
        for (let obj of this.prognosises) {
            params.push(obj.prognosisParameterId);
        }
        activeParams = [...new Set(params)];
        this.activeParameters = this.parameters.filter(x => activeParams.findIndex(y => y === x.id) !== -1);
        this.inactiveParameters = this.parameters.filter(x => activeParams.findIndex(y => y === x.id) === -1);
    }

    setSelectedParameter(item: PrognosisParameter | null) {
        this.selectedParameter = item;

        this.$nextTick(() => {
            this.activeParametersTable.setCurrentRow(item);
        })
    }

    parameterSelected(item: PrognosisParameter) {
        this.selectedParameter = item;
        this.prognosisesGrid.reload();
    }

    itemChangedProgAlt() {
        this.$store.dispatch('updateCache', BaseDataType.PrognosisAlternatives);
    }

    allowEditAlternatives() {
        return this.$store.getters.getAppPermission(AppRightEnum.Fia_Basedata_Write);
    }

    allowEditPrognosis() {
        return this.$store.getters.getAppPermission(AppRightEnum.Fia_Prognosis_Write);
    }
}