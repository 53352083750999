import BaseComponent from "@/components/base-component";
import DataService from "@/services/data-service";
import InlineEdit from "@/helpers/inline-edit";
import { Component, Watch, Prop } from "vue-property-decorator";
import { AxiosPromise, AxiosResponse } from "axios";
import { PaymentGroup } from "@/models/PaymentGroup";
import { TypeOfPayment } from "@/models/TypeOfPayment";
import { mapGetters } from "vuex";
import { PaymentGroupEnum, BaseDataType } from '@/constants/fia-constants';
import dataService from '@/services/data-service';

@Component({
    computed: mapGetters({
        typeOfPaymentsList: "getTypeOfPayments"
    })
})
export default class PaymentsComponent extends BaseComponent {
    typeOfPaymentsList!: TypeOfPayment[];

    paymentGroupsGrid: InlineEdit<PaymentGroup>;
    typeOfPaymentsGrid: InlineEdit<TypeOfPayment>;
    selectedType: number = 1;
    typeOfPaymentsOrder: number = 0;
    paymentGroupsOrder: number = 0;

    @Prop()
    reload!: boolean;
    @Prop()
    readOnly!: boolean;

    @Watch("reload")
    reloadWatcher() {
        if (this.reload) {
            (<any>this).$refs.paymentGroupsTable.doLayout();
            (<any>this).$refs.typeOfPaymentsTable.doLayout();
        }
    }

    @Watch("paymentGroupsGrid.currentItem")
    currentItemWatcher() {
        if (this.paymentGroupsGrid.currentItem) {
            this.paymentGroupsOrder = this.paymentGroupsGrid.currentItem.entity.orderNo;
        }

        this.typeOfPaymentsGrid.reload();
    }

    @Watch("typeOfPaymentsGrid.currentItem")
    currentTypeOfPaymentWatcher(value: any) {
        this.typeOfPaymentsOrder = this.typeOfPaymentsGrid.currentItem ? this.typeOfPaymentsGrid.currentItem.entity.orderNo : 0;
    }

    constructor() {
        super();
        this.paymentGroupsGrid = new InlineEdit<PaymentGroup>(this, "paymentGroupsTable", this.loadPaymentGroups, this.savePaymentGroup, this.deletePaymentGroup, this.newPaymentGroup, undefined, this.hasNoErrors);
        this.paymentGroupsGrid.onItemChanged = this.itemChangedPaymentGroup;
        this.paymentGroupsGrid.readOnly = this.readOnly;
        this.typeOfPaymentsGrid = new InlineEdit<TypeOfPayment>(this, "typeOfPaymentsTable", this.loadTypeOfPayments, this.saveTypeOfPayment, this.deleteTypeOfPayment, this.newTypeOfPayment, undefined, this.hasNoErrors);
        this.typeOfPaymentsGrid.onItemChanged = this.itemChangedTypeOfPayment;
        this.typeOfPaymentsGrid.readOnly = this.readOnly;
    }

    mounted() {
        this.paymentGroupsGrid.reload();
        this.typeOfPaymentsGrid.reload();
    }

    loadPaymentGroups(): AxiosPromise<PaymentGroup[]> {
        return DataService.getPaymentGroups();
    }

    savePaymentGroup(entity: PaymentGroup): AxiosPromise<PaymentGroup> {
        return DataService.savePaymentGroup(entity);
    }

    deletePaymentGroup(id: number): AxiosPromise<PaymentGroup> {
        return DataService.deletePaymentGroup(id);
    }

    newPaymentGroup(): PaymentGroup {
        return {
            id: 0,
            name: "",
            orderNo: this.getMaxOrderNo(this.paymentGroupsGrid.items) + 1,
            languageId: this.$store.getters.getUser.languageId
        };
    }

    loadTypeOfPayments(): AxiosPromise<TypeOfPayment[]> {
        if (this.paymentGroupsGrid.currentItem)
            return DataService.getTypeOfPayments().then<AxiosResponse<TypeOfPayment[]>>(x => {
                x.data = x.data.filter(e => e.paymentGroupId == this.paymentGroupsGrid.currentItem!.entity.id);
                return x
            });
        else
            return DataService.getTypeOfPayments();
    }

    saveTypeOfPayment(entity: TypeOfPayment): AxiosPromise<TypeOfPayment> {
        return DataService.saveTypeOfPayment(entity);
    }

    deleteTypeOfPayment(id: number): AxiosPromise<TypeOfPayment> {
        return DataService.deleteTypeOfPayment(id);
    }

    newTypeOfPayment(): TypeOfPayment {
        return {
            id: 0,
            sign: "+",
            orderNo: this.typeOfPaymentsGrid.items.length === 0 ? 1 : this.getMaxOrderNo(this.typeOfPaymentsGrid.items) + 1,
            rentRecovery: false,
            vacansLocked: this.isVacancy() ? false : null,
            inactive: false,
            paymentGroupId: this.paymentGroupsGrid.currentItem!.entity.id,
            vacansOfTypeOfPaymentId: null,
            name: "",
            languageId: this.$store.getters.getUser.languageId,
            typeOfPaymentGroupIds: []
        };
    }

    isVacancy(): boolean {
        if (!this.paymentGroupsGrid.currentItem) return false;
        return this.paymentGroupsGrid.currentItem.entity.id === PaymentGroupEnum.Vacancies;
    }

    getMaxOrderNo(items: any): number {
        return Math.max.apply(Math, items.map((x: any) => x.entity.orderNo))
    }

    typeOrderChange() {
        let currentNumber: number = this.typeOfPaymentsGrid.currentItem!.entity.orderNo;
        let newNumber: number = this.typeOfPaymentsOrder;
        if (isNaN(newNumber) || newNumber === currentNumber || newNumber > this.getMaxOrderNo(this.typeOfPaymentsGrid.items))
            return;

        this.confirm('ConfirmSortOrderChange')
            .then(async () => {
                let currentOrder: TypeOfPayment[] = this.typeOfPaymentsGrid.entities.sort((x, y) => x.orderNo - y.orderNo);
                if (currentNumber < newNumber) {
                    for (let i = currentNumber; i <= newNumber; i++) {
                        currentOrder[i - 1].orderNo--;
                    }
                }
                else {
                    for (let j = currentNumber; j >= newNumber; j--) {
                        currentOrder[j - 1].orderNo++;
                    }
                }
                currentOrder[currentOrder.indexOf(this.typeOfPaymentsGrid.currentItem!.entity)].orderNo = newNumber;
                await dataService.updateTypeOfPaymentOrder(this.typeOfPaymentsGrid.entities);
                await this.typeOfPaymentsGrid.reload();
            }).catch(() => {
            });
    }

    paymentGroupChange() {
        let currentNumber: number = this.paymentGroupsGrid.currentItem!.entity.orderNo;
        let newNumber: number = this.paymentGroupsOrder;
        if (isNaN(newNumber) || newNumber === currentNumber || newNumber > this.getMaxOrderNo(this.paymentGroupsGrid.items))
            return;

        this.confirm('ConfirmSortOrderChange')
            .then(async () => {
                let currentOrder: PaymentGroup[] = this.paymentGroupsGrid.entities.sort((x, y) => x.orderNo - y.orderNo);
                if (currentNumber < newNumber) {
                    for (let i = currentNumber; i <= newNumber; i++) {
                        currentOrder[i - 1].orderNo--;
                    }
                }
                else {
                    for (let j = currentNumber; j >= newNumber; j--) {
                        currentOrder[j - 1].orderNo++;
                    }
                }
                currentOrder[currentOrder.indexOf(this.paymentGroupsGrid.currentItem!.entity)].orderNo = newNumber;
                await dataService.updatePaymentGroupOrder(this.paymentGroupsGrid.entities);
                this.paymentGroupsGrid.reload();
            }).catch(() => {
            });
    }


    paymentGroupsOrderEnabled(): boolean {
        return this.paymentGroupsGrid.currentItem !== null && this.paymentGroupsGrid.currentItem.entity.id > 0;
    }

    typeOfPaymentsOrderEnabled(): boolean {
        return this.typeOfPaymentsGrid.currentItem !== null && this.typeOfPaymentsGrid.currentItem.entity.id > 0;
    }

    newTypeOfPaymentEnabled(): boolean {
        if (!this.paymentGroupsGrid.currentItem) return false;

        let id = this.paymentGroupsGrid.currentItem.entity.id;
        return (id > 0 && id !== PaymentGroupEnum.CashFlow && id !== PaymentGroupEnum.NetOperatingIncome);
    }

    async itemChangedPaymentGroup(paymentGroup: PaymentGroup, action: string) {
        await this.$store.dispatch('updateCache', BaseDataType.PaymentGroups);

        if (action === "delete")
            await this.$store.dispatch('updateCache', BaseDataType.TypeOfPayments);
    }

    itemChangedTypeOfPayment() {
        this.$store.dispatch('updateCache', BaseDataType.TypeOfPayments);
    }
}