import * as signalR from "@microsoft/signalr";
import { SignalRMessage } from '@/models/interfaces/signalR/SignalRMessage';
import store from '../store/store';
import _Vue from 'vue';
import { msalPluginInstance } from "@/plugins/msal-plugin";

declare module "vue/types/vue" {
  interface Vue {
    $signalrHub: _Vue
  }
}

export default {
  install (Vue: typeof _Vue) {
    const reconnectInterval = 5000;
    //Note - in development mode this url points to staging environment. See .env
    const hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(process.env.VUE_APP_SIGNALR_URL as string, {
        accessTokenFactory: async() => (<any> await msalPluginInstance.acquireToken()),
      })
      .configureLogging(signalR.LogLevel.Information)
      .build()

    // Create global Vue event hub for SignalR messaging
    const signalrHub = new Vue()
    Vue.prototype.$signalrHub = signalrHub

    // Hook up connected event
    hubConnection.on('HubMessage', (hubMessage: SignalRMessage) => {
        signalrHub.$emit("HubMessage", hubMessage)
    });

    // Hook up disconnected event
    hubConnection.onclose(() => {
        logState();
        signalrHub.$emit("Disconnected");
        start();
    })

    // SignalR connection function with reconnect feature
    let startedPromise = null
    function start(): Promise<unknown> {
      startedPromise = hubConnection.start().then(() => {
        logState();
        signalrHub.$emit("Connected");
        store.dispatch('setConnectionId', hubConnection.connectionId);
      }).catch(e => {
          console.log(e);
          console.log(`SignalR: connection failed. Reconnecting in ${reconnectInterval} ms`)
          return new Promise((resolve, reject) =>
            setTimeout(() => start().then(resolve).catch(reject), reconnectInterval))
      });
      return startedPromise
    }

    function logState(): void {
        console.log('SignalR ' + hubConnection.state);
    }

    window.addEventListener('beforeunload', () => {
      hubConnection.stop();
    });

    // Start the connection
    start();
  }
}
