import Vue from 'vue';
import { Component, Watch, Prop } from 'vue-property-decorator';
import { Select, Option } from 'element-ui';
import { SelectItem } from '@/models/interfaces/SelectItem';
import { QueryChangeHandler } from 'element-ui/types/select';

@Component({})
export default class ElSelectExtended extends Vue.component('Select', Select) {
    
    select: any;

    @Prop({default: false})
    scrollIntoView!: boolean;

    mounted() {
        this.select = this;
    }

    @Watch("query")
    queryChangeWatcher(query: string) {
        if (!this.filterMethod) {
            this.filter(query);
        }
    }

    @Watch("visible")
    visibilityWatcher() {
        this.select.setInitialScroll();
    }

    @Watch("hoverIndex")
    indexWatcher(newVal: number, oldVal: number) {
        //Prevent reset
        if (newVal === -1 && oldVal)
            this.select.hoverIndex = oldVal;
    }

    setInitialScroll() {
        if (this.select.visible) {
            this.select.$nextTick(() => { 
                setTimeout(() => {
                    let selected = this.select.selected;                
                    if (this.scrollIntoView)
                        selected.$el.scrollIntoView(true);
    
                    // Set the hoveredIndex to the currently selected option
                    for (let i = 0; i < this.select.options.length; i++) {
                        if (this.select.options[i].value == selected.value) {
                            this.select.hoverIndex = i;
                            break;
                        }
                    }
                }, 180);
            });
        }
    }

    filter(query: string) {
        if (!query || query.length == 0) return;
        let firstOptionIdx = -1;

        this.select.options.forEach((x: any, i: number) => {
            let label = typeof x.label === 'string' ? x.label : x.label.toString();
            if (!label || label.length == 0) return;
            x.visible = label.toLowerCase().startsWith(query);
            if (x.visible && firstOptionIdx === -1) {
                //Hover the first item in the filtered list
                firstOptionIdx = i;
                x.$el.scrollIntoView(true);
            }
        });
        this.select.hoverIndex = firstOptionIdx;
    }

}