import BaseComponent from "@/components//base-component";
import DataService from "@/services/data-service";
import { Component, Prop, Watch } from "vue-property-decorator";
import { PropertyVersionSlim } from "@/models/interfaces/PropertyVersionSlim";
import { PropertySearchResultItem } from "@/models/interfaces/PropertySearchResultItem";
import { ContractListItem } from "@/models/interfaces/ContractListItem";
import TableSelectExtension from '@/helpers/table-select-extension';

@Component({})
export default class ContractGetComponent extends BaseComponent {
    @Prop()
    open!: boolean;

    @Prop()
    contractsEnabled!: boolean;

    @Prop()
    multipleContracts!: boolean;

    title: string = "";
    propertyList: PropertySearchResultItem[] = [];
    versionList: PropertyVersionSlim[] = [];
    contractList: ContractListItem[] = [];
    selectedContracts: number[] = [];
    searchTextName: string = "";
    searchTextIdentifier: string = "";
    propertyDataLoading: boolean = false;
    versionDataLoading: boolean = false;
    contractDataLoading: boolean = false;
    selectedProperty: PropertySearchResultItem | null = null;
    selectedVersion: PropertyVersionSlim | null = null;
    select: TableSelectExtension = new TableSelectExtension(this, 'contractsTable');

    @Watch("open")
    openWatcher() {
        if (this.open) {
            this.selectedContracts = [];
            this.title = this.translate(this.contractsEnabled ? "SelectContract" : "SelectPropertyVersion");

            this.$nextTick(() => {
                (<any>this.$refs.searchTextName).$el.children[0].focus();
            });
        }
    }

    searchProperty() {
        this.propertyList = [];
        this.versionList = [];
        this.contractList = [];
        this.propertyDataLoading = true;
        DataService.searchProperties({ name: this.searchTextName, identifier: this.searchTextIdentifier, page: 0, maxRows: 0 })
            .then(x => {
                this.propertyList = x.data.properties;
                this.selectedProperty = null;
                this.selectedVersion = null;
                this.selectedContracts = [];
                this.propertyDataLoading = false;
            });
    }

    propertySelected(property: PropertySearchResultItem) {
        this.selectedProperty = property;
        this.versionDataLoading = true;
        this.versionList = [];
        this.contractList = [];
        DataService.getVersionsByProperty(property.id)
            .then(x => {
                this.versionList = x.data;
                this.selectedVersion = null;
                this.selectedContracts = [];
                this.versionDataLoading = false;
            });
    }

    versionSelected(version: PropertyVersionSlim) {
        this.selectedVersion = version;
        this.contractDataLoading = true;
        this.contractList = [];
        DataService.getContractList(version.id)
            .then(x => {
                this.contractList = x.data;
                this.selectedContracts = [];
                this.contractDataLoading = false;
            })
    }

    cancel() {
        this.close(true);
        this.clear();
    }

    close(canceled: boolean = false) {
        let result = {
            propertyId: canceled ? undefined : this.selectedProperty!.id,
            versionId: canceled ? undefined : this.selectedVersion!.id,
            contracts: (this.contractsEnabled && !canceled) ? this.selectedContracts : undefined
        };
        this.$emit("update:open", false);
        this.$emit("closed", canceled, result);
    }

    async ok() {
        this.close(false);
        this.clear();
    }

    clear() {
        this.propertyList = [];
        this.versionList = [];
        this.contractList = [];
        this.searchTextName = "";
        this.searchTextIdentifier = "";
        this.selectedProperty = null;
        this.selectedVersion = null;
        this.selectedContracts = [];
    }

    canOk(): boolean {
        return (this.contractsEnabled ? (this.multipleContracts ? this.selectedContracts.length > 0 : this.selectedContracts.length === 1) : this.selectedVersion !== null)
    }

    getPropertyListHeightStyle(): string {
        return `height: calc(${this.contractsEnabled ? 33 : 50}% - 4px);`;
    }

    getVersionListHeightStyle(): string {
        return `height: calc(${this.contractsEnabled ? 33 : 50}% - 4px);margin-top: 8px;`;
    }

    contractSelection(items: ContractListItem[], item: ContractListItem) {
        this.select.select(items, item);
        this.selectedContracts = items.map(x => x.id);
    }

    selectContract(item: ContractListItem) {
        (this.$refs as any).contractsTable.toggleRowSelection(item);
        let selectedItems = (this.$refs as any).contractsTable.selection as ContractListItem[];
        this.selectedContracts = selectedItems.map(x => x.id);
    }
}