import BaseComponent from "@/components/base-component";
import { Component, Watch, Prop } from "vue-property-decorator";
import { TaxInformation, PropertyTaxType } from "@/models";
import InlineEdit from '@/helpers/inline-edit';
import { AxiosPromise, AxiosResponse } from 'axios';
import { ValidationError } from '@/models/interfaces/ValidationError';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import ValidationTableFeedbackComponent from '@/components/common/validation-table-feedback/validation-table-feedback';
import InputTextComponent from '@/components/common/input-text/input-text';
import { mapGetters } from 'vuex';
import { TaxTypeCode } from '@/models/TaxTypeCode';
import ValidationErrorComponent from '@/components/common/validation-error/validation-error';

@Component({
    components: {
        InputNumericComponent: InputNumericComponent,
        InputTextComponent: InputTextComponent,
        ValidationTableFeedbackComponent: ValidationTableFeedbackComponent,
        ValidationErrorComponent: ValidationErrorComponent,
    },
    computed: mapGetters({
        taxTypeList: "getTaxTypeCodes"
    })
})
export default class TaxInfoComponent extends BaseComponent {
    @Prop()
    taxInfo!: TaxInformation[];
    @Prop()
    complexErrors!: ValidationError[];
    @Prop()
    isLinked!: boolean;
    @Prop()
    layout!: boolean;

    taxInfosGrid: InlineEdit<TaxInformation>;
    taxTypeList!: TaxTypeCode[];
    propertyTaxTypeList: PropertyTaxType[] = [];
    
    @Watch("taxInfo", { immediate: true })
    taxInfoWatcher() {
        this.taxInfosGrid.reload();
        this.propertyTaxTypeList = this.$store.getters.getActivePropertyTaxTypes();
        this.taxInfosGrid.readOnly = this.isLinked;
    }

    @Watch("layout")
    layoutWatcher() {
        if (this.layout == true) {
            (<any> this).$refs.taxInfosTable.doLayout();
        }
    }

    mounted() {
        this.taxTypeList.sort((a, b) => a.typeNumber - b.typeNumber);
    }

    constructor() {
        super();
        this.taxInfosGrid = new InlineEdit<TaxInformation> (this, "taxInfosTable", this.loadTaxInfos, undefined, undefined, this.newTaxInfo, undefined, this.hasNoErrors);
        this.taxInfosGrid.sync = true;
    }

    loadTaxInfos(): AxiosPromise<TaxInformation[]> {
        return Promise.resolve(<AxiosResponse> { data: this.taxInfo });
    }

    newTaxInfo(): TaxInformation {
        return <TaxInformation> {
            id: this.getNewId(this.taxInfosGrid.entities),
            baseTax: 0,
            propertyTaxTypeId: null,
            taxTypeCodeId: null,
            valueYear: ""
        }
    }

    getTaxTypeNumber(value: number | null): number | null {
        if(value == null) return null;
        const index = this.taxTypeList.map(e => e.id).indexOf(value);
        return this.taxTypeList[index].typeNumber
    }
}