import Component from "vue-class-component";
import BaseComponent from '@/components/base-component';
import { BusMessage, JobAddedMessage } from '@/models/messages/messages';
import dataService from "@/services/data-service";

@Component({})
export default class PortfolioImportComponent extends BaseComponent {
    selectedFileName: string = "";
    portfolioName: string = "";
    versionIdentifier: string = "";
    onlyContracts: boolean = false;
    existingNames: boolean = false;
    uploadData: any = {};
    disableUpload: boolean = false;
    headers: object = {};
    unsupportedFile: boolean = false;

    url(): string {
        return process.env.VUE_APP_FIAAPI_URL + "portfolios/import"
    }

    setUploadData(file: any) {
        return new Promise(async resolve => {
            this.headers = await dataService.getAuthHeaders();

            this.uploadData = {
                portfolioName: this.portfolioName,
                versionIdentifier : this.versionIdentifier,
                contractsOnly : this.onlyContracts,
                existingNames: this.existingNames
            }
            this.$nextTick(() => {
                resolve(file)
            })
        })
    }

    onAdd(file: any) {
        if (file.raw.type != "application/x-zip-compressed") {
            this.$notify.error({
                title: this.translate("Error"),
                message: this.translate("WrongFileType")
            });
            this.unsupportedFile = true;
        }
        else {
            this.unsupportedFile = false;
        }
        this.selectedFileName = file.name;
    }

    onSuccess() {
        (<HTMLFormElement>this.$refs.upload).clearFiles();
        this.$store.dispatch("sendBusMessage", <BusMessage<JobAddedMessage>>{ type: "JobAdded" });
        this.disableUpload = false;
        this.$message({
            message: this.translate('PortfolioImportAddedMessage'),
            type: 'success'
        });
    }

    onError(e: any) {
        this.$notify.error({
            title: this.translate("Error"),
            message: this.translate("PortfolioImportError")
        });
    }

    disableSubmit(): boolean {
        if (this.selectedFileName.length > 0 && this.portfolioName.length > 0 && this.unsupportedFile != true && (this.versionIdentifier.length > 0 || this.existingNames))
            return false;
        return true;
    }

    importPortfolio() {
        this.confirm("ConfirmImportPortfolio").then(() => {
            (<HTMLFormElement>this.$refs.upload).submit();
        }).catch(() => {});
    }
}