import * as msal from "@azure/msal-browser"
import { AuthenticationResult } from "@azure/msal-browser"
import Vue, { PluginObject, VueConstructor } from "vue"

declare module "vue/types/vue" {
  interface Vue {
    $msal: MsalPlugin
  }
}

export interface MsalPluginOptions {
  clientId: string;
  authority: string;
  scopes: string[];
  logLevel: msal.LogLevel;
}

let msalInstance: msal.PublicClientApplication

export let msalPluginInstance: MsalPlugin

export class MsalPlugin implements PluginObject<MsalPluginOptions> {
  private pluginOptions!: MsalPluginOptions

  public install(vue: VueConstructor<Vue>, options?: MsalPluginOptions): void {
    if (!options) {
      throw new Error("MsalPluginOptions must be specified")
    }
    this.pluginOptions = options
    this.initialize(options)
    msalPluginInstance = this
    vue.prototype.$msal = Vue.observable(msalPluginInstance)
  }

  private initialize(options: MsalPluginOptions): void {
    const msalConfig: msal.Configuration = {
      auth: {
        clientId: options.clientId,
        authority: options.authority,
        redirectUri: window.location.origin
      },
      cache: {
        cacheLocation: "localStorage"
      },
      system: {
        loggerOptions: {
          loggerCallback: (level: msal.LogLevel, message: string, containsPii: boolean): void => {
            if (containsPii) {
              return
            }
            switch (level) {
              case msal.LogLevel.Error:
                console.error(message)
                return
              case msal.LogLevel.Info:
                console.info(message)
                return
              case msal.LogLevel.Verbose:
                console.debug(message)
                return
              case msal.LogLevel.Warning:
                console.warn(message)
            }
          },
          piiLoggingEnabled: false,
          logLevel: options.logLevel
        }
      }
    }

    msalInstance = new msal.PublicClientApplication(msalConfig)
  }

  public async signIn(): Promise<void> {
    const loginRequest: msal.RedirectRequest = {
      scopes: this.pluginOptions.scopes
    }
    try {
      await msalInstance.loginRedirect(loginRequest)
    } catch (err: any) {
      console.log(err);
      // IMPORTANT: If redirect login is in progress, wait for the handleRedirectPromise to resolve before returning to the caller
      if (err.errorCode && err.errorCode.indexOf("interaction_in_progress") > -1) {
        await msalInstance.handleRedirectPromise().catch(() => null)
        await new Promise(r => setTimeout(r, 1000)); // WORKAROUND: wait som extra time to make sure the redirect is executed before proceeding
      }
      else {
        console.error(err)
        throw err
      }
    }
  }

  public async signOut(): Promise<void> {
    await msalInstance.logoutRedirect()
  }

  public async handeRedirectPromise(): Promise<AuthenticationResult | null> {
    return msalInstance.handleRedirectPromise();
  }

  public async acquireToken(): Promise<string> {
    const accounts = msalInstance.getAllAccounts().filter(x => x.tenantId === process.env.VUE_APP_AD_TENANT);
    const request: msal.SilentRequest = {
      account: accounts ? accounts[0] : undefined,
      scopes: this.pluginOptions.scopes
    }
    try {
      const response = await msalInstance.acquireTokenSilent(request)
      return response.accessToken
    } catch (error) {
      if (error instanceof msal.InteractionRequiredAuthError) {
        await this.signIn()
      }
    }
    return ""
  }

  public isAuthenticated(): boolean {
    const accounts = msalInstance.getAllAccounts()
    return accounts && accounts.length > 0
  }
}
