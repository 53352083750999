import BaseComponent from "@/components/base-component";
import { Component, Watch, Prop } from "vue-property-decorator";
import InlineEdit from '@/helpers/inline-edit';
import { AxiosPromise, AxiosResponse } from 'axios';
import { mapGetters } from 'vuex';
import { PropertyDetail, Property, PropertyEnvironmentalCertificate, EnvironmentalCertificate, EnvironmentalCertificateLevel } from '@/models';
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import InputTextComponent from '@/components/common/input-text/input-text';
import { ValidationError } from '@/models/interfaces/ValidationError';

@Component({
    components: {
        YearMonthPickerComponent,
        InputNumericComponent,
        InputTextComponent
    },
    computed: mapGetters({
        environmentalCertificates: "getEnvironmentalCertificates",
        environmentalCertificateLevels: "getEnvironmentalCertificateLevels",
    })
})

export default class EnvironmentalCertificatesComponent extends BaseComponent {
    environmentalCertificates!: EnvironmentalCertificate[];
    environmentalCertificateLevels!: EnvironmentalCertificateLevel[];

    @Prop()
    propertyDetail!: PropertyDetail;
    @Prop()
    complexErrors!: ValidationError[];
    @Prop()
    isLinked!: boolean;
    @Prop()
    layout!: boolean;
    @Prop()
    property!: Property;

    environmentalCertificateGrid: InlineEdit<PropertyEnvironmentalCertificate>;

    currentEnvironmentalCertificateId: number;

    @Watch("property", { immediate: true })
    async propertyWatcher() {
        await this.environmentalCertificateGrid.reload();
    }

    @Watch("layout")
    layoutWatcher() {
        if (this.layout == true) {
            (<any> this).$refs.envCertificatesTable.doLayout();
        }
    }

    constructor() {
        super();
        this.environmentalCertificateGrid = new InlineEdit<PropertyEnvironmentalCertificate>(this, "envCertificatesTable", this.loadEnvironmentalCertificates, undefined, undefined, this.newCertificate, undefined, this.hasNoErrors);
        this.environmentalCertificateGrid.sync = true;
        this.currentEnvironmentalCertificateId = 0;
    }

    handleCertificateChange(row: any, newValue: any) {
        const matchingLevels = this.getCertificateLevelsFromCertificate(newValue);
        if (matchingLevels.length > 0) {
          row.entity.environmentalCertificateLevelId = matchingLevels[0].id;
        } else {
          row.entity.environmentalCertificateLevelId = null;
        }
    }

    loadEnvironmentalCertificates(): AxiosPromise<PropertyEnvironmentalCertificate[]> {
        this.environmentalCertificates.sort((a, b) => a.id - b.id);
        const environmentalCertificates = Array.isArray(this.property.propertyEnvironmentalCertificates) ? this.property.propertyEnvironmentalCertificates : [];
    
        environmentalCertificates.forEach(certificate => {
            const level = this.environmentalCertificateLevels.find(level => level.id === certificate.environmentalCertificateLevelId);
            if (level) {
                certificate.environmentalCertificateId = level.environmentalCertificateId;
            }
        });
    
        return Promise.resolve(<AxiosResponse>{ data: environmentalCertificates });
    }

    getCertificateLevelName(id: number) {
        const level = this.environmentalCertificateLevels.find(level => level.id === id);
        return level ? level.name : null;
    }

    getCertificateName(id: number) {
        const certificate = this.environmentalCertificates.find(cert => cert.id === id);
        return certificate ? certificate.name : null;
    }

    getCertificateFromLevel(id: number){
        const level = this.environmentalCertificateLevels.find(level => level.id === id);
        const certificate = level ? this.environmentalCertificates.find(certificate => certificate.id === level?.environmentalCertificateId) : null;
        return certificate ? certificate.id : null;
    }

    newCertificate(): PropertyEnvironmentalCertificate {
        return <PropertyEnvironmentalCertificate>{
            id: this.getNewId(this.environmentalCertificateGrid.entities),
            environmentalCertificateLevelId: 1001,
            comment: "",
            startDate: "",
            environmentalCertificateId: 1,
        }
    }

    getCertificateLevelsFromCertificate(id: number) {
        return this.environmentalCertificateLevels.filter(level => level.environmentalCertificateId === id);
    }

}