import BaseComponent from "@/components/base-component";
import { Component, Watch, Prop } from "vue-property-decorator";
import { PropertyConstructionPlan } from "@/models/PropertyConstructionPlan";
import InlineEdit from '@/helpers/inline-edit';
import { AxiosPromise, AxiosResponse } from 'axios';
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import ValidationErrorComponent from "@/components/common/validation-error/validation-error";
import { ValidationError } from "@/models/interfaces/ValidationError";

@Component({
    components: {
        YearMonthPickerComponent,
        ValidationErrorComponent
    }
})
export default class ConstructionPlanComponent extends BaseComponent {
    @Prop()
    construction!: PropertyConstructionPlan[];
    @Prop()
    isLinked!: boolean;
    @Prop()
    complexErrors!: ValidationError[];

    @Prop()
    layout!: boolean;

    constructionsGrid: InlineEdit<PropertyConstructionPlan>;
    text: string = "";

    @Watch("construction", { immediate: true })
    contructionsWatcher() {
        this.constructionsGrid.reload();
        this.constructionsGrid.readOnly = this.isLinked;
    }

    @Watch("layout")
    layoutWatcher() {
        if (this.layout == true) {
            (<any> this).$refs.constructionsTable.doLayout();
        }
    }

    constructor() {
        super();
        this.constructionsGrid = new InlineEdit<PropertyConstructionPlan> (this, "constructionsTable", this.loadConstructions, undefined, undefined, this.newContruction, undefined, this.hasNoErrors);
        this.constructionsGrid.sync = true;
    }

    loadConstructions(): AxiosPromise<PropertyConstructionPlan[]> {
        return Promise.resolve(<AxiosResponse> { data: this.construction });
    }

    newContruction(): PropertyConstructionPlan {
        return <PropertyConstructionPlan> {
            id: this.getNewId(this.constructionsGrid.entities),
            name: "",
            date: this.newIsoDateMonth(),
            denotation: "",
            text: "",
            type: this.translate('ConstructionPlanTypeList_DetailPlan')
        }
    }
}