import BaseComponent from '@/components/base-component';
import { Prop, Component } from 'vue-property-decorator';
import { CalculationReportSettings } from '@/models/interfaces/CalculationState';
import { User, Currency, Language } from '@/models';
import { mapGetters } from 'vuex';
import { ValidationError } from '@/models/interfaces/ValidationError';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import dataService from '@/services/data-service';
import { Customer } from '@/constants/fia-constants';

interface IReportTemplate {
    id: number;
    name: string;
}
@Component({
    components: {
        InputNumericComponent,
    },
    computed: mapGetters({
        user: "getUser",
        currencies: "getCurrencies",
        languages: "getLanguages",
    })
})
export default class ReportSettingsComponent extends BaseComponent {
    @Prop()
    calculationState!: CalculationReportSettings;
    @Prop()
    isVersion!: boolean;
    @Prop()
    isLocked!: boolean;
    @Prop({default: false})
    isDialog!: boolean;

    complexErrors: ValidationError[] = [];
    reportTemplates: IReportTemplate[] = [];
    currencies!: Currency[];
    languages!: Language[];
    
    user!: User;

    async mounted() {
        try {
            this.reportTemplates = await dataService.getReportTemplates().then(x => x.data);
        } catch (error) {
        }

        this.calculationState.reportTemplateId = this.reportTemplates.length > 0 ? this.reportTemplates[0].id : 0;
    }

    monthlyIncrementEnabled() {
        return true;
    }

    showMonthlyIncrement() {
        return this.user.customerId == Customer.CWFinland;
    }
}