import { AxiosPromise, AxiosResponse } from 'axios';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import BaseComponent from '@/components/base-component';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import InputTextComponent from '@/components/common/input-text/input-text';
import ValidationErrorComponent from '@/components/common/validation-error/validation-error';
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import ConctractRecoveriesEditComponent from '@/components/contract/contract-recoveries-edit/contract-recoveries-edit';
import {
    BaseDataType, FiaServerApi$type, monthList, NewStreamLogic, paymentFrequencies,
    PaymentStreamType, TypeOfPaymentEnum
} from '@/constants/fia-constants';
import InlineEdit from '@/helpers/inline-edit';
import {
    AdvancedPercentageIncrementStream, Contract, CostIncome, PaymentStream, PropertyVersion,
    Recovery, ShareOfPaymentStream, Transaction, TypeOfPayment, User, VariableAmount, VariableAmountIncrementStream
} from '@/models';
import { ValidationError } from '@/models/interfaces/ValidationError';
import { ValidationRule } from "@/models/interfaces/ValidationRule";

@Component({
    components: {
        YearMonthPickerComponent,
        InputNumericComponent,
        ConctractRecoveriesEditComponent,
        InputTextComponent,
        ValidationErrorComponent
    },
    computed: mapGetters({
        propertyVersion: "getPropertyVersion",
        user: "getUser",
    })
})
export default class ContractRecoveriesComponent extends BaseComponent {
    propertyVersion!: PropertyVersion;
    user!: User;

    recoveriesGrid: InlineEdit<Recovery>;
    dialogEditRecovery: Recovery | null = null;
    dialogVisible: boolean = false;
    saving: boolean = false;
    monthList: number[] = monthList;
    frequencyList = paymentFrequencies;
    typeOfPaymentList: TypeOfPayment[] = [];
    shortcutTypes: TypeOfPaymentEnum[] = [
        TypeOfPaymentEnum.TaxRecovery,
        TypeOfPaymentEnum.HeatRecovery,
        TypeOfPaymentEnum.OtherSupplements,
        TypeOfPaymentEnum.RentDiscounts,
        TypeOfPaymentEnum.AdditionForConversion,
        TypeOfPaymentEnum.TurnoverAddition
    ];

    constructor() {
        super();
        this.recoveriesGrid = new InlineEdit<Recovery>(this, "recoveriesTable", this.loadRecoveries, undefined, undefined, this.newRecovery, undefined, this.hasNoErrors);
        this.recoveriesGrid.sync = true;
        this.recoveriesGrid.readOnly = this.versionLocked();
        this.recoveriesGrid.useConfirmDeleteDialog = false;
    }

    @Prop()
    contract!: Contract;

    @Prop()
    complexErrors!: ValidationError[];

    @Watch("contract", { immediate: true })
    contractWatcher() {
        this.recoveriesGrid.reload();
    }

    @Watch("recoveriesGrid.editItem")
    currentRecoveryWatcher() {
        if (this.recoveriesGrid.editItem) {
            let typeOfPaymentId = this.recoveriesGrid.editItem.entity.costIncome.transaction.typeOfPaymentId
            this.typeOfPaymentList = this.$store.getters.getActiveTypeOfPayments([typeOfPaymentId]);
        }
    }

    @Prop()
    reload!: boolean;

    @Watch("reload")
    reloadWatcher() {
        if (this.reload == true) {
            (<any> this).$refs.recoveriesTable.doLayout();
        }
    }

    loadRecoveries(): AxiosPromise<Recovery[]> {
        return Promise.resolve(<AxiosResponse>{ data: this.contract ? this.contract.recoveries : [] });
    }

    filteredRecoveries() {
        return this.recoveriesGrid.items.filter(recovery =>
          recovery.entity.costIncome && recovery.entity.costIncome.transaction &&
          recovery.entity.costIncome.transaction.typeOfPaymentId !== TypeOfPaymentEnum.TenantImprovement
        );
      }

    edit(recovery: Recovery) {
        const item = this.recoveriesGrid.items.find(x => x.entity.id === recovery.id);
        if (item) {
            this.recoveriesGrid.itemSelected(item);
            this.dialogEditRecovery = recovery;
            this.dialogVisible = true;
        }
    }

    checkValidForEdit(): boolean {
        if (this.complexErrors.some(x => x.rule !== ValidationRule.Warning)) {
            this.$message({ message: this.translate("ErrorMessage_RecoveryEditInvalidContract"), type: "error" });
            return false;
        }
        return true;
    }

    async addRecovery() {
        if (!this.checkValidForEdit()) return;
        await this.recoveriesGrid.add();
        if (this.recoveriesGrid.currentItem) {
            this.edit(this.recoveriesGrid.currentItem.entity);
        }
    }

    async handleCommand(shortcut: TypeOfPaymentEnum) {
        let currentDate = new Date();
        let newRecovery: Recovery;
        const recoveryId = this.getNewId(this.recoveriesGrid.entities);
        switch (shortcut) {
            case TypeOfPaymentEnum.TaxRecovery:
                newRecovery = <Recovery>{
                    id: recoveryId,
                    contractId: this.contract.id,
                    startDate: this.monthStart(this.contract.startDate),
                    endDate: this.monthStart(this.contract.endDate),
                    costIncome: <CostIncome> {
                        id: 0,
                        description: this.translate("RecoveryShortcutTypesTax"),
                        recoveryId: 0,
                        transaction: <Transaction>{
                            id: 0,
                            propertyVersionId: this.propertyVersion.id,
                            comment: "",
                            fromSink: false,
                            changedDate: "",
                            typeOfPaymentId: TypeOfPaymentEnum.TaxRecovery,
                        },
                        paymentStreams: [<ShareOfPaymentStream> {
                            $type: FiaServerApi$type.ShareOfPaymentStreamDto,
                            comment: "",
                            endDate: null,
                            id: 0,
                            inAdvance: true,
                            paymentFrequency: 3,
                            paymentGroupId: null,
                            percentage: 0,
                            startDate: this.monthStart(this.contract.startDate),
                            typeOfPaymentId: TypeOfPaymentEnum.PropertyTaxFacilities,
                            typeOfPaymentStreamId: PaymentStreamType.ShareOfPaymentStream,
                        }]
                        }
                    };
                break;
            case TypeOfPaymentEnum.OtherSupplements:
            case TypeOfPaymentEnum.HeatRecovery:
                newRecovery = <Recovery>{
                    id: recoveryId,
                    contractId: this.contract.id,
                    startDate: this.monthStart(this.contract.startDate),
                    endDate: this.monthStart(this.contract.endDate),
                    costIncome: <CostIncome> {
                        id: 0,
                        description: this.translate(shortcut === TypeOfPaymentEnum.HeatRecovery ? "RecoveryShortcutTypesHeat" : "RecoveryShortcutTypesOther"),
                        recoveryId: 0,
                        transaction: <Transaction> {
                            id: 0,
                            propertyVersionId: this.propertyVersion.id,
                            comment: "",
                            fromSink: false,
                            changedDate: this.monthStart(currentDate),
                            typeOfPaymentId: shortcut === TypeOfPaymentEnum.OtherSupplements ? 
                                TypeOfPaymentEnum.OtherSupplements : 
                                TypeOfPaymentEnum.HeatRecovery,
                        },
                        paymentStreams: [<AdvancedPercentageIncrementStream> {
                            $type: FiaServerApi$type.AdvancedPercentageIncrementStreamDto,
                            comment: "",
                            developmentTypeId: 5,
                            endDate: this.monthEnd(new Date(this.contract.endDate)),
                            firstIncrement: this.getContractStreamFirstIncrementDate(),
                            id: 0,
                            inAdvance: true,
                            maintenanceCost: null,
                            margin: 0,
                            monthOfChange: 1,
                            paymentFrequency: 3,
                            prognosisParameterId: null,
                            prolongingId: 0,
                            squaremeterPrices: [],
                            startAmount: 0,
                            startDate: this.monthStart(new Date(this.contract.startDate)),
                            typeOfPaymentStreamId: PaymentStreamType.AdvancedPercentageStream,
                        }]
                    }
                };
                break;
            case TypeOfPaymentEnum.RentDiscounts:
                newRecovery = <Recovery>{
                    id: recoveryId,
                    contractId: this.contract.id,
                    startDate: this.monthStart(this.contract.startDate),
                    endDate: this.monthStart(this.contract.endDate),
                    costIncome: <CostIncome> {
                        id: 0,
                        description: this.translate("RecoveryRentDiscounts"),
                        recoveryId: 0,
                        transaction: <Transaction> {
                            id: 0,
                            propertyVersionId: this.propertyVersion.id,
                            comment: "",
                            fromSink: false,
                            changedDate: this.monthStart(currentDate),
                            typeOfPaymentId: TypeOfPaymentEnum.RentDiscounts,
                        },
                        paymentStreams: [<VariableAmountIncrementStream> {
                            $type: FiaServerApi$type.VariableAmountIncrementStreamDto,
                            comment: "",
                            endDate: this.monthEnd(new Date(this.contract.endDate)),
                            id: 0,
                            inAdvance: true,
                            maintenanceCost: null,
                            margin: 0,
                            paymentFrequency: 3,
                            prolongingId: 0,
                            startDate: this.monthStart(new Date(this.contract.startDate)),
                            typeOfPaymentStreamId: PaymentStreamType.VariableAmountIncrementStream,
                            variableAmounts: [<VariableAmount> {
                                id: 0,
                                amount: 0,
                                startDate: this.monthStart(this.contract.startDate)
                            }]
                        }]
                    }
                };
                break;
            case TypeOfPaymentEnum.AdditionForConversion:
                newRecovery = <Recovery>{
                    id: recoveryId,
                    contractId: this.contract.id,
                    startDate: this.monthStart(this.contract.startDate),
                    endDate: this.monthStart(this.contract.endDate),
                    costIncome: <CostIncome> {
                        id: 0,
                        description: this.translate("RecoveryAdditionForConversion"),
                        recoveryId: 0,
                        transaction: <Transaction> {
                            id: 0,
                            propertyVersionId: this.propertyVersion.id,
                            comment: "",
                            fromSink: false,
                            changedDate: this.monthStart(currentDate),
                            typeOfPaymentId: TypeOfPaymentEnum.AdditionForConversion,
                        },
                        paymentStreams: [<VariableAmountIncrementStream> {
                            $type: FiaServerApi$type.VariableAmountIncrementStreamDto,
                            comment: "",
                            endDate: this.monthEnd(new Date(this.contract.endDate)),
                            id: 0,
                            inAdvance: true,
                            maintenanceCost: null,
                            margin: 0,
                            paymentFrequency: 3,
                            prolongingId: 0,
                            startDate: this.monthStart(new Date(this.contract.startDate)),
                            typeOfPaymentStreamId: PaymentStreamType.VariableAmountIncrementStream,
                            variableAmounts: [<VariableAmount> {
                                id: 0,
                                amount: 0,
                                startDate: this.monthStart(this.contract.startDate)
                            }]
                        }]
                    }
                };
                break;
                case TypeOfPaymentEnum.TurnoverAddition:
                    newRecovery = <Recovery>{
                        id: recoveryId,
                        contractId: this.contract.id,
                        startDate: this.monthStart(this.contract.startDate),
                        endDate: this.monthStart(this.contract.endDate),
                        costIncome: <CostIncome> {
                            id: 0,
                            description: this.translate(shortcut === TypeOfPaymentEnum.TurnoverAddition ? "RecoveryShortcutTypesTurnover" : "RecoveryShortcutTypesOther"),
                            recoveryId: 0,
                            transaction: <Transaction> {
                                id: 0,
                                propertyVersionId: this.propertyVersion.id,
                                comment: "",
                                fromSink: false,
                                changedDate: this.monthStart(currentDate),
                                typeOfPaymentId: TypeOfPaymentEnum.TurnoverAddition
                            },
                            paymentStreams: [<AdvancedPercentageIncrementStream> {
                                $type: FiaServerApi$type.AdvancedPercentageIncrementStreamDto,
                                comment: "",
                                developmentTypeId: 5,
                                endDate: this.monthEnd(new Date(this.contract.endDate)),
                                firstIncrement: this.getContractStreamFirstIncrementDate(),
                                id: 0,
                                inAdvance: true,
                                maintenanceCost: null,
                                margin: 0,
                                monthOfChange: 1,
                                paymentFrequency: 3,
                                prognosisParameterId: null,
                                prolongingId: 0,
                                squaremeterPrices: [],
                                startAmount: 0,
                                startDate: this.monthStart(new Date(this.contract.startDate)),
                                typeOfPaymentStreamId: PaymentStreamType.AdvancedPercentageStream,
                            }]
                        }
                    };
                    break;    
            default:
                throw "Command type not supported";
        }
        this.contract.recoveries.push(newRecovery);
        await this.recoveriesGrid.reload();
        this.recoveriesGrid.itemSelected(this.recoveriesGrid.items[this.recoveriesGrid.items.length - 1]);
    }

    getContractStreamFirstIncrementDate() {
        let firstIncrement = new Date(this.propertyVersion.calculationStart);
        if (firstIncrement < new Date(this.contract!.startDate)) {
            firstIncrement = new Date(this.contract!.startDate);
        }

        switch (firstIncrement.getMonth()) {
            case 0:
                firstIncrement = new Date(firstIncrement.getFullYear(), 0, 1);
            default:
                firstIncrement = new Date(firstIncrement.getFullYear() + 1, 0, 1);
        }

        return this.monthStart(firstIncrement);
    }

    translateCommand(value: TypeOfPaymentEnum) {
        var base: BaseDataType = BaseDataType.TypeOfPayments;
        var trans = this.translateBaseData(value, base);
        return trans;
    }

    async closed(canceled: boolean) {
        if (canceled) {
            await this.recoveriesGrid.reload();
        }
    }

    newRecovery(): Recovery {
        const newStreamLogic: NewStreamLogic = this.user!.userSettings.newStreamLogic;
        return <Recovery>{
            id: this.getNewId(this.recoveriesGrid.entities),
            contractId: this.contract.id,
            startDate: this.monthStart(this.contract.startDate),
            endDate: this.monthStart(this.contract.endDate),
            costIncome: <CostIncome>{
                id: 0,
                description: "",
                recoveryId: 0,
                transaction: <Transaction>{
                    id: 0,
                    propertyVersionId: this.propertyVersion.id,
                    comment: "",
                    fromSink: false,
                    changedDate: "",
                    typeOfPaymentId: newStreamLogic === NewStreamLogic.Finnish ?  TypeOfPaymentEnum.ServiceCharges : TypeOfPaymentEnum.TaxRecovery,
                },
                paymentStreams: []
            }
        }
    }

    closeDialog() {
        this.dialogVisible = false;
    }

    saved(contract: Contract) {
        this.$emit("saved", contract);
    }

    formatBooleanRecoveries(value: any): string {
        return this.translate(value ? "No" : "Yes");
    }

    calcEndChanged(value: boolean, firstStream: PaymentStream) {
        firstStream.endDate = value ? null : this.monthEnd(this.contract.endDate);
    }

    startDateChanged(recovery: Recovery) {
        recovery.startDate = this.monthStart(recovery.costIncome.paymentStreams[0].startDate);
    }

    endDateChanged(recovery: Recovery) {
        if (recovery.costIncome.paymentStreams.length === 1) {  // TODO: check this!
            let firstStream = recovery.costIncome.paymentStreams[0];
            recovery.endDate = firstStream.endDate ? firstStream.endDate : this.monthEnd(this.propertyVersion.calculationEnd);
        }
    }

    canAddRecovery(): boolean {
        return !this.versionLocked();
    }

    getRecoveryErrors(recovery: Recovery) : ValidationError[] {
        return this.complexErrors.filter(e => e.refId === recovery.id || recovery.costIncome.paymentStreams.some(ps => ps.id.toString() === e.refId))
    }
    
    hasStreams(recovery: Recovery): boolean {
        return recovery.costIncome.paymentStreams && recovery.costIncome.paymentStreams.length > 0;
    }
}