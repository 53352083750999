import { Component, Prop } from "vue-property-decorator";
import BaseComponent from "@/components/base-component";
import { User, Database, Customer } from '@/models';
import dataService from '@/services/data-service';
import { mapGetters } from 'vuex';
import { AppRight } from '@/models/AppRight';
import { UserGroup } from "@/models/UserGroup";
import { AppRightEnum } from "@/constants/fia-constants";
import { GroupRight } from "@/models/GroupRight";

@Component({
    computed: mapGetters({
        databaseList: "getDatabases",
        currentUser: "getUser"
    })
})
export default class EditGroupComponent extends BaseComponent {
    databaseList!: Database[];
    currentUser!: User;

    @Prop()
    customer!: Customer;

    @Prop()
    editedGroup!: UserGroup;

    appRights: AppRight[] = [];
    activeDatabases: Database[] = [];
    saving: boolean = false;

    async mounted(): Promise<void> {
        this.appRights = (await dataService.getAppRights().then(x => x.data)).sort((a, b) => a.sortOrder - b.sortOrder);
        this.activeDatabases = [...new Set(this.databaseList)].sort((a, b) => a.name.localeCompare(b.name));
    }

    // Gets the translated display name of the permission
    getPermissionDisplayName(appId: any): string {
        const key = "AppRight_" + appId;
        return this.translate(key);
    }

    // Returns the admin GroupRight object for the specified permission and database
    getAdminGroupRight(appId: number, dbId: number): GroupRight | undefined {
        const adminGroup = this.customer.userGroups.find(x => x.isAdmin);
        return adminGroup ? adminGroup.groupRights.find(x => x.appRightId === appId && x.databaseId === dbId) : undefined;
    }

    // Returns the edited GroupRight object for the specified permission and database
    getGroupRight(appId: number, dbId: number): GroupRight | undefined {
        return this.editedGroup!.groupRights.find(x => x.appRightId === appId && x.databaseId === dbId)
    }

    // Toggles granted flag on the specified permission
    toggleGranted(appId: number, dbId: number) {
        if (!this.canToggle()) return;

        const groupRight = this.getGroupRight(appId, dbId);
        if (groupRight) {
            if (groupRight.granted || this.editedGroup.isAdmin) {
                const ix = this.editedGroup.groupRights.findIndex(x => x.appRightId === appId && x.databaseId === dbId);
                this.editedGroup.groupRights.splice(ix, 1);
            } else {
                groupRight.granted = true;
            }
        } else {
            if (this.getAdminGroupRight(appId, dbId) || this.canToggleAdmin()) {
                this.editedGroup.groupRights.push({
                    id: 0,
                    appRightId: appId,
                    databaseId: dbId,
                    granted: this.editedGroup.isAdmin,  // NOTE: ??
                    userGroupId: this.editedGroup.id
                });
            }
        }
    }
    
    // Returns the permission text css class for the specified permission
    getPermissionTextClass(appRight: AppRight): string {
        if (appRight.isTopLevel) return "perm-top-level"

        let level = -1;
        let ar: AppRight | undefined = appRight;
        while(ar) {
            ar = this.appRights.find(x => ar && x.id === ar.parentId);
            level++;
        }

        return `perm-sub-${level}`;
    }

    // Returns the permission icon css class for the specified permission and database
    getPermissionIconClass(appId: AppRightEnum, dbId: number): string[] {
        const classes: string[] = ["perm-check-icon"];
        const groupRight = this.getGroupRight(appId, dbId);
        const adminGroupRight = this.getAdminGroupRight(appId, dbId);
        if (adminGroupRight) {
            if (groupRight) {
                classes.push("color-green");
                classes.push(groupRight.granted ? "el-icon-success" : "el-icon-circle-check");
            } else {
                classes.push("color-red");
                classes.push("el-icon-error");
            }
        } else {
            classes.push("el-icon-minus");
        }
        return classes;
    }

    // Saves the edited group
    async save() {
        if (this.editedGroup) {
            try {
                this.saving = true;
                const group = await dataService.saveUserGroup(this.editedGroup).then(x => x.data);
                this.$emit("saved", group);
            } catch (error) {
                throw error;
            } finally {
                this.saving = false
            }
        }
    }

    // Cancel any changes to the edited group
    async cancel(): Promise<void> {
        this.$emit("canceled", this.isNew());
    }

    // Deletes the edited group
    async deleteGroup() {
        try {
            await this.confirm("ConfirmDeleteGroup");
        } catch (error) {
            return;
        }
        
        try {
            this.saving = true;
            await dataService.deleteUserGroup(this.editedGroup.id)
            this.$emit("deleted");
        } catch (error) {
            throw error;
        } finally {
            this.saving = false
        }
    }

    isNew(): boolean {
        return this.editedGroup!.id === 0;
    }
    
    isSysAdminGroup(): boolean {
        return this.editedGroup && this.editedGroup.isSysAdmin;
    }

    canSave(): boolean {
        return (this.currentUser.userGroup.isSysAdmin || !this.editedGroup.isAdmin) && 
               (<any>this).errors.items.length === 0 &&
               !this.isSysAdminGroup();
    }

    canCancel(): boolean {
        return (this.currentUser.userGroup.isSysAdmin || !this.editedGroup.isAdmin) &&
               !this.isSysAdminGroup();
    }

    canDelete(): boolean {
        return (this.currentUser.userGroup.isSysAdmin || !this.editedGroup.isAdmin) &&
               !this.isNew() &&
               !this.isSysAdminGroup();
    }

    canToggle(): boolean {
        return this.currentUser.userGroup.isSysAdmin || (this.currentUser.userGroup.isAdmin && !this.editedGroup.isAdmin);
    }

    canToggleAdmin(): boolean {
        return this.currentUser.userGroup.isSysAdmin && this.editedGroup.isAdmin;
    }
}
