import { Prop, Vue, Component } from "vue-property-decorator";
import { Dialog } from 'element-ui';

@Component({})
export default class DialogComponent extends Vue.component('Dialog', Dialog) {

    @Prop()
    allowMove!: boolean;

    @Prop({required: false, default: '100%'})
    declare width: string;

    moving: boolean = false;
    x: number = 0;
    y: number = 0;
    dialogElement!: HTMLElement;
    ms: number = 0;

    onMouseDown(e: MouseEvent) {
        if (!this.allowMove) return;
        e.preventDefault();
        this.x = e.clientX;
        this.y = e.clientY;
        this.dialogElement = (<HTMLElement> this.$refs.dialog);
        this.moving = true;
        let context = this;
        let onMouseUp = function(e: any) {
            window.removeEventListener("onmouseup", onMouseUp);
            context.onMouseUp(e);
        }
        window.onmouseup = onMouseUp;
        window.onmousemove = this.onDrag;
    }

    onDrag(e: MouseEvent) {
        if (!this.moving || !this.dialogElement || (Date.now() - this.ms) < 23) return;
        e.preventDefault();
        let newX = this.x - e.clientX;
        let newY = this.y - e.clientY
        this.x = e.clientX;
        this.y = e.clientY;

        //Using transform matrix to improve performance
        let matrixStr = window.getComputedStyle(this.dialogElement).getPropertyValue('transform');
        let matrix = matrixStr.substring(matrixStr.indexOf("(")+1, matrixStr.indexOf(")")).split(",")
                    .map(x => Number(x));
        this.dialogElement.style.transform = 'translate(' + (matrix[matrix.length-2] - newX) + "px,"+
                                                            (matrix[matrix.length-1] - newY) + "px)";
        
        //Don't let the window translate outside of visible area
        let rect = <any> this.dialogElement.getBoundingClientRect();
        let translateXBound = ((window.innerWidth - rect.width) / 2) + (rect.width/2);
        let translateYBound = ((window.innerHeight - rect.height) / 2) + (rect.height/2);

        if (rect.x + (rect.width/2) > window.innerWidth)
            this.dialogElement.style.transform = 'translate('+translateXBound+'px, ' + (matrix[matrix.length-1] - newY) + 'px)';
        else if (rect.y + (rect.height/2) >= window.innerHeight)
            this.dialogElement.style.transform = 'translate('+ (matrix[matrix.length-2] - newX) +'px, ' + (translateYBound) + 'px)';
        if  (rect.y + (rect.height/2) < 0)
            this.dialogElement.style.transform = 'translate('+ (matrix[matrix.length-2] - newX )+'px, ' + (-translateYBound) + 'px)';
        else if (rect.x + (rect.width/2) < 0)
            this.dialogElement.style.transform = 'translate('+(-translateXBound)+'px, ' + (matrix[matrix.length-1] - newY) + 'px)';

        this.ms = Date.now();
    }

    onMouseUp(e: MouseEvent) {
        this.moving = false;
    }
    
    onClose(e : any) {
        if (this.dialogElement) {
            this.dialogElement.style.transform = 'translate(0px, 0px)';
        }
    }
    

}
