export const DateFormat: string = "YYYY-MM-DDTHH:mm:ss";
export const YearMonthFormat: string = "YYYY-MM";
export const YearMonthDayFormat: string = "YYYY-MM-DD";

export const DefaultDeductingExpenses: number = 0.03;

export enum AppRightEnum {
    None = 0,
    Fia = 6,
    Fia_Write = 18,
    Fia_Map = 14,
    Fia_Metria = 17,
    Fia_Prognosis = 16,
    Fia_Prognosis_Write = 19,
    Fia_Basedata_Read = 20,
    Fia_Basedata_Write = 21,
    ImportExport = 2,
    ImportExport_CopyPortfolioTo = 15,
    VDok = 3,
    VDokEditor = 4
}

export enum FiaServerApi$type {
    AdvancedPercentageIncrementStreamDto = "Fia.WebAPI.Models.AdvancedPercentageIncrementStreamDto, Fia.WebAPI",
    BasicPercentageStreamDto = "Fia.WebAPI.Models.BasicPercentageStreamDto, Fia.WebAPI",
    IndexIncrementStreamDto = "Fia.WebAPI.Models.IndexIncrementStreamDto, Fia.WebAPI",
    ShareOfPaymentStreamDto = "Fia.WebAPI.Models.ShareOfPaymentStreamDto, Fia.WebAPI",
    VariableAmountIncrementStreamDto = "Fia.WebAPI.Models.VariableAmountIncrementStreamDto, Fia.WebAPI",
}

export enum ErrorType {
    ValidationError = "validation-error",
}

export enum LanguageEnum {
    Swedish = 1,
    English = 2,
    Danish = 3,
    Finish = 4
}

export enum NewStreamLogic {
    Default = 0,
    Finnish = 1
}

export enum PaymentStreamType {
    AdvancedPercentageStream = 4711,
    AdvancedPercentageStreamAdvancedGui = -1,  //Type does not exist in db, only in GUI
    VariableAmountIncrementStream = 4712,
    IndexIncrementStream = 4713,
    BasicPercentageStream = 4714,
    ShareOfPaymentStream = 4715,
    PropertyTaxStream = 4716
}

export enum ProlongingType {
    UntilCalculationEnd = 4711,
    UntilStreamEnd = 4712,
    UseMarketRent = 4713
}

export enum PrognosisParameterEnum {
    Inflation = 1
}

export enum JobType {
    ImportContracts = 1,
    CopyPortfolio,
    ExportPortfolio,
    ImportPortfolio,
    Calculation,
    CalculationDetailed,
    CalculationPortfolio,

    ValuationReport = 10000,
    CalculationReport,
    Ipd6NotReadyReport,
    Ipd4Report,
    Ipd6Report,
    KeyRatiosReport,
    PropertyAddressListReport,
    PortfolioDetailsReport,
    ContractsToExcelReport,
    KeyRatiosShortReport,
    SensitivityAnalysisReport,
    KeyRatiosExtendedReport,
    ComparisonReport
}

export enum JobStatus {
    Pending = 1,
    InProgress = 2,
    Canceled = 3,
    Finished = 4,
    Failed = 5
}

export enum JobIdentityType {
    None,
    PropertyVersion,
    Portfolio
}

export enum BaseDataType {
    All = 1,
    Currencies = 2,
    DevelopmentTypes = 3,
    PrognosisAlternatives = 4,
    Countries = 5,
    Counties = 6,
    Subcounties = 7,
    PremisesTypes = 8,
    MarketTypes = 9,
    TypeOfPayments = 10,
    PropertyTaxTypes = 11,
    PrognosisParameters = 12,
    IndexDenotations = 13,
    IndexValues = 14,
    IndexClauses = 15,
    Ipd6 = 16,
    Statuses = 17,
    PaymentGroups = 18,
    Language = 19,
    TaxTypeCodes = 20,
    TypeOfPaymentStreams = 21,
    PremisesTypeGroups = 22,
    InterestTypes = 23,
    AmortizationTypes = 24,
    TypeOfPaymentGroups = 25,
}

export enum PaymentGroupEnum {
    Invalid = -1,
    NetOperatingIncome = 1,
    CashFlow = 2,
    Rents = 7,
    Investments = 8,
    Costs = 9,
    Vacancies = 11,
    FinancialPayments = 14
}

export enum RentTypeEnum {
    Commercial = 7, // Lokaler
    GarageParking = 13,
    Hotel = 50,
    Office = 51,
    Others = 54, // Övriga
    Residential = 6,
    Restaurant = 107,
    Retail = 52,
    Storage = 70 // Industri
}

export enum PremisesTypesEnum {
    Commercial = 31, // Lokaler
    GarageParking = 9,
    Hotel = 6,
    Office = 2,
    Others = 12, // Övriga
    Residential = 1,
    Restaurant = 5,
    Retail = 3,
    Storage = 8, // Industri
}

export enum IpdReports {
    NotReadyProperties = 1,
    Ipd4 = 2,
    KeyRatios = 3,
    Ipd6 = 4,
    PropertyAddressList = 5,
    KeyRatiosCompact = 6

}

export enum IndexClauseEnum {
    NewClause = 2
}


export enum IndexDenotationEnum {
    Kpi = 1
}

export enum CalculationType {
    Normal = 1,
    Detailed = 2
}

export enum ReportType {
    Valuation = 1,
    Calculation = 2,
    Ipd6NotReady = 3,
    Ipd4 = 4,
    Ipd6 = 5,
    KeyRatios = 6,
    PropertyAddressList = 7,
    PortfolioDetails = 8,
    ContractsToExcel = 9,
    KeyRatiosShort = 10,
    ValuationNew = 11,
    SensitivityAnalysis = 12,
    KeyRatiosExtended = 13,
    Comparison = 14
}

export enum QueueStatus {
    Pending = 0,
    Started = 1,
    Finished = 2,
    Failed = 3,
    Canceled = 4
}

export enum QueuePriority {
    Normal = 0,
    High = 1
}

export enum Ipd6Enum {
    TurnoverBasedRent = 1,
    SteppedRent = 2,
    IndexIncrementRent = 3,
    Other = 4,
    Vacant = 5
}

export enum TypeOfPaymentEnum {
    PropertyTaxResidential = 11,
    PropertyTaxFacilities = 12,
    RentDiscounts = 15,
    HeatRecovery = 16,
    TaxRecovery = 17,
    LeaseholdFee = 21,
    AdditionForConversion = 23,
    OtherSupplements = 24,
    Investment = 25,
    PropertyTaxIndustrial = 34,
    RentOffice = 51,
    TurnoverAddition = 57,
    TenantImprovement = 71,
    PropertyTax = 93,
    ServiceCharges = 97,
    VacancyOffice = 98,
    CapEx = 105,
}

export const paymentFrequencies = [1, 2, 3, 4, 6, 12];

export const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const appRightTopLevel = [AppRightEnum.Fia, AppRightEnum.ImportExport, AppRightEnum.VDok, AppRightEnum.VDokEditor];

export enum EditVersionDialogMode {
    Edit,
    Add,
    CopyToSame,
    CopyToOther
}

export enum ActivityType {
    ContractImport = 1,
    PortfolioCopy = 2
}

export enum ActivityStatus {
    Pending = 1,
    InProgress = 2,
    Canceled = 3,
    Finished = 4,
    Failed = 5
}

export enum AmortizationTypeEnum {
    Fixed = 4711,
    Percentual = 4712
}

export enum InterestTypeEnum {
    Fixed = 4711,
    Dependent = 4712,
    Bound = 4713
}

export enum CountriesEnum {
    Sweden = 3,
    USA = 4,
    Denmark = 7,
    England = 9,
    Finland = 11,
    Norway = 13,
    Switzerland = 15,
    Netherlands = 17,
    Latvia = 19,
    Germany = 21,
    Lithuania = 25,
    Estonia = 29,
    Poland = 32
}
export enum TypeOfPaymentGroupEnum {
    Contract = 5,
    Taxes = 6,
}

export enum BaseDataStatus {
    Let = 1,
    Vacant = 2,
    Internal = 3,
    LetTenantMoved = 4,
    LetNotMovedIn = 5,
    LetNoRentInsolvency = 6,
    VacantRefurbishmentsTakingPlace = 7,
    VacantLongTerm = 8,
    InternallyLet = 9,
    VacantLongerTerm = 10,
}

export enum ValuationModuleDisplayEnum {
    Always = 1,
    IfData = 2,
    IfAllData = 3,
    IfPrelAssessment = 4,
    IfPreviousModuleWritten = 5,
    IfPreviousModuleNotWritten = 6,
}

export enum ValuationModulePageBreakEnum {
    Both = 11,
    Before = 19,
    After = 91,
    None = 99
}

export enum ValuationModuleTypeEnum {
    Normal = 1,
    PageFooter = 2,
    IndexAppendix = 3,
    FrontPage = 4,
    PageHeader = 5,
    Signature = 6,
    FrontPageNoSummary = 7
}

export enum ValuationModulePictureTypeEnum {
    FrontPage = 0,
    Photo = 1,
    Map = 2,
    Zoning = 3,
    ValuerFirst = 4,
    ValuerSecond = 5,
    MapWithBorder = 6,
    SiteMap = 7,
    FloorPlan = 8,
    Interior1 = 9,
    Interior2 = 10,
    EnvironmentalImpact = 11
}

export enum ValuationModuleGraphTypeEnum {
    NumberOfContracts = 1,
    Percentage = 2,
    Operation = 3,
    AreaDistribution = 4,
    OperationNet = 5,
    StreamRate = 6,
    AreaDistributionVacancy = 7,
    FutureEconomicVacancy = 8,
    ContractedAssessedRent = 9
}

export enum Customer {
    FiaSoftware = 0,
    CWSweden = 1,
    CWFinland = 2,
    Forum = 3,
    Klovern = 4,
    Vasakronan = 5
}

export enum FileType {
    None = 1,
    Excel = 2,
    Word = 3
}

export enum SearchTab {
    PropertySearch = 'propertySearch',
    PortfolioSearch = 'portfolioSearch',
    ContractSearch = 'contractSearch',
    Loan = 'loan'
}

export enum ServiceError{
    Unknown = 'Unknown',
    CalculationRequired = 'CalculationRequired',
    RecalculationRequired = 'RecalculationRequired',
}

export enum PropertyEnergyClassType {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    F = 'F',
    G = 'G',
}
