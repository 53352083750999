import BaseComponent from "@/components/base-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import DataService from "@/services/data-service";
import InlineEdit from "@/helpers/inline-edit";
import { AxiosPromise } from "axios";
import { DevelopmentType } from "@/models/DevelopmentType";
import { BaseDataType } from "@/constants/fia-constants";
import InputNumericComponent from "@/components/common/input-numeric/input-numeric";

@Component({
    components: {
        InputNumericComponent: InputNumericComponent
    }
})
export default class DevelopmentsComponent extends BaseComponent {
    developmentsGrid: InlineEdit<DevelopmentType>;

    @Prop()
    reload!: boolean;
    @Prop()
    readOnly!: boolean;

    @Watch("reload")
    reloadWatcher() {
        if (this.reload == true) {
            (<any> this).$refs.developmentsTable.doLayout();
        }
    }

    constructor() {
        super();
        this.developmentsGrid = new InlineEdit<DevelopmentType>(this, "developmentsTable", this.loadDevelopments, this.saveDevelopment, this.deleteDevelopment, this.newDevelopment, undefined, this.hasNoErrors);
        this.developmentsGrid.onItemChanged = this.itemChanged;
        this.developmentsGrid.readOnly = this.readOnly;
    }

    mounted() {
        this.developmentsGrid.reload();
    }

    loadDevelopments(): AxiosPromise<DevelopmentType[]> {
        return DataService.getDevelopmentTypes();
    }

    saveDevelopment(entity: DevelopmentType): AxiosPromise<DevelopmentType> {
        return DataService.saveDevelopmentType(entity);
    }

    deleteDevelopment(id: number): AxiosPromise<DevelopmentType> {
        return DataService.deleteDevelopmentType(id);
    }

    newDevelopment(): DevelopmentType {
        return <DevelopmentType> { 
            id: 0, 
            name: "", 
            inactive: false, 
            languageId: this.$store.getters.getUser.languageId 
        };
    }
    
    itemChanged() {
        this.$store.dispatch('updateCache', BaseDataType.DevelopmentTypes);
    }
}
