import BaseComponent from "@/components/base-component";
import { Component, Prop } from "vue-property-decorator";
import { PercentageIncrementStream, PropertyVersion, PremisesType } from "@/models";
import { ValidationError } from "@/models/interfaces/ValidationError";
import { monthList, PaymentStreamType } from '@/constants/fia-constants';
import { PropOptions } from 'vue';
import { mapGetters } from 'vuex';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';

@Component({
    components: {
        YearMonthPickerComponent,
        InputNumericComponent
    },
    computed: mapGetters({
        propertyVersion: "getPropertyVersion",
        premisesTypes: "getPremisesTypes"
    })
})
export default class BaseValuesComponent extends BaseComponent {
    propertyVersion!: PropertyVersion;
    premisesTypes!: PremisesType[];

    @Prop()
    complexErrors!: ValidationError[];
    @Prop()
    stream!: PercentageIncrementStream;
    @Prop(<PropOptions>{ default: false, require: false })
    startAmountUpdated!: boolean;
    @Prop()
    typeOfFlow!: string;
    @Prop()
    disableStartAmount!: boolean;

    monthList = monthList;

    getPremisesTypeName(premisesTypeId: number): string {
        let pt = this.premisesTypes.find(x => x.id === premisesTypeId);
        return pt ? pt.name : "?";
    }

    getArea(): number {
        let pa = this.propertyVersion.premisesAreas.find(x => x.premisesTypeId === this.stream.maintenanceCost!.premisesTypeId);
        return pa ? pa.area : 0;
    }

    updateStartAmount() {
        this.stream.startAmount = this.stream.maintenanceCost!.costPerSquaremeter * this.getArea();
    }

    showMaintenanceCost(): boolean {
        return this.typeOfFlow === 'costIncome' && this.stream.typeOfPaymentStreamId === PaymentStreamType.BasicPercentageStream;
    }
}