import BaseComponent from "@/components/base-component";
import { Component, Watch, Prop } from "vue-property-decorator";
import { ReportType, JobIdentityType } from "@/constants/fia-constants";
import { mapGetters } from "vuex";
import { Currency } from "@/models/Currency";
import { PropertyReadyInfo } from "@/models/interfaces/PropertyReadyInfo";
import dataService from "@/services/data-service";
import { AddIpd6NotReadyRequest } from "@/models/interfaces/request/AddIpd6NotReadyRequest";
import { User, Language, Portfolio } from "@/models";
import { AddIpd4Request } from "@/models/interfaces/request/AddIpd4Request";
import { AddPropertyAddressListRequest } from "@/models/interfaces/request/AddPropertyAddressListRequest";
import { BusMessage, ReportAddedMessage } from '@/models/messages/messages';
import { AddKeyRatiosRequest } from '@/models/interfaces/request/AddKeyRatiosRequest';
import { AddIpd6Request } from '@/models/interfaces/request/AddIpd6Request';
import { ElTable } from 'element-ui/types/table';
import { AddSensitivityAnalysisRequest } from "@/models/interfaces/request/AddSensitivityAnalysisRequest";
import { AddKeyRatiosExtendedRequest } from "@/models/interfaces/request/AddKeyRatiosExtendedRequest";
import PortfolioSelectComponent from "../portfolio/portfoliosearch/portfolio-select";

interface reportListViewModel {
    id: ReportType;
    name: string;
    readyOnly: boolean;
    notReadyOnly: boolean;
}

@Component({
    components: {
        PortfolioSelectComponent
    },
    computed: mapGetters({
        currencyList: "getCurrencies",
        languageList: "getLanguages",
        connectionId: "getConnectionId"
    })
})
export default class IpdReportsComponent extends BaseComponent {
    currencyList!: Currency[];
    languageList!: Language[];
    connectionId!: string;

    @Prop()
    open!: boolean;
    @Prop()
    dataId!: number;
    @Prop()
    isPortfolio!: boolean;
    
    readyInfo = <PropertyReadyInfo>{ ready: 0, notReady: 0 };
    selectedReports: reportListViewModel[] = [];
    selectedDate: string = this.formatIsoDate(new Date());
    selectedCurrency: number = 1;
    selectedLanguage: number = 1;
    activeReportList: reportListViewModel[] = [];
    dialogTitle: string = "";
    user: User = this.$store.getters.getUser;
    comparedPortfolio: Portfolio | null = null;

    reportList: reportListViewModel[] = [
        { id: ReportType.Ipd6NotReady, name: this.translate("ReportTypeIpd6NotReady"), readyOnly: false, notReadyOnly: true },
        { id: ReportType.Ipd4, name: this.translate("ReportTypeIpd4"), readyOnly: true, notReadyOnly: false },
        { id: ReportType.Ipd6, name: this.translate("ReportTypeIpd6"), readyOnly: true, notReadyOnly: false },
        { id: ReportType.KeyRatios, name: this.translate("ReportTypeKeyRatios"), readyOnly: true, notReadyOnly: false },
        { id: ReportType.KeyRatiosShort, name: this.translate("ReportTypeKeyRatiosShort"), readyOnly: true, notReadyOnly: false },
        { id: ReportType.KeyRatiosExtended, name: this.translate("ReportTypeKeyRatiosExtended"), readyOnly: true, notReadyOnly: false },
        { id: ReportType.SensitivityAnalysis, name: this.translate("ReportTypeSensitivityAnalysis"), readyOnly: true, notReadyOnly: false },
        { id: ReportType.PropertyAddressList, name: this.translate("ReportTypePropertyAddressList"), readyOnly: false, notReadyOnly: false }

    ];

    @Watch("open")
    openWatcher() {
        if (this.open) {
            this.dialogTitle = this.translate("IPD") + " - " + this.translate(this.isPortfolio ? "Portfolio" : "PropertyVersion");
            this.refresh();
        }
    }

    mounted() {
        if (!this.isPortfolio) {
            this.reportList.splice(this.reportList.findIndex(x => x.id == ReportType.PropertyAddressList), 1);
        }
        let lang = this.languageList.find(x => x.languageId == this.user.languageId);
        this.selectedLanguage = lang ? lang.languageId : 1;
    }

    async refresh() {
        try {
            if (this.isPortfolio) {
                this.readyInfo = await dataService.getPropertyReadyPortfolio(this.dataId).then(x => x.data);
            }
            else {
                this.readyInfo = await dataService.getPropertyReadyVersion(this.dataId).then(x => x.data);
            }
        } catch (error) {
            return Promise.reject(new Error(""));
        }

        this.activeReportList = this.reportList.filter(x => (!x.notReadyOnly || this.readyInfo.notReady > 0) && (!x.readyOnly || this.readyInfo.ready > 0));
    }
    selectionChange(items: any) {
        this.selectedReports = items;
    }

    rowClick(item: reportListViewModel){
        let table = (<ElTable> this.$refs.reportsTable);
        if (!table) return;
        table.toggleRowSelection(item);
    }

    cancel() {
        this.close(true);
    }

    close(canceled: boolean = false) {
        this.$emit("update:open", false);
        this.$emit("closed", canceled);
    }

    dateEnabled(): boolean {
        var items = this.selectedReports;
        for (var item of items) {
            if (item.id == ReportType.Ipd4 ||
                item.id == ReportType.KeyRatios ||
                item.id == ReportType.KeyRatiosShort)
                return true;
        }
        return false;
    }

    currencyEnabled(): boolean {
        var items = this.selectedReports;
        for (var item of items) {
            if (item.id === ReportType.Ipd4 ||
                item.id === ReportType.Ipd6 ||
                item.id === ReportType.KeyRatiosShort ||
                item.id === ReportType.KeyRatios ||
                item.id === ReportType.KeyRatiosExtended
            )
                return true;
        }
        return false;
    }

    async sendRequest(report: reportListViewModel) {
        try {
            switch (report.id) {
                case ReportType.Ipd6NotReady:
                    let req1: AddIpd6NotReadyRequest = {
                        languageId: this.selectedLanguage,
                        show: true,
                        identityType: this.isPortfolio ? JobIdentityType.Portfolio : JobIdentityType.PropertyVersion,
                        identityId: this.dataId.toString()
                    }
    
                    await dataService.addIpdNotReadyReport(req1);
                    break;
                case ReportType.Ipd4:
                    let req2: AddIpd4Request = {
                        languageId: this.selectedLanguage,
                        currencyId: this.selectedCurrency,
                        show: true,
                        identityType: this.isPortfolio ? JobIdentityType.Portfolio : JobIdentityType.PropertyVersion,
                        identityId: this.dataId.toString(),
                        valuationDate: this.selectedDate,
                    }
    
                    await dataService.addIpd4Report(req2);
                    break;
                case ReportType.Ipd6:
                    let req3: AddIpd6Request = {
                        languageId: this.selectedLanguage,
                        currencyId: this.selectedCurrency,
                        show: true,
                        identityType: this.isPortfolio ? JobIdentityType.Portfolio : JobIdentityType.PropertyVersion,
                        identityId: this.dataId.toString(),
                        valuationDate: this.selectedDate
                    }
    
                    await dataService.addIpd6Report(req3);
                    break;
                case ReportType.KeyRatios:
                case ReportType.KeyRatiosShort:
                    let req4: AddKeyRatiosRequest = {
                        languageId: this.selectedLanguage,
                        currencyId: this.selectedCurrency,
                        show: true,
                        identityType: this.isPortfolio ? JobIdentityType.Portfolio : JobIdentityType.PropertyVersion,
                        identityId: this.dataId.toString(),
                        valuationDate: this.selectedDate,
                        type: report.id === ReportType.KeyRatiosShort ? "compact" : ReportType.KeyRatiosExtended ? "extended" : "",
                        colorful: this.user.userSettings.reportSettings.colorful,
                        comparedIdentityId: this.comparedPortfolio ? this.comparedPortfolio.id : null
                    }
    
                    await dataService.addKeyRatiosReport(req4);
                    break;
                case ReportType.PropertyAddressList:
                    let req5: AddPropertyAddressListRequest = {
                        languageId: this.selectedLanguage,
                        show: true,
                        identityType: this.isPortfolio ? JobIdentityType.Portfolio : JobIdentityType.PropertyVersion,
                        identityId: this.dataId.toString(),
                    }
                    await dataService.addPropertyAddressListReport(req5);
                    break;
                case ReportType.SensitivityAnalysis:
                    let req6: AddSensitivityAnalysisRequest = {
                        languageId: this.selectedLanguage,
                        currencyId: this.selectedCurrency,
                        show: true,
                        identityType: this.isPortfolio ? JobIdentityType.Portfolio : JobIdentityType.PropertyVersion,
                        identityId: this.dataId.toString(),
                        valuationDate: this.selectedDate,
                        type: "",
                        colorful: this.user.userSettings.reportSettings.colorful,
                    }
    
                    await dataService.addSensitivityAnalysisReport(req6);
                    break;
                case ReportType.KeyRatiosExtended:
                    let req7: AddKeyRatiosExtendedRequest = {
                        languageId: this.selectedLanguage,
                        currencyId: this.selectedCurrency,
                        show: true,
                        identityType: this.isPortfolio ? JobIdentityType.Portfolio : JobIdentityType.PropertyVersion,
                        identityId: this.dataId.toString(),
                        valuationDate: this.selectedDate,
                        type:"extended",
                        colorful: this.user.userSettings.reportSettings.colorful,
                        comparedIdentityId: this.comparedPortfolio ? this.comparedPortfolio.id : null
                    }
                    await dataService.addKeyRatiosExtendedReport(req7);
                    break;
                default:
            }   
        } catch (error) {
            return Promise.reject(new Error(""));
        } finally {
            this.comparedPortfolio = null;
        }
    }

    async generateReport() {
        let user: User = this.$store.getters.getUser;
        if (this.$store.getters.getUserSettingsHasChanged) {
            user.userSettings = await dataService.saveUserSettings(user.userSettings).then(x => x.data);
        }
        
        for (let report of this.selectedReports) {
            await this.sendRequest(report);
        }

        await this.$store.dispatch("sendBusMessage", <BusMessage<ReportAddedMessage>>{ type: "ReportAdded" });
        this.close();
    }

    canBeCompared() {
        return this.isPortfolio && this.selectedReports.some(x => x.id == ReportType.KeyRatios || x.id == ReportType.KeyRatiosShort);
    }

    onPortfolioSelected(portfolio: Portfolio) {
        this.comparedPortfolio = portfolio;
    }
}