import { Component, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";
import Utils from "@/helpers/utils";
import dataService from "@/services/data-service";
import { Currency, PropertyVersion, Job, VariableAmount } from "@/models";
import { CurrencyUnit } from "@/components/web-reports/rent-composition/rent-composition";
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import BaseComponent from "@/components/base-component";
import { ContractRecoveriesAdjustment } from "@/models/ContractRecoveriesAdjustment";
import { ContractListItem } from "@/models/interfaces/ContractListItem";
import { RecoveryAdjustment } from "@/models/RecoveryAdjustment";
import moment from "moment";
import { DateFormat, YearMonthFormat } from "@/constants/fia-constants";
import { CurrencyHelper } from "@/helpers/currency";
import { ElTableColumn } from "element-ui/types/table-column";
import { GetContractRecoveriesAdjustmentRequest } from "@/models/interfaces/request/GetContractRecoveriesAdjustmentRequest";
import { BusMessage, RecoveriesChangedMessage, PerformCalculationMessage } from "@/models/messages/messages";
import { ErrorHandling } from "@/helpers/error-handling";
import { AxiosError } from "axios";
import { SignalRMessage } from "@/models/interfaces/signalR/SignalRMessage";
import { MessageType } from "@/models/interfaces/signalR/MessageType";
import InputDateComponent from "@/components/common/input-date/input-date";

@Component({
    computed: mapGetters({
        propertyVersion: "getPropertyVersion",
    }),
    components: {
        InputNumericComponent,
        YearMonthPickerComponent,
        InputDateComponent
    }
})
export default class ContractTenantImprovementEditComponent extends BaseComponent {

    propertyVersion!: PropertyVersion;
    currencyUnit = CurrencyUnit;

    signalRMsgHandler: any;


    @Prop()
    contracts!: ContractListItem[];

    contractRecoveries: ContractRecoveriesAdjustment[] = [];
    calculationYear = this.newIsoDateMonth();
    calculationEndYear = this.newIsoDateMonth();
    firstIncrement = this.newIsoDateMonth();
    loading = false;
    error: string | null = null;
    moveStreamDates: number = 3;

    mounted() {
        this.signalRMsgHandler = (message: SignalRMessage) => this.onSignalRMessage(message);
        this.$signalrHub.$on("HubMessage", this.signalRMsgHandler);
        this.load();
    }

    beforeDestroy() {
        this.$signalrHub.$off("HubMessage", this.signalRMsgHandler);
    }

    isHGA(recovery: ContractRecoveriesAdjustment)
    {
        return recovery.tenantImprovement && recovery.tenantImprovement.recoveries && recovery.tenantImprovement.recoveries.length > 0 && recovery.tenantImprovement.recoveries[0].costIncome;
    }

    hasStreams(recovery: ContractRecoveriesAdjustment)
    {
        const paymentStreams = recovery.tenantImprovement.recoveries[0].costIncome.paymentStreams as any;
        return paymentStreams && paymentStreams.length > 0 && paymentStreams[0].variableAmounts && paymentStreams[0].variableAmounts.length > 0;
    }

    streamHasMultipleVariableAmounts(stream:any)
    {
        if (
            stream.variableAmounts &&
            stream.variableAmounts.length > 1
          ) {
            return true;
          }
        return false;

    }
    multipleVariableAmounts(tenantImprovement: any) {
      
        if (
            tenantImprovement &&
            tenantImprovement.recoveries &&
            tenantImprovement.recoveries.length > 0
        ) {
          const _recovery = tenantImprovement.recoveries[0];
          if (
            _recovery.costIncome &&
            _recovery.costIncome.paymentStreams &&
            _recovery.costIncome.paymentStreams.length > 0
          ) {
            const paymentStream = _recovery.costIncome.paymentStreams[0] as any;
            if (
              paymentStream.variableAmounts &&
              paymentStream.variableAmounts.length > 1
            ) {
              return true;
            }
          }
        }
        return false;
      }


      getAmounts(tenantImprovement: any) {
        if (tenantImprovement &&
            tenantImprovement.recoveries &&
            tenantImprovement.recoveries.length > 0) 
            {
            const _recovery = tenantImprovement.recoveries[0];
            if (_recovery.costIncome && _recovery.costIncome.paymentStreams && _recovery.costIncome.paymentStreams.length > 0)
            {
                const paymentStream = _recovery.costIncome.paymentStreams[0] as any;
                if (
                paymentStream.variableAmounts &&
                paymentStream.variableAmounts.length > 0
            ) {
              const amountsInfo = paymentStream.variableAmounts.map((amount: VariableAmount) => {
                const date = this.toYearMonth(amount.startDate); 
                const value = this.formatNumber(amount.amount,0);
                return `${date}: ${value}`;
              });
              // Join the entries into a single string with line breaks. Vue must use v-html to interpret this.
              return  amountsInfo.join('<br/>');
              
            }
          }
        }
        
        return 'No amounts available';
      }

    load() {
        if (!this.propertyVersion || !this.propertyVersion.calculationValue) {
            this.error = this.translate('BadRequest_CalculationRequired');
            return;
        }
        this.calculationYear = this.propertyVersion.calculationValue.startDate;
        this.calculationEndYear = this.propertyVersion.calculationValue.endDate;
        this.firstIncrement = moment(this.propertyVersion.calculationValue.startDate).add(1, "year").month(0).date(1).format(DateFormat);
        
        this.loading = true;
        const request: GetContractRecoveriesAdjustmentRequest = {
            versionId: this.propertyVersion.id,
            contractIds: this.contracts.map(x => x.id),
            calculationYear: new Date(this.calculationYear).getFullYear(),
            calculationEndYear: new Date(this.calculationEndYear).getFullYear(),
            suppressRecalculation: true,
        }
        dataService.getContractRecoveriesAdjustment(request).then(x => {
            this.contractRecoveries = x.data.slice(0, -3);
            this.error = null;

            this.contractRecoveries.forEach(recovery => {
                if (this.isHGA(recovery)){
                    const paymentStreams = recovery.tenantImprovement.recoveries[0].costIncome.paymentStreams as any;
                    if(this.hasStreams(recovery)){  
                        let aggregatedAmount = 0
                        paymentStreams[0].variableAmounts.forEach((variableAmount: { amount: number; }) => {
                            aggregatedAmount += variableAmount.amount;
                        });
                        recovery.tenantImprovement.newValue = aggregatedAmount;
                        recovery.tenantImprovement.initialValue = aggregatedAmount;
                    } else {
                        recovery.tenantImprovement.newValue = 0;
                        recovery.tenantImprovement.initialValue = 0;
                    }

                }
                const associatedContract = this.contracts.find(contract => contract.id === recovery.contractId)   
                recovery.area = associatedContract ? associatedContract.area : 0;   
                if(this.isHGA(recovery)){
                    if(this.hasStreams(recovery) && !recovery.tenantImprovement.containsMultiplePaymentStreams && !recovery.tenantImprovement.containsMultiplePaymentStreams){
                        const paymentStreams = recovery.tenantImprovement.recoveries[0].costIncome.paymentStreams as any;
                        recovery.tenantImprovement.startDate = paymentStreams[0].startDate;
                        recovery.tenantImprovement.endDate = paymentStreams[0].endDate;
                        recovery.tenantImprovement.newStartDate = paymentStreams[0].startDate;
                        recovery.tenantImprovement.newEndDate = paymentStreams[0].endDate;
                    }
                    else {
                        recovery.tenantImprovement.startDate = recovery.tenantImprovement.recoveries[0].startDate;
                        recovery.tenantImprovement.endDate = recovery.tenantImprovement.recoveries[0].endDate;
                        recovery.tenantImprovement.newStartDate = recovery.tenantImprovement.recoveries[0].startDate;
                        recovery.tenantImprovement.newEndDate = recovery.tenantImprovement.recoveries[0].endDate;
                    }
                } else {
                    if (recovery.tenantImprovement) {
                        if (!this.propertyVersion || !this.propertyVersion.calculationValue) {
                            this.error = this.translate('BadRequest_CalculationRequired');
                            return;
                        }
                        recovery.tenantImprovement.startDate = this.propertyVersion.calculationValue.startDate;
                        recovery.tenantImprovement.endDate = moment(this.propertyVersion.calculationValue.startDate).add(11, 'months').endOf('month').format(DateFormat);

                        recovery.tenantImprovement.newStartDate = this.propertyVersion.calculationValue.startDate;
                        recovery.tenantImprovement.newEndDate = moment(this.propertyVersion.calculationValue.startDate).add(11, 'months').endOf('month').format(DateFormat);
                    }
                }
                });

        }).catch((err: AxiosError) => {
            const errorText = ErrorHandling.GetErrorText(err)
            this.error = this.translate(errorText);
        }).finally(() => {
            this.loading = false;
            const recoveriesTable = (<any> this).$refs.recoveriesTable;
            if (recoveriesTable) {
                recoveriesTable.doLayout();
            }
        });
    }

    clear() {
        this.contractRecoveries = [];
        this.error = null;
    }

    async save() {
        if (this.contractRecoveries.length === 0 || this.error) {
            return;
        }
        this.loading = true;
        await dataService.saveContractRecoveries({
            recoveryAdjustments: this.contractRecoveries,
            calculationYear: new Date(this.calculationYear).getFullYear(),
            firstIncrement: this.firstIncrement,
            calculationEndYear: new Date(this.calculationEndYear).getFullYear()
        })
        .then(async x => {
            this.$notify.success({
                title: '',
                message: this.translate('TenantImprovementsUpdated')
            });
            await this.$store.dispatch("sendBusMessage", <BusMessage<RecoveriesChangedMessage>>{ type: "TIChanged" });
        }).catch(err => {
            console.error(err);
            this.$notify.error({
                title: this.translate('Error'),
                message: this.translate('ErrorActionFailed')
            });
        }).finally(() => {
            this.loading = false;
        });
    }

    async calculate() {
        this.$store.dispatch("sendBusMessage", <BusMessage<PerformCalculationMessage>>{ type: "PerformCalculation" });
        this.loading = true;
        this.$notify.success({
            title: '',
            message: this.translate('CalculationStarted')
        });
    }

    onSignalRMessage(message: SignalRMessage): any {
        console.debug(`Calculation update - ${message.type}`);
        const job = JSON.parse(message.data) as Job;
        if (this.propertyVersion.id && job.identityId && this.propertyVersion.id.toString() != job.identityId.toString()) {
            return;
        }
        
        if (message.type === MessageType.JobFinished) {
            this.load();
            this.loading = false;
            return;
        }
        if (message.type === MessageType.JobFailed) {
            this.$notify.error({
                title: '',
                message: this.translate('CalculationError')
            });
            this.loading = false;
            return;
        }
    }

    onPaste(event:ClipboardEvent, row: ContractRecoveriesAdjustment, typeOfRecovery: 'tenantImprovement' ) {
        if (!document.activeElement || !(<HTMLElement>document.activeElement).blur
            || !event.clipboardData || !event.clipboardData.getData('text')) {
            return;
        }

        (<HTMLElement>document.activeElement).blur();
        let pastedText = event.clipboardData.getData('text');
        const pastedRows = pastedText.split('\n').map(x => x.replace(/\s/g, ''));
        
        if (pastedRows.some(r => r.split('\t').length > 1)) {
            this.$notify.warning({
                title: this.translate('ValidationWarning'),
                message: this.translate('PasteErrorMultipleColumns')
            });
            return;
        }

        const selectedRowIdx = this.contractRecoveries.indexOf(row);
        for (let i = selectedRowIdx, j = 0; i < this.contractRecoveries.length && j < pastedRows.length; i++, j++) {
            let newVal = parseFloat(pastedRows[j].trim().replace(',','.'));
            if (!isFinite(newVal)) {
                continue;
            }
            if (this.contractRecoveries[i][typeOfRecovery] && this.contractRecoveries[i][typeOfRecovery].isValid) {
                this.contractRecoveries[i][typeOfRecovery].newValue = newVal;
            }
        }
    }


    onPasteDates(event: ClipboardEvent, row: ContractRecoveriesAdjustment, typeOfRecovery: 'tenantImprovement', dateField: 'newStartDate' | 'newEndDate') {
        if (!document.activeElement || !(<HTMLElement>document.activeElement).blur
            || !event.clipboardData || !event.clipboardData.getData('text')) {
            return;
        }

        event.preventDefault();

        const pastedText = event.clipboardData.getData('text');

        const pastedRows = pastedText.split('\n').map(x => x.trim()).filter(x => x);
        
        if (pastedRows.some(r => r.split('\t').length > 1)) {
            this.$notify.warning({
                title: this.translate('ValidationWarning'),
                message: this.translate('PasteErrorMultipleColumns')
            });
            return;
        }

        const selectedRowIdx = this.contractRecoveries.indexOf(row);

        for (let i = selectedRowIdx, j = 0; i < this.contractRecoveries.length && j < pastedRows.length; i++, j++) {
            const pastedValue = pastedRows[j];

            const parsedDate = moment(pastedValue, moment.ISO_8601, true);

            if (parsedDate.isValid()) {
                const formattedDate = dateField === 'newStartDate'
                ? parsedDate.startOf('month').format('YYYY-MM-DD')
                : parsedDate.endOf('month').format('YYYY-MM-DD');
                console.log(formattedDate)

            this.contractRecoveries[i][typeOfRecovery][dateField] = formattedDate;
            } else {
                this.$notify.warning({
                    title: this.translate('ValidationWarning'),
                    message: this.translate('PasteErrorInvalidDateFormat')
                });
                break;
            }
        }
    }

    
    getValidation(recovery: RecoveryAdjustment) : { class: string|undefined, text: string|undefined, any: boolean } {
        if (recovery.containsMultiplePaymentStreams) {
            return { class: 'disabled', text: this.translate('Error_MultiplePaymentStreamsTI'), any: true };
        }
            if (recovery.containsMultipleRecoveries) {
            return { class: 'disabled', text: this.translate('Error_MultipleTI'), any: true };
        }       
        if (recovery.recoveries[0] && recovery.recoveries[0].costIncome && this.streamHasMultipleVariableAmounts( recovery.recoveries[0].costIncome.paymentStreams[0])) {
            return { class: 'disabled', text: this.translate('Error_MultipleStreamAmountsTI'), any: true };
        }

        return { class: undefined, text: undefined, any: false};
    }

    anyWarnings() {
        return false;
    }
    
    getCellStyle(row: ContractRecoveriesAdjustment, col: any) : { class: string|undefined, text: string|undefined } {
        
        if (col && col.property && col.property === 'tenantImprovement.newValue') {
            if (
              row.tenantImprovement &&
              row.tenantImprovement.newValue !== undefined &&
              row.tenantImprovement.newValue !== row.tenantImprovement.initialValue
            ) {
              return { class: 'cell-changed', text: undefined};
            }
          }

        if (col && col.property && col.property === 'tenantImprovement.newStartDate') {
            if (
              row.tenantImprovement &&
              row.tenantImprovement.newStartDate !== undefined &&
              !moment(row.tenantImprovement.newStartDate).isSame(
                moment(row.tenantImprovement.startDate),
                'month')
            ) {
              return { class: 'cell-changed', text: undefined};
            }
          }

        if (col && col.property && col.property === 'tenantImprovement.newEndDate') {
            if (
              row.tenantImprovement &&
              row.tenantImprovement.newEndDate !== undefined &&
              !moment(row.tenantImprovement.newEndDate).isSame(
                moment(row.tenantImprovement.endDate),
                'month')
            ) {
              return { class: 'cell-changed', text: undefined};
            }
          }
          
        if(this.changed(row))
            return { class: 'row-changed', text: undefined};

        return { class: undefined, text: undefined};
    }

    formatCurrency(currencyUnit: CurrencyUnit) {
        return CurrencyHelper.formatCurrency(this.getCurrency(), currencyUnit);
    }

    changed(recovery: ContractRecoveriesAdjustment)
    {
        if(recovery && recovery.tenantImprovement){
            return !(recovery.tenantImprovement.initialValue == recovery.tenantImprovement.newValue 
                    &&
                    moment(recovery.tenantImprovement.newStartDate).isSame(
                        moment(recovery.tenantImprovement.startDate),
                        'month')
                    &&
                    moment(recovery.tenantImprovement.newEndDate).isSame(
                        moment(recovery.tenantImprovement.endDate),
                        'month')
                    )
        }
        else
            return false;
    }

    editable(recovery: ContractRecoveriesAdjustment)
    {
        return recovery.tenantImprovement && !this.getValidation(recovery.tenantImprovement).any;
    }


    calculateTotal(recovery: ContractRecoveriesAdjustment): number {
        if (!this.changed(recovery)) {
            return recovery.hgaTotal || 0; 
        } else if (this.editable(recovery)) {
            const recoveryData = recovery.tenantImprovement;

            const startDate = moment(recoveryData.newStartDate);
            const endDate = moment(recoveryData.newEndDate);

            if (!startDate.isValid() || !endDate.isValid()) {
                return 0;
            }
            let preFrequency = 4;
            if(recovery.tenantImprovement.recoveries[0] && recovery.tenantImprovement.recoveries[0].costIncome && recovery.tenantImprovement.recoveries[0].costIncome.paymentStreams[0]){
                preFrequency = recovery.tenantImprovement.recoveries[0].costIncome.paymentStreams[0].paymentFrequency;
            }
            const hgaNewValue = recovery.tenantImprovement.newValue || 0;
            const frequency = 12 / preFrequency;
            const payment = hgaNewValue/frequency;            
            const calcStartDate = moment(this.propertyVersion.calculationValue?.startDate);

            const totalCalculated = payment * Utils.calculateNumberOfPayments(startDate>calcStartDate?startDate:calcStartDate, endDate, frequency);
            return totalCalculated;
        } else if(this.getValidation(recovery.tenantImprovement).any){
            return recovery.hgaTotal;
        }
        return 0;
    }

    calculateTotalPerArea(recovery: ContractRecoveriesAdjustment): number {
        const totalValue = this.calculateTotal(recovery);
        const area = recovery.area || 0;

        if (area > 0 && totalValue > 0) {
            const totalPerArea = +totalValue / area;
            return Math.round(totalPerArea);
        }
        return 0;
    }

    getCurrency() {
        let currencies = this.$store.getters.getCurrencies;
        if (!currencies) return null;
        return (<Currency[]> currencies).find(x => x.id == this.propertyVersion.calculationValue!.currencyId);
    }

    getCurrencyRate() {
        let currencies = this.$store.getters.getCurrencies;
        if (!this.propertyVersion || !currencies) return 1;
        let versionCurrency = (<Currency[]> currencies).find(x => x.id == this.propertyVersion.currencyId);
        if (!versionCurrency) return 0;
        let stateCurrency = (<Currency[]> currencies).find(x => x.id == this.propertyVersion.calculationValue!.currencyId);
        if (!stateCurrency) return 0;
        return versionCurrency.rate / stateCurrency.rate;
    }

    toYearMonth(date: string) {
        if (!date) {
            return date;
        }
        return moment(date).format(YearMonthFormat);
    }

    moveStreamDatesLabel() {
        return this.translate('MoveStreamDates').replace('*', this.moveStreamDates.toString()); 
    }

    moveStreamDatesForward() {
        if (!this.contractRecoveries || this.contractRecoveries.length == 0) {
            return;
        }
        this.contractRecoveries.forEach((recovery, index) => {
            if (recovery.tenantImprovement && recovery.tenantImprovement.newStartDate && !this.getValidation(recovery.tenantImprovement).any) {
                this.$set(this.contractRecoveries[index].tenantImprovement, 'newStartDate', 
                    moment(recovery.tenantImprovement.newStartDate).add(this.moveStreamDates, 'months').startOf('month').format(DateFormat));
            }
            if (recovery.tenantImprovement && recovery.tenantImprovement.newEndDate && !this.getValidation(recovery.tenantImprovement).any) {
                this.$set(this.contractRecoveries[index].tenantImprovement, 'newEndDate', 
                    moment(recovery.tenantImprovement.newEndDate).add(this.moveStreamDates, 'months').startOf('month').format(DateFormat));
            }
        });
    }

    moveStreamDatesBackwards() {
        if (!this.contractRecoveries || this.contractRecoveries.length == 0) {
            return;
        }
        this.contractRecoveries.forEach(recovery => {
            if (recovery.tenantImprovement && recovery.tenantImprovement.newStartDate && !this.getValidation(recovery.tenantImprovement).any) {
                recovery.tenantImprovement.newStartDate = moment(recovery.tenantImprovement.newStartDate).subtract(this.moveStreamDates, 'months').startOf('month').format(DateFormat);
            }
            if (recovery.tenantImprovement && recovery.tenantImprovement.newEndDate && !this.getValidation(recovery.tenantImprovement).any) {
                recovery.tenantImprovement.newEndDate = moment(recovery.tenantImprovement.newEndDate).subtract(this.moveStreamDates, 'months').startOf('month').format(DateFormat);
            }
        });
    }

    getSummaries(tableData: any): any {
        const data: ContractRecoveriesAdjustment[] = tableData.data;
    
        let areaSum = 0;
        let hgaInitialSum = 0;
        let hgaNewSum = 0;
        let hgaTotalSum = 0;
        //let hgaTotalPerKvmSum = 0;
    
        for (const row of data) {
            if (row.area) {
                areaSum += row.area || 0;
            }
    
            if (row.tenantImprovement) {
                hgaInitialSum += row.tenantImprovement.initialValue || 0;
                hgaNewSum += row.tenantImprovement.newValue || 0;
                hgaTotalSum += this.calculateTotal(row) || 0;
                //hgaTotalPerKvmSum += this.calculateTotalPerArea(row) || 0;
            }
        }
    
        return [
            '', '', '',
            this.formatNumber(areaSum, 0),
            this.formatNumber(hgaInitialSum, 0),
            this.formatNumber(hgaNewSum, 0),
            '', '', '', '',
            this.formatNumber(hgaTotalSum, 0),
            '', '', '', '',
            //this.formatNumber(hgaTotalPerKvmSum, 2)
        ];
    }   
}