import BaseComponent from "@/components/base-component";
import DataService from "@/services/data-service";
import InlineEdit from "@/helpers/inline-edit";
import { Component, Prop, Watch } from "vue-property-decorator";
import { AxiosPromise } from "axios";
import { IndexClause } from "@/models/IndexClause";
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import { BaseDataType } from '@/constants/fia-constants';

@Component({
    components: {
        YearMonthPickerComponent,
        InputNumericComponent
    }
})
export default class IndexClauseComponent extends BaseComponent {
    indexClausesGrid: InlineEdit<IndexClause>;

    @Prop()
    reload!: boolean;
    @Prop()
    readOnly!: boolean;

    @Watch("reload")
    reloadWatcher() {
        if (this.reload == true) {
            (<any> this).$refs.indexClausesTable.doLayout();
        }
    }

    constructor() {
        super();
        this.indexClausesGrid = new InlineEdit<IndexClause>(this, "indexClausesTable", this.loadIndexClauses, this.saveIndexClause, this.deleteIndexClause, this.newIndexClause, undefined, this.hasNoErrors);
        this.indexClausesGrid.onItemChanged = this.itemChanged;
        this.indexClausesGrid.readOnly = this.readOnly;
    }

    mounted() {
        this.indexClausesGrid.reload();
    }

    loadIndexClauses(): AxiosPromise<IndexClause[]> {
        return DataService.getIndexClauses();
    }

    saveIndexClause(entity: IndexClause): AxiosPromise<IndexClause> {
        return DataService.saveIndexClause(entity);
    }

    deleteIndexClause(id: number): AxiosPromise<IndexClause> {
        return DataService.deleteIndexClause(id);
    }

    newIndexClause(): IndexClause {
        return { id: 0, units: 0, rounding: 0, onlyUp: 'J', inactive: false, name: "", languageId: this.$store.getters.getUser.languageId };
    }
    
    itemChanged() {
        this.$store.dispatch('updateCache', BaseDataType.IndexClauses);
    }
}