import BaseComponent from "@/components/base-component";
import DataService from "@/services/data-service";
import { Component } from "vue-property-decorator";
import { Language, Database } from "@/models";
import { mapGetters } from 'vuex';
import { JobAddedMessage, BusMessage } from '@/models/messages/messages';
import { AppRightEnum } from '@/constants/fia-constants';
import { FileUploadStatus } from 'element-ui/types/upload';

@Component({
    computed: mapGetters({
        languages: "getLanguages"
    })
})
export default class ContractImportComponent extends BaseComponent {
    languages!: Language[];

    databases: Database[] = [];
    selectedLanguageId: number = 1;
    selectedDatabaseId: number = 13;
    selectedFileName: string = "";
    description: string = "";
    uploadData: any = {};
    disableUpload: boolean = false;
    disableDownload: boolean = false;
    headers: object = {};
    unsupportedFile: boolean = false;

    mounted() {
        this.databases = this.$store.getters.getDatabasesByAppRight([AppRightEnum.ImportExport]);
        this.selectedDatabaseId = this.$store.getters.getUser.databaseId;
    }

    url(): string {
        return process.env.VUE_APP_FIAAPI_URL + "importcontracts/import"
    }

    setUploadData(file: any) {
        return new Promise(async resolve => {
            this.headers = await DataService.getAuthHeaders();

            this.uploadData = {
                databaseId: this.selectedDatabaseId,
                languageId: this.selectedLanguageId,
                name: this.description
            }
            this.$nextTick(() => {
                resolve(file)
            })
        })
    }

    onSuccess() {
        (<HTMLFormElement>this.$refs.upload).clearFiles();
        this.$store.dispatch("sendBusMessage", <BusMessage<JobAddedMessage>>{ type: "JobAdded" });
        this.disableUpload = false;
        this.$message({
            message: this.translate('ContractImportAddedMessage'),
            type: 'success'
        });
    }

    onError(e: any) {
        this.$notify.error({
            title: this.translate("Error"),
            message: this.translate("ContractImportError")
        });
    }

    importContract() {
        this.confirm("ConfirmImportContracts").then(() => {
            (<HTMLFormElement>this.$refs.upload).submit();
            this.selectedFileName = '';
        }).catch(() => {});
    }
    s2ab(s: any) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        // tslint:disable-next-line:no-bitwise
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }
    async downloadTemplate() {
        let lang = this.selectedLanguageId;
        this.disableDownload = true;
        await DataService.getImportTemplate(lang).then((response) => {
            var bin = atob(response.data);
            var ab = this.s2ab(bin);

            var blob = new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
            let url = window.URL.createObjectURL(blob);

            var a = document.createElement('a')
            a.href = url
            a.download = 'ImportTemplate.xlsx';
            a.click();
            a.remove();
            setTimeout(() => window.URL.revokeObjectURL(url), 100)

            this.disableDownload = false; 

        }).catch((x) => {  
            this.disableDownload = false;
            console.log(x);
        });
    }

    onChange(file: any) {
        let status: FileUploadStatus = file.status;
        if (status == 'ready') {
            this.selectedFileName = file.name;
        }
    }

    onRemove() {
        this.disableUpload = false;
    }

    disableSubmit(): boolean {
        if (this.selectedFileName.length > 0 && this.description.length > 0)
            return false;
        return true;
    }
}