import { Component, Watch, Prop } from "vue-property-decorator";
import BaseComponent from "@/components/base-component";
import { Valuation, Language, ValuationModuleListItem, ValuationInstanceModule, ValuationListItem, FiaDataUser, ValuationFilterModule, User } from '@/models';
import InlineEdit from '@/helpers/inline-edit';
import dataService from '@/services/data-service';
import { AxiosPromise, AxiosResponse } from 'axios';
import { mapGetters } from 'vuex';
import validationService from '@/services/validation-service';
import { ValidationError } from '@/models/interfaces/ValidationError';
import InputTextComponent from '@/components/common/input-text/input-text';
import { BaseViewModel } from '@/models/interfaces/BaseViewModel';

@Component({
    components: {
        InputTextComponent
    },
    computed: mapGetters({
        languages: "getLanguages"
    })
})
export default class ValuationComponent extends BaseComponent {
    valuationInstancesGrid: InlineEdit<ValuationListItem>;
    valuationModulesGrid: InlineEdit<ValuationModuleListItem>;
    languages!: Language[];
    valuationModules: ValuationModuleListItem[] = [];
    complexErrors: ValidationError[] = [];

    selectedInstance: Valuation | null = null;
    valuationInstanceModuleGridLoading: boolean = false;
    currentVIM: ValuationInstanceModule | null = null;
    indexVIM: number = -1;
    originalInstance: string = "";
    previousValuation: string = "";
    activeTab: string = "valuation"
    setDependencyMode: boolean = false;
    buttonHover: number | null = null;
    saveLoading: boolean = false;
    deleteLoading: boolean = false;
    instanceLoading: boolean = false;
    moduleNameFilter: string = "";
    moduleNameFilterTimer: NodeJS.Timeout | null = null;
    modulePageIdx: number = 1;

    constructor() {
        super();
        this.valuationInstancesGrid = new InlineEdit<ValuationListItem>(this, "valuationInstancesTable", this.loadValuationInstances, undefined, undefined, this.newValuationInstace, undefined, this.hasNoErrors);
        this.valuationModulesGrid = new InlineEdit<ValuationModuleListItem>(this, "valuationModulesTable", this.loadValuationModules, undefined, undefined, this.newValuationModule, undefined, this.hasNoErrors);
        this.valuationModulesGrid.loading = true;
    }

    async mounted() {
        await dataService.getValuationModuleList(this.modulePageIdx++, 50).then(x => {
            this.valuationModules = x.data;
        });
        this.valuationInstancesGrid.reload().then(() => {
            if (this.valuationInstancesGrid.items.length > 0) {
                this.getFullInstance(this.valuationInstancesGrid.items[0]);
            }
        });
        this.valuationModulesGrid.reload();
    }

    toggleSelection(rows: ValuationInstanceModule[]) {
        if (rows) {
            rows.forEach((row: ValuationInstanceModule) => {
                (<any> this).$refs.valuationInstanceModuleTable.toggleRowSelection(row);
            });
        } else {
            (<any> this).$refs.valuationInstanceModuleTable.clearSelection();
        }
    }

    @Prop()
    reload!: boolean;

    @Watch("reload")
    reloadWatcher() {
        if (this.reload == true) {
            (<any> this).$refs.valuationInstancesTable.doLayout();
            (<any> this).$refs.valuationModulesTable.doLayout();
            (<any> this).$refs.valuationInstanceModuleTable.doLayout();
        }
    }

    @Watch("selectedInstance", { deep: true, immediate: true })
    valuationWatcher() {
        if (this.selectedInstance) {
            validationService.validateVDokValuations(this.selectedInstance, this.valuationInstancesGrid.entities.map(x => x.name), this.complexErrors);
        }
    }

    // @Watch("valuationInstancesGrid.currentItem")
    // async currentItemWatcher(value: any) {
    //     if (this.valuationInstancesGrid.currentItem != null) {
    //         this.valuationInstanceModuleGridLoading = true;
    //         await dataService.getValuationInstanceById(this.valuationInstancesGrid.currentItem.entity.id)
    //             .then(x => {
    //                 this.selectedInstance = x.data;
    //                 this.originalInstance = JSON.stringify(this.selectedInstance);
    //                 this.valuationInstanceModuleGridLoading = false;
    //             })
    //         this.currentVIM = null;
    //         this.indexVIM = -1;
    //     }
    // }

    async getFullInstance(instance: BaseViewModel<ValuationListItem>) {
        this.instanceLoading = true;
        this.valuationInstancesGrid.itemSelected(instance);
        this.valuationInstanceModuleGridLoading = true;
        await dataService.getValuationInstanceById(instance.entity.id)
            .then(x => {
                this.selectedInstance = x.data;
                this.originalInstance = JSON.stringify(this.selectedInstance);
                let vals: ValuationInstanceModule[] = this.selectedInstance!.instanceModules.filter((x) =>
                    this.selectedInstance!.valuationFilters.find((y: ValuationFilterModule) => y.moduleId == x.module.id)
                );
                if (vals)   
                    this.$nextTick(() => this.toggleSelection(vals));
                this.valuationInstanceModuleGridLoading = false;
                this.instanceLoading = false;
            })
        this.currentVIM = null;
        this.indexVIM = -1;
    }

    async confirmChange(row: any) {
        if (JSON.stringify(this.selectedInstance) != this.originalInstance) {
            await this.$confirm(this.translate('ConfirmValuationInstance'), this.translate('Confirm'), {
                distinguishCancelAndClose: true,
                confirmButtonText: this.translate('Yes'),
                cancelButtonText: this.translate('No')
            })
                .then(async () => {
                    await this.save();
                    // await this.valuationInstancesGrid.itemSelected(row);
                    this.getFullInstance(row);
                    (<any> this).$refs.valuationInstancesTable.setCurrentRow(this.valuationInstancesGrid.currentItem);
                    return;
                }).catch(async action => {
                    if (action === 'cancel') {
                        // await this.valuationInstancesGrid.itemSelected(row);
                        this.getFullInstance(row);
                        (<any> this).$refs.valuationInstancesTable.setCurrentRow(this.valuationInstancesGrid.currentItem);
                        return;
                    }
                    else {
                        return;
                    }
            });
        }
        else {
            // this.valuationInstancesGrid.itemSelected(row);
            this.getFullInstance(row);
        }
    }

    handleSelectionChange(items: ValuationInstanceModule[], item: ValuationInstanceModule) {
        let add = items.some(x => x.module.id === item.module.id);
        let filters = this.selectedInstance!.valuationFilters;
        if (add) {
            filters.push({
                valuationInstanceId: this.selectedInstance!.id, 
                moduleId: item.module.id, 
                preSelected: true, 
                id: this.getNewId(filters)
            })
        } else {
            let idx = filters.find(x => x.moduleId === item.module.id);
            if (!idx) return;
            filters.splice(filters.indexOf(idx));
        }
    }

    selectValuationInstanceModule(row: ValuationInstanceModule, col: any, event: any) {
        if(this.setDependencyMode) {
            this.currentVIM!.dependentModuleId = row.module.id;
            this.setDependencyMode = false;
        }
        this.currentVIM = row;
        this.indexVIM = this.currentVIM.order;
    }

    setDependency(row: ValuationInstanceModule) {
        this.currentVIM = row;
        this.setDependencyMode = true;
    }

    getDependencyOrderNumber(item: ValuationInstanceModule): number {
        if (this.selectedInstance && item.dependentModuleId)
            return this.selectedInstance.instanceModules[this.selectedInstance.instanceModules.map(d => d.module.id).indexOf(item.dependentModuleId!)].order + 1;
        return 0;
    }

    loadValuationInstances(): AxiosPromise<ValuationListItem[]> {
        return dataService.getValuationInstanceList();
    }

    loadValuationModules(): AxiosPromise<ValuationModuleListItem[]> {        
        return Promise.resolve(<AxiosResponse> {
            data: this.valuationModules
        });
    }

    newValuationInstace(): ValuationListItem{
        return <ValuationListItem> {
            id: 0,
            name: this.selectedInstance!.name,
            user: this.selectedInstance!.user
        };
    }

    newValuationModule(): Valuation{
        return <Valuation> {};
    }

    moveTop() {
        for (let i = 0; i <= this.indexVIM; i++) {
            this.selectedInstance!.instanceModules[i].order++;            
        }
        this.currentVIM!.order = 0;
        this.indexVIM = 0;

        this.triggerSort();
    }

    moveUp() {
        this.selectedInstance!.instanceModules[this.indexVIM - 1].order++;
        this.currentVIM!.order--;
        this.indexVIM = this.currentVIM!.order;

        this.triggerSort();
    }

    moveDown() {
        this.selectedInstance!.instanceModules[this.indexVIM + 1].order--;
        this.currentVIM!.order++;
        this.indexVIM = this.currentVIM!.order;

        this.triggerSort();
    }

    moveBottom() {
        for (let i = this.indexVIM; i < this.selectedInstance!.instanceModules.length; i++) {
            this.selectedInstance!.instanceModules[i].order--;            
        }
        this.currentVIM!.order = this.selectedInstance!.instanceModules.length - 1;
        this.indexVIM = this.selectedInstance!.instanceModules.length - 1;

        this.triggerSort();
    }

    triggerSort() {
        this.selectedInstance!.instanceModules.sort((x, y) => x.order - y.order);
        (<any> this).$refs.valuationInstanceModuleTable.sort("order", "ascending");
    }

    addModule(row: ValuationModuleListItem) {
        let order = this.selectedInstance!.instanceModules.length == 0 ? 0 : this.selectedInstance!.instanceModules[this.selectedInstance!.instanceModules.length - 1].order + 1;
        let newModule: ValuationInstanceModule = {
            id: this.getNewId(this.selectedInstance!.instanceModules),
            order: order,
            dependentModuleId: null,
            module: <ValuationModuleListItem> {
                id: row.id,
                name: row.name
            }
        }
        this.selectedInstance!.instanceModules.push(newModule);
    }

    removeModule(row: ValuationInstanceModule) {
        // Cant remove module if it is unique and dependencies exists
        var modulesDependingOnRow = this.selectedInstance!.instanceModules.filter(x => x.dependentModuleId == row.module.id);
        if (modulesDependingOnRow.length > 0) {
            if (this.selectedInstance!.instanceModules.filter(x => x.module.id == row.module.id).length == 1) {
                this.$message({
                    message: this.translate("RemoveWarningModuleDependency").replace("{0}", modulesDependingOnRow[0].module.name),
                    type: "error",
                });
                return;
            }
        }

        this.selectedInstance!.instanceModules.sort((x, y) => x.order - y.order);
        this.selectedInstance!.instanceModules.splice(row.order, 1);
        for (let i = row.order; i < this.selectedInstance!.instanceModules.length; i++) {
            this.selectedInstance!.instanceModules[i].order--;
        }
    }
    async save() {
        let id: number;
        this.saveLoading = true;
        await dataService.saveValuationInstance(this.selectedInstance!)
            .then(async x => {
                this.selectedInstance = null;
                id = x.data.id;
                await this.valuationInstancesGrid.reload();
                this.saveLoading = false;
            })
            .finally(() => {
                let index = this.valuationInstancesGrid.entities.map(x => x.id).indexOf(id);
                this.getFullInstance(this.valuationInstancesGrid.items[index]);
                (<any> this).$refs.valuationInstancesTable.setCurrentRow(this.valuationInstancesGrid.currentItem);
            })
            .catch(() => {
            this.saveLoading = false;
        });
    }

    addInstance() {
        let u: User = this.$store.getters.getUser;
        this.selectedInstance = {
            id: 0,
            instanceModules: [],
            languageId: u.languageId,
            name: "",
            tableOfContents: false,
            user: <FiaDataUser> {
                id: u.id,
                email: u.email,
                name: u.realName
            },
            valuationFilters: []
        }
        this.valuationInstancesGrid.currentItem = null;
    }
    deleteInstance() {
        this.deleteLoading = true;
        let id = this.selectedInstance!.id;
        this.selectedInstance = null;
        dataService.deleteValuationInstance(id)
            .then(() => {
                this.valuationInstancesGrid.reload();
                this.getFullInstance(this.valuationInstancesGrid.items[0]);
                this.deleteLoading = false;
            });
    }

    copy() {
        let newInstance = Object.assign({}, this.selectedInstance);
        newInstance.id = 0;
        this.selectedInstance = newInstance;
        this.valuationInstancesGrid.currentItem = null;
        (<any> this).$refs.valuationInstancesTable.setCurrentRow();
        this.$message(this.translate('EditCopyMessage') + newInstance.name + '.');
    }

    toLowerCase(name: string): string {
        return name.toLowerCase();
    }

    async page() {
        await dataService.getValuationModuleList(this.modulePageIdx++, 50, 0, this.moduleNameFilter).then(x => {
            this.valuationModules = this.valuationModules.concat(x.data);
            this.valuationModulesGrid.reload();
        });
    }

    filterByName(delay: number = 500) {
        if (this.moduleNameFilterTimer) {
            clearTimeout(this.moduleNameFilterTimer);
        }
        this.moduleNameFilterTimer = setTimeout(async() => {
            this.modulePageIdx = 1;
            await dataService.getValuationModuleList(this.modulePageIdx++, 50, 0, this.moduleNameFilter).then(x => {
                this.valuationModules = x.data;
                this.valuationModulesGrid.reload();
            });
        }, delay);
    }

    async showAllInstances() {
        const instances = (await dataService.getValuationInstanceList(true)).data;
        this.valuationInstancesGrid.reload(instances);
    }
}