import BaseComponent from "@/components/base-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import { IndexIncrementStream, IndexClause, IndexDenotation } from "@/models";
import { ValidationError } from "@/models/interfaces/ValidationError";
import { paymentFrequencies, monthList } from '@/constants/fia-constants';
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import BaseValuesComponent from '@/components/payment-flows/base-values/base-values';

@Component({
    components: {
        YearMonthPickerComponent,
        InputNumericComponent,
        BaseValuesComponent
    }
})
export default class IndexStreamComponent extends BaseComponent {
    @Prop()
    stream!: IndexIncrementStream;
    @Prop()
    complexErrors!: ValidationError[];
    @Prop()
    typeOfFlow!: string;

    indexClausesList: IndexClause[] = [];
    indexDenotationsList: IndexDenotation[] = [];
    frequencyList = paymentFrequencies;
    monthList = monthList;

    @Watch("stream", { immediate: true })
    streamWatcher() {
        if (!this.stream) return;

        this.indexClausesList = this.$store.getters.getActiveIndexClauses([this.stream.indexClauseId]);
        this.indexDenotationsList = this.$store.getters.getActiveIndexDenotations([this.stream.indexDenotationId]);
    }
}