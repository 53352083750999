import BaseComponent from "@/components//base-component";
import DataService from "@/services/data-service";
import { Component, Prop, Watch } from "vue-property-decorator";
import { PropertyVersionSlim } from "@/models/interfaces/PropertyVersionSlim";
import { PropertySearchResultItem } from "@/models/interfaces/PropertySearchResultItem";
import { PropertyVersion } from '@/models';
import { CostIncomeListItem } from '@/models/CostIncomeListItem';
import TableSelectExtension from '@/helpers/table-select-extension';

@Component({})
export default class CostIncomeGetComponent extends BaseComponent {
    propertyVersion!: PropertyVersion;

    @Prop()
    open!: boolean;

    @Prop()
    costIncomesEnabled!: boolean;

    @Prop()
    multipleCostIncomes!: boolean;

    title: string = "";
    propertyList: PropertySearchResultItem[] = [];
    versionList: PropertyVersionSlim[] = [];
    costIncomeList: CostIncomeListItem[] = [];
    searchTextName: string = "";
    searchTextIdentifier: string = "";
    propertyDataLoading: boolean = false;
    versionDataLoading: boolean = false;
    premisesDataLoading: boolean = false;
    selectedProperty: PropertySearchResultItem | null = null;
    selectedVersion: PropertyVersionSlim | null = null;
    selectedCostIncomes: number[] = [];
    select: TableSelectExtension = new TableSelectExtension(this, 'costIncomesTable');

    @Watch("open")
    openWatcher() {
        if (this.open) {
            this.selectedCostIncomes = [];
            this.title = this.translate('SelectCostIncome');

            if (process.env.VUE_APP_MODE === "dev") {
                this.searchTextName = "Gurkan";
                this.searchProperty();
            }

            this.$nextTick(() => {
                (<any>this.$refs.searchTextName).$el.children[0].focus();
            });
        }
    }

    searchProperty() {
        this.propertyList = [];
        this.versionList = [];
        this.costIncomeList = [];
        this.propertyDataLoading = true;
        DataService.searchProperties({ name: this.searchTextName, identifier: this.searchTextIdentifier, page: 0, maxRows: 0 })
            .then(x => {
                this.propertyList = x.data.properties;
                this.selectedProperty = null;
                this.selectedVersion = null;
                this.selectedCostIncomes = [];
                this.propertyDataLoading = false;
            });
    }

    propertySelected(property: PropertySearchResultItem) {
        this.selectedProperty = property;
        this.versionDataLoading = true;
        this.versionList = [];
        this.costIncomeList = [];
        DataService.getVersionsByProperty(property.id)
            .then(x => {
                this.versionList = x.data;
                this.selectedVersion = null;
                this.selectedCostIncomes = [];
                this.versionDataLoading = false;
            });
    }

    versionSelected(version: PropertyVersionSlim) {
        this.selectedVersion = version;
        this.premisesDataLoading = true;
        this.costIncomeList = [];
        DataService.getCostIncomeList(version.id)
            .then(x => {
                this.costIncomeList = x.data;
                this.selectedCostIncomes = [];
                this.premisesDataLoading = false;
            });
    }

    cancel() {
        this.close(true);
        this.clear();
    }

    close(canceled: boolean = false) {
        let result = {
            propertyId: canceled ? undefined : this.selectedProperty!.id,
            versionId: canceled ? undefined : this.selectedVersion!.id,
            costIncomes: (this.costIncomesEnabled && !canceled) ? this.selectedCostIncomes : undefined
        };
        this.$emit("update:open", false);
        this.$emit("closed", canceled, result);
    }

    async ok() {
        this.close(false);
        this.clear();
    }

    clear() {
        this.propertyList = [];
        this.versionList = [];
        this.costIncomeList = [];
        this.searchTextName = "";
        this.searchTextIdentifier = "";
        this.selectedProperty = null;
        this.selectedVersion = null;
        this.selectedCostIncomes = [];
    }

    canOk(): boolean {
        return this.costIncomesEnabled ? (this.multipleCostIncomes ? this.selectedCostIncomes.length > 0 : this.selectedCostIncomes.length === 1) : this.selectedVersion !== null;
    }

    getPropertyListHeightStyle(): string {
        return `height: calc(${this.costIncomesEnabled ? 33 : 50}% - 4px);`;
    }

    getVersionListHeightStyle(): string {
        return `height: calc(${this.costIncomesEnabled ? 33 : 50}% - 4px);margin-top: 8px;`;
    }

    costIncomeSelection(items: CostIncomeListItem[], item: CostIncomeListItem) {
        this.select.select(items, item);
        this.selectedCostIncomes = items.map(x => x.id);
    }

    selectCostIncome(item: CostIncomeListItem) {
        (this.$refs as any).costIncomesTable.toggleRowSelection(item);
    }
}