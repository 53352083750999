import BaseComponent from '@/components/base-component';


export default class TableSelectExtension {

    vue: BaseComponent;
    shiftPressed: boolean = false;
    lastSelection: any | null;

    tableRef: string;
    itemIdField: string;

    constructor(vue: BaseComponent, tableRef: string, itemIdField: string = 'id') {
        this.vue = vue;
        this.tableRef = tableRef;
        this.itemIdField = itemIdField;

        let context = this;
        window.addEventListener('keyup', (e)   => { context.shiftPressed = e.shiftKey });
        window.addEventListener('keydown', (e) => { context.shiftPressed = e.shiftKey });
    }
    
    select(items: any, itemSelected: any) {
        let table = <any> this.vue.$refs[ this.tableRef ];
        if (!table)  return;

        if (itemSelected && items.length > 1) { // At least one item needs to be selected
            if (this.shiftPressed && this.lastSelection) {
                let previousIdx = table.tableData.findIndex((x: any) => x[this.itemIdField] === this.lastSelection[this.itemIdField]);
                let latestIdx = table.tableData.findIndex((x: any) => x[this.itemIdField] === itemSelected[this.itemIdField]);
                let lowIdx = latestIdx < previousIdx ? latestIdx : previousIdx
                let highIdx = latestIdx > previousIdx ? latestIdx : previousIdx
             
                //Iterate between indexes and add to selection array
                for (let i = lowIdx; i < highIdx; i++) {
                    //avoid duplicates
                    if (items.indexOf(table.tableData[i]) !== -1) {
                        items.splice(items.indexOf(table.tableData[i]), 1);
                    }
                    items.push(table.tableData[i]);
                }
            }
        }
        
        this.lastSelection = table.tableData.find((x: any) => x === itemSelected);

        table.clearSelection();
        items.forEach((row: any) => {
            table.toggleRowSelection(row, true);
        });
    }

}