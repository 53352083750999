import { Component, Prop, Watch } from "vue-property-decorator";
import { mapGetters } from 'vuex'
import { CalculationResult } from "@/models";
import BaseComponent from '@/components/base-component';
import CashflowComponent from '@/components/web-reports/cashflow/cashflow';
import { AccumulationChartPlugin, AccumulationChart, PieSeries, AccumulationLegend, AccumulationDataLabel, AccumulationTooltip, ChartPlugin, Chart, ColumnSeries, Category, DataLabel, Tooltip, Legend } from "@syncfusion/ej2-vue-charts";
import Vue from 'vue';

Vue.use(AccumulationChartPlugin);
Vue.use(ChartPlugin);
AccumulationChart.Inject( AccumulationLegend, PieSeries, AccumulationDataLabel, AccumulationTooltip );
Chart.Inject( ColumnSeries, Legend, DataLabel, Category, Tooltip );
const ChartSettings = require('./chart-settings.json');

interface InvestmentsData {
    calculationResult: CalculationResult,
    startYear: number,
    endYear: number,
    prognosisList: number[],
    sumCashflow: number;
    startLoan: number;
    endLoan: number;
    purchaseValue: number;
    equityAtCalculationStart: number;
    equityAtCalculationEnd: number;
    presentValueLevered: number;
}

@Component({
    components: {
        CashflowComponent,
    },
    computed: mapGetters({
        _tabLevel1: "getTabLevel1",
    })
})
export default class InvestmentsReportComponent extends BaseComponent {
    
    loading: boolean = false;
    colors: string[] = ['#37a2db', '#065c9e'];
    infoMessage: string = "";
    _tabLevel1!: string;

    irrBarChartSettings: any  = JSON.parse(JSON.stringify(ChartSettings.BarChart));
    irrPieChartSettings: any  = JSON.parse(JSON.stringify(ChartSettings.PieChart));
    irrLeveragedPieChartSettings: any = JSON.parse(JSON.stringify(ChartSettings.PieChart));
    irrPirChart: any;

    @Prop()
    investmentsData!: InvestmentsData;
    @Prop()
    cashflowData!: any;
    @Prop({default: 1})
    currencyRate!: number;

    @Watch("investmentsData", {immediate: true}) activeWatcher() {
        this.refresh();
    }

    @Watch("_tabLevel1", {immediate: true}) onTabLevelChange() {
        this.doLayout();
    }

    async refresh() {
        if (!this.investmentsData || !this.hasIrrData()) return;
        this.buildCharts();
    }

    buildCharts() {
        if (!this.investmentsData)  return;
        let calc = this.investmentsData.calculationResult;

        this.irrPieChartSettings.seriesData = [{ 
            x: this.decimals(((1-calc.irrRestvalueShare) * calc.irr) * 100, 2, 2),
            y: this.decimals((1-calc.irrRestvalueShare) * 100, 2, 0, true),
            text: this.translate("CashFlow"), fill: this.colors[0]
        }, { 
            x: this.decimals((calc.irrRestvalueShare * calc.irr) * 100, 2, 2), 
            y: this.decimals(calc.irrRestvalueShare * 100, 2, 0, true), 
            text: this.translate("RestValue"), fill: this.colors[1] 
        }];
        this.irrLeveragedPieChartSettings.seriesData = [{ 
            x: this.decimals(((1-calc.irrRestvalueShareLeveraged) * calc.irrLeveraged) * 100, 2, 2),
            y: this.decimals((1-calc.irrRestvalueShareLeveraged)*100,2, 0, true), 
            text: this.translate("CashFlow"), fill: this.colors[0] 
        }, { 
            x: this.decimals((calc.irrRestvalueShareLeveraged * calc.irrLeveraged) * 100, 2, 2), 
            y: this.decimals(calc.irrRestvalueShareLeveraged*100,2, 0, true), 
            text: this.translate("RestValue"), fill: this.colors[1] 
        }];
        this.irrBarChartSettings.seriesData = [{ 
            x: this.translate('TotalEquity'), y: this.decimals(calc.irr*100, 2, 0, true), fill: this.colors[0], text: this.decimals(calc.irr*100, 2, 2)
        }, { 
            x: this.translate('Equity'), y: this.decimals(calc.irrLeveraged*100, 2, 0, true), fill: this.colors[1], text: this.decimals(calc.irrLeveraged*100, 2, 2)
        }];
        
        this.doLayout();
    }

    profitRatioEquity() {
        if (!this.investmentsData)  return;
        let p = (this.investmentsData.sumCashflow + this.investmentsData.equityAtCalculationEnd) / this.investmentsData.equityAtCalculationStart;
        return p.toFixed(2);
    }

    hasIrrData() {
        return this.investmentsData && this.investmentsData.calculationResult && this.investmentsData.calculationResult.irr;
    }

    doLayout() {
        this.$nextTick(function () {
            if ((<any> this.$refs.irrPieChart)) 
                (<any> this.$refs.irrPieChart).ej2Instances.refresh();
            if ((<any> this.$refs.irrLeveragedPieChart)) 
                (<any> this.$refs.irrLeveragedPieChart).ej2Instances.refresh();
            if ((<any> this.$refs.irrChart)) 
                (<any> this.$refs.irrChart).ej2Instances.refresh();
        });
    }

}
