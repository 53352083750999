import Vue, { nextTick } from 'vue';
import BaseComponent from '@/components/base-component';
import Component from 'vue-class-component';
import dataService from '@/services/data-service';
import InlineEdit from '@/helpers/inline-edit';
import { Prop, Watch } from 'vue-property-decorator';
import { ValuationListItem, ValuationModuleListItem, Valuation, ValuationModule, ValuationDataTableListItem, ValuationDataTableField, ValuationTableInstance, ValuationDataTableFieldInModule, FiaDataUser, User } from '@/models';
import { AxiosPromise, AxiosResponse } from 'axios';
import { BaseViewModel } from '@/models/interfaces/BaseViewModel';
import { ValuationModuleDisplayEnum, ValuationModulePageBreakEnum, LanguageEnum, ValuationModuleTypeEnum, ValuationModulePictureTypeEnum, ValuationModuleGraphTypeEnum } from '@/constants/fia-constants';
import { RichTextEditorPlugin, Toolbar, HtmlEditor, MarkdownEditor, RichTextEditor, FontSizeModel, FormatModel, ToolbarSettingsModel, BackgroundColorModel, FontColorModel, PasteCleanup, RichTextEditorComponent, NodeSelection } from "@syncfusion/ej2-vue-richtexteditor";

Vue.use(RichTextEditorPlugin);
RichTextEditor.Inject(Toolbar, HtmlEditor, MarkdownEditor, PasteCleanup);

enum RichTextEditorFieldsEnum {
    None = 1,
    Header = 2,
    Text = 3
}

interface MDataType {
    value: any;
    label: string;
}

@Component({
})
export default class ModulesComponent extends BaseComponent {
    @Prop()
    reload!: boolean;

    valuationsList: ValuationListItem[] = [];
    valuationModules: ValuationModuleListItem[] = [];
    valuationDataTablesList: ValuationDataTableListItem[] =  [];
    valuationDataTableFields: ValuationDataTableField[] = [];
    valuationTableInstanceList: ValuationTableInstance[] = [];
    dataFieldsInSelectedModule: number[] = [];
    tableIdsInSelectedModule: number[] = [];
    fieldsInModule: ValuationDataTableFieldInModule[] = [];
    valuationModulesGrid: InlineEdit<ValuationModuleListItem>;

    selectedInstanceId: number | null = null;
    selectedInstance: Valuation | null = null;
    selectedModule: ValuationModule | null = null;
    selectedModuleUnModifiedJson: string | null = null;
    selectedDataTable: number | null = null;
    selectedDataTableField: number | null = null;
    selectedTableInstance: number | null = null;
    selectedPhotoType: number | null = null;
    selectedGraphType: number | null = null;
    selectedTextField: RichTextEditorFieldsEnum = RichTextEditorFieldsEnum.None;
    activeDataField: Element | null = null;

    dataLoading: boolean = false;
    moduleLoading: boolean = false;
    pageBreakBefore: boolean = false;
    pageBreakAfter: boolean = false;
    saveLoading: boolean = false;
    deleteLoading: boolean = false;
    moduleChanged: boolean = false;
    moduleChangeInProgress = false;
    showExtra: boolean = false;

    ValuationModuleDisplayEnum = ValuationModuleDisplayEnum;
    ValuationModulePageBreakEnum = ValuationModulePageBreakEnum;
    RichTextEditorFieldsEnum = RichTextEditorFieldsEnum;
    ValuationModuleTypeEnum = ValuationModuleTypeEnum;
    
    moduleHeader: string = "";
    moduleText: string = "";
    moduleNameFilter: string = "";
    moduleNameFilterTimer: NodeJS.Timeout | null = null;
    modulePageIdx: number = 1;

    editorHeight: number = 0;
    testRunning: boolean = false;
    selection: NodeSelection = new NodeSelection();

    toolbarSettings: ToolbarSettingsModel = {
        items: ["Bold", "Italic", "Underline", "SuperScript",
            <any>{
                tooltipText: 'Uppercase',
                click: this.addCustomFormat.bind(this, 'uppercase'),
                template: '<button class="e-tbar-btn e-btn" tabindex="-1" style="width:100%"><div class="e-tbar-btn-text" style="font-weight: 500;"><span class="e-btn-icon e-upper-case e-icons"></span></div></button>'
            },
            "|", 'OrderedList', 'UnorderedList',
            "FontSize", 'Alignments', "FontColor", "BackgroundColor", "|",
            "Formats", "ClearFormat", "|",
            "SourceCode",
        ]
    };

    formats: FormatModel = {
        width: '60px',
        types: [ 
            { text: this.translate('Paragraph'), value: 'P', cssClass: 'e-paragraph' },
            { text: this.translate('HeaderOne'), value: 'H1', cssClass: 'e-h1' },
            { text: this.translate('HeaderTwo'), value: 'H2', cssClass: 'e-h2' }, 
            { text: this.translate('HeaderThree'), value: 'H3', cssClass: 'e-h3' } 
        ]
    };
    headerFormats: FormatModel = {
        width: this.formats.width,
        types: [ 
            this.formats.types![1],
            this.formats.types![2],
            this.formats.types![3]
        ],
        default: this.formats.types![2].text
    };

    fontColor: FontColorModel = {
        colorCode: { Custom: ['#13385c'] },
        default: '#13385c',
    }

    fontSize: FontSizeModel = {
        default: '8.5pt',
        width: '35px',
        items: [ 
            { text: '8'  , value: '8pt'  },
            { text: '8.5', value: '8.5pt'},
            { text: '9'  , value: '9pt'  },
            { text: '10' , value: '10pt' },
            { text: '11' , value: '11pt' },
            { text: '12' , value: '12pt' },
            { text: '14' , value: '14pt' },
            { text: '16' , value: '16pt' },
            { text: '18' , value: '18pt' },
            { text: '20' , value: '20pt' },
            { text: '22' , value: '22pt' },
            { text: '24' , value: '24pt' },
            { text: '26' , value: '26pt' },
            { text: '27' , value: '27pt' },
            { text: '28' , value: '28pt' },
            { text: '30' , value: '30pt' },
            { text: '32' , value: '32pt' },
            { text: '34' , value: '34pt' },
            { text: '36' , value: '36pt' },
            { text: '38' , value: '38pt' },
            { text: '40' , value: '40pt' }
        ]
    };

    backgroundColor: BackgroundColorModel = {
        colorCode: { Custom: ['#00ff00'] },
        default: '#00ff00',
    };

    photoTypes: MDataType[] = [
        { value: ValuationModulePictureTypeEnum.FrontPage, label: this.translate('FrontPage') },
        { value: ValuationModulePictureTypeEnum.Photo, label: this.translate('Photo') },
        { value: ValuationModulePictureTypeEnum.Map, label: this.translate('Map') },
        { value: ValuationModulePictureTypeEnum.Zoning, label: this.translate('Zoning') },
        { value: ValuationModulePictureTypeEnum.ValuerFirst, label: this.translate('ValuerFirst') },
        { value: ValuationModulePictureTypeEnum.ValuerSecond, label: this.translate('ValuerSecond') },
        { value: ValuationModulePictureTypeEnum.MapWithBorder, label: this.translate('MapWithBorder')},
        { value: ValuationModulePictureTypeEnum.SiteMap, label: this.translate('SiteMap')},
        { value: ValuationModulePictureTypeEnum.FloorPlan, label: this.translate('FloorPlan')},
        { value: ValuationModulePictureTypeEnum.Interior1, label: this.translate('Interior1')},
        { value: ValuationModulePictureTypeEnum.Interior2, label: this.translate('Interior2')},
        { value: ValuationModulePictureTypeEnum.EnvironmentalImpact, label: this.translate('EnvironmentalImpact')}
    ];

    graphTypes: MDataType[] = [
        { value: ValuationModuleGraphTypeEnum.NumberOfContracts, label: this.translate('NumberOfContractsGraph') },
        { value: ValuationModuleGraphTypeEnum.Percentage, label: this.translate('PercentageGraph') },
        { value: ValuationModuleGraphTypeEnum.Operation, label: this.translate('OperationGraph') },
        { value: ValuationModuleGraphTypeEnum.AreaDistribution, label: this.translate('AreaDistributionGraph') },
        { value: ValuationModuleGraphTypeEnum.OperationNet, label: this.translate('OperationNetGraph') },
        { value: ValuationModuleGraphTypeEnum.StreamRate, label: this.translate('StreamRateGraph') },
        { value: ValuationModuleGraphTypeEnum.AreaDistributionVacancy, label: this.translate('AreaDistributionVacancyGraph') },
        { value: ValuationModuleGraphTypeEnum.FutureEconomicVacancy, label: this.translate('FutureEconomicVacancyGraph') },
        { value: ValuationModuleGraphTypeEnum.ContractedAssessedRent, label: this.translate('ContractedAssessedRentGraph') }
    ];

    constructor() {
        super();
        this.valuationModulesGrid = new InlineEdit<ValuationModuleListItem>(this, "valuationModulesTable", this.loadValuationModules, undefined, undefined, this.newValuationModule, undefined, this.hasNoErrors);
    }

    mounted() {
        window.addEventListener('resize', this.resizeEditor);
        window.addEventListener('click', this.removeTagEventHandler);
    }
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeEditor);
        window.removeEventListener('click', this.removeTagEventHandler);
    }

    @Watch("reload")
    reloadWatcher() {
        if (this.reload == true) {
            this.doReload().then(x => {
                (<any> this).$refs.valuationModulesTable.doLayout();
            })
        }
    }

    @Watch("selectedDataTable")
    selectedDataTableWatcher() {
        if (this.selectedDataTable) {
            let table = this.valuationDataTablesList[this.selectedDataTable - 1];
            this.valuationDataTableFields = [];
            this.selectedDataTableField = null;
            dataService.getValuationDataTableFields(table.spId)
                .then(x => {
                    this.valuationDataTableFields = x.data;
                });
        }
    }

    doReload() : Promise<void> {
        return new Promise(async (resolve) => {
            this.dataLoading = true;
            await dataService.getValuationInstanceList().then(x => this.valuationsList = x.data);
            await dataService.getValuationDataTableList().then(x => this.valuationDataTablesList = x.data);
            await dataService.getValuationTableInstanceList().then(x => {
                this.valuationTableInstanceList = x.data;
                this.dataLoading = false;
                this.valuationModulesGrid.loading = true;
            });
            this.modulePageIdx = 1;
            await dataService.getValuationModuleList(this.modulePageIdx++, 500)
                .then(async x => {
                    this.valuationModules = x.data;
                    await this.valuationModulesGrid.reload();
                    this.valuationModulesGrid.loading = false;
                    resolve();
                });
        })
    }

    async confirmChange(row: BaseViewModel<ValuationModuleListItem>) {
        if (this.moduleIsModified()) {
            await this.$confirm(this.translate('ConfirmValuationModule'), this.translate('Confirm'), {
                distinguishCancelAndClose: true,
                confirmButtonText: this.translate('Yes'),
                cancelButtonText: this.translate('No')
            })
                .then(async () => {
                    await this.save();
                    await this.getFullModule(row);
                    (<any> this).$refs.valuationModulesTable.setCurrentRow(this.valuationModulesGrid.currentItem);
                    return;
                })
                .catch(async action => {
                    if (action === 'cancel') {
                        await this.getFullModule(row);
                        (<any> this).$refs.valuationModulesTable.setCurrentRow(this.valuationModulesGrid.currentItem);
                        return;
                    }
                    else {
                        return;
                    }
                });
        }
        else {
            await this.getFullModule(row);
        }
    }

    getFullInstance(instance: number) {
        this.valuationModulesGrid.loading = true;
        dataService.getValuationModuleList(1, 400, instance)
            .then(x => {
                // this.selectedInstance = x.data;
                this.valuationModules = x.data;
                this.valuationModulesGrid.reload();
            });
    }

    async getFullModule(module: BaseViewModel<ValuationModuleListItem>) {
        this.moduleLoading = true;
        this.valuationModulesGrid.itemSelected(module);
        this.selectedDataTable = null;
        this.selectedDataTableField = null;
        this.selectedTableInstance = null;
        this.selectedPhotoType = null;
        this.selectedGraphType = null;
        this.selectedTextField = RichTextEditorFieldsEnum.None;
        await dataService.getValuationModuleById(module.entity.id)
            .then(async x => {
                this.selectModule(x.data);
                this.fieldsInModule = [];
                this.fieldsInModule = await dataService.getValuationDataTableFieldsInModule(this.selectedModule!.id).then(x => x.data);
            
                // Header
                if (this.selectedModule!.pageHeader) {
                    await this.dbTextToHtml(this.selectedModule!.pageHeader, this.fieldsInModule).then((x: string) => this.moduleHeader = x );
                }
                else this.moduleHeader = "";

                // Text
                await this.dbTextToHtml(this.selectedModule!.text, this.fieldsInModule).then((x: string) => 
                    this.moduleText = x
                );
                this.moduleLoading = false;
                this.resizeEditor();
            });
    }

    removeTagEventHandler(e: MouseEvent) {
        if (e.target && e.target instanceof HTMLElement && (<HTMLElement>e.target).classList.contains('vdok-tag')) {
            (<HTMLElement>e.target).remove();
        }
    }

    replaceAt(text: string, index: number, replacement: string, target: string): string {
        return text.substr(0, index) + replacement + text.substr(index + target.length);
    }

    findEndTag(str: string, tagName: string, startIdx: number) {
        let startTags = 0;
        let startTag = '<'+tagName;
        let endTag = '</'+tagName+'>';
        let cursor = startIdx;
        let iterations = 0;
        
        while (iterations++ < 1000) {
            let startTagIdx = str.indexOf(startTag, cursor);
            let endTagIdx = str.indexOf(endTag, cursor);

            let cStartIdx = startTagIdx == -1 ? Number.MAX_VALUE : startTagIdx + startTag.length;
            let cEndIdx = endTagIdx + endTag.length;
            cursor = cStartIdx < cEndIdx ? cStartIdx : cEndIdx;

            if (endTagIdx == -1) return null;

            if (startTagIdx != -1 && startTagIdx < endTagIdx) {
                startTags++;
                continue;
            }
            if (startTags == 0)
                return endTagIdx;
            
            startTags--;
        }
    }

    triggerChange() {
        if (!this.moduleChangeInProgress) {
            this.moduleChanged = true;
        }
        setTimeout(() => {
            (<any> this).$refs.pageHeaderRTE.refreshUI();
        }, 200);
    }

    clearSelect() {
        this.selectedInstanceId = null;
        this.selectedInstance = null;
        this.selectedTextField = RichTextEditorFieldsEnum.None;
        this.modulePageIdx = 1;
        this.page();
    }

    addDataTableField() {
        let text: string = "";
        let dataTable: ValuationDataTableListItem;
        let field: ValuationDataTableField;
        
        if (this.selectedDataTable && this.selectedDataTableField) {
            let instance = this.currentInstance();
            this.setInstanceFocus(instance);

            dataTable = this.valuationDataTablesList[this.valuationDataTablesList.map(x => x.id).indexOf(this.selectedDataTable!)];
            field = this.valuationDataTableFields[this.valuationDataTableFields.map(x => x.id).indexOf(this.selectedDataTableField!)];

            // text = '<span contentEditable="false" class="vdok-tag el-tag el-tag--info el-tag--small tag-font-fix tag-data" title="' + field.id + '">' + dataTable.name + ' - ' + field.name + '<i class="el-tag__close el-icon-edit tag-edit-icon"></i><i class="el-tag__close el-icon-close"></i></span><span>&nbsp;</span>';
            text = `<span class="vdok-tag el-tag el-tag--small tag-data" title="${field.id}" contentEditable="false">${dataTable.name} - ${field.name}</span>`;
            text = `<span>${ text }<span> </span></span>`;
            instance.executeCommand('insertHTML', text);
        }
    }

    addTableInstance() {
        let text: string = "";
        let table: ValuationTableInstance;

        if (this.selectedTableInstance) {
            table = this.valuationTableInstanceList[this.valuationTableInstanceList.map(x => x.id).indexOf(this.selectedTableInstance!)];

            let instance = this.currentInstance();
            this.setInstanceFocus(instance);

            // text = '<span contentEditable="false" class="vdok-tag el-tag el-tag--small tag-font-fix tag-table" title="' + table.id + '">' + table.name + '<i class="el-tag__close el-icon-close"></i></span><span>&nbsp;</span>';
            text = `<span class="vdok-tag el-tag el-tag--small tag-table" title="${table.id}" contentEditable="false">${table.name}</span>`;

            if (instance.value == null || instance.value.length == 0) {
                instance.executeCommand('insertHTML', text);
                instance.refreshUI();
            }
            else {
                this.$message.error('Måste vara tomt fält');
            }
        }
    }

    addPhoto(type: ValuationModulePictureTypeEnum) {
        let rte = (<RichTextEditor>(<any> this).$refs.textRTE);
        if (rte.getHtml() && (rte.getHtml().indexOf("tag-photo") !== -1 || rte.getHtml().indexOf("tag-pic") !== -1 || rte.getHtml().indexOf("tag-graph") !== -1)) {
            this.$message(this.translate("Warning_onePhotoPerModule"));
            return;
        }

        let photoType = this.photoTypes.find((x: MDataType) => x.value == type);
        let name: string = photoType!.label;
        let photoStyle = [ValuationModulePictureTypeEnum.ValuerFirst, ValuationModulePictureTypeEnum.ValuerSecond].includes(type) ? 'tag-photo' : 'tag-pic';

        this.setInstanceFocus(rte);
        // let text = `<span contentEditable="false" class="vdok-tag el-tag el-tag--success el-tag--small tag-font-fix ${photoStyle}" title="${type}">${this.translate("PhotoTag")}${name}<i class="el-tag__close el-icon-close"></i></span><span>&nbsp;</span>`;
        let text = `<span class="vdok-tag el-tag el-tag--small ${photoStyle}" title="${type}" contentEditable="false">${this.translate("PhotoTag")}${name}</span>`;
        rte.executeCommand('insertHTML', text);
    }

    addGraph(type: ValuationModuleGraphTypeEnum) {
        let rte = (<RichTextEditor>(<any> this).$refs.textRTE);
        if (rte.getHtml() && (rte.getHtml().indexOf("tag-photo") !== -1 || rte.getHtml().indexOf("tag-pic") !== -1 || rte.getHtml().indexOf("tag-graph") !== -1)) {
            this.$message(this.translate("Warning_onePhotoPerModule"));
            return;
        }
                
        let graphType = this.graphTypes.find((x: MDataType) => x.value == type);
        let name: string = graphType!.label;

        this.setInstanceFocus(rte);
        // let text = '<span contenteditable="false" class="vdok-tag el-tag el-tag--small tag-font-fix tag-graph" title="' + type + '">' + this.translate("Graph") + ': ' + name + '<i class="el-tag__close el-icon-close"></i></span><span>&nbsp;</span>';
        let text = `<span class="vdok-tag el-tag el-tag--small tag-graph" title="${type}" contentEditable="false">${this.translate("Graph")}: ${name}</span>`;
        rte.executeCommand('insertHTML', text);
    }

    addSpecialField(button: string) {
        let text = '';
        switch (button) {
            case "currency":
                // text = '<span contentEditable="false" class="vdok-tag el-tag el-tag--success el-tag--small tag-font-fix TAG_CURRENCY">Val<i class="el-tag__close el-icon-close"></i></span><span>&nbsp;</span>';
                text = `<span class="vdok-tag el-tag el-tag--small TAG_CURRENCY" contentEditable="false">Val</span>`;
                break;
            case "currencyT":
                // text = '<span contentEditable="false" class="vdok-tag el-tag el-tag--success el-tag--small tag-font-fix TAG_CURRENCY_THOUSAND">ValT<i class="el-tag__close el-icon-close"></i></span><span>&nbsp;</span>';
                text = `<span class="vdok-tag el-tag el-tag--small TAG_CURRENCY_THOUSAND" contentEditable="false">ValT</span>`;
                break;
            case "currencyM2":
                // text = '<span contentEditable="false" class="vdok-tag el-tag el-tag--success el-tag--small tag-font-fix TAG_CURRENCY_PER_M2 ">Val/m2<i class="el-tag__close el-icon-close"></i></span><span>&nbsp;</span>';
                text = `<span class="vdok-tag el-tag el-tag--small TAG_CURRENCY_PER_M2" contentEditable="false">Val/m2</span>`;
                break;
            case "space":
                text = `<span class="vdok-tag el-tag el-tag--small TAG_NBSP" contentEditable="false">_</span>`;
                break;
            case "dash":
                // text = '<span contentEditable="false" class="vdok-tag editor-inline-tag tag-font-fix TAG_SOFT_HYPHEN">-</span><span>&nbsp;</span>';
                text = `<span class="vdok-tag el-tag el-tag--small TAG_SOFT_HYPHEN" contentEditable="false">-</span>`;
                break;
        }

        const instance = this.currentInstance();
        this.setInstanceFocus(instance);
        instance.executeCommand('insertHTML', text);
    }

    addCustomFormat(format: 'uppercase', e: any) {
        const instance = this.currentInstance();
        this.setInstanceFocus(instance);

        const span = document.createElement('span');
        switch (format) {
            case 'uppercase': span.style.textTransform = 'uppercase'; break;
        }

        this.selection.getRange(document).surroundContents(span);
    }

    async save() {
        this.saveLoading = true;

        this.selectedModule!.pageHeader = this.selectedModule!.hasPageHeader ? this.parseHtmlToDbString(this.moduleHeader, true) : null;
        this.selectedModule!.text = this.parseHtmlToDbString(this.moduleText, false);
        
        let nonDuplicate = [...new Set(this.dataFieldsInSelectedModule)];
        let tableIds = [...new Set(this.tableIdsInSelectedModule)];

        dataService.saveValuationModule(this.selectedModule!, nonDuplicate, tableIds)
            .then(x => {
                this.selectModule(x.data);
            })
            .catch(() => {
                
            })
            .finally(() => {
                this.valuationModulesGrid.loading = true;
                this.modulePageIdx = 1;
                dataService.getValuationModuleList(this.modulePageIdx++, 50)
                    .then(async x => {
                        this.valuationModules = x.data;
                        await this.valuationModulesGrid.reload();
                        this.valuationModulesGrid.loading = false;
                    });
                this.saveLoading = false;
            });
    }
    
    copy() {
        let newModule = Object.assign({}, this.selectedModule);
        newModule.id = 0;
        this.selectModule(newModule);
        this.valuationModulesGrid.currentItem = null;
        (<any> this).$refs.valuationModulesTable.setCurrentRow();
        this.$message(this.translate("CopyHasBeenCreatedOf") + newModule.name + '.');
    }

    async deleteModule() {
        this.confirm("ConfirmDeleteModule").then(async () => {
            try {
                this.deleteLoading = true;
                this.valuationModulesGrid.loading = true;
                await dataService.deleteValuationModule(this.selectedModule!.id);
                this.selectModule(null);
                this.modulePageIdx = 1;
                this.valuationModules = await dataService.getValuationModuleList(this.modulePageIdx++, 50).then(x => x.data);
                await this.valuationModulesGrid.reload();
                this.getFullModule(this.valuationModulesGrid.items[0]);
                this.$message.success(this.translate('ModuleHasBeenDeleted'));
            } catch (error: any) {
                this.$message.error({
                    type: 'error',
                    message: this.translate('RowDeleteError') + error.message
                });
            } finally {
                this.deleteLoading = false;
                this.valuationModulesGrid.loading = false;
            }
        });           
    }

    resizeEditor() {
        if(this.selectedModule && (<any> this).$refs.textRTE) {
            this.editorHeight = document.getElementById("editorRow") != null ? document.getElementById("editorRow")!.clientHeight : 0;
            setTimeout(() => {
                (<RichTextEditor>(<any> this.$refs.textRTE).ej2Instances).refreshUI();
            }, 300);
        }
    }

    addModule() {
        let u: User = this.$store.getters.getUser;
        this.selectModule({
            id: 0,
            displayCondition: 1,
            displayInIndex: false,
            drivingSPId: null,
            emptyRowAfter: true,
            fontSize: 0,
            hasPageHeader: false,
            name: "",
            pageBreakAfter: false,
            pageBreakBefore: false,
            pageHeader: null,
            text: "",
            type: 1,
            user: <FiaDataUser> {
                id: u.id,
                email: u.email,
                name: u.realName
            }
        });
        this.moduleHeader = '';
        this.moduleText = '';
        this.valuationModulesGrid.currentItem = null;
    }

    loadValuationModules(): AxiosPromise<ValuationModuleListItem[]> {
        return Promise.resolve(<AxiosResponse> {
            data: this.valuationModules
        });
    }

    newValuationModule(): Valuation{
        return <Valuation> {};
    }

    async page() {
        await dataService.getValuationModuleList(this.modulePageIdx++, 50, this.selectedInstanceId ? this.selectedInstanceId : 0, this.moduleNameFilter).then(x => {
            this.valuationModules = this.valuationModules.concat(x.data);
            this.valuationModulesGrid.reload();
        });
    }

    filterByName(delay: number = 500) {
        if (this.moduleNameFilterTimer) {
            clearTimeout(this.moduleNameFilterTimer);
        }
        this.moduleNameFilterTimer = setTimeout(async() => {
            this.modulePageIdx = 1;
            await dataService.getValuationModuleList(this.modulePageIdx++, 50, this.selectedInstanceId ? this.selectedInstanceId : 0, this.moduleNameFilter).then(x => {
                this.valuationModules = x.data;
                this.valuationModulesGrid.reload();
            });
        }, delay);
    }

    async dbTextToHtml(text: string | null, fields: ValuationDataTableFieldInModule[]): Promise<string> {
        if (text == null) return "";
        if (text.length == 0) return "";
        
        let i = 0;
        let styled = text;
        
        // Font + Font size
        if (styled.indexOf("FNT") != -1) {
            let arr = styled.match(/<{FNT:\d+\,?\d*}>/g);
            if (arr && arr.length > 0) {
                arr.forEach((x,i) => {
                    styled = styled.replace(x, x.replace(",", "."));
                })
            }
            styled = styled.replace(/(<{FNT:\d*\.?\d*}>)/g, e => {
                let v = e.match(/(\d+\.?\d*)/)![0];
                v = v.replace(',', '.');
                return '<span style="font-size: ' + v + 'pt;">'; 
            });
            styled = styled.replace(/<{FNT}>/g, '</span>')
        }

        let formatRules = [
            { fiaTag: /<{B}>/g, htmlTagOpen: '<strong>', htmlTagClose: '</strong>' },
            { fiaTag: /<{I}>/g, htmlTagOpen: '<em>', htmlTagClose: '</em>' },
            { fiaTag: /<{U}>/g, htmlTagOpen: '<span style="text-decoration: underline;">', htmlTagClose: '</span>' },
            { fiaTag: /<{S}>/g, htmlTagOpen: '<sup>', htmlTagClose: '</sup>' },
            { fiaTag: /<{H1}>/g, htmlTagOpen: '<h1>', htmlTagClose: '</h1>' },
            { fiaTag: /<{H2}>/g, htmlTagOpen: '<h2>', htmlTagClose: '</h2>' },
            { fiaTag: /<{H3}>/g, htmlTagOpen: '<h3>', htmlTagClose: '</h3>' },
            { fiaTag: /<{HL}>/g, htmlTagOpen: '<span style="background-color: rgb(0, 255, 0);">', htmlTagClose: '</span>' },
            { fiaTag: /<{FNTBLUE}>/g, htmlTagOpen: '<font color="#13385c">', htmlTagClose: '</font>' },
        ]

        for (let j = 0; j < formatRules.length; j++) {
            styled = styled.replace(formatRules[j].fiaTag, () => {
                let index = i;
                i++;
                return index % 2 == 0 ? formatRules[j].htmlTagOpen : formatRules[j].htmlTagClose;
            });
            i = 0;
        }        

        // New line
        styled = styled.replace(/<{NL}>/g, "<br>");

        // Column break
        styled = styled.replace(/<{CB}><{CB}>/g, `<span class="vdok-tag el-tag el-tag--small tag-column-break" contentEditable="false">${ this.translate("ColumnBreak") }</span>`);
        styled = styled.replace(/<{CBC}><{CBC}>/g, `<span class="vdok-tag el-tag el-tag--small tag-column-break-continuous" contentEditable="false">${ this.translate("ColumnBreakContinuous") }</span>`);
        styled = styled.replace(/<{CD}>1<{CD}>/g, `<span class="vdok-tag el-tag el-tag--small tag-column-definition-1" contentEditable="false">${ this.translate("ColumnDefinition1") }</span>`);
        styled = styled.replace(/<{CD}>2<{CD}>/g, `<span class="vdok-tag el-tag el-tag--small tag-column-definition-2" contentEditable="false">${ this.translate("ColumnDefinition2") }</span>`);

        // Currency
        styled = styled.replace(/<\[M\]>/g, `<span class="vdok-tag el-tag el-tag--small TAG_CURRENCY" contentEditable="false">Val</span>`);
        styled = styled.replace(/<\[K\]>/g, `<span class="vdok-tag el-tag el-tag--small TAG_CURRENCY_THOUSAND" contentEditable="false">ValT</span>`);
        styled = styled.replace(/<\[L\]>/g, `<span class="vdok-tag el-tag el-tag--small TAG_CURRENCY_PER_M2" contentEditable="false">Val/m2</span>`);

        // Hard space
        styled = styled.replace(/<{NBSP}>/g, `<span class="editor-inline-tag" contentEditable="false">_</span>`);
        styled = styled.replace(/&nbsp;/g, `<span class="editor-inline-tag" contentEditable="false">_</span>`);

        // Soft dash
        styled = styled.replace(/<{SHY}>/g, `<span class="editor-inline-tag" contentEditable="false">-</span>`);

        // Doc
        styled = styled.replace(/#doc:\d+#/gi, e => {
            let id = parseInt(e.match(/\d+/)![0], 10);
            return `<span class="vdok-tag el-tag el-tag--small tag-document" contentEditable="false" title="${id}">${ this.translate("Document") }: ${id}</span>`;
        })

        // Graph
        styled = styled.replace(/#Graf:\d+#/gi, e => {
            let id = parseInt(e.match(/\d+/)![0], 10);
            let graphType = this.graphTypes.find(x => x.value == id);
            if (!graphType) {
                alert('error');
                return '';
            }
            let name = graphType.label;
            return `<span class="vdok-tag el-tag el-tag--small tag-graph" contentEditable="false" title="${id}">${ this.translate("Graph") }: ${name}</span>`;
        });

        // Photo
        styled = styled.replace(/#photo:\d#/gi, e => {
            let type: number = parseInt(e.match(/\d/)![0], 10);
            let name = this.photoTypes.find(x => x.value == type)!.label;
            return `<span class="vdok-tag el-tag el-tag--small tag-photo" contentEditable="false" title="${type}">${ this.translate("PhotoTag") }${name}</span>`;
        });

        // Pic
        styled = styled.replace(/#Pic:\d#/gi, e => {
            let type: number = parseInt(e.match(/\d/)![0], 10);
            let name = this.photoTypes.find(x => x.value == type)!.label;
            return `<span class="vdok-tag el-tag el-tag--small tag-pic" contentEditable="false" title="${type}">${ this.translate("PhotoTag") }${name}</span>`;
        });

        // Data field
        styled = styled.replace(/(<\[D\]>).+?(<\[D\]>)/g, e => {
            let id = e.match(/\d+F\d|\d+/);
            let field = fields[fields.map(x => x.id).indexOf(parseInt(id![0], 10))];
            return `<span class="vdok-tag el-tag el-tag--small tag-data" contentEditable="false" title="${ id }">${ this.translate("DataTag") }${ field.tableName } - ${ field.fieldName }</span>`;
        });

        // Tables
        styled = styled.replace(/(<\[T\]>)\d+?(<\[T\]>)/, e => {
            let id = e.match(/\d+F\d|\d+/);
            let table = this.valuationTableInstanceList[this.valuationTableInstanceList.map(x => x.id).indexOf(parseInt(id![0], 10))];
            return `<span class="vdok-tag el-tag el-tag--small tag-table" contentEditable="false" title="${ id }">${ this.translate("TableTag") }${ table.name }</span>`;
        });

        // Alignment
        styled = styled.replace(/<{L}>/g, () => {
            let index = i;
            i++;
            return index % 2 == 0 ? '<div style="text-align: left;">' : "</div>";
        });
        styled = styled.replace(/<{C}>/g, () => {
            let index = i;
            i++;
            return index % 2 == 0 ? '<div style="text-align: center;">' : "</div>";
        });
        styled = styled.replace(/<{R}>/g, () => {
            let index = i;
            i++;
            return index % 2 == 0 ? '<div style="text-align: right;">' : "</div>";
        });
        styled = styled.replace(/#caps#/g, () => {
            let index = i;
            i++;
            return index % 2 == 0 ? '<span style="text-transform: uppercase;">' : "</span>";
        });

        return styled;
    }

    parseText(text: string): string {
        if (text == null || text.length == 0) return "";

        let parsed = text;

        parsed = parsed.replace("\ufeff", "");
        // &
        parsed = parsed.replace(/&amp;/g, "&");
        // &nbsp;
        parsed = parsed.replace(/&nbsp;/g, " ");
        
        let dom = document.createElement('html');
        dom.innerHTML = parsed;

        const forEachClass = (className: string, cmd: (e: Element) => void) => {
            let elements = dom.getElementsByClassName(className);
            for (let i = elements.length - 1; i >= 0; i--) {
                cmd(elements[i]);
            }
        }
        const forSingle = (className: string, cmd: (e: Element) => void) => {
            let elements = dom.getElementsByClassName(className);
            if (elements.length > 0) {
                cmd(elements[0]);
            }
        }

        // Currency
        forEachClass('TAG_CURRENCY', (e) => { e.outerHTML = '<[M]>'; });
        forEachClass('TAG_CURRENCY_THOUSAND', (e) => { e.outerHTML = '<[K]>'; });
        forEachClass('TAG_CURRENCY_PER_M2', (e) => { e.outerHTML = '<[L]>'; });
        // Hard space
        forEachClass('TAG_NBSP', (e) => { e.outerHTML = '<{NBSP}>'; });
        // Soft dash
        forEachClass('TAG_SOFT_HYPHEN', (e) => { e.outerHTML = '<{SHY}>'; });
        // Graph
        forEachClass('tag-graph', (e) => { e.outerHTML = `#Graf:${ e.getAttribute('title') }#`; });
        // Photo
        forEachClass('tag-photo', (e) => {
            let id = parseInt(e.getAttribute('title')!.match(/\d+/g)![0], 10);
            e.outerHTML = `#Photo:${ id }#`; 
            this.dataFieldsInSelectedModule.push(id);
        });
        // Pic
        forEachClass('tag-pic', (e) => {
            let id = parseInt(e.getAttribute('title')!.match(/\d+/g)![0], 10);
            e.outerHTML = `#Pic:${ id }#`;
            this.dataFieldsInSelectedModule.push(id);
        });
        // Data
        forEachClass('tag-data', (e) => {
            let id = parseInt(e.getAttribute('title')!.match(/\d+/g)![0], 10);
            const innerHtml = e.innerHTML.replace(e.textContent!, `<[D]>${ id }<[D]>`);
            e.outerHTML = innerHtml;
            this.dataFieldsInSelectedModule.push(id);
        });

        // Only singles allowed
        // Column break
        forSingle('tag-column-break', (e) => { dom.innerHTML = '<{CB}><{CB}>'; });
        // Column definitions
        forSingle('tag-column-break-continuous', (e) => { dom.innerHTML = '<{CBC}><{CBC}>'; });
        forSingle('tag-column-definition-1', (e) => { dom.innerHTML = '<{CD}>1<{CD}>'; });
        forSingle('tag-column-definition-2', (e) => { dom.innerHTML = '<{CD}>2<{CD}>'; });
        // Table
        forSingle('tag-table', (e) => {
            let id = parseInt(e.getAttribute('title')!.match(/\d+/g)![0], 10);
            console.log(e.textContent);
            let innerhtml = `<[T]>${ e.getAttribute('title') }<[T]>`;
            this.tableIdsInSelectedModule.push(Number(e.getAttribute('title')));
            if (dom.textContent != null && dom.textContent!.replace(e.textContent!,'').length > 0) {
                innerhtml += `<{FNT:7}>${ dom.textContent!.replace(e.textContent!,'') }<{FNT}>`; // support expl.
            }
            dom.innerHTML = innerhtml;
        });

        // console.log(dom.getElementsByTagName('body')[0].innerHTML.replace(/&lt;/gi, '<').replace(/&gt;/gi, '>'));
        return dom.getElementsByTagName('body')[0].innerHTML.replace(/&lt;/gi, '<').replace(/&gt;/gi, '>').trim();
    }
    
    parseHtmlToDbString(text: string, isHeader: boolean) {
        if (text == null || text.length == 0) return "";

        if (isHeader && text.toLowerCase().indexOf("<h") == -1) {
            //Paragraphs are not allowed in module header. This function should not be neccessary since paragrapgh is not
            //an option in the format list, but this is Syncfusion :)
            text = "<h2>" + text + "</h2>";
        }
        
        text = this.parseText(text);

        return text;
    }

    currentInstance() {
        return this.selectedTextField == RichTextEditorFieldsEnum.Header ?
        (<RichTextEditor>(<any> this.$refs.pageHeaderRTE).ej2Instances) :
        (<RichTextEditor>(<any> this.$refs.textRTE).ej2Instances);
    }

    previousSelection: Range | null = null;

    onRteBlur() {
        if (this.selectedTextField === RichTextEditorFieldsEnum.Text) {
            this.previousSelection = this.currentInstance().getRange();
        }
    }

    onRteFocus(e: RichTextEditorFieldsEnum) {
        this.selectedTextField = e;
        this.previousSelection = null;
    }

    setInstanceFocus(instance: RichTextEditor) {
        if (this.previousSelection != null) {
            instance.selectRange(this.previousSelection);
            instance.focusIn();
        }
    }

    // Iterates through the module table list, loads the module and saves the module.
    // Generate a reference document, run this, and compare the results.
    async batchTest() {
        let i = 0;
        let interval = setInterval(async () => {
            try {
                i++;
                if (this.valuationModulesGrid.items[i].entity == null) clearInterval(interval);
                await this.getFullModule(this.valuationModulesGrid.items[i]);
                console.log(this.valuationModulesGrid.items[i].entity.name)
                this.selectedModule!.pageHeader = this.selectedModule!.hasPageHeader ? this.parseHtmlToDbString(this.moduleHeader, true) : null;
                this.selectedModule!.text = this.parseHtmlToDbString(this.moduleText, false);

                let nonDuplicate = [];
                nonDuplicate = [...new Set(this.dataFieldsInSelectedModule)];

                await dataService.saveValuationModule(this.selectedModule!, nonDuplicate, []);
                if (i > this.valuationModulesGrid.items.length - 1) {
                    console.log("done");
                    clearInterval(interval);
                }
            } catch {
                console.log("err" + ", " + this.valuationModulesGrid.items[i].entity.name);
            }
        }, 800);
    }

    selectModule(module: ValuationModule | null) {
        this.selectedModule = module;
        this.selectedModuleUnModifiedJson = JSON.stringify(module);
        this.moduleChanged = false;
        this.moduleChangeInProgress = true;
        setTimeout(() => {
            this.moduleChangeInProgress = false;
        }, 1500);
    }

    moduleIsModified() {
        return this.moduleChanged || 
            (this.selectedModuleUnModifiedJson && JSON.stringify(this.selectedModule) !== this.selectedModuleUnModifiedJson);
    }

    pageHeaderChanged() {
        nextTick(() => {
            (<any> this).$refs.pageHeaderRTE.refreshUI();
        })
    }
}