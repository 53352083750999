import BaseComponent from '@/components/base-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import { Loan, DevelopmentType, PrognosisParameter, AmortizationAmount, InterestRate, InterestType, AmortizationType } from '@/models';
import { AmortizationTypeEnum, InterestTypeEnum } from '@/constants/fia-constants';
import { mapGetters } from 'vuex';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import InlineEdit from '@/helpers/inline-edit';
import { AxiosPromise, AxiosResponse } from 'axios';
import { ValidationError } from '@/models/interfaces/ValidationError';
import InputTextComponent from '@/components/common/input-text/input-text';
import DialogComponent from '@/components/common/dialog/dialog';

@Component({
    components: {
        YearMonthPickerComponent,
        InputNumericComponent,
        InputTextComponent,
        DialogComponent
    },
    computed: mapGetters({
        developmentTypes: "getDevelopmentTypes",
        prognosisParameters: "getPrognosisParameters",
        interestTypes: "getInterestTypes",
        amortizationTypes: "getAmortizationTypes"
    })
})
export default class LoanDetailsComponent extends BaseComponent {
    @Prop()
    loan!: Loan;
    @Prop()
    complexErrors!: ValidationError[];

    tab: string = 'Loan';
    AmortizationType = AmortizationTypeEnum;
    InterestType = InterestTypeEnum;
    developmentTypes!: DevelopmentType[];
    prognosisParameters!: PrognosisParameter[];
    interestTypes!: InterestType[];
    amortizationTypes!: AmortizationType[];
    currentAmortizationType: AmortizationTypeEnum = AmortizationTypeEnum.Fixed;

    amortizationAmountsGrid: InlineEdit<AmortizationAmount>;
    interestRateGrid: InlineEdit<InterestRate>;

    interestRadio: number = 0;

    constructor() {
        super();
        this.amortizationAmountsGrid = new InlineEdit<AmortizationAmount>(this, "amortizationAmountsTable", this.loadAmortizationAmounts, undefined, undefined, this.newAmortizationAmount, undefined, this.hasNoErrors);
        this.amortizationAmountsGrid.sync = true;
        this.interestRateGrid = new InlineEdit<InterestRate>(this, "interestRateTable", this.loadIntrestRates, undefined, undefined, this.newIntrestRate, undefined, this.hasNoErrors);
        this.interestRateGrid.sync = true;
        this.amortizationAmountsGrid.useConfirmDeleteDialog = false;
        this.interestRateGrid.useConfirmDeleteDialog = false;
    }

    @Watch("loan", { immediate: true })
    loanWatcher() {
        this.amortizationAmountsGrid.reload();
        this.interestRateGrid.reload();
        this.currentAmortizationType = this.loan.amortization.amortizationTypeId;

        if(this.loan.interest.developmentTypeId)
            this.interestRadio = 1;
        else
            this.interestRadio = 0;
    }

    @Watch("interestRadio")
    interestRadioWatcher(value: number) {
        if (value == 1) {
            this.loan.interest.prognosisParameterId = null;
            this.loan.interest.developmentTypeId = 2;
        }
        else {
            this.loan.interest.developmentTypeId = null;
            this.loan.interest.prognosisParameterId = 5
        }
    }

    loadAmortizationAmounts(): AxiosPromise<AmortizationAmount[]> {
        return Promise.resolve(<AxiosResponse> {
            data: this.loan.amortization.amortizationAmounts
        });
    }

    amortizationTypeChanged() {
        if(this.loan.amortization.amortizationAmounts.length > 0) {
            this.confirm('ConfirmAmortizationTypeChange')
            .then(() => {
               this.loan.amortization.amortizationAmounts = [];
               this.currentAmortizationType = this.loan.amortization.amortizationTypeId;
               this.amortizationAmountsGrid.reload();
            })
            .catch(() => {
                this.loan.amortization.amortizationTypeId = this.currentAmortizationType;
            });
        }
    }

    newAmortizationAmount(): AmortizationAmount {
        let dates = this.amortizationAmountsGrid.entities.map(x => new Date(x.time)).sort(this.sortDates);
        let newDate = dates.length > 0 ? this.formatIsoDate(dates[dates.length - 1]) : this.loan.startDate;

        return <AmortizationAmount> {
            id: this.getNewId(this.amortizationAmountsGrid.entities),
            amortizationId: this.loan.amortization.id,
            percentageOfLoanAmount: this.loan.amortization.amortizationTypeId == AmortizationTypeEnum.Percentual ? 0 : null,
            time: newDate,
            yearlyAmount: this.loan.amortization.amortizationTypeId == AmortizationTypeEnum.Fixed ? 0 : null
        }
    }

    loadIntrestRates(): AxiosPromise<InterestRate[]> {
        return Promise.resolve(<AxiosResponse> { data: this.loan.interest.interestRates });
    }

    newIntrestRate(): InterestRate {
        let dates = this.interestRateGrid.entities.map(x => new Date(x.time)).sort(this.sortDates);
        let newDate = dates.length > 0 ? this.formatIsoDate(dates[dates.length - 1]) : this.loan.startDate;

        return <InterestRate> {
            id: this.getNewId(this.interestRateGrid.entities),
            interestId: this.loan.interest.id,
            rate: 0,
            time: newDate
        }
    }

    sortDates(a: Date, b: Date)
    {
        return a.getTime() - b.getTime();
    }

    canAddInterestRate() : boolean {
        return this.loan.interest.interestTypeId !== InterestTypeEnum.Dependent;
    }

    addWithDeselect() {
        this.interestRateGrid.deselect();
        this.interestRateGrid.add();
    }
}