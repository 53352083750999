import { Component, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";
import dataService from "@/services/data-service";
import { Currency, PropertyVersion, Job } from "@/models";
import { CurrencyUnit } from "@/components/web-reports/rent-composition/rent-composition";
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import BaseComponent from "@/components/base-component";
import { ContractRecoveriesAdjustment } from "@/models/ContractRecoveriesAdjustment";
import { ContractListItem } from "@/models/interfaces/ContractListItem";
import { RecoveryAdjustment } from "@/models/RecoveryAdjustment";
import moment from "moment";
import { DateFormat } from "@/constants/fia-constants";
import { CurrencyHelper } from "@/helpers/currency";
import { ElTableColumn } from "element-ui/types/table-column";
import { GetContractRecoveriesAdjustmentRequest } from "@/models/interfaces/request/GetContractRecoveriesAdjustmentRequest";
import { BusMessage, RecoveriesChangedMessage, PerformCalculationMessage } from "@/models/messages/messages";
import { ErrorHandling } from "@/helpers/error-handling";
import { AxiosError } from "axios";
import { SignalRMessage } from "@/models/interfaces/signalR/SignalRMessage";
import { MessageType } from "@/models/interfaces/signalR/MessageType";

@Component({
    computed: mapGetters({
        propertyVersion: "getPropertyVersion",
    }),
    components: {
        InputNumericComponent,
        YearMonthPickerComponent
    }
})
export default class ContractRecoveriesAdjustmentComponent extends BaseComponent {

    propertyVersion!: PropertyVersion;
    currencyUnit = CurrencyUnit;

    signalRMsgHandler: any;

    @Prop()
    contracts!: ContractListItem[];

    contractRecoveries: ContractRecoveriesAdjustment[] = [];
    calculationYear = this.newIsoDateMonth();
    firstIncrement = this.newIsoDateMonth();
    loading = false;
    error: string | null = null;

    mounted() {
        this.signalRMsgHandler = (message: SignalRMessage) => this.onSignalRMessage(message);
        this.$signalrHub.$on("HubMessage", this.signalRMsgHandler);
        this.load();
    }

    beforeDestroy() {
        this.$signalrHub.$off("HubMessage", this.signalRMsgHandler);
    }

    load() {
        if (!this.propertyVersion || !this.propertyVersion.calculationValue) {
            this.error = this.translate('BadRequest_CalculationRequired');
            return;
        }
        this.calculationYear = this.propertyVersion.calculationValue.startDate;
        this.firstIncrement = moment(this.propertyVersion.calculationValue.startDate).add(1, "year").month(0).date(1).format(DateFormat);
        
        this.loading = true;
        const request: GetContractRecoveriesAdjustmentRequest = {
            versionId: this.propertyVersion.id,
            contractIds: this.contracts.map(x => x.id),
            calculationYear: new Date(this.calculationYear).getFullYear(),
            calculationEndYear: -1, 
            suppressRecalculation: false,
            //NR 2024-10-08
            //Disable calculationEndYear condition by assigning -1.
            //We only want to get recoveries that exist the first year of the calulation.
            //If calulcationEndYear is assigned, we will also get recoveries that start later than year1 but before calulation end.
        }
        dataService.getContractRecoveriesAdjustment(request).then(x => {
            this.contractRecoveries = x.data.slice(0, -3);
            this.error = null;
        }).catch((err: AxiosError) => {
            const errorText = ErrorHandling.GetErrorText(err)
            this.error = this.translate(errorText);
        }).finally(() => {
            this.loading = false;
            const recoveriesTable = (<any> this).$refs.recoveriesTable;
            if (recoveriesTable) {
                recoveriesTable.doLayout();
            }
        });
    }

    clear() {
        this.contractRecoveries = [];
        this.error = null;
    }

    async save() {
        if (this.contractRecoveries.length === 0 || this.error) {
            return;
        }
        this.loading = true;
        await dataService.saveContractRecoveries({
            recoveryAdjustments: this.contractRecoveries,
            calculationYear: new Date(this.calculationYear).getFullYear(),
            firstIncrement: this.firstIncrement,
            calculationEndYear: -1 
            //NR 2024-10-08
            //Disable calculationEndYear condition by assigning -1.
            //We only want to get recoveries that exist the first year of the calulation.
            //If calulcationEndYear is assigned, we will also get recoveries that start later than year1 but before calulation end.
        })
        .then(async x => {
            this.$notify.success({
                title: '',
                message: this.translate('RecoveriesUpdated')
            });
            await this.$store.dispatch("sendBusMessage", <BusMessage<RecoveriesChangedMessage>>{ type: "RecoveriesChanged" });
        }).catch(err => {
            console.error(err);
            this.$notify.error({
                title: this.translate('Error'),
                message: this.translate('ErrorActionFailed')
            });
        }).finally(() => {
            this.loading = false;
        });
    }

    async calculate() {
        this.$store.dispatch("sendBusMessage", <BusMessage<PerformCalculationMessage>>{ type: "PerformCalculation" });
        this.loading = true;
        this.$notify.success({
            title: '',
            message: this.translate('CalculationStarted')
        });
    }

    onSignalRMessage(message: SignalRMessage): any {
        console.debug(`Calculation update - ${message.type}`);
        const job = JSON.parse(message.data) as Job;
        if (this.propertyVersion.id && job.identityId && this.propertyVersion.id.toString() != job.identityId.toString()) {
            return;
        }
        
        if (message.type === MessageType.JobFinished) {
            this.load();
            this.loading = false;
            return;
        }
        if (message.type === MessageType.JobFailed) {
            this.$notify.error({
                title: '',
                message: this.translate('CalculationError')
            });
            this.loading = false;
            return;
        }
    }

    onPaste(event:ClipboardEvent, row: ContractRecoveriesAdjustment, typeOfRecovery: 'heat' | 'misc' | 'conversion' | 'tax') {
        if (!document.activeElement || !(<HTMLElement>document.activeElement).blur
            || !event.clipboardData || !event.clipboardData.getData('text')) {
            return;
        }

        (<HTMLElement>document.activeElement).blur();
        let pastedText = event.clipboardData.getData('text');
        const pastedRows = pastedText.split('\n').map(x => x.replace(/\s/g, ''));
        
        if (pastedRows.some(r => r.split('\t').length > 1)) {
            this.$notify.warning({
                title: this.translate('ValidationWarning'),
                message: this.translate('PasteErrorMultipleColumns')
            });
            return;
        }

        const selectedRowIdx = this.contractRecoveries.indexOf(row);
        for (let i = selectedRowIdx, j = 0; i < this.contractRecoveries.length && j < pastedRows.length; i++, j++) {
            let newVal = parseFloat(pastedRows[j].trim().replace(',','.'));
            if (!isFinite(newVal)) {
                continue;
            }
            if (typeOfRecovery == 'tax') {
                newVal *= 0.01;
            }
            if (this.contractRecoveries[i][typeOfRecovery] && this.contractRecoveries[i][typeOfRecovery].isValid) {
                this.contractRecoveries[i][typeOfRecovery].newValue = newVal;
            }
        }
    }

    getValidation(recovery: RecoveryAdjustment) : { class: string|undefined, text: string|undefined, any: boolean } {
        if (recovery.containsMultiplePaymentStreams) {
            return { class: 'disabled', text: this.translate('Error_MultiplePaymentStreams'), any: true };
        }
        if (recovery.containsIndexStreams) {
            return { class: 'disabled', text: this.translate('Error_IndexStreams'), any: true };
        }
        if (recovery.initialValue != 0 && recovery.newValue == 0) {
            return { class: 'warning', text: this.translate('Warning_WillBeRemoved'), any: true };
        }
        if (recovery.containsMultipleRecoveries) {
            return { class: 'warning', text: this.translate('Warning_MultipleRecoveries'), any: true };
        }
        return { class: undefined, text: undefined, any: false};
    }

    anyWarnings() {
        return false;
    }
    
    getCellStyle(row: RecoveryAdjustment, col: ElTableColumn) : { class: string|undefined, text: string|undefined } {
        return { class: undefined, text: undefined}
    }

    formatCurrency(currencyUnit: CurrencyUnit) {
        return CurrencyHelper.formatCurrency(this.getCurrency(), currencyUnit);
    }

    getCurrency() {
        let currencies = this.$store.getters.getCurrencies;
        if (!currencies) return null;
        return (<Currency[]> currencies).find(x => x.id == this.propertyVersion.calculationValue!.currencyId);
    }

    getCurrencyRate() {
        let currencies = this.$store.getters.getCurrencies;
        if (!this.propertyVersion || !currencies) return 1;
        let versionCurrency = (<Currency[]> currencies).find(x => x.id == this.propertyVersion.currencyId);
        if (!versionCurrency) return 0;
        let stateCurrency = (<Currency[]> currencies).find(x => x.id == this.propertyVersion.calculationValue!.currencyId);
        if (!stateCurrency) return 0;
        return versionCurrency.rate / stateCurrency.rate;
    }

    getSummaries(tableData: any): any {
        const data: ContractRecoveriesAdjustment[] = tableData.data;
        let heatInitialSum = 0;
        let heatNewSum = 0;
        let heatDiffSum = 0;
        let miscInitialSum = 0;
        let miscNewSum = 0;
        let miscDiffSum = 0;
        let convInitialSum = 0;
        let convNewSum = 0;
        let convDiffSum = 0;
        let taxInitialSum = 0;
        let taxNewSum = 0;
        let taxDiffSum = 0;

        for(const row of data) {
            if (row.heat) {
                heatInitialSum += row.heat.initialValue || 0;
                heatNewSum += row.heat.newValue || 0;
                heatDiffSum += row.heat.newValue - row.heat.initialValue;
            }
            if (row.misc) {
                miscInitialSum += row.misc.initialValue || 0;
                miscNewSum += row.misc.newValue || 0;
                miscDiffSum += row.misc.newValue - row.misc.initialValue;
            }
            if (row.conversion) {
                convInitialSum += row.conversion.initialValue || 0;
                convNewSum += row.conversion.newValue || 0;
                convDiffSum += row.conversion.newValue - row.conversion.initialValue;
            }
            if (row.tax) {
                taxInitialSum += row.tax.initialValue || 0;
                taxNewSum += row.tax.newValue || 0;
                taxDiffSum += row.tax.newValue - row.tax.initialValue;
            }
        }
       
        return ["", "", "",
                this.formatNumber(heatInitialSum, 0), this.formatNumber(heatNewSum, 0), this.formatNumber(heatDiffSum, 0),
                this.formatNumber(miscInitialSum, 0), this.formatNumber(miscNewSum, 0), this.formatNumber(miscDiffSum, 0),
                this.formatNumber(convInitialSum, 0), this.formatNumber(convNewSum, 0), this.formatNumber(convDiffSum, 0),
                this.formatNumber(taxInitialSum * 100, 2), this.formatNumber(taxNewSum * 100, 2), this.formatNumber(taxDiffSum * 100, 2)
            ];
    }
}