import BaseComponent from "@/components/base-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import DataService from "@/services/data-service";
import { PortfolioSearchResult } from "@/models/interfaces/PortfolioSearchResult";
import { mapGetters } from "vuex";
import { PropertyVersionSlim } from '@/models/interfaces/PropertyVersionSlim';
import EditPropertyVersionDialogComponent, { OpenPropertyVersionEditDialogArgs } from '@/components/property/propertysearch/new-property-dialog/edit-property-version-dialog';
import { EditVersionDialogMode, AppRightEnum } from '@/constants/fia-constants';
import { Portfolio, PropertyVersion } from '@/models';
import EditMultiplePropertiesDialogComponent from './edit-multiple-dialog/edit-multiple-dialog';
import { BusMessage, SearchPortfolioVersionMessage } from '@/models/messages/messages';
import TableSelectExtension from '@/helpers/table-select-extension';
import ReportSettingsDialogComponent from './report-settings-dialog/report-settings-dialog';
import dataService from '@/services/data-service';

@Component({
    components: {
        EditPropertyVersionDialogComponent,
        EditMultiplePropertiesDialogComponent,
        ReportSettingsDialogComponent,
    },
    computed: mapGetters({
        propertyVersion: "getPropertyVersion",
        busMessage: "getBusMessage",
        portfolio: "getPortfolio"
    })
})
export default class PortfolioSearchComponent extends BaseComponent {
    propertyVersion!: PropertyVersion;
    busMessage!: BusMessage<any>;
    portfolio!: Portfolio;
    
    searchTextName: string = "";
    portfolioList: PortfolioSearchResult[] = [];
    versionList: PropertyVersionSlim[] = [];
    args: OpenPropertyVersionEditDialogArgs | null = null;
    selectedPortfolio: PortfolioSearchResult | null = null;
    selectedProperties: PropertyVersionSlim[] = [];
    editMultipleDialogVisible: boolean = false;
    reportSettingDialogOpen: boolean = false;
    searchingPortfolio: boolean = false;
    searchingPortfolioVersions: boolean = false;
    select: TableSelectExtension = new TableSelectExtension(this, 'versionTable');
    skipPortfolioWatch = false;

    @Prop()
    active!: string

    @Watch("active")
    activeWatcher() {
        if(this.active == "portfolioSearch")
            (<any>this.$refs.search).$el.children[0].focus();
    }

    @Watch("busMessage")
    async busWatcher() {
        switch (this.busMessage.type) {
            case "SearchPortfolioVersionMessage":
                let payload = <BusMessage<SearchPortfolioVersionMessage>> this.busMessage;
                if (payload.data != null && payload.data.portfolioName) {
                    this.searchTextName = payload.data.portfolioName;
                    await this.searchPortfolio();
                    if (this.portfolioList.length > 0) {
                        await this.portfolioSelected(this.portfolioList[0]);
                    }
                    if (this.versionList.length > 0) {
                        await this.versionSelected(this.versionList[0]);
                    }
                }
                break;
        }
    }

    @Watch("portfolio")
    async portfolioWatcher() {
        if (this.skipPortfolioWatch) {
            this.skipPortfolioWatch = false;
            return;
        }
        await this.searchPortfolio();
        this.selectPortfolioListItem();
        await this.loadVersions();
    }

    mounted() {
        (<any>this.$refs.search).$el.children[0].focus();

        if (process.env.VUE_APP_MODE === "dev") {
            this.searchTextName = "Erik";
            this.searchPortfolio();
        }
    }

    async searchPortfolio() {
        try {
            this.searchingPortfolio = true;
            this.portfolioList = await DataService.searchPortfolios(this.searchTextName).then(x => x.data);
            this.searchingPortfolio = false;
        } catch (error) {
            return Promise.reject(new Error(""));
        }
    }

    async portfolioSelected(portfolioItem: PortfolioSearchResult) {
        this.selectedPortfolio = portfolioItem;
        this.selectedProperties = [];

        try {
            await this.loadPortfolio(portfolioItem.id);
            await this.loadVersions();
        } catch (error) {
            return Promise.reject(new Error(""));
        }
    }

    async loadPortfolio(portfolioId: number): Promise<void> {
        this.skipPortfolioWatch = true;
        const portfolio = await dataService.getPortfolio(portfolioId, true).then(x => x.data);
        await this.$store.dispatch("setPortfolio", portfolio);
    }

    async loadVersions(): Promise<void> {
        if (this.selectedPortfolio) {
            this.searchingPortfolioVersions = true;
            this.versionList = await DataService.getVersionsByPortfolio(this.selectedPortfolio.id).then(x => x.data);
            this.$store.dispatch("setSearchedPropertyIds", this.versionList.map(x => x.propertyId));
            this.searchingPortfolioVersions = false;
        }
        else {
            this.versionList = [];
        }
    }

    async versionSelected(versionItem: PropertyVersionSlim) {
        try {
            await this.$store.dispatch("loadPropertyVersion", { id: versionItem.id, source: "portfoliosearch" });
        } catch (error) {
            return Promise.reject(new Error(""));
        }
    }

    canCalculateVersions(): boolean {
        return this.selectedProperties && this.selectedProperties.length > 0;
    }

    canEditVersions(): boolean {
        return this.selectedProperties && this.selectedProperties.length > 0;
    }

    selectedPropertiesCountString(): string {
        return this.selectedProperties.length > 0 ? this.selectedProperties.length.toString() : '';
    }

    async versionDialogClosed(canceled: boolean) {
        if (!canceled && this.selectedPortfolio) {
            try {
                await this.loadVersions();
            } catch (error) {
                return Promise.reject(new Error(""));
            }
        }
    }

    async editMultipleDialogClosed(canceled: boolean) {
        if (!canceled) {
            await this.loadVersions();
            this.selectedProperties = [];
        }
    }

    selection(items: PropertyVersionSlim[], item: PropertyVersionSlim) {
        this.select.select(items, item);
        this.selectedProperties = items;
    }

    selectPortfolioListItem(): void {
        const tbl = (<any>this.$refs).portfolioTable;
        const item = this.portfolio ? this.portfolioList.find(x => x.id === this.portfolio.id) || null : null;
        this.$nextTick(() => {
            tbl.setCurrentRow(item);
        })
    }
}
