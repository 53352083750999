import BaseComponent from "@/components/base-component";
import DataService from "@/services/data-service";
import InlineEdit from "@/helpers/inline-edit";
import { Component, Watch, Prop } from "vue-property-decorator";
import { AxiosPromise, AxiosResponse } from "axios";
import { IndexDenotation } from "@/models/IndexDenotation";
import { IndexValue } from "@/models/IndexValue";
import { PrognosisParameter } from "@/models/PrognosisParameter";
import { mapGetters } from "vuex";
import { monthList, BaseDataType } from '@/constants/fia-constants';
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';

@Component({
    components: {
        YearMonthPickerComponent,
        InputNumericComponent
    },
    computed: mapGetters({
        prognosisParameterList: "getPrognosisParameters"
    })
})
export default class IndexComponent extends BaseComponent {
    indexDenotationsGrid: InlineEdit<IndexDenotation>;
    indexValuesGrid: InlineEdit<IndexValue>;
    prognosisParameterList!: PrognosisParameter[];

    selectedType: number = 1;
    indexValuesCount: number = 0;
    selectedMonthBaseData: number = 10;
    monthListBaseData = monthList;

    @Prop()
    reload!: boolean;
    @Prop()
    readOnly!: boolean;

    @Watch("reload")
    reloadWatcher() {
        if (this.reload == true) {
            (<any> this).$refs.indexDenotationsTable.doLayout();
            (<any> this).$refs.indexValuesTable.doLayout();
        }
    }

    @Watch("indexDenotationsGrid.currentItem") currentItemIndexWatcher() {
        if (this.indexValuesGrid)
            this.indexValuesGrid.reload();
    }

    @Watch("selectedMonthBaseData") itemsIndexWatcher() {
        if (this.indexValuesGrid)
            this.indexValuesGrid.reload();
    }

    constructor() {
        super();
        this.indexDenotationsGrid = new InlineEdit<IndexDenotation>(this, "indexDenotationsTable", this.loadIndexDenotations, this.saveIndexDenotation, this.deleteIndexDenotation, this.newIndexDenotation, undefined, this.hasNoErrors);
        this.indexDenotationsGrid.onItemChanged = this.itemChangedIndex;
        this.indexDenotationsGrid.readOnly = this.readOnly;
        this.indexValuesGrid = new InlineEdit<IndexValue>(this, "indexValuesTable", this.loadIndexValues, this.saveIndexValue, this.deleteIndexValue, this.newIndexValue, undefined, this.hasNoErrors);
        this.indexValuesGrid.onItemChanged = this.itemChangedIndexValues;
        this.indexValuesGrid.readOnly = this.readOnly;
    }

    mounted() {
        this.indexDenotationsGrid.reload();
        this.indexValuesGrid.reload();
    }

    loadIndexDenotations(): AxiosPromise<IndexDenotation[]> {
        return DataService.getIndexDenotations();
    }

    saveIndexDenotation(entity: IndexDenotation): AxiosPromise<IndexDenotation> {
        return DataService.saveIndexDenotation(entity);
    }

    deleteIndexDenotation(id: number): AxiosPromise<IndexDenotation> {
        return DataService.deleteIndexDenotation(id);
    }

    newIndexDenotation(): IndexDenotation {
        return { id: 0, prognosisParameterId: 1, name: "", inactive: false, languageId: this.$store.getters.getUser.languageId };
    }

    currentIndexDenotationId(): number | null {
        return (this.indexDenotationsGrid && this.indexDenotationsGrid.currentItem) ? this.indexDenotationsGrid.currentItem.entity.id : null;
    }

    loadIndexValues(): AxiosPromise<IndexValue[]> {
        let m: string = (this.selectedMonthBaseData > 9) ? this.selectedMonthBaseData.toString() : "0" + this.selectedMonthBaseData.toString();
        return DataService.getIndexValueByDenotation(this.currentIndexDenotationId()).then<AxiosResponse<IndexValue[]>>(x => {
            this.indexValuesCount = x.data.length;
            x.data = x.data.filter(e => e.indexDate.substring(5, 7) == m);
            return x
        });
    }

    saveIndexValue(entity: IndexValue): AxiosPromise<IndexValue> {
        return DataService.saveIndexValue(entity);
    }

    deleteIndexValue(id: number): AxiosPromise<IndexValue> {
        return DataService.deleteIndexValue(id);
    }

    newIndexValue(): IndexValue {
        return <IndexValue>{ id: 0, indexDate: this.newIsoDateMonth(false, this.selectedMonthBaseData), indexDenotationId: this.currentIndexDenotationId(), value: 0 };
    }
    
    async itemChangedIndex(indexDenotation: IndexDenotation, action: string) {
        await this.$store.dispatch('updateCache', BaseDataType.IndexDenotations);

        if (action === "delete") 
            await this.$store.dispatch('updateCache', BaseDataType.IndexValues);
    }
    
    itemChangedIndexValues() {
        this.$store.dispatch('updateCache', BaseDataType.IndexValues);
    }
}