import { AxiosError, AxiosResponse } from "axios";

// tslint:disable-next-line: no-namespace
export namespace ErrorHandling {

    export function GetErrorText(err: any): string {
        if (err && err.response && err.response.status === 400 && err.response.data && err.response.data.startsWith("BadRequest_")) {
            return err.response.data;
        } 
        return "AnUnexpectedErrorOccurred";
    }
}