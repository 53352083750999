import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { ValidationError } from "@/models/interfaces/ValidationError";
import { ValidationRule } from '@/models/interfaces/ValidationRule';


@Component({})
export default class ValidationErrorComponent extends Vue {
    @Prop()
    complexError!: ValidationError[];

    errorText(): string {
        return this.complexError.map(x => '- ' + x.error).join('\r\n');
    }
    warningText(): string {
        var warnings = this.complexError.filter(x => x.rule == ValidationRule.Warning);
        return warnings.map(x => "- " + x.error).join('\r\n');
    }
    hasErrors(): boolean {
        var hasErrors = this.complexError && this.complexError.filter(x => x.rule != ValidationRule.Warning).length > 0;
        return hasErrors
    }
    hasWarnings(): boolean {
        var hasWarnings = this.complexError && this.complexError.filter(x => x.rule == ValidationRule.Warning).length > 0; 
        return hasWarnings;
    }
}