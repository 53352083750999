import { Component } from 'vue-property-decorator';

import BaseComponent from '@/components/base-component';
import ContractImportComponent from '@/components/contract/contract-import/contract-import';
import PortfolioCopyComponent from '@/components/portfolio/portfolio-copy/portfolio-copy';
import PortfolioExportComponent from '@/components/portfolio/portfolio-export/portfolio-export';
import PortfolioImportComponent from '@/components/portfolio/portfolio-import/portfolio-import';

@Component({
    components: {
        PortfolioCopyComponent,
        ContractImportComponent,
        PortfolioExportComponent,
        PortfolioImportComponent
    }
})
export default class ImportExportComponent extends BaseComponent {
    activeTabName: string = "contract-import";

}