import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import BaseComponent from '@/components/base-component';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import IpdReportsComponent from '@/components/ipd-reports/ipd-reports';
import { Currency, Language, PaymentGroup, Portfolio, PrognosisAlternative, PrognosisParameter, PropertyVersion, User, Job } from '@/models';
import { CalculationReportSettings, CalculationState } from '@/models/interfaces/CalculationState';
import { PortfolioSearchResult } from '@/models/interfaces/PortfolioSearchResult';
import { AddCalculationRequest } from '@/models/interfaces/request/AddCalculationRequest';
import VersionHeaderComponent from '@/components/version-header/version-header';
import PortfolioHeaderComponent from '@/components/calculation/portfolio-header/portfolio-header';
import { DateFormat, Customer, JobIdentityType, JobType, JobStatus, AppRightEnum, SearchTab } from '@/constants/fia-constants';
import moment from "moment";
import { ValidationError } from '@/models/interfaces/ValidationError';
import { BusMessage, CalcAddedMessage, GenerateValuationMessage, SearchPortfolioVersionMessage, SetTabMessage } from '@/models/messages/messages';
import dataService from '@/services/data-service';
import validationService from '@/services/validation-service';
import { MessageType } from "@/models/interfaces/signalR/MessageType";
import ReportSettingsComponent from './report-settings/report-settings';
import WebReportsComponent from '@/components/web-reports/web-reports';
import { SignalRMessage } from '@/models/interfaces/signalR/SignalRMessage';

@Component({
    components: {
        YearMonthPickerComponent,
        InputNumericComponent,
        VersionHeaderComponent,
        PortfolioHeaderComponent,
        IpdReportsComponent,
        ReportSettingsComponent,
        WebReportsComponent
    },
    computed: mapGetters({
        propertyVersion: "getPropertyVersion",
        propertyVersionLoading: "getPropertyVersionLoading",
        paymentGroups: "getPaymentGroups",
        currencies: "getCurrencies",
        languages: "getLanguages",
        user: "getUser",
        portfolio: "getPortfolio",
        busMessage: "getBusMessage"
    })
})
export default class CalculationComponent extends BaseComponent {
    propertyVersion!: PropertyVersion;
    propertyVersionLoading!: boolean;
    paymentGroups!: PaymentGroup[];
    currencies!: Currency[];
    languages!: Language[];
    user!: User;
    portfolio!: Portfolio;
    busMessage!: BusMessage<any>;

    oldPropertyVersionId: number = 0;
    calculationState: CalculationState | null = null;
    ipdDialogVisible: boolean = false;
    prognosisAlternatives: PrognosisAlternative[] = [];
    prognosisParameters: PrognosisParameter[] = [];
    portfolioList: PortfolioSearchResult[] = [];
    loadingResults: boolean = false;
    complexErrors: ValidationError[] = [];
    currentView: string = 'WebReports';
    warnings: string[] = [];

    // Non-reactive properties
    signalRMsgHandler: any;

    @Prop()
    isVersion!: boolean;

    @Prop()
    active!: string;

    @Prop()
    isLocked!: boolean;
    
    @Watch("propertyVersion")
    async versionWatcher(newValue: PropertyVersion, oldValue: PropertyVersion) {
        if (!this.isVersion) return;
        if (!this.propertyVersion) return;
        const sameVersion = newValue?.id == oldValue?.id;
        await this.setState(sameVersion);
    }

    @Watch("portfolio")
    async portfolioWatcher(newValue: Portfolio, oldValue: Portfolio) {
        if (this.isVersion) return;
        if (!this.portfolio) return;
        const samePortfolio = newValue?.id == oldValue?.id;
        await this.setState(samePortfolio);
    }

    @Watch("calculationState", { deep: true })
    stateWatcher() {
        validationService.validateCalculationState(this.calculationState, this.complexErrors)
    }

    @Watch("busMessage")
    async busMessageWatcher() {
        if (this.busMessage.type === "MultiMarketRentChanged" ||
            this.busMessage.type === "RecoveriesChanged" ||
            this.busMessage.type === "TIChanged" ||
            this.busMessage.type === "PerformCalculation") {
            await this.calculate(true);
        }
    }

    async mounted() {
        this.$on('ipd-reports-dialog-emit', () => {
            this.ipdDialogVisible = false;
        });

        var calculationReportSettings: CalculationReportSettings = {
            rptArea: false,
            rptRentAdjColumn: false,
            rptRentTermProlColumn: false,
            rptGreenDeal: false,
            rptAreaAmount: this.user.customerId == 1?false:true,
            rptInternalComments: false,
            rptRentRebates: false,
            rptDetailedStreams: false,
            rptInvestments: false,
            reportTemplateId: 0,
            rptRents: this.isVersion,
            rptCashFlow: true,
            marketValue: 0,
            transactionCosts: 0,
            currencyId: null,
            languageId: this.$store.getters.getUser.languageId,
            extensionStreamsAverageIncrement: this.monthlyIncrementEnabled()
        }

        // Set state defaults
        this.calculationState = {
            ...calculationReportSettings,
            calculationValue: null,
            calculationResult: null,
            estimatedMarketValue: null,
            costDeductionPercentage: 0,
            buildingRightsValue: 0,
            includeLoan: false,
            optimizedCalculation: false
        };

        this.signalRMsgHandler = (message: SignalRMessage) => this.onSignalRMessage(message);
        this.$signalrHub.$on("HubMessage", this.signalRMsgHandler);
    }

    beforeDestroy() {
        this.$signalrHub.$off("HubMessage", this.signalRMsgHandler);
    }

    async onSignalRMessage(message: SignalRMessage) {
        if (message.type !== MessageType.JobFinished) return;
        const job = JSON.parse(message.data) as Job;
        if ((job.type !== JobType.Calculation && job.type !== JobType.CalculationPortfolio) || job.status !== JobStatus.Finished) return;

        switch (job.identityType) {
            case JobIdentityType.Portfolio:
                // if there's a different portfolio loaded, avoid confusion and return
                if (!this.portfolio || this.portfolio.id !== Number(job.identityId)) return;
                this.reloadPortfolio(Number(job.identityId));
                break;
            case JobIdentityType.PropertyVersion:
                // if there's a different property version loaded, avoid confusion and return
                if (!this.propertyVersion || this.propertyVersion.id !== Number(job.identityId)) return;
                // else, reload the current property version
                await this.$store.dispatch("loadPropertyVersion", { id: this.propertyVersion.id, source: "" });
                break;
        }
    }

    ready(): boolean {
        if ((this.isVersion && this.propertyVersion != null) || (!this.isVersion && this.portfolio != null))
            return this.calculationState !== null && this.calculationState.calculationValue !== null;
        return false;
    }

    dataId(): number {
        return this.isVersion ? this.propertyVersion.id : this.portfolio!.id;
    }

    intervalValues() {
        return [1000, 10000, 100000, 1000000, 10000000];
    }

    async setState(sameVersion: boolean) {
        if (!this.calculationState) return;
        if (this.isVersion && !this.propertyVersion) return;
        if (!this.isVersion && !this.portfolio) return;

        // Get calculation values
        let calculationValue = this.isVersion ? this.propertyVersion.calculationValue : this.portfolio!.calculationValue;
        if (!calculationValue) {
            calculationValue = {
                id: 0,
                propertyVersionId: this.isVersion ? this.propertyVersion.id : null,
                portfolioId: this.isVersion ? null : this.portfolio!.id,
                startDate: this.isVersion ? this.propertyVersion.calculationStart : this.monthStart(),
                endDate: this.isVersion ? this.propertyVersion.calculationEnd : moment(new Date()).add(5, "year").endOf("year").endOf("month").startOf("day").format(DateFormat),
                prognosisAlternativeId: 2,
                flowCalculationInterest: true,
                flowInterestRate: 1,
                flowPrognosisParameterId: 1,
                restValueCalculationInterest: true,
                restValueInterestRate: 1,
                restValuePrognosisParameterId: 1,
                residualCorrection: 0,
                restValueTurnover: 1,
                paymentGroupId: 1,
                marketValue: 0,
                transactionCosts: 0,
                currencyId: 0,
            }
        }

        // Handle currency fallback
        if (this.calculationState.currencyId == null ||this.calculationState.currencyId === 0) {
            this.calculationState.currencyId = this.isVersion ? this.propertyVersion.currencyId : 1; // TODO: portfolio fallback! 
        }

        // Set calculation state
        this.calculationState.calculationValue = calculationValue;

        if (this.propertyVersion)
            this.calculationState.marketValue = this.propertyVersion.id == this.oldPropertyVersionId ? this.calculationState.marketValue : 0;

        this.calculationState.marketValue = this.calculationState.calculationValue && this.calculationState.calculationValue.marketValue ?
            this.calculationState.calculationValue.marketValue : 0;
        this.calculationState.transactionCosts = this.calculationState.calculationValue && this.calculationState.calculationValue.transactionCosts ?
            this.calculationState.calculationValue.transactionCosts : 0;

        // Refresh list data that is dependent on active data
        this.prognosisAlternatives = this.$store.getters.getActivePrognosisAlternatives([this.calculationState.calculationValue.prognosisAlternativeId]);
        this.prognosisParameters = this.$store.getters.getActivePrognosisParameters([this.calculationState.calculationValue.flowPrognosisParameterId, this.calculationState.calculationValue.restValuePrognosisParameterId]);

        if (!sameVersion) {
            this.warnings = [];
        }
    }

    calcInterest(): boolean {
        if (this.calculationState && this.calculationState.calculationValue && this.calculationState.calculationValue.restValueCalculationInterest)
            return true;
        else
            return false;
    }

    isValid(): boolean {
        return this.complexErrors.length === 0;
    }

    canCalculate(): boolean {
        return this.isValid();
    }

    canCreateValuation(): boolean {
        return this.isVersion && this.$store.getters.getAppPermission(AppRightEnum.VDok);
    }

    async generateValuation() {
        let msg: BusMessage<GenerateValuationMessage> = {
            type: "GenerateValuation", data: { propertyIdentifier: this.propertyVersion.property.propertyIdentifier,
            versionId: this.propertyVersion.id,
            propertyName: this.propertyVersion.property.propertyName 
        }};
        await this.$store.dispatch("sendBusMessage", msg);
    }

    async calculate(isWebReport: boolean) {
        if (!this.calculationState || !this.calculationState.calculationValue) return;

        let request = this.createCalculationRequest(isWebReport);

        try {
            if (this.$store.getters.getUserSettingsHasChanged) {
                this.user.userSettings = await dataService.saveUserSettings(this.user.userSettings).then(x => x.data);
            }
            await dataService.addCalculation(request).then(x => {
                this.warnings = x.data.warnings;
            });
            await this.$store.dispatch("sendBusMessage", <BusMessage<CalcAddedMessage>>{ type: "CalcAdded" });
        } catch (error) {
            return Promise.reject(new Error(""));
        } finally {
            this.calculationState.rptInternalComments = false;
        }
    }

    // Sets Rest value default values depending on selected type
    handleRestValueCalculationInterestChange(value: boolean) {
        if (value && this.calculationState && this.calculationState.calculationValue) {
            // Set always to inflation when using CalculationInterest
            this.calculationState.calculationValue.restValuePrognosisParameterId = 1;
            this.calculateRestValueInterestRate(null);
        }
    }

    calculateFlowAndRestValueInterestRates(event: any) {
        if (this.user.customerId === Customer.CWSweden) {
            return;
        }
        if (this.calculationState && this.calculationState.calculationValue) {
            const calcValue = this.calculationState.calculationValue;

            const restValue = calcValue.restValueInterestRate;
            const streamValue = calcValue.flowInterestRate;
            if (restValue && streamValue) {
                const areCalculationInterests = calcValue.flowCalculationInterest && calcValue.restValueCalculationInterest;
                if (restValue !== streamValue && areCalculationInterests) {
                    this.confirm(this.translate('ConfirmExitYieldUpdate'), this.translate('Confirm')).then(() => {
                        this.calculateFlowInterestRate(event);
                        this.calculateRestValueInterestRate(event);
                    }).catch(() => {
                        // cancelled
                    });
                    return;
                }
            }
        }
        this.calculateFlowInterestRate(event);
        this.calculateRestValueInterestRate(event);
    }

    async calculateFlowInterestRate(event: KeyboardEvent | null) {
        if (!this.calculationState ||
            !this.calculationState.calculationValue ||
            !this.calculationState.calculationValue.flowPrognosisParameterId ||
            !this.calculationState.calculationValue.flowCalculationInterest // Must always be "Calculation interest" (true) to calculate
        ) {
            return;
        }

        const exitYield = event && event.target ? +(<HTMLInputElement>event.target).value : this.calculationState.calculationValue.restValueTurnover;
        const prognosisPercentage = await this.calculateInterestRates(this.calculationState.calculationValue.flowPrognosisParameterId);

        if (exitYield && typeof prognosisPercentage !== "undefined") {
            this.calculationState.calculationValue.flowInterestRate = +(exitYield + prognosisPercentage).toFixed(2);
            (<InputNumericComponent>this.$refs.flowInterestRate).highlightField();
        }
    }

    async calculateRestValueInterestRate(event: KeyboardEvent | null) {
        if (!this.calculationState ||
            !this.calculationState.calculationValue ||
            !this.calculationState.calculationValue.restValueCalculationInterest // Must always be "Calculation interest" (true) to calculate
        ) {
            return;
        }

        const exitYield = event && event.target ? +(<HTMLInputElement>event.target).value : this.calculationState.calculationValue.restValueTurnover;
        const prognosisPercentage = await this.calculateInterestRates(1); // Always use 1 = inflation

        if (exitYield && typeof prognosisPercentage !== "undefined") {
            this.calculationState.calculationValue.restValueInterestRate = +(exitYield + prognosisPercentage).toFixed(2);
            (<InputNumericComponent>this.$refs.restValueInterestRate).highlightField();
        }
    }

    // Calculates interest rates for Stream and Rest value based on Exit yield + prognosis
    async calculateInterestRates(prognosisParameter: number) {

        if (!this.calculationState || !this.calculationState.calculationValue) {
            return;
        }

        const prognosisAlternative = this.calculationState.calculationValue.prognosisAlternativeId;
        let prognosisPercentage = 0;

        if (prognosisAlternative && prognosisParameter) {
            prognosisPercentage = await this.getPrognosePercentage(prognosisAlternative, prognosisParameter);
        }

        return prognosisPercentage;
    }

    async getPrognosePercentage(alternativeId: number, parameterId: number) {
        let prognoses = await dataService.getPrognosesByAlternativeAndParameter(alternativeId, parameterId).then(x => x.data);
        if (prognoses && prognoses.length > 0) {
            prognoses = prognoses.sort((a, b) => {
                return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
            });
            prognoses = prognoses.filter(p => {
                if (this.calculationState && this.calculationState.calculationValue) {
                    const periodStart = new Date(this.calculationState.calculationValue.startDate).getTime();
                    const prognoseTime = new Date(p.startDate).getTime();
                    // Filter prognoses that are valid during startDates
                    return periodStart >= prognoseTime;
                }
                return false;
            });
            if (prognoses.length > 0) {
                // Most recent prognose before startDate
                // return +(Math.round(prognoses[prognoses.length - 1].development * 100 * 20) / 20).toFixed(2);
                return +(prognoses[prognoses.length - 1].development * 100);
            }
        }
        return 0;
    }

    getBertilValuation() {
        var request = this.createCalculationRequest(false);
        if (!request) return;
        dataService.getBertilValuation(request).then(x => {
            this.$notify.success({
                title: 'Bertil säger',
                message: this.formatNumber(parseInt(x.data.prediction!, 0)!, 0) + ' '
                + this.currencies.filter(x => x.id == this.propertyVersion.currencyId)[0].name
            });
        });
    }

    createCalculationRequest(isWebReport: boolean): AddCalculationRequest {
        let state = this.calculationState!;
        let cv = this.calculationState!.calculationValue!;

        let request = <AddCalculationRequest>{
            identityType: this.isVersion ? JobIdentityType.PropertyVersion : JobIdentityType.Portfolio,
            identityId: (this.isVersion ? this.propertyVersion.id : this.portfolio!.id).toString(),
            show: true,
            startDate: cv.startDate,
            endDate: cv.endDate,
            prognosisAlternativeId: cv.prognosisAlternativeId,
            paymentGroupId: cv.paymentGroupId,
            restValueCalcInterestRate: cv.restValueCalculationInterest ? cv.restValueInterestRate : -99999, // TODO: temp constant value
            restValueRealInterestRate: cv.restValueCalculationInterest ? -99999 : cv.restValueInterestRate,
            flowCalcInterest: cv.flowCalculationInterest ? cv.flowInterestRate : -99999,
            flowRealInterest: cv.flowCalculationInterest ? -99999 : cv.flowInterestRate,
            restValuePrognosisParameterId: cv.restValuePrognosisParameterId,
            flowPrognosisPrognosisId: cv.flowPrognosisParameterId,
            restValueTurnover: cv.restValueTurnover,
            residualCorrection: cv.residualCorrection,
            marketValue: state.marketValue,
            transactionCosts: state.transactionCosts,
            currencyId: state.currencyId,
            languageId: state.languageId,
            calculateLoans: state.includeLoan,
            alwaysRecalculate: true, // TODO: remove?
            cashFlowReport: state.rptCashFlow,
            investmentReport: state.rptInvestments,
            rentsReport: isWebReport ? false : state.rptRents,
            rentsReportAdjColumn: state.rptRentAdjColumn,
            rentsReportGreenDealColumn: state.rptGreenDeal,
            rentsReportAreaAmountColumn: state.rptAreaAmount,
            rentsReportTerminationProlongingColumn: state.rptRentTermProlColumn,
            rentsReportInternalComments: state.rptInternalComments,
            areaReport: isWebReport ? true : state.rptArea,
            detailedStreamsReport: state.rptDetailedStreams,
            rentRebatesReport: state.rptRentRebates,
            reportTemplateId: state.reportTemplateId,
            useSyncFusion: true,
            colorful: this.user.userSettings.reportSettings.colorful,
            costDeductionPercentage: state.costDeductionPercentage,
            extensionStreamsAverageIncrement: state.extensionStreamsAverageIncrement,
            optimizedCalculation: isWebReport ? state.optimizedCalculation : false,
            generateReport: isWebReport ? false : (
                state.rptCashFlow || state.rptInvestments || state.rptRents || state.rptArea ||
                state.rptDetailedStreams || state.rptRentRebates
            ),
            useWebJob: isWebReport
        }

        return request;
    }

    editLoan() {
        if (!this.isVersion && this.portfolio) {
            let msg: BusMessage<SearchPortfolioVersionMessage> = { type: "SearchPortfolioVersionMessage", data: { portfolioName: this.portfolio.name } }
            this.$store.dispatch("sendBusMessage", msg);
        }

        this.$nextTick(() => {
            this.$store.dispatch("sendBusMessage", <BusMessage<SetTabMessage>>{ type: "SetSearchTabMessage", data: { tab: SearchTab.PropertySearch } });
            this.$nextTick(() => {
                this.$store.dispatch("setTabLevel1", "propertyVersion");
                setTimeout(() => {
                    this.$store.dispatch("sendBusMessage", <BusMessage<SetTabMessage>>{ type: "SetPropertyVersionTabMessage", data: { tab: SearchTab.Loan } });
                }, 1200);
            });
        })
    }

    monthlyIncrementEnabled() {
        return true;
    }
    
    showMonthlyIncrement() {
        return this.user.customerId == Customer.CWFinland;
    }

    demo() {
        return this.user.customerId == Customer.FiaSoftware;
    }

    async reloadPortfolio(portfolioId: number) {
        try {
            if (!this.portfolio || this.portfolio.id !== portfolioId) return;
            const portfolio = await dataService.getPortfolio(this.portfolio.id, true).then(x => x.data);
            await this.$store.dispatch("setPortfolio", portfolio);
        } catch (error) {
            return Promise.reject(new Error(""));
        }
    }
}
