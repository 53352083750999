import BaseComponent from "@/components/base-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import dataService from '@/services/data-service';
import { Property } from '@/models';
import { mapGetters } from "vuex";
import { BusMessage } from "@/models/messages/messages";

@Component({
    components: {
    },
    computed: mapGetters({
        busMessage: "getBusMessage"
    })
})
export default class ImagesComponent extends BaseComponent {
    busMessage!: BusMessage<any>;
    
    uploadData: any = {};
    photoImage: string = "";
    mapImage: string = "";
    map2Image: string = "";
    frontpageImage: string = "";
    siteMapImage: string = "";
    floorPlanImage: string = "";
    interior1Image: string = "";
    interior2Image: string = "";
    environmentalImpactImage: string = "";
    headers: object = {};

    @Prop()
    property!: Property;

    @Watch("property", { immediate: true })
    async propertyWatcher() {
        this.headers = await dataService.getAuthHeaders();
        this.photoImage = await this.loadImage(this.property.id, "foto");
        this.mapImage = await this.loadImage(this.property.id, "map");
        this.map2Image = await this.loadImage(this.property.id, "map2");
        this.frontpageImage = await this.loadImage(this.property.id, "fp");
        this.siteMapImage = await this.loadImage(this.property.id, "sm");
        this.floorPlanImage = await this.loadImage(this.property.id, "fpl");
        this.interior1Image = await this.loadImage(this.property.id, "int1");
        this.interior2Image = await this.loadImage(this.property.id, "int2");
        this.environmentalImpactImage = await this.loadImage(this.property.id, "ei");
    }

    @Watch("busMessage")
    async busMessageWatcher() {
        if (this.busMessage.type === "PropertyMapUpdated") {
            this.headers = await dataService.getAuthHeaders();
            this.mapImage = await this.loadImage(this.property.id, "map");
        }
    }
    
    async loadImage(propertyId: number, suffix: string): Promise<string> {
        try {
            let imgData = await dataService.getPropertyImage(propertyId, suffix, 280).then(x => x.data);
            let result = imgData === "" ? "" : "data:image/jpg;base64," + imgData;
            return result;
        } catch (error) {
            return "";
        }
    }

    url(): string {
        return process.env.VUE_APP_FIAAPI_URL + "properties/importimage"
    }

    setUploadData(file: any, type: string): Promise<any> {
        if (['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
            return new Promise(resolve => {
                this.uploadData = {
                    propertyId: this.property.id,
                    type: type
                };
                this.$nextTick(() => {
                    resolve(file)
                });
            });
        }
        return new Promise(() => {
            this.onError(new Error("Wrong filetype"));
            return false;
        })
    }

    async onSuccessPhoto() {
        (<HTMLFormElement>this.$refs.uploadPhoto).clearFiles();
        this.photoImage = await this.loadImage(this.property.id, "foto");
    }

    async onSuccessMap() {
        (<HTMLFormElement>this.$refs.uploadMap).clearFiles();
        this.mapImage = await this.loadImage(this.property.id, "map");
    }

    async onSuccessMap2() {
        (<HTMLFormElement>this.$refs.uploadMap2).clearFiles();
        this.map2Image = await this.loadImage(this.property.id, "map2");
    }

    async onSuccessFrontpage() {
        (<HTMLFormElement>this.$refs.uploadFrontpage).clearFiles();
        this.frontpageImage = await this.loadImage(this.property.id, "fp");
    }

    async onSuccessSiteMap() {
        (<HTMLFormElement>this.$refs.uploadSiteMap).clearFiles();
        this.siteMapImage = await this.loadImage(this.property.id, "sm");
    }

    async onSuccessFloorPlan() {
        (<HTMLFormElement>this.$refs.uploadFloorPlan).clearFiles();
        this.floorPlanImage = await this.loadImage(this.property.id, "fpl");
    }

    async onSuccessInterior1() {
        (<HTMLFormElement>this.$refs.uploadInterior1).clearFiles();
        this.interior1Image = await this.loadImage(this.property.id, "int1");
    }

    async onSuccessInterior2() {
        (<HTMLFormElement>this.$refs.uploadInterior2).clearFiles();
        this.interior2Image = await this.loadImage(this.property.id, "int2");
    }

    async onSuccessEnvironmentalImpact() {
        (<HTMLFormElement>this.$refs.uploadEnvironmentalImpact).clearFiles();
        this.environmentalImpactImage = await this.loadImage(this.property.id, "ei");
    }

    deleteImage(type: string) {
        this.confirm("ConfirmDeleteImage").then(async () => {
            try {
                await dataService.deletePropertyImage(this.property.id, type);
                switch (type) {
                    case "foto":
                        this.photoImage = "";
                        return;
                    case "map":
                        this.mapImage = "";
                        return;
                    case "map2":
                        this.map2Image = "";
                        return;
                    case "fp":
                        this.frontpageImage = "";
                        return;
                    case "sm":
                        this.siteMapImage = "";
                        return;
                    case "fpl":
                        this.floorPlanImage = "";
                        return;
                    case "int1":
                        this.interior1Image = "";
                        return;
                    case "int2":
                        this.interior2Image = "";
                    case "ei":
                        this.environmentalImpactImage = "";
                        return;
                }
            } catch (error) {
                return Promise.reject(new Error(""));
            }
        }).catch(() => { });
    }

    // showSuccessMessage() {
    //     this.$message({
    //         message: this.translate('ImageUploadedMessage'),
    //         type: 'success'
    //     });
    // }

    onError(e: any) {
        this.$notify.error({
            title: this.translate("Error"),
            message: this.translate("ImageUploadError")
        });
    }
}