import { CurrencyUnit } from "@/components/web-reports/rent-composition/rent-composition";
import { Currency } from "@/models";

export class CurrencyHelper {
    static formatCurrency(currency: Currency | null | undefined, currencyUnit: CurrencyUnit) {
        if (!currency) {
            return '';
        }
        switch (currencyUnit) {
            case CurrencyUnit.Unit: return currency.postUnit;
            case CurrencyUnit.Thousands: return currency.postThousands;
            case CurrencyUnit.PerSqm: return currency.postPerM2;
            case CurrencyUnit.Sqm: return 'm²';
            case CurrencyUnit.Percentage: return '%';
            default: return '';
        }
    }
}
