import BaseComponent from '@/components/base-component';
import { Component, Prop } from "vue-property-decorator";
import DialogComponent from "@/components/common/dialog/dialog";
import YearMonthPickerComponent from "@/components/common/year-month-picker/year-month-picker";

@Component({
    components: {
        DialogComponent,
        YearMonthPickerComponent
    }
})
export default class GeneratePortfolioComponent extends BaseComponent {
    @Prop()
    open!: boolean;

    cancel() {
        this.close(true);
    }

    close(canceled: boolean) {
        this.$emit("update:open", false);
        this.$emit("closed", canceled);
    }
}