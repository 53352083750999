import BaseComponent from "@/components/base-component";
import { Component, Watch, Prop } from "vue-property-decorator";
import { LanguageEnum, BaseDataType } from "@/constants/fia-constants";
import InlineEdit from "@/helpers/inline-edit";
import { TransEntity } from "@/models/interfaces/TransEntity";
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import dataService from '@/services/data-service';
import { AxiosPromise, AxiosResponse } from 'axios';
import validationService from '@/services/validation-service';
import { ValidationError } from '@/models/interfaces/ValidationError';
import ValidationTableFeedbackComponent from '@/components/common/validation-table-feedback/validation-table-feedback';
import InputTextComponent from '@/components/common/input-text/input-text';
import { SaveTranslationRequest } from '@/models/interfaces/request/SaveTranslationRequest';

interface LangBaseDataViewModel {
    id: BaseDataType;
    name: string;
}

interface TransBaseDataViewModel {
    id: number,
    source: TransEntity,
    destination: TransEntity
}

@Component({
    components: {
        YearMonthPickerComponent,
        InputNumericComponent,
        ValidationTableFeedbackComponent,
        InputTextComponent
    }
})
export default class LanguageComponent extends BaseComponent {
    languagesGrid: InlineEdit<TransBaseDataViewModel>;
    sourceData: any[] = [];
    destinationData: any[] = [];
    sourceLanguage: number = 2;
    destinationLanguage: number = 1;
    selectedBaseData: number = 2;
    baseDataList: LangBaseDataViewModel[] = [
        {id: BaseDataType.Currencies, name: this.translate('Currencies')},
        {id: BaseDataType.Countries, name: this.translate('Countries')},
        {id: BaseDataType.DevelopmentTypes, name: this.translate('DevelopmentTypes')},
        {id: BaseDataType.PremisesTypes, name: this.translate('PremisesTypes')},
        {id: BaseDataType.MarketTypes, name: this.translate('MarketTypes')},
        {id: BaseDataType.PaymentGroups, name: this.translate('PaymentGroups')},
        {id: BaseDataType.TypeOfPayments, name: this.translate('TypeOfPayments')},
        {id: BaseDataType.PropertyTaxTypes, name: this.translate('PropertyTaxTypes')},
        {id: BaseDataType.TaxTypeCodes, name: this.translate('TaxTypeCodes')},
        {id: BaseDataType.PrognosisAlternatives, name: this.translate('PrognosisAlternatives')},
        {id: BaseDataType.PrognosisParameters, name: this.translate('PrognosisParameters')},
        {id: BaseDataType.IndexDenotations, name: this.translate('IndexDenotations')},
        {id: BaseDataType.IndexClauses, name: this.translate('IndexClauses')},
        {id: BaseDataType.Ipd6, name: this.translate('Ipd6')},
        {id: BaseDataType.Statuses, name: this.translate('Statuses')},
        {id: BaseDataType.Language, name: this.translate('Language')}
    ];

    @Prop()
    readOnly!: boolean;
    
    complexErrors: ValidationError[] = [];

    constructor() {
        super();
        this.languagesGrid = new InlineEdit<TransBaseDataViewModel>(this, "languagesTable", this.loadLanguages, this.saveLanguage, undefined, this.newLanguage, undefined, this.hasNoErrors);
        this.languagesGrid.sync = true;
        this.languagesGrid.readOnly = this.readOnly;
    }

    mounted() {
        
    }

    @Watch("selectedBaseData", { immediate: true })
    async selectedBaseDataWatcher() {
        await this.updateSource();
        await this.updateDestination();
        this.languagesGrid.reload();
    }
    @Watch("sourceLanguage")
    async sourceLanguageWatcher() {
        await this.updateSource();
        this.languagesGrid.reload();
    }
    @Watch("destinationLanguage")
    async destinationLanguageWatcher() {
        await this.updateDestination();
        this.languagesGrid.reload();
    }

    @Watch("languagesGrid.entities", { deep: true })
    portfolioVersionsGridWatcher() {
        validationService.validateBaseDataLanguage(this.languagesGrid, this.complexErrors);
    }

    async updateDestination() {
        switch (this.selectedBaseData) {
            case BaseDataType.Currencies:
                await dataService.getCurrencies(this.destinationLanguage)
                    .then(d => this.destinationData = d.data);
                break;

            case BaseDataType.DevelopmentTypes:
                await dataService.getDevelopmentTypes(this.destinationLanguage)
                    .then(d => this.destinationData = d.data);
                break;

            case BaseDataType.PrognosisAlternatives:
                await dataService.getPrognosisAlternatives(this.destinationLanguage)
                    .then(d => this.destinationData = d.data);
                break;

            case BaseDataType.Countries:
                await dataService.getCountries(this.destinationLanguage)
                    .then(d => this.destinationData = d.data);
                break;

            case BaseDataType.PremisesTypes:
                await dataService.getPremisesTypes(this.destinationLanguage)
                    .then(d => this.destinationData = d.data);
                break;

            case BaseDataType.MarketTypes:
                await dataService.getMarketTypes(this.destinationLanguage)
                    .then(d => this.destinationData = d.data);
                break;

            case BaseDataType.TypeOfPayments:
                await dataService.getTypeOfPayments(this.destinationLanguage)
                    .then(d => this.destinationData = d.data);
                break;

            case BaseDataType.PropertyTaxTypes:
                await dataService.getPropertyTaxTypes(this.destinationLanguage)
                    .then(d => this.destinationData = d.data);
                break;

            case BaseDataType.PrognosisParameters:
                await dataService.getPrognosisParameters(this.destinationLanguage)
                    .then(d => this.destinationData = d.data);
                break;

            case BaseDataType.IndexDenotations:
                await dataService.getIndexDenotations(this.destinationLanguage)
                    .then(d => this.destinationData = d.data);
                break;

            case BaseDataType.IndexClauses:
                await dataService.getIndexClauses(this.destinationLanguage)
                    .then(d => this.destinationData = d.data);
                break;

            case BaseDataType.Ipd6:
                await dataService.getIpd6(this.destinationLanguage)
                    .then(d => this.destinationData = d.data);
                break;

            case BaseDataType.Statuses:
                await dataService.getStatuses(this.destinationLanguage)
                    .then(d => this.destinationData = d.data);
                break;

            case BaseDataType.PaymentGroups:
                await dataService.getPaymentGroups(this.destinationLanguage)
                    .then(d => this.destinationData = d.data);
                break;

            case BaseDataType.TaxTypeCodes:
                await dataService.getTaxTypeCodes(this.destinationLanguage)
                    .then(d => this.destinationData = d.data);
                break;

            case BaseDataType.Language:
                await dataService.getLanguages(this.destinationLanguage)
                    .then(d => this.destinationData = d.data);
                break;

            default: break;
        }
    }

    async updateSource() {
        switch (this.selectedBaseData) {
            case BaseDataType.Currencies:
                await dataService.getCurrencies(this.sourceLanguage)
                    .then(s => this.sourceData = s.data);
                break;

            case BaseDataType.DevelopmentTypes:
                await dataService.getDevelopmentTypes(this.sourceLanguage)
                    .then(s => this.sourceData = s.data);
                break;

            case BaseDataType.PrognosisAlternatives:
                await dataService.getPrognosisAlternatives(this.sourceLanguage)
                    .then(s => this.sourceData = s.data);
                break;

            case BaseDataType.Countries:
                await dataService.getCountries(this.sourceLanguage)
                    .then(s => this.sourceData = s.data);
                break;

            case BaseDataType.PremisesTypes:
                await dataService.getPremisesTypes(this.sourceLanguage)
                    .then(s => this.sourceData = s.data);
                break;

            case BaseDataType.MarketTypes:
                await dataService.getMarketTypes(this.sourceLanguage)
                    .then(s => this.sourceData = s.data);
                break;

            case BaseDataType.TypeOfPayments:
                await dataService.getTypeOfPayments(this.sourceLanguage)
                    .then(s => this.sourceData = s.data);
                break;

            case BaseDataType.PropertyTaxTypes:
                await dataService.getPropertyTaxTypes(this.sourceLanguage)
                    .then(s => this.sourceData = s.data);
                break;

            case BaseDataType.PrognosisParameters:
                await dataService.getPrognosisParameters(this.sourceLanguage)
                    .then(s => this.sourceData = s.data);
                break;

            case BaseDataType.IndexDenotations:
                await dataService.getIndexDenotations(this.sourceLanguage)
                    .then(s => this.sourceData = s.data);
                break;

            case BaseDataType.IndexClauses:
                await dataService.getIndexClauses(this.sourceLanguage)
                    .then(s => this.sourceData = s.data);
                break;

            case BaseDataType.Ipd6:
                await dataService.getIpd6(this.sourceLanguage)
                    .then(s => this.sourceData = s.data);
                break;

            case BaseDataType.Statuses:
                await dataService.getStatuses(this.sourceLanguage)
                    .then(s => this.sourceData = s.data);
                break;

            case BaseDataType.PaymentGroups:
                await dataService.getPaymentGroups(this.sourceLanguage)
                    .then(s => this.sourceData = s.data);
                break;

            case BaseDataType.TaxTypeCodes:
                await dataService.getTaxTypeCodes(this.sourceLanguage)
                    .then(s => this.sourceData = s.data);
                break;

            case BaseDataType.Language:
                await dataService.getLanguages(this.sourceLanguage)
                    .then(s => this.sourceData = s.data);
                break;

            default: break;
        }
    }

    loadLanguages(): AxiosPromise<TransBaseDataViewModel[]> {
        let tmp: TransBaseDataViewModel[];
        tmp = this.sourceData.map(s => {
            return {
                id: s.id,
                source: {
                    name: s.name, 
                    languageId: s.languageId, 
                    id: s.id
                },
                destination: {
                    name: this.destinationData[this.destinationData.map( x => x.id ).indexOf(s.id)].name,
                    languageId: this.destinationData[this.destinationData.map( x => x.id ).indexOf(s.id)].languageId,
                    id: this.destinationData[this.destinationData.map( x => x.id ).indexOf(s.id)].id
                }
            }
        });
        return Promise.resolve(<AxiosResponse> {data: tmp});
    }

    saveLanguage(entity: TransBaseDataViewModel): AxiosPromise<TransBaseDataViewModel> {
        let preSave: SaveTranslationRequest = {
            baseDataId: this.selectedBaseData,
            id: entity.id,
            sourceName: entity.source.name,
            destinationName: entity.destination.name,
            sourceLanguageId: this.sourceLanguage,
            destinationLanguageId: this.destinationLanguage
        };

        // let afterSave: TransBaseDataViewModel;
        return dataService.saveTranslationRequest(preSave)
            .then(response => {
                // afterSave = {
                //     id: response.data.id,
                //     source: { id: response.data.id, name: response.data.sourceName, languageId: response.data.sourceLanguageId },
                //     destination: { id: response.data.id, name: response.data.destinationName, languageId: response.data.destinationLanguageId }
                // }
                return Promise.resolve(<AxiosResponse> {data: entity})
            });
    }

    newLanguage(): TransBaseDataViewModel {
        let id = this.getNewId(this.languagesGrid.entities);
        return <TransBaseDataViewModel> {
            id: id,
            source: { name: "", languageId: this.sourceLanguage, id: id },
            destination: { name: "", languageId: this.destinationLanguage, id: id }
        }
    }

    translateLanguageCode(value: number): string {
        switch (value) {
            case LanguageEnum.Swedish:
                return this.translate("Swedish");
            case LanguageEnum.Danish:
                return this.translate("Danish");
            case LanguageEnum.Finish:
                return this.translate("Finish");
            case LanguageEnum.English:
                return this.translate("English");
            default:
                return "";
        }
    }
}