import BaseComponent from "@/components/base-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import { PropertyVersion } from "@/models";
import MarketRentComponent from '@/components/versiondetails/marketrent/marketrent';
import DevelopmentComponent from '@/components/versiondetails/development/development';
import DetailsPortfoliosComponent from '@/components/versiondetails/portfolios/details-portfolios';
import DetailsClassificationComponent from '@/components/versiondetails/classification/details-classification';
import BuildingDevComponent from '@/components/versiondetails/buildingdev/buildingdev';
import { mapGetters } from 'vuex';
import dataService from '@/services/data-service';
import DialogComponent from '@/components/common/dialog/dialog';

@Component({
    components: {
        BuildingDevComponent,
        MarketRentComponent,
        DevelopmentComponent,
        DetailsPortfoliosComponent,
        DetailsClassificationComponent,
        DialogComponent
    },
    computed: mapGetters({
        propertyVersion: "getPropertyVersion"
    })
})
export default class VersionDetailsComponent extends BaseComponent {
    propertyVersion!: PropertyVersion;

    activeTab: string = "marketrent";
    isDirtyMarket: boolean = false;
    isDirtyDevelopment: boolean = false;
    isDirtyDetails: boolean = false;
    isDirtyClassification: boolean = false;
    refreshing: boolean = false;

    reload: string = "";

    @Prop()
    open!: boolean;

    @Watch("open")
    async openWatcher() {
        try {
            this.refreshing = true;
            await dataService.refreshClassifications(this.propertyVersion.id)
                .then(() => this.refreshing = false);
        } catch (error) {
        }
    }

    @Watch("activeTab")
    activeTabWatcher() {
    }

   async close() {
        if ((await this.saveAll())) {
            this.$notify.error({
                title: this.translate('ErrorUnexpected'),
                message: this.translate('ErrorActionFailed')
            });
        }
        else
            this.$emit("update:open", false);
    }

    cancel() {
        this.$emit("update:open", false);
    }

    async saveAll() {
        if ((<any>this).$refs.market.isDirty()) {
            this.isDirtyMarket = await (<any>this).$refs.market.save();
        }
        if ((<any>this).$refs.development.isDirty()) {
            this.isDirtyDevelopment = await (<any>this).$refs.development.save();
        }
        if ((<any>this).$refs.details.isDirty()) {
            this.isDirtyDetails = await (<any>this).$refs.details.save();
        }
        if ((<any>this).$refs.classification.isDirty()) {
            this.isDirtyClassification = await (<any>this).$refs.classification.save();
        }

        return this.isDirtyMarket && this.isDirtyDevelopment && this.isDirtyDetails && this.isDirtyClassification;
    }
}