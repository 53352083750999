import BaseComponent from "@/components/base-component";
import { Component, Prop } from "vue-property-decorator";
import { mapGetters } from 'vuex';
import dataService from "@/services/data-service";
import { AdvancedPercentageIncrementStream, Contract, DevelopmentType, IndexIncrementStream, Job, PercentageIncrementStream, PremisesType, PrognosisParameter, Status, TypeOfPayment, TypeOfPaymentStream, VariableAmountIncrementStream } from "@/models";
import { BaseDataStatus, Ipd6Enum, PaymentGroupEnum, PaymentStreamType, PrognosisParameterEnum, ProlongingType, YearMonthFormat } from "@/constants/fia-constants";
import { ContractBulkEdit } from "@/models/ContractBulkEdit";
import paymentflowService from "@/services/paymentflow-service";
import InputTextComponent from "@/components/common/input-text/input-text";
import store from "@/store/store";
import InputNumericComponent from "@/components/common/input-numeric/input-numeric";
import moment from "moment";
import InputDateComponent from "@/components/common/input-date/input-date";
import { TransEntity } from "@/models/interfaces/TransEntity";
import utils from "@/helpers/utils";
import validationService from "@/services/validation-service";
import { ValidationError } from "@/models/interfaces/ValidationError";
import { ValidationRule } from "@/models/interfaces/ValidationRule";
import { BusMessage, PerformCalculationMessage, ReloadCostincomeListMessage } from "@/models/messages/messages";
import { SignalRMessage } from "@/models/interfaces/signalR/SignalRMessage";
import { MessageType } from "@/models/interfaces/signalR/MessageType";

@Component({
    components: {
        InputNumericComponent,
        InputTextComponent,
        InputDateComponent
    },
    computed: mapGetters({})
})
export default class ContractBulkEditComponent extends BaseComponent {

    premisesTypes: PremisesType[] = [];
    statuses: Status[] = [];
    typeOfPaymentStreams: TypeOfPaymentStream[] = [];
    filteredTypeOfPaymentStreams: TypeOfPaymentStream[] = [];
    prognosisParameters: PrognosisParameter[] = [];
    developmentTypes: DevelopmentType[] = [];
    typeOfPayments: TypeOfPayment[] = [];
    filteredTypeOfPayments: TypeOfPayment[] = [];
    prolongingTypes: {id: number, name: string}[] = [];
    componentErrors: string[] = [];

    @Prop({default: null})
    propertyVersionId!: number | null;

    @Prop({default: null})
    portfolioId!: number | null;

    visible: boolean = false;
    contracts: ContractBulkEdit[] = [];
    loading = false;
    page: number = 1;
    pageSize = 25;
    selectedCell: {row: ContractBulkEdit, column: any, cell: HTMLElement} | undefined;
    signalRMsgHandler: ((message: SignalRMessage) => any) | undefined = undefined;
    textAreaSelectedRowIdx = -1;

    get nrOfInvalidRows() { 
        return this.contracts.filter(contract => !contract.isValid).length;
    }
  
    mounted() {
        this.premisesTypes = store.getters.getPremisesTypes;
        this.statuses = store.getters.getStatuses;
        this.typeOfPaymentStreams = store.getters.getTypeOfPaymentStreams;
        this.prognosisParameters = this.$store.getters.getActivePrognosisParameters();
        this.developmentTypes = this.$store.getters.getActiveDevelopmentTypes();
        this.typeOfPayments = this.$store.getters.getTypeOfPayments;
        this.filteredTypeOfPayments = this.typeOfPayments.filter(x => x.paymentGroupId === PaymentGroupEnum.Rents);
        this.prolongingTypes = [
            { id: ProlongingType.UntilCalculationEnd, name: this.translate('UntilCalculationEnd') },
            { id: ProlongingType.UntilStreamEnd, name: this.translate('ContractView_TabContract_CancelAtLastStreamEnd') },
            { id: ProlongingType.UseMarketRent, name: this.translate('ContractView_TabContract_UseMarketRent') }
        ];
        this.filteredTypeOfPaymentStreams = this.typeOfPaymentStreams.filter((x:TypeOfPaymentStream) => [
            PaymentStreamType.AdvancedPercentageStream,
            PaymentStreamType.IndexIncrementStream,
            PaymentStreamType.VariableAmountIncrementStream,
        ].includes(x.id));

        if (process.env.VUE_APP_MODE === "dev" && !this.propertyVersionId && !this.portfolioId) {
            this.propertyVersionId = 113459;
            this.open();
        }
    }

    beforeDestroy() {
        document.removeEventListener('paste', this.handlePaste);
        this.$signalrHub.$off("HubMessage", this.signalRMsgHandler);
    }

    open() {
        this.getContracts();
        this.page = 1;
        this.visible = true;
        document.addEventListener('paste', this.handlePaste);
        this.signalRMsgHandler = (message: SignalRMessage) => this.onSignalRMessage(message);
        this.$signalrHub.$on("HubMessage", this.signalRMsgHandler);
    }

    close() {
        document.removeEventListener('paste', this.handlePaste);
        this.$signalrHub.$off("HubMessage", this.signalRMsgHandler);
        this.contracts = [];
        this.visible = false;
        this.$emit('contractsChanged');
        this.$store.dispatch("sendBusMessage", <BusMessage<ReloadCostincomeListMessage>>{ type: "ReloadCostincomeListMessage", data: {} });
    }

    beforeClose(done: any) {
        this.$emit('contractsChanged');
        this.$store.dispatch("sendBusMessage", <BusMessage<ReloadCostincomeListMessage>>{ type: "ReloadCostincomeListMessage", data: {} });
        done();
    }
    
    getContracts() {
        this.loading = true;
        dataService.getContractBulkEditTemplate(this.propertyVersionId, this.portfolioId).then(x => {
            console.log(x);
            for (let c of x.data) {
                c.complexErrors = [];
                this.validate(c);
            }
            this.contracts = x.data;
            this.componentErrors = [];
        }).catch(x => {
            const errorMsgTranslated = this.translate(x.response.data);
            if (errorMsgTranslated) {
                this.componentErrors.push(errorMsgTranslated);
            } else {
                this.componentErrors.push(this.translate('ErrorUnexpected'));
            }
        }).finally(() => {
            this.loading = false;
            (<any> this).$refs.table.doLayout();
        })
    }

    saveAndReload() {
        this.save(async () => {
            this.getContracts();
        });
    }

    saveAndClose() {
        this.save(() => {
            this.close();
        });
    }

    save(callback: any) {
        const modifiedContracts = this.contracts.filter(x => this.changed(x));
        console.debug(`saving ${modifiedContracts.length} contracts`);
        if (modifiedContracts.length == 0) {
            callback();
            return;
        }
        
        this.loading = true;
        dataService.saveContractBulkEdit(this.propertyVersionId, this.portfolioId, modifiedContracts).then(x => {
            if (this.propertyVersionId) {
                this.$store.dispatch("loadPropertyVersion", { id: this.propertyVersionId, source: "" });
            } else {
                console.log('reload portfolio');
            }
        }).catch(x => {
            if (x.response.data) {
                this.componentErrors.push(x.response.data);
            } else {
                this.componentErrors.push(this.translate('ErrorUnexpected'));
            }
        }).finally(() => {
            this.loading = false;
            if (callback) {
                callback();
            }
        })
    }
    
    select(scope: any, e: any) {
        if (this.selectedCell) {
            this.selectedCell.cell.classList.remove('cell-paste-highlight');    
        }
        this.selectedCell = {row: scope.row, column: scope.column, cell: e.target };
        this.selectedCell.cell.classList.add('cell-paste-highlight');
    }

    handlePaste(event: ClipboardEvent) {
        if (!this.selectedCell || !event.clipboardData) {
            return;
        }

        const pastedData = event.clipboardData.getData('text');
        const pastedRows = pastedData.split('\r\n').map(p => this.trimQuotes(p.trim()));
        
        if (pastedRows.some(r => r.split('\t').length > 1)) {
            this.$notify.warning({
                title: this.translate('ValidationWarning'),
                message: this.translate('PasteErrorMultipleColumns')
            });
            return;
        }

        this.handlePasteFields(pastedRows, false).then(() => {
            console.log('Paste is valid. Committing');
            this.handlePasteFields(pastedRows, true);
            event.preventDefault();
        }).catch((e: Error) => {
            if (e) {
                this.$notify.warning({
                    title: this.translate('ValidationWarning'),
                    message: e.message
                });
            }
        });
    }

    handlePasteFields(pastedRows: string[], commit: boolean) {
        return new Promise<void>((resolve, reject) => {
            const selectedRowIdx = this.contracts.indexOf(this.selectedCell!.row);
            for (let i = selectedRowIdx, j = 0; i < this.contracts.length && j < pastedRows.length; i++, j++) {
                if (!this.canPaste(this.contracts[i], this.selectedCell!.column.property, pastedRows[j])) {
                    continue;
                }

                switch (this.selectedCell!.column.property) {
                    case 'typeOfPaymentStreamId':
                        const streamType = this.findByName(this.typeOfPaymentStreams, pastedRows[j], this.translate('Stream'));
                        if (commit) {
                            this.streamTypeChanged(this.contracts[i], streamType.id);
                        }
                        break;
                    case 'indexStreamAmount':
                        console.debug(this.selectedCell!.column.property);
                        if (commit) {
                            if (this.isIndexStream(this.contracts[i].contract.currentValue)) {
                                (<PercentageIncrementStream>this.contracts[i].contract.currentValue.paymentStreams[0]).startAmount =
                                    this.numeric(pastedRows[j]) ? utils.int(pastedRows[j]) : 0;
                            }
                        }
                        break;
                    case 'paymentStreamAmount':
                        console.debug(this.selectedCell!.column.property);
                        if (commit) {
                            if (!this.isIndexStream(this.contracts[i].contract.currentValue)) {
                                if (this.contracts[i].contract.currentValue.paymentStreams[0].typeOfPaymentStreamId == PaymentStreamType.VariableAmountIncrementStream) {
                                    (<VariableAmountIncrementStream>this.contracts[i].contract.currentValue.paymentStreams[0]).variableAmounts[0].amount = 
                                        this.numeric(pastedRows[j]) ? utils.int(pastedRows[j]) : 0;
                                } else {
                                    (<PercentageIncrementStream>this.contracts[i].contract.currentValue.paymentStreams[0]).startAmount = 
                                        this.numeric(pastedRows[j]) ? utils.int(pastedRows[j]) : 0;
                                }
                            }
                        }
                        break;
                    case 'paymentStreamBaseNumber':
                        console.debug(this.selectedCell!.column.property);
                        if (commit && this.contracts[i].contract.currentValue.paymentStreams[0].typeOfPaymentStreamId == PaymentStreamType.IndexIncrementStream) {
                            (<IndexIncrementStream>this.contracts[i].contract.currentValue.paymentStreams[0]).baseNumber = 
                                this.numeric(pastedRows[j]) ? this.parseFloat(pastedRows[j], 2) : 0;
                        }
                        break;
                    case 'paymentStreamIndexPercentage':
                        console.debug(this.selectedCell!.column.property);
                        if (commit && this.contracts[i].contract.currentValue.paymentStreams[0].typeOfPaymentStreamId == PaymentStreamType.IndexIncrementStream) {
                            (<IndexIncrementStream>this.contracts[i].contract.currentValue.paymentStreams[0]).indexPercentage = 
                                this.numeric(pastedRows[j]) ? this.parseFloat(pastedRows[j], 2)* 0.01 : 0;
                        }
                        break;
                    case 'prognosisParameterId':
                        console.debug(this.selectedCell!.column.property);
                        const parameterId = this.findByName(this.prognosisParameters, pastedRows[j], this.translate('Prognosis'));
                        if (commit
                            && this.contracts[i].contract.currentValue.paymentStreams[0].typeOfPaymentStreamId == PaymentStreamType.AdvancedPercentageStream
                            && (<AdvancedPercentageIncrementStream>this.contracts[i].contract.currentValue.paymentStreams[0]).prognosisParameterId != undefined) {
                            (<AdvancedPercentageIncrementStream>this.contracts[i].contract.currentValue.paymentStreams[0]).prognosisParameterId = parameterId.id;
                            this.prognosisParameterChanged(this.contracts[i]);
                        }
                        break;
                    case 'developmentId':
                        console.debug(this.selectedCell!.column.property);
                        const developmentType = this.findByName(this.developmentTypes, pastedRows[j], this.translate('Development'));
                        if (commit
                            && this.contracts[i].contract.currentValue.paymentStreams[0].typeOfPaymentStreamId == PaymentStreamType.AdvancedPercentageStream
                            && (<AdvancedPercentageIncrementStream>this.contracts[i].contract.currentValue.paymentStreams[0]).developmentTypeId != undefined) {
                            (<AdvancedPercentageIncrementStream>this.contracts[i].contract.currentValue.paymentStreams[0]).developmentTypeId = developmentType.id;
                            this.developmentTypeChanged(this.contracts[i]);
                        }
                        break;
                    case 'prognosisParameterMargin':
                        console.debug(this.selectedCell!.column.property);
                        if (commit 
                            && this.contracts[i].contract.currentValue.paymentStreams[0].typeOfPaymentStreamId == PaymentStreamType.AdvancedPercentageStream) {
                            (<AdvancedPercentageIncrementStream>this.contracts[i].contract.currentValue.paymentStreams[0]).margin =
                                this.numeric(pastedRows[j]) ? this.parseFloat(pastedRows[j], 2) * 0.01 : 0;
                        }
                        break;
                    case 'paymentStreamFirstIncrement':
                        console.debug(this.selectedCell!.column.property);
                        if (commit
                            && this.contracts[i].contract.currentValue.paymentStreams[0].typeOfPaymentStreamId != PaymentStreamType.VariableAmountIncrementStream
                            && utils.isValidDate(pastedRows[j])) {
                            (<PercentageIncrementStream>this.contracts[i].contract.currentValue.paymentStreams[0]).firstIncrement = utils.date(pastedRows[j], false);
                        }
                        break;
                    case 'prolongingTypeId':
                        console.debug(this.selectedCell!.column.property);
                        const prolongingType = this.findByName(this.prolongingTypes, pastedRows[j], this.translate('ProlongingType'));
                        if (commit && prolongingType) {
                            this.contracts[i].contract.currentValue.prolongingTypeId = prolongingType.id;
                            this.prolongingTypeChanged(this.contracts[i]);
                        }
                        break;
                    case 'prolongationMonths':
                        console.debug(this.selectedCell!.column.property);
                        if (commit) {
                            this.contracts[i].contract.currentValue.prolongationMonths =
                                this.numeric(pastedRows[j]) ? utils.int(pastedRows[j]) : 0;
                        }
                        break;
                    case 'statusId':
                        console.debug(this.selectedCell!.column.property);
                        const status = this.findByName(this.statuses, pastedRows[j], this.translate('Status'));
                        if (commit && status) {
                            this.contracts[i].contract.currentValue.statusId = status.id
                            this.statusChanged(this.contracts[i]);
                        }
                        break;
                    case 'movingInDate':
                        console.debug(this.selectedCell!.column.property);
                        if (commit && utils.isValidDate(pastedRows[j])) {
                            this.contracts[i].contract.currentValue.movingInDate = utils.date(pastedRows[j], false);
                        }
                        break;
                    case 'contractStartDate':
                        console.debug(this.selectedCell!.column.property);
                        if (commit && utils.isValidDate(pastedRows[j])) {
                            this.contracts[i].contract.currentValue.startDate = utils.date(pastedRows[j], false);
                            this.contractStartDateChanged(this.contracts[i]);
                        }
                        break;
                    case 'contractEndDate':
                        console.debug(this.selectedCell!.column.property);
                        if (commit && utils.isValidDate(pastedRows[j])) {
                            this.contracts[i].contract.currentValue.endDate = utils.date(pastedRows[j], true);
                            this.contractEndDateChanged(this.contracts[i]);
                        }
                        break;
                    case 'terminationMonth':
                        console.debug(this.selectedCell!.column.property);
                        if (commit) {
                            this.contracts[i].contract.currentValue.terminationMonth =
                                this.numeric(pastedRows[j]) ? utils.int(pastedRows[j]) : 0;
                        }
                        break;
                    case 'premisesTypeId':
                        console.debug(this.selectedCell!.column.property);
                        const premisesType = this.findByName(this.premisesTypes, pastedRows[j], this.translate('PremisesType'));
                        if (commit && premisesType) {
                            this.contracts[i].contract.currentValue.leaseAreas[0].premisesTypeId = premisesType.id
                            this.contracts[i].contract.currentValue.mainPremisesTypeId = premisesType.id
                            this.premisesTypeChanged(this.contracts[i]);
                        }
                        break;
                    case 'contractArea':
                        console.debug(this.selectedCell!.column.property);
                        if (commit) {
                            this.contracts[i].contract.currentValue.leaseAreas[0].contractArea =
                                this.numeric(pastedRows[j]) ? this.parseFloat(pastedRows[j], 2) : 0;
                        }
                        break;
                    case 'numberOf':
                        console.debug(this.selectedCell!.column.property);
                        if (commit) {
                            this.contracts[i].contract.currentValue.leaseAreas[0].numberOf =
                                this.numeric(pastedRows[j]) ? utils.int(pastedRows[j]) : 0;
                        }
                        break;
                    case 'typeOfPaymentId':
                        console.debug(this.selectedCell!.column.property);
                        const typeOfPaymentId = this.findByName(this.typeOfPayments, pastedRows[j], this.translate('TypeOfPayment'));
                        if (commit && typeOfPaymentId) {
                            this.contracts[i].contract.currentValue.transaction.typeOfPaymentId = typeOfPaymentId.id;
                        }
                        break;
                    case 'contract.currentValue.vat':
                        console.debug(this.selectedCell!.column.property);
                        const p = pastedRows[j].trim().toLowerCase();
                        const vat = p == this.translate('Yes').trim().toLowerCase() ? true : p == this.translate('No').trim().toLowerCase() ? false : null;
                        if (commit) {
                            this.contracts[i].contract.currentValue.vat = vat;
                        }
                        break;
                    case 'contract.currentValue.internalComment':
                        console.debug(this.selectedCell!.column.property);
                        if (commit) {
                            this.contracts[i].contract.currentValue.internalComment = pastedRows[j]
                        }
                        break;
                    case 'contract.currentValue.greenDeal':
                        console.debug(this.selectedCell!.column.property);
                        const g = pastedRows[j].trim().toLowerCase();
                        const greenDeal = g == this.translate('Yes').trim().toLowerCase() ? 1 : g == this.translate('No').trim().toLowerCase() ? 0 : null;
                        if (commit) {
                            this.contracts[i].contract.currentValue.greenDeal = greenDeal;
                        }
                        break;    
                    case 'comment':
                        console.debug(this.selectedCell!.column.property);
                        if (commit) {
                            this.contracts[i].contract.currentValue.transaction.comment = pastedRows[j]
                        }
                        break;
                    
                    case 'contract.currentValue.tenant':
                    case 'contract.currentValue.propertyIdentifier':
                        if (this.selectedCell!.column.property) {
                            console.debug(this.selectedCell!.column.property);
                            const validProp = utils.getObjectProperty(this.selectedCell!.column.property, this.contracts[i]);
                            if (validProp && commit && pastedRows[j] && pastedRows[j].trim().length > 0) {
                                utils.setObjectProperty(this.selectedCell!.column.property, pastedRows[j], this.contracts[i]);
                            }
                        }
                        break;
                    default:
                        reject();
                        break;
                }

                if (commit) {
                    this.validate(this.contracts[i]);
                }
            }

            resolve();
        });
    }

    canPaste(contract: ContractBulkEdit, property: string, pastedValue: string | undefined) {
        if (pastedValue === '') {
            return false;
        }
        if (property.toLowerCase().includes('comment')) {
            return true;
        }
        const isVacant = this.isVacant(contract);
        if ([
            'movingInDate',
            'contractStartDate',
            'contractEndDate',
            'paymentStreamFirstIncrement',
            'terminationMonth',
            'prolongationMonths',
        ].includes(property) && isVacant) {
            return false;
        }
        if ([
            'movingInDate',
            'contractStartDate',
            'contractEndDate',
            'terminationMonth',
            'paymentStreamFirstIncrement',   
        ].includes(property) && !moment(pastedValue).isValid()) {
            return false;
        }
        return contract.isValid;
    }

    parseFloat(num: string, decimals: number = 0) {
        return parseFloat(num.replaceAll(/\s+/g, '').replaceAll(',', '.'));
    }

    getSummaries(tableData: any): any {
        const data: ContractBulkEdit[] = tableData.data;
        let passingRent = 0;

        for(const row of data) {
            passingRent += row.rentCompositionRow.rentExRecoveries;
        }
       
        return ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "",
        "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "",this.decimals(passingRent, 0)];
    }

    loadRowBaseData(row: any) {
        this.$set(row, 'loaded', true);
    }

    textAreaFocused(event: FocusEvent, row: ContractBulkEdit) {
        this.textAreaSelectedRowIdx = this.contracts.indexOf(row);
    }

    textAreaBlurred(event: FocusEvent) {
        this.textAreaSelectedRowIdx = -1;
    }

    streamTypeChanged(row: ContractBulkEdit, newTypeId: number) {
        console.debug('Stream type changed');
        
        const newStream = paymentflowService.changeStreamType(row.contract.currentValue.paymentStreams[0], newTypeId,
            row.propertyVersion, 'contract', row.contract.currentValue, null, null);
        newStream.id = 0;

        if (newStream.typeOfPaymentStreamId == PaymentStreamType.VariableAmountIncrementStream) {
            (newStream as VariableAmountIncrementStream).variableAmounts.push({ 
                id: 0,
                startDate: newStream.startDate,
                amount: 0
             });
        }

        if (utils.isPercentageIncrementStream(newStream) && utils.isPercentageIncrementStream(row.contract.currentValue.paymentStreams[0])) {
            (<PercentageIncrementStream>newStream).firstIncrement = (<PercentageIncrementStream>row.contract.currentValue.paymentStreams[0]).firstIncrement;
        }

        paymentflowService.copyAmount(row.contract.currentValue.paymentStreams[0], newStream);
        this.$set(row.contract.currentValue.paymentStreams, 0, newStream);
    }

    prolongingTypeChanged(row: ContractBulkEdit) {
        if (this.useMarketRent(row.contract.currentValue)) {
            if (!row.contract.currentValue.marketRentIndexPercent)
                row.contract.currentValue.marketRentIndexPercent = 1;
            if (!row.contract.currentValue.prognosisParameterId)
                row.contract.currentValue.prognosisParameterId = PrognosisParameterEnum.Inflation
        }
        else {
            row.contract.currentValue.marketRentIndexPercent = null;
            row.contract.currentValue.prognosisParameterId = null;
        }
        this.validate(row);
    }

    useMarketRent(contract: Contract): boolean {
        return contract.prolongingTypeId == PaymentStreamType.IndexIncrementStream;
    }

    isIndexStream(contract: Contract) {
        return contract.paymentStreams[0].typeOfPaymentStreamId == PaymentStreamType.IndexIncrementStream;
    }

    isAdvancedPercentageStream(contract: Contract) {
        return contract.paymentStreams[0].typeOfPaymentStreamId == PaymentStreamType.AdvancedPercentageStream;
    }

    isVariableAmountsStream(contract: Contract) {
        return contract.paymentStreams[0].typeOfPaymentStreamId == PaymentStreamType.VariableAmountIncrementStream;
    }

    setPage(v: number) {
        this.page = v;
    }

    pagedTableData() {
        return this.contracts.slice((this.page-1) * this.pageSize, (this.pageSize * (this.page-1)) + this.pageSize);
    }

    validate(row: ContractBulkEdit) {
        if (row.isValid) {
            validationService.validateContract(row.contract.currentValue, row.complexErrors, row.contract.currentValue.id);
        }
    }

    toYearMonth(date: string) {
        if (!date) {
            return date;
        }
        return moment(date).format(YearMonthFormat);
    }

    getRowClass({ row, rowIndex }: { row: ContractBulkEdit, rowIndex: number}) {
        let classes = !row.isValid ? 'disabled-row ' : '';
        classes += this.textAreaSelectedRowIdx == rowIndex ? 'cell-enlarged ' : '';
        return classes;
    }

    findByName(list: TransEntity[] | {id: number, name: string}[], searchVal: string, type: string) {
        const value = list.find(x => x.name.toLowerCase().trim() === searchVal.toLowerCase().trim());
        if (!value) {
            throw new Error(`${this.translate('NotFound')}: ${type} - ${searchVal}`)
        }
        return value;
    }

    tryCalculate() {
        const unsavedChanges = this.contracts.some(x => JSON.stringify(x.contract.initialValue) != JSON.stringify(x.contract.currentValue));
        if (unsavedChanges) {
            this.$notify.warning({
                title: this.translate('Warning'),
                message: this.translate('WarningUnsavedChanges')
            });
            return;
        }
        this.$store.dispatch("sendBusMessage", <BusMessage<PerformCalculationMessage>>{ type: "PerformCalculation" });
        this.loading = true;
    }

    onSignalRMessage(message: SignalRMessage): any {
        console.debug(`Calculation update - ${message.type}`);
        const job = JSON.parse(message.data) as Job;
        if (this.propertyVersionId && job.identityId && this.propertyVersionId.toString() != job.identityId.toString()) {
            return;
        }
        
        if (message.type === MessageType.JobFinished) {
            this.getContracts();
            this.loading = false;
            return;
        }
        if (message.type === MessageType.JobFailed) {
            this.componentErrors.push(this.translate('CalculationError'));
            this.loading = false;
            return;
        }
    }

    premisesTypeChanged(row: ContractBulkEdit) {
        if (row.contract.initialValue.mainPremisesTypeId == row.contract.initialValue.leaseAreas[0].premisesTypeId) {
            row.contract.currentValue.mainPremisesTypeId = row.contract.currentValue.leaseAreas[0].premisesTypeId;
        }
        // Update the payment type based on the premises type. In db table lokal_typ has a column betalnings_typ_id
        const paymentIdByPremisesType = this.premisesTypes.find(p => p.id === row.contract.currentValue.mainPremisesTypeId)?.typeOfPaymentId;
        if (paymentIdByPremisesType) {
            row.contract.currentValue.transaction.typeOfPaymentId = paymentIdByPremisesType;

            if (!this.filteredTypeOfPayments.some(fp => fp.id === paymentIdByPremisesType)) {
                const typeOfPayment = this.typeOfPayments.find(p => p.id === paymentIdByPremisesType);
                if (typeOfPayment) {
                    this.filteredTypeOfPayments.push(typeOfPayment);
                }
            }
        }
        this.validate(row);
    }

    anyErrors() {
        //console.log(this.contracts.filter(x => x.complexErrors.some(e => e.rule !== ValidationRule.Warning)));
        return this.contracts.some(x => this.changed(x) && x.complexErrors.some(e => e.rule !== ValidationRule.Warning));
    }

    contractStartDateChanged(row: ContractBulkEdit) {
        row.contract.currentValue.paymentStreams[0].startDate = row.contract.currentValue.startDate;
        utils.updateContractStartDate(row.contract.currentValue, true);
        this.validate(row);
    }

    contractEndDateChanged(row: ContractBulkEdit) {
        utils.updateContractEndDate(row.contract.currentValue);
        this.validate(row);
    }

    cellStyle({row, column, rowIndex, columnIndex}: {row: ContractBulkEdit, column: any, rowIndex: number, columnIndex: number}) {
        if (!row.isValid) return '';
        return this.valueChanged(row, column.property) ? {backgroundColor: '#ffbf80 !important', borderRadius: '6px'} : '';
    }

    valueChanged(row: ContractBulkEdit, property: any) {
        switch (property) {
            case 'typeOfPaymentStreamId': return row.contract.currentValue.paymentStreams[0].typeOfPaymentStreamId != row.contract.initialValue.paymentStreams[0].typeOfPaymentStreamId;
            case 'paymentStreamAmount': return this.getStreamAmount(row.contract.currentValue) != this.getStreamAmount(row.contract.initialValue);
            case 'indexStreamAmount': return this.getStreamAmount(row.contract.currentValue) != this.getStreamAmount(row.contract.initialValue);
            case 'paymentStreamBaseNumber': return (<IndexIncrementStream>row.contract.currentValue.paymentStreams[0]).baseNumber != (<IndexIncrementStream>row.contract.initialValue.paymentStreams[0]).baseNumber;
            case 'paymentStreamIndexPercentage': return (<IndexIncrementStream>row.contract.currentValue.paymentStreams[0]).indexPercentage != (<IndexIncrementStream>row.contract.initialValue.paymentStreams[0]).indexPercentage;
            case 'prognosisParameterId': return (<AdvancedPercentageIncrementStream>row.contract.currentValue.paymentStreams[0]).prognosisParameterId != (<AdvancedPercentageIncrementStream>row.contract.initialValue.paymentStreams[0]).prognosisParameterId
            case 'developmentId': return (<AdvancedPercentageIncrementStream>row.contract.currentValue.paymentStreams[0]).developmentTypeId != (<AdvancedPercentageIncrementStream>row.contract.initialValue.paymentStreams[0]).developmentTypeId
            case 'prognosisParameterMargin': return (<AdvancedPercentageIncrementStream>row.contract.currentValue.paymentStreams[0]).margin != (<AdvancedPercentageIncrementStream>row.contract.initialValue.paymentStreams[0]).margin;
            case 'paymentStreamFirstIncrement': return (<IndexIncrementStream>row.contract.currentValue.paymentStreams[0]).firstIncrement != (<IndexIncrementStream>row.contract.initialValue.paymentStreams[0]).firstIncrement;
            case 'prolongingTypeId': return row.contract.currentValue.prolongingTypeId != row.contract.initialValue.prolongingTypeId;
            case 'prolongationMonths': return row.contract.currentValue.prolongationMonths != row.contract.initialValue.prolongationMonths;
            case 'statusId': return row.contract.currentValue.statusId != row.contract.initialValue.statusId;
            case 'movingInDate': return row.contract.currentValue.movingInDate != row.contract.initialValue.movingInDate;
            case 'contractStartDate': return row.contract.currentValue.startDate != row.contract.initialValue.startDate;
            case 'contractEndDate': return row.contract.currentValue.endDate != row.contract.initialValue.endDate;
            case 'terminationMonth': return row.contract.currentValue.terminationMonth != row.contract.initialValue.terminationMonth;
            case 'premisesTypeId': return row.contract.currentValue.mainPremisesTypeId != row.contract.initialValue.mainPremisesTypeId;
            case 'contractArea': return row.contract.currentValue.leaseAreas[0].contractArea != row.contract.initialValue.leaseAreas[0].contractArea;
            case 'numberOf': return row.contract.currentValue.leaseAreas[0].numberOf != row.contract.initialValue.leaseAreas[0].numberOf;
            case 'contract.currentValue.tenant': return row.contract.currentValue.tenant != row.contract.initialValue.tenant;
            case 'contract.currentValue.propertyIdentifier': return row.contract.currentValue.propertyIdentifier != row.contract.initialValue.propertyIdentifier;
            case 'contract.currentValue.internalComment': return row.contract.currentValue.internalComment != row.contract.initialValue.internalComment;
            case 'comment': return row.contract.currentValue.transaction.comment != row.contract.initialValue.transaction.comment;
            case 'contract.currentValue.vat': return row.contract.currentValue.vat != row.contract.initialValue.vat;
            case 'typeOfPaymentId': return row.contract.currentValue.transaction.typeOfPaymentId != row.contract.initialValue.transaction.typeOfPaymentId;
            case 'contract.currentValue.greenDeal': return row.contract.currentValue.greenDeal != row.contract.initialValue.greenDeal;
        }
    }

    changed(row: ContractBulkEdit) {
        return JSON.stringify(row.contract.currentValue) !== JSON.stringify(row.contract.initialValue);
    }

    getStreamAmount(contract: Contract) {
        return this.isVariableAmountsStream(contract) ? (<VariableAmountIncrementStream>contract.paymentStreams[0]).variableAmounts[0]?.amount :
            (<IndexIncrementStream>contract.paymentStreams[0]).startAmount;
    }

    isVacant(row: ContractBulkEdit) {
        return row.contract.currentValue.statusId == BaseDataStatus.Vacant;
    }
    
    statusChanged(row: ContractBulkEdit) {
        const status = this.statuses.find(x => x.id == row.contract.currentValue.statusId);
        if (status && status.vacant) {
            row.contract.currentValue.ipd6Id = Ipd6Enum.Vacant;
            if (status.id === BaseDataStatus.Vacant) {
                row.contract.currentValue.tenant = this.translate('Vacant');
                row.contract.currentValue.recoveries = [];
                row.contract.currentValue.vat = null;
                row.contract.currentValue.greenDeal = null;
                row.contract.currentValue.corporateIdentityNumber = '';
                const calcStartDate = row.propertyVersion.calculationValue ? row.propertyVersion.calculationValue.startDate : row.propertyVersion.calculationStart;
                row.contract.currentValue.endDate = this.monthEnd(moment(calcStartDate).add(-1, "month").toDate()); 
                if (row.contract.currentValue.paymentStreams[0].typeOfPaymentStreamId != PaymentStreamType.AdvancedPercentageStream) {
                    this.streamTypeChanged(row, PaymentStreamType.AdvancedPercentageStream);
                }
                (<AdvancedPercentageIncrementStream>row.contract.currentValue.paymentStreams[0]).startAmount = 0;
                (<AdvancedPercentageIncrementStream>row.contract.currentValue.paymentStreams[0]).endDate = row.contract.currentValue.endDate;
                (<AdvancedPercentageIncrementStream>row.contract.currentValue.paymentStreams[0]).developmentTypeId = null;
                (<AdvancedPercentageIncrementStream>row.contract.currentValue.paymentStreams[0]).prognosisParameterId = PrognosisParameterEnum.Inflation;
            }
        }
        this.validate(row);
    }

    prognosisParameterChanged(row: ContractBulkEdit) {
        (<AdvancedPercentageIncrementStream>row.contract.currentValue.paymentStreams[0]).developmentTypeId = null;
    }

    developmentTypeChanged(row: ContractBulkEdit) {
        (<AdvancedPercentageIncrementStream>row.contract.currentValue.paymentStreams[0]).prognosisParameterId = null;
    }

    clearInternalComments() {
        this.contracts.forEach(x => {
            if (x.contract.currentValue.internalComment) {
                x.contract.currentValue.internalComment = ''
            }
        });
    }

    numeric(s: string) {
        return this.isNumeric(s?.replaceAll(/\s+/g, '').replaceAll(',','.'));
    }

    getRowInactiveReason(row: ContractBulkEdit) {
        if (row.hasMultipleStreamsInRecoveries) {
            return this.translate('ContractBulkEditValidationWarning2');
        }
        if (row.hasMultipleLeaseAreas) {
            return this.translate('ContractBulkEditValidationWarning');
        }
        if (!row.streamSupported) {
            return this.translate('ContractBulkEditValidationWarning4');    
        }
        return this.translate('ContractBulkEditValidationWarning3');
    }

    undo(row: ContractBulkEdit) {
        row.contract.currentValue = JSON.parse(JSON.stringify(row.contract.initialValue));
        this.validate(row);
    }

    getValidationCountMsg() {
        const count = this.contracts.reduce((x, x2) => x + x2.complexErrors.length, 0);
        if (count === 0) return '';
        return this.translate('NumberOfValidationErrors').replace('{}', count.toString());
    }

    trimQuotes(str: string) {
        if (str.startsWith('"') && str.endsWith('"')) {
          return str.substring(1, str.length - 1);
        }
        return str;
    }
}


