import BaseComponent from "@/components/base-component";
import { Component, Watch, Prop } from "vue-property-decorator";
import { MarketRent, PropertyVersion, DevelopmentType, PrognosisParameter, Currency } from "@/models";
import DataService from "@/services/data-service";
import { MarketType } from "@/models/MarketType";
import InlineEdit from "@/helpers/inline-edit";
import { AxiosPromise } from "axios";
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import dataService from '@/services/data-service';
import validationService from '@/services/validation-service';
import { ValidationError } from '@/models/interfaces/ValidationError';
import ValidationTableFeedbackComponent from '@/components/common/validation-table-feedback/validation-table-feedback';
import ValidationErrorComponent from '@/components/common/validation-error/validation-error';
import { mapGetters } from 'vuex';
import { MarketRentChangedMessage, BusMessage } from '@/models/messages/messages';
import EditMultipleMarketRentsDialogComponent from '@/components/versiondetails/marketrent/edit-multiple-market-rents-dialog/edit-multiple-market-rents-dialog';

@Component({
    components: {
        YearMonthPickerComponent,
        InputNumericComponent,
        ValidationTableFeedbackComponent,
        ValidationErrorComponent,
        EditMultipleMarketRentsDialogComponent
    }, 
    computed: mapGetters({
        propertyVersion: "getPropertyVersion"
    })
})
export default class MarketRentComponent extends BaseComponent {
    propertyVersion!: PropertyVersion;
    
    marketRentsGrid: InlineEdit<MarketRent>;
    currentPropertyVersion: number = -1;
    marketTypes: MarketType[] = [];
    activeMarketTypes: MarketType[] = [];
    developmentTypes: DevelopmentType[] = [];
    prognosisParameters: PrognosisParameter[] = [];
    complexErrors: ValidationError[] = [];
    newDate: string = "";
    saving: boolean = false;
    editMultipleDialogOpened = false;

    constructor() {
        super();
        this.marketRentsGrid = new InlineEdit<MarketRent>(this, "marketRentsTable", this.loadMarketRents, undefined, undefined, this.newMarketRent, this.editMarketRent, this.hasNoErrors);
        this.marketRentsGrid.sync = true;
        this.marketRentsGrid.readOnly = this.versionLocked();
        this.marketRentsGrid.useConfirmDeleteDialog = false;
    }

    @Prop()
    reload!: boolean;

    @Watch("reload")
    async reloadWatcher() {
        if (this.reload) {
            if (this.reloadNeeded()) {
                await this.reloadData();
            }
            this.marketRentsGrid.redraw();
        }
    }

    @Watch("propertyVersion")
    async versionWatcher() {
        if (this.reloadNeeded()) {
            await this.reloadData();
        }
    }

    @Watch("marketRentsGrid.entities", { deep: true, immediate: true }) 
    marketRentsGridWatcher() {
        validationService.validatePropertyVersionDetailsMarket(this.marketRentsGrid.entities, this.complexErrors);
    }

    async mounted() {
        await this.reloadData();

        let marketTypeIds = this.getUsedMarketTypeIds();
        let prognosisParamerIds = this.marketRentsGrid.entities.map(x => x.prognosisParameterId).filter((value, index, self) => self.indexOf(value) === index);
        let developmentTypeIds = this.marketRentsGrid.entities.map(x => x.developmentTypeId).filter((value, index, self) => self.indexOf(value) === index);

        this.marketTypes = this.$store.getters.getActiveMarketTypes(marketTypeIds);
        this.prognosisParameters = this.$store.getters.getActivePrognosisParameters(prognosisParamerIds);
        this.developmentTypes = this.$store.getters.getActiveDevelopmentTypes(developmentTypeIds);
    }
    
    async reloadData(marketRents?: MarketRent[] | undefined) {
        await this.marketRentsGrid.reload(marketRents);
        this.complexErrors = [];
        this.currentPropertyVersion = this.propertyVersion!.id;
        this.newDate = this.propertyVersion.calculationStart;
    }

    reloadNeeded(): boolean {
        return this.reload && this.propertyVersion && this.currentPropertyVersion !== this.propertyVersion.id;
    }

    getUsedMarketTypeIds(): number[] {
        return this.marketRentsGrid.entities.map(x => x.marketTypeId).filter((value, index, self) => self.indexOf(value) === index);
    }

    updateMarketTypes() {
        let usedIds = this.getUsedMarketTypeIds();
        let currentId = this.marketRentsGrid.currentItem ? this.marketRentsGrid.currentItem.entity.marketTypeId : null;
        this.activeMarketTypes = this.marketTypes.filter(x => (currentId && x.id === currentId) || usedIds.indexOf(x.id) === -1);
    }

    loadMarketRents(): AxiosPromise<MarketRent[]> {
        return DataService.getMarketRent(this.propertyVersion.id);
    }

    newMarketRent() {
        return <MarketRent>{
            id: this.getNewId(this.marketRentsGrid.entities),
            propertyVersionId: this.propertyVersion.id,
            marketRentId: 0,
            marketTypeId: 0, // Set in edit function after filtering the list
            rentLevel: 0,
            startDate: this.propertyVersion.calculationStart,
            index: 100,
            prognosisParameterId: null,
            developmentTypeId: this.developmentTypes[0].id,
            usedByCount: 0,
        }
    }

    editMarketRent(item: MarketRent) {
        this.updateMarketTypes();
        if (item.id < 1 && item.marketTypeId === 0) {
            item.marketTypeId = this.activeMarketTypes[0].id;
        }
    }

    developmentChanged() {
        this.marketRentsGrid.currentItem!.entity.prognosisParameterId = null;
    }

    prognosisChanged() {
        this.marketRentsGrid.currentItem!.entity.developmentTypeId = null;
    }

    async save() {
        this.saving = true;
        await dataService.saveMarketRents(this.propertyVersion.id, this.marketRentsGrid.entities)
            .then(async x => {
                await this.reloadData(x.data); 
                await this.$store.dispatch("sendBusMessage", <BusMessage<MarketRentChangedMessage>>{ type: "MarketRentChanged" });
                this.saving = false;
                return true;
            })
            .catch(() => {
                this.$notify.error({
                    title: this.translate('ErrorUnexpected'),
                    message: this.translate('ErrorActionFailed')
                });
                this.saving = false;
                return false;
            });
    }

    update() {
        if (this.marketRentsGrid)
            for (let i = 0; i < this.marketRentsGrid.items.length; i++)
                this.marketRentsGrid.items[i].entity.startDate = this.newDate;
    }
    
    async undo() {
        await this.reloadData();
    }

    isValid(): boolean {
        return this.complexErrors.length === 0;
    }

    canUpdate(): boolean {
        return this.marketRentsGrid.entities.length > 0 && !this.versionLocked() && !this.marketRentsGrid.loadError;
    }

    canUndo(): boolean {
        return !this.versionLocked() && !this.marketRentsGrid.loadError;
    }

    canSave(): boolean {
        return !this.saving && this.isValid() && !this.versionLocked() && !this.marketRentsGrid.loadError;
    }

    canAdd(): boolean {
        return !this.versionLocked() && !this.marketRentsGrid.loadError;
    }

    duplicateText(): string {
        const duplicateMarketTypeIds = this.marketRentsGrid.items.map(x => x.entity.marketTypeId).filter((v, i, a) => a.indexOf(v) !== i);
        if (duplicateMarketTypeIds.length === 0) {
            return "";
        }
        else
        {
            const marketTypes = this.marketTypes.filter(x => duplicateMarketTypeIds.some(m => x.id == m));
            const typesList = marketTypes.map(x => x.name).join(", ");
            return this.translate("DuplicateMarketRents", { types: typesList });
        }
    }

    toggleEditMultipleDialog() {
        this.editMultipleDialogOpened = !this.editMultipleDialogOpened;
    }

    getCurrency() {
        let currencies = this.$store.getters.getCurrencies;
        if (!currencies) return null;
        return (<Currency[]> currencies).find(x => x.id == this.propertyVersion.calculationValue!.currencyId);
    }

    getCurrencyRate() {
        let currencies = this.$store.getters.getCurrencies;
        if (!this.propertyVersion || !currencies) return 1;
        let versionCurrency = (<Currency[]> currencies).find(x => x.id == this.propertyVersion.currencyId);
        if (!versionCurrency) return 0;
        let stateCurrency = (<Currency[]> currencies).find(x => x.id == this.propertyVersion.calculationValue!.currencyId);
        if (!stateCurrency) return 0;
        return versionCurrency.rate / stateCurrency.rate;
    }
}
