import { BaseViewModel } from "@/models/interfaces/BaseViewModel";
import { Entity } from "@/models/interfaces/Entity";
import { AxiosPromise } from "axios";
import BaseComponent from "@/components/base-component";
import { General } from "./general";

export default class InlineEdit<T extends Entity> {
    vue: BaseComponent;
    tableRef: string;
    entities: T[] = [];
    items: BaseViewModel<T>[] = [];
    currentItem: BaseViewModel<T> | null = null;
    editItem: BaseViewModel<T> | null = null;
    clonedItem: BaseViewModel<T> | null = null;
    loadFunction: () => AxiosPromise<T[]>;
    saveFunction: ((entity: T) => AxiosPromise<T>) | undefined;
    deleteFunction: ((id: number) => AxiosPromise<T>) | undefined;
    newFunction: (() => T) | undefined = undefined;
    newDataFunction: (() => T) | undefined = undefined;
    editFunction: ((entity: T) => void) | undefined;
    validateFunction: (() => boolean) | undefined;
    loading: boolean = false;
    sync: boolean = false;
    readOnly: boolean = false;
    loadError: boolean = false;
    useConfirmDeleteDialog: boolean = true;

    onItemChanged: ((entity: T, action: string) => void) | undefined;

    constructor(vue: BaseComponent, tableRef: string,
        loadFunc: () => AxiosPromise<T[]>,
        saveFunc: ((entity: T) => AxiosPromise<T>) | undefined = undefined,
        deleteFunc: ((id: number) => AxiosPromise<T>) | undefined = undefined,
        newFunc: (() => T) | undefined = undefined,
        editFunc: ((entity: T) => void) | undefined,
        validateFunc: (() => boolean) | undefined) {

        this.vue = vue;
        this.tableRef = tableRef;
        this.loadFunction = loadFunc;

        if (saveFunc)
            this.saveFunction = saveFunc;

        if (deleteFunc)
            this.deleteFunction = deleteFunc;

        if (editFunc)
            this.editFunction = editFunc;

        if (newFunc)
            this.newFunction = newFunc;

        if (validateFunc)
            this.validateFunction = validateFunc;
    }

    async reload(items?: T[]): Promise<void> {
        this.loading = true;

        if (items) {
            this.entities = items;
        }
        else {
            try {
                this.entities = await this.loadFunction().then(x => x.data);
                this.loadError = false;
            } catch (error) {
                this.loadError = true;
                this.entities = [];
            }
        }

        this.items = this.entities.map(e => <BaseViewModel<T>>{ entity: e, edit: false, busy: false })

        // Find the first item in the list, taking the sort order of the datatable in account
        let firstItem: BaseViewModel<T> | null = null;
        if (this.items.length > 0) {
            const tbl = <any>this.vue.$refs[this.tableRef];
            if (tbl && tbl.defaultSort) {
                if ((tbl.defaultSort.order || '').toLowerCase() === 'descending') {
                    firstItem = this.items.sort((a, b) => General.resolvePath(b, tbl.defaultSort.prop, 0) - General.resolvePath(a, tbl.defaultSort.prop, 0))[0];
                } else {
                    firstItem = this.items.sort((a, b) => General.resolvePath(a, tbl.defaultSort.prop, 0) - General.resolvePath(b, tbl.defaultSort.prop, 0))[0];
                }
            }
        }
       
        this.deselect();
        this.setCurrentRow(this.items.length > 0 ? (firstItem || this.items[0]) : null);
        this.loading = false;
    }

    async itemSelected(item: BaseViewModel<T>) {
        // if (this.readOnly) return;
        if (await this.saveOnLeave(item)) {
            this.currentItem = item;
        }
        this.edit(item);
    }

    async saveOnLeave(item: BaseViewModel<T> | null = null): Promise<boolean> {
        if (this.editItem) {
            if (item && item.entity.id == this.editItem.entity.id)
                return true;

            if (this.itemDirty() || this.itemNew()) {
                    let ok = await this.save();
                    if (!ok) {
                        this.setCurrentRow(this.editItem);
                        return false;
                }
            } else {
                this.editItem.edit = false;
                this.editItem = null;
            }
        }
        return true;
    }

    async edit(item: BaseViewModel<T>) {
        if (this.readOnly) return;

        if (await this.saveOnLeave(item)) {
            this.clonedItem = JSON.parse(JSON.stringify(item)); // Cloned object used for undo functionality
            item.edit = true;
            this.editItem = item;
            this.setCurrentRow(this.editItem);

            if (this.editFunction)
                this.editFunction(this.editItem.entity);
        }
    }

    async save(): Promise<boolean> {
        if (!this.editItem) {
            return false;
        }
        else {
            try {
                if (this.validateFunction && !this.validateFunction())
                    return false;

                this.editItem.busy = true;

                if (this.saveFunction) {
                    let response = await this.saveFunction(this.editItem.entity);
                    this.editItem.entity.id = response.data.id;
                }

                if (this.onItemChanged)
                    this.onItemChanged(this.editItem.entity, this.itemNew() ? "added" : "changed");

                // let newVm = <BaseViewModel<T>>{ entity: response.data, edit: false };
                // this.items.splice(this.items.indexOf(this.editItem), 1, newVm);
                // this.setCurrentRow(newVm);
                this.editItem.busy = false;
                this.deselect();

                return true;
            }
            catch (error) {
                this.editItem.busy = false;
                this.vue.$alert(this.vue.translate('RowSaveError') + error, this.vue.translate('Error') || "", { confirmButtonText: 'OK' });
                return false;
            }
        }
    }

    undo() {
        if (this.readOnly) return;

        if (!this.editItem) return;

        this.editItem.busy = true;

        if (this.editItem.entity.id < 1) {
            if (this.sync)
                this.entities.splice(this.entities.indexOf(this.editItem.entity), 1)

            this.items.splice(this.items.indexOf(this.editItem), 1)
        }
        else {
            if (this.clonedItem) {
                if (this.sync)
                    this.entities.splice(this.entities.indexOf(this.editItem.entity), 1, this.clonedItem.entity)

                this.editItem.entity = this.clonedItem.entity;
            }
        }

        this.editItem.busy = false;
        this.deselect();
    }

    async add() {
        if (!this.newFunction || this.readOnly) return;

        if (await this.saveOnLeave()) {
            let newEntity = this.newFunction();
            let newItems = this.items.filter(x => x.entity.id < 1);
            newEntity.id = newItems.length == 0 ? 0 : Math.min(...newItems.map(x => x.entity.id)) - 1;

            if (this.sync)
                this.entities.push(newEntity);

            let newItem = <BaseViewModel<T>>{ entity: newEntity, edit: true, busy: false };
            this.items.push(newItem);
            this.deselect();
            this.editItem = newItem;
            this.setCurrentRow(newItem);

            if (this.editFunction)
                this.editFunction(this.editItem.entity);

            this.vue.$nextTick(() => {
                const tbl = this.vue.$refs[this.tableRef] as any;
                if (tbl) {
                    tbl.$children[tbl.$children.length - 1].$children[tbl.$children[tbl.$children.length - 1].$children.length - 1].$el.scrollIntoView(false);
                }
            });
        }
    }

    async addData(data: any) {
        if (!this.newFunction || this.readOnly) return;
      
        if (await this.saveOnLeave()) {
          let newEntity = this.newFunction();
      
          Object.assign(newEntity, data);
      
          let newItems = this.items.filter(x => x.entity.id < 1);
          newEntity.id = newItems.length == 0 ? 0 : Math.min(...newItems.map(x => x.entity.id)) - 1;
      
          if (this.sync)
            this.entities.push(newEntity);
      
          let newItem = <BaseViewModel<T>>{ entity: newEntity, edit: true, busy: false };
          this.items.push(newItem);
          this.deselect();
          this.editItem = newItem;
          this.setCurrentRow(newItem);
      
          if (this.editFunction)
            this.editFunction(this.editItem.entity);
      
          this.vue.$nextTick(() => {
            const tbl = this.vue.$refs[this.tableRef] as any;
            if (tbl) {
              tbl.$children[tbl.$children.length - 1].$children[tbl.$children[tbl.$children.length - 1].$children.length - 1].$el.scrollIntoView(false);
            }
          });
        }
      }

    async delete(item: BaseViewModel<T>) {
        if (this.readOnly) return;

        if (!this.useConfirmDeleteDialog) {
            this.deleteAction(item);
            return;
        }

        if (await this.saveOnLeave(item)) {
            this.setCurrentRow(item);
            if (this.itemNew()) {
                this.deleteAction(item);
            }
            else {
                this.vue.confirm('ConfirmDeleteRow', 'Confirm').then(() => {
                    item.busy = true;
                    if (this.deleteFunction) {
                        this.deleteFunction(item.entity.id).then(response => {
                            this.deleteAction(item);

                            if (this.onItemChanged)
                                this.onItemChanged(item.entity, "deleted");
                        }).catch(response => {
                            item.busy = false;
                            this.vue.$alert(this.vue.translate('RowDeleteError') + response.error, this.vue.translate('Error') || "", { confirmButtonText: 'OK' });
                        });
                    }
                    else {
                        this.deleteAction(item);
                    }
    
                }).catch(() => {
                    // User canceled
                });
            }
        }
    }

    private deleteAction(item: BaseViewModel<T>) {
        if (this.sync)
            this.entities.splice(this.entities.indexOf(item.entity), 1)

        this.items.splice(this.items.indexOf(item), 1)
        this.currentItem = null;
        item.busy = false;
        let nextItem = this.items.length > 0 ? this.items[0] : null;
        this.setCurrentRow(nextItem);
    }

    itemDirty(): boolean {
        if (!this.editItem || !this.clonedItem)
            return false;
        return JSON.stringify(this.editItem.entity) !== JSON.stringify(this.clonedItem.entity);
    }

    itemNew(): boolean {
        return this.currentItem !== null && this.currentItem.entity.id < 1;
    }

    deselect() {
        if (this.editItem)
            this.editItem.edit = false;

        this.clonedItem = null;
        this.editItem = null;
    }

    setCurrentRow(item: BaseViewModel<T> | null) {
        this.currentItem = item;
        this.vue.$nextTick(() => {
            const tbl = this.vue.$refs[this.tableRef] as any;
            if (tbl) {
                tbl.setCurrentRow(item);
            }
        });
    }
    
    redraw(): void {
        const tbl = this.vue.$refs[this.tableRef] as any;
        if (tbl) {
            tbl.doLayout();
        }
    }
}