import BaseComponent from "@/components/base-component";
import { Component, Watch, Prop } from "vue-property-decorator";
import { mapGetters } from 'vuex'
import { Classification, PropertyVersion } from "@/models";
import dataService from "@/services/data-service";
import InlineEdit from "@/helpers/inline-edit";
import { AxiosPromise } from "axios";
import { ClassificationChangedMessage, BusMessage } from '@/models/messages/messages';

@Component({
    computed: mapGetters({
        propertyVersion: "getPropertyVersion"
    })
})
export default class DetailsClassificationComponent extends BaseComponent {
    propertyVersion!: PropertyVersion;

    classificationsGrid: InlineEdit<Classification>;
    currentPropertyVersion: number = -1;
    saving: boolean = false;

    constructor() {
        super();
        this.classificationsGrid = new InlineEdit<Classification>(this, "classificationsTable", this.loadClassifications, undefined, undefined, undefined, undefined, this.hasNoErrors);
        this.classificationsGrid.sync = true;
        this.classificationsGrid.readOnly = this.versionLocked();
    }

    @Prop()
    reload!: boolean;

    @Watch("reload")
    async reloadWatcher() {
        if (this.reload) {
            await this.reloadData();
            this.classificationsGrid.redraw();
        }
    }

    @Watch("propertyVersion")
    async versionWatcher() {
        if (this.reload) {
            await this.reloadData();
        }
    }

    async mounted() {
        await this.classificationsGrid.reload();
    }

    async reloadData() {
        await dataService.refreshClassifications(this.propertyVersion!.id);
        await this.classificationsGrid.reload();
        this.currentPropertyVersion = this.propertyVersion!.id;
    }

    loadClassifications(): AxiosPromise<Classification[]> {
        return dataService.getClassifications(this.propertyVersion.id);
    }

    async save() {
        this.saving = true;
        await dataService.saveClassifications(this.propertyVersion.id, this.classificationsGrid.entities)
            .then(async x => {
                this.classificationsGrid.reload();
                await this.$store.dispatch("sendBusMessage", <BusMessage<ClassificationChangedMessage>>{ type: "ClassificationChanged" });
                this.saving = false;
                return true;
            })
            .catch(() => {
                this.$notify.error({
                    title: this.translate('ErrorUnexpected'),
                    message: this.translate('ErrorActionFailed')
                });
                this.saving = false;
                return false;
            });
    }

    async undo() {
        await this.classificationsGrid.reload();
    }

    isValid(): boolean {
        return true;
    }

    canUndo(): boolean {
        return !this.versionLocked() && !this.classificationsGrid.loadError;
    }

    canSave(): boolean {
        return !this.saving && this.isValid() && !this.versionLocked() && !this.classificationsGrid.loadError;
    }
}