import BaseComponent from "@/components/base-component";
import { Component, Watch, Prop } from "vue-property-decorator";
import { PropertyShare } from "@/models/PropertyShare";
import { JointOwnershipProperty } from "@/models/JointOwnershipProperty";
import InlineEdit from '@/helpers/inline-edit';
import { AxiosPromise, AxiosResponse } from 'axios';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import { ValidationError } from '@/models/interfaces/ValidationError';
import InputTextComponent from '@/components/common/input-text/input-text';
import ValidationTableFeedbackComponent from '@/components/common/validation-table-feedback/validation-table-feedback';
import ValidationErrorComponent from '@/components/common/validation-error/validation-error';
import { BaseViewModel } from "@/models/interfaces/BaseViewModel";

@Component({
    components: {
        InputNumericComponent: InputNumericComponent,
        InputTextComponent: InputTextComponent,
        ValidationTableFeedbackComponent: ValidationTableFeedbackComponent,
        ValidationErrorComponent: ValidationErrorComponent,
    }
})
export default class SharesComponent extends BaseComponent {
    @Prop()
    shares!: PropertyShare[];
    @Prop()
    complexErrors!: ValidationError[];
    @Prop()
    isLinked!: boolean;

    @Prop()
    layout!: boolean;

    sharesGrid: InlineEdit<PropertyShare>;
    jointsGrid: InlineEdit<JointOwnershipProperty>;

    @Watch("shares", { immediate: true })
    sharesWatcher() {
        this.sharesGrid.reload();
        this.sharesGrid.readOnly = this.isLinked;
    }

    @Watch("sharesGrid.currentItem")
    currentShareWatcher() {
        this.jointsGrid.reload();
    }

    @Watch("layout")
    layoutWatcher() {
        if (this.layout == true) {
            (<any> this).$refs.sharesTable.doLayout();
            (<any> this).$refs.jointsTable.doLayout();
        }
    }

    constructor() {
        super();
        this.sharesGrid = new InlineEdit<PropertyShare>(this, "sharesTable", this.loadShares, undefined, undefined, this.newShare, undefined, this.hasNoErrors);
        this.sharesGrid.sync = true;
        this.jointsGrid = new InlineEdit<JointOwnershipProperty>(this, "jointsTable", this.loadJoints, undefined, undefined, this.newJoint, undefined, this.hasNoErrors);
        this.jointsGrid.sync = true;
        this.sharesGrid.useConfirmDeleteDialog = false;
        this.jointsGrid.useConfirmDeleteDialog = false;
    }

    loadShares(): AxiosPromise<PropertyShare[]> {
        return Promise.resolve(<AxiosResponse>{ data: this.shares })
    }

    newShare(): PropertyShare {
        return <PropertyShare>{
            id: this.getNewId(this.sharesGrid.entities),
            shareTypeText: "",
            purpose: "",
            yearlyRevenue: 0,
            shareTypeId: 1,
            jointOwnershipProperties: [{
                id: 0,
                propertyName: ""
            }]
        };
    }

    loadJoints(): AxiosPromise<JointOwnershipProperty[]> {
        return Promise.resolve(<AxiosResponse>{ data: this.sharesGrid.currentItem ? this.sharesGrid.currentItem.entity.jointOwnershipProperties : [] })
    }

    newJoint(): JointOwnershipProperty {
        return <JointOwnershipProperty>{
            id: this.getNewId(this.sharesGrid.currentItem!.entity.jointOwnershipProperties),
            propertyName: ""
        };
    }

    canAddJoint(): boolean {
        return !!this.sharesGrid.currentItem;
    }

    getJointProperties(share: PropertyShare): string {
        return share.jointOwnershipProperties.map(x => x.propertyName).join(", ");
    }

    getJointRefId(share: BaseViewModel<PropertyShare> | null, jointProperty: BaseViewModel<JointOwnershipProperty> | null): string {
        return (share ? share.entity.id : "") + "|" + (jointProperty ? jointProperty.entity.id : "")
    }
}