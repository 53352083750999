import BaseComponent from "@/components/base-component";
import { Component, Watch, Prop } from "vue-property-decorator";
import InlineEdit from '@/helpers/inline-edit';
import { AxiosPromise, AxiosResponse } from 'axios';
import { PropertyDetail, PropertyEnergyClass, Property, BoverketEnergyDeclaration, EnvironmentalCertificate, EnvironmentalCertificateLevel, PropertyEnvironmentalCertificate } from '@/models';
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import InputTextComponent from '@/components/common/input-text/input-text';
import { ValidationError } from '@/models/interfaces/ValidationError';
import { PropertyEnergyClassType } from "@/constants/fia-constants";
import DataService from '@/services/data-service';
import { mapGetters } from 'vuex';

@Component({
    components: {
        YearMonthPickerComponent,
        InputNumericComponent,
        InputTextComponent
    },
    computed: mapGetters({
        environmentalCertificates: "getEnvironmentalCertificates",
        environmentalCertificateLevels: "getEnvironmentalCertificateLevels",
    })
})
export default class ESGComponent extends BaseComponent {
    @Prop()
    propertyDetail!: PropertyDetail;
    @Prop()
    complexErrors!: ValidationError[];
    @Prop()
    isLinked!: boolean;
    @Prop()
    layout!: boolean;
    @Prop()
    property!: Property;

    esgGrid: InlineEdit<PropertyEnergyClass>;
    boverketEnergyDeclarationData: BoverketEnergyDeclaration[] = [];
    specificPropertyName: string = "";
    environmentalCertificates!: EnvironmentalCertificate[];
    environmentalCertificateLevels!: EnvironmentalCertificateLevel[];
    environmentalCertificateGrid: InlineEdit<PropertyEnvironmentalCertificate>;


    @Watch("property", { immediate: true })
    async propertyWatcher() {
        await this.esgGrid.reload();
        await this.environmentalCertificateGrid.reload();
        this.boverketEnergyDeclarationData = [];
        if (this.property) {
            this.specificPropertyName = this.property.propertyName;
          }
    }

    @Watch("layout")
    layoutWatcher() {
        if (this.layout == true) {
            (<any> this).$refs.esgTable.doLayout();
            (<any> this).$refs.envCertificatesTable.doLayout();
        }
    }

    constructor() {
        super();
        this.esgGrid = new InlineEdit<PropertyEnergyClass>(this, "esgTable", this.loadESG, undefined, undefined, this.newESG, undefined, this.hasNoErrors);
        this.esgGrid.sync = true;
        this.environmentalCertificateGrid = new InlineEdit<PropertyEnvironmentalCertificate>(this, "envCertificatesTable", this.loadEnvironmentalCertificates, undefined, undefined, this.newCertificate, undefined, this.hasNoErrors);
        this.environmentalCertificateGrid.sync = true;
    }

    loadESG(): AxiosPromise<PropertyEnergyClass[]> {
        return Promise.resolve(<AxiosResponse>{ data: this.property.propertyEnergyClasses })
    }

    newESG(): PropertyEnergyClass {
        return <PropertyEnergyClass>{
            id: this.getNewId(this.esgGrid.entities),
            energyDeclarationId: "",
            energyClass: "",
            energyDeclarationDate: this.newIsoDateMonth(),
            comment: "",
            primaryEnergyNumber: 0,
            energyPerformance: 0,
            propertyId: 0,
            address: "",
            radonMeasurement: "",
            ventilationControl: "",
        }
    }

    getPropertyEnergyClassOptions(): { label: string, value: string }[] {
        const enumValues = Object.values(PropertyEnergyClassType);
        return enumValues.map(value => ({
            label: value,
            value: value
        }));
    }

    importFromBoverket() {
        const viewCountyName = this.property.viewCountyName ? this.property.viewCountyName : "";
        const propertyName = this.specificPropertyName != "" ? this.specificPropertyName : this.property.propertyName;
        this.$notify.info({
            title: 'Info',
            message:  this.translate("BoverketInfoMessageSection1") + " [" + viewCountyName + "] " + this.translate("BoverketInfoMessageSection2") + " [" + propertyName + "]."});
        DataService.getBoverketEnergyDeclarations(viewCountyName, propertyName).then(x => {
            this.boverketEnergyDeclarationData = x.data.energideklarationer;
        }).catch(() => {
           return;
        }).finally(() => {
            if(this.boverketEnergyDeclarationData.length == 0){
                this.$notify.error({
                    title: this.translate('Error'),
                    message:  this.translate("BoverketErrorMessageSection1") + " [" + viewCountyName + "] " + this.translate("BoverketErrorMessageSection2") + " [" + propertyName + "]."});
            }
        });
    }

    copyRow(row: any) {
        this.esgGrid.addData(<PropertyEnergyClass>{
            id: this.getNewId(this.esgGrid.entities),
            energyDeclarationId: row.id ? row.id : "",
            energyClass: row.energiklass ? row.energiklass : "",
            energyDeclarationDate: row.utford ? row.utford : "",
            comment: "",
            primaryEnergyNumber: row.primarenergital ? this.extractNumericValue(row.primarenergital) : 0,
            energyPerformance: row.energiprestanda ? this.extractNumericValue(row.energiprestanda) : 0,
            propertyId: 0,
            address: row.fastigheter[0].adresser[0].adress ? row.fastigheter[0].adresser[0].adress : "",
            radonMeasurement: row.radonmatning ? row.radonmatning : "",
            ventilationControl: row.ventilationskontroll ? row.ventilationskontroll : "",
        })
        const index = this.boverketEnergyDeclarationData.indexOf(row);
        if (index !== -1) {
           this.boverketEnergyDeclarationData.splice(index, 1);
        }
      }
    
    extractNumericValue(inputString: string) {
        const match = inputString.match(/\d+/);
      
        if (match) {
          const numericValue = parseInt(match[0], 10);
          return numericValue;
        } else {
          return 0;
        }
      }

      handleCertificateChange(row: any, newValue: any) {
        const matchingLevels = this.getCertificateLevelsFromCertificate(newValue);
        if (matchingLevels.length > 0) {
          row.entity.environmentalCertificateLevelId = matchingLevels[0].id;
        } else {
          row.entity.environmentalCertificateLevelId = null;
        }
    }

    loadEnvironmentalCertificates(): AxiosPromise<PropertyEnvironmentalCertificate[]> {
        this.environmentalCertificates.sort((a, b) => a.id - b.id);
        const environmentalCertificates = Array.isArray(this.property.propertyEnvironmentalCertificates) ? this.property.propertyEnvironmentalCertificates : [];
    
        environmentalCertificates.forEach(certificate => {
            const level = this.environmentalCertificateLevels.find(level => level.id === certificate.environmentalCertificateLevelId);
            if (level) {
                certificate.environmentalCertificateId = level.environmentalCertificateId;
            }
        });
    
        return Promise.resolve(<AxiosResponse>{ data: environmentalCertificates });
    }

    getCertificateLevelName(id: number) {
        const level = this.environmentalCertificateLevels.find(level => level.id === id);
        return level ? level.name : null;
    }

    getCertificateName(id: number) {
        const certificate = this.environmentalCertificates.find(cert => cert.id === id);
        return certificate ? certificate.name : null;
    }

    getCertificateFromLevel(id: number){
        const level = this.environmentalCertificateLevels.find(level => level.id === id);
        const certificate = level ? this.environmentalCertificates.find(certificate => certificate.id === level?.environmentalCertificateId) : null;
        return certificate ? certificate.id : null;
    }

    newCertificate(): PropertyEnvironmentalCertificate {
        return <PropertyEnvironmentalCertificate>{
            id: this.getNewId(this.environmentalCertificateGrid.entities),
            environmentalCertificateLevelId: 1001,
            comment: "",
            startDate: "",
            environmentalCertificateId: 1,
        }
    }

    getCertificateLevelsFromCertificate(id: number) {
        return this.environmentalCertificateLevels.filter(level => level.environmentalCertificateId === id);
    }

}