import moment from 'moment';
import { Component, Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import BaseComponent from '@/components/base-component';
import DialogComponent from '@/components/common/dialog/dialog';
import InputTextComponent from '@/components/common/input-text/input-text';
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import { YearMonthDayFormat } from '@/constants/fia-constants';
import { PortfolioValuationData } from '@/models/PortfolioValuationData';
import { sourceCodeMouseDown } from '@syncfusion/ej2-vue-richtexteditor';

@Component({
    components: {
        DialogComponent,
        YearMonthPickerComponent,
        InputTextComponent,
    },
    computed: mapGetters({
    })
})
export default class PortfolioValuationComponent extends BaseComponent {
    
    @Prop()
    open!: boolean;

    @Prop()
    generateDisabled!: boolean;

    private readonly emptyValuationData: string = JSON.stringify(<PortfolioValuationData> {
        purpose: "",
        employer: "",
        startDate: moment(new Date()).startOf("month").startOf("day").format(YearMonthDayFormat),
        valuationDate: moment(new Date()).startOf("month").startOf("day").format(YearMonthDayFormat),
        preliminaryAssessment: false,
    });

    portfolioValuationData: PortfolioValuationData = JSON.parse(this.emptyValuationData); 

    cancel() {
        this.close();
    }

    generate() {
        this.$emit("generate", this.portfolioValuationData);
        //this.portfolioValuationData = JSON.parse(this.emptyValuationData);
        this.close();
    }
    
    close() {
        this.$emit("update:open", false);
    }

    isDate(str: string) {
        if (str == null || str.length === 0)  return true; //Allow field to be null
        return moment(str).isValid();
    }
}