import BaseComponent from '@/components/base-component';
import { Component, Prop, Watch } from "vue-property-decorator";
import { WatchOptions } from 'vue';
import { mapGetters } from 'vuex';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import { PaymentGroup, TypeOfPayment, ShareOfPaymentStream, Transaction } from '@/models';
import { ValidationError } from "@/models/interfaces/ValidationError";

@Component({
    components: {
        InputNumericComponent
    },
    computed: mapGetters({
        paymentGroupList: "getPaymentGroupsNormal",
        typeOfPaymentList: "getTypeOfPayments"
    })
})
export default class ShareStreamComponent extends BaseComponent {
    paymentGroupList!: PaymentGroup[];
    typeOfPaymentList!: TypeOfPayment[];

    @Prop()
    complexErrors!: ValidationError[];
    @Prop()
    stream!: ShareOfPaymentStream;
    @Prop()
    typeOfFlow!: string;
    @Prop()
    transaction!: Transaction;

    paymentGroupId: number | null = null;
    activeTypeOfPayments: TypeOfPayment[] = [];

    @Watch("stream", <WatchOptions>{ immediate: true })
    streamWatcher() {
        if (!this.stream) return;

        this.activeTypeOfPayments = this.$store.getters.getActiveTypeOfPayments([this.stream.typeOfPaymentId]);

        if (this.stream.typeOfPaymentId) {
            let tp = this.activeTypeOfPayments.find(x => x.id === this.stream.typeOfPaymentId);
            this.paymentGroupId = tp ? tp.paymentGroupId : this.paymentGroupList[0].id;
            this.filterTypeList();
        }
    }

    @Watch("transaction.typeOfPaymentId") 
    transTypeofPaymentWatcher() {
        if (this.typeOfFlow !== "recovery") return;
        
        let top = this.typeOfPaymentList.find(x => x.id === this.transaction.typeOfPaymentId);
        if (!top) return;

        if (top.vacansLocked) {
            let topVacancy = this.typeOfPaymentList.find(x => x.id === top!.vacansOfTypeOfPaymentId);
            if (topVacancy) {
                this.paymentGroupId = topVacancy.paymentGroupId;
                this.filterTypeList();
                this.stream.typeOfPaymentId = topVacancy.id;
            }
        }
    }

    get groupOrType(): string {
        return this.stream.paymentGroupId ? "group" : "type";
    }

    set groupOrType(value: string) {
        if (value === "group") {
            this.stream.paymentGroupId = this.paymentGroupId;
            this.paymentGroupId = null;
            this.stream.typeOfPaymentId = null;
        }
        else {
            this.paymentGroupId = this.stream.paymentGroupId;
            this.stream.paymentGroupId = null;
            this.groupChanged();
        }
    }

    groupChanged() {
        this.filterTypeList();
        this.stream.typeOfPaymentId = this.activeTypeOfPayments[0].id;
    }

    filterTypeList() {
        this.activeTypeOfPayments = this.typeOfPaymentList.filter(x => x.paymentGroupId === this.paymentGroupId);
    }

    useTypeOfPayment(): boolean {
        return this.stream.typeOfPaymentId !== null;
    }

    canChangePaymentGroup(): boolean {
        return this.typeOfFlow !== "recovery" && !this.vacancyLocked();
    }

    canChangePaymentType(): boolean {
        return !this.vacancyLocked(); 
    }

    canEditPaymentGroup(): boolean {
        return this.groupOrType === "group" && this.canChangePaymentGroup();
    }

    canEditPaymentType(): boolean {
        return this.groupOrType === "type" && this.canChangePaymentType();
    }

    vacancyLocked(): boolean {
        if (this.typeOfFlow === "recovery") {
            let top = this.typeOfPaymentList.find(x => x.id === this.transaction.typeOfPaymentId);
            return top !== undefined && top.vacansLocked !== null && top.vacansLocked === true;
        }
        else {
            return false;
        }
    }
}