export const sv: object = {
  sv: {
    treegrid: {
      EmptyRecord: 'Inga träffar',
      ExpandAll: 'Expandera alla',
      CollapseAll: 'Stäng alla',
      Print: "Skriv ut",
      Pdfexport: "PDF-export",
      Excelexport: "Excel-export",
      Wordexport: "Word-export",
      FilterButton: "Filter",
      ClearButton: "Rensa",
      StartsWith: "Börjar med",
      EndsWith: "Slutar med",
      Contains: "Innehåller",
      Equal: "Lika med",
      NotEqual: "Ej lika med",
      LessThan: "Mindre än",
      LessThanOrEqual: "Mindre än / lika med",
      GreaterThan: "Större än",
      GreaterThanOrEqual: "Större än / lika med",
      EnterValue: "Ange värde",
      FilterMenu: "Filter",
      ClearFilter: "Rensa filter",
      Search: "Sök",
      OKButton: "Ok",
      CancelButton: "Avbryt",
      Matchs: "Inga träffar",
      TextFilter: "Text-filter",
      CustomFilter: "Anpassat filter",
      ShowRowsWhere: "Visa rader där:",
      CustomFilterPlaceHolder: "Ange värde",
      CustomFilterDatePlaceHolder: "Ange datum",
      AND: "OCH",
      OR: "ELLER",
      MatchCase: "Skiftlägeskänslig",
      SelectAll: "Alla",
      Between: "Mellan",
      NumberFilter: "Numeriska filter",
      DateFilter: "Datum filter",
      SortAscending: "Stigande",
      SortDescending: "Fallande"
    },
    pager: {
      currentPageInfo: '{0} av {1} sidor',
      totalItemsInfo: '({0} rader)',
      firstPageTooltip: 'Första sidan',
      lastPageTooltip: 'Sista sidan',
      nextPageTooltip: 'Nästa sida',
      previousPageTooltip: 'Föregående sida',
      nextPagerTooltip: 'Nästa sida',
      previousPagerTooltip: 'Föregående sida'
    },
    dropdowns: {
      noRecordsTemplate: "Inga data"
    },
    datepicker: {
      placeholder: "Välj datum",
      today: "idag"
    }
  }
}