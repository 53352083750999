import BaseComponent from "@/components/base-component";
import DataService from "@/services/data-service";
import InlineEdit from "@/helpers/inline-edit";
import { Component, Watch, Prop } from "vue-property-decorator";
import { AxiosPromise } from "axios";
import { Status } from "@/models/Status";
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import { BaseDataType } from '@/constants/fia-constants';

@Component({
    components: {
        YearMonthPickerComponent,
        InputNumericComponent
    }
})
export default class IpdStatusComponent extends BaseComponent {
    statusesGrid: InlineEdit<Status>;

    @Prop()
    reload!: boolean;
    @Prop()
    readOnly!: boolean;

    @Watch("reload")
    reloadWatcher() {
        if (this.reload == true) {
            (<any> this).$refs.statusesTable.doLayout();
        }
    }

    constructor() {
        super();
        this.statusesGrid = new InlineEdit<Status>(this, "statusesTable", this.loadStatuses, this.saveStatus, this.deleteStatus, this.newStatus, undefined, this.hasNoErrors);
        this.statusesGrid.onItemChanged = this.itemChanged;
        this.statusesGrid.readOnly = this.readOnly;
    }

    mounted() {
        this.statusesGrid.reload();
    }

    loadStatuses(): AxiosPromise<Status[]> {
        return DataService.getStatuses();
    }

    saveStatus(entity: Status): AxiosPromise<Status> {
        return DataService.saveStatus(entity);
    }

    deleteStatus(id: number): AxiosPromise<Status> {
        return DataService.deleteStatus(id);
    }

    newStatus(): Status {
        return <Status> {
            id: 0, name: "", 
            ipdStatus: 1, 
            vacant: false, 
            languageId: this.$store.getters.getUser.languageId 
        };
    }

    itemChanged() {
        this.$store.dispatch('updateCache', BaseDataType.Statuses);
    }
}