import BaseComponent from "@/components/base-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import DataService from "@/services/data-service";
import InlineEdit from "@/helpers/inline-edit";
import { AxiosPromise } from "axios";
import { MarketType } from "@/models/MarketType";
import { BaseDataType } from '@/constants/fia-constants';
import InputNumericComponent from "@/components/common/input-numeric/input-numeric";
import { mapGetters } from "vuex";
import { PremisesType } from "@/models";

@Component({
    computed: mapGetters({
        premisesTypes: "getPremisesTypes",
    }),
    components: {
        InputNumericComponent: InputNumericComponent
    }
})
export default class MarketsComponent extends BaseComponent {
    premisesTypes!: PremisesType[];
    marketsGrid: InlineEdit<MarketType>;

    @Prop()
    reload!: boolean;
    @Prop()
    readOnly!: boolean;

    @Watch("reload")
    reloadWatcher() {
        if (this.reload == true) {
            (<any> this).$refs.marketsTable.doLayout();
        }
    }

    constructor() {
        super();
        this.marketsGrid = new InlineEdit<MarketType>(this, "marketsTable", this.loadMarkets, this.saveMarket, this.deleteMarket, this.newMarket, undefined, this.hasNoErrors);
        this.marketsGrid.onItemChanged = this.itemChanged;
        this.marketsGrid.readOnly = this.readOnly;
    }

    mounted() {
        this.marketsGrid.reload();
    }

    loadMarkets(): AxiosPromise<MarketType[]> {
        return DataService.getMarketTypes();
    }

    saveMarket(entity: MarketType): AxiosPromise<MarketType> {
        if (!this.marketsGrid.clonedItem!.entity.inactive && entity.inactive) {
            if (this.premisesTypes.some(x => x.marketTypeDefaultValueMaps.some(m => m.marketTypeId === entity.id))) {
                throw this.translate("Error_InactivateMarketType");
            }
        }
        return DataService.saveMarketType(entity);
    }

    deleteMarket(id: number): AxiosPromise<MarketType> {
        return DataService.deleteMarketType(id);
    }

    newMarket(): MarketType {
        return <MarketType> { 
            id: 0, 
            name: "", 
            inactive: false, 
            languageId: this.$store.getters.getUser.languageId 
        };
    }
    
    itemChanged() {
        this.$store.dispatch('updateCache', BaseDataType.MarketTypes);
    }
}
