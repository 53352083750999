import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import dataService from '@/services/data-service';
import moment from 'moment';
import { DateFormat } from '@/constants/fia-constants';
import { PropertyVersionSlim } from '@/models/interfaces/PropertyVersionSlim';
import { UpdatePropertiesDateRequest } from '@/models/interfaces/request/UpdatePropertiesDateRequest';
import { UpdateFirstIncrementByVersionsRequest } from '@/models/interfaces/request/UpdateFirstIncrementByVersionsRequest';
import { UpdatePortfolioCalculationPeriodDatesRequest } from '@/models/interfaces/request/UpdatePortfolioCalculationPeriodDatesRequest';
import { UpdatePortfolioMarketRentStartRequest } from '@/models/interfaces/request/UpdatePortfolioMarketRentStartRequest';
import { UpdatePortfolioCostIncomesFirstIncrementRequest } from '@/models/interfaces/request/UpdatePortfolioCostIncomesFirstIncrementRequest';

enum Action {
    EditVersionStartEnd,
    FirstIncrementRecoveries,
    CalculationPeriods,
    MarketRentStart,
    CostIncomesFirstIncrement
}
@Component({
    components: {
        YearMonthPickerComponent
    }
})
export default class EditMultiplePropertiesDialogComponent extends BaseComponent {
    @Prop()
    open!: boolean;

    @Prop()
    properties!: PropertyVersionSlim[];

    visible: boolean = false;
    startDate: string = "";
    endDate: string = "";
    loading: boolean = false;
    actions = Action;
    action: Action = Action.EditVersionStartEnd;

    @Watch("open")
    openWatcher() {
        if (this.open) {
            this.startDate = this.findEarliestDate();
            this.endDate = this.findLatestDate();
            this.visible = true;
        }
    }

    @Watch("action")
    actionWatcher() {
        if (this.action === Action.EditVersionStartEnd || this.action === Action.CalculationPeriods) {
            this.startDate = this.findEarliestDate();
            this.endDate = this.findLatestDate();
        }
        else {
            this.startDate = moment().startOf('year').add(1, 'year').format(DateFormat);
        }
    }

    cancel() {
        this.close(true);
    }

    close(canceled: boolean = false) {
        this.$emit("update:open", false);
        this.$emit("closed", canceled);
    }

    findEarliestDate(): string {
        let dates = this.properties.map(x => x.calculationStart);
        let earliest = dates.reduce((pre, cur) => {
            return Date.parse(pre) > Date.parse(cur) ? cur : pre;
        });
        return earliest;
    }

    findLatestDate(): string {
        let dates = this.properties.map(x => x.calculationEnd);
        let latest = dates.reduce((pre, cur) => {
            return Date.parse(pre) < Date.parse(cur) ? cur : pre;
        });
        return latest;
    }

    getLabelTranslation(value: Action): string {
        switch (value) {
            case Action.FirstIncrementRecoveries:
            case Action.CostIncomesFirstIncrement:
                return this.translate('FirstIncrementMonth_Long');
            case Action.MarketRentStart:
                return this.translate('StartTime');
            default:
                return this.translate('Unkown');
        }
    }

    ok() {
        switch (this.action) {
            case this.actions.EditVersionStartEnd:
                    this.actionEditVersionStartEnd();
                break;
            case this.actions.FirstIncrementRecoveries:
                    this.actionFirstIncrementRecoveries();
                break;
            case this.actions.CalculationPeriods:
                    this.actionCalculationPeriods();
                break;
            case this.actions.CostIncomesFirstIncrement:
                    this.actionCostIncomesFirstIncrement();
                break;
            case this.actions.MarketRentStart:
                    this.actionMarketRentStart();
                break;
        }
    }

    actionEditVersionStartEnd() {
        this.confirm('ConfirmUpdateSelectedProperties').then(async () => {
            try {
                this.loading = true;
                let request: UpdatePropertiesDateRequest = { versionIds: this.properties.map(x => x.id), startDate: this.startDate, endDate: this.endDate }
                await dataService.updatePropertiesDate(request);
                this.loading = false;
                this.close();
            } catch (error) {
                this.loading = false;
                return Promise.reject(new Error(""));
            }
        }).catch(() => {
            return;
        });
    }

    actionFirstIncrementRecoveries() {
        let selectedVersions = this.properties.map(x => x.id);
        if (selectedVersions == null || selectedVersions.length == 0) return;
        
        this.confirm('ConfirmUpdateFirstIncrementByVersions').then(async () => {
            try {
                this.loading = true;
                let request: UpdateFirstIncrementByVersionsRequest = { versionIds: selectedVersions, firstIncrementDate: this.startDate };
                let updatedCount = await dataService.updateRecoveriesFirstIncrementByVersions(request).then(x => x.data);
                this.$notify.info({
                    title: 'Info',
                    message: updatedCount + " " + this.translate("PaymentFlows").toLowerCase() + " " + this.translate("UpdatedAlt").toLowerCase()
                });
                let version = this.$store.getters.getPropertyVersion;
                if (version) {
                    await this.$store.dispatch("loadPropertyVersion", { id: version.id });
                }
                this.loading = false;
                this.close();
            } catch (error) {
                this.loading = false;
                return Promise.reject(new Error(""));
            }
        }).catch(() => {
            return;
        });
    }

    actionCalculationPeriods() {
        let selectedVersions = this.properties.map(x => x.id);
        if (selectedVersions == null || selectedVersions.length == 0) return;

        this.confirm('ConfirmUpdateSelectedPropertiesCalculationPeriods').then(async () => {
            let request;
            try {
                this.loading = true;
                let request: UpdatePortfolioCalculationPeriodDatesRequest = { versionIds: selectedVersions, startDate: this.startDate, endDate: this.endDate }
                let updatedCount = await dataService.updatPortfolioeCalculationPeriods(request).then(x => x.data);
                this.$notify.info({
                    title: 'Info',
                    message: updatedCount + " " + this.translate("CalculationPeriods").toLowerCase() + " " + this.translate("UpdatedAlt").toLowerCase()
                });
                this.loading = false;
                this.close();
            } catch (error) {
                this.loading = false;
                return Promise.reject(new Error(""));
            }
        }).catch(() => {
            return;
        });
    }

    actionCostIncomesFirstIncrement() {
        let selectedVersions = this.properties.map(x => x.id);
        if (selectedVersions == null || selectedVersions.length == 0) return;

        this.confirm('ConfirmUpdateSelectedPropertiesCostIncomesFirstIncrement').then(async () => {
            try {
                this.loading = true;
                let request: UpdatePortfolioCostIncomesFirstIncrementRequest = { versionIds: selectedVersions, firstIncrement: this.startDate }
                let updatedCount = await dataService.updatPortfolioeCostIncomesFirstIncrement(request).then(x => x.data);
                this.$notify.info({
                    title: 'Info',
                    message: updatedCount + " " + this.translate("CostIncomes").toLowerCase() + " " +this.translate("FirstIncrementMonth_Long").toLowerCase() + " " + this.translate("UpdatedAlt").toLowerCase()
                });
                this.loading = false;
                this.close();
            } catch (error) {
                this.loading = false;
                return Promise.reject(new Error(""));
            }
        }).catch(() => {
            return;
        });
    }

    actionMarketRentStart() {
        let selectedVersions = this.properties.map(x => x.id);
        if (selectedVersions == null || selectedVersions.length == 0) return;

        this.confirm('ConfirmUpdateSelectedPropertiesMarketRentStart').then(async () => {
            try {
                this.loading = true;
                let request: UpdatePortfolioMarketRentStartRequest = { versionIds: selectedVersions, startTime: this.startDate }
                let updatedCount = await dataService.updatPortfolioeMarketRentStart(request).then(x => x.data);
                this.$notify.info({
                    title: 'Info',
                    message: updatedCount + " " + this.translate("MarketRents").toLowerCase() + " " +this.translate("StartTime").toLowerCase() + " " + this.translate("UpdatedAlt").toLowerCase()
                });
                this.loading = false;
                this.close();
            } catch (error) {
                this.loading = false;
                return Promise.reject(new Error(""));
            }
        }).catch(() => {
            return;
        });
    }
}