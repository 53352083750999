import { Component, Prop } from "vue-property-decorator";
import { mapGetters } from 'vuex'
import { PropertyVersion } from "@/models";
import BaseComponent from "@/components/base-component";
import VersionDetailsComponent from '@/components/versiondetails/versiondetails';

@Component({
    components: {
        VersionDetailsComponent
    },
    computed: mapGetters({
        propertyVersion: "getPropertyVersion"
    })
})
export default class VersionHeaderComponent extends BaseComponent {
    propertyVersion!: PropertyVersion;

    activeName: string = "0";

    @Prop()
    allowEditDetails!: boolean;

    @Prop()
    fullAdress!: boolean;

    detailsDialogVisible: boolean = false;

    details() {
    }

    calculate() {
    }

    openDetails() {
        this.detailsDialogVisible = true;
    }

    allowEdit() {
        return this.allowEditDetails == null || this.allowEditDetails === true;
    }

    showFullAdress() {
        return this.fullAdress == null || this.fullAdress === true;
    }
}
