import BaseComponent from "@/components/base-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import DataService from "@/services/data-service";
import { Currency } from "@/models/Currency";
import InlineEdit from "@/helpers/inline-edit";
import { AxiosPromise } from "axios";
import { BaseDataType } from "@/constants/fia-constants";
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';

@Component({
    components: {
        InputNumericComponent
    }
})
export default class CurrenciesComponent extends BaseComponent {
    currencyGrid: InlineEdit<Currency>;

    @Prop()
    reload!: boolean;
    @Prop()
    readOnly!: boolean;

    constructor() {
        super();
        this.currencyGrid = new InlineEdit<Currency>(this, "currenciesTable", this.loadCurrencies, this.saveCurrency, this.deleteCurrency, this.newCurrency, undefined, this.hasNoErrors);
        this.currencyGrid.onItemChanged = this.itemChanged;
        this.currencyGrid.readOnly = this.readOnly;
    }

    mounted() {
        this.currencyGrid.reload();
    }  
    
    @Watch("reload")
    reloadWatcher() {
        if (this.reload == true) {
            (<any> this).$refs.currenciesTable.doLayout();
        }
    }

    loadCurrencies(): AxiosPromise<Currency[]> {
        return DataService.getCurrencies();
    }

    saveCurrency(entity: Currency): AxiosPromise<Currency> {
        return DataService.saveCurrency(entity);
    }

    deleteCurrency(id: number): AxiosPromise<Currency> {
        return DataService.deleteCurrency(id);
    }

    newCurrency(): Currency {
        return { id: 0, name: "", rate: 0, languageId: this.$store.getters.getUser.languageId, postPerM2: "", postThousands: "", postUnit: "" };
    }

    itemChanged() {
        this.$store.dispatch('updateCache', BaseDataType.Currencies);
    }
}
