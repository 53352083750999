import { mapGetters } from 'vuex';
import { AxiosPromise, AxiosResponse } from "axios";
import { Component, Watch, Prop } from "vue-property-decorator";
import BaseComponent from "@/components/base-component";
import { Contract, LeaseArea, PremisesType, PropertyVersion } from "@/models";
import InlineEdit from "@/helpers/inline-edit";
import { ValidationError } from '@/models/interfaces/ValidationError';
import InputTextComponent from '@/components/common/input-text/input-text';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import dataService from '@/services/data-service';
import { BusMessage } from '@/models/messages/messages';
import ValidationErrorComponent from '@/components/common/validation-error/validation-error';
import validationService from '@/services/validation-service';

export interface MarketTypeVm {
    id: number;
    text: string;
    firstRentLevel: number;
}

@Component({
    components: {
        InputTextComponent,
        InputNumericComponent,
        ValidationErrorComponent
    },
    computed: mapGetters({
        propertyVersion: "getPropertyVersion",
        premisesTypes: "getPremisesTypes",
        busMessage: "getBusMessage"
    })
})
export default class ContractLeaseAreasComponent extends BaseComponent {
    propertyVersion!: PropertyVersion;
    premisesTypes!: PremisesType[];
    busMessage!: BusMessage<any>;
    sortValueAscending: boolean = false;
    sortNameAscending: boolean = false;

    leaseAreaGrid: InlineEdit<LeaseArea>;
    marketTypes: MarketTypeVm[] = [];

    @Prop()
    contract!: Contract;

    @Prop()
    complexErrors!: ValidationError[];

    @Watch("busMessage")
    async busWatcher() {
        switch (this.busMessage.type) {
            case "MarketRentChanged":
            case "MultiMarketRentChanged":
                await this.updateMarketTypes();
                break;
        }
    }

    @Prop()
    reload!: boolean;

    @Watch("contract")
    async contractWatcher() {
        if (!this.contract) return;

        await this.leaseAreaGrid.reload();
    }

    @Watch("reload")
    reloadWatcher() {
        if (this.reload == true) {
            (<any> this).$refs.leaseAreasTable.doLayout();
        }
    }

    @Watch("leaseAreaGrid.entities", { deep: true })
    leaseAreaGridWatcher() {
        validationService.validateContract(this.contract, this.complexErrors);
    }

    constructor() {
        super();
        this.leaseAreaGrid = new InlineEdit<LeaseArea>(this, "leaseAreasTable", this.loadLeaseAreas, undefined, undefined, this.newLeaseArea, undefined, this.hasNoErrors);
        this.leaseAreaGrid.sync = true;
        this.leaseAreaGrid.useConfirmDeleteDialog = false;
        this.leaseAreaGrid.readOnly = this.versionLocked();
    }

    async mounted() {
        await this.updateMarketTypes();
        await this.leaseAreaGrid.reload();
    }

    sortMarketTypesByValue() {
        this.marketTypes.sort((a, b) => {
            if (a.firstRentLevel < b.firstRentLevel) 
                return this.sortValueAscending ? -1 : 1;
            if (a.firstRentLevel > b.firstRentLevel)
                return this.sortValueAscending ? 1 : -1;
            return 0;
        });
        this.sortValueAscending = !this.sortValueAscending;
    }

    sortMarketTypesByName() {
        this.marketTypes.sort((a, b) => this.sortNameAscending ? a.text.localeCompare(b.text) : b.text.localeCompare(a.text));
        this.sortNameAscending = !this.sortNameAscending;
    }

    getMarketTypeTextById(marketTypeId: number) {
        const marketType = this.marketTypes.find(x => x.id === marketTypeId);
        return marketType ? marketType.text : "";
    }

    async updateMarketTypes() {
        if (!this.contract) return;
        
        try {
            const marketRents = await dataService.getMarketRent(this.contract.transaction.propertyVersionId).then(x => x.data);
            this.marketTypes = marketRents.map(x => x.marketTypeId).filter((v, i, a) => a.indexOf(v) === i).map(x => {
                const rentLevels = marketRents.filter(r => r.marketTypeId === x).map(x => x.rentLevel);
                return { id: x, firstRentLevel: rentLevels[0], text: this.getMarketTypeText(x, rentLevels) };
            });
            this.sortMarketTypesByValue();
        } catch (error) {
            return Promise.reject(new Error(""));
        }
    }

    getMarketTypeText(marketTypeId: number, rentLevels: number[]): string {
        const marketType = this.translateBaseData(marketTypeId, this.baseDataType.MarketTypes);
        const rentLevel = rentLevels.length > 1 ? this.translate("Multiple").toLowerCase() : rentLevels[0] + "/m²";
        return `${marketType} (${rentLevel})`;
    }

    getLeaseAreaErrors(id: number) {
        const areaErrors = this.getError(this.complexErrors, "", id);
        const contractError = this.getError(this.complexErrors, "contract.leaseAreas", this.contract.id);
        const combinedErrors = areaErrors.concat(contractError);
        return combinedErrors;
    }

    loadLeaseAreas(): AxiosPromise<LeaseArea[]> {
        return Promise.resolve(<AxiosResponse>{ data: this.contract ? this.contract.leaseAreas : [] });
    }

    newLeaseArea(): LeaseArea {
        let premisesTypeId = this.premisesTypes[0].id;
        if (!this.contract.leaseAreas.some(x => x.premisesTypeId === this.contract.mainPremisesTypeId)) {
            premisesTypeId = this.contract.mainPremisesTypeId;
        }

        return <LeaseArea>{
            id: this.getNewId(this.leaseAreaGrid.entities),
            contractId: this.contract.id,
            numberOf: 1,
            contractArea: 0,
            value: null,
            premisesTypeId: premisesTypeId,
            marketTypeId: null
        };
    }

    premisesTypeHasArea(leaseArea: LeaseArea) {
        if (!leaseArea || !this.premisesTypes) return true;
        let selectedPremisesType = this.premisesTypes.find(x => x.id == leaseArea.premisesTypeId);
        if (!selectedPremisesType) return true;
        return selectedPremisesType.hasArea;
    }

    leaseAreaTableFormat(column: any, cellValue: any) {
        switch (column.property) {
            case "premisesTypeId":
                return this.translateBaseData(cellValue, this.baseDataType.PremisesTypes);
            case "marketTypeId":
                return this.translateBaseData(cellValue, this.baseDataType.MarketTypes);
        }
    }

    canAdd(): boolean {
        return !this.versionLocked();
    }
}