import BaseComponent from '@/components/base-component';
import { Component, Watch, Prop } from "vue-property-decorator";
import LoanDetailsComponent from '@/components/loan/loan-details/loan-details';
import { mapGetters } from 'vuex';
import { PropertyVersion, LoanListItem, Loan, Transaction, Amortization, Interest } from '@/models';
import dataService from '@/services/data-service';
import { AmortizationTypeEnum, InterestTypeEnum } from '@/constants/fia-constants';
import { ValidationError } from '@/models/interfaces/ValidationError';
import validationService from '@/services/validation-service';

@Component({
    components: {
        LoanDetailsComponent
    },
    computed: mapGetters({
        propertyVersion: "getPropertyVersion"
    })
})
export default class LoanListComponent extends BaseComponent{
    propertyVersion!: PropertyVersion;
    currentPropertyVersion: number = -1;

    complexErrors: ValidationError[] = [];

    loans: LoanListItem[] = [];
    loan: Loan | null = null;
    loanCloned: string = "";
    selectedLoan: LoanListItem | null = null;
    loading: boolean = false;
    saving: boolean = false;
    loanRowClicks: number = 0;

    @Prop()
    reload!: boolean;

    @Watch("reload")
    reloadWatcher() {
        if (this.reload == true) {
            if (this.currentPropertyVersion != this.propertyVersion!.id) 
                this.refreshLoanList();
            (<any> this).$refs.loanTable.doLayout();
        }
    }

    @Watch("propertyVersion")
    async versionWatcher() {
        if (this.currentPropertyVersion == this.propertyVersion!.id) return;
        if(this.propertyVersion && this.reload == true)
            await this.refreshLoanList();
    }

    @Watch("loan", { deep: true, immediate: true })
    loanValidationWatcher() {
        validationService.validateLoan(this.loan, this.complexErrors);
    }

    async refreshLoanList() {
        this.loading = true;
        this.loans = await dataService.getLoanList(this.propertyVersion.id).then(x => x.data);
        this.loan = null;
        this.loanCloned = "";
        this.selectedLoan = null;
        this.loading = false;
        this.currentPropertyVersion = this.propertyVersion!.id;
    }

    async loanRowClick(item: LoanListItem) {
        if (!this.selectedLoan || item.loanId !== this.selectedLoan.loanId) {
            if (this.loading) {
                return;
            }
        }
        if (item != this.selectedLoan) {
            this.selectedLoan = item;
            await this.loadLoan(item.loanId);
        }
    }

    async loadLoan(id: number) {
        try {
            this.loading = true;
            const loan = await dataService.getLoan(id).then(x => x.data);
            this.setLoan(loan);
        } catch (error) {
            return Promise.reject(new Error(""));
        } finally {
            this.loading = false;
        }
    }

    setLoan(loan: Loan | null) {
        this.loan = loan;
        this.loanCloned = loan ? JSON.stringify(this.loan) : "";
    }

    add() {
        this.loan = {
            id: 0,
            loanName: this.translate('Loan'),
            startDate: this.propertyVersion.calculationStart,
            endDate: this.propertyVersion.calculationEnd,
            loanAmount: 0,
            startAmount: 0,
            endAmount: 0,
            loanTypeOfPaymentId: 86,
            amortizationTypeOfPaymentId: 126,
            amortization: <Amortization>{
                id: 0,
                loanId: 0,
                paymentFrequency: 3,
                inAdvance: false,
                amortizationTypeId: AmortizationTypeEnum.Fixed,
                amortizationAmounts: [],
                transaction: <Transaction>{ 
                    propertyVersionId: this.propertyVersion.id 
                }
            },
            interest: <Interest>{
                id: 0,
                loanId: 0,
                paymentFrequency: 3,
                inAdvance: false,
                interestTypeId: InterestTypeEnum.Bound,
                margin: 0,
                startInterest: 0,
                developmentTypeId: 2,
                prognosisParameterId: null,
                interestRates: [],
                transaction: <Transaction>{ 
                    propertyVersionId: this.propertyVersion.id 
                }
            },
            existingLoan: false,
        }
    }

    remove() {
        this.confirm("ConfirmDeleteRow").then(async () => {
            await dataService.deleteLoan(this.selectedLoan!.loanId);
            await this.refreshLoanList();
        }).catch(() => {});
    }

    async save() {
        if (this.loan != null) {
            this.saving = true;
            switch (this.loan.amortization.amortizationTypeId) {
                case AmortizationTypeEnum.Fixed:
                    this.loan.amortization.amortizationAmounts.forEach((amount, index) => {
                        if (amount.percentageOfLoanAmount != null)
                            this.loan!.amortization.amortizationAmounts.splice(index, 1);
                    });
                    break;
                case AmortizationTypeEnum.Percentual:
                    this.loan.amortization.amortizationAmounts.forEach((amount, index) => {
                        if (amount.yearlyAmount != null)
                            this.loan!.amortization.amortizationAmounts.splice(index, 1);
                    });
                    break;
            }

            switch (this.loan.interest.interestTypeId) {
                case InterestTypeEnum.Bound: // 4713
                    this.loan.interest.margin = this.loan.loanAmount;
                    break;
                case InterestTypeEnum.Dependent: // 4712
                    this.loan.interest.interestRates = [];
                    this.loan.startAmount = this.loan.loanAmount;
                    break;
                case InterestTypeEnum.Fixed: // 4711
                    this.loan.interest.margin  = 0;
                    this.loan.interest.developmentTypeId = null;
                    this.loan.startAmount = this.loan.loanAmount;
                    break;
            }
            
            let loan = await dataService.saveLoan(this.loan).then(x => x.data);

            if (this.loan.id === 0) {
                this.loan.id = loan.id;
            }

            await this.refreshLoanList();
            this.saving = false;
            if (this.selectedLoan) {
                const currentLoanId = this.selectedLoan.loanId;
                await this.loadLoan(currentLoanId);
            }
        }
    }

    async undo(): Promise<void> {
        if (this.loanIsNew()) {
            this.setLoan(null);
            return;
        }
        if (this.loanIsDirty()) {
            this.confirm('ConfirmUndoChanges').then(async () => {
                await this.loadLoan(this.loan!.id);
            }).catch(() => { });
        }
    }

    NoOfMonthsToTimesPerYearConverter(row: any, column: any, value: any) {
        return 12 / Number(value);
    }

    loanIsActive(): boolean {
        return this.loan !== null;
    }
    
    loanIsNew(): boolean {
        return this.loan !== null && this.loan.id === 0;
    }

    loanIsValid(): boolean {
        return this.complexErrors.length === 0;
    }
    
    loanIsDirty(): boolean {
        return this.loan !== null && JSON.stringify(this.loan) !== this.loanCloned;
    }

    canDelete(): boolean {
        return this.loanIsActive() && !this.loanIsNew() && !this.versionLocked();
    } 

    canSave(): boolean {
        return this.loanIsActive() && !this.versionLocked() && this.loanIsValid();
    } 

    canAdd() {
        return this.propertyVersion !== null && !this.versionLocked() && !this.loanIsNew();
    }

    canUndo() {
        return this.loanIsActive() && !this.versionLocked();
    }

}