import BaseComponent from '@/components/base-component';
import Component from 'vue-class-component';
import dataService from '@/services/data-service';
import { JobStatus, JobType } from '@/constants/fia-constants';
import { Job } from '@/models';
import { SignalRMessage } from "@/models/interfaces/signalR/SignalRMessage";
import { MessageType } from "@/models/interfaces/signalR/MessageType";
import { ReportResponse } from "@/models/interfaces/response/ReportResponse";
import { mapGetters } from 'vuex';

@Component({
    computed: mapGetters({
        connectionId: "getConnectionId",
    })
})
export default class NotificationsComponent extends BaseComponent {
    connectionId!: string;
    activeJobs: Job[] = [];
    maxItems: number = 1000;
    //  [
    //     <Job>{ id: "1", created: this.newIsoDateMonth(), started: this.newIsoDateMonth(), finished: "", databaseId: 13, description: "Stockholm, Gurkan 1", databaseName: "Fia sweden", progress: 66, type: JobType.ContractImport, status: JobStatus.InProgress },
    //     <Job>{ id: "2", created: this.newIsoDateMonth(), started: this.newIsoDateMonth(), finished: this.newIsoDateMonth(), databaseId: 13, description: "Portfölj test1", databaseName: "Fia sweden", progress: 100, type: JobType.CopyPortfolio, status: JobStatus.Finished },
    //     <Job>{ id: "3", created: this.newIsoDateMonth(), started: this.newIsoDateMonth(), finished: "", databaseId: 13, description: "Stockholm, Gurkan 3", databaseName: "Fia sweden", progress: 20, type: JobType.ContractImport, status: JobStatus.Failed },
    // ];
    updating: boolean = false;

    // Enums for use in the view
    jobStatus = JobStatus;

    // Non-reactive properties
    signalRMsgHandler: any;

    async mounted() {
        this.signalRMsgHandler = (message: SignalRMessage) => this.onSignalRMessage(message);
        this.$signalrHub.$on("HubMessage", this.signalRMsgHandler);

        await this.updateActivities();
    }

    beforeDestroy() {
        this.$signalrHub.$off("HubMessage", this.signalRMsgHandler);
    }

    onSignalRMessage(message: SignalRMessage): void {
        if (!this.validateMessage(message)) return;
        const job = JSON.parse(message.data) as Job;
        switch (message.type) {
            case MessageType.JobCreated:
                // Add the new job to the top of the list
                this.activeJobs.splice(0, 0, job);

                // If the list item count exceeds the limit, remove the job at the very bottom
                if (this.activeJobs.length > this.maxItems) {
                    this.activeJobs.splice(this.activeJobs.length - 1, 1);
                }
                break;
            case MessageType.JobFinished:
                let finishedJob = this.findJob(job.id);
                if (finishedJob) {
                    finishedJob.progress = 100;
                    finishedJob.status = JobStatus.Finished
                }
                this.jobFinished(job); 
                break;
            case MessageType.JobFailed:
                let failedJob = this.findJob(job.id);
                if (failedJob) {
                    failedJob.status = JobStatus.Failed;
                }
                break;
            case MessageType.JobStarted:
            case MessageType.JobProgressChanged:
                const jobInProgress = this.findJob(job.id);
                if (jobInProgress) {
                    jobInProgress.status = job.status;
                    jobInProgress.progress = job.progress;
                }
                break;
            case MessageType.JobCanceled:
                const canceledJob = this.findJob(job.id);
                if (canceledJob) {
                    canceledJob.status = JobStatus.Canceled;
                }
                break;
            default:
                break;
        }
    }

    findJob(id: string): Job | undefined {
        return this.activeJobs.find(x => x.id == id);
    }

    validateMessage(message: SignalRMessage) {
        return message && message.data &&
            [MessageType.JobCreated, MessageType.JobStarted, MessageType.JobFinished, MessageType.JobFailed, MessageType.JobProgressChanged, MessageType.JobCanceled].includes(message.type);
    }

    async updateActivities() {
        try {
            this.updating = true;
            this.activeJobs = await dataService.getJobQueue(this.maxItems).then(x => x.data);
        } catch (error) {
            return Promise.reject(new Error(""));
        } finally {
            this.updating = false;
        }
    }

    jobFinished(job: Job) {
        switch (job.type) {
            case JobType.ExportPortfolio:
                if (job.issuedByConnectionId == this.connectionId) {
                    this.openAttachment(job);
                }
                break;
            case JobType.ValuationReport:
            case JobType.CalculationReport:
            case JobType.Ipd6NotReadyReport:
            case JobType.Ipd4Report:
            case JobType.Ipd6Report:
            case JobType.KeyRatiosReport:
            case JobType.KeyRatiosShortReport:
            case JobType.PropertyAddressListReport:
            case JobType.PortfolioDetailsReport:
            case JobType.ContractsToExcelReport:
            case JobType.SensitivityAnalysisReport:
            case JobType.KeyRatiosExtendedReport:
                this.$message({
                    message: this.translate("JobReady", { type: this.getJobTypeName(job.type), report: job.description }),
                    type: 'info'
                });
                if (job.issuedByConnectionId == this.connectionId) {
                    this.openReport(job);
                }
                break;
        }
    }

    jobsInProgress(): number {
        return this.activeJobs.filter(x => x.status === JobStatus.Pending || x.status === JobStatus.InProgress).length;
    }

    getJobTypeName(type: JobType): string {
        switch (type) {
            case JobType.CopyPortfolio:
                return this.translate("JobType_CopyPortfolio");
            case JobType.ImportContracts:
                return this.translate("JobType_ContractImport");
            case JobType.ExportPortfolio:
                return this.translate("JobType_ExportPortfolio");
            case JobType.ImportPortfolio:
                return this.translate("JobType_ImportPortfolio");
            case JobType.Calculation:
                return this.translate("JobType_Calculation");
            case JobType.CalculationDetailed:
                return this.translate("JobType_CalculationDetailed");
            case JobType.CalculationPortfolio:
                return this.translate("JobType_CalculationPortfolio");
            case JobType.ValuationReport:
                return this.translate("ReportTypeValuation");
            case JobType.CalculationReport:
                return this.translate("ReportTypeCalculation");
            case JobType.Ipd6NotReadyReport:
                return this.translate("ReportTypeIpd6NotReady");
            case JobType.Ipd4Report:
                return this.translate("ReportTypeIpd4");
            case JobType.Ipd6Report:
                return this.translate("ReportTypeIpd6");
            case JobType.KeyRatiosReport:
                return this.translate("ReportTypeKeyRatios");
            case JobType.KeyRatiosShortReport:
            case JobType.PropertyAddressListReport:
                return this.translate("ReportTypePropertyAddressList");
            case JobType.PortfolioDetailsReport:
                return this.translate("ReportTypePortfolioDetails");
            case JobType.ContractsToExcelReport:
                return this.translate("ReportTypeContractsToExcel");
            case JobType.SensitivityAnalysisReport:
                return this.translate("ReportTypeSensitivityAnalysis");
            case JobType.KeyRatiosExtendedReport:
                return this.translate("ReportTypeKeyRatiosExtended");
            default:
                return "?";
        }
    }

    getJobStatusText(status: JobStatus): string {
        switch (status) {
            case JobStatus.Pending:
                return this.translate("JobStatus_Pending");
            case JobStatus.InProgress:
                return this.translate("JobStatus_InProgress");
            case JobStatus.Canceled:
                return this.translate("JobStatus_Canceled");
            case JobStatus.Failed:
                return this.translate("JobStatus_Failed");
            case JobStatus.Finished:
                return this.translate("JobStatus_Finished");
        }
    }

    async showError(id: string) {
        try {
            let error = await dataService.getJobResult(id).then(x => x.data);
            this.$notify.error({
                title: this.translate("Error"),
                message: error == "Error" ? this.translate("AnUnexpectedErrorOccurred") : error
            });
        } catch (error) {
            return Promise.reject(new Error(""));
        }
    }

    private openReport(job: Job) {
        const reportResponse: ReportResponse = JSON.parse(job.data);

        dataService.getReportFile(job.id)
            .then(response => {
                var bin = atob(response.data);
                var ab = this.s2ab(bin);
                var blob = new Blob([ab], {}); // UTAN MIME-KOD
                // var blob = new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' }); // MS EXCEL
                // var blob = new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document;' }); // MS WORD
                let url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                if (reportResponse.fileName == "[qid].xlsx") {
                    let type: string;
                    type = this.getJobTypeName(job.type);
                    reportResponse.fileName = job.description + ", " + type + ".xlsx";
                    a.download = reportResponse.fileName;
                }
                else {
                    a.download = reportResponse.fileName.replace("[qid]", job.id.toString());
                }

                a.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));
                a.remove();
                setTimeout(() => window.URL.revokeObjectURL(url), 100);
            });
    }

    private openAttachment(job: Job) {
        dataService.getExportedPortfolioFile(job.id)
            .then(response => {
                var bin = atob(response.data);
                var ab = this.s2ab(bin);
                var blob = new Blob([ab], { type: "octet/stream" });
                let url = window.URL.createObjectURL(blob);
                var a = document.createElement('a')
                a.href = url
                let fileName = job.description.replace(/[/\\?%*:|"<>]/g, '-');
                a.download = fileName + ".zip";
                // a.click()
                a.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));
                a.remove()
                setTimeout(() => window.URL.revokeObjectURL(url), 100)
            });
    }

    private s2ab(s: any) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        // tslint:disable-next-line:no-bitwise
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }

    public cancelJob(id: string): void {
        this.$confirm('Vill du avbryta generering', {
            confirmButtonText: this.translate('Yes'),
            cancelButtonText: this.translate('No'),
            type: 'warning'
        })
        .then(() => {
            dataService.cancelJob(id);
        })
        .catch(() => {});
    }
}