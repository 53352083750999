import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import BaseComponent from '@/components/base-component';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import { CalculationResult, Currency, EstimatedMarketValue, Portfolio, PropertyVersion, User } from '@/models';
import { ValidationError } from '@/models/interfaces/ValidationError';
import { CalculationState } from '@/models/interfaces/CalculationState';
import { VersionKpis } from '@/models/interfaces/VersionKpis';
import { formatDecimalNumber } from '@/helpers/numbers';
import { SaveCalculationResultsRequest } from '@/models/interfaces/request/SaveCalculationResultsRequest';
import dataService from '@/services/data-service';
import { PropertyVersionSlim } from '@/models/interfaces/PropertyVersionSlim';


@Component({
    components: {
        YearMonthPickerComponent,
        InputNumericComponent,
    },
    computed: mapGetters({
        propertyVersion: "getPropertyVersion",
        user: "getUser",
        currencies: "getCurrencies"
    })
})
export default class CalculationResultComponent extends BaseComponent {
    propertyVersion!: PropertyVersion;
    user!: User;
    currencies!: Currency[];

    savingCalculationResult: boolean = false;
    complexErrors: ValidationError[] = [];
    
    costDeduction: number = 0;
    remains: number = 0;
    correction: number = 0;
    marketValuePerM2: number = 0;
    suggestedMarketValue: boolean = false;
    suggestedMarketValueInterval: boolean = false;
    suggestedMarketValueBondRate: boolean = false;
    
    propertyVersionList: PropertyVersionSlim[] = []; 
    selectedComparisonPropertyVersionFull!: PropertyVersion;
    calculationStateComparison!: CalculationState;
    
    localSelectedPropertyVersion: number | null = null;

    @Prop()
    selectedPropertyVersion!: PropertyVersionSlim;
    reportData: VersionKpis | null = null;
    versionReportData: VersionKpis | null = null;
    @Prop()
    calculationState!: CalculationState;
    @Prop()
    isVersion!: boolean;
    @Prop()
    portfolio!: Portfolio;
    @Prop()
    isLocked!: boolean;
    
    @Watch('propertyVersion') async propertyVersionWatcher() {
        this.setState();
        await this.getVersions(this.propertyVersion.propertyId);
        const index = this.propertyVersionList.findIndex(item => item.id === this.propertyVersion.id);

        if (index !== -1 && index + 1 < this.propertyVersionList.length) {
            this.localSelectedPropertyVersion = this.propertyVersionList[index + 1].id;
        } else {
            this.localSelectedPropertyVersion = this.propertyVersionList[0].id;
        }
        await this.localSelectedPropertyVersionChanged(this.localSelectedPropertyVersion, 0)
    }
    @Watch('portfolio') portfolioWatcher() {
        this.setState();
    }

    async mounted() { //kolla vad som händer i denna när man mountar igen, finns alla värden?
        if(!this.portfolio){
            await this.getVersions(this.propertyVersion.propertyId);
            const index = this.propertyVersionList.findIndex(item => item.id === this.propertyVersion.id);
    
            if (index !== -1 && index + 1 < this.propertyVersionList.length) {
                this.localSelectedPropertyVersion = this.propertyVersionList[index + 1].id;
            } else {
                this.localSelectedPropertyVersion = this.propertyVersionList[0].id;
            }
            await this.localSelectedPropertyVersionChanged(this.localSelectedPropertyVersion, 0)
        }
        this.setState();
    }

    async localSelectedPropertyVersionChanged(newVal: number, oldVal: number) {
        if (newVal && newVal !== oldVal) {
            this.$emit('update:selectedPropertyVersion', newVal);
            await this.refreshVersionData(newVal)
        }
    }

    isCalculated(): boolean {
        return this.calculationState !== null && this.calculationState.calculationResult !== null && this.calculationState.calculationResult.id !== 0;
    }

    intervalValues() {
        return [1000, 10000, 100000, 1000000, 10000000];
    }

    refreshResults() {
        if (this.calculationState && this.calculationState.calculationResult && this.calculationState.estimatedMarketValue) {
            this.costDeduction = Math.round(this.calculationState.costDeductionPercentage * this.calculationState.estimatedMarketValue.marketValue);
            this.remains = Math.round(this.calculationState.calculationResult.presentValueSum - this.costDeduction);
            this.correction = Math.round(this.calculationState.estimatedMarketValue.marketValue - this.remains - this.calculationState.buildingRightsValue);
            this.marketValuePerM2 = (this.calculationState.calculationResult.areaTotal === 0 || this.calculationState.calculationResult.areaTotal == null) ? 0 : Math.round(this.calculationState.estimatedMarketValue.marketValue / this.calculationState.calculationResult.areaTotal);
        }
    }

    async saveCalculationResult() {
        try {
            this.savingCalculationResult = true;
            let request: SaveCalculationResultsRequest = { estimatedMarketValue: <EstimatedMarketValue>this.calculationState!.estimatedMarketValue, costDeductionPercentage: this.calculationState!.costDeductionPercentage };
            let estimatedMarketValue = await dataService.saveCalculationResults(request).then(x => x.data);
            if (this.isVersion) {
                this.propertyVersion.estimatedMarketValue = estimatedMarketValue;
                this.propertyVersion.property.viewCostDeductionPercentage = this.calculationState!.costDeductionPercentage;
            }
            else {
                this.portfolio!.estimatedMarketValue = estimatedMarketValue;
            }
            this.setState();
        } catch (error) {
            return Promise.reject(new Error("savecalc"));
        } finally {
            this.savingCalculationResult = false;
        }
    }

    async deleteCalculationResult() {
        try {
            this.savingCalculationResult = true;
            await dataService.deleteCalculationResults(this.calculationState!.estimatedMarketValue!);
            if (this.isVersion && this.propertyVersion.estimatedMarketValue) {
                this.propertyVersion.estimatedMarketValue = null;
            } else if (!this.isVersion && this.portfolio && this.portfolio.estimatedMarketValue) {
                this.portfolio.estimatedMarketValue = null;
            }
            
            this.setState();
        } catch (error) {
            return Promise.reject(new Error("savecalc"));
        } finally {
            this.savingCalculationResult = false;
        }
    }

    async handleIntervalCommand(val: number) {
        var estMarketValue = this.calculationState ? this.calculationState.estimatedMarketValue : null;
        if (!estMarketValue) return;
        estMarketValue.interval = val;
        await dataService.saveInterval(estMarketValue).then((x) => {
            // Reset other properties than the interval before setting state from backend entity
            // Only the interval should be affected by this operation
            x.data.bondRate = estMarketValue!.bondRate;
            x.data.marketValue = estMarketValue!.marketValue;

            if (this.isVersion) {
                this.propertyVersion.estimatedMarketValue = x.data;
            } else {
                this.portfolio!.estimatedMarketValue = x.data;
            }
            this.setState();
        });
    }

    canClearResult(): boolean {
        let hasSetEstimatedMarketValue = false;
        if (this.isVersion && this.propertyVersion.estimatedMarketValue && this.propertyVersion.estimatedMarketValue.id > 0) {
            hasSetEstimatedMarketValue = true;
        } else if (!this.isVersion && this.portfolio && this.portfolio.estimatedMarketValue && this.portfolio.estimatedMarketValue.id > 0) {
            hasSetEstimatedMarketValue = true;
        }
        return this.isValid() && this.isCalculated() && hasSetEstimatedMarketValue;
    }

    formatIntervalNumber(val: number) {
        const locale = this.$i18n.locale()!;
        const minDecimals = 0;
        const decimals = 0;
        const percentage = false;
        return formatDecimalNumber(val, locale, decimals, minDecimals, percentage);
    }

    formatPercentage(val:number) {
        const locale = this.$i18n.locale()!;
        const minDecimals = 2;
        const decimals = 2;
        const percentage = false;
        return formatDecimalNumber(val, locale, decimals, minDecimals, percentage);
    }

    canSaveResult(): boolean {
        return this.isValid() && this.isCalculated();
    }

    isValid(): boolean {
        return this.complexErrors.length === 0;
    }

    async refreshVersion() {
        if(this.portfolio && !this.isVersion) return;
    
        let id = this.isVersion ? this.propertyVersion.id : 0;
        await dataService.getVersionKpis(id)
            .then(x => {
                this.reportData = x.data;
                
            })
            .catch(x => 
                {
                    this.reportData = null
                })
            ;
        if (this.reportData === null)  return;
    };

    async refreshVersionData(id: number) {
        if(this.portfolio && !this.isVersion) return;
    
        await dataService.getVersionKpis(id)
            .then(x => {
                this.versionReportData = x.data;
            })
            .catch(x => 
                {
                    this.versionReportData = null
                })
            ;
        if (this.versionReportData === null)  return;
    };
    
    setState() {
        if (!this.calculationState) return;
        if (this.isVersion && !this.propertyVersion) return;
        if (!this.isVersion && !this.portfolio) return;

        this.refreshVersion();
        if(this.reportData != null)
        {
            const yieldYear1 = this.reportData.yieldYear1
            const marketValue = this.reportData.marketValue
        }
        // Get calculation results
        let calculationResult: CalculationResult = (this.isVersion ? this.propertyVersion.calculationResult : this.portfolio!.calculationResult) ||
        {
            id: 0,
            presentValueSum: 0,
            areaTotal: 0,
            calculationDate: null,
            restValue: 0,
            irr: 0,
            irrLeveraged: 0,
            irrRestvalueShare: 0,
            irrRestvalueShareLeveraged: 0,
            presentValueRestValue: 0
        };
        // Get est. market value
        let estimatedMarketValue = this.isVersion ? this.propertyVersion.estimatedMarketValue : this.portfolio!.estimatedMarketValue;
        estimatedMarketValue = {
            id: estimatedMarketValue ? estimatedMarketValue.id : 0,
            marketValue: estimatedMarketValue ? estimatedMarketValue.marketValue : -1,
            interval: estimatedMarketValue ? estimatedMarketValue.interval : -1,
            bondRate: estimatedMarketValue ? estimatedMarketValue.bondRate : -1,
            propertyVersionId: this.isVersion ? this.propertyVersion.id : null,
            portfolioId: this.isVersion ? null : this.portfolio!.id
        }

        // Calculate est. market value default if there are no saved values
        const presentValueSum = calculationResult.presentValueSum;
        const costDeduction = presentValueSum - (presentValueSum / (1 + this.calculationState.costDeductionPercentage));
        let marketValue = Math.round(presentValueSum - costDeduction + this.calculationState.buildingRightsValue);
        const userRoundingSetting = this.user.userSettings.calculationSettings.rounding;
        let interval = estimatedMarketValue.interval;

        this.suggestedMarketValue = estimatedMarketValue.marketValue == null || estimatedMarketValue.marketValue == -1;
        this.suggestedMarketValueInterval = estimatedMarketValue.interval == null || estimatedMarketValue.interval == -1;
        this.suggestedMarketValueBondRate = estimatedMarketValue.bondRate == null || estimatedMarketValue.bondRate == -1;
        
        if (this.suggestedMarketValueInterval) {
            if (userRoundingSetting) {
                interval = userRoundingSetting;
            } else {
                let marketValueChars = marketValue.toString().length;
                if (marketValueChars < 2) marketValueChars = 2;
                interval = Math.pow(10, marketValueChars - 2);
            }
        }

        marketValue = Math.round(Math.round(marketValue / interval) * interval);
        
        if (estimatedMarketValue.id === 0) {
            estimatedMarketValue.marketValue = marketValue;
            estimatedMarketValue.interval = interval;
            estimatedMarketValue.bondRate = 5.3;
        } 
        if (this.suggestedMarketValue) {
            estimatedMarketValue.marketValue = marketValue;
        }
        if (this.suggestedMarketValueInterval) {
            estimatedMarketValue.interval = interval;
        }
        if (this.suggestedMarketValueBondRate) {
            estimatedMarketValue.bondRate = 5.3;
        }
        
        // Cost deduction & building rights value
        if (this.isVersion && calculationResult && calculationResult.id !== 0) {
            this.calculationState.costDeductionPercentage = this.propertyVersion.property.viewCostDeductionPercentage;
            this.calculationState.buildingRightsValue = this.propertyVersion.property.viewBuildingRightsValue;
        } else {
            this.calculationState.costDeductionPercentage = 0;
            this.calculationState.buildingRightsValue = 0;
        }

        // Set calculation state
        this.calculationState.calculationResult = calculationResult;
        this.calculationState.estimatedMarketValue = estimatedMarketValue;

        this.refreshResults();
    }

    getCurrencySqm(): string {
        const currency = this.currencies.find(x => x.id === (this.calculationState.currencyId || this.propertyVersion.currencyId));
        return currency ? currency.postPerM2 : "?";
    }
    getCurrency(): string {
        const currency = this.currencies.find(x => x.id === (this.calculationState.currencyId || this.propertyVersion.currencyId));
        return currency ? currency.postUnit : "?";
    }



   async getVersions(propertyId: number) {
        try {
            this.propertyVersionList = (await dataService.getVersionsByProperty(propertyId).then(x => x.data)).sort((a, b) => b.id - a.id);
        } catch (error) {
            return Promise.reject(new Error(""));
        }
    }

    async fetchPropertyVersionDetails(versionId: number) {
        try {
            this.selectedComparisonPropertyVersionFull = await dataService.getPropertyVersion(versionId).then(x => x.data);
        } catch (error) {
            console.error("Failed to fetch property version details:", error);
        } finally {
        }
    }
}
