import Vue from "vue";
import moment, { Moment } from "moment";
import { Component } from "vue-property-decorator";
import { MessageType } from "element-ui/types/message";
import { MessageBoxData } from "element-ui/types/message-box";
import { ValidationError } from "@/models/interfaces/ValidationError";
import { PaymentStream, PropertyVersion } from "@/models";
import { PaymentStreamType, BaseDataType, DateFormat, YearMonthFormat, YearMonthDayFormat } from "@/constants/fia-constants";
import { TransEntity } from "@/models/interfaces/TransEntity";
import { Entity } from '@/models/interfaces/Entity';
import { isNumeric } from '@/helpers/numbers';
import { ValidationRule } from "@/models/interfaces/ValidationRule";
import utils from "@/helpers/utils";

@Component({})
export default class BaseComponent extends Vue {
    baseDataType = BaseDataType;
    loadError: boolean = false;

    paymentFrequencies: number[] = [1, 2, 3, 4, 6, 12];

    public formatYearMonthTable(row: any, column: any, value: any): string {
        if (value)
            return moment(value).format(YearMonthFormat);
        return "";
    }

    public formatYearMonthDayTable(row: any, column: any, value: any): string {
        if (value)
            return moment(value).format(YearMonthDayFormat);
        return "";
    }

    public formatYear(value: any): string {
        return moment(value).format('YYYY');
    }

    public formatMonth(value: any): string {
        return value ? moment(value).format('M') : "";
    }

    public formatYearMonth(value: any): string {
        if (!value) return "";
        return moment(value).format(YearMonthFormat);
    }

    public yearMonthInt(value: any): number {
        if (!value) return 0;
        return parseInt(moment(value).format("YYYYMM"), 10);
    }

    public formatYearMonthDay(value: any): string {
        if (!value) return "";
        return moment(value).format(YearMonthDayFormat);
    }

    public formatIsoDate(date: string | Date): string {
        return moment(date).format(DateFormat);
    }

    public newIsoDateMonth(endOfMonth: boolean = false, month?: number) {
        let date = moment(new Date()).startOf("day");

        if (month) {
            date.set('month', month - 1);
        }

        date = endOfMonth ? date.endOf("month") : date.startOf("month");
        return date.format(DateFormat);
    }

    public utcToLocal(date: any): moment.Moment {
        return moment.utc(date).local();
    }

    public formatYearMonthHourMinute(value: any): string {
        return moment(value).format('YYYY-MM-DD, HH:mm');
    }

    public formatBoolean(value: boolean | null): string {
        return value == null ? "" : this.translate(value ? 'Yes' : 'No');
    }

    public formatBooleanTable(row: any, column: any, value: any): string {
        if (value == null)
            return "";
        else if (value)
            return this.translate('Yes');
        return this.translate('No');
    }

    public translate(key: string, options?: any): string {
        return this.$i18n.translate(key, options) || "";
    }

    public confirm(msgKey: string, titleKey: string = "Confirm", confirmKey: string = "OK", cancelKey: string = "Cancel", type: MessageType = "info", msgArgs: string[] = []): Promise<MessageBoxData> {
        let msg = this.translate(msgKey);
        
        let i = 0
        for(let a of msgArgs) {
            msg = msg.replace("{" + i.toString() + "}", a);
            i++;
        }
        
        return this.$confirm(msg, this.translate(titleKey), {
            confirmButtonText: this.translate(confirmKey),
            cancelButtonText: this.translate(cancelKey),
            type: type
        })
    }

    formatNumber(value: any, digits: number, maximumFractionDigits: number | undefined = undefined): string {
        if (!isNumeric(value)) return value;
        if (value || value === 0) {
            return new Intl.NumberFormat(this.$i18n.locale()!, { style: 'decimal', minimumFractionDigits: digits, maximumFractionDigits: maximumFractionDigits }).format(value)
        }
        return "";
    }

    formatPercentage(value: any, digits: number, addSign: boolean = false) {
        if (!value && value !== 0) return "";

        if (!isNumeric(value)) return value;

        let sign = (addSign ? " %" : "");
        return this.formatNumber(value * 100, digits) + sign;
    }

    decimals(number: number, decimals: number, digits: number = 0, asNumeric: boolean = false) {
        if (asNumeric)
            return Math.round(number * (Math.pow(10, decimals))) / Math.pow(10, decimals);
        else
            return this.formatNumber(Math.round(number * (Math.pow(10, decimals))) / Math.pow(10, decimals), digits);
    }

    getError(errors: ValidationError[], keys: string | string[], refIds?: string | string[] | number | number[]): ValidationError[] {
        if (!errors) {
            return [];
        }
        let ref: string = "";

        if (refIds || refIds === 0) {
            if (Array.isArray(refIds)) {
                ref = refIds.join("|");
            }
            else {
                ref = refIds.toString()
            }
        }

        if (keys === "")
            return errors.filter(x => (ref === "" || ref === x.refId.toString()));

        if (!Array.isArray(keys))
            keys = [keys];

        return errors.filter(x => (<string[]>keys).findIndex(k => k === x.key) !== -1 && (ref === "" || ref === x.refId.toString()));
    }

    getErrors(errors: ValidationError[], key: string, refId?: any): ValidationError[] {
        if (!errors) return [];
        return errors.filter(x => (!key || key == '' || x.key == key) && (!refId || x.refId == refId.toString()));
    }

    getFirstError(complexErrors: ValidationError[], key: string, refId?: any): string | null {
        const errors = this.getError(complexErrors, key, refId);
        if (!complexErrors || complexErrors.length == 0) {
            return null;
        }
        if (!key) {
            return complexErrors[0].error;
        }
        return errors[0].error;
    }

    // getErrorText(errors: ValidationError[], key: string, refId?: any):string {
    //     let e = this.getError(errors, key, refId);
    //     return e ? e.error : "";
    // }

    isPercentageIncrementStream(stream: PaymentStream): boolean {
        return stream.typeOfPaymentStreamId == PaymentStreamType.BasicPercentageStream ||
            stream.typeOfPaymentStreamId == PaymentStreamType.IndexIncrementStream ||
            stream.typeOfPaymentStreamId == PaymentStreamType.AdvancedPercentageStream ||
            stream.typeOfPaymentStreamId == PaymentStreamType.AdvancedPercentageStreamAdvancedGui;
    }

    isPercentageIncrementStreamType(type: PaymentStreamType): boolean {
        return utils.isPercentageIncrementStreamType(type);
    }


    translateBaseData(value: any, type: BaseDataType) {
        if (value == null) return "";

        let entity = (<TransEntity[]>this.$store.getters["get" + BaseDataType[type]]).find(x => x.id === value);
        let result = entity ? entity.name : "#MISSING#";
        return result;
    }

    convertFrequency(value: any): number | null {
        return !isNumeric(value) ? null : 12 / value;
    }

    getNewId(entities: Entity[]) {
        let newItems = entities.filter(x => x.id < 1);
        let newId = newItems.length == 0 ? 0 : Math.min(...newItems.map(x => x.id)) - 1;
        return newId;
    }

    monthStart(date?: string | Date): string {
        if (!date) date = new Date();
        return moment(date).startOf("month").startOf("day").format(DateFormat);
    }

    monthEnd(date?: string | Date): string {
        if (!date) date = new Date();
        return moment(date).endOf("month").startOf("day").format(DateFormat);
    }

    versionLocked(): boolean {
        let version:PropertyVersion = this.$store.getters.getPropertyVersion;
        return version ? version.locked : false;
    }

    isValid(errors: ValidationError[], scope?: string | string[], includeWarnings = false): boolean {
        if (Array.isArray(scope)) {
            return !errors.some(x => (includeWarnings || x.rule !== ValidationRule.Warning) && scope.some(y => y === x.scope));
        } else {
            return !errors.some(x => (includeWarnings || x.rule !== ValidationRule.Warning) && (!scope || x.scope === scope));
        }
    }

    isNumeric(value: any): boolean {
        return isNumeric(value);
    }

    hasNoErrors(): boolean {
        return (<any>this).errors.items.length === 0;
    }
}