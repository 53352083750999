import BaseComponent from "@/components/base-component";
import InlineEdit from "@/helpers/inline-edit";
import { Component, Prop, Watch } from "vue-property-decorator";
import { VariableAmount, VariableAmountIncrementStream } from "@/models";
import { AxiosPromise, AxiosResponse } from "axios";
import { ValidationError } from "@/models/interfaces/ValidationError";
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import ValidationTableFeedbackComponent from '@/components/common/validation-table-feedback/validation-table-feedback';

@Component({
    components: {
        YearMonthPickerComponent,
        InputNumericComponent,
        ValidationTableFeedbackComponent
    }
})
export default class VariableAmountStreamComponent extends BaseComponent {
    @Prop()
    complexErrors!: ValidationError[];
    @Prop()
    stream!: VariableAmountIncrementStream;

    variableAmountsGrid: InlineEdit<VariableAmount>;

    constructor() {
        super();
        this.variableAmountsGrid = new InlineEdit<VariableAmount>(this, "variableAmountsTable", this.loadVariableAmounts, undefined, undefined, this.newVariableAmount, undefined, this.hasNoErrors);
        this.variableAmountsGrid.sync = true;
        this.variableAmountsGrid.useConfirmDeleteDialog = false;
    }

    @Watch("stream", { immediate: true })
    streamWatcher() {
        this.variableAmountsGrid!.reload();
    }

    loadVariableAmounts(): AxiosPromise<VariableAmount[]> {
        return Promise.resolve(<AxiosResponse>{ data: this.stream ? this.stream.variableAmounts : [] });
    }

    newVariableAmount(): VariableAmount {
        return <VariableAmount>{
            id: this.getNewId(this.variableAmountsGrid!.entities),
            startDate: this.stream.startDate,
            amount: 0
        };
    }
}