import { Component, Prop, Watch } from "vue-property-decorator";
import { AdvancedPercentageIncrementStream, PropertyVersion, SquaremeterPrice, PrognosisParameter, Development } from "@/models";
import { mapGetters } from "vuex";
import { DevelopmentType } from "@/models/DevelopmentType";
import { ValidationError } from "@/models/interfaces/ValidationError";
import BaseComponent from '@/components/base-component';
import { monthList } from '@/constants/fia-constants';
import { PremisesArea } from '@/models/PremisesArea';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import BaseValuesComponent from '@/components/payment-flows/base-values/base-values';

interface AreaPriceViewModel {
    premisesArea: PremisesArea;
    squaremeterPrice: SquaremeterPrice;
    sum: number;
}

@Component({
    components: {
        YearMonthPickerComponent,
        InputNumericComponent,
        BaseValuesComponent
    },
    computed: mapGetters({
        propertyVersion: "getPropertyVersion",
    })
})
export default class AdvancedPercentageStreamComponent extends BaseComponent {
    propertyVersion!: PropertyVersion;

    @Prop()
    complexErrors!: ValidationError[];
    @Prop()
    stream!: AdvancedPercentageIncrementStream;
    @Prop()
    extended!: boolean;
    @Prop()
    typeOfFlow!: string;
    @Prop()
    devsInContract!: Development[];
    @Prop()
    layout!: boolean;

    developmentList: DevelopmentType[] = [];
    prognosisList: PrognosisParameter[] = [];
    tempDevelopmentId: number | null = null;
    tempPrognosisParameterId: number | null = null;
    monthList = monthList;
    areaPrices: AreaPriceViewModel[] = [];
    startAmountUpdated: boolean = false;

    @Watch("stream", { immediate: true })
    streamWatcher() {
        if (!this.stream) return;
        this.initialize();        
    }

    @Watch("layout")
    layoutWatcher() {
        if (this.layout) {
            const areaPricesTable = (<any> this).$refs.areaPricesTable;
            if (areaPricesTable) {
                areaPricesTable.doLayout();
            }
        }
    }

    initialize() {
        this.developmentList = this.$store.getters.getActiveDevelopmentTypes([this.stream.developmentTypeId]);
        this.prognosisList = this.$store.getters.getActivePrognosisParameters([this.stream.prognosisParameterId]);

        this.startAmountUpdated = false;
        if (this.extended) {
            this.areaPrices = [];
            for (let pa of this.propertyVersion.premisesAreas) {
                let sqmPrice = this.stream.squaremeterPrices.find(x => x.premisesTypeId === pa.premisesTypeId &&
                    x.propertyVersionId === pa.propertyVersionId);
                if (!sqmPrice) {
                    sqmPrice = { id: this.getNewId(this.stream.squaremeterPrices), premisesTypeId: pa.premisesTypeId, paymentStreamId: this.stream.id, propertyVersionId: this.propertyVersion.id, price: 0 };
                    this.stream.squaremeterPrices.push(sqmPrice);
                }

                this.areaPrices.push({ premisesArea: pa, squaremeterPrice: sqmPrice, sum: (pa.area === 0 ? 1 : pa.area) * (sqmPrice.price || 0) });
            }

            this.updateStartAmount(true);
        }
    }

    get devOrProg(): string {
        return this.stream.developmentTypeId ? "dev" : "prog";
    }

    set devOrProg(value: string) {
        if (value === "dev") {
            this.tempPrognosisParameterId = this.stream.prognosisParameterId;
            this.stream.prognosisParameterId = null;
            this.stream.developmentTypeId = this.tempDevelopmentId ? this.tempDevelopmentId : this.developmentList[0].id;
        }
        else {
            this.tempDevelopmentId = this.stream.developmentTypeId;
            this.stream.developmentTypeId = null;
            this.stream.prognosisParameterId = this.tempPrognosisParameterId ? this.tempPrognosisParameterId : this.prognosisList[0].id;
        }
    }

    devEnabled(): boolean {
        return this.devOrProg === "dev";
    }

    updateStartAmount(startup: boolean = false) {
        let newStartAmount = Math.trunc(this.areaPrices.map(x => x.sum).reduce((a, b) => a + b, 0));
        if (this.stream.startAmount !== newStartAmount) {
            this.stream.startAmount = newStartAmount;
            if (startup)
                this.startAmountUpdated = true;
        }
    }

    priceChanged(item: AreaPriceViewModel) {
        item.sum = (item.premisesArea.area === 0 ? 1 : item.premisesArea.area) * (item.squaremeterPrice.price || 0);
        this.updateStartAmount();
    }

    sumChanged(item: AreaPriceViewModel) {
        item.squaremeterPrice.price = item.premisesArea.area === 0 ? item.sum : item.sum / item.premisesArea.area;
        this.updateStartAmount();
    }

    checkDevelopment(id: number | null): boolean {
        if (id == null) return false;

        if (this.devsInContract.map(x => x.developmentTypeId).indexOf(id) == -1)
            return true

        return false;
    }

    getSummaries(param: any) {
        let data: AreaPriceViewModel[] = param.data;
        let sum1 = 0;
        let sum2 = 0;
        let sum3 = 0;
        for(let i of data) {
            sum1 += i.premisesArea.area;
            sum3 += i.sum;
        }
        sum2 = sum3 / sum1;
        return ["", this.formatNumber(sum1, 1), this.formatNumber(sum2, 0), this.formatNumber(sum3, 0)];
    }
}