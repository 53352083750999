import { Style, RegularShape, Fill, Stroke, Text } from "@/imports/map-import";

export const propertyPinStyle = new Style({
    image: new RegularShape({
        fill: new Fill({ color: [255, 0, 0] }),
        stroke: new Stroke({ color: [0, 0, 0], width: 1 }),
        points: 8,
        radius: 3,
        angle: Math.PI / 4
    })
});

export const selectedPropertyPinStyle = new Style({
    image: new RegularShape({
        fill: new Fill({ color: [0, 0, 255] }),
        stroke: new Stroke({ color: [0, 0, 0], width: 1 }),
        points: 4,
        radius: 10,
        angle: Math.PI / 4
    })
});

export const userAreaStyle = new Style({
    stroke: new Stroke({
        color: 'rgba(0, 255, 0, 0.8)',
        width: 2
    }),
    fill: new Fill({ color: 'rgba(0, 0, 0, 0)' })
})
export const userAreaStyleSelected = new Style({
    stroke: new Stroke({
        color: 'rgba(0, 180, 0, 0.5)',
        width: 5
    }),
    fill: new Fill({ color: 'rgba(40, 255, 0, 0.2)' }),
    text: new Text({
        font: "18px sans-serif",
        fill: new Fill({ color: '#333' })
    })
})
export const userAreaStyleHovered = new Style({
    stroke: new Stroke({
        color: 'rgba(0, 180, 0, 0.5)',
        width: 3
    }),
    fill: new Fill({ color: 'rgba(0, 255, 0, 0.2)' }),
    text: new Text({
        font: "18px sans-serif",
        fill: new Fill({
            color: '#333'
        })
    })
})

export const publicUserAreaStyle = new Style({
    stroke: new Stroke({
        color: 'rgba(0, 200, 255, 0.8)',
        width: 2
    }),
    fill: new Fill({ color: 'rgba(0, 0, 0, 0)' })
})
export const publicUserAreaStyleSelected = new Style({
    stroke: new Stroke({
        color: 'rgba(0, 200, 180, 0.5)',
        width: 5
    }),
    fill: new Fill({ color: 'rgba(40, 0, 255, 0.2)' }),
    text: new Text({
        font: "18px sans-serif",
        fill: new Fill({ color: '#333' })
    })
})
export const publicUserAreaStyleHovered = new Style({
    stroke: new Stroke({
        color: 'rgba(0, 200, 180, 0.5)',
        width: 3
    }),
    fill: new Fill({ color: 'rgba(0, 0, 255, 0.2)' }),
    text: new Text({
        font: "18px sans-serif",
        fill: new Fill({
            color: '#333'
        })
    })
})
