import 'ol/ol.css';
import 'ol-contextmenu/dist/ol-contextmenu.css';
import 'ol-contextmenu/dist/ol-contextmenu.min.css';

import { format } from 'ol/coordinate';
import { Point } from 'ol/geom';
import { Component, Watch, Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import BaseMapComponent from '@/components/map-shared/base-map-component';
import { publicUserAreaStyle, userAreaStyle } from '@/components/map-shared/styles';
import {
    Circle, Cluster, Extent, Feature, Fill, Geom, Icon, IconAnchorUnits, LS, Map, Overlay, Proj,
    Stroke, Style, Text, TileJSON, TileLayer, Vector, VectorLayer, View, WFS
} from '@/imports/map-import';
import { PropertyVersion, User } from '@/models';
import { ContractsSummary, VersionInfo } from '@/models/interfaces/ContractsSummary';
import { UserDefArea } from '@/models/interfaces/UserDefArea';
import DataService from '@/services/data-service';
import { BusMessage, PropertyMapUpdatedMessage } from "@/models/messages/messages";
import { Size } from 'ol/size';

@Component({
    computed: mapGetters({
        propertyId: "getPropertyId",
        searchedPropertyIds: "getSearchedPropertyIds",
        propertyVersion: "getPropertyVersion",
        currentUser: "getUser"
    })
})
export default class MapComponent extends BaseMapComponent {
    propertyVersion!: PropertyVersion;
    searchedPropertyIds!: number[];
    propertyId!: number;
    currentUser!: User;

    tileLayers: TileLayer<any>[] = [];
    geoServerUrl: string = process.env.VUE_APP_FIAAPI_URL + "geo";
    hoverPropertyId: number = 0;
    propertyVectorSource!: Vector;
    visiblePropertyIds: string = "";
    lastPropertyId: number = 0;
    propertyInfo: ContractsSummary = <ContractsSummary>{ version: <VersionInfo>{}, contract: [], vacancy: [], costs: [] };
    lockInfoBox: boolean = false;
    posChangeActive: boolean = false;
    activePanel: string = "properties";
    propertyLayerEnabled: boolean = true;
    userAreaLayerEnabled: boolean = false;
    userAreaPublicLayerEnabled: boolean = false;
    areasEnabled: boolean = true;
    municipalitiesLayerEnabled: boolean = false;
    clusterSource!: Cluster;
    clusterLayer!: VectorLayer<any>;
    userDefAreas: UserDefArea[] = [];
    zoomLevel: number = 8;
    userAreaFeature!: Feature;
    infoBoxOverlay!: Overlay;
    infoBoxOverlayShowTimer!: any;
    infoBoxOverlayHideTimer!: any;
    propertyClusterVectorSource!: Vector;
    exportMapPinScale: number = 1.9;
    lastPinStyleScale: number | Size = 0;
    lastSelectedPinStyleScale: number | Size = 0;
    mapId: string = "";
    propertiesCentered = false;
    lastSelectedPropertyId: number | null = null;
    
    propertyPinStyle = new Style({
        image: new Icon({
            anchor: [0.5, 208],
            anchorXUnits: IconAnchorUnits.FRACTION,
            anchorYUnits: IconAnchorUnits.PIXELS,
            src: require('../../assets/map/pin.png'),
            scale: 0.15
          })
    });

    selectedPropertyPinStyle = new Style({
        image: new Icon({
            anchor: [0.5, 208],
            anchorXUnits: IconAnchorUnits.FRACTION,
            anchorYUnits: IconAnchorUnits.PIXELS,
            src: require('../../assets/map/pin_selected.png'),
            scale: 0.17
          }),
          zIndex: 2000
    });

    @Prop()
    reload!: boolean;

    @Prop()
    type!: string;

    @Prop()
    enabled!: boolean;

    @Watch("propertyId") propertyIdWatcher(val: any) {
        if (this.enabled && this.reload && this.propertyId !== 0) {
            this.showProperty(this.propertyId, this.lastSelectedPropertyId !== this.propertyId);
            this.lastSelectedPropertyId = null;
        }
    }

    @Watch("searchedPropertyIds") searchedPropertyIdsWatcher(val: any) {
        if (this.enabled) {
            this.visiblePropertyIds = Array.prototype.map.call(this.searchedPropertyIds, (s: any) => s).toString();
            if (this.reload) {
                this.refreshPropertyVectorSource((context) => {
                    context.zoomPropertyFeatures();
                    this.propertiesCentered = true;
                });
            }
        }
    }

    @Watch("reload")
    reloadWatcher() {
        if (this.enabled && this.reload) {
            this.refreshPropertyVectorSource((context) => {
                if (!this.propertiesCentered) {
                    context.zoomPropertyFeatures();
                    this.propertiesCentered = true;
                }
                if (this.propertyId !== 0) {
                    this.showProperty(this.propertyId, false);
                }
            });
            this.refresh();
        }
    }

    created() {
        this.mapId = this.type + "-map";
    }

    mounted() {
        this.createTileLayer();
        this.createPropertyLayer();
        this.createDrawLayer();
        this.createUserDefAreaLayer();
        this.createUserDefPublicAreaLayer();
        // this.createMunicipalityLayer();
        
        
        let startPosition = Proj.transform([18.04352, 59.33273], 'EPSG:4326', 'EPSG:3857');
        
        this.infoBoxOverlay = new Overlay({
            element: <HTMLElement> this.$refs.propertyInfoBox,
            // autoPan: true,
            autoPanAnimation: {
                duration: 300
            }
        });
        
        this.map = new Map({
            layers: [
                this.tileLayers[0],
                this.tileLayers[1],
                this.propertyLayer,
                this.drawLayer,
                this.userDefAreasLayer,
                this.userDefPublicAreasLayer,
                // this.municipalityLayer
            ],
            overlays: [this.infoBoxOverlay],
            target: this.mapId,
            view: new View({
                center: startPosition,
                zoom: this.zoomLevel    
            })
        });

        this.map.on('singleclick', (e: any) => this.mapClick(e));
        this.map.on('pointermove', (e: any) => this.mapPointerMove(e)); // TODO

        this.map.getView().on('propertychange', (e: any) => {
            switch (e.key) {
                case 'resolution':
                    let newZoomLevel = this.map.getView().getZoom() || 8;
                    if (newZoomLevel != this.zoomLevel) {
                        this.zoomLevel = newZoomLevel;
                        this.updateLayerVisibility();
                    }
                    break;
            }
            this.hideInfoBox();
        });

        var listenerKey = this.propertyVectorSource.on('change', (e: any) => {
            if (this.propertyVectorSource.getState() == 'ready' && !this.clusterLayer) {
                this.createCluster();
                this.map.addLayer(this.clusterLayer);
            }
        });

        this.createContextMenu();

        this.$nextTick(() => {
            this.updateLayerVisibility();
        })

        // var selectPointerMove = new Select({
        //     condition: EventConditions.pointerMove
        //   });

        // this.map.addInteraction(selectPointerMove);
    }

    togglePropertyLayer() {
        this.updateLayerVisibility();
    }

    toggleUserDefLayer() {
        this.updateLayerVisibility();
    }

    togglePublicUserDefLayer() {
        this.updateLayerVisibility();
    }

    toggleMunicipalitiesLayer() {
        this.updateLayerVisibility();
    }

    toggleAreas() {
        this.updateLayerVisibility();
    }

    updateLayerVisibility() {
        let detailed = this.zoomLevel > 12;

        if (this.clusterLayer) {
            this.clusterLayer.setVisible(this.propertyLayerEnabled && !detailed);
        }

        this.propertyLayer.setVisible(detailed && this.propertyLayerEnabled);
        this.userDefAreasLayer.setVisible(this.userAreaLayerEnabled);
        this.userDefPublicAreasLayer.setVisible(this.userAreaPublicLayerEnabled);
        // this.municipalityLayer.setVisible(this.areasEnabled && this.municipalitiesLayerEnabled);
    }

    showProperty(id: number, setZoom: boolean) {
        let source: Vector = this.propertyLayer.getSource();
        let features: Feature[] = source.getFeatures();
        // reset last selected propertys pin style
        features.forEach(x => {
            let fid = (<any>x).values_.propertyid;
            if (fid === this.lastPropertyId.toString()) {
                x.setStyle(this.propertyPinStyle);
                // TODO: break?
            }
        });

        // set selected propertys pin style 
        features.forEach(x => {
            let fid = (<any>x).values_.propertyid;
            if (fid === id.toString()) {
                this.lastPropertyId = <number>id;
                x.setStyle(this.selectedPropertyPinStyle);

                if (setZoom) {
                    const geom = x.getGeometry();
                    if (geom) {
                        let ext = geom.getExtent();
                        let center = Extent.getCenter(ext);
                        this.map.getView().setCenter([center[0], center[1]]);
                        if ((this.map.getView().getZoom() || 8) < 14)
                            this.map.getView().setZoom(14);
                    }
                }

                // TODO: break?
            }
        });
    }

    getPropertyLayerUrl(): string {
        return `${this.geoServerUrl}/GetPropertyLocations`;
    }

    refreshPropertyVectorSource(then: (context: this) => any) {
        this.propertyVectorSource.clear(true);
        this.refreshPropertyLayer();
        let context = this;
        this.propertyVectorSource.once("change", function(e){
            then(context);
        });
    }

    refreshPropertyVectorCluster() {
        if (this.propertyVectorSource && this.propertyClusterVectorSource) {
            this.propertyClusterVectorSource.clear(true);
            this.propertyClusterVectorSource.addFeatures(this.propertyVectorSource.getFeatures());
        }
    }

    refreshPropertyLayer() {
        let context = this;
        context.loading++;
        context.PostHttpInterceptor(context.getPropertyLayerUrl(), context.visiblePropertyIds, function (response) {
            let features = new WFS().readFeatures(response.responseText);
            context.transformFeatures(features);
            context.propertyVectorSource.addFeatures(features);
            context.refreshPropertyVectorCluster();
            context.loading--;
        });
    }

    transformFeatures(features: Feature<Geom.Geometry>[]): void {
        features.map(f => {
            const geom = f.getGeometry();
            if (geom) geom.transform('EPSG:4326', 'EPSG:3857')
        });
    }

    zoomPropertyFeatures(): void {
        var source: Vector = this.propertyLayer.getSource();
        var features: Feature[] = source.getFeatures();

        if (features.length > 0) {
            let extent = this.propertyVectorSource.getExtent();
            this.map.getView().fit(extent, { size: this.map.getSize(), maxZoom: 14 });
        }
    }

    createTileLayer() {
        this.tileLayers.push(new TileLayer({
            source: new TileJSON({
                url: (process.env.VUE_APP_TILE_STREET_URL || "") + "?key=" + this.currentUser.mapKey,
                tileSize: 512,
                crossOrigin: 'anonymous',
            }),
        }));
        this.tileLayers.push(new TileLayer({
            source: new TileJSON({
                url: (process.env.VUE_APP_TILE_HYBRID_URL || "") + "?key=" + this.currentUser.mapKey,
                tileSize: 512,
                crossOrigin: 'anonymous',
            }),
            visible: false
        }));
    }

    createPropertyLayer() {
        let context = this;
        this.propertyVectorSource = new Vector({
            format: new WFS(),
            loader: async function (extent, resolution, projection) {
                if (context.visiblePropertyIds.length > 0) {
                    context.refreshPropertyLayer();
                }
            },
            // strategy: LS.bbox
        });

        // this.propertyVectorSource.on('change', (e: any) => this.zoomPropertyFeatures());

        this.propertyLayer = new VectorLayer({
            source: this.propertyVectorSource,
            style: this.propertyPinStyle
        });

        this.propertyLayer.setZIndex(1000);
        this.propertyLayer.set('name', 'property')
    }

    createMunicipalityLayer() {
        let context = this;
        this.municipalityLayer = new VectorLayer({
            source: new Vector({
                format: new WFS(),
                loader: async function (extent, resolution, projection) {
                    context.MapHttpInterceptor(
                        //todo
                        context.geoServerUrl + "?service=WFS&version=1.1.0&request=GetFeature&typeName=FIA:municipality", function (response) {
                            let features = new WFS().readFeatures(response.responseText);
                            context.transformFeatures(features);
                            context.municipalityLayer.getSource().addFeatures(features);
                        });
                },
                strategy: LS.bbox
            }),
            style: new Style({
                stroke: new Stroke({
                    color: 'rgba(0, 0, 255, 0.5)',
                    width: 1
                }),
                fill: new Fill({ color: 'rgba(0, 0, 0, 0)' })
            })
        });

        this.municipalityLayer.setZIndex(999);
        this.municipalityLayer.set('name', 'municipality')
    }

    createUserDefAreaLayer() {
        let context = this;
        let id = this.$store.getters.getUser.id;
        this.userDefAreasLayer = new VectorLayer({
            source: new Vector({
                format: new WFS(),
                loader: async function (extent, resolution, projection) {
                    context.MapHttpInterceptor(
                        context.geoServerUrl + "/GetUserLocationFilters", function (response) {
                            let features = new WFS().readFeatures(response.responseText);
                            context.transformFeatures(features);
                            context.userDefAreasLayer.getSource().addFeatures(features);
                        });                
                },
                strategy: LS.bbox
            }),
            style: userAreaStyle
        });

        this.userDefAreasLayer.setZIndex(998);
        this.userDefAreasLayer.set('name', 'userDefinedAreas')
    }

    createUserDefPublicAreaLayer() {
        let context = this;
        let id = this.$store.getters.getUser.id;

        this.userDefPublicAreasLayer = new VectorLayer({
            source: new Vector({
                format: new WFS(),
                loader: async function (extent, resolution, projection) {
                    context.MapHttpInterceptor(
                        context.geoServerUrl + "/GetPublicUserLocationFilters", function (response) {
                            let features = new WFS().readFeatures(response.responseText);
                            context.transformFeatures(features);
                            context.userDefPublicAreasLayer.getSource().addFeatures(features);
                        });                
                },
                strategy: LS.bbox
            }),
            style: publicUserAreaStyle
        });

        this.userDefPublicAreasLayer.setZIndex(997);
        this.userDefPublicAreasLayer.set('name', 'publicUserDefinedAreas')
    }

    refresh() {
        this.$nextTick(() => {
            this.map.updateSize();
        });
    }

    styleCache: any = {};
    createCluster() {
        this.propertyClusterVectorSource = new Vector({
            features: this.propertyVectorSource.getFeatures()
        });

        this.clusterSource = new Cluster({
            distance: 80,
            source: this.propertyClusterVectorSource,
            geometryFunction: feature => {
                const geom = feature.getGeometry();
                if (!geom) return new Point([0,0]);
                return new Geom.Point(Extent.getCenter(geom.getExtent()));
            },
        });

        this.clusterLayer = new VectorLayer({
            source: this.clusterSource,
            style: feature => {
                let size = feature.get('features').length;
                let chars = size.toString().length;
                // if (size === 1) {
                //     return propertyPinStyle;
                // }
                let style = this.styleCache[size];
                if (!style) {
                    style = new Style({
                        image: new Circle({
                            radius: 8 + chars * 2,
                            stroke: new Stroke({
                                color: '#fff'
                            }),
                            fill: new Fill({
                                color: '#3399CC'
                            })
                        }),
                        text: new Text({
                            text: size.toString(),
                            fill: new Fill({
                                color: '#fff'
                            })
                        })
                    });
                    this.styleCache[size] = style;
                }
                return style;
            }
        });
        this.clusterLayer.setZIndex(1001);
    }

    mapPointerMove(e: any) {
        if (this.creatingArea) {
            return;
        }
        let pixel = this.map.getEventPixel(e.originalEvent);
        let hit = this.map.hasFeatureAtPixel(pixel, {
            layerFilter: function (layer) {
                return layer.get('name') !== 'municipality';
            }
        });
        let propertyHit = false;

        if (this.userAreaFeature && this.userAreaFeature !== this.selectedAreaFeature) {
            this.userAreaFeature.setStyle(undefined);
        }

        if (hit) {
            let feature = this.map.forEachFeatureAtPixel(pixel,
                function (feature: any, layer: any) {
                    return [feature, layer];
                });
            if (feature && feature.length > 1 && feature[1]) {
                let layerName = feature[1].get('name');

                if (layerName === 'property') {
                    propertyHit = true;
                    clearTimeout(this.infoBoxOverlayHideTimer);
                    let propertyId: number = <number>feature[0].values_.propertyid;

                    if (this.infoBoxOverlayShowTimer == null) {
                        this.infoBoxOverlayShowTimer = setTimeout(() => {
                            if (this.hoverPropertyId !== propertyId) {
                                this.loading++;
                                DataService.getContractsSummary(propertyId).then(d => {
                                    this.propertyInfo = d.data;
                                    this.showInfoBox(e, e.coordinate);
                                }).catch(() => { this.propertyInfo = <ContractsSummary>{ version: <VersionInfo>{}, contract: [], vacancy: [], costs: [] } 
                                }).finally(() => { this.loading--;});
                            } else {
                                this.showInfoBox(e, e.coordinate);
                            }
                            this.hoverPropertyId = propertyId;
                            this.infoBoxOverlayHideTimer = null;
                            this.infoBoxOverlayShowTimer = null;
                        }, 280);
                    }
                }
                else if (layerName == "userDefinedAreas" || layerName == "publicUserDefinedAreas") {
                    this.userAreaFeature = (<Feature>feature[0]);
                    if (this.userAreaFeature !== this.selectedAreaFeature) {
                        this.userAreaFeature.setStyle(this.getHoveredAreaStyle(layerName, (<any> this.userAreaFeature).values_.filter_name));
                    }
                }
            }
        }

        if (!propertyHit) {
            clearTimeout(this.infoBoxOverlayShowTimer);
            this.infoBoxOverlayShowTimer = null;
        }

        if (!propertyHit && !this.lockInfoBox) {
            if (this.infoBoxOverlayHideTimer == null) {
                this.infoBoxOverlayHideTimer = setTimeout(_ => {
                    this.hideInfoBox();
                }, 200)
            }
        }

        (<any>this.map.getViewport()).style.cursor = this.posChangeActive ? 'crosshair' : (propertyHit ? 'pointer' : '');
    }

    getFeaturesAtPixel(pixel: any): any[] {
        let features: any = [];
        this.map.forEachFeatureAtPixel(pixel, (f: any, l: any) => {
            features.push({ f, l });
        });
        return features;
    }

    getFeatureAtPixelByLayer(pixel: any, layer: string): any {
        let features = this.getFeaturesAtPixel(pixel);
        for (let i = 0; i < features.length; i++) {
            let item = features[i];
            if (item.l && item.l.values_ && item.l.values_.name === layer)
                return {feature: item.f, layer: item.l};
        }
        return undefined;
    }

    async mapClick(e: any) {
        if (this.modifyingArea) {
            this.editAreaFinish();
        }
        if (this.creatingArea) {
            return;
        }

        let userAreaFeature: Feature = this.getFeatureAtPixelByLayer(e.pixel, "userDefinedAreas");
        let publicUserAreaFeature: Feature = this.getFeatureAtPixelByLayer(e.pixel, "publicUserDefinedAreas");
        let propertyFeature = this.getFeatureAtPixelByLayer(e.pixel, "property");

        if (!this.posChangeActive) {
            // If a property is clicked, load the latest property version
            if (propertyFeature) {
                const propertyId = parseInt(propertyFeature.feature.values_.propertyid, 10);
                const latestVersion = await DataService.getLatestPropertyVersionId(propertyId).then(x => x.data);
                if (latestVersion) {
                    this.lastSelectedPropertyId = propertyId;
                    await this.$store.dispatch("loadPropertyVersion", { id: latestVersion.id, source: "map" });
                }
            }
            else if (userAreaFeature || publicUserAreaFeature) {
                this.selectedArea = null;
                let feature = userAreaFeature ? userAreaFeature : publicUserAreaFeature;
                let values = (<any> feature).feature.values_;
                let area = { id: values.uid, filterName: values.filter_name, userName: values.user_name, isPublic: values.is_public === 'true', userId: values.user_id };

                let layername = (<any> feature).layer.get('name');
                if (this.selectedAreaFeature) {
                    this.selectedAreaFeature.setStyle(undefined);
                }
                this.selectedAreaFeature = (<Feature>((<any> feature).feature));
                this.selectedAreaFeature.setStyle(this.getSelectedAreaStyle(layername, area.filterName));

                this.searchByArea(values.geom.getPolygons()[0]);

                this.activePanel = layername;
                if (userAreaFeature) {
                    this.selectedArea = area;
                }
            }
            else { // if clicked pixel has no functionality, clear the current area (if it exists)
                if (this.selectedAreaFeature) {
                    this.selectedAreaFeature.setStyle(undefined);
                    this.selectedAreaFeature = undefined;
                    this.selectedArea = null;
                    this.$store.dispatch("setSearchedProperties", { propertyIds: null, source: "map" });
                }
            } 
        } else { // if position change is active, ignore other functionalities at pixel
            let lonlat = Proj.transform(e.coordinate, 'EPSG:3857', 'EPSG:4326');

            await this.confirm(this.translate('ConfirmPropertyPositionChange', { 
                property: this.propertyVersion.property.propertyName 
            })).then(() => {
                if (!this.propertyId) return;

                DataService.updatePropertyPosition(this.propertyId, lonlat[0], lonlat[1]).then(_ => {
                    this.refreshPropertyVectorSource(() => {
                        this.showProperty(this.propertyId, true);
                    });
                    this.posChangeActive = false;
                });
            }).catch(() => {
                this.posChangeActive = false;
            });
        }
    }

    searchByArea(polygon: any) {
        //Populate search tree and show properties on map
        this.$store.dispatch("setSearchingProperties", true);
        this.loading++;
        let coords = polygon.getCoordinates()[0].map((c : any) => Proj.transform(c, 'EPSG:3857', 'EPSG:4326'));
        coords = coords.map((c: any) => <string> c[1] + ' ' + c[0]).join(",");
        DataService.getPropertiesByArea(coords).then(response => {
            let features = new WFS().readFeatures(response.data);
            this.transformFeatures(features);
            this.propertyVectorSource.clear(true);
            this.propertyVectorSource.addFeatures(features);
           
            let propertyIds = features.map((x: any) => x.values_.propertyid);
            this.visiblePropertyIds = "";
            this.refreshPropertyVectorCluster();
            this.$store.dispatch("setSearchingProperties", false);
            this.$store.dispatch("setSearchedProperties", { propertyIds: propertyIds, source: "map" });
            // this.showProperty(this.propertyId, false);
            this.loading--;
           
        }).catch(() => { this.loading--; });
    }

    contractInfoTableFormat(row: any, column: any, cellValue: any) {
        switch (column.property) {
            case "premisesTypeId":
                return this.translateBaseData(cellValue, this.baseDataType.PremisesTypes);
            case "area":
            case "passingRent":
            case "passingRentM2":
            case "marketRent":
            case "marketRentM2":
                return this.formatNumber(Math.round(cellValue), 0);
        }
    }

    vacanciesTableFormat(row: any, column: any, cellValue: any) {
        switch (column.property) {
            case "percentage":
                return this.formatPercentage(cellValue, 2, false);
        }
    }

    mouseEnterInfobox() {
        this.lockInfoBox = true;
        clearTimeout(this.infoBoxOverlayHideTimer);
        this.infoBoxOverlayHideTimer = null
    }

    mouseLeaveInfobox() {
        if (this.lockInfoBox) {
            this.lockInfoBox = false;
        }
    }

    showInfoBox(event: any, coordinates: any) {
        setTimeout(() => {
            let map = this.map.getTargetElement().getBoundingClientRect();
            let e = this.infoBoxOverlay.getElement();
            if (e) {
                let y = event.originalEvent.pageY;
                let showOnRight = event.originalEvent.pageX - map.left < map.width / 2;
                let marginX = 17;
                let marginY = 30;
    
                this.infoBoxOverlay.setPosition(coordinates);
                this.infoBoxOverlay.setOffset([showOnRight ? marginX : -e.clientWidth - marginX, this.infoBoxOverlay.getOffset()[1]]);
                this.infoBoxOverlay.setOffset([this.infoBoxOverlay.getOffset()[0], y + e.clientHeight > map.bottom ? -(y + e.clientHeight - map.bottom + marginY) : 0]);
            }
        }, 160);
    }

    hideInfoBox() {
        this.infoBoxOverlay.setPosition(undefined);
    }

    toggleHybridMode() {
        for (let i = 0; i < this.tileLayers.length; i++) {
            this.tileLayers[i].setVisible(!this.tileLayers[i].getVisible());
        }
    }

    changePosition() {
        // check if a property version is chosen
        if (!this.propertyId) {
            this.$notify.warning({
                title: this.translate("ValidationWarning"),
                message: this.translate("NoVersionSelected")
            });
            return;
        }
        // check if the chosen property is currently visible on the map
        if (!this.searchedPropertyIds.find(id => id == this.propertyId)) {
            this.$notify.warning({
                title: this.translate("Warning"),
                message: this.translate("NoPropertySelected")
            });
            return;
        }
        this.posChangeActive = true;
    }

    tableFormatter(row: any, column: any, cellValue: any) {
        switch (column.property) {
            case "typeOfPaymentId":
                return this.translateBaseData(cellValue, this.baseDataType.TypeOfPayments);
            case "amount":
                let areaSum = 0;
                this.propertyInfo.contract.forEach(c => areaSum += c.area);
                const formatted = this.formatNumber(Math.round(cellValue / (areaSum > 0 ? areaSum : 1)), 0);
                const currency = this.translateBaseData(this.propertyInfo.version.currencyId, this.baseDataType.Currencies);
                return `${formatted} ${currency}/m²`;
        }
    }

    marker(obj: any) {
        var coord4326 = Proj.transform(obj.coordinate, 'EPSG:3857', 'EPSG:4326');
        var template = 'Coordinate is ({x} | {y})';
        var iconStyle = new Style({
            image: new Icon({ scale: 0.6, src: 'img/pin_drop.png' }),
            text: new Text({
                offsetY: 25,
                text: format(coord4326, template, 2),
                font: '15px Open Sans,sans-serif',
                fill: new Fill({ color: '#111' }),
                stroke: new Stroke({ color: '#eee', width: 2 }),
            }),
            });
            var feature = new Feature({
                type: 'removable',
                geometry: new Point(obj.coordinate),
            });
        
        feature.setStyle(iconStyle);
        this.propertyVectorSource.addFeature(feature);
    }

    isPropertySelected() {
        return this.getSelectedPropertyId() != 0;
    }

    getSelectedPropertyId() {
        const propInfoId = this.propertyInfo.version.propertyId;

        if (propInfoId != null) return propInfoId;

        if (!this.propertyVersion) {
            return 0;
        }
        return this.propertyVersion.propertyId; 
    }

    createContextMenu() {
        // https://github.com/jonataswalker/ol-contextmenu
        var ContextMenu = require('ol-contextmenu');
        var contextmenu_items = [
            {
              text: 'Byt position',
              classname: 'some-style-class', // add some CSS rules
              callback: () => this.changePosition()
            },
            '-' // this is a separator
          ];
        var contextmenu = new ContextMenu({
            width: 170,
            defaultItems: false,
            items: contextmenu_items 
        });

        this.map.addControl(contextmenu);
    }

    exportMapImageToProperty() {
        // This method is called once after the renderSync() method below is completed
        // See example: https://openlayers.org/en/latest/examples/export-map.html
        this.map.once('rendercomplete', async () => {
            try {
                this.loading++;
                const mapCanvas = this.getCanvasFromMap();
                const imageBlob = await this.canvasToBlob(mapCanvas);
                const file = new File([imageBlob!], 'File', { type: 'image/jpeg' });
                const form = new FormData();
                form.append("File", file);
                form.append("PropertyId", this.propertyId.toString());
                form.append("Type", 'map');
                await DataService.addPropertyImage(form).then(x => x.data);
                this.$store.dispatch("sendBusMessage", <BusMessage<PropertyMapUpdatedMessage>>{ type: "PropertyMapUpdated", data: {} });
                this.$message.success({
                    type: 'success',
                    message: this.translate('MapSavedSuccessNotification', { property: this.propertyVersion.property.propertyName })
                });
            } catch (error) {
                if (error instanceof Error) {
                    this.$message.error({
                        type: 'error',
                        message: this.translate('MapSavedErrorNotification', { err: error.message || this.translate('Unknown') })
                    });
                }
            } finally {
                this.loading--;
                this.resetImageExportMode();
            }
        })
        
        this.setImageExportMode();
        this.map.renderSync();
    }

    downloadMapImage() {
        // This method is called once after the renderSync() method below is completed
        // See example: https://openlayers.org/en/latest/examples/export-map.html
        this.map.once('rendercomplete', async () => {
            try {
                this.loading++;
                const mapCanvas = this.getCanvasFromMap();
                const imageBlob = await this.canvasToBlob(mapCanvas);
                
                const file = new File([imageBlob!], 'File', {type: 'image/jpeg'});
                const blob = new Blob([file], {});
                const url = window.URL.createObjectURL(blob);
                var a = document.createElement('a')
                a.download = this.propertyVersion && this.propertyVersion.property ? this.propertyVersion.property.propertyName + '.png' : this.translate('Map') + '.png';
                a.href = url;
                a.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
                a.remove();
                setTimeout(() => window.URL.revokeObjectURL(url), 100);
            } catch (error) {
                console.error(error);
                this.$message.error({
                    type: 'error',
                    message: this.translate('ErrorUnexpected')
                });
            } finally {
                this.loading--;
                this.resetImageExportMode();
            }
        })
        
        this.setImageExportMode();
        this.map.renderSync();
    }

    // Wraps the blob export function in a promise so we can await it (instead of using a callback)
    canvasToBlob(canvas: HTMLCanvasElement, type?: string, quality?: any): Promise<Blob | null> {
        return new Promise(function(resolve, reject) {
            canvas.toBlob(blob => {
                resolve(blob);
            }, type, quality);
        });
    }

    // Resize pushpins etc. (before exporting it as an image)
    setImageExportMode() {
        const scale = this.exportMapPinScale * 0.15;
        this.lastPinStyleScale = this.propertyPinStyle.getImage().getScale();
        this.lastSelectedPinStyleScale = this.selectedPropertyPinStyle.getImage().getScale();
        this.setPinStyleScale(scale, this.propertyPinStyle);
        this.setPinStyleScale(scale, this.selectedPropertyPinStyle);
        this.refreshFeatures(this.propertyLayer);
    }

    // Reset map layout (after exporting it as an image)
    resetImageExportMode() {
        this.setPinStyleScale(this.lastPinStyleScale as number, this.propertyPinStyle);
        this.setPinStyleScale(this.lastSelectedPinStyleScale as number, this.selectedPropertyPinStyle);
        this.refreshFeatures(this.propertyLayer);
    }

    // Returns a canvas element from the OL map object
    getCanvasFromMap(): HTMLCanvasElement {
        const mapCanvas = document.createElement('canvas');
        const size = this.map.getSize();
        if (!size) throw new Error("Canvas size not defined.");
        mapCanvas.width = size[0];
        mapCanvas.height = size[1];
        const mapContext = mapCanvas.getContext('2d');
        Array.prototype.forEach.call(
            document.querySelectorAll(`#${this.mapId} .ol-layer canvas`),
            function (canvas) {
            if (canvas.width > 0) {
                const opacity = canvas.parentNode.style.opacity;
                mapContext!.globalAlpha = opacity === '' ? 1 : Number(opacity);
                const transform = canvas.style.transform;
                // Get the transform parameters from the style's transform matrix
                const matrix = transform
                .match(/^matrix\(([^\(]*)\)$/)[1]
                .split(',')
                .map(Number);
                // Apply the transform to the export map context
                CanvasRenderingContext2D.prototype.setTransform.apply(
                mapContext,
                matrix
                );
                mapContext!.drawImage(canvas, 0, 0);
            }
            }
        );
        mapCanvas.remove();
        return mapCanvas;
    }

    setPinStyleScale(scale: number, style: Style) {
        const image = style.getImage();
        if (!image) return;
        image.setScale(scale);
    }

    refreshFeatures(layer: VectorLayer<any>) {
        layer.getSource().getFeatures().forEach((x: any) => {
            x.changed();
        });
    }
}
