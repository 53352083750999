import BaseComponent from "@/components/base-component";
import { Component, Watch, Prop } from "vue-property-decorator";
import { BuildingRight } from "@/models/BuildingRight";
import InlineEdit from '@/helpers/inline-edit';
import { AxiosPromise } from 'axios';
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import InputTextComponent from '@/components/common/input-text/input-text';
import ValidationTableFeedbackComponent from '@/components/common/validation-table-feedback/validation-table-feedback';
import ValidationErrorComponent from '@/components/common/validation-error/validation-error';
import { ValidationError } from '@/models/interfaces/ValidationError';
import dataService from '@/services/data-service';
import { BuildingRightUnit, PropertyVersion, BuildingRightPurpose } from '@/models';
import { mapGetters } from 'vuex';
import validationService from '@/services/validation-service';

@Component({
    components: {
        YearMonthPickerComponent,
        InputNumericComponent,
        InputTextComponent,
        ValidationTableFeedbackComponent,
        ValidationErrorComponent
    },
    computed: mapGetters({
        propertyVersion: "getPropertyVersion",
        purposes: "getBuildingRightPurposes",
        units: "getBuildingRightUnits",
    })
})
export default class BuildingDevComponent extends BaseComponent {
    propertyVersion!: PropertyVersion;
    purposes!: BuildingRightPurpose[];
    units!: BuildingRightUnit[];

    buildsGrid: InlineEdit<BuildingRight>;
    currentPropertyVersion: number = -1;
    saving: boolean = false;
    complexErrors: ValidationError[] = [];

    @Prop({default: false})
    reload!: boolean;

    @Watch("reload")
    async reloadWatcher() {
        if (this.reload) {
            if (this.reloadNeeded()) {
                await this.reloadData();
            }
            this.buildsGrid.redraw();
        }
    }

    @Watch("propertyVersion")
    async versionWatcher() {
        if (this.reloadNeeded()) {
            await this.reloadData();
        }
    }

    @Watch("buildsGrid.currentItem", { deep: true })
    gridWatcher() {
        validationService.validateBuildRights(this.buildsGrid.entities, this.complexErrors);
    }

    constructor() {
        super();
        this.buildsGrid = new InlineEdit<BuildingRight> (this, "buildsTable", this.loadBuilds, undefined, undefined, this.newBuild, undefined, this.hasNoErrors);
        this.buildsGrid.sync = true;
        this.buildsGrid.useConfirmDeleteDialog = false;
    }

    async mounted() {
        await this.reloadData();
    }

    async reloadData() {
        await this.buildsGrid.reload();
        this.complexErrors = [];
        this.currentPropertyVersion = this.propertyVersion!.id;
    }

    reloadNeeded(): boolean {
        return this.reload && this.propertyVersion && this.currentPropertyVersion !== this.propertyVersion.id;
    }

    loadBuilds(): AxiosPromise<BuildingRight[]> {
        return dataService.getPropertyVersionBuildRights(this.propertyVersion.id);
    }

    getPurposeString(id: number) {
        var purpose = this.purposes.find(p => p.id == id);
        return purpose ? purpose.name : "";
    }

    getUnitString(id: number) {
        var unit = this.units.find(u => u.id == id);
        return unit ? unit.name : "";
    }

    newBuild(): BuildingRight {
        const entities = this.buildsGrid.entities;
        const lastRow = entities.length > 0 ? entities[entities.length - 1] : null;
        return <BuildingRight> {
            id: this.getNewId(this.buildsGrid.entities),
            purpose: lastRow ? lastRow.purpose : this.purposes[0].name,
            purposeId: lastRow ? lastRow.purposeId : this.purposes[0].id,
            area: 0,
            unitId: lastRow ? lastRow.unitId : this.units[0].id,
            unit: lastRow ? lastRow.unit : this.units[0].name,
            propertyDetailId: 1,
            valueM2: 0
        }
    }

    canSave() {
        return this.complexErrors.length == 0 && !this.saving;
    }

    save() {
        this.saving = true;
        dataService.savePropertyVersionBuildRights(this.propertyVersion.id, this.buildsGrid.entities)
            .finally(() => {
                this.saving = false;
                this.buildsGrid.deselect();
            });
    }

    unitChanged(right: BuildingRight) {
        if (right && right.id <= 0) {
            const unit = this.units.find(x => x.id === right.unitId);
            if (unit && unit.areaDefault) {
                right.area = unit.areaDefault;
            }
        }
    }
}