import moment from 'moment';
import VueElement from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import BaseComponent from '@/components/base-component';
import DialogComponent from '@/components/common/dialog/dialog';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import InputTextComponent from '@/components/common/input-text/input-text';
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import ContractRecoveriesComponent from '@/components/contract/contract-recoveries/contract-recoveries';
import ContractLeaseAreasComponent from '@/components/contract/contract-lease-areas/contract-lease-areas';
import PaymentFlowsComponent from '@/components/payment-flows/paymentflows';
import ContractTenantImprovementComponent from '../contract-tenant-improvement/contract-tenant-improvement';
import {
    BaseDataStatus, DateFormat, Ipd6Enum, PaymentStreamType, PremisesTypesEnum,
    PrognosisParameterEnum, TypeOfPaymentGroupEnum, TypeOfPaymentEnum
} from '@/constants/fia-constants';
import utils from '@/helpers/utils';
import {
    Contract, Ipd6, LeaseArea, PaymentGroup, PremisesType, PrognosisParameter,
    PropertyVersion, Status, TypeOfPayment
} from '@/models';
import { ValidationError } from '@/models/interfaces/ValidationError';

@Component({
    components: {
        YearMonthPickerComponent,
        InputNumericComponent,
        PaymentFlowsComponent,
        InputTextComponent,
        DialogComponent,
        ContractRecoveriesComponent,
        ContractLeaseAreasComponent,
        ContractTenantImprovementComponent,
    },
    computed: mapGetters({
        ipdList: "getIpd6",
        statusList: "getStatuses",
        premisesTypesList: "getPremisesTypes",
        prognosisList: "getPrognosisParameters",
        paymentGroupsList: "getPaymentGroupsNormal",
    })
})
export default class ContractDetailsEditComponent extends BaseComponent {
    ipdList!: Ipd6[];
    statusList!: Status[];
    premisesTypesList!: PremisesType[];
    prognosisList!: PrognosisParameter[];
    paymentGroupsList!: PaymentGroup[];

    @Prop()
    contract!: Contract;

    @Prop()
    complexErrors!: ValidationError[];

    @Prop() // Synced
    activeTab!: string;

    @Prop()
    version!: PropertyVersion;

    typeOfPaymentsList: TypeOfPayment[] = [];
    activeTypeOfPaymentsList: TypeOfPayment[] = [];
    paymentGroupId: number = -1;
    saving: boolean = false;
    tab: string = "Contract";

    @Watch("contract", { immediate: true })
    contractWatcher() {
        if (!this.contract) return;
        this.initialize();
    }

    @Watch("activeTab", { immediate: true })
    activeTabWatcher() {
        this.tab = this.activeTab;
    }

    initialize() {
        if (!this.contract) return;
        this.typeOfPaymentsList = this.$store.getters.getActiveTypeOfPayments([this.contract.transaction.typeOfPaymentId]);
        let pt = this.typeOfPaymentsList.find(x => x.id === this.contract.transaction.typeOfPaymentId);
        this.paymentGroupId = pt ? pt.paymentGroupId : this.paymentGroupsList[0].id;
        this.filterTypeList();
    }

    filterTypeList() {
        this.activeTypeOfPaymentsList = this.typeOfPaymentsList.filter(x => x.paymentGroupId == this.paymentGroupId);
        this.activeTypeOfPaymentsList = this.activeTypeOfPaymentsList.filter(x => x.typeOfPaymentGroupIds == null
            || x.typeOfPaymentGroupIds.some(t => t === TypeOfPaymentGroupEnum.Contract) 
            || x.id == this.contract.transaction.typeOfPaymentId);
    }

    useMarketRent(): boolean {
        return this.contract.prolongingTypeId == PaymentStreamType.IndexIncrementStream;
    }

    prolongingTypeChanged() {
        if (this.useMarketRent()) {
            if (!this.contract.marketRentIndexPercent)
                this.contract.marketRentIndexPercent = 1;
            if (!this.contract.prognosisParameterId)
                this.contract.prognosisParameterId = PrognosisParameterEnum.Inflation
        }
        else {
            this.contract.marketRentIndexPercent = null;
            this.contract.prognosisParameterId = null;
        }
    }

    updateCurrentTypeOfPayment() {
        this.contract.transaction.typeOfPaymentId = (this.activeTypeOfPaymentsList[0] != null) ? this.activeTypeOfPaymentsList[0].id : -1;
    }

    paymentGroupChanged() {
        this.filterTypeList();
        this.updateCurrentTypeOfPayment();
    }

    statusChanged() {
        const status = this.statusList.find(x => x.id == this.contract.statusId);
        if (status && status.vacant) {
            this.contract.ipd6Id = Ipd6Enum.Vacant;
            if (status.id === BaseDataStatus.Vacant) {
                this.confirm("ConfirmSetVacancyFields").then(() => {
                    this.setVacant();
                }).catch(() => {});
            }
        }
    }

    setVacant() {
        this.contract.tenant = this.translate('Vacant');
        this.contract.recoveries = this.contract.recoveries.filter(recovery => 
            recovery.costIncome.transaction.typeOfPaymentId === TypeOfPaymentEnum.TenantImprovement
        );
        this.contract.vat = null;
        this.contract.corporateIdentityNumber = '';
        
        const calcStartDate = this.version.calculationValue ? this.version.calculationValue.startDate : this.version.calculationStart;
        this.contract.endDate = this.monthEnd(moment(calcStartDate).add(-1, "month").toDate()); 
        const flowsComponent = (<PaymentFlowsComponent> this.$refs.paymentFlowsComponentRef);
        if (flowsComponent) {
            flowsComponent.setVacant(this.contract.endDate);
        }
    }

    endDateChanged() {
        utils.updateContractEndDate(this.contract);
    }

    mainPremisesChanged(newPremisesType: PremisesTypesEnum) {
        const premisesType = this.premisesTypesList.find(x => x.id === newPremisesType);
        if (premisesType && premisesType.typeOfPaymentId) {
            const typeOfPayment = this.typeOfPaymentsList.find(x => x.id === premisesType.typeOfPaymentId);
            if (typeOfPayment) {
                if (this.paymentGroupId !== typeOfPayment.paymentGroupId) {
                    this.paymentGroupId = typeOfPayment.paymentGroupId;
                    const element = (<VueElement>this.$refs.paymentGroup).$el.children[0].children[0];
                    this.highlightField(element);
                }
                if (this.contract.transaction.typeOfPaymentId !== premisesType.typeOfPaymentId) {
                    this.contract.transaction.typeOfPaymentId = premisesType.typeOfPaymentId;
                    this.filterTypeList();
                    const element = (<VueElement>this.$refs.typeOfPayment).$el.children[0].children[0];
                    this.highlightField(element);
                }
            }
        }
        const oldMainPremisesType = this.contract.mainPremisesTypeId;
        this.contract.mainPremisesTypeId = newPremisesType;
        this.updateContractLeaseAreas(oldMainPremisesType, newPremisesType);
    }

    updateContractLeaseAreas(oldPremisesType: PremisesTypesEnum, newPremisesType: PremisesTypesEnum) {
        let areas: LeaseArea[] = this.contract.leaseAreas;
        areas.forEach(area => {
            if (area.premisesTypeId === oldPremisesType) {
                area.premisesTypeId = newPremisesType;
            }
        });
    }

    highlightField(element: Element): void {
        if (element) {
            element.classList.add('flashit');
            setTimeout(() => {
                element.classList.remove('flashit');
            }, 2000);
        }
    }

    async saved(savedContract: Contract) {
        this.$emit("saved", savedContract);
    }

    tabClick(tab: any) {
        this.$emit('update:activeTab', tab.name);
    }
}