import BaseComponent from "@/components/base-component";
import { Component, Watch, Prop } from "vue-property-decorator";
import { PropertyDetail } from "@/models";
import { ValidationError } from '@/models/interfaces/ValidationError';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import InputTextComponent from '@/components/common/input-text/input-text';
import ValidationTableFeedbackComponent from '@/components/common/validation-table-feedback/validation-table-feedback';
import ValidationErrorComponent from '@/components/common/validation-error/validation-error';
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import { Valuer } from '@/models/Valuer';
import dataService from '@/services/data-service';
import { KeyValue } from '@/models/interfaces/request/KeyValue';

@Component({
    components: {
        InputNumericComponent,
        InputTextComponent,
        YearMonthPickerComponent,
        ValidationTableFeedbackComponent,
        ValidationErrorComponent,
    }
})
export default class GeneralComponent extends BaseComponent {
    @Prop()
    general!: PropertyDetail;
    @Prop()
    complexErrors!: ValidationError[];
    @Prop()
    isLinked!: boolean;

    rebuildYears: string = "";
    valuers: Valuer[] = [];
    activeValuers: KeyValue[] = [];

    @Watch("general", { immediate: true })
    async generalWatcher() {
        this.rebuildYears = this.general.propertyRebuiltYears.map(x => this.formatYear(x.rebuiltYear)).sort().join(', ');
        this.rebuiltYearsChanged();
        if (this.valuers.length === 0) {
            try {
                this.valuers = await dataService.getValuers().then(x => x.data);
            } catch (error) {
            }
        }

        this.activeValuers = this.valuers.filter(x => (!x.inactive || (x.id === this.general.valuer1 || x.id === this.general.valuer2))).map(x => <KeyValue>{ key: x.id, value: x.realName });
        this.activeValuers.unshift({ key: null, value: "" });
        this.activeValuers.sort((a, b) => a.value > b.value ? 1 : -1);
    }

    rebuiltYearsChanged() {
        let years = this.rebuildYears.split(",").map(x => parseInt(x.trim(), 10)).filter(x => x > 0 && x < 3000).map(x => x.toString());
        this.$emit("rebuiltYearsChanged", years);
    }

    valuer1Changed() {
        this.general.valuer2 = null;
    }
}