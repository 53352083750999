import BaseComponent from "@/components/base-component";
import DataService from "@/services/data-service";
import InlineEdit from "@/helpers/inline-edit";
import { Component, Prop, Watch } from "vue-property-decorator";
import { AxiosPromise } from "axios";
import { PremisesType } from "@/models/PremisesType";
import { mapGetters } from "vuex";
import { BaseDataType } from '@/constants/fia-constants';
import { PremisesTypeGroup, TypeOfPayment } from "@/models";
import InputNumericComponent from "@/components/common/input-numeric/input-numeric";

@Component({
    components: {
        InputNumericComponent: InputNumericComponent
    },
    computed: mapGetters({
        premisesTypeGroups: "getPremisesTypeGroups",
        typeOfPayments: "getTypeOfPayments"
    })
})
export default class PremisesComponent extends BaseComponent {
    premisesTypeGroups!: PremisesTypeGroup[];
    typeOfPayments!: TypeOfPayment[];

    premisesGrid: InlineEdit<PremisesType>;
    premisesTable: string;

    @Prop()
    reload!: boolean;
    @Prop()
    readOnly!: boolean;

    constructor() {
        super();
        this.premisesTable = "premisesTable";
        this.premisesGrid = new InlineEdit<PremisesType>(this, this.premisesTable, this.loadPremises, this.savePremises, this.deletePremises, this.newPremises, undefined, this.hasNoErrors);
        this.premisesGrid.onItemChanged = this.itemChanged;
        this.premisesGrid.readOnly = this.readOnly;
    }
    mounted() {
        this.premisesGrid.reload();
    }

    @Watch("reload")
    reloadWatcher() {
        if (this.reload == true) {
            (<any> this).$refs.premisesTable.doLayout();
        }
    }

    loadPremises(): AxiosPromise<PremisesType[]> {
        return DataService.getPremisesTypes();
    }

    savePremises(entity: PremisesType): AxiosPromise<PremisesType> {
        return DataService.savePremisesType(entity);
    }

    deletePremises(id: number): AxiosPromise<PremisesType> {
        return DataService.deletePremisesType(id);
    }

    newPremises(): PremisesType {
        return <PremisesType> { 
            id: 0, 
            ipdNum: 0, 
            inactive: false, name: "", 
            premisesTypeGroupIds: [],
            hasArea: false,
            typeOfPaymentId: null,
            languageId: this.$store.getters.getUser.languageId,
            marketTypeDefaultValueMaps: []
        };
    }

    itemChanged() {
        this.$store.dispatch('updateCache', BaseDataType.PremisesTypes);
    }
}
