import BaseComponent from "@/components/base-component";
import DataService from "@/services/data-service";
import InlineEdit from "@/helpers/inline-edit";
import { Component, Prop, Watch } from "vue-property-decorator";
import { AxiosPromise } from "axios";
import { Ipd6 } from "@/models/Ipd6";
import { BaseDataType } from '@/constants/fia-constants';
import InputNumericComponent from "@/components/common/input-numeric/input-numeric";
import YearMonthPickerComponent from "@/components/common/year-month-picker/year-month-picker";

@Component({
    components: {
        YearMonthPickerComponent: YearMonthPickerComponent,
        InputNumericComponent: InputNumericComponent
    }
})
export default class IpdComponent extends BaseComponent {
    Ipd6Grid: InlineEdit<Ipd6>;

    @Prop()
    reload!: boolean;
    @Prop()
    readOnly!: boolean;

    @Watch("reload")
    reloadWatcher() {
        if (this.reload == true) {
            (<any> this).$refs.Ipd6Table.doLayout();
        }
    }

    constructor() {
        super();
        this.Ipd6Grid = new InlineEdit<Ipd6>(this, "Ipd6Table", this.loadIpd6, this.saveIpd6, this.deleteIpd6, this.newIpd6, undefined, this.hasNoErrors);
        this.Ipd6Grid.onItemChanged = this.itemChanged;
        this.Ipd6Grid.readOnly = this.readOnly;
    }

    mounted() {
        this.Ipd6Grid.reload();
    }

    loadIpd6(): AxiosPromise<Ipd6[]> {
        return DataService.getIpd6();
    }

    saveIpd6(entity: Ipd6): AxiosPromise<Ipd6> {
        return DataService.saveIpd6(entity);
    }

    deleteIpd6(id: number): AxiosPromise<Ipd6> {
        return DataService.deleteIpd6(id);
    }

    newIpd6(): Ipd6 {
        return <Ipd6> {
            id: 0, name: "",
            ipdNum: 1, 
            languageId: this.$store.getters.getUser.languageId
        };
    }
    
    itemChanged() {
        this.$store.dispatch('updateCache', BaseDataType.Ipd6);
    }
}