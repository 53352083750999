import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import BaseComponent from '@/components/base-component';
import CostIncomeDetailsEditComponent from '@/components/costincome/costincome-details-edit/costincome-details-edit';
import CostIncomeGetComponent from '@/components/costincome/costincome-get/costincome-get';
import CostIncomeUpdateSelectedComponent from '@/components/costincome/costincome-updated-selected/costincome-update-selected';
import {
    BaseDataType, FiaServerApi$type, PaymentGroupEnum, PaymentStreamType, TypeOfPaymentEnum
} from '@/constants/fia-constants';
import TableSelectExtension from '@/helpers/table-select-extension';
import { CostIncome, PropertyVersion, Transaction, TypeOfPayment, VariableAmount, VariableAmountIncrementStream } from '@/models';
import { CostIncomeListItem } from '@/models/CostIncomeListItem';
import { CopyCostIncomeRequest } from '@/models/interfaces/request/CopyCostIncomeRequest';
import { ValidationError } from '@/models/interfaces/ValidationError';
import dataService from '@/services/data-service';
import validationService from '@/services/validation-service';
import { BusMessage } from '@/models/messages/messages';

enum CostIncomeTemplate {
    Investment = TypeOfPaymentEnum.Investment,
    TenantImprovement = TypeOfPaymentEnum.TenantImprovement,
    LeaseholdFee = TypeOfPaymentEnum.LeaseholdFee
}

@Component({
    components: {
        CostIncomeDetailsEditComponent,
        CostIncomeUpdateSelectedComponent,
        CostIncomeGetComponent
    },
    computed: mapGetters({
        busMessage: "getBusMessage",
        propertyVersion: "getPropertyVersion"
    })
})
export default class CostIncomeEditComponent extends BaseComponent {
    propertyVersion!: PropertyVersion;

    currentPropertyVersion: number = -1;
    costIncomes: CostIncomeListItem[] = [];
    costIncome: CostIncome | null = null;
    selectedCostIncome: CostIncomeListItem | null = null;
    selectedCostIncomes: CostIncomeListItem[] = [];
    loading: boolean = false;
    saving: boolean = false;
    deleting: boolean = false;
    listLoading: boolean = false;
    copying: boolean = false;
    costIncomeCloned: string = "";
    complexErrors: ValidationError[] = [];
    listIsFocused: boolean = false;
    costIncomeTable: any;
    costIncomeTableWrapper: any;
    editMultipleDialogVisible: boolean = false;
    getDialogVisible: boolean = false;
    gettingCostIncome: boolean = false;
    select: TableSelectExtension = new TableSelectExtension(this, 'costIncomesTable');
    costIncomeTemplates: CostIncomeTemplate[] = [
        CostIncomeTemplate.Investment,
        CostIncomeTemplate.TenantImprovement,
        CostIncomeTemplate.LeaseholdFee
    ];
    editMode: string = "1"; // Empty string for collapsed and "1" for expanded
    activeTab: string = "CostIncome";
    busMessage!: BusMessage<any>;

    @Prop()
    reload!: boolean;

    @Watch("reload")
    reloadWatcher() {
        if (this.reload) {
            if (this.currentPropertyVersion != this.propertyVersion!.id) {
                this.generateCostIncomeList();
            }
            if (this.costIncomeTable) {
                this.costIncomeTable.doLayout();
            }
        }
    }

    @Watch("propertyVersion") propertyVersionWatcher() {
        if (this.currentPropertyVersion == this.propertyVersion!.id) return;
        if (this.reload) {
            this.generateCostIncomeList();
        }
    }

    @Watch("costIncome", { deep: true })
    costIncomeWatcher() {
        validationService.validateCostIncome(this.costIncome, this.complexErrors);
    }

    @Watch("complexErrors")
    errorsWatcher() {
        this.$emit("isValid", this.complexErrors.length === 0)
    }

    @Watch("busMessage")
    async busWatcher() {
        switch (this.busMessage.type) {
            case "ReloadCostincomeListMessage":
                this.generateCostIncomeList();
                break;
        }
    }

    generateCostIncomeList() {
        this.setCostIncome(null);
        this.selectedCostIncome = null;
        this.selectedCostIncomes = [];
        this.updateCostIncomeList();
        this.complexErrors = [];
        this.scrollRowIntoView("el-table__row");
        this.currentPropertyVersion = this.propertyVersion!.id;
    }

    mounted() {
        this.costIncomeTable = (<any>this.$refs).costIncomesTable;
        this.costIncomeTableWrapper = (<any>this.$refs).costIncomesTableWrapper;
    }

    async updateCostIncomeList() {
        if (this.propertyVersion) {
            try {
                this.listLoading = true;
                this.costIncomes = await dataService.getCostIncomeList(this.propertyVersion.id).then(x => x.data);
            } catch (error) {
                return Promise.reject(new Error(""));
            }
            finally {
                this.listLoading = false;
            }
        }
        else {
            this.costIncomes = [];
        }
    }

    resetSelectedRow() {
        this.$nextTick(() => {
            this.costIncomeTable.setCurrentRow(this.selectedCostIncome);
        })
    }

    async costIncomeRowClick(item: CostIncomeListItem) {
        if (!this.selectedCostIncome || item.id !== this.selectedCostIncome.id) {
            if (this.loading) {
                this.resetSelectedRow();
                return;
            }
        }

        if (item != this.selectedCostIncome) {
            if (this.costIncomeDirty()) {
                if (!(await this.discardCurrentChanges())) {
                    this.resetSelectedRow();
                    return;
                }
            }

            this.selectedCostIncome = item;
            await this.loadCostIncome(item.id);
        }
    }
    
    async discardCurrentChanges(): Promise<boolean> {
        try {
            await this.confirm("ConfirmDiscardChanges");
            return true;
        } catch (error) {
            return false;
        }
    }

    async loadCostIncome(id: number) {
        try {
            this.loading = true;
            const costIncome = await dataService.getCostIncome(id).then(x => x.data);
            this.setCostIncome(costIncome);
        } catch (error) {
            return Promise.reject(new Error(""));
        } finally {
            this.loading = false;
        }
    }

    setCostIncome(costIncome: CostIncome | null) {
        this.costIncome = costIncome;
        this.costIncomeCloned = costIncome ? JSON.stringify(this.costIncome) : "";
    }

    selection(items: CostIncomeListItem[], item: CostIncomeListItem) {
        this.select.select(items, item);
        this.selectedCostIncomes = items;
    }

    costIncomeDirty(): boolean {
        return this.costIncome !== null && JSON.stringify(this.costIncome) !== this.costIncomeCloned;
    }

    undo() {
        if (this.costIncomeIsNew()) {
            this.setCostIncome(null);
            return;
        }
        if (this.costIncomeDirty()) {
            this.confirm('ConfirmUndoChanges').then(() => {
                this.costIncome = JSON.parse(this.costIncomeCloned);
            }).catch(() => { });
        }
    }

    async add(template?: CostIncomeTemplate) {
        if (this.costIncomeDirty() && !(await this.discardCurrentChanges())) {
            return;
        }

        let newCostIncome: CostIncome | null = null;
        if (template) {
            const currentDate = new Date();
            switch (template) {
                case CostIncomeTemplate.Investment:
                    newCostIncome = {
                        id: 0,
                        description: this.translate("CostIncomeShortcutInvestments"),// "Investments",
                        recoveryId: 0,
                        transaction: <Transaction>{
                            id: 0,
                            propertyVersionId: this.propertyVersion.id,
                            comment: "",
                            fromSink: false,
                            changedDate: "",
                            typeOfPaymentId: TypeOfPaymentEnum.Investment,
                        },
                        paymentStreams: [<VariableAmountIncrementStream>{
                            $type: FiaServerApi$type.VariableAmountIncrementStreamDto,
                            comment: "",
                            endDate: this.monthEnd(new Date(currentDate.getFullYear(), 11, 31)),
                            id: 0,
                            inAdvance: true,
                            paymentFrequency: 3,
                            startDate: this.monthStart(new Date(currentDate.getFullYear(), 0, 1)),
                            typeOfPaymentStreamId: PaymentStreamType.VariableAmountIncrementStream,
                            variableAmounts: [<VariableAmount>{
                                id: 0,
                                amount: 0,
                                startDate: this.monthStart(new Date(currentDate.getFullYear(), 0, 1))
                            }],
                        }]
                    }
                    break;
                case CostIncomeTemplate.TenantImprovement:
                    newCostIncome = {
                        id: 0,
                        description: this.translate("CostIncomeShortcutTenantImprovement"),
                        recoveryId: 0,
                        transaction: <Transaction>{
                            id: 0,
                            propertyVersionId: this.propertyVersion.id,
                            comment: "",
                            fromSink: false,
                            changedDate: "",
                            typeOfPaymentId: TypeOfPaymentEnum.TenantImprovement,
                        },
                        paymentStreams: [<VariableAmountIncrementStream>{
                            $type: FiaServerApi$type.VariableAmountIncrementStreamDto,
                            comment: "",
                            endDate: this.monthEnd(new Date(this.propertyVersion.calculationEnd)),
                            id: 0,
                            inAdvance: true,
                            paymentFrequency: 3,
                            startDate: this.monthStart(new Date(this.propertyVersion.calculationStart)),
                            typeOfPaymentStreamId: PaymentStreamType.VariableAmountIncrementStream,
                            variableAmounts: [<VariableAmount>{
                                id: 0,
                                amount: 0,
                                startDate: this.monthStart(new Date(this.propertyVersion.calculationStart))
                            }],
                        }]
                    }
                    break;
                case CostIncomeTemplate.LeaseholdFee:
                    const startDate = this.monthStart(new Date(this.propertyVersion.calculationValue ? this.propertyVersion.calculationValue.startDate : this.propertyVersion.calculationStart));
                    const endDate = this.monthEnd(new Date(this.propertyVersion.calculationValue ? this.propertyVersion.calculationValue.endDate : this.propertyVersion.calculationEnd));
                    newCostIncome = {
                        id: 0,
                        description: this.translate("CostIncomeShortcutLeaseholdFee"),
                        recoveryId: 0,
                        transaction: <Transaction>{
                            id: 0,
                            propertyVersionId: this.propertyVersion.id,
                            comment: "",
                            fromSink: false,
                            changedDate: "",
                            typeOfPaymentId: TypeOfPaymentEnum.LeaseholdFee,
                        },
                        paymentStreams: [<VariableAmountIncrementStream>{
                            $type: FiaServerApi$type.VariableAmountIncrementStreamDto,
                            comment: "",
                            endDate: endDate,
                            id: 0,
                            inAdvance: true,
                            paymentFrequency: 3,
                            startDate: startDate,
                            typeOfPaymentStreamId: PaymentStreamType.VariableAmountIncrementStream,
                            variableAmounts: [<VariableAmount>{
                                id: 0,
                                amount: 0,
                                startDate: startDate
                            }],
                        }]
                    }
                    break;
            }
        } else {
            newCostIncome = this.createCostIncome();
        }

        this.selectedCostIncome = null;
        this.resetSelectedRow();
        this.activeTab = "CostIncome";
        this.setCostIncome(newCostIncome);
    }

    async copy() {
        if (!this.selectedCostIncome) return;

        const request = <CopyCostIncomeRequest>{ costIncomeIds: [this.selectedCostIncome.id], propertyVersionId: this.propertyVersion.id };
        this.copying = true;
        try {
            const newId = await dataService.copyCostIncome(request).then(x => x.data);
            await this.updateCostIncomeList();
            if (newId !== 0) {
                this.selectListItem(newId);
                await this.loadCostIncome(newId);
            }
        } catch (error) {
            return Promise.reject(new Error(""));
        } finally {
            this.copying = false;
        }
    }

    async remove() {
        this.confirm('ConfirmRemoveCostIncomes').then(async () => {
            this.deleting = true;
            const ids = this.selectedCostIncomes.length > 0 ? this.selectedCostIncomes.map(x => x.id) : [this.selectedCostIncome!.id];
          
            try {
                await dataService.deleteCostIncomes(ids);
                for (let id of ids) {
                    let ix = this.costIncomes.findIndex(c => c.id === id);
                    if (ix !== -1) {
                        this.costIncomes.splice(ix, 1);
                    }
                }
                this.setCostIncome(null);
                this.selectedCostIncome = null;
                this.selectedCostIncomes = [];
            } catch (error) {
                return Promise.reject(new Error(""));
            } finally {
                this.deleting = false;
            }
        }).catch(() => { });
    }
    
    async save() {
        if (!this.costIncome) return;

        try {
            this.saving = true;
            const costIncome = await dataService.saveCostIncome(this.costIncome).then(x => x.data);
            await this.updateCostIncomeList();
            this.selectListItem(costIncome.id);
            this.setCostIncome(costIncome);
        } catch (error) {
            return Promise.reject(new Error(""));
        } finally {
            this.saving = false;
        }
    }
    
    // Updates the list item values with the saved cost income values
    // updateListItemFromCostIncome(costIncome: CostIncome) {
    //     if (!this.selectedCostIncome) return;

    //     const firstStream = costIncome.paymentStreams[0];
    //     let percentageStream: PercentageIncrementStream | null = null;
    //     let indexStream: IndexIncrementStream | null = null;
    //     if (utils.isPercentageIncrementStreamType(firstStream.typeOfPaymentStreamId)) {
    //         percentageStream = firstStream as PercentageIncrementStream;
    //     }

    //     if (firstStream.typeOfPaymentStreamId === PaymentStreamType.IndexIncrementStream) {
    //         indexStream = firstStream as IndexIncrementStream;
    //     }

    //     this.selectedCostIncome.description = costIncome.description;
    //     this.selectedCostIncome.typeOfPaymentName = this.translateBaseData(costIncome.transaction.typeOfPaymentId, BaseDataType.TypeOfPayments);
    //     this.selectedCostIncome.endDate = firstStream.endDate;
    //     this.selectedCostIncome.firstIncrement = percentageStream ? percentageStream.firstIncrement : null;
    //     this.selectedCostIncome.monthOfChange = percentageStream ? percentageStream.monthOfChange.toString() : null;
    //     this.selectedCostIncome.paymentFrequency = firstStream.paymentFrequency;
    //     this.selectedCostIncome.inAdvance = firstStream.inAdvance;
    //     this.selectedCostIncome.startAmount = percentageStream ? percentageStream.startAmount : null;
    //     this.selectedCostIncome.baseNumber = indexStream ? indexStream.baseNumber : null;
    //     this.selectedCostIncome.indexPercentage = indexStream ? indexStream.indexPercentage : null;
    // }

    selectListItem(costIncomeId: number) {
        let item = this.costIncomes.find(x => x.id === costIncomeId);

        if (item) {
            this.selectedCostIncome = item;
            this.$nextTick(() => {
                this.costIncomeTable.setCurrentRow(this.selectedCostIncome);
                this.scrollRowIntoView("current-row");
            })
        }
    }

    createCostIncome(): CostIncome {
        const tp = (<TypeOfPayment[]>this.$store.getters.getTypeOfPayments).find(x => !x.inactive && x.paymentGroupId === PaymentGroupEnum.Costs);
        return <CostIncome>{
            id: 0,
            description: "",
            recoveryId: 0,
            transaction: <Transaction>{
                id: 0,
                propertyVersionId: this.propertyVersion.id,
                comment: "",
                fromSink: false,
                changedDate: "",
                typeOfPaymentId: tp!.id,
            },
            paymentStreams: []
        }
    }

    listKeyUp(event: any) {
        if (this.listIsFocused && (event.which === 40 || event.which == 38)) {
            let d = (event.which == 40) ? "down" : "up";
            this.nextCostIncome(d);
            event.preventDefault();
            return false;
        }
    }

    updateListFocus(focus: boolean) {
        this.listIsFocused = focus;
    }

    // Select the next item in the list. 
    // NOTE: take sorting into account (use the tableData array on the data table component)
    async nextCostIncome(direction: string) {
        if (!this.selectedCostIncome) return;
        let currentIx = this.costIncomeTable.tableData.findIndex((x: CostIncomeListItem) => x.id === this.selectedCostIncome!.id);
        if (currentIx === -1 ||
            direction === "up" && currentIx === 0 ||
            direction === "down" && currentIx === (this.costIncomes.length - 1)) {
            return;
        };

        let newCostIncomeListItem = this.costIncomeTable.tableData[currentIx + (direction === "up" ? -1 : 1)];
        this.costIncomeTable.setCurrentRow(newCostIncomeListItem);
        await this.costIncomeRowClick(newCostIncomeListItem);
        this.scrollRowIntoView("current-row");
    }

    scrollRowIntoView(className: string) {
        this.$nextTick(() => {
            // TODO: first check if the item is in view?
            let row = this.costIncomeTable.$el.querySelector("." + className);
            if (row) row.scrollIntoView(false);
        });
    }

    costIncomeIsNew(): boolean {
        return this.costIncome !== null && this.costIncome.id === 0;
    }

    costIncomeValid(): boolean {
        return this.complexErrors.length === 0;
    }

    costIncomeActive(): boolean {
        return this.costIncome !== null;
    }

    canCopy() {
        return this.costIncomeActive() && !this.copying && !this.versionLocked() && !this.costIncomeIsNew();
    }

    canEditMultiple() {
        return this.selectedCostIncomes.length > 0 && !this.versionLocked();
    }

    canImportGroundLeases() {
        return !this.versionLocked();
    }

    canGet() {
        return !this.versionLocked();
    }

    canAdd() {
        return this.propertyVersion !== null && !this.versionLocked() && !this.costIncomeIsNew();
    }

    canDelete(): boolean {
        return ((this.costIncomeActive() && !this.costIncomeIsNew()) || this.selectedCostIncomes.length > 0) && !this.versionLocked();
    }

    canUndo() {
        return this.costIncomeActive() && !this.versionLocked();
    }

    canSave() {
        return this.costIncomeActive() && this.costIncomeValid() && !this.versionLocked();
    }

    getCurrencyExtension() {
        var currency = this.translateBaseData(this.propertyVersion.currencyId, this.baseDataType.Currencies);
        return "(" + currency + "/m²)";
    }

    editMultiple() {
        this.editMultipleDialogVisible = true;
    }

    async editMultipleDialogClosed(canceled: boolean) {
        if (!canceled) {
            await this.updateCostIncomeList();
            this.selectedCostIncomes = [];

            if (this.costIncome) {
                this.selectListItem(this.costIncome.id);
                await this.loadCostIncome(this.costIncome.id);
            }
            else {
                this.setCostIncome(null);
            }
        }
    }
    
    getCostIncomes() {
        this.getDialogVisible = true;
    }

    async getDialogClosed(canceled: boolean, selected: any) {
        if (!canceled && this.propertyVersion) {
            const request = <CopyCostIncomeRequest>{ costIncomeIds: selected.costIncomes, propertyVersionId: this.propertyVersion.id };
            try {
                this.gettingCostIncome = true;
                const newCostIncomeId = await dataService.copyCostIncome(request).then(x => x.data);
                await this.updateCostIncomeList();
                if (newCostIncomeId !== 0) {
                    this.selectListItem(newCostIncomeId);
                    await this.loadCostIncome(newCostIncomeId);
                }
            } catch (error) {
                return Promise.reject(new Error(""));
            } finally {
                this.gettingCostIncome = false;
            }
        }
    }

    formatStartAmount(row: CostIncomeListItem, value: number) {
        var formattedAmount = this.formatNumber(value, 0);
        if (value && row && row.squaremeterPrice) {
            var formattedSquarePrice = this.decimals(row.squaremeterPrice, 0);
            var formattedAreaStr = formattedAmount + " (" + formattedSquarePrice + ")";
            return formattedAreaStr;
        }
        return formattedAmount;
    }

    async importGroundLeases() {
        const lease = await dataService.getGroundLeases(this.propertyVersion.propertyId).then(res => res.data);
        const costincome = this.createCostIncome();
        costincome.description = this.translate('LandLease');
        costincome.transaction.typeOfPaymentId = TypeOfPaymentEnum.LeaseholdFee;

        const stream = <VariableAmountIncrementStream>{
            id: 0,
            $type: FiaServerApi$type.VariableAmountIncrementStreamDto,
            typeOfPaymentStreamId: PaymentStreamType.VariableAmountIncrementStream,
            comment: "",
            inAdvance: true,
            paymentFrequency: 3,
            startDate: this.propertyVersion.calculationStart,
            endDate: null,
        };
        stream.variableAmounts = [];
        stream.variableAmounts.push(<VariableAmount>{
            id: 0,
            startDate: lease.dayOfLetting ? lease.dayOfLetting : this.monthStart(new Date(this.propertyVersion.calculationStart)),
            amount: lease.instalment ? lease.instalment : 0
        });
        costincome.paymentStreams.push(stream);

        this.selectedCostIncome = null;
        this.setCostIncome(costincome);
    }

    translateCommand(value: CostIncomeTemplate) {
        return this.translateBaseData(value, BaseDataType.TypeOfPayments);
    }
}