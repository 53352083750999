import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { formatDecimalNumber, isNumeric, reverseFormatNumber, round } from '@/helpers/numbers';
import { ValidationError } from '@/models/interfaces/ValidationError';

@Component({})
export default class InputNumericComponent extends Vue {
    @Prop()
    value!: any;
    @Prop({ default: 0, type: Number, required: false })
    decimals!: number;
    @Prop({ default: 0, type: Number, required: false })
    minDecimals!: number;
    @Prop()
    inputClass!: string;
    @Prop()
    disabled!: boolean;
    @Prop()
    placeholder!: string;
    @Prop({ default: "" })
    inputStyle!: string;
    @Prop()
    align!: string;
    @Prop()
    name!: string;
    @Prop({ default: false, type: Boolean, required: false })
    percentage!: boolean;
    @Prop({ default: false, type: Boolean, required: false })
    invalidToZero!: boolean;
    @Prop({ default: () => [], required: false })
    complexError!: ValidationError[];
    @Prop()
    paste!: (e:ClipboardEvent) => void;
    @Prop()
    tabPressed!: (e:KeyboardEvent) => void;

    propertyString: string = "";
    inputElement!: HTMLElement;

    @Watch("value") propertyWatcher(value: any) {
        this.setValue(value);
    }

    mounted() {
        this.setValue(this.value);
        this.inputElement = (<any>this.$refs["elinput"]).$el.children[0];
  
        this.inputElement.setAttribute("style", (this.align == "right" ? "text-align:right;" : '') + this.inputStyle);
    }

    setValue(value: any) {
        let minDec = this.percentage && this.minDecimals === 0 ? this.decimals : this.minDecimals;
        this.propertyString = (value == null || !isNumeric(value)) ? value : formatDecimalNumber(value, this.$i18n.locale()!, this.decimals, minDec, this.percentage);
    }

    change(value: any) {
        let tmpVal = reverseFormatNumber(value, this.$i18n.locale()!, this.$store.getters.getUser.languageId);

        if (this.invalidToZero && (tmpVal === "" || !isNumeric(tmpVal))) {
            tmpVal = "0";
            this.propertyString = "0";
        }

        if (tmpVal === "") {
            this.$emit("input", null);
            this.$emit("change", null);
        }
        else if (!isNumeric(tmpVal)) {
            this.$emit("input", value);
            this.$emit("change", value);
        }
        else {
            tmpVal = round(+tmpVal, this.decimals);

            if (this.percentage)
                tmpVal = tmpVal * 0.01;

            this.$emit("input", tmpVal);
            this.$emit("change", tmpVal);
        }

        this.setValue(tmpVal);
    }

    focus(event: any) {
        this.$emit("focus", event);
    }

    blur(event: any) {
        this.$emit("blur", event);
    }

    errorText(): string {
        return this.hasErrors() ? this.complexError.map(x => "- " + x.error).join('\r\n') : "";
    }

    hasErrors(): boolean {
        return this.complexError.length > 0;
    }

    highlightField(): void {
        const element = (<Vue>this.$refs.elinput).$el;
        for(let i = 0; i < element.children.length; i++){
            element.children[i].classList.add('flashit');
            setTimeout(() => {
                element.children[i].classList.remove('flashit');
            }, 2000)
        }
    }

    onPaste(event: ClipboardEvent) {
        if (this.paste) {
            this.paste(event);
        }
    }

    onTabPressed(e:any) {
        if (this.tabPressed) {
            this.tabPressed(e);
        }
    }
}
