import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import PrognosisComponent from '@/components/base-data/prognosis/prognosis';
import CalculationComponent from '@/components/calculation/calculation';
import MapComponent from '@/components/map/map';
import PortfolioEditComponent from '@/components/portfolio/portfolio-edit/portfolio-edit';
import PortfolioSearchComponent from '@/components/portfolio/portfoliosearch/portfoliosearch';
import ContractSearchComponent from '@/components/contract/contract-search/contract-search';
import PropertyEditComponent from '@/components/property/property-edit/property-edit';
import PropertyVersionComponent from '@/components/property/property-version/property-version';
import PropertySearchComponent from '@/components/property/propertysearch/propertysearch';
import ValuationsComponent from '@/components/valuations/valuations';
import VersionDetailsComponent from '@/components/versiondetails/versiondetails';
import WebReportsComponent from '@/components/web-reports/web-reports';
import { AppRightEnum, SearchTab } from '@/constants/fia-constants';
import { PropertyVersion, User, Portfolio, Property } from '@/models';
import { BusMessage, SetTabMessage, VersionLoadedMessage } from '@/models/messages/messages';
import dataService from '@/services/data-service';
import VersionHeaderComponent from '@/components/version-header/version-header';
import PortfolioHeaderComponent from '@/components/calculation/portfolio-header/portfolio-header';
import ContractSearchResultsComponent from '@/components/contract/contract-search-results/contract-search-results';
import { ContractSearchResponse } from "@/models/interfaces/response/ContractSearchResponse";

@Component({
    components: {
        PropertySearchComponent,
        PortfolioSearchComponent,
        ContractSearchComponent,
        CalculationComponent,
        ValuationsComponent,
        VersionDetailsComponent,
        PortfolioEditComponent,
        PropertyEditComponent,
        MapComponent,
        PropertyVersionComponent,
        WebReportsComponent,
        PrognosisComponent,
        VersionHeaderComponent,
        PortfolioHeaderComponent,
        ContractSearchResultsComponent
    },
    computed: mapGetters({
        propertyVersion: "getPropertyVersion",
        propertyVersionLoading: "getPropertyVersionLoading",
        propertyVersionActive: "getPropertyVersionActive",
        _tabLevel1: "getTabLevel1",
        busMessage: "getBusMessage",
        user: "getUser",
        portfolio: 'getPortfolio'
    }),
    name: "Fia"
})
export default class FiaComponent extends Vue {
    propertyVersion!: PropertyVersion;
    propertyVersionLoading!: boolean;
    propertyVersionActive!: boolean;
    _tabLevel1!: string;
    busMessage!: BusMessage<any>;
    user!: User;
    portfolio!: Portfolio;

    layout: string = "";
    searchVisible: boolean = true;
    searchVisibleTimer: any = null;
    searchDocked: boolean = true;
    mainTab: string = "start";
    tabLevel2: string = "contract";
    activeSearchTab: SearchTab = SearchTab.PropertySearch;
    reportsInProgress: number = 0;
    // used to remember tab when switching between propertyVersion and portfolio
    previousTabLevel1: string = 'portfolio';
    reloadTab: string = "";
    contractSearchActive: boolean = false;
    contractSearchResponse: ContractSearchResponse = { result: [], moreRows: false, maxRows: 0 }

    get tabLevel1(): string {
        return this._tabLevel1;
    }
    set tabLevel1(value: string) {
        if (value !== "0")
            this.$store.dispatch("setTabLevel1", value);
    }

    @Watch('activeSearchTab') 
    activeSearchTabWatcher() {
        this.onSearchTabChange();
    }

    @Watch("busMessage")
    async busWatcher() {
        switch (this.busMessage.type) {
            case "VersionLoaded":
                const versionLoadedMsg: VersionLoadedMessage = this.busMessage.data;
                if (versionLoadedMsg.source === "portfoliosearch") {
                    this.activeSearchTab = SearchTab.PropertySearch;
                }
                break;   
            case "SetSearchTabMessage":
                const setTabMsg: SetTabMessage = this.busMessage.data;
                this.activeSearchTab = setTabMsg.tab == SearchTab.PropertySearch || SearchTab.PortfolioSearch ? setTabMsg.tab : SearchTab.PropertySearch;
                break;
        }
    }

    mounted() {
        const userHideSetting = this.user.userSettings.propertySearchHidden;
        const hideSearch = userHideSetting != null ? userHideSetting : false;
        this.searchVisible = !hideSearch;
        this.searchDocked = !hideSearch;
        
    }
    inProgress(count: number) {
        this.reportsInProgress = count;
    }

    baseDataEnabled(): boolean {
        return this.$store.getters.getAppPermission(AppRightEnum.Fia_Basedata_Read);
    }

    prognosisEnabled(): boolean {
        return this.$store.getters.getAppPermission(AppRightEnum.Fia_Prognosis);
    }

    valuationsEnabled(): boolean {
        return this.$store.getters.getAppPermission(AppRightEnum.VDok);
    }

    mapEnabled(): boolean {
        return this.$store.getters.getAppPermission(AppRightEnum.Fia_Map);
    }

    searchVisibleOrDocked(): boolean {
        return this.searchVisible || this.searchDocked;
    }

    invertSearchDocked(): void {
        const invertedDockStatus = !this.searchDocked;
        this.searchDocked = invertedDockStatus;
        this.user.userSettings.propertySearchHidden = !invertedDockStatus;
        dataService.saveUserSettings(this.user.userSettings);
    }

    // editProperty() {
    //     this.mainTab = "properties";
    //     this.$store.dispatch("sendBusMessage", <BusMessage<EditPropertyMessage>>{ type: "EditProperty", data: { propertyId: this.propertyVersion.propertyId } });
    // }

    canEditProperty(): boolean {
        return this.propertyVersionActive;
    }

    changeTabLevel1(tabName: string) {
        this.tabLevel1 = tabName;
    }

    setSearchVisible(visible: boolean) {
        if (this.searchDocked) return;
        if (this.searchVisibleTimer != null)  {
            clearTimeout(this.searchVisibleTimer);
            this.searchVisibleTimer = null;
        }
        
        this.searchVisibleTimer = setTimeout(() => {
            this.searchVisible = visible;
        }, 130);
    }

    onSearchTabChange() {
        let currentTabLevel1 = this.tabLevel1;
        this.tabLevel1 = this.previousTabLevel1;
        this.previousTabLevel1 = currentTabLevel1;
    }

    onContractSearchStarted() {
        this.contractSearchActive = true;
    }

    onContractSearchCompleted(response: ContractSearchResponse) {
        this.contractSearchActive = false;
        this.contractSearchResponse = response;
    }

    onPropertyDeleted(property: Property) {
        let propertySearchComponent = this.$refs.propertySearch;
        if (propertySearchComponent) {
            (<PropertySearchComponent> propertySearchComponent).removeFromSearchList(property);
        }
    }
}
