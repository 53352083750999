import BaseComponent from "@/components/base-component";
import { Component, Watch, Prop } from "vue-property-decorator";
import dataService from "@/services/data-service";
import { Property, PropertyDetail, PropertyVersion, PropertyDetailsExtraFieldType } from "@/models";
import GeneralComponent from '@/components/property/property-edit/general/general';
import SharesComponent from '@/components/property/property-edit/shares/shares';
import RightsComponent from '@/components/property/property-edit/rights/rights';
import ConstructionPlanComponent from '@/components/property/property-edit/constructionplan/constructionplan';
import LandleaseComponent from '@/components/property/property-edit/landlease/landlease';
import TaxInfoComponent from '@/components/property/property-edit/taxinfo/taxinfo';
import AssumptionsComponent from '@/components/property/property-edit/assumptions/assumptions';
import PropertyDataImportComponent from '@/components/property/property-edit/import/property-data-import';
import ImagesComponent from '@/components/property/property-edit/images/images';
import { ValidationError } from '@/models/interfaces/ValidationError';
import validationService from '@/services/validation-service';
import InputTextComponent from '@/components/common/input-text/input-text';
import { mapGetters } from 'vuex';
import { BusMessage } from '@/models/messages/messages';
import { CountriesEnum, AppRightEnum } from '@/constants/fia-constants';
import LocationFormComponent from '@/components/property/property-edit/location-form/location-form';
import { ElLoadingComponent } from 'element-ui/types/loading';
import PropertyDetailsExtraFieldComponent from './property-edit-extra-field/property-edit-extra-field';
import ESGComponent from "@/components/property/property-edit/esg/esg";
import EnvironmentalCertificatesComponent from "./environmental-certificates/environmental-certificates";

@Component({
    components: {
        GeneralComponent,
        SharesComponent,
        RightsComponent,
        ConstructionPlanComponent,
        LandleaseComponent,
        TaxInfoComponent,
        AssumptionsComponent,
        ImagesComponent,
        InputTextComponent,
        PropertyDataImportComponent,
        LocationFormComponent,
        PropertyDetailsExtraFieldComponent,
        ESGComponent,
        EnvironmentalCertificatesComponent,
    },
    computed: mapGetters({
        busMessage: "getBusMessage",
        propertyId: "getPropertyId",
        extraFieldTypes: "getPropertyDetailsExtraFieldTypes"
    })
})
export default class PropertyEditComponent extends BaseComponent {
    busMessage!: BusMessage<any>;
    propertyId!: number;
    extraFieldTypes!: PropertyDetailsExtraFieldType[];

    selectedProperty: Property | null = null;

    activeTab: string = "property"
    propertyLoading: boolean = false;
    complexErrors: ValidationError[] = [];
    propertySaving: boolean = false;
    newRebuiltYears: string[] = [];
    removing: boolean = false;
    layout: string = "";
    loadingInstance: ElLoadingComponent | null = null;

    @Prop()
    active!: string

    @Watch("active")
    activeWatcher() {
        if (this.active == "property" && this.propertyId && (!this.selectedProperty || this.selectedProperty.id != this.propertyId)) {
            // (<any>this.$refs.search).$el.children[0].focus();
            this.loadProperty(this.propertyId);
        }
    }

    @Watch("propertyId")
    propertyIdWatcher() {
        if (this.active == "property" && this.propertyId && (!this.selectedProperty || this.selectedProperty.id != this.propertyId)) {
            this.loadProperty(this.propertyId);
            validationService.validateProperty(this.selectedProperty, this.complexErrors);
        }
    }

    @Watch("selectedProperty", { deep: true })
    propertyWatcher() {
        validationService.validateProperty(this.selectedProperty, this.complexErrors);
    }

    @Watch("removing")
    removingWatcher() {
        if (this.removing) {
            this.loadingInstance = this.$loading({ fullscreen: true });
        }
        else {
            if (this.loadingInstance != null) {
                this.loadingInstance!.close();
            }
        }
    }

    async mounted() {
        // if (process.env.VUE_APP_MODE === "dev") {
        //     this.searchTextName = "Gurkan";
        //     this.search();
        // }
    }

    async loadProperty(id: number) {
        try {
            this.propertyLoading = true;
            this.selectedProperty = await dataService.getProperty(id, true).then(x => {
                return x.data
            });
        } catch (error) {
            return Promise.reject(new Error(""));
        } finally {
            this.propertyLoading = false;
        }
    }

    save() {
        if (!this.selectedProperty) return;

        this.fixRebuiltYears(this.selectedProperty.propertyDetail);

        this.propertySaving = true;
        dataService.saveProperty(this.selectedProperty).then(async x => {
            this.selectedProperty = x.data;
            // await this.updateLocation();

            // If this is the same property as the active property version, reload the version (updates build rights etc.)
            let version: PropertyVersion = this.$store.getters.getPropertyVersion;
            if (version && this.selectedProperty && this.selectedProperty.id === version.propertyId) {
                await this.$store.dispatch("loadPropertyVersion", { id: version.id, source: "propertyedit" });
            }
        }).catch(e => {
            this.$notify.error({
                title: this.translate('ErrorUnexpected'),
                message: this.translate('ErrorActionFailed')
            });
        }).finally(() => {
            this.propertySaving = false;
        })
    }

    fixRebuiltYears(detail: PropertyDetail) {
        // Remove deleted years
        detail.propertyRebuiltYears = detail.propertyRebuiltYears.filter(x => this.newRebuiltYears.findIndex(y => y === this.formatYear(x.rebuiltYear)) !== -1);

        // Add new years
        for (let y of this.newRebuiltYears) {
            let existing = detail.propertyRebuiltYears.find(x => this.formatYear(x.rebuiltYear) === y);
            if (!existing) {
                detail.propertyRebuiltYears.push({ id: this.getNewId(detail.propertyRebuiltYears), propertyDetailId: detail.id, rebuiltYear: this.formatIsoDate(y + "-01-01") });
            }
        }
    }

    undo() {
        if (!this.selectedProperty) return;

        if (this.selectedProperty.id === 0) {
            this.selectedProperty = null;
        }
        else {
            this.loadProperty(this.selectedProperty.id);
        }
    }

    remove() {
        this.confirm('ConfirmRemoveProperty', "Confirm", "Ok", "Avbryt", "warning", [this.selectedProperty!.propertyName]).then(async () => {
            try {
                this.removing = true;
                await dataService.deleteProperty(this.selectedProperty!.id);
                this.$store.dispatch('setPropertyVersion', null);
                this.$emit('onPropertyDeleted', this.selectedProperty);
                this.selectedProperty = null;
            } catch (error) {
                return Promise.reject(new Error(""));
            } finally {
                this.removing = false;
            }
        }).catch(() => {
            return;
        });
    }

    isValid(): boolean {
        return this.complexErrors.length === 0;
    }

    isNew(): boolean {
        return this.selectedProperty !== null && this.selectedProperty.id === 0;
    }

    canUndo(): boolean {
        return !this.propertyLoading && !this.propertySaving;
    }

    canSave(): boolean {
        return this.isValid() && !this.propertyLoading && !this.propertySaving;
    }

    canRemove(): boolean {
        return !this.isNew();
    }

    rebuiltYearsChanged(years: string[]) {
        this.newRebuiltYears = years;
    }

    isValidTab(tab: string): boolean {
        let errors = this.complexErrors.filter(y => y.key.startsWith(tab + "."));
        if (errors.length > 0) {
            switch (tab) {
                case "property":
                    return true;
                case "general":
                    return true;
                case "shares":
                    return true;
                case "rights":
                    return true;
                case "groundLeases":
                    return true;
                case "builds":
                    return true;
                case "taxes":
                    return true;
                default:
                    return false;
            }
        }
        return false;
    }

    importEnabled() {
        return  this.selectedProperty && 
                this.selectedProperty.id !== 0 &&
                this.selectedProperty.viewCountryId === CountriesEnum.Sweden && 
                this.$store.getters.getAppPermission(AppRightEnum.Fia_Metria);
    }

    isLinked(): boolean {
        return !!this.selectedProperty && this.selectedProperty.propertyImportId !== null;
    }

    // locationComponent() {
    //     return (<LocationFormComponent> this.$refs.locationForm);
    // }
}