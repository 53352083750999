import BaseComponent from "@/components/base-component";
import DataService from "@/services/data-service";
import { Component, Prop, Watch } from "vue-property-decorator";
import InlineEdit from "@/helpers/inline-edit";
import { AxiosPromise } from "axios";
import { PremisesTypeGroup } from "@/models/PremisesTypeGroup";
import { BaseDataType } from '@/constants/fia-constants';
import InputTextComponent from '@/components/common/input-text/input-text';
import ValidationTableFeedbackComponent from '@/components/common/validation-table-feedback/validation-table-feedback';

@Component({
    components: {
        InputTextComponent,
        ValidationTableFeedbackComponent
    }
})
export default class PremisesGroupsComponent extends BaseComponent {
    premisesGroupsGrid: InlineEdit<PremisesTypeGroup>;
    premisesGroupsTable: string;

    @Prop()
    reload!: boolean;
    @Prop()
    readOnly!: boolean;
    constructor() {
        super();
        this.premisesGroupsTable = "premisesGroupsTable";
        this.premisesGroupsGrid = new InlineEdit<PremisesTypeGroup>(
                this, 
                this.premisesGroupsTable, 
                this.loadPremisesGroups, 
                this.savePremisesGroup, 
                this.deletePremisesGroup, 
                this.newPremisesGroup,
                undefined,
                this.hasNoErrors);
        this.premisesGroupsGrid.readOnly = this.readOnly;
        this.premisesGroupsGrid.onItemChanged = this.itemChanged;
    }
    mounted() {
        this.premisesGroupsGrid.reload();
    }
    @Watch("reload")
    reloadWatcher() {
        if (this.reload == true) {
            (<any> this).$refs.premisesGroupsTable.doLayout();
        }
    }

    
    loadPremisesGroups(): AxiosPromise<PremisesTypeGroup[]> {
        return DataService.getPremisesTypeGroups();
    }
    savePremisesGroup(entity: PremisesTypeGroup): AxiosPromise<PremisesTypeGroup> {
        return DataService.savePremisesTypeGroup(entity);
    }
    deletePremisesGroup(id: number): AxiosPromise<PremisesTypeGroup> {
        return DataService.deletePremisesTypeGroup(id);
    }
    newPremisesGroup(): PremisesTypeGroup {
        return <PremisesTypeGroup> { 
            id: 0, 
            name: "", 
            languageId: this.$store.getters.getUser.languageId 
        };
    }
    itemChanged() {
        this.$store.dispatch('updateCache', BaseDataType.PremisesTypeGroups);
    }
}