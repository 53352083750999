import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import BaseComponent from '@/components/base-component';
import ContractEditComponent from '@/components/contract/contract-edit/contract-edit';
import CostIncomeEditComponent from '@/components/costincome/costincome-edit/costincome-edit';
import LoanListComponent from '@/components/loan/loan-list/loan-list';
import PropertyTaxComponent from '@/components/property/property-tax/property-tax';
import VersionHeaderComponent from '@/components/version-header/version-header';
import { PropertyVersion } from '@/models';
import { BusMessage, SetTabMessage } from '@/models/messages/messages';

import MarketRentComponent from '@/components/versiondetails/marketrent/marketrent';
import DevelopmentComponent from '@/components/versiondetails/development/development';
import DetailsPortfoliosComponent from '@/components/versiondetails/portfolios/details-portfolios';
import DetailsClassificationComponent from '@/components/versiondetails/classification/details-classification';
import BuildingDevComponent from '@/components/versiondetails/buildingdev/buildingdev';

@Component({
    components: {
        VersionHeaderComponent,
        ContractEditComponent,
        PropertyTaxComponent,
        CostIncomeEditComponent,
        LoanListComponent,

        
        BuildingDevComponent,
        MarketRentComponent,
        DevelopmentComponent,
        DetailsPortfoliosComponent,
        DetailsClassificationComponent,
    },
    computed: mapGetters({
        busMessage: "getBusMessage",
        propertyVersion: "getPropertyVersion"
    })
})
export default class PropertyVersionComponent extends BaseComponent {
    busMessage!: BusMessage<any>;
    propertyVersion!: PropertyVersion;

    contractValid: boolean = true;
    taxesValid: boolean = true;
    costIncomeValid: boolean = true;
    totalArea: number = 0;
    activeTab: string = "contract";

    @Prop()
    active!: string;

    @Watch("busMessage")
    async busWatcher() {
        switch (this.busMessage.type) {
            case "SetPropertyVersionTabMessage":
                let payload = <SetTabMessage> this.busMessage.data;
                if (payload.tab) {
                    this.activeTab = payload.tab;
                }
                break;
        }
    }

    mounted() {
    }

    reloadTab(tabName: string): boolean {
        return this.activeTab === tabName && this.active === 'propertyVersion';
    }
}