import BaseComponent from "@/components/base-component";
import { Component, Watch, Prop } from "vue-property-decorator";
import DataService from "@/services/data-service";
import InlineEdit from "@/helpers/inline-edit";
import { AxiosPromise } from "axios";
import { Country, County, Subcounty, User } from "@/models";
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import { Region } from '@/models/Region';
import dataService from '@/services/data-service';
import { mapGetters } from "vuex";

@Component({
    components: {
        InputNumericComponent
    },
    computed: 
    mapGetters({
        user: "getUser"
    })
})
export default class AreasComponent extends BaseComponent {
    user!: User;
    
    countryGrid: InlineEdit<Country>;
    regionGrid: InlineEdit<Region>;
    countyGrid: InlineEdit<County>;
    subcountyGrid: InlineEdit<Subcounty>;
    selectedType: number = 1;

    @Prop()
    reload!: boolean;
    @Prop()
    readOnly!: boolean;

    @Watch("reload")
    reloadWatcher() {
        if (this.reload == true) {
            (<any> this).$refs.countriesTable.doLayout();
            (<any> this).$refs.regionsTable.doLayout();
            (<any> this).$refs.countiesTable.doLayout();
            (<any> this).$refs.subcountiesTable.doLayout();
        }
    }

    @Watch("countryGrid.currentItem") currentCountryWatcher() {
        if (this.regionGrid)
            this.regionGrid.reload();
    }

    @Watch("regionGrid.currentItem") currentRegionWatcher() {
        if (this.countyGrid)
            this.countyGrid.reload();
    }

    @Watch("countyGrid.currentItem") currentCountyWatcher() {
        if (this.subcountyGrid)
            this.subcountyGrid.reload();
    }

    constructor() {
        super();
        this.countryGrid = new InlineEdit<Country>(this, "countriesTable", this.loadCountries, this.saveCountry, this.deleteCountry, this.newCountry, undefined, this.hasNoErrors);
        this.regionGrid = new InlineEdit<Region>(this, "regionsTable", this.loadRegions, this.saveRegion, this.deleteRegion, this.newRegion, undefined, this.hasNoErrors);
        this.countyGrid = new InlineEdit<County>(this, "countiesTable", this.loadCounties, this.saveCounty, this.deleteCounty, this.newCounty, undefined, this.hasNoErrors);
        this.subcountyGrid = new InlineEdit<Subcounty>(this, "subcountiesTable", this.loadSubcounties, this.saveSubcounty, this.deleteSubcounty, this.newSubcounty, undefined, this.hasNoErrors);
        this.countryGrid.readOnly = this.readOnly;
        this.countyGrid.readOnly = this.readOnly;
        this.subcountyGrid.readOnly = this.readOnly;
    }

    mounted() {
        this.countryGrid.reload();
        this.countyGrid.reload();
        this.subcountyGrid.reload();
    }

    loadCountries(): AxiosPromise<Country[]> {
        return DataService.getCountries();
    }

    saveCountry(entity: Country): AxiosPromise<Country> {
        return DataService.saveCountry(entity);
    }

    deleteCountry(id: number): AxiosPromise<Country> {
        return DataService.deleteCountry(id);
    }

    newCountry(): Country {
        return <Country>{ 
            id: 0, name: "", 
            origId: 0, languageId: this.$store.getters.getUser.languageId 
        };
    }

    currentCountryId(): number | null {
        return (this.countryGrid && this.countryGrid.currentItem) ? this.countryGrid.currentItem.entity.id : null;
    }


    loadRegions(): AxiosPromise<Region[]> {
        return DataService.getRegionsByCountry(this.currentCountryId());
    }

    saveRegion(entity: Region): AxiosPromise<Region> {
        return DataService.saveRegion(entity);
    }

    deleteRegion(id: number): AxiosPromise<Region> {
        return DataService.deleteRegion(id);
    }

    newRegion(): Region {
        return <Region> {
            id: 0,
            name: "",
            countryId: this.currentCountryId(),
            languageId: this.$store.getters.getUser.languageId
        };
    }

    currentRegionId(): number | null {
        return (this.regionGrid && this.regionGrid.currentItem) ? this.regionGrid.currentItem.entity.id : null;
    }


    loadCounties(): AxiosPromise<County[]> {
        return DataService.getCountiesByRegion(this.currentRegionId());
    }

    saveCounty(entity: County): AxiosPromise<County> {
        return DataService.saveCounty(entity);
    }

    deleteCounty(id: number): AxiosPromise<County> {
        return DataService.deleteCounty(id);
    }

    newCounty(): County {
        return <County>{ 
            id: 0, name: "", 
            countryId: this.currentCountryId(),
            regionId: this.currentRegionId(),
            languageId: this.$store.getters.getUser.languageId 
        };
    }

    currentCountyId(): number | null {
        return (this.countyGrid && this.countyGrid.currentItem) ? this.countyGrid.currentItem.entity.id : null;
    }


    loadSubcounties(): AxiosPromise<Subcounty[]> {
        return DataService.getSubcountiesByCountyId(this.currentCountyId());            
    }

    saveSubcounty(entity: Subcounty): AxiosPromise<Subcounty> {
        return DataService.saveSubcounty(entity);
    }

    deleteSubcounty(id: number): AxiosPromise<Subcounty> {
        return DataService.deleteSubcounty(id);
    }

    newSubcounty(): Subcounty {
        return <Subcounty>{ 
            id: 0, name: "", 
            countyId: this.currentCountyId(), 
            languageId: this.$store.getters.getUser.languageId 
        };
    }

    isSysAdmin(): boolean {
        return this.user.userGroup.isSysAdmin;
    }
}
