import BaseComponent from "@/components/base-component";
import { Component, Prop } from "vue-property-decorator";
import { ValidationError } from "@/models/interfaces/ValidationError";

@Component({})
export default class ValidationTableFeedbackComponent extends BaseComponent {
    @Prop()
    output!: any;

    @Prop()
    complexError!: ValidationError[];

    // @Prop({ required: false, default: undefined })
    // formatFunction!: ((value: string) => string) | undefined;

    errorText(): string {
        return this.hasErrors() ? this.complexError.map(x => "- " + x.error).join('\r\n') : "";
    }

    hasErrors(): boolean {
        return this.complexError.length > 0;
    }

    // getValue(): string {
    //     return this.formatFunction ? this.formatFunction(this.output) : this.output;
    // }
}