import BaseComponent from "@/components/base-component";
import { Component } from "vue-property-decorator";
import { PortfolioSearchResult } from "@/models/interfaces/PortfolioSearchResult";
import { mapGetters } from "vuex";
import dataService from '@/services/data-service';

@Component({
    components: {
    },
    computed: mapGetters({
    })
})
export default class PortfolioSelectComponent extends BaseComponent {
    
    loading = false;
    buttonDisabled = false;
    portfolioList: PortfolioSearchResult[] = [];
    selectedPortfolioId: number | null = null;
    dialogVisible: boolean = false;
    propertyVersionList: any[] = [];
    failDialogVisible: boolean = false;

    mounted() {
        
    }

    pickPortfolio(value: any) {
        let i: number = this.portfolioList.map(x => {
            return x.id;
        }).indexOf(value);
        this.$emit('onPortfolioSelected', this.portfolioList[i]);
    }

    searchMethod(query: string) {
        if (query !== '' && query.length >= 3) {
            this.loading = true;
            setTimeout(async () => {
                try {
                    this.portfolioList = await dataService.searchPortfolios(query).then(x => x.data);
                } catch (error) {
                    return Promise.reject(new Error(""));
                } finally {
                    this.loading = false;
                    this.buttonDisabled = false;
                }
            }, 200);
        } else {
            this.portfolioList = [];
            this.buttonDisabled = true;
        }
    }

    async displayVersions() {
        if (!this.selectedPortfolioId) {
            return;
        }
        this.dialogVisible = true;

        try {
            this.propertyVersionList = await dataService.getVersionsByPortfolio(this.selectedPortfolioId).then(x => x.data);
            console.log(this.propertyVersionList);
        } catch (error) {
            return Promise.reject(new Error(""));
        }
    }
}
