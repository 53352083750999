export function getBrowser(): { name: string, version: string } {
    // tslint:disable-next-line:one-variable-per-declaration
    var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident|edge(?=\/))\/?\s*(\d+)(\.\d+)+/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE ', version: (tem[1] || '') };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR\/(\d+)/);
      if (tem != null) {
        return { name: 'Opera', version: tem[1] };
      }
  
      tem = ua.match(/\bEdge\/(\d+(.\d+)+)/i);
      if (tem != null) {
        return { name: 'Microsoft Edge', version: tem[1] };
      }
    }
    M = M[2] ? [M[1], M[0].substring(M[0].indexOf('/')+1)] : [navigator.appName, navigator.appVersion, '-?'];
    // tslint:disable-next-line:no-conditional-assignment
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1]
    };
  }
  