import BaseComponent from "@/components/base-component";
import { Component, Watch } from "vue-property-decorator";
import CurrenciesComponent from '@/components/base-data/currencies/currencies';
import AreasComponent from '@/components/base-data/areas/areas';
import PremisesComponent from '@/components/base-data/premises/premises';
import PremisesGroupsComponent from '@/components/base-data/premises-groups/premises-groups';
import DevelopmentsComponent from '@/components/base-data/developments/developments';
import MarketsComponent from '@/components/base-data/markets/markets';
import PaymentsComponent from '@/components/base-data/payments/payments';
import PrognosisComponent from '@/components/base-data/prognosis/prognosis';
import PrognosisParameterComponent from '@/components/base-data/prognosisparameter/prognosisparameter';
import IndexComponent from '@/components/base-data/index';
import IndexClauseComponent from '@/components/base-data/index-clause/index-clause';
import IpdStatusComponent from '@/components/base-data/ipd-status/ipd-status';
import IpdComponent from '@/components/base-data/ipd/ipd';
import LanguageComponent from '@/components/base-data/language/language';
import PropertyTaxBaseDataComponent from '@/components/base-data/property-tax/property-tax';
import { AppRightEnum } from '@/constants/fia-constants';
import PaymentGroupsComponent from './payment-type-groups/payment-groups';

@Component({
    components: {
        CurrenciesComponent,
        AreasComponent,
        PremisesComponent,
        PremisesGroupsComponent,
        DevelopmentsComponent,
        MarketsComponent,
        PaymentsComponent,
        PaymentGroupsComponent,
        PropertyTaxBaseDataComponent,
        PrognosisComponent,
        PrognosisParameterComponent,
        IndexComponent,
        IndexClauseComponent,
        IpdStatusComponent,
        IpdComponent,
        LanguageComponent
    }
})
export default class BaseDataComponent extends BaseComponent {
    activeTab: string = "currency";
    reload: string = "";

    mounted() {
        this.$store.dispatch('setCurrenciesLoaded', true);
    }

    valuationsEnabled(): boolean {
        return this.$store.getters.getAppPermission(AppRightEnum.VDok);
    }

    triggerDataLoad(tab: any) {
        this.reload = tab.name;
        switch (tab.name) {
            case "currency":
                if (!this.$store.getters.getCurrenciesLoaded)
                    this.$store.dispatch('setCurrenciesLoaded', true);
                break;
            case "areas":
                if (!this.$store.getters.getAreasLoaded)
                    this.$store.dispatch('setAreasLoaded', true);
                break;
            case "premises":
                if (!this.$store.getters.getPremisesLoaded)
                    this.$store.dispatch('setPremisesLoaded', true);
                break;
            case "development":
                if (!this.$store.getters.getDevelopmentsLoaded)
                    this.$store.dispatch('setDevelopmentsLoaded', true);
                break;
            case "market":
                if (!this.$store.getters.getMarketsLoaded)
                    this.$store.dispatch('setMarketsLoaded', true);
                break;
            case "payments":
                if (!this.$store.getters.getPaymentsLoaded)
                    this.$store.dispatch('setPaymentsLoaded', true);
                break;
            case "typeOfPaymentGroups":
                if (!this.$store.getters.getTypeOfPaymentGroupsLoaded)
                    this.$store.dispatch('setTypeOfPaymentGroupsLoaded', true);
                break;
            case "tax":
                if (!this.$store.getters.getTaxesLoaded)
                    this.$store.dispatch('setTaxesLoaded', true);
                break;
            case "prognosis":
                if (!this.$store.getters.getPrognosisLoaded)
                    this.$store.dispatch('setPrognosisLoaded', true);
                break;
            case "prognosis-parameter":
                if (!this.$store.getters.getPrognosisParametersLoaded)
                    this.$store.dispatch('setPrognosisParametersLoaded', true);
                break;
            case "index":
                if (!this.$store.getters.getIndexLoaded)
                    this.$store.dispatch('setIndexLoaded', true);
                break;
            case "index-clause":
                if (!this.$store.getters.getIndexClausesLoaded)
                    this.$store.dispatch('setIndexClausesLoaded', true);
                break;
            case "ipd-status":
                if (!this.$store.getters.getIpdStatusesLoaded)
                    this.$store.dispatch('setIpdStatusesLoaded', true);
                break;
            case "ipd6":
                if (!this.$store.getters.getIpd6Loaded)
                    this.$store.dispatch('setIpd6Loaded', true);
                break;
            case "language":
                if (!this.$store.getters.getLanguagesLoaded)
                    this.$store.dispatch('setLanguagesLoaded', true);
                break;            
        }
    }

    readBaseData() {
        return this.$store.getters.getAppPermission(AppRightEnum.Fia_Basedata_Read);
    }

    writeBaseData() {
        return this.$store.getters.getAppPermission(AppRightEnum.Fia_Basedata_Write);
    }

    readPrognosis(): boolean {
        return this.$store.getters.getAppPermission(AppRightEnum.Fia_Prognosis);
    }

    writePrognosis(): boolean {
        return this.$store.getters.getAppPermission(AppRightEnum.Fia_Prognosis_Write);
    }
}
