import { Component } from 'vue-property-decorator';

import BaseComponent from '@/components/base-component';
import {
    publicUserAreaStyleHovered, publicUserAreaStyleSelected, userAreaStyleHovered,
    userAreaStyleSelected
} from '@/components/map-shared/styles';
import {
    Draw, Extent, Feature, Geom, Map, Modify, Proj, Select, Style, Vector, VectorLayer
} from '@/imports/map-import';
import { UserDefArea } from '@/models/interfaces/UserDefArea';
import dataService from '@/services/data-service';

import { propertyPinStyle, selectedPropertyPinStyle } from './styles';

@Component({})
export default class BaseMapComponent extends BaseComponent {
    map!: Map;
    propertyLayer!: VectorLayer<any>;
    municipalityLayer!: VectorLayer<any>;
    userDefAreasLayer!: VectorLayer<any>;
    userDefPublicAreasLayer!: VectorLayer<any>;
    drawLayer!: VectorLayer<any>;
    selectedPropertyFeature: Feature | undefined = undefined;
    customPolygon!: Draw;
    customPolygonModify!: Modify;
    creatingArea = false;
    selectedArea: UserDefArea | null = null;
    selection!: Select;
    selectedAreaFeature: Feature | undefined;
    modifyInteraction!: Modify;
    modifyingArea!: boolean;
    loading: number = 0;

    cancelArea() {
        this.creatingArea = false;
        this.customPolygon.setActive(false);
        this.map.removeInteraction(this.customPolygonModify);
        this.map.removeInteraction(this.modifyInteraction);
        this.refreshVectorSource(this.drawLayer.getSource());
        this.selectedArea = null;
    }

    refreshVectorSource(source: Vector) {
        source.clear(true);
        source.refresh();
    }

    createArea() {
        this.creatingArea = true;
        this.selectedArea = { id: 0, filterName: "", userName: "TODO", isPublic: false, userId: 0 }

        this.customPolygon = new Draw({
            source: this.drawLayer.getSource(),
            type: 'Polygon'
        });
        this.customPolygon.on('drawend', (x: any) => {
            this.map.removeInteraction(this.customPolygon);
            this.map.removeInteraction(this.customPolygonModify);
        });
        this.map.addInteraction(this.customPolygon);

        this.customPolygonModify = new Modify({ source: this.drawLayer.getSource() });
        this.map.addInteraction(this.customPolygonModify);
    }

    async saveArea() {
        if (this.selectedArea) {

            if (this.selectedArea.id > 0 && this.selectedAreaFeature) {
                this.updateArea();
            }
            else {
                let sketchCoords: [number, number][] = (<any>this.customPolygon).sketchCoords_[0];
                let coords = sketchCoords;
                coords.push(sketchCoords[0])
                let coordsProj = coords.map(c => Proj.transform(c, 'EPSG:3857', 'EPSG:4326'));
                let coordsStr = coordsProj.map(c => c[0].toString() + " " + c[1].toString());
                let geom = coordsStr.join(",");
                this.loading++;
                dataService.updateUserDefArea(0, this.selectedArea.filterName, geom, this.selectedArea.isPublic).then(_ => {
                    this.customPolygon.setActive(false);
                    this.refreshVectorSource(this.drawLayer.getSource());
                    this.refreshVectorSource(this.userDefAreasLayer.getSource());
                    this.creatingArea = false;
                    this.selectedArea = null;
                }).finally(() => { this.loading--; });
            }
        }
    }

    async updateArea() {
        if (this.selectedArea) {
            let polygons = (<any>this.selectedAreaFeature).getGeometry().getPolygons();
            if (polygons.length == 0)
                return;

            let polygon = <Geom.Polygon>polygons[0];
            let sketchCoords = polygon.getCoordinates()[0];
            let coords = sketchCoords;
            let coordsProj = coords.map(c => Proj.transform(c, 'EPSG:3857', 'EPSG:4326'));
            let coordsStr = coordsProj.map(c => c[0].toString() + " " + c[1].toString());
            let geom = coordsStr.join(",");
            this.loading++;
            dataService.updateUserDefArea(this.selectedArea.id, this.selectedArea.filterName, geom, this.selectedArea.isPublic).then(_ => {
                if (this.selectedAreaFeature && this.selectedArea) {
                    this.selectedAreaFeature.set('filter_name', this.selectedArea.filterName);
                    this.selectedAreaFeature.setStyle(this.getSelectedAreaStyle('userDefinedAreas', this.selectedArea.filterName));
                }
                if (this.modifyInteraction) {
                    this.modifyInteraction.setActive(false);
                }
                this.refreshVectorSource(this.drawLayer.getSource());
                this.refreshVectorSource(this.userDefAreasLayer.getSource());
                this.modifyingArea = false;
                this.selectedArea = null;
            }).finally(() => { this.loading--; });
        }
    }

    editArea() {
        if (this.selectedArea && !this.modifyingArea) {
            this.selection = new Select();
            this.selection.getFeatures().extend([<Feature> this.selectedAreaFeature]);
            this.modifyInteraction = new Modify({
                features: this.selection.getFeatures()
            });
            this.map.addInteraction(this.modifyInteraction);
            this.modifyingArea = true;            
        }
    }

    editAreaFinish(updateArea : boolean = true) {
        this.map.removeInteraction(this.modifyInteraction);
        this.modifyingArea = false;
        if (updateArea)
            this.updateArea();
    }

    removeArea() {
        this.confirm("ConfirmDeleteUserDefArea", "Confirm").then(() => {
            if (this.modifyingArea) {
                this.editAreaFinish(false);
            }
            if (this.selectedArea) {
                this.loading++;
                dataService.removeUserDefArea(this.selectedArea.id).then(() => {
                    this.refreshVectorSource(this.userDefAreasLayer.getSource());
                    this.creatingArea = false;
                    this.selectedArea = null;
                }).finally(() => { this.loading--; });
            }
        }).catch(() => {});
    }

    createDrawLayer() {
        var source = new Vector({ wrapX: false });

        this.drawLayer = new VectorLayer({
            source: source,
            zIndex: 2000
        });
    }

    findPropertyFeature(id: number): Feature | undefined {
        let source: Vector = this.propertyLayer.getSource();
        let features: Feature[] = source.getFeatures();
        for (let f of features) {
            if ((<any>f).values_.propertyid === id.toString()) {
                return f;
            }
        }
        return undefined;
    }

    showProperty(id: number, setZoom: boolean) {
        if (this.selectedPropertyFeature) {
            this.selectedPropertyFeature.setStyle(propertyPinStyle)
        }

        let feature = this.findPropertyFeature(id);
        if (feature) {
            this.selectedPropertyFeature = feature;
            this.selectedPropertyFeature.setStyle(selectedPropertyPinStyle);

            if (setZoom) {
                const geom = feature.getGeometry();
                if (geom) {
                    let ext = geom.getExtent();
                    let center = Extent.getCenter(ext);
                    this.map.getView().setCenter([center[0], center[1]]);
                    this.map.getView().setZoom(12);
    
                    // this.map.setView(new View({
                    //     projection: 'EPSG:3857',
                    //     center: [center[0], center[1]], //zoom to the center of your feature
                    //     zoom: 14
                    // }));
                }
            }

        }
        else {
            this.selectedPropertyFeature = undefined;
        }
    }

    getSelectedAreaStyle(layername: string, text: string): Style {
        let style = userAreaStyleSelected;
        switch (layername) {
            case 'publicUserDefinedAreas':
            style = publicUserAreaStyleSelected;
            break;
        }
        style.getText().setText(text);
        return style;
    }
    getHoveredAreaStyle(layername: string, text: string): Style {
        let style = userAreaStyleHovered;
        switch (layername) {
            case 'publicUserDefinedAreas':
            style = publicUserAreaStyleHovered;
            break;
        }
        style.getText().setText(text);
        return style;
    }

    async MapHttpInterceptor(url: string, onResponse: (r: any) => any) {
        var client = new XMLHttpRequest();
        client.open('GET', url);
        let headers = await dataService.getAuthHeaders();
        for (const key of Object.keys(headers)) {
            client.setRequestHeader(key, (<any> headers)[key]);
        }
        client.onload = function () {
            if (onResponse)
                onResponse(this);
        };
        client.send();
    }

    async PostHttpInterceptor(url: string, data: any, onResponse: (r: any) => any) {
        var client = new XMLHttpRequest();
        client.open('POST', url);
        let headers = await dataService.getAuthHeaders();
        for (const key of Object.keys(headers)) {
            client.setRequestHeader(key, (<any> headers)[key]);
        }
        client.setRequestHeader('Content-type', 'application/json');
        client.onload = function () {
            if (onResponse)
                onResponse(this);
        };
        client.send(JSON.stringify(data));
    }
}