//@ts-ignore
import { VueContext } from 'vue-context';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import BaseComponent from '@/components/base-component';
import ContractGetComponent from '@/components/contract/contract-get/contract-get';
import EditPropertyVersionDialogComponent, { OpenPropertyVersionEditDialogArgs } from '@/components/property/propertysearch/new-property-dialog/edit-property-version-dialog';
import { EditVersionDialogMode, AppRightEnum, CountriesEnum } from '@/constants/fia-constants';
import { Portfolio, PremisesType, Property, PropertyVersion, User } from '@/models';
import { PropertySearchResultItem } from '@/models/interfaces/PropertySearchResultItem';
import { PropertyVersionSlim } from '@/models/interfaces/PropertyVersionSlim';
import { BusMessage, VersionLoadedMessage } from '@/models/messages/messages';
import { PropertySearchRequest } from '@/models/PropertySearchRequest';
import DataService from '@/services/data-service';
import PropertyEditComponent from '@/components/property/property-edit/property-edit';
import AreaSelectComponent, { AreaSelection } from '@/components/common/area-select/area-select';
import AddPropertyDialog from './new-property-dialog/add-property-dialog';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import { TaxTypeCode } from '@/models/TaxTypeCode';

@Component({
    components: {
        EditPropertyVersionDialogComponent,
        ContractGetComponent,
        PropertyEditComponent,
        AddPropertyDialog,
        AreaSelectComponent,
        InputNumericComponent,
        VueContext: VueContext
    },
    computed: mapGetters({
        propertyVersion: "getPropertyVersion",
        propertyId: "getPropertyId",
        deletingPropertyVersion: "getDeletingPropertyVersion",
        busMessage: "getBusMessage",
        searchingProperties: "getSearchingProperties",
        user: "getUser",
        premisesTypes: "getPremisesTypes",
        taxTypeCodes: "getTaxTypeCodes"
    })
})
export default class PropertySearchComponent extends BaseComponent {
    propertyVersion!: PropertyVersion;
    propertyId!: number;
    deletingPropertyVersion!: boolean;
    busMessage!: BusMessage<any>;
    searchingProperties!: boolean;
    user!: User;
    premisesTypes!: PremisesType[];
    taxTypeCodes!: TaxTypeCode[];

    defaultCountryId: CountriesEnum = CountriesEnum.Sweden;
    searchText: PropertySearchRequest =  {
        name: "",
        identifier: "",
        tenants: "",
        premisesTypes: [],
        fromVersion: "",
        toVersion: "",
        fromArea: null,
        toArea: null,
        countries: [],
        regions: [],
        counties: [],
        subcounties: [],
        page: 0,
        maxRows: 250,
        portfolioId: null,
        taxTypeCodes: [],
        address: "",
    };
    propertyList: PropertySearchResultItem[] = [];
    versionList: PropertyVersionSlim[] = [];
    selectedVersion: PropertyVersionSlim | null = null;
    dialogVisible: boolean = false;
    dialogEditMode: boolean = false;
    selectedProperty = <PropertySearchResultItem>{};
    searching: boolean = false;
    versionsLoading: boolean = false;
    args: OpenPropertyVersionEditDialogArgs | null = null;
    versionToCopy: PropertyVersionSlim | null = null;
    canPasteVersion: boolean = false;
    getDialogVisible: boolean = false;
    getPropertyId: number | null = null;
    advancedSearch: boolean = true;
    invalidSearchAreaSyntax: boolean = false;
    invalidSearchVersionSyntax: boolean = false;
    recentList: PropertyVersionSlim[] = [];
    favoriteList: PropertyVersionSlim[] = [];
    recentListNeedsRefresh: boolean = true;
    favoriteListNeedsRefresh: boolean = false;
    currentPageProperty: number = 0;
    moreRowsProperty: boolean = false;
    portfolioList: Portfolio[] = [];

    @Prop()
    active!: string

    @Watch("active")
    activeWatcher() {
        if (this.active == "propertySearch")
            (<any>this.$refs.search).$el.children[0].focus();
    }

    @Watch("searchingProperties") 
    searchingPropertiesWatcher(val: boolean) {
        this.searching = val;
    }

    @Watch("busMessage")
    busWatcher() {
        switch (this.busMessage.type) {
            case "PropertyVersionAdded":
            case "PropertyVersionUpdated":
            case "PropertyVersionCopied":
                let selectLatestVersion = this.busMessage.type !== 'PropertyVersionUpdated';
                this.loadVersions(selectLatestVersion).then(() => {
                    if (this.selectedVersion) {
                        let row = this.versionList.find(x => x.id === this.selectedVersion!.id);

                        if (row) {
                            this.selectedVersion = row;
                            this.$nextTick(() => {
                                (<any>this.$refs).versionsTable.setCurrentRow(row);
                            })
                        }
                    }
                });
                break;
            case "VersionLoaded":
                this.recentListNeedsRefresh = true;
                let msg: VersionLoadedMessage = this.busMessage.data;
                switch (msg.source) {
                    case "recentsearch":
                    case "favoritesearch":
                    case "portfoliosearch":
                    case "map":
                        this.setToCurrent(false);
                        break;
                }
                break;
            case "SearchedPropertiesFromMap":
                if (this.busMessage.data.propertyIds != null && this.busMessage.data.propertyIds.length > 0) {
                    this.searchProperties(this.busMessage.data.propertyIds);
                } else {
                    this.searchProperty();
                }
                break;
        }
    }

    async setToCurrent(setRecent: boolean = true) {
        if (!this.propertyVersion) return;

        await this.clearSearch(true);
        const itemInList = this.propertyList.find(prop => prop.id === this.propertyVersion.propertyId);
        if (!itemInList) {
            this.propertyList = [{ id: this.propertyVersion.propertyId, countyName: this.propertyVersion.property.viewCountyName || "", propertyIdentifier: this.propertyVersion.property.propertyIdentifier, propertyName: this.propertyVersion.property.propertyName }]
            this.selectedProperty = this.propertyList[0];
        } else {
            this.selectedProperty = itemInList;
        }
        await this.loadVersions(setRecent);
        let version = this.versionList.find(x => x.id === this.propertyVersion.id);
        if (version) {
            this.selectedVersion = version;
            this.$nextTick(() => {
                (<any>this.$refs).propertiesTable.setCurrentRow(this.selectedProperty);
                (<any>this.$refs).versionsTable.setCurrentRow(this.selectedVersion);
            })
        }
    }

    async mounted() {
        this.defaultCountryId = this.user.userSettings.defaultCountryId || CountriesEnum.Sweden;
        this.taxTypeCodes.sort((a, b) => a.typeNumber - b.typeNumber);
        await this.loadFavorites();
        if (process.env.VUE_APP_MODE === "dev") {
            this.searchText.name = "Gurkan";
            this.searchProperty();
        }
        (<any>this.$refs.search).$el.children[0].focus();
    }

    async loadRecent() {
        this.recentList = await DataService.getRecent().then(x => x.data);
        this.recentListNeedsRefresh = false;
    }

    async loadFavorites() {
        this.favoriteList = await DataService.getFavorites().then(x => x.data);
        this.favoriteListNeedsRefresh = false;
    }

    async searchProperty(nextPage: boolean = false) {
        if (!this.advancedSearch && !nextPage) {
            await this.clearSearch();
        }

        try {
            this.searching = true;
            this.searchText.page = nextPage ? this.currentPageProperty + 1 : 0;
            const result = await DataService.searchProperties(this.searchText).then(x => x.data);
            if (nextPage) {
                this.propertyList.push(...result.properties);
            } else {
                this.propertyList = result.properties;
            }
            this.currentPageProperty = result.page;
            this.moreRowsProperty = result.moreRows;
            await this.$store.dispatch("setSearchedPropertyIds", this.propertyList.map(x => x.id));
            await this.$store.dispatch("setPropertySearchRequest", {...this.searchText});
        } catch (error) {
            return Promise.reject(new Error(""));
        }
        finally {
            this.searching = false;
        }
    };

    async searchProperties(ids: number[]) {
        this.searching = true;
        try {
            let properties = await DataService.getProperties(ids).then(x => x.data);
            await this.$store.dispatch("setSearchedPropertyIds", properties.map(x => x.id));
            this.propertyList = properties;
        } finally {
            this.searching = false;
            (<any> this).$refs.propertiesTable.doLayout();
        }
    }

    async clearSearch(fullClear: boolean = false) {
        if (fullClear) {
            this.searchText.name = "";
            this.searchText.identifier = "";
        }
        this.searchText.tenants = "";
        this.searchText.premisesTypes = [];
        this.searchText.fromArea = null;
        this.searchText.toArea = null;
        this.searchText.fromVersion = "";
        this.searchText.toVersion = "";
        this.searchText.countries = [];
        this.searchText.regions = [];
        this.searchText.counties = [];
        this.searchText.subcounties = [];
        this.searchText.portfolioId = null;
        this.searchText.taxTypeCodes = [];
        this.searchText.address = "";

        this.currentPageProperty = 0;
        this.moreRowsProperty = false;

        if ((<any>this.$refs).areaSelect)
            await (<any>this.$refs).areaSelect.reset();
    }

    async propertySelected(e: PropertySearchResultItem) {
        if (this.versionsLoading) return;

        await this.$store.dispatch("setPropertyId", e.id);
        this.selectedProperty = e;
        await this.loadVersions();
    }

    loadVersions(selectLatestVersion: boolean = true): Promise<any> {
        this.versionsLoading = true;

        return DataService.getVersionsByProperty(this.selectedProperty.id, this.searchText.tenants, this.searchText.premisesTypes, this.searchText.fromArea, this.searchText.toArea, this.searchText.fromVersion, this.searchText.toVersion, this.searchText.portfolioId, this.searchText.taxTypeCodes)
            .then(x => {
                this.versionList = x.data;
                
                // if no versions are found, return
                if (this.versionList.length === 0) {
                    this.versionsLoading = false;
                    return;
                }

                this.versionList = this.versionList.sort((a, b) => b.id - a.id);
                const searchFilterMatch = this.versionList.find(x => x.searchMatch);

                if (searchFilterMatch) {
                    let version = searchFilterMatch;
                    (<any> this.$refs.versionsTable).setCurrentRow(version);
                    this.versionSelected(version);
                }
                else if (selectLatestVersion) {
                    // Only pick latest if source is property search, skip if other search source.
                    // pick latest version and highlight the row, load version data
                    let version = this.versionList[0]; 
                    (<any> this.$refs.versionsTable).setCurrentRow(version);
                    this.versionSelected(version);
                }
                this.versionsLoading = false;
            }).catch(() => {
                this.versionsLoading = false;
            })
            .finally(() => {
                (<any> this).$refs.versionsTable.doLayout();
            });
    }

    async versionSelected(version: PropertyVersionSlim) {
        if (this.$store.getters.getPropertyVersionLoading) return;

        this.selectedVersion = version;
        await this.$store.dispatch("loadPropertyVersion", { id: version.id, source: "propertysearch" });
        this.scrollToSelectedVersionRow();
    }

    scrollToSelectedVersionRow() {
        const versionTable = <HTMLTableElement>(<any>this.$refs.versionsTable).$el.querySelector(".el-table__body-wrapper");
        const currentRow = <HTMLElement>(<any>this.$refs.versionsTable).$el.querySelector(".el-table__row.current-row");
        if (versionTable && currentRow) {
            versionTable.scrollTo({ top: currentRow.offsetTop - versionTable.clientHeight/2, behavior: 'smooth' });
        }
    }

    canClearSearch(): boolean {
        return !this.searching;
    }

    canDeleteVersion(): boolean {
        return this.propertyVersion && !this.deletingPropertyVersion;
    }

    canAddVersion(): boolean {
        return this.propertyId !== 0 && !this.searching;
    }

    canEditVersion(): boolean {
        return this.propertyVersion && !this.searching;
    }

    deleteVersion() {
        this.confirm(this.translate('ConfirmDeletePropertyVersion') + this.propertyVersion.versionIdentifier, undefined, undefined, undefined, 'warning')
            .then(async () => {
                await this.$store.dispatch("deletePropertyVersion", this.propertyVersion.id);
                await this.loadVersions();
            }).catch(() => {
                
            });
    }

    editVersion() {
        this.args = <OpenPropertyVersionEditDialogArgs>{ editMode: EditVersionDialogMode.Edit, sourceVersionId: this.selectedVersion!.id, sourcePropertyId: this.selectedProperty.id };
    }

    addVersion() {
        this.args = <OpenPropertyVersionEditDialogArgs>{ editMode: EditVersionDialogMode.Add, sourceVersionId: 0, sourcePropertyId: this.selectedProperty.id };
    }

    async versionContextClick(command: string, version: PropertyVersionSlim) {
        switch (command) {
            case 'copy':
                this.args = <OpenPropertyVersionEditDialogArgs>{ editMode: EditVersionDialogMode.CopyToSame, sourceVersionId: version.id, sourcePropertyId: version.propertyId };
                break;
            case 'copy-to-other':
                this.versionToCopy = version;
                break;
        }
    }

    propertyContextClick(command: string, property: PropertySearchResultItem) {
        switch (command) {
            case 'paste':
                this.args = <OpenPropertyVersionEditDialogArgs>{ editMode: EditVersionDialogMode.CopyToOther, sourceVersionId: this.versionToCopy!.id, sourcePropertyId: this.versionToCopy!.propertyId, targetPropertyId: property.id };
                break;
            case 'get-version':
                this.getPropertyId = property.id;
                this.getDialogVisible = true;
                break;
        }
    }

    async versionDialogClosed(canceled: boolean) {
        if (!canceled) {
            if (this.args!.editMode === EditVersionDialogMode.Add) {
                await this.loadVersions();
            }
        }
    }

    getPropertyPasteLabel(): string {
        return this.translate("Paste") + (this.versionToCopy ? ": " + this.versionToCopy!.versionIdentifier + " (" + this.versionToCopy!.propertyName + ")" : "");
    }

    propertyContentMenuClicked(e: Event, item: PropertySearchResultItem) {
        this.canPasteVersion = this.versionToCopy !== null && this.versionToCopy.propertyId !== item.id;
        (<any>this.$refs.propertymenu).open(e, { row: item })
    }
    
    getPropertyVersion() {
        this.getDialogVisible = true;
    }

    async getDialogClosed(canceled: boolean, selected: any) {
        if (!canceled && this.getPropertyId) {
            this.args = <OpenPropertyVersionEditDialogArgs>{ 
                editMode: EditVersionDialogMode.CopyToOther, 
                sourceVersionId: selected.versionId, 
                sourcePropertyId: selected.propertyId, 
                targetPropertyId: this.getPropertyId 
            };
            this.getPropertyId = null;
        }
    }

    mapEnabled(): boolean {
        return this.$store.getters.getAppPermission(AppRightEnum.Fia_Map);
    }

    async toggleAdvancedSearch() {
        if (this.advancedSearch) {
            await this.clearSearch();
        }
        this.advancedSearch = !this.advancedSearch;
    }

    // async exportVersions() {
    //     let request: AddContractsToExcelRequest = {
    //         languageId: this.user.languageId,
    //         show: true,
    //         userName: this.user.realName,
    //         mainItems: this.versionList.map(x => <KeyValue>{ key: x.id, value: x.versionIdentifier }),
    //         treeLevel: 4,
    //         onlyLatestVersion: true,
    //         startDate: this.newIsoDateMonth(false),
    //         endDate: this.newIsoDateMonth(true),
    //         tenantFilter: this.propertySearchRequest.tenants || "",
    //         premesisTypeFilter: this.propertySearchRequest.premisesTypes || ""
    //     }
        
    //     try {
    //         await DataService.addContractsToExcelReport(request);
    //         await this.$store.dispatch("sendBusMessage", <BusMessage<ReportAddedMessage>>{ type: "ReportAdded" });
    //     } catch (error) {
    //         return Promise.reject(new Error(""));
    //     }
    // }

    versionRowFormat(arg: { row: PropertyVersionSlim, rowIndex: number }): string {
        return arg.row.searchMatch ? "search-match" : "";
    }

    openAddPropertyDialog() {
        (<AddPropertyDialog> this.$refs.addPropertyDialog).openDialog();
    }


    async favoritesClicked(opened: boolean) {
        if (opened && this.favoriteListNeedsRefresh)
            await this.loadFavorites();
    }

    async toggleFavorite(versionId: number, add: boolean) {
        try {
            if (add) {
                await DataService.addVersionFavorite(versionId);
            } else {
                await DataService.deleteVersionFavorite(versionId);
            }
            this.loadFavorites();
        } catch (error) {
        }
    }

    isFavorite(versionId: number): boolean {
        return this.favoriteList.some(x => x.id === versionId)
    }

    async favoriteSelected(versionId: number) {
        const favorite = this.favoriteList.find(x => x.id === versionId);
        if (!favorite) return;

        await this.clearSearch(true);
        await this.$store.dispatch("setSearchedPropertyIds", [favorite.propertyId]);
        await this.$store.dispatch("loadPropertyVersion", { id: favorite.id, source: "favoritesearch" });
    }

    async removeFavorite(item: PropertyVersionSlim) {
        await DataService.deleteVersionFavorite(item.id);
        await this.loadFavorites();
    }

    async recentClicked(opened: boolean) {
        if (opened && this.recentListNeedsRefresh)
            await this.loadRecent();
    }

    async recentSelected(versionId: number) {
        const recent = this.recentList.find(x => x.id === versionId);
        if (!recent) return;

        await this.clearSearch(true);
        await this.$store.dispatch("setSearchedPropertyIds", [recent.propertyId]);
        await this.$store.dispatch("loadPropertyVersion", { id: recent.id, source: "recentsearch" });
    }

    // New property dialog - on saved property. Add new property to search list and select it
    onNewPropertySave(newProperty: Property) {
        let newSearchResult = <PropertySearchResultItem> {
            id: newProperty.id,
            propertyName: newProperty.propertyName,
            propertyIdentifier: newProperty.propertyIdentifier,
            countyName: newProperty.viewCountyName
        }

        this.propertyList.push(newSearchResult);
        this.selectedProperty = newSearchResult;
        (<any>this.$refs).propertiesTable.setCurrentRow(this.selectedProperty);
        this.$store.dispatch("setTabLevel1", 'property');
        this.propertySelected(this.selectedProperty);
    }

    removeFromSearchList(property: Property) {
        let idx = this.propertyList.findIndex(x => x.id == property.id);
        if (idx === -1) return;
        this.propertyList.splice(idx, 1);
    }

    areaChanged(area: AreaSelection) {
        this.searchText.countries = area.countries;
        this.searchText.regions = area.regions;
        this.searchText.counties = area.counties;
        this.searchText.subcounties = area.subcounties;
    }

    searchPortfolio(query: string) {
        if (query !== '' && query.length >= 3) {
            setTimeout(async () => {
                await DataService.searchPortfolios(query).then((x: any) => {
                    this.portfolioList = x.data;
                });
            }, 200);
        } else {
            this.portfolioList = [];
        }
    }
}
