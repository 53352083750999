import { Component, Watch } from "vue-property-decorator";
import BaseComponent from "@/components/base-component";
import { PortfolioSearchResult } from '@/models/interfaces/PortfolioSearchResult';
import { Portfolio, VersionPortfolio } from '@/models';
import dataService from '@/services/data-service';
import InlineEdit from '@/helpers/inline-edit';
import { AxiosPromise, AxiosResponse } from 'axios';
import { BusMessage, JobAddedMessage } from '@/models/messages/messages';
import { ExportPortfolioRequest } from "@/models/interfaces/request/ExportPortfolioRequest";

@Component({

})
export default class PortfolioExportComponent extends BaseComponent {
    portfolioVersionsGrid: InlineEdit<VersionPortfolio>;
    portfolioList: PortfolioSearchResult[] = [];
    selectedPortfolio: Portfolio | null = null;
    searchText: string = "";
    portfolioSearching: boolean = false;
    progressValue: number = 0;

    constructor() {
        super();
        this.portfolioVersionsGrid = new InlineEdit<VersionPortfolio>(this, "portfolioVersionsTable", this.loadPortfolioVersions, undefined, undefined, this.newPortfolioVersions, undefined, this.hasNoErrors);
        this.portfolioVersionsGrid.sync = true;
    }

    @Watch("searchText")
    searchTextWatcher(query: string) {
        if (query !== '' && query.length >= 3) {
            setTimeout(async () => {
                try {
                    this.portfolioSearching = true;
                    this.portfolioList = await dataService.searchPortfolios(query).then(x => x.data);
                } catch (error) {
                    return Promise.reject(new Error(""));
                } finally {
                    this.portfolioSearching = false;
                }
            }, 200);
        } else {
            this.portfolioList = [];
        }
    }

    @Watch("selectedPortfolio")
    selectedPortfolioWatcher() {
        this.portfolioVersionsGrid.reload();
    }

    async exportPortfolio() {
        try {
            const request: ExportPortfolioRequest = {
                portfolioId: this.selectedPortfolio!.id
            }
            await dataService.exportPortfolio(request);
            await this.$store.dispatch("sendBusMessage", <BusMessage<JobAddedMessage>>{ type: "JobAdded" });
        } catch (error) {
            return Promise.reject(new Error(""));
        }
    }

    selectPortfolio(item: PortfolioSearchResult) {
        this.loadPortfolio(item.id);
    }

    async loadPortfolio(portfolioId: number) {
        try {
            this.selectedPortfolio = await dataService.getPortfolio(portfolioId, false).then(x => x.data);
        } catch (error) {
            return Promise.reject(new Error(""));
        }
    }

    loadPortfolioVersions(): AxiosPromise<VersionPortfolio[]> {
        return Promise.resolve(<AxiosResponse>{ data: this.selectedPortfolio!.id ? this.selectedPortfolio!.propertyVersionPortfolios : [] });
    }

    newPortfolioVersions() {
        return <VersionPortfolio>{};
    }
}