import BaseComponent from "@/components/base-component";
import { Component, Watch, Prop } from "vue-property-decorator";
import { VersionPortfolio, PropertyVersion } from "@/models";
import dataService from "@/services/data-service";
import InlineEdit from "@/helpers/inline-edit";
import { AxiosPromise } from "axios";
import { mapGetters } from 'vuex';
import { PortfolioSearchResult } from '@/models/interfaces/PortfolioSearchResult';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import ValidationTableFeedbackComponent from '@/components/common/validation-table-feedback/validation-table-feedback';
import ValidationErrorComponent from '@/components/common/validation-error/validation-error';
import { ValidationError } from '@/models/interfaces/ValidationError';
import validationService from '@/services/validation-service';
import { BusMessage, VersionPortfolioChangedMessage } from '@/models/messages/messages';

@Component({
    components: {
        InputNumericComponent,
        ValidationTableFeedbackComponent,
        ValidationErrorComponent
    },
    computed: mapGetters({
        propertyVersion: "getPropertyVersion"
    })
})
export default class DetailsPortfoliosComponent extends BaseComponent {
    propertyVersion!: PropertyVersion;

    versionPortfoliosGrid: InlineEdit<VersionPortfolio>;
    currentPropertyVersion: number = -1;
    complexErrors: ValidationError[] = [];
    portfoliolist: PortfolioSearchResult[] = [];
    selectedPortfolioId: number | null = null;
    loading: boolean = false;
    saving: boolean = false;

    constructor() {
        super();
        this.versionPortfoliosGrid = new InlineEdit<VersionPortfolio>(this, "versionPortfoliosTable", this.loadVersionPortfolios, undefined, undefined, this.newVersionPortfolio, undefined, this.hasNoErrors);
        this.versionPortfoliosGrid.sync = true;
        this.versionPortfoliosGrid.readOnly = this.versionLocked();
        this.versionPortfoliosGrid.useConfirmDeleteDialog = false;
    }

    @Prop()
    reload!: boolean;

    @Watch("reload")
    async reloadWatcher() {
        if (this.reload) {
            if (this.reloadNeeded()) {
                await this.reloadData();
            }
            this.versionPortfoliosGrid.redraw();
        }
    }

    @Watch("propertyVersion")
    async versionWatcher() {
        if (this.reloadNeeded()) {
            await this.reloadData();
        }
    }

    @Watch("versionPortfoliosGrid.entities", { deep: true, immediate: true })
    marketRentsGridWatcher() {
        validationService.validatePropertyVersionDetailsPortfolio(this.versionPortfoliosGrid.entities, this.complexErrors);
    }

    @Watch("versionPortfoliosGrid.currentItem")
    currentPortfolioWatcher() {
        if (!this.versionPortfoliosGrid.currentItem) return;

        const item = this.versionPortfoliosGrid.currentItem!.entity;
        if (item.portfolioId === 0) {
            this.portfoliolist = [];
            this.selectedPortfolioId = null;
        } else {
            const portfolio = { id: item.portfolioId, name: item.viewPortfolioName } as PortfolioSearchResult;
            this.portfoliolist = [portfolio];
            this.selectedPortfolioId = portfolio.id;
        }
    }

    async mounted() {
        await this.reloadData();
    }

    async reloadData(versionPortfolios?: VersionPortfolio[] | undefined) {
        await this.versionPortfoliosGrid.reload(versionPortfolios);
        this.complexErrors = [];
        this.currentPropertyVersion = this.propertyVersion!.id;
    }

    reloadNeeded(): boolean {
        return this.reload && this.propertyVersion && this.currentPropertyVersion !== this.propertyVersion.id;
    }

    loadVersionPortfolios(): AxiosPromise<VersionPortfolio[]> {
        return dataService.getVersionPortfolios(this.propertyVersion.id);
    }

    portfolioChanged(item: VersionPortfolio) {
        if (item && this.selectedPortfolioId) {
            item.portfolioId = this.selectedPortfolioId;
            item.viewPortfolioName = this.portfoliolist.find(x => x.id === this.selectedPortfolioId)!.name;
        }
    }

    newVersionPortfolio() {
        return <VersionPortfolio>{
            id: this.getNewId(this.versionPortfoliosGrid.entities),
            propertyVersionId: this.propertyVersion.id,
            share: 0,
            portfolioId: 0,

            viewPortfolioName: "",
            viewPropertyName: "",
            viewPropertyIdentifier: "",
            viewVersionIdentifier: "",
            viewCountyName: "",
            viewLocked: false,
        }
    }

    searchMethod(query: string) {
        if (query !== '' && query.length >= 3) {
            this.loading = true;
            setTimeout(() => {
                dataService.searchPortfolios(query)
                    .then((x: any) => {
                        this.portfoliolist = x.data;
                        this.loading = false;
                    });
            }, 200);
        } else {
            this.portfoliolist = [];
            this.loading = false;
        }
    }

    async save() {
        this.saving = true;
        await dataService.saveVersionPortfolios(this.propertyVersion.id, this.versionPortfoliosGrid.entities)
            .then(async x => {
                this.reloadData(x.data);
                await this.$store.dispatch("sendBusMessage", <BusMessage<VersionPortfolioChangedMessage>>{ type: "VersionPortfolioChanged" });
                this.saving = false;
                return true;
            })
            .catch(() => {
                this.$notify.error({
                    title: this.translate('ErrorUnexpected'),
                    message: this.translate('ErrorActionFailed')
                });
                this.saving = false;
                return false;
            });
    }

    async undo() {
        await this.reloadData();
    }

    isValid(): boolean {
        return this.complexErrors.length === 0;
    }

    canUndo(): boolean {
        return !this.versionLocked() && !this.versionPortfoliosGrid.loadError;
    }

    canSave(): boolean {
        return !this.saving && this.isValid() && !this.versionLocked() && !this.versionPortfoliosGrid.loadError;
    }

    canAdd(): boolean {
        return !this.versionLocked() && !this.versionPortfoliosGrid.loadError;
    }
}