import { AxiosResponse } from "axios";

// tslint:disable-next-line: no-namespace
export namespace General {

  export const resolvePath = (object: any, path: any, defaultValue: any) => path.split('.').reduce((o: any, p: any) => o ? o[p] : defaultValue, object);

  export function downloadFile(response: AxiosResponse<any>) {
    let dispHeader: string = response.headers["content-disposition"];
    let ix = dispHeader.lastIndexOf("filename*=UTF-8''")
    let fileName = "unknown";
    if (ix !== -1) {
      fileName = decodeURI(dispHeader.substring(ix + 17, dispHeader.length));
    }
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }
}