import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ProgressStatus } from 'element-ui/types/progress';
import { SignalRMessage } from '@/models/interfaces/signalR/SignalRMessage';
import { MessageType } from '@/models/interfaces/signalR/MessageType';
import { Job, Portfolio, PropertyVersion } from "@/models";
import { JobIdentityType } from "@/constants/fia-constants";

@Component({})
export default class ProgressBarComponent extends Vue {

    progress: number = 0;
    status: ProgressStatus | null = null;
    signalRMsgHandler: any;

    @Prop()
    isVersion!: boolean;
    @Prop()
    propertyVersion!: PropertyVersion;
    @Prop()
    portfolio!: Portfolio;

    mounted() {
        this.signalRMsgHandler = (message: SignalRMessage) => this.onSignalRMessage(message);
        this.$signalrHub.$on("HubMessage", this.signalRMsgHandler);
    }

    beforeDestroy() {
        this.$signalrHub.$off("HubMessage", this.signalRMsgHandler);
    }

    onSignalRMessage(message: SignalRMessage) {
        if (!this.isValidMessage(message))
            return;
        const job = JSON.parse(message.data) as Job;

        if (!this.shouldUpdateProgress(job))
            return;
        
        switch (message.type) {
            case MessageType.JobFailed:
                this.status = 'exception';
                break;
            case MessageType.JobProgressChanged:
            case MessageType.JobFinished:
                this.status = null;
                this.progress = job.progress;
                break;
        }
    }

    isValidMessage(message: SignalRMessage) {
        return message && message.data && 
               [MessageType.JobStarted, MessageType.JobFinished, MessageType.JobFailed, MessageType.JobProgressChanged].includes(message.type);
    }

    shouldUpdateProgress(job: Job) {
        if (this.isVersion && job.identityType === JobIdentityType.PropertyVersion && this.propertyVersion.id === Number(job.identityId))
            return true;
        if (!this.isVersion && job.identityType === JobIdentityType.Portfolio && this.portfolio.id === Number(job.identityId))
            return true;
        return false;
    }

    hasValue(val: number | null) {
        return val != null && val > 0
    }
}
