import { Component } from "vue-property-decorator";
import BaseComponent from "@/components/base-component";
import ValuationComponent from "@/components/vdok/valuation/valuation";
import ModulesComponent from "@/components/vdok/modules/modules";
import { getBrowser } from '@/helpers/browser';

@Component({
    components: {
        ValuationComponent,
        ModulesComponent
    }
})
export default class VDokComponent extends BaseComponent {
    activeTab: string = "valuation"
    reload: string = "";
    disabled: boolean = false;

    mounted() {
        let browserVersion = getBrowser();
        if (browserVersion.name !== "Chrome" || parseFloat(browserVersion.version) < 69.0) {
            // this.disabled = true;
            // this.activeTab = '';
            this.$message({
                message: this.translate("UnSupportedBrowserWarning"),
                type: "warning",
            });
        }
    }

}