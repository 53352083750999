import axios, { AxiosInstance, AxiosPromise } from 'axios';

import { ErrorType } from '@/constants/fia-constants';
import {
    Classification, Contract, CostIncome, Country, County, Currency,
    Development, DevelopmentType, EstimatedMarketValue, IndexClause, IndexDenotation, IndexValue,
    Ipd6, Job, Language, Loan, LoanListItem, MarketRent, MarketType, PaymentGroup, Portfolio,
    PremisesType, PremisesTypeGroup, Prognosis, PrognosisAlternative, PrognosisParameter, Property,
    PropertyDetail, PropertyTaxRate, PropertyTaxType, PropertyVersion, Status,
    Subcounty, Tax, TypeOfPayment, TypeOfPaymentStream, User, Valuation, ValuationDataTableField,
    ValuationDataTableFieldInModule, ValuationDataTableListItem,
    ValuationListItem, ValuationModule, ValuationModuleListItem, ValuationTableInstance,
    VersionPortfolio, PropertyLand, PropertyTemplate, ContractSearchRequest, Database, Customer, BoverketEnergyDeclaration, EnvironmentalCertificate
} from '@/models';
import { BuildingRight } from '@/models/BuildingRight';
import { CostIncomeListItem } from '@/models/CostIncomeListItem';
import { BaseDataCache } from '@/models/interfaces/BaseDataCache';
import { ContractListItem } from '@/models/interfaces/ContractListItem';
import { PortfolioSearchResult } from '@/models/interfaces/PortfolioSearchResult';
import { PropertyLocation } from '@/models/interfaces/PropertyLocation';
import { PropertyReadyInfo } from '@/models/interfaces/PropertyReadyInfo';
import { PropertySearchResultItem } from '@/models/interfaces/PropertySearchResultItem';
import { PropertyVersionSlim } from '@/models/interfaces/PropertyVersionSlim';
import { AddCalculationRequest } from '@/models/interfaces/request/AddCalculationRequest';
import { AddContractsToExcelRequest } from '@/models/interfaces/request/AddContractsToExcelRequest';
import { AddIpd4Request } from '@/models/interfaces/request/AddIpd4Request';
import { AddIpd6NotReadyRequest } from '@/models/interfaces/request/AddIpd6NotReadyRequest';
import { AddIpd6Request } from '@/models/interfaces/request/AddIpd6Request';
import { AddKeyRatiosRequest } from '@/models/interfaces/request/AddKeyRatiosRequest';
import { AddKeyRatiosExtendedRequest } from '@/models/interfaces/request/AddKeyRatiosExtendedRequest';
import {
    AddMultipleVersionsCalculationRequest
} from '@/models/interfaces/request/AddMultipleVersionsCalculationRequest ';
import { AddPortfolioDetailsRequest } from '@/models/interfaces/request/AddPortfolioDetailsRequest';
import {
    AddPropertyAddressListRequest
} from '@/models/interfaces/request/AddPropertyAddressListRequest';
import { CopyContractRequest } from '@/models/interfaces/request/CopyContractRequest';
import { CopyCostIncomeRequest } from '@/models/interfaces/request/CopyCostIncomeRequest';
import { CopyPortfolioRequest } from '@/models/interfaces/request/CopyPortfolioRequest';
import {
    CopyPortfolioVersionsRequest
} from '@/models/interfaces/request/CopyPortfolioVersionsRequest';
import { CopyPropertyVersionRequest } from '@/models/interfaces/request/CopyPropertyVersionRequest';
import { ImportContractsRequest } from '@/models/interfaces/request/ImportContractsRequest';
import {
    ImportPropertyDetailsRequest
} from '@/models/interfaces/request/ImportPropertyDetailsRequest';
import { LinkPropertyDetailsRequest } from '@/models/interfaces/request/LinkPropertyDetailsRequest';
import {
    LockPortfolioVersionsRequest
} from '@/models/interfaces/request/LockPortfolioVersionsRequest';
import { RemoveContractsRequest } from '@/models/interfaces/request/RemoveContractsRequest';
import {
    SaveCalculationResultsRequest
} from '@/models/interfaces/request/SaveCalculationResultsRequest';
import { SaveTranslationRequest } from '@/models/interfaces/request/SaveTranslationRequest';
import { UpdateContractDateRequest } from '@/models/interfaces/request/UpdateContractDateRequest';
import {
    UpdateContractProlongingTypeRequest
} from '@/models/interfaces/request/UpdateContractProlongingTypeRequest';
import {
    UpdateContractRecoveryTaxTypeRequest
} from '@/models/interfaces/request/UpdateContractRecoveryTaxTypeRequest';
import {
    UpdateContractTypeOfPaymentRequest
} from '@/models/interfaces/request/UpdateContractTypeOfPaymentRequest';
import { UpdateContractVatRequest } from '@/models/interfaces/request/UpdateContractVatRequest';
import {
    UpdateCostIncomesFirstIncrementRequest
} from '@/models/interfaces/request/UpdateCostIncomesFirstIncrementRequest';
import {
    UpdateFirstIncrementByVersionsRequest
} from '@/models/interfaces/request/UpdateFirstIncrementByVersionsRequest';
import {
    UpdatePropertiesDateRequest
} from '@/models/interfaces/request/UpdatePropertiesDateRequest';
import {
    UpdateVersionsInPortfolioRequest
} from '@/models/interfaces/request/UpdateVersionsInPortfolioRequest';
import { UpdateContractGreenDealRequest } from '@/models/interfaces/request/UpdateContractGreenDealRequest';
import { BusMessage, HttpErrorMessage } from '@/models/messages/messages';
import { PremisesArea } from '@/models/PremisesArea';
import { PropertyImport } from '@/models/PropertyImport';
import { UserSettings } from '@/models/settings/UserSettings';
import { TaxTypeCode } from '@/models/TaxTypeCode';
import { TypeOfPaymentGroup } from '@/models/TypeOfPaymentGroup';
import { Valuer } from '@/models/Valuer';
import store from '@/store/store';
import { BertilResponse } from '@/models/BertilResponse';
import { ExportPortfolioRequest } from "@/models/interfaces/request/ExportPortfolioRequest";
import { AddPropertyRequest } from '@/models/interfaces/request/AddPropertyRequest';
import { ContractSearchResponse } from "@/models/interfaces/response/ContractSearchResponse";
import { Region } from "@/models/Region";
import { UpdatePortfolioCalculationPeriodDatesRequest } from '@/models/interfaces/request/UpdatePortfolioCalculationPeriodDatesRequest';
import { UpdatePortfolioCostIncomesFirstIncrementRequest } from '@/models/interfaces/request/UpdatePortfolioCostIncomesFirstIncrementRequest';
import { UpdatePortfolioMarketRentStartRequest } from '@/models/interfaces/request/UpdatePortfolioMarketRentStartRequest';
import { UpdateContractMainPremisesTypeRequest } from '@/models/interfaces/request/UpdateContractMainPremisesTypeRequest';
import { AppRight } from "@/models/AppRight";
import { UserGroup } from '@/models/UserGroup';
import { PropertySearchRequest } from '@/models/PropertySearchRequest';
import { PropertySearchResult } from '@/models/interfaces/PropertySearchResult';
import { WebReports } from '@/models/interfaces/web-report/WebReports';
import { EditMultipleMarketRentsTemplateDto } from '@/models/EditMultipleMarketRentsTemplate';
import { ContractRecoveriesAdjustment } from '@/models/ContractRecoveriesAdjustment';
import { ContractRecoveriesAdjustmentRequest } from '@/models/interfaces/request/ContractRecoveriesAdjustmentRequest';
import { AddSensitivityAnalysisRequest } from '@/models/interfaces/request/AddSensitivityAnalysisRequest';
import { GetContractRecoveriesAdjustmentRequest } from '@/models/interfaces/request/GetContractRecoveriesAdjustmentRequest';
import { msalPluginInstance } from "@/plugins/msal-plugin";
import { AddCalculationResponse } from '@/models/interfaces/response/AddCalculationResponse';
import { ContractBulkEdit } from '@/models/ContractBulkEdit';
import { VersionKpis } from '@/models/interfaces/VersionKpis';

export default new class DataService {
    HTTP!: AxiosInstance;

    initialize(): void {
        this.HTTP = axios.create({
            baseURL: process.env.VUE_APP_FIAAPI_URL,
            headers: {
                'Content-Type': "application/json"
            }
        });

        // Add global http error handling by using an interceptor
        this.HTTP.interceptors.response.use(undefined, (error: any) => {
            let code = (error && error.response && error.response.status) ? error.response.status : "";
            let message = (error && error.message) ? error.message : "";
            let data = (error && error.response && error.response.data) ? error.response.data : "";

            if (data == 'BadAppVersion') {
                setTimeout(() => {
                    location.reload();
                }, 500);
                return;
            }

            const sendBusMessage = () => {
                let msg: BusMessage<HttpErrorMessage> = { type: "HttpError", data: { error: { statusCode: code, message: message, data: data } } };
                store.dispatch("sendBusMessage", msg);
            }

            if (data) {
                if (Array.isArray(data)) {
                    var containsValidationErrors = data.find(error => error.type == ErrorType.ValidationError)
                    if (!containsValidationErrors) {
                        sendBusMessage();
                    }
                } else if (data.type !== ErrorType.ValidationError) {
                    sendBusMessage();
                }
            } else {
                sendBusMessage();
            }
            return Promise.reject(error);
        });

        // Add auth token to every request by using an interceptor
        this.HTTP.interceptors.request.use(async config => {
            const headers = await this.getAuthHeaders();
            if (!config || !config.headers) return config;
            config.headers.Authorization = headers.Authorization;
            config.headers.databaseId = headers.databaseId;
            config.headers.connectionId = headers.connectionId;
            config.headers.appVersion = headers.appVersion;
            return config;
        }, undefined);
    }

    async getAuthHeaders(): Promise<any> {
        const token = await msalPluginInstance.acquireToken();
        return {
            Authorization: "Bearer " + token,
            databaseId: store.getters.getUser ? store.getters.getUser.databaseId : "",
            connectionId: store.getters.getConnectionId,
            appVersion: process.env.VUE_APP_VERSION || "?"

        };
    }

    searchProperties(request: PropertySearchRequest): AxiosPromise<PropertySearchResult> {
        const countries = request.countries ? request.countries.join() : "";
        const regions = request.regions ? request.regions.join() : "";
        const counties = request.counties ? request.counties.join() : "";
        const subcounties = request.subcounties ? request.subcounties.join() : "";
        const premisesTypes = request.premisesTypes ? request.premisesTypes.join() : "";
        const taxTypeCodes = request.taxTypeCodes ? request.taxTypeCodes.join() : "";
        return this.HTTP.get(`properties/search?name=${request.name || ""}&identifier=${request.identifier || ""}&tenants=${request.tenants || ""}&premisesTypes=${premisesTypes}&taxTypeCodes=${taxTypeCodes}&fromArea=${request.fromArea || null}&toArea=${request.toArea || null}&fromVersion=${request.fromVersion || ""}&toVersion=${request.toVersion || ""}&countries=${countries}&regions=${regions}&counties=${counties}&subcounties=${subcounties}&page=${request.page}&maxRows=${request.maxRows}&portfolioId=${request.portfolioId}&address=${request.address ?? ''}`);
    }

    searchPortfolios(name: string): AxiosPromise<PortfolioSearchResult[]> {
        return this.HTTP.get(`portfolios/search?name=${name}`);
    }

    searchContracts(request: ContractSearchRequest): AxiosPromise<ContractSearchResponse> {
        return this.HTTP.post(`contracts/search`, JSON.stringify(request));
    }

    getVersionsByProperty(propertyId: number, tenants?: string, premisesTypeIds?: number[], fromArea?: number | null, toArea?: number | null, fromVersion?: string, toVersion?: string, portfolioId?: number | null, taxTypeCodes?: number[]): AxiosPromise<PropertyVersionSlim[]> {
        const premisesTypes = premisesTypeIds ? premisesTypeIds.join() : "";
        return this.HTTP.get(`Versions/FindByPropertyId?propertyId=${propertyId}&tenants=${tenants}&premisesTypes=${premisesTypes}&fromArea=${fromArea}&toArea=${toArea}&fromVersion=${fromVersion}&toVersion=${toVersion}&portfolioId=${portfolioId}&taxTypeCodes=${taxTypeCodes}`);
    }

    getVersionsByPortfolio(portfolioId: number): AxiosPromise<PropertyVersionSlim[]> {
        return this.HTTP.get(`Versions/FindByPortfolioId?portfolioId=${portfolioId}`);
    }

    getPropertyVersion(versionId: number): AxiosPromise<PropertyVersion> {
        return this.HTTP.get(`Versions/Get?id=${versionId}`);
    }

    getPropertyVersionSlim(versionId: number): AxiosPromise<PropertyVersionSlim> {
        return this.HTTP.get(`Versions/GetSlim?id=${versionId}`);
    }

    getContract(contractId: number): AxiosPromise<Contract> {
        return this.HTTP.get(`contracts/byId?id=${contractId}`);
    }

    getPropertyInfo(propertyId: number): AxiosPromise<any> {
        return this.HTTP.get(`properties/info?propertyId=${propertyId}`);
    }

    saveContract(contract: Contract): AxiosPromise<Contract> {
        return this.HTTP.post("contracts/save", JSON.stringify(contract));
    }

    deleteContracts(request: RemoveContractsRequest): AxiosPromise<any> {
        return this.HTTP.post('contracts/delete', JSON.stringify(request));
    }

    getContractList(versionId: number): AxiosPromise<ContractListItem[]> {
        return this.HTTP.get(`contracts/list?versionId=${versionId}`);
    }

    getContractBulkEditTemplate(propertyVersionId: number | null, portfolioId: number | null): AxiosPromise<ContractBulkEdit[]> {
        return this.HTTP.get(`contracts/BulkEditTemplate?versionId=${propertyVersionId}&portfolioId=${portfolioId}`);
    }

    saveContractBulkEdit(propertyVersionId: number | null, portfolioId: number | null, contracts: ContractBulkEdit[]): AxiosPromise<void> {
        return this.HTTP.post(`contracts/SaveContractBulkEdit?versionId=${propertyVersionId}&portfolioId=${portfolioId}`, JSON.stringify(contracts));
    }

    getUser(): AxiosPromise<User> {
        return this.HTTP.get(`accounts/getUser`);
    }

    getUsers(customerId: number): AxiosPromise<User[]> {
        return this.HTTP.get(`accounts/getUsers/${customerId}`);
    }

    getCustomer(): AxiosPromise<Customer> {
        return this.HTTP.get(`accounts/getCustomer`);
    }

    getCustomers(): AxiosPromise<Customer[]> {
        return this.HTTP.get(`accounts/getCustomers`);
    }

    getAppRights(): AxiosPromise<AppRight[]> {
        return this.HTTP.get(`accounts/getAppRights`);
    }

    getDatabases(): AxiosPromise<Database> {
        return this.HTTP.get(`accounts/getDatabases`);
    }

    saveUserSettings(userSettings: UserSettings): AxiosPromise<UserSettings> {
        return this.HTTP.post(`accounts/saveUserSettings`, JSON.stringify(userSettings))
    }

    saveUser(user: User): AxiosPromise<User> {
        return this.HTTP.put('accounts/saveUser', JSON.stringify(user));
    }

    getUserImage(userId: number, pixels: number): AxiosPromise<string> {
        return this.HTTP.get(`accounts/getImage?userId=${userId}&pixels=${pixels}`, { headers: { "Content-Type": "image/jpeg" } });
    }

    deleteUserImage(id: number): AxiosPromise<any> {
        return this.HTTP.delete(`accounts/DeleteImage/${id}`);
    }

    saveUserGroup(userGroup: UserGroup): AxiosPromise<UserGroup> {
        return this.HTTP.post('accounts/saveUserGroup', JSON.stringify(userGroup));
    }

    deleteUserGroup(id: number): AxiosPromise<any> {
        return this.HTTP.delete(`accounts/DeleteUserGroup/${id}`);
    }

    updatePropertyPosition(propertyId: number, longitude: number, latitude: number): AxiosPromise<any> {
        return this.HTTP.post("geo/updatePropertyPosition", JSON.stringify({ propertyId, longitude, latitude }));
    }

    updateUserDefArea(id: number, name: string, geometry: string, isPublic: boolean): AxiosPromise<any> {
        return this.HTTP.post("geo/updateUserDefArea", JSON.stringify({ id, name, geometry, isPublic }));
    }

    removeUserDefArea(id: number): AxiosPromise<any> {
        return this.HTTP.post("geo/removeUserDefArea", JSON.stringify({ id }));
    }

    getUserDefAreas(): AxiosPromise<any> {
        return this.HTTP.get(`geo/userDefAreas`);
    }

    getPropertiesByArea(polygon: number[][][]): AxiosPromise<any> {
        return this.HTTP.get(`geo/GetPropertiesByGeometry?geomRequest=within(geom,MULTIPOLYGON((( ${polygon} ))))`);
    }

    getFavorites(): AxiosPromise<PropertyVersionSlim[]> {
        return this.HTTP.get(`Versions/UserFavorites`);
    }

    getRecent(): AxiosPromise<PropertyVersionSlim[]> {
        return this.HTTP.get(`Versions/UserLatest`);
    }

    getCalculationValues(id: number, portfolio: boolean): AxiosPromise<any> {
        return this.HTTP.get(`calculationvalues/get?id=${id}&portfolio=${portfolio}`);
    }

    getCostIncomeCalculatedDatas(versionId: number, typeOfPaymentId: number) {
        return this.HTTP.get(`calculateddatas/costincome?versionId=${versionId}&typeOfPaymentId=${typeOfPaymentId}`);
    }

    getEstimatedMarketValue(id: number, portfolio: boolean): AxiosPromise<any> {
        return this.HTTP.get(`estimatedmarketvalues/get?id=${id}&portfolio=${portfolio}`);
    }

    searchValuations(id: number, isPortfolio: boolean): AxiosPromise<any> {
        return this.HTTP.get(`valuations/search?id=${id}&isPortfolio=${isPortfolio}`);
    }

    getContractsSummary(propertyId: number): AxiosPromise<any> {
        return this.HTTP.get(`properties/contractsSummary?propertyId=${propertyId}`);
    }

    getLatestPropertyVersionId(propertyId: number): AxiosPromise<PropertyVersionSlim> {
        return this.HTTP.get(`Versions/FindLatestByPropertyId?propertyId=${propertyId}`);
    }

    addValuation(request: any): AxiosPromise<any> {
        return this.HTTP.post("reports/addvaluation", request, { headers: { 'Content-Type': 'multipart/form-data' } });
    }

    getValuationInstances(): AxiosPromise<any> {
        return this.HTTP.get(`valuations/instances`);
    }

    getReportTemplates(): AxiosPromise<any> {
        return this.HTTP.get(`calculations/reportTemplates`);
    }

    saveCalculationResults(request: SaveCalculationResultsRequest): AxiosPromise<EstimatedMarketValue> {
        return this.HTTP.post("calculations/SaveResults", JSON.stringify(request));
    }

    deleteCalculationResults(emv: EstimatedMarketValue): AxiosPromise<EstimatedMarketValue> {
        return this.HTTP.post("calculations/DeleteResults", JSON.stringify(emv));
    }

    saveInterval(estMarketValue: EstimatedMarketValue): AxiosPromise<EstimatedMarketValue> {
        return this.HTTP.post("calculations/saveInterval", JSON.stringify(estMarketValue));
    }

    getCountriesOptimized(): AxiosPromise<Country[]> {
        return this.HTTP.get(`countries/GetOptimized`);
    }

    getRegionsByCountryOptimized(countryId: number): AxiosPromise<Region[]> {
        return this.HTTP.get(`regions/GetByCountryIdOptimized?countryId=${countryId}`);
    }

    getCountiesByCountryOptimized(countryId: number): AxiosPromise<County[]> {
        return this.HTTP.get(`counties/GetByCountryIdOptimized?countryId=${countryId}`);
    }

    getCountiesByRegionOptimized(regionId: number): AxiosPromise<County[]> {
        return this.HTTP.get(`counties/GetByRegionIdOptimized?regionId=${regionId}`);
    }

    getSubcountiesByCountyOptimized(countyId: number): AxiosPromise<Subcounty[]> {
        return this.HTTP.get(`subcounties/GetByCountyIdOptimized?countyId=${countyId}`);
    }

    getMarketRent(versionId: number): AxiosPromise<MarketRent[]> {
        return this.HTTP.get(`MarketRents/FindByVersionId?versionId=${versionId}`);
    }

    getDevelopment(versionId: number): AxiosPromise<Development[]> {
        return this.HTTP.get(`Developments/FindByVersionId?versionId=${versionId}`);
    }

    getVersionPortfolios(versionId: number): AxiosPromise<VersionPortfolio[]> {
        return this.HTTP.get(`versions/Portfolios?versionId=${versionId}`);
    }

    getClassifications(versionId: number): AxiosPromise<Classification[]> {
        return this.HTTP.get(`Classifications/FindByVersionId?versionId=${versionId}`);
    }

    saveVersionPortfolios(versionId: number, items: VersionPortfolio[]): AxiosPromise<VersionPortfolio[]> {
        return this.HTTP.post("versions/savebatchversionportfolios", JSON.stringify({ id: versionId, items: items }));
    }

    saveMarketRents(versionId: number, items: MarketRent[]): AxiosPromise<MarketRent[]> {
        return this.HTTP.post("marketrents/savebatch", JSON.stringify({ id: versionId, items: items }));
    }

    getTaxes(versionId: number): AxiosPromise<Tax[]> {
        return this.HTTP.get(`Taxes/FindByVersionId?versionId=${versionId}`);
    }

    refreshClassifications(versionId: number): AxiosPromise<any> {
        return this.HTTP.post("Classifications/Refresh", JSON.stringify(versionId));
    }

    getPropertyDetails(propertyId: number): AxiosPromise<PropertyDetail> {
        return this.HTTP.get(`properties/details?propertyId=${propertyId}`);
    }

    getCostIncomeList(versionId: number): AxiosPromise<CostIncomeListItem[]> {
        return this.HTTP.get(`costincomes/list?versionId=${versionId}`);
    }

    getCostIncome(costIncomeId: number): AxiosPromise<CostIncome> {
        return this.HTTP.get(`costincomes/byId?id=${costIncomeId}`);
    }

    getPortfolio(portfolioId: number, includeCalc: boolean): AxiosPromise<Portfolio> {
        return this.HTTP.get(`portfolios/byId?id=${portfolioId}&includeCalc=${includeCalc}`);
    }

    savePortfolio(portfolio: Portfolio): AxiosPromise<Portfolio> {
        return this.HTTP.post("portfolios/save", JSON.stringify(portfolio));
    }

    addPropertyVersion(request: PropertyVersionSlim): AxiosPromise<PropertyVersionSlim> {
        return this.HTTP.post("versions/Add", JSON.stringify(request));
    }

    updatePropertyVersion(request: PropertyVersionSlim): AxiosPromise<any> {
        return this.HTTP.put("Versions/Update", JSON.stringify(request));
    }

    getProperty(propertyId: number, includeDetails: boolean): AxiosPromise<Property> {
        return this.HTTP.get(`properties/byId?propertyId=${propertyId}&includeDetails=${includeDetails}`);
    }

    getPropertyTemplates(): AxiosPromise<PropertyTemplate[]> {
        return this.HTTP.get(`properties/PropertyTemplates`);
    }

    getPropertyVersionBuildRights(versionId: number): AxiosPromise<BuildingRight[]> {
        return this.HTTP.get(`versions/getRights?versionId=${versionId}`);
    }

    savePropertyVersionBuildRights(versionId: number, rights: BuildingRight[]): AxiosPromise<void> {
        return this.HTTP.post(`versions/saveRights?versionId=${versionId}`, JSON.stringify(rights));
    }

    getGroundLeases(propertyId: number): AxiosPromise<PropertyLand> {
        return this.HTTP.get(`properties/groundLeases?propertyId=${propertyId}`);
    }

    getProperties(propertyIds: number[]): AxiosPromise<PropertySearchResultItem[]> {
        return this.HTTP.post('properties/byIds', JSON.stringify(propertyIds));
    }

    searchLocations(searchText: string): AxiosPromise<PropertyLocation[]> {
        return this.HTTP.get(`properties/searchLocations?searchText=${searchText}`);
    }

    getLatestLocations(limit: number): AxiosPromise<PropertyLocation[]> {
        return this.HTTP.get(`properties/latestLocations?limit=${limit}`);
    }

    updateProperty(request: Property): AxiosPromise<any> {
        return this.HTTP.post("properties/Update", JSON.stringify(request));
    }

    getCurrencies(languageId: number | null = null, useFallback: boolean | null = null): AxiosPromise<Currency[]> {
        return this.HTTP.get(`currencies/getall?languageId=${languageId}&useFallback=${useFallback}`);
    }

    saveCurrency(request: Currency): AxiosPromise<Currency> {
        return this.HTTP.post("currencies/save", JSON.stringify(request));
    }

    deleteCurrency(id: number): AxiosPromise<Currency> {
        return this.HTTP.delete(`currencies/delete?id=${id}`);
    }

    importContracts(request: ImportContractsRequest) {
        return this.HTTP.post("importcontracts/import", JSON.stringify(request));
    }

    getJobQueue(limit: number): AxiosPromise<Job[]> {
        return this.HTTP.get(`jobs/getQueue?limit=${limit}`);
    }

    copyPortfolio(request: CopyPortfolioRequest) {
        return this.HTTP.post("portfolios/copy", JSON.stringify(request));
    }

    getJobResult(jobId: string): AxiosPromise<string> {
        return this.HTTP.get(`jobs/getResult?jobId=${jobId}`);
    }

    getDevelopmentTypes(languageId: number | null = null, useFallback: boolean | null = null): AxiosPromise<DevelopmentType[]> {
        return this.HTTP.get(`developmenttypes/getall?languageId=${languageId}&useFallback=${useFallback}`);
    }

    saveDevelopmentType(request: DevelopmentType): AxiosPromise<DevelopmentType> {
        return this.HTTP.post("developmenttypes/save", JSON.stringify(request));
    }

    deleteDevelopmentType(id: number): AxiosPromise<DevelopmentType> {
        return this.HTTP.delete(`developmenttypes/delete?id=${id}`);
    }

    getIndexClauses(languageId: number | null = null, useFallback: boolean | null = null): AxiosPromise<IndexClause[]> {
        return this.HTTP.get(`indexclauses/getall?languageId=${languageId}&useFallback=${useFallback}`);
    }

    saveIndexClause(request: IndexClause): AxiosPromise<IndexClause> {
        return this.HTTP.post("indexclauses/save", JSON.stringify(request));
    }

    deleteIndexClause(id: number): AxiosPromise<IndexClause> {
        return this.HTTP.delete(`indexclauses/delete?id=${id}`);
    }

    getIndexDenotations(languageId: number | null = null, useFallback: boolean | null = null): AxiosPromise<IndexDenotation[]> {
        return this.HTTP.get(`indexdenotations/getall?languageId=${languageId}&useFallback=${useFallback}`);
    }

    saveIndexDenotation(request: IndexDenotation): AxiosPromise<IndexDenotation> {
        return this.HTTP.post("indexdenotations/save", JSON.stringify(request));
    }

    deleteIndexDenotation(id: number): AxiosPromise<IndexDenotation> {
        return this.HTTP.delete(`indexdenotations/delete?id=${id}`);
    }

    getIpd6(languageId: number | null = null, useFallback: boolean | null = null): AxiosPromise<Ipd6[]> {
        return this.HTTP.get(`ipd6/getall?languageId=${languageId}&useFallback=${useFallback}`);
    }

    saveIpd6(request: Ipd6): AxiosPromise<Ipd6> {
        return this.HTTP.post("ipd6/save", JSON.stringify(request));
    }

    deleteIpd6(id: number): AxiosPromise<Ipd6> {
        return this.HTTP.delete(`ipd6/delete?id=${id}`);
    }

    getMarketTypes(languageId: number | null = null, useFallback: boolean | null = null): AxiosPromise<MarketType[]> {
        return this.HTTP.get(`markettypes/getall?languageId=${languageId}&useFallback=${useFallback}`);
    }

    saveMarketType(request: MarketType): AxiosPromise<MarketType> {
        return this.HTTP.post("markettypes/save", JSON.stringify(request));
    }

    deleteMarketType(id: number): AxiosPromise<MarketType> {
        return this.HTTP.delete(`markettypes/delete?id=${id}`);
    }

    getPaymentGroups(languageId: number | null = null, useFallback: boolean | null = null): AxiosPromise<PaymentGroup[]> {
        return this.HTTP.get(`paymentgroups/getall?languageId=${languageId}&useFallback=${useFallback}`);
    }

    getTypeOfPaymentGroups(languageId: number | null = null, useFallback: boolean | null = null): AxiosPromise<TypeOfPaymentGroup[]> {
        return this.HTTP.get(`typeofpaymentgroups/getall?languageId=${languageId}&useFallback=${useFallback}`);
    }

    savePaymentGroup(request: PaymentGroup): AxiosPromise<PaymentGroup> {
        return this.HTTP.post("paymentgroups/save", JSON.stringify(request));
    }

    deletePaymentGroup(id: number): AxiosPromise<PaymentGroup> {
        return this.HTTP.delete(`paymentgroups/delete?id=${id}`);
    }

    getPremisesTypes(languageId: number | null = null, useFallback: boolean | null = null): AxiosPromise<PremisesType[]> {
        return this.HTTP.get(`premisestypes/getall?languageId=${languageId}&useFallback=${useFallback}`);
    }

    savePremisesType(request: PremisesType): AxiosPromise<PremisesType> {
        return this.HTTP.post("premisestypes/save", JSON.stringify(request));
    }

    deletePremisesType(id: number): AxiosPromise<PremisesType> {
        return this.HTTP.delete(`premisestypes/delete?id=${id}`);
    }

    getPremisesTypeGroups(languageId: number | null = null, useFallback: boolean | null = null): AxiosPromise<PremisesTypeGroup[]> {
        return this.HTTP.get(`premisestypegroups/getall?languageId=${languageId}&useFallback=${useFallback}`);
    }
    savePremisesTypeGroup(entity: PremisesTypeGroup): AxiosPromise<PremisesTypeGroup> {
        return this.HTTP.post(`premisestypegroups/save?languageId=${entity.languageId}`, JSON.stringify(entity));
    }

    deletePremisesTypeGroup(id: number): AxiosPromise<PremisesTypeGroup> {
        return this.HTTP.delete(`premisestypegroups/delete?id=${id}`);
    }

    getPrognosisAlternatives(languageId: number | null = null, useFallback: boolean | null = null): AxiosPromise<PrognosisAlternative[]> {
        return this.HTTP.get(`prognosisalternatives/getall?languageId=${languageId}&useFallback=${useFallback}`);
    }

    savePrognosisAlternative(request: PrognosisAlternative): AxiosPromise<PrognosisAlternative> {
        return this.HTTP.post("prognosisalternatives/save", JSON.stringify(request));
    }

    deletePrognosisAlternative(id: number): AxiosPromise<PrognosisAlternative> {
        return this.HTTP.delete(`prognosisalternatives/delete?id=${id}`);
    }

    getPrognosisParameters(languageId: number | null = null, useFallback: boolean | null = null): AxiosPromise<PrognosisParameter[]> {
        return this.HTTP.get(`prognosisparameters/getall?languageId=${languageId}&useFallback=${useFallback}`);
    }

    savePrognosisParameter(request: PrognosisParameter): AxiosPromise<PrognosisParameter> {
        return this.HTTP.post("prognosisparameters/save", JSON.stringify(request));
    }

    deletePrognosisParameter(id: number): AxiosPromise<PrognosisParameter> {
        return this.HTTP.delete(`prognosisparameters/delete?id=${id}`);
    }

    getPropertyTaxRatesByType(typeId: number | null): AxiosPromise<PropertyTaxRate[]> {
        return this.HTTP.get(`propertytaxrates/getbytype?typeId=${typeId || '-1'}`);
    }

    savePropertyTaxRate(request: PropertyTaxRate): AxiosPromise<PropertyTaxRate> {
        return this.HTTP.post("propertytaxrates/save", JSON.stringify(request));
    }

    deletePropertyTaxRate(id: number): AxiosPromise<PropertyTaxRate> {
        return this.HTTP.delete(`propertytaxrates/delete?id=${id}`);
    }

    getPropertyTaxTypes(languageId: number | null = null, useFallback: boolean | null = null): AxiosPromise<PropertyTaxType[]> {
        return this.HTTP.get(`propertytaxtypes/getall?languageId=${languageId}&useFallback=${useFallback}`);
    }

    savePropertyTaxType(request: PropertyTaxType): AxiosPromise<PropertyTaxType> {
        return this.HTTP.post("propertytaxtypes/save", JSON.stringify(request));
    }

    deletePropertyTaxType(id: number): AxiosPromise<PropertyTaxType> {
        return this.HTTP.delete(`propertytaxtypes/delete?id=${id}`);
    }

    getStatuses(languageId: number | null = null, useFallback: boolean | null = null): AxiosPromise<Status[]> {
        return this.HTTP.get(`statuses/getall?languageId=${languageId}&useFallback=${useFallback}`);
    }

    saveStatus(request: Status): AxiosPromise<Status> {
        return this.HTTP.post("statuses/save", JSON.stringify(request));
    }

    deleteStatus(id: number): AxiosPromise<Status> {
        return this.HTTP.delete(`statuses/delete?id=${id}`);
    }

    getTypeOfPayments(languageId: number | null = null, useFallback: boolean | null = null): AxiosPromise<TypeOfPayment[]> {
        return this.HTTP.get(`typeofpayments/getall?languageId=${languageId}&useFallback=${useFallback}`);
    }

    saveTypeOfPayment(request: TypeOfPayment): AxiosPromise<TypeOfPayment> {
        return this.HTTP.post("typeofpayments/save", JSON.stringify(request));
    }

    saveTypeOfPaymentGroup(request: TypeOfPaymentGroup): AxiosPromise<TypeOfPaymentGroup> {
        return this.HTTP.post("typeofpaymentgroups/save", JSON.stringify(request));
    }
    saveTypeOfPaymentGroupPayment(request: TypeOfPaymentGroup): AxiosPromise<TypeOfPaymentGroup> {
        return this.HTTP.post("typeofpaymentgroups/add", JSON.stringify(request));
    }
    deleteTypeOfPaymentGroupPayment(request: TypeOfPaymentGroup): AxiosPromise<TypeOfPaymentGroup> {
        return this.HTTP.post("typeofpaymentgroups/remove", JSON.stringify(request));
    }

    deleteTypeOfPayment(id: number): AxiosPromise<TypeOfPayment> {
        return this.HTTP.delete(`typeofpayments/delete?id=${id}`);
    }

    getPrognosesByAlternative(alternativeId: number | null): AxiosPromise<Prognosis[]> {
        return this.HTTP.get(`prognoses/getbyalternative?alternativeId=${alternativeId || '-1'}`);
    }

    getPrognosesByAlternativeAndParameter(alternativeId: number | null, parameterId: number | null): AxiosPromise<Prognosis[]> {
        return this.HTTP.get(`prognoses/getbyalternativeandparameter?alternativeId=${alternativeId || '-1'}&parameterId=${parameterId || '-1'}`);
    }

    savePrognosis(request: Prognosis): AxiosPromise<Prognosis> {
        return this.HTTP.post("prognoses/save", JSON.stringify(request));
    }

    deletePrognosis(id: number): AxiosPromise<Prognosis> {
        return this.HTTP.delete(`prognoses/delete?id=${id}`);
    }

    deleteByParameterId(parameterId: number, alternativeId: number): AxiosPromise<Prognosis> {
        return this.HTTP.delete(`prognoses/deletebyparameterid?parameterId=${parameterId}&alternativeId=${alternativeId}`);
    }

    getIndexValueByDenotation(denotationId: number | null): AxiosPromise<IndexValue[]> {
        return this.HTTP.get(`indexvalues/getbydenotation?denotationId=${denotationId || '-1'}`);
    }

    saveIndexValue(request: IndexValue): AxiosPromise<IndexValue> {
        return this.HTTP.post("indexvalues/save", JSON.stringify(request));
    }

    deleteIndexValue(id: number): AxiosPromise<IndexValue> {
        return this.HTTP.delete(`indexvalues/delete?id=${id}`);
    }

    getCountries(languageId: number | null = null, useFallback: boolean | null = null): AxiosPromise<Country[]> {
        return this.HTTP.get(`countries/getall?languageId=${languageId}&useFallback=${useFallback}`);
    }

    saveCountry(request: Country): AxiosPromise<Country> {
        return this.HTTP.post("countries/save", JSON.stringify(request));
    }

    deleteCountry(id: number): AxiosPromise<Country> {
        return this.HTTP.delete(`countries/delete?id=${id}`);
    }

    getRegionsByCountry(countryId: number | null): AxiosPromise<Region[]> {
        return this.HTTP.get(`regions/getbycountryid?countryId=${countryId || '-1'}`);
    }

    saveRegion(request: Region): AxiosPromise<Region> {
        return this.HTTP.post("regions/save", JSON.stringify(request));
    }

    deleteRegion(id: number): AxiosPromise<Region> {
        return this.HTTP.delete(`regions/delete?id=${id}`);
    }

    getCountiesByCountry(countryId: number | null): AxiosPromise<County[]> {
        return this.HTTP.get(`counties/getbycountryid?countryId=${countryId || '-1'}`);
    }

    getCountiesByRegion(regionId: number | null): AxiosPromise<County[]> {
        return this.HTTP.get(`counties/getbyregionidoptimized?regionId=${regionId || '-1'}`);
    }

    saveCounty(request: County): AxiosPromise<County> {
        return this.HTTP.post("counties/save", JSON.stringify(request));
    }

    deleteCounty(id: number): AxiosPromise<County> {
        return this.HTTP.delete(`counties/delete?id=${id}`);
    }

    getSubcountiesByCountyId(countyId: number | null): AxiosPromise<Subcounty[]> {
        return this.HTTP.get(`subcounties/getbycountyid?countyId=${countyId || '-1'}`);
    }

    saveSubcounty(request: Subcounty): AxiosPromise<Subcounty> {
        return this.HTTP.post("subcounties/save", JSON.stringify(request));
    }

    deleteSubcounty(id: number): AxiosPromise<Subcounty> {
        return this.HTTP.delete(`subcounties/delete?id=${id}`);
    }

    updateRecoveriesFirstIncrement(request: UpdateContractDateRequest): AxiosPromise<any> {
        return this.HTTP.post("contracts/updaterecoveriesfirstincrement", JSON.stringify(request));
    }

    updateContractFirstIncrement(request: UpdateContractDateRequest): AxiosPromise<any> {
        return this.HTTP.post("contracts/updatecontractfirstincrement", JSON.stringify(request));
    }

    addCalculation(request: AddCalculationRequest): AxiosPromise<AddCalculationResponse> {
        return this.HTTP.post("calculations/add", JSON.stringify(request));
    }
    addMultiplePortfolioCalculations(request: AddMultipleVersionsCalculationRequest): AxiosPromise<any> {
        return this.HTTP.post("calculations/calculateMultipleVersions", JSON.stringify(request));
    }

    getTypeOfPaymnetStreams(languageId: number | null = null, useFallback: boolean | null = null): AxiosPromise<TypeOfPaymentStream[]> {
        return this.HTTP.get(`typeofpaymentstreams/getall?languageId=${languageId}&useFallback=${useFallback}`);
    }

    getPropertyReadyVersion(versionId: number): AxiosPromise<PropertyReadyInfo> {
        return this.HTTP.get(`versions/readystatus?versionId=${versionId}`);
    }

    getPropertyReadyPortfolio(portfolioId: number): AxiosPromise<PropertyReadyInfo> {
        return this.HTTP.get(`portfolios/readystatus?portfolioId=${portfolioId}`);
    }

    addIpdNotReadyReport(request: AddIpd6NotReadyRequest): AxiosPromise<any> {
        return this.HTTP.post("reports/addipd6notready", JSON.stringify(request));
    }

    addIpd4Report(request: AddIpd4Request): AxiosPromise<any> {
        return this.HTTP.post("reports/addipd4", JSON.stringify(request));
    }

    addIpd6Report(request: AddIpd6Request): AxiosPromise<any> {
        return this.HTTP.post("reports/addipd6", JSON.stringify(request));
    }

    addKeyRatiosReport(request: AddKeyRatiosRequest): AxiosPromise<any> {
        return this.HTTP.post("reports/addkeyratios", JSON.stringify(request));
    }

    addKeyRatiosExtendedReport(request: AddKeyRatiosExtendedRequest): AxiosPromise<any> {
        return this.HTTP.post("reports/addkeyratiosextended", JSON.stringify(request));
    }

    addSensitivityAnalysisReport(request: AddSensitivityAnalysisRequest): AxiosPromise<any> {
        return this.HTTP.post("reports/addsensitivityanalysis", JSON.stringify(request));
    }

    addPropertyAddressListReport(request: AddPropertyAddressListRequest): AxiosPromise<any> {
        return this.HTTP.post("reports/addpropertyaddresslist", JSON.stringify(request));
    }

    addPortfolioDetailsReport(request: AddPortfolioDetailsRequest): AxiosPromise<any> {
        return this.HTTP.post("reports/addportfoliodetails", JSON.stringify(request));
    }

    addContractsToExcelReport(request: AddContractsToExcelRequest): AxiosPromise<any> {
        return this.HTTP.post("reports/addcontractstoexcel", JSON.stringify(request));
    }

    addProperty(request: AddPropertyRequest): AxiosPromise<Property> {
        return this.HTTP.post("properties/add", JSON.stringify(request));
    }

    getLanguages(languageId: number | null = null, useFallback: boolean | null = null): AxiosPromise<Language[]> {
        return this.HTTP.get(`languages/getall?languageId=${languageId}&useFallback=${useFallback}`);
    }

    deletePropertyVersion(versionId: number): AxiosPromise<any> {
        return this.HTTP.delete(`versions/delete?versionId=${versionId}`);
    }

    deleteProperty(propertyId: number): AxiosPromise<any> {
        return this.HTTP.delete(`properties/delete?propertyId=${propertyId}`);
    }

    saveProperty(request: Property): AxiosPromise<Property> {
        return this.HTTP.post("properties/save", JSON.stringify(request));
    }

    saveCostIncome(costIncome: CostIncome): AxiosPromise<CostIncome> {
        return this.HTTP.post("costincomes/save", JSON.stringify(costIncome));
    }

    deleteCostIncomes(costIncomeIds: number[]): AxiosPromise<any> {
        return this.HTTP.delete(`costIncomes/delete?costIncomeIds=${costIncomeIds.join()}`);
    }

    copyPropertyVersion(request: CopyPropertyVersionRequest): AxiosPromise<any> {
        return this.HTTP.post("versions/copy", JSON.stringify(request));
    }

    getTaxTypeCodes(languageId: number | null = null, useFallback: boolean | null = null): AxiosPromise<TaxTypeCode[]> {
        return this.HTTP.get(`taxtypecodes/getall?languageId=${languageId}&useFallback=${useFallback}`);
    }

    saveTaxTypeCode(request: TaxTypeCode): AxiosPromise<TaxTypeCode> {
        return this.HTTP.post("taxtypecodes/save", JSON.stringify(request));
    }

    deleteTaxTypeCode(id: number): AxiosPromise<TaxTypeCode> {
        return this.HTTP.delete(`taxtypecodes/delete?id=${id}`);
    }

    copyContract(request: CopyContractRequest): AxiosPromise<number> {
        return this.HTTP.post("contracts/copy", JSON.stringify(request));
    }

    copyCostIncome(request: CopyCostIncomeRequest): AxiosPromise<number> {
        return this.HTTP.post("costincomes/copy", JSON.stringify(request));
    }

    getReportFile(jobId: string): AxiosPromise<any> {
        return this.HTTP.get(`reports/getReport?jobId=${jobId}`, { headers: { "Content-Type": "text/plain" } });
    }

    addVersionFavorite(versionId: number): AxiosPromise<any> {
        return this.HTTP.post("versions/addfavorite", JSON.stringify(versionId));
    }

    deleteVersionFavorite(versionId: number): AxiosPromise<any> {
        return this.HTTP.delete(`versions/deletefavorite?versionId=${versionId}`);
    }

    setLanguage(languageId: number): AxiosPromise<any> {
        return this.HTTP.post("accounts/setlanguage", JSON.stringify(languageId));
    }

    setDatabase(databaseId: number): AxiosPromise<any> {
        return this.HTTP.post("accounts/setdatabase", JSON.stringify(databaseId));
    }

    saveTaxes(versionId: number, items: Tax[]): AxiosPromise<Tax[]> {
        return this.HTTP.post("taxes/savebatch", JSON.stringify({ id: versionId, items: items }));
    }

    getTaxesByPropertyDetails(propertyId: number, versionId: number): AxiosPromise<Tax[]> {
        return this.HTTP.get(`taxes/getbypropertydetails?propertyId=${propertyId}&versionId=${versionId}`);
    }

    saveClassifications(versionId: number, items: Classification[]): AxiosPromise<Classification[]> {
        return this.HTTP.post("classifications/savebatch", JSON.stringify({ id: versionId, items: items }));
    }

    saveDevelopments(versionId: number, items: Development[]): AxiosPromise<Development[]> {
        return this.HTTP.post("developments/savebatch", JSON.stringify({ id: versionId, items: items }));
    }

    getBaseData(languageId: number | null = null, useFallback: boolean | null = null): AxiosPromise<BaseDataCache> {
        return this.HTTP.get(`basedatacache/get?languageId=${languageId}&useFallback=${useFallback}`);
    }

    lockPortfolioVersions(request: LockPortfolioVersionsRequest): AxiosPromise<any> {
        return this.HTTP.post("portfolios/lockversions", JSON.stringify(request));
    }

    copyPortfolioVersions(request: CopyPortfolioVersionsRequest): AxiosPromise<any> {
        return this.HTTP.post("portfolios/copyVersions", JSON.stringify(request));
    }

    updateRecoveriesFirstIncrementByVersions(request: UpdateFirstIncrementByVersionsRequest): AxiosPromise<any> {
        return this.HTTP.post("portfolios/updaterecoveriesfirstincrementbyversions", JSON.stringify(request));
    }

    getPropertyImage(propertyId: number, suffix: string, pixels: number): AxiosPromise<string> {
        return this.HTTP.get(`properties/getImage?propertyId=${propertyId}&suffix=${suffix}&pixels=${pixels}`, { headers: { "Content-Type": "image/jpeg" } });
    }

    deletePropertyImage(propertyId: number, suffix: string): AxiosPromise<any> {
        return this.HTTP.delete(`properties/deleteimage?propertyId=${propertyId}&suffix=${suffix}`);
    }

    getValuers(): AxiosPromise<Valuer[]> {
        return this.HTTP.get(`valuers/getall`);
    }

    deletePortfolio(portfolioId: number): AxiosPromise<any> {
        return this.HTTP.delete(`portfolios/delete?portfolioId=${portfolioId}`);
    }

    getModuleFilters(): AxiosPromise<any[]> {
        return this.HTTP.get(`valuations/FilterableModules`);
    }

    getImportTemplate(languageId: number): AxiosPromise<any> {
        return this.HTTP.get(`ImportContracts/getImportTemplate?languageId=${languageId}`)
    }

    getVersionPremisesAreas(versionId: number): AxiosPromise<PremisesArea> {
        return this.HTTP.get(`versions/premisesareas?id=${versionId}`);
    }

    getVersionTotalLeaseArea(versionId: number): AxiosPromise<number> {
        return this.HTTP.get(`versions/totalleasearea?id=${versionId}`);
    }

    exportPortfolio(request: ExportPortfolioRequest): AxiosPromise<any> {
        return this.HTTP.post("portfolios/export", JSON.stringify(request));
    }

    getExportedPortfolioFile(jobId: string): AxiosPromise<any> {
        return this.HTTP.get(`portfolios/getexportedfile?jobId=${jobId}`, { headers: { "Content-Type": "text/plain" } });
    }

    updateContractEnd(request: UpdateContractDateRequest): AxiosPromise<any> {
        return this.HTTP.post("contracts/updatecontractend", JSON.stringify(request));
    }

    updateContractStart(request: UpdateContractDateRequest): AxiosPromise<any> {
        return this.HTTP.post("contracts/updatecontractstart", JSON.stringify(request));
    }

    updateCostIncomesFirstIncrement(request: UpdateCostIncomesFirstIncrementRequest): AxiosPromise<any> {
        return this.HTTP.post("costincomes/updatefirstincrement", JSON.stringify(request));
    }

    saveTranslationRequest(request: SaveTranslationRequest): AxiosPromise<any> {
        return this.HTTP.post("languages/savetranslation", JSON.stringify(request));
    }

    updatePaymentGroupOrder(order: PaymentGroup[]): AxiosPromise<any> {
        return this.HTTP.post("paymentgroups/updateorder", JSON.stringify(order));
    }

    updateTypeOfPaymentOrder(order: TypeOfPayment[]): AxiosPromise<any> {
        return this.HTTP.post("typeofpayments/updateorder", JSON.stringify(order));
    }

    getLoanList(versionId: number): AxiosPromise<LoanListItem[]> {
        return this.HTTP.get(`loans/list?versionId=${versionId}`);
    }

    saveLoan(loan: Loan): AxiosPromise<Loan> {
        return this.HTTP.post("loans/save", JSON.stringify(loan));
    }

    deleteLoan(loanId: number): AxiosPromise<LoanListItem[]> {
        return this.HTTP.delete(`loans/delete?loanId=${loanId}`)
    }

    getLoan(id: number): AxiosPromise<Loan> {
        return this.HTTP.get(`loans/byId?id=${id}`);
    }

    updatePropertiesDate(request: UpdatePropertiesDateRequest): AxiosPromise<any> {
        return this.HTTP.post("portfolios/updatepropertiesdate", JSON.stringify(request));
    }

    getReportsData(versionId: number, isPortfolio: boolean): AxiosPromise<WebReports> {
        return this.HTTP.get(`reports/reportsdata?versionId=${versionId}&isPortfolio=${isPortfolio}`);
    }

    getVersionKpis(versionId: number): AxiosPromise<VersionKpis> {
        return this.HTTP.get(`reports/versionkpis?versionId=${versionId}`);
    }

    getEditMultipleMarketRentsTemplate(versionId: number): AxiosPromise<EditMultipleMarketRentsTemplateDto[]> {
        return this.HTTP.get(`marketrents/editmultipletemplate?versionId=${versionId}`);
    }

    getContractRecoveriesAdjustment(request: GetContractRecoveriesAdjustmentRequest): AxiosPromise<ContractRecoveriesAdjustment[]> {
        return this.HTTP.post("costincomes/contractrecoveriesadjustment", JSON.stringify(request));
    }

    saveMultipleMarketRents(marketRents: EditMultipleMarketRentsTemplateDto[]): AxiosPromise<any> {
        return this.HTTP.post(`marketrents/editmultiple`, JSON.stringify(marketRents));
    }

    saveContractRecoveries(contractRecoveries: ContractRecoveriesAdjustmentRequest): AxiosPromise<any> {
        return this.HTTP.post(`costincomes/savecontractrecoveriesadjustment`, JSON.stringify(contractRecoveries));
    }

    getReportsIsOutdated(versionId: number, isPortfolio: boolean): AxiosPromise<boolean> {
        return this.HTTP.get(`reports/reportsoutdated?versionId=${versionId}&isPortfolio=${isPortfolio}`);
    }
    getNotYetCalculatedTransactions(versionId: number, isPortfolio: boolean): AxiosPromise<any> {
        return this.HTTP.get(`reports/getnotyetcalculatedtransactions?versionId=${versionId}&isPortfolio=${isPortfolio}`);
    }

    importPropertyDetails(request: ImportPropertyDetailsRequest): AxiosPromise<number> {
        return this.HTTP.post("propertydataimport/import", JSON.stringify(request));
    }

    getPropertyPdf(importId: number): AxiosPromise<any> {
        return this.HTTP.get(`propertydataimport/getpdf?importId=${importId}`, { responseType: 'blob' });
    }

    getPropertyDataImports(propertyId: number): AxiosPromise<PropertyImport[]> {
        return this.HTTP.get(`propertydataimport/getimports?propertyId=${propertyId}`);
    }

    linkPropertyDetails(request: LinkPropertyDetailsRequest): AxiosPromise<any> {
        return this.HTTP.post("propertydataimport/link", JSON.stringify(request));
    }

    unlinkPropertyDetails(propertyId: number): AxiosPromise<any> {
        return this.HTTP.post("propertydataimport/unlink", propertyId);
    }

    getValuationInstanceList(includeInactive: boolean = false): AxiosPromise<ValuationListItem[]> {
        return this.HTTP.get(`valuations/instancelist?includeInactive=${includeInactive}`);
    }

    getValuationInstanceById(id: number): AxiosPromise<Valuation> {
        return this.HTTP.get(`valuations/getinstance?id=${id}`);
    }

    getValuationModuleList(modulePageIdx = 0, modulePageSize = 0, instanceId = 0, moduleName = ''): AxiosPromise<ValuationModuleListItem[]> {
        return this.HTTP.get(`valuations/getmodules?pageIndex=${modulePageIdx}&pageSize=${modulePageSize}&instanceId=${instanceId}&moduleName=${moduleName}`);
    }

    getValuationModuleById(id: number): AxiosPromise<ValuationModule> {
        return this.HTTP.get(`valuations/getmodule?id=${id}`);
    }

    saveValuationInstance(instance: Valuation): AxiosPromise<Valuation> {
        return this.HTTP.post("valuations/saveinstance", JSON.stringify(instance));
    }

    deleteValuationInstance(instanceId: number): AxiosPromise<any> {
        return this.HTTP.delete(`valuations/delete?instanceId=${instanceId}`);
    }

    getValuationDataTableList(): AxiosPromise<ValuationDataTableListItem[]> {
        return this.HTTP.get("valuations/datatablelist");
    }

    getValuationDataTableFields(tableId: number): AxiosPromise<ValuationDataTableField[]> {
        return this.HTTP.get(`valuations/datatablefields?spId=${tableId}`);
    }

    getValuationTableInstanceList(): AxiosPromise<ValuationTableInstance[]> {
        return this.HTTP.get("valuations/tableinstancelist");
    }

    getValuationDataTableFieldsInModule(id: number): AxiosPromise<ValuationDataTableFieldInModule[]> {
        return this.HTTP.get(`valuations/datatablefieldsinmodule?id=${id}`);
    }

    getBertilValuation(request: AddCalculationRequest): AxiosPromise<BertilResponse> {
        return this.HTTP.post("bertil/evaluate", JSON.stringify(request));
    }

    saveValuationModule(mod: ValuationModule, fields: number[], tableIds: number[]): AxiosPromise<ValuationModule> {
        let request: any = { mod: mod, fields: fields, tableIds };
        return this.HTTP.post("valuations/savemodule", JSON.stringify(request));
    }

    deleteValuationModule(moduleId: number): AxiosPromise<any> {
        return this.HTTP.delete(`valuations/deletemodule?moduleId=${moduleId}`);
    }

    updateVersionsInPortfolio(request: UpdateVersionsInPortfolioRequest): AxiosPromise<any> {
        return this.HTTP.post("portfolios/updateversions", JSON.stringify(request));
    }

    updateContractRecoveryTaxType(request: UpdateContractRecoveryTaxTypeRequest): AxiosPromise<any> {
        return this.HTTP.post("contracts/updaterecoverytaxtype", JSON.stringify(request));
    }

    updateContractProlongingType(request: UpdateContractProlongingTypeRequest): AxiosPromise<any> {
        return this.HTTP.post("contracts/updateprolongingtype", JSON.stringify(request));
    }
    updateContractTypeOfPayment(request: UpdateContractTypeOfPaymentRequest): AxiosPromise<any> {
        return this.HTTP.post("contracts/updatetypeofpayment", JSON.stringify(request));
    }
    updateContractVat(request: UpdateContractVatRequest): AxiosPromise<any> {
        return this.HTTP.post("contracts/updatevat", JSON.stringify(request));
    }
    updateContractMainPremisesType(request: UpdateContractMainPremisesTypeRequest): AxiosPromise<any> {
        return this.HTTP.post("contracts/updatemainpremisestype", JSON.stringify(request));
    }

    updatPortfolioeCalculationPeriods(request: UpdatePortfolioCalculationPeriodDatesRequest): AxiosPromise<any> {
        return this.HTTP.post("portfolios/updatecalculationperiods", JSON.stringify(request));
    }
    updatPortfolioeCostIncomesFirstIncrement(request: UpdatePortfolioCostIncomesFirstIncrementRequest): AxiosPromise<any> {
        return this.HTTP.post("portfolios/updatecostincomesfirstincrement", JSON.stringify(request));
    }
    updatPortfolioeMarketRentStart(request: UpdatePortfolioMarketRentStartRequest): AxiosPromise<any> {
        return this.HTTP.post("portfolios/updatemarketrentstart", JSON.stringify(request));
    }
    updateContractGreenDeal(request: UpdateContractGreenDealRequest): AxiosPromise<any> {
        return this.HTTP.post("contracts/updategreendeal", JSON.stringify(request));
    }

    addPropertyImage(request: any): AxiosPromise<any> {
        return this.HTTP.post('properties/importimage', request, { headers: { 'Content-Type': 'multipart/form-data' } });
    }

    cancelJob(jobId: string): AxiosPromise<string> {
        return this.HTTP.get(`jobs/cancelJob?jobId=${jobId}`);
    }

    getBoverketEnergyDeclarations(county: string, propertyName: string): AxiosPromise<any> {
        return this.HTTP.get(`boverket/getenergydeclarations?county=${county}&propertyName=${propertyName}`);
    }

    getEnvironmentalCertificates(): AxiosPromise<EnvironmentalCertificate[]> {
        return this.HTTP.get(`properties/EnvironmentalCertificates`);
    }
    getEnvironmentalCertificateLevels(): AxiosPromise<EnvironmentalCertificate[]> {
        return this.HTTP.get(`properties/EnvironmentalCertificateLevels`);
    }
}