import BaseComponent from "@/components/base-component";
import { Component, Watch } from "vue-property-decorator";
import dataService from "@/services/data-service";
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import InputTextComponent from '@/components/common/input-text/input-text';
import { ValidationError } from '@/models/interfaces/ValidationError';
import validationService from '@/services/validation-service';
import { Property, PropertyTemplate, User } from '@/models';
import LocationFormComponent from '@/components/property/property-edit/location-form/location-form';
import { AddPropertyRequest } from '@/models/interfaces/request/AddPropertyRequest';
import { mapGetters } from "vuex";

@Component({
    components: {
        YearMonthPickerComponent,
        InputTextComponent,
        LocationFormComponent
    },
    computed: mapGetters({
        user: "getUser"
    })
})
export default class AddPropertyDialog extends BaseComponent {
    user!: User;

    dialogVisible: boolean = false;
    newProperty: Property | null = null;
    complexErrors: ValidationError[] = [];
    propertyTemplates: PropertyTemplate[] = [];
    propertyTemplatesFiltered: PropertyTemplate[] = [];
    selectedPropertyTemplateId: number | null = 0;
    saving: boolean = false;

    @Watch('dialogVisible')
    async dialogVisibleWatcher() {
        if (this.dialogVisible) {
            this.propertyTemplates = (await dataService.getPropertyTemplates().then(x => x.data)).sort((a, b) => a.propertyName.localeCompare(b.propertyName));
            const defaultTemplate = this.propertyTemplates.find(x => x.isDefault);
            if (defaultTemplate) {
                this.selectedPropertyTemplateId = defaultTemplate.id;
            } else if (this.propertyTemplates.length > 0) {
                this.selectedPropertyTemplateId = this.propertyTemplates[0].id;
            }
            this.filterTemplates("");
        }
    }

    save() {
        validationService.validateProperty(this.newProperty, this.complexErrors);
        if (this.complexErrors.length > 0 || !this.newProperty) return;
        this.saving = true;

        let request = <AddPropertyRequest>{
            propertyName: this.newProperty.propertyName,
            propertyIdentifier: this.newProperty.propertyIdentifier,
            address: this.newProperty.address,
            zip: this.newProperty.zip,
            subcountyId: this.newProperty.subcountyId,
            template: this.propertyTemplates.find(x => x.id === this.selectedPropertyTemplateId),
        };

        dataService.addProperty(request).then(x => {
            this.$emit('onSave', x.data);
            this.dialogVisible = false;
        }).finally(() => {
            this.saving = false;
        });
    }

    openDialog() {
        this.newProperty = this.emptyProperty();
        this.dialogVisible = true;
    }

    closeDialog() {
        this.dialogVisible = false;
    }

    emptyProperty() {
        return <Property>{
            id: 0,
            propertyName: "",
            propertyIdentifier: "",
            address: "",
            zip: "",
            subcountyId: this.user.userSettings.defaultSubCountyId,
            providerPropertyName: null,
            providerPropertyId: null,
            linkedDateUtc: null,
            propertyImportId: null,
            viewSubcountyName: null,
            viewCountyId: this.user.userSettings.defaultCountyId,
            viewCountyName: null,
            viewCountryId: this.user.userSettings.defaultCountryId,
            viewCostDeductionPercentage: 0,
            viewBuildingRightsValue: 0,
            isTemplate: false
        };
    }

    filterTemplates(text: string) {
        if (text) {
            this.propertyTemplatesFiltered = this.propertyTemplates.filter(x => x.propertyName.toLowerCase().includes(text.toLowerCase()));
        } else {
            this.propertyTemplatesFiltered = this.propertyTemplates;
        }
    }
}