import BaseComponent from "@/components/base-component";
import { Component, Watch, Prop } from "vue-property-decorator";
import InlineEdit from '@/helpers/inline-edit';
import { AxiosPromise, AxiosResponse } from 'axios';
import { PropertyDetail, GroundLease, PropertyLand } from '@/models';
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import InputTextComponent from '@/components/common/input-text/input-text';
import { ValidationError } from '@/models/interfaces/ValidationError';


@Component({
    components: {
        YearMonthPickerComponent,
        InputNumericComponent,
        InputTextComponent
    }
})
export default class LandleaseComponent extends BaseComponent {
    @Prop()
    propertyDetail!: PropertyDetail;
    @Prop()
    complexErrors!: ValidationError[];
    @Prop()
    isLinked!: boolean;
    @Prop()
    layout!: boolean;
    
    groundLeasesGrid: InlineEdit<GroundLease>;
    oldPropertyLand: PropertyLand | null = null;
    text: string = "";
    instalmentTimes: number[] = [10,20];

    @Watch("propertyDetail", { immediate: true })
    async propertyDetailWatcher() {
        await this.groundLeasesGrid.reload();
        this.oldPropertyLand = null;
    }

    @Watch("layout")
    layoutWatcher() {
        if (this.layout == true) {
            (<any> this).$refs.landleasesTable.doLayout();
        }
    }

    constructor() {
        super();
        this.groundLeasesGrid = new InlineEdit<GroundLease>(this, "landleasesTable", this.loadLandLeases, undefined, undefined, this.newGroundLease, undefined, this.hasNoErrors);
        this.groundLeasesGrid.sync = true;
        this.groundLeasesGrid.useConfirmDeleteDialog = false;
    }

    loadLandLeases(): AxiosPromise<GroundLease[]> {
        return Promise.resolve(<AxiosResponse>{ data: this.propertyDetail.groundLeases })
    }

    landLeaseChanged() {
        if (this.propertyDetail.landLease) {
            this.propertyDetail.propertyLand = (this.oldPropertyLand == null) ? {
                id: 0, 
                propertyDetailId: this.propertyDetail.id, 
                instalmentTime: 10, 
                instalment: 0, 
                dayOfLetting: this.monthStart(), 
                fixedTo: this.monthStart(), 
                lettedTo: this.monthStart(), 
                purpose: ""
            } : this.oldPropertyLand;
        }
        else {
            this.oldPropertyLand = (this.propertyDetail.propertyLand == null) ? {
                id: 0, 
                propertyDetailId: this.propertyDetail.id, 
                instalmentTime: 10, 
                instalment: 0, 
                dayOfLetting: this.monthStart(), 
                fixedTo: this.monthStart(), 
                lettedTo: this.monthStart(), 
                purpose: ""
            } : Object.assign({}, this.propertyDetail.propertyLand);
            this.propertyDetail.propertyLand = null;
        }
    }

    newGroundLease(): GroundLease {
        return <GroundLease>{
            id: this.getNewId(this.groundLeasesGrid.entities),
            name: "",
            yearlyCost: null,
            startDate: this.newIsoDateMonth(),
            endDate: this.newIsoDateMonth(),
            purpose: ""
        }
    }
}