import Vue from 'vue';
import Router from 'vue-router';
import Fia from './components/fia/fia';
import ImportExport from './components/import-export/import-export';
import Admin from './components/admin/admin';
import store from '@/store/store';
import { AppRightEnum } from '@/constants/fia-constants';
import VDokComponent from './components/vdok/vdok';
import BaseDataComponent from "./components/base-data/basedata";

export const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Fia',
      component: Fia,
    },
    {
      path: '/ImportExport',
      name: 'ImportExport',
      component: ImportExport,
    },
    {
      path: '/vdok',
      name: 'VDok',
      component: VDokComponent,
    },
    {
      path: '/basedata',
      name: 'BaseData',
      component: BaseDataComponent,
    },
    {
      path: '/admin',
      name: 'Admin',
      component: Admin,
    },
    // {
    //   path: '/Fia',
    //   name: 'Fia',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
    // },
    {
      path: '/*',
      redirect: '/'
    }
  ],
});

router.beforeEach((to, from, next) => {
  const path = to.path.toLowerCase();
  if (path === '/fia') {
     if (!store.getters.getAppPermission(AppRightEnum.Fia)) next('/')
  }
  else if (path === '/importexport') {
    if (!store.getters.getAppPermission(AppRightEnum.ImportExport)) next('/')
  }
  else if (path === '/vdok') {
    if (!store.getters.getAppPermission(AppRightEnum.VDokEditor)) next('/')
  }
  else if (path === '/basedata') {
    if (!store.getters.getAppPermission(AppRightEnum.Fia_Basedata_Read)) next('/')
  }
  else if (path === '/admin') {
    if (!store.getters.getUser.userGroup.isAdmin) next('/')
  }
 
  next()
 })

Vue.use(Router);
