import BaseComponent from "@/components/base-component";
import { Component, Watch, Prop } from "vue-property-decorator";
import { PaymentGroup } from "@/models/PaymentGroup";
import { TypeOfPayment } from "@/models/TypeOfPayment";
import { mapGetters } from "vuex";
import { TypeOfPaymentGroup } from '@/models/TypeOfPaymentGroup';
import InlineEdit from '@/helpers/inline-edit';
import DataService from "@/services/data-service";
import { AxiosPromise, AxiosResponse } from 'axios';
import { BaseDataType, TypeOfPaymentGroupEnum } from '@/constants/fia-constants';


@Component({
    computed: mapGetters({
        typeOfPaymentsList: "getTypeOfPayments"
    })
})
export default class PaymentGroupsComponent extends BaseComponent {

    typeOfPaymentGroupsList: TypeOfPaymentGroup[] = [];
    typeOfPaymentsList!: TypeOfPayment[];
    typeOfPaymentsInGroupList: TypeOfPayment[] = [];    
    typeOfPaymentGroupsGrid: InlineEdit<TypeOfPaymentGroup>;
    
    @Prop()
    reload!: boolean;
    @Prop()
    readOnly!: boolean;

    @Watch("reload")
    reloadWatcher() {
        if (this.reload) {
            (<any>this).$refs.typeOfPaymentGroupsTable.doLayout();
        }
    }

    @Watch("typeOfPaymentGroupsGrid.currentItem")
    currentItemWatcher() { }

    @Watch("typeOfPaymentsGrid.currentItem")
    currentTypeOfPaymentWatcher(value: any) { }

    constructor() {
        super();
        this.typeOfPaymentGroupsGrid = new InlineEdit<TypeOfPaymentGroup>(this, "typeOfPaymentGroupsTable", this.loadTypeOfPaymentGroups, undefined, undefined, undefined, undefined, this.hasNoErrors);
        this.typeOfPaymentGroupsGrid.onItemChanged = this.itemChangedPaymentGroup;
        this.typeOfPaymentGroupsGrid.readOnly = this.readOnly;
    }

    async mounted() {
        this.typeOfPaymentGroupsGrid.reload();
    }

    loadTypeOfPaymentGroups(): AxiosPromise<TypeOfPaymentGroup[]> {
        return DataService.getTypeOfPaymentGroups();
    }

    addToGroup(row: any) {
        if (!this.typeOfPaymentGroupsGrid.currentItem) return;
        let paymentGroup = <TypeOfPaymentGroup> { id: this.typeOfPaymentGroupsGrid.currentItem.entity.id, typeOfPayments: [row] };
        let selectedGroup = this.typeOfPaymentGroupsGrid.currentItem.entity.id;

        DataService.saveTypeOfPaymentGroupPayment(paymentGroup).then(x => {
            (<TypeOfPayment> row).typeOfPaymentGroupIds.push(selectedGroup);    
        }).finally(async () => { 
            await this.$store.dispatch('updateCache', BaseDataType.TypeOfPayments);
        });
    }

    removeFromGroup(row: any) {
        if (!this.typeOfPaymentGroupsGrid.currentItem) return;
        let paymentGroup = <TypeOfPaymentGroup> { id: this.typeOfPaymentGroupsGrid.currentItem.entity.id, typeOfPayments: [row] };
        let selectedGroup = this.typeOfPaymentGroupsGrid.currentItem.entity.id;
        
        DataService.deleteTypeOfPaymentGroupPayment(paymentGroup).then(x => {
            let payment = (<TypeOfPayment> row);
            let index = payment.typeOfPaymentGroupIds.indexOf(selectedGroup);
            payment.typeOfPaymentGroupIds.splice(index, 1);    
        }).finally(async () => { 
            await this.$store.dispatch('updateCache', BaseDataType.TypeOfPayments);
        });
    }

    async itemChangedPaymentGroup(typeOfPaymentGroup: TypeOfPaymentGroup, action: string) {
        await this.$store.dispatch('updateCache', BaseDataType.TypeOfPaymentGroups);
        if (action === "delete")
            await this.$store.dispatch('updateCache', BaseDataType.TypeOfPaymentGroups);
    }
}