import BaseComponent from "@/components/base-component";
import { NewStreamLogic, PrognosisParameterEnum, PaymentGroupEnum, TypeOfPaymentEnum, PaymentStreamType, FiaServerApi$type, DateFormat, IndexDenotationEnum, IndexClauseEnum } from "@/constants/fia-constants";
import utils from "@/helpers/utils";
import { UserSettings, Contract, Recovery, CostIncome, PropertyVersion, TypeOfPayment, PaymentGroup, AdvancedPercentageIncrementStream, BasicPercentageStream, IndexIncrementStream, MaintenanceCost, PaymentStream, PercentageIncrementStream, ShareOfPaymentStream, VariableAmountIncrementStream } from "@/models";
import { Entity } from "@/models/interfaces/Entity";
import { PremisesArea } from "@/models/PremisesArea";
import store from "@/store/store";
import moment from "moment";

export interface NewStreamProps {
    context: BaseComponent;
    newId: number;
    typeOfFlow: 'contract' | 'recovery' | 'costIncome';
    contract: Contract | null;
    recovery: Recovery | null;
    costIncome: CostIncome | null;
    propertyVersion: PropertyVersion,
    developmentTypeId: number;
    versionPremisesAreas: PremisesArea[];
}

export default new class PaymentflowService {

    typeOfPayments!: TypeOfPayment[];
    paymentGroupsNormal!: PaymentGroup[];
    userSettings!: UserSettings;

    initialize() {
        this.typeOfPayments = store.getters.getTypeOfPayments;
        this.paymentGroupsNormal = store.getters.getPaymentGroupsNormal;
        this.userSettings = store.getters.getUser.userSettings;
    }
    
    newStream(props: NewStreamProps) {
        const newStreamLogic: NewStreamLogic = this.userSettings.newStreamLogic;
        switch (newStreamLogic) {
            case NewStreamLogic.Finnish:
                return this.createFinnishPaymentStream(props);
            case NewStreamLogic.Default:
            default:
                return this.createDefaultPaymentStream(props);
        }
    }

    private createFinnishPaymentStream(props: NewStreamProps): PaymentStream {
        let newStream = <PaymentStream>{
            id: props.newId,
            comment: "",
            inAdvance: true,
            paymentFrequency: 1,
            prolongingId: props.newId
        };

        this.setStreamDates(newStream, props);

        // Determine new stream type
        let useShareOf = false;
        let useAdvancedPercentageStreamAdvancedGui = false;

        const transaction = this.getTransaction(props.typeOfFlow, props.contract, props.recovery, props.costIncome);
        let transTypeOfPayment = this.typeOfPayments.find(x => x.id === transaction.typeOfPaymentId);

        if (transTypeOfPayment)
            [useShareOf, useAdvancedPercentageStreamAdvancedGui] = this.determineStreamType(props, transTypeOfPayment);

        // set stream defaults based on flow type
        switch (props.typeOfFlow) {
            case "recovery":
                this.setAdvancedIncrementDefaultsInternal(props, <AdvancedPercentageIncrementStream>newStream, false);
                (<AdvancedPercentageIncrementStream>newStream).prognosisParameterId = PrognosisParameterEnum.Inflation;
                (<AdvancedPercentageIncrementStream>newStream).developmentTypeId = null;
                break;
            case "costIncome":
                newStream.endDate = null; // until calc end
                switch (transTypeOfPayment!.paymentGroupId) {
                    case PaymentGroupEnum.Costs:
                        this.setContractStreamDefaults(props, newStream);
                        break;
                    case PaymentGroupEnum.Vacancies:
                        this.setShareOfPaymentDefaultsInternal(<ShareOfPaymentStream>newStream, transTypeOfPayment, undefined, props);
                        (<ShareOfPaymentStream>newStream).typeOfPaymentId = TypeOfPaymentEnum.RentOffice;
                        (<ShareOfPaymentStream>newStream).paymentGroupId = null;
                        break;
                    case PaymentGroupEnum.Investments:
                        newStream.endDate = props.context.monthEnd(props.propertyVersion.calculationEnd);
                        this.setVariableAmountsDefaults(<VariableAmountIncrementStream>newStream);
                        break;
                    default:
                        if (useShareOf && transTypeOfPayment) {
                            this.setShareOfPaymentDefaultsInternal(<ShareOfPaymentStream>newStream, transTypeOfPayment, undefined, props);
                        }
                        else if (useAdvancedPercentageStreamAdvancedGui) {
                            this.setAdvancedIncrementDefaultsInternal(props, <AdvancedPercentageIncrementStream>newStream, true);
                        } else {
                            this.setIndexIncrementDefaults(<IndexIncrementStream>newStream);
                        }
                        break;
                }
                break;
            case "contract":
                this.setContractStreamDefaults(props, newStream);
                break;
            default:
                // should never end up here
                throw "Flow type not supported";
        }
        return newStream;
    }

    createDefaultPaymentStream(props: NewStreamProps): PaymentStream {
        let newStream = <PaymentStream>{
            id: props.newId,
            comment: "",
            inAdvance: true,
            paymentFrequency: 3,
            prolongingId: props.newId
        };

        this.setStreamDates(newStream, props);

        // Determine new stream type
        let useShareOf = false;
        let useAdvancedPercentageStreamAdvancedGui = false;

        const transaction = this.getTransaction(props.typeOfFlow, props.contract, props.recovery, props.costIncome);
        let transTypeOfPayment = this.typeOfPayments.find(x => x.id === transaction.typeOfPaymentId);
        
        if (transTypeOfPayment)
            [useShareOf, useAdvancedPercentageStreamAdvancedGui] = this.determineStreamType(props, transTypeOfPayment);

        // Set default values for the stream depending on type
        if (useShareOf && transTypeOfPayment) {
            this.setShareOfPaymentDefaultsInternal(<ShareOfPaymentStream> newStream, transTypeOfPayment, undefined, props);
        }
        else if (useAdvancedPercentageStreamAdvancedGui) {
            this.setAdvancedIncrementDefaultsInternal(props, <AdvancedPercentageIncrementStream>newStream, true);
            if (props.typeOfFlow == 'recovery') {
                this.setFirstIncrementBasedOnCalculationStart(props, <AdvancedPercentageIncrementStream>newStream);
            }
        }
        else {
            this.setIndexIncrementDefaults(<IndexIncrementStream>newStream);
            if (props.typeOfFlow == 'recovery') {
                this.setFirstIncrementBasedOnCalculationStart(props, <AdvancedPercentageIncrementStream>newStream);
            }
        }

        return newStream;
    }

    setStreamDates(stream: PaymentStream, props: NewStreamProps): void {
        // Set start/end date
        switch (props.typeOfFlow) {
            case "contract":
            case "recovery":
                stream.startDate = props.context.monthStart(props.contract!.startDate);
                stream.endDate = props.context.monthEnd(props.contract!.endDate);
                if (props.typeOfFlow == "recovery" && props.recovery!.costIncome.transaction.typeOfPaymentId == TypeOfPaymentEnum.TaxRecovery) {
                    stream.endDate = null;
                }
                break;
            case "costIncome":
                stream.startDate = props.context.monthStart(props.propertyVersion.calculationStart);
                stream.endDate = props.context.monthEnd(props.propertyVersion.calculationEnd);
                break;
        }
    }

    getTransaction(typeOfFlow: 'contract' | 'recovery' | 'costIncome', contract: Contract | null, recovery: Recovery | null, costIncome: CostIncome | null) {
        switch (typeOfFlow) {
            case "contract":
                return contract!.transaction;
            case "recovery":
                return recovery!.costIncome!.transaction;
            case "costIncome":
                return costIncome!.transaction;
        }
    }

    determineStreamType(props: NewStreamProps, transTypeOfPayment: TypeOfPayment): [boolean, boolean] {
        let useShareOf = false;
        let useAdvancedPercentageStreamAdvancedGui = false;
        if (props.typeOfFlow === "costIncome" || props.typeOfFlow === "recovery") {
            if (transTypeOfPayment.paymentGroupId === PaymentGroupEnum.Vacancies ||
                (transTypeOfPayment.paymentGroupId === PaymentGroupEnum.Rents && transTypeOfPayment.id === TypeOfPaymentEnum.TaxRecovery)) {
                useShareOf = true;
            } else if (props.typeOfFlow === "costIncome" && transTypeOfPayment.paymentGroupId === PaymentGroupEnum.Costs) {
                useAdvancedPercentageStreamAdvancedGui = true;
            }
        }
        return [useShareOf, useAdvancedPercentageStreamAdvancedGui];
    }

    private setContractStreamDefaults(props: NewStreamProps, newStream: PaymentStream) {
        this.setAdvancedIncrementDefaultsInternal(props, <AdvancedPercentageIncrementStream>newStream, false);
        (<AdvancedPercentageIncrementStream>newStream).prognosisParameterId = PrognosisParameterEnum.Inflation;
        (<AdvancedPercentageIncrementStream>newStream).developmentTypeId = null;
    }

    setAdvancedIncrementDefaults(stream: AdvancedPercentageIncrementStream, extended: boolean, propertyVersion: PropertyVersion) {
        this.setAdvancedIncrementDefaultsInternal({
            propertyVersion: propertyVersion,
            versionPremisesAreas: propertyVersion.premisesAreas,
            developmentTypeId: propertyVersion.developments.length > 0 ? propertyVersion.developments[0].developmentTypeId : null
        } as NewStreamProps, stream, extended);
    }

    private setAdvancedIncrementDefaultsInternal(props: NewStreamProps, stream: AdvancedPercentageIncrementStream, extended: boolean) {
        let advStream = (<AdvancedPercentageIncrementStream>stream);
        let firstActiveDevType = props.developmentTypeId;
        advStream.typeOfPaymentStreamId = PaymentStreamType.AdvancedPercentageStream;
        advStream.$type = FiaServerApi$type.AdvancedPercentageIncrementStreamDto;
        advStream.margin = 0;
        advStream.developmentTypeId = firstActiveDevType ? firstActiveDevType : null;
        advStream.prognosisParameterId = firstActiveDevType ? null : PrognosisParameterEnum.Inflation;
        advStream.squaremeterPrices = [];

        // Add m2 prices for advanced extended stream type
        if (extended) {
            for (let pa of props.versionPremisesAreas) {
                let sqmPrice = { id: this.getNewId(advStream.squaremeterPrices), premisesTypeId: pa.premisesTypeId, paymentStreamId: stream.id, propertyVersionId: props.propertyVersion.id, price: 0 };
                advStream.squaremeterPrices.push(sqmPrice);
            }
        }

        this.setPercentageIncrementDefaults(stream);
    }

    setShareOfPaymentDefaults(stream: ShareOfPaymentStream, transTypeOfPayment: TypeOfPayment | undefined, sourceStream: ShareOfPaymentStream | undefined,
        typeOfFlow: 'contract' | 'recovery' | 'costIncome', paymentGroupsNormal: PaymentGroup[], typeOfPayments: TypeOfPayment[], recovery: Recovery) {
        this.setShareOfPaymentDefaultsInternal(stream, transTypeOfPayment, sourceStream, <any>{
                typeOfFlow: typeOfFlow,
                paymentGroupsNormal: paymentGroupsNormal,
                typeOfPayments: typeOfPayments,
                recovery: recovery
            });
    }

    setShareOfPaymentDefaultsInternal(stream: ShareOfPaymentStream, transTypeOfPayment: TypeOfPayment | undefined, sourceStream: ShareOfPaymentStream | undefined, props: NewStreamProps) {
        stream.typeOfPaymentStreamId = PaymentStreamType.ShareOfPaymentStream;
        stream.$type = FiaServerApi$type.ShareOfPaymentStreamDto;
        let paymentGroupId: number | null = null;
        let typeOfPaymentId: number | null = null;

        if (props.typeOfFlow === "costIncome") {
            if (sourceStream) {
                paymentGroupId = sourceStream.paymentGroupId;
                typeOfPaymentId = sourceStream.typeOfPaymentId;
            }
            else {
                paymentGroupId = this.paymentGroupsNormal.length === 0 ? null : this.paymentGroupsNormal[0].id;
            }
        }
        else if (props.typeOfFlow === "recovery") {
            if (transTypeOfPayment && transTypeOfPayment.vacansOfTypeOfPaymentId) {
                let vacansTop = this.typeOfPayments.find(x => x.id === transTypeOfPayment!.vacansOfTypeOfPaymentId);
                if (vacansTop)
                    typeOfPaymentId = vacansTop.id;
            }
            else {
                if (sourceStream) {
                    paymentGroupId = sourceStream.paymentGroupId;
                    typeOfPaymentId = sourceStream.typeOfPaymentId;
                }
                else if (props.recovery!.costIncome.transaction.typeOfPaymentId == TypeOfPaymentEnum.TaxRecovery) {
                    typeOfPaymentId = 12;
                }
                else {
                    typeOfPaymentId = this.typeOfPayments.length === 0 ? null : this.typeOfPayments[0].id;
                }
            }
        }

        stream.paymentGroupId = paymentGroupId;
        stream.typeOfPaymentId = typeOfPaymentId;
        stream.percentage = 0;
    }

    setPercentageIncrementDefaults(stream: PercentageIncrementStream) {
        stream.startAmount = 0;
        stream.monthOfChange = 1;
        stream.firstIncrement = moment(stream.startDate).add(1, "year").startOf("year").format(DateFormat);
    }

    setVariableAmountsDefaults(stream: VariableAmountIncrementStream) {
        stream.typeOfPaymentStreamId = PaymentStreamType.VariableAmountIncrementStream;
        stream.$type = FiaServerApi$type.VariableAmountIncrementStreamDto;
        stream.variableAmounts = [];
    }

    setIndexIncrementDefaults(stream: IndexIncrementStream) {
        stream.typeOfPaymentStreamId = PaymentStreamType.IndexIncrementStream;
        stream.$type = FiaServerApi$type.IndexIncrementStreamDto;
        stream.baseNumber = 0;
        stream.baseMonth = 10;
        stream.indexPercentage = 1;
        stream.indexDenotationId = IndexDenotationEnum.Kpi;
        stream.indexClauseId = IndexClauseEnum.NewClause;
        stream.maintenanceCost = null;

        this.setPercentageIncrementDefaults(stream);
    }

    setBasicPercentageDefaults(stream: BasicPercentageStream, typeOfFlow: 'contract' | 'recovery' | 'costIncome', propertyVersion: PropertyVersion, premisesTypeId: number) {
        stream.typeOfPaymentStreamId = PaymentStreamType.BasicPercentageStream;
        stream.$type = FiaServerApi$type.BasicPercentageStreamDto;
        stream.variablePercentages = [];

        // TODO: validate premisesAreas.Any() first!
        // Create maintenance cost for cost incomes
        if (typeOfFlow === "costIncome") {
            stream.maintenanceCost = <MaintenanceCost>{
                id: 0,
                costPerSquaremeter: 0,
                paymentStreamId: 0,
                propertyVersionId: propertyVersion.id,
                premisesTypeId: premisesTypeId
            };
        }

        this.setPercentageIncrementDefaults(stream);
    }

    setFirstIncrementBasedOnCalculationStart(props: NewStreamProps, newStream: AdvancedPercentageIncrementStream) {
        let firstIncrement = new Date(props.propertyVersion.calculationStart);
        if (firstIncrement < new Date(props.contract!.startDate)) {
            console.log('First Increment is less than contract start date. Using contract start date instead');
            firstIncrement = new Date(props.contract!.startDate);
        }
        switch (firstIncrement.getMonth()) {
            case 0:
                firstIncrement = new Date(firstIncrement.getFullYear(), 0, 1);
            default:
                firstIncrement = new Date(firstIncrement.getFullYear() + 1, 0, 1);
        }
        newStream.firstIncrement = props.context.monthStart(firstIncrement);
    }

    changeStreamType(oldStream: PaymentStream, newStreamType: PaymentStreamType, propertyVersion: PropertyVersion,
        typeOfFlow: 'contract' | 'recovery' | 'costIncome', contract: Contract | null, recovery: Recovery | null, costIncome: CostIncome | null) {
        // Copy base stream info from the old stream
        let newStream = <PaymentStream>{
            id: oldStream.id,
            startDate: oldStream.startDate,
            endDate: oldStream.endDate,
            paymentFrequency: oldStream.paymentFrequency,
            inAdvance: oldStream.inAdvance,
            comment: oldStream.comment
        };

        // Percentage increment stream setup
        if (utils.isPercentageIncrementStreamType(newStreamType)) {
            let newPercentageStream = (<PercentageIncrementStream>newStream);

            // Copy percentage stream info from the old stream
            if (utils.isPercentageIncrementStream(<PaymentStream>oldStream)) {
                let oldPercentageStream = (<PercentageIncrementStream>oldStream);
                newPercentageStream.startAmount = oldPercentageStream.startAmount;
                newPercentageStream.monthOfChange = oldPercentageStream.monthOfChange;
                newPercentageStream.firstIncrement = oldPercentageStream.firstIncrement;
            }
            // Default values for percentage stream
            else {
                newPercentageStream.startAmount = 0;
                newPercentageStream.monthOfChange = 1;
                newPercentageStream.firstIncrement = moment(newStream.startDate).add(1, "year").format(DateFormat);
            }
        }

        this.setStreamDefaults(newStream, newStreamType, undefined, propertyVersion, typeOfFlow, contract, recovery, costIncome);
        return newStream;
    }

    // Sets default values for the specific stream type 
    // Used when creating a new stream (from previous) and when changing stream type
    setStreamDefaults(stream: PaymentStream, streamType: PaymentStreamType, sourceStream: PaymentStream | undefined, propertyVersion: PropertyVersion,
        typeOfFlow: 'contract' | 'recovery' | 'costIncome', contract: Contract | null, recovery: Recovery | null, costIncome: CostIncome | null): void {
        switch (streamType) {
            case PaymentStreamType.AdvancedPercentageStream:
                this.setAdvancedIncrementDefaults(<AdvancedPercentageIncrementStream>stream, false, propertyVersion)
                break;
            case PaymentStreamType.AdvancedPercentageStreamAdvancedGui:
                this.setAdvancedIncrementDefaults(<AdvancedPercentageIncrementStream>stream, true, propertyVersion)
                break;
            case PaymentStreamType.VariableAmountIncrementStream:
                this.setVariableAmountsDefaults(<VariableAmountIncrementStream>stream)
                break;
            case PaymentStreamType.IndexIncrementStream:
                this.setIndexIncrementDefaults(<IndexIncrementStream>stream);
                break;
            case PaymentStreamType.BasicPercentageStream:
                this.setBasicPercentageDefaults(<BasicPercentageStream>stream, typeOfFlow, propertyVersion, propertyVersion.premisesAreas[0].premisesTypeId)
                break;
            case PaymentStreamType.ShareOfPaymentStream:
                const transaction = this.getTransaction(typeOfFlow, contract, recovery, costIncome);
                let transTypeOfPayment = this.typeOfPayments.find(x => x.id === transaction!.typeOfPaymentId);
                this.setShareOfPaymentDefaults(<ShareOfPaymentStream>stream, transTypeOfPayment, <ShareOfPaymentStream>sourceStream, typeOfFlow, this.paymentGroupsNormal, this.typeOfPayments, recovery!);
                break;
            default:
                throw "Payment stream type not supported";
        }
    }

    getNewId(entities: Entity[]) {
        let newItems = entities.filter(x => x.id < 1);
        let newId = newItems.length == 0 ? 0 : Math.min(...newItems.map(x => x.id)) - 1;
        return newId;
    }

    copyAmount(fromStream: PaymentStream, toStream: PaymentStream) {
        if (toStream.typeOfPaymentStreamId == PaymentStreamType.VariableAmountIncrementStream) {
            (toStream as VariableAmountIncrementStream).variableAmounts[0].amount = this.getAmount(fromStream);
        } else {
            (toStream as PercentageIncrementStream).startAmount = this.getAmount(fromStream);
        }
    }

    getAmount(stream: PaymentStream) {
        switch (stream.typeOfPaymentStreamId) {
            case PaymentStreamType.AdvancedPercentageStream:
            case PaymentStreamType.AdvancedPercentageStreamAdvancedGui:
            case PaymentStreamType.IndexIncrementStream:
            case PaymentStreamType.BasicPercentageStream:
                return (stream as PercentageIncrementStream).startAmount;
            case PaymentStreamType.VariableAmountIncrementStream:
                return (stream as VariableAmountIncrementStream).variableAmounts[0].amount;
            default:
                throw "Payment stream type not supported";
        }
    }
}