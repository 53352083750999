import BaseComponent from "@/components/base-component";
import { Component, Watch, Prop } from "vue-property-decorator";
import { mapGetters } from 'vuex'
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import { PropertyVersion, DevelopmentType, PrognosisParameter, Development, DevelopmentFixedAdjustment } from '@/models';
import dataService from '@/services/data-service';
import { AxiosPromise, AxiosResponse } from 'axios';
import InlineEdit from '@/helpers/inline-edit';
import { ValidationError } from '@/models/interfaces/ValidationError';
import validationService from '@/services/validation-service';
import ValidationTableFeedbackComponent from '@/components/common/validation-table-feedback/validation-table-feedback';
import ValidationErrorComponent from '@/components/common/validation-error/validation-error';

@Component({
    components: {
        YearMonthPickerComponent,
        InputNumericComponent,
        ValidationTableFeedbackComponent,
        ValidationErrorComponent
    },
    computed: mapGetters({
        propertyVersion: "getPropertyVersion",
        developmentTypes: "getDevelopmentTypes",
        prognosisParameters: "getPrognosisParameters"
    })
})
export default class DevelopmentComponent extends BaseComponent {
    propertyVersion!: PropertyVersion;
    developmentTypes!: DevelopmentType[];
    prognosisParameters!: PrognosisParameter;
    
    fixedAdjustmentsGrid: InlineEdit<DevelopmentFixedAdjustment>;
    currentPropertyVersion: number = -1;
    complexErrors: ValidationError[] = [];
    activeDevelopmentTypes: DevelopmentType[] = [];
    developmentsInVersion: Development[] = [];
    selectedDevelopment: Development | null = null;
    developmentTypesIds: number[] = [];
    saving: boolean = false;

    constructor() {
        super();
        this.fixedAdjustmentsGrid = new InlineEdit<DevelopmentFixedAdjustment>(this, "fixedAdjustmentsTable", this.loadFixedAdjustments, undefined, undefined, this.newFixedAdjustment, undefined, this.hasNoErrors);
        this.fixedAdjustmentsGrid.sync = true;
        this.fixedAdjustmentsGrid.readOnly = this.versionLocked();
    }

    @Prop()
    reload!: boolean;

    @Watch("reload")
    async reloadWatcher() {
        if (this.reload) {
            if (this.reloadNeeded()) {
                await this.reloadData();
            }
            this.redrawTables();
        }
    }

    @Watch("propertyVersion")
    async versionWatcher() {
        if (this.reloadNeeded()) {
            await this.reloadData();
        }
    }

    @Watch("developmentsInVersion", { deep: true, immediate: true })
    developmentsInVersionWatcher() {
        validationService.validatePropertyVersionDetailsDevelopment(this.developmentsInVersion, this.complexErrors);
    }

    async mounted() {
        await this.reloadData();

        this.$nextTick(() => {
            this.redrawTables();
        });
    }

    async reloadData(developments?: Development[] | undefined) {
        try {
            this.developmentsInVersion = developments ? developments : await dataService.getDevelopment(this.propertyVersion.id).then(x => x.data);
            this.filterDevelopmentTypes();
            if (this.developmentsInVersion.length > 0) {
                this.setCurrentRow(this.developmentsInVersion[0]);
            }
            this.loadError = false;
            this.currentPropertyVersion = this.propertyVersion!.id;
            this.complexErrors = [];
        } catch (error) {
            this.loadError = true;
            Promise.reject(new Error(""));
        }
    }

    reloadNeeded(): boolean {
        return this.reload && this.propertyVersion && this.currentPropertyVersion !== this.propertyVersion.id;
    }

    redrawTables() {
        (<any>this.$refs).developmentTypesTable.doLayout();
        (<any>this.$refs).developmentsInVersionTable.doLayout();
        this.fixedAdjustmentsGrid.redraw();
    }

    loadFixedAdjustments(): AxiosPromise<DevelopmentFixedAdjustment[]> {
        return Promise.resolve(<AxiosResponse>{ data: this.selectedDevelopment ? this.selectedDevelopment.fixedAdjustments : [] })
    }

    newDevelopment(devId: number = 1): Development {
        return <Development>{
            id: this.getNewId(this.developmentsInVersion),
            calculationType: true,
            developmentTypeId: devId,
            fixedAdjustments: [],
            margin: 1,
            prognosisParameterId: 1,
            propertyVersionId: 1
        }
    }

    newFixedAdjustment(): DevelopmentFixedAdjustment {
        return <DevelopmentFixedAdjustment>{
            id: this.getNewId(this.fixedAdjustmentsGrid.entities),
            fixedAdjustment: 0,
            startTime: this.newIsoDateMonth()
        }
    }

    developmentSelected(item: Development) {
        this.selectedDevelopment = item;
        this.fixedAdjustmentsGrid.reload();
    }

    filterDevelopmentTypes() {
        this.activeDevelopmentTypes = this.developmentTypes.filter(x => this.developmentsInVersion.findIndex(y => y.developmentTypeId === x.id) === -1);
    }

    addDevelopment(item: Development) {
        if (this.versionLocked()) return;

        let newItem = this.newDevelopment(item.id);
        this.developmentsInVersion.push(newItem);
        this.filterDevelopmentTypes();
        this.setCurrentRow(newItem);
    }

    removeDevelopment(item: Development) {
        if (this.versionLocked()) return;

        if (this.selectedDevelopment && this.selectedDevelopment.id === item.id)
            this.setCurrentRow(null);

        this.developmentsInVersion.splice(this.developmentsInVersion.indexOf(item), 1);
        this.filterDevelopmentTypes();
    }

    setCurrentRow(item: Development | null) {
        this.selectedDevelopment = item;
        this.$nextTick(() => {
            (<any>this.$refs).developmentsInVersionTable.setCurrentRow(item);
        });
    }

    async save() {
        this.saving = true;
        await dataService.saveDevelopments(this.propertyVersion.id, this.developmentsInVersion)
            .then(async x => {
                this.reloadData(x.data);
                await this.$store.dispatch("setDevelopmentsInVersion", x.data);
                this.saving = false;
                return true;
            })
            .catch(() => {
                this.$notify.error({
                    title: this.translate('ErrorUnexpected'),
                    message: this.translate('ErrorActionFailed')
                });
                this.saving = false;
                return false;
            });
    }

    async undo() {
        await this.reloadData();
    }

    isValid(): boolean {
        return this.complexErrors.length === 0;
    }

    canUndo(): boolean {
        return !this.versionLocked() && !this.loadError && !this.fixedAdjustmentsGrid.loadError;
    }

    canSave(): boolean {
        return !this.saving && this.isValid() && !this.versionLocked() && !this.loadError && !this.fixedAdjustmentsGrid.loadError;
    }

    canAdd(): boolean {
        return !this.versionLocked() && !this.loadError && !this.fixedAdjustmentsGrid.loadError;
    }
}