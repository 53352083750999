import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import BaseComponent from '@/components/base-component';
import DialogComponent from '@/components/common/dialog/dialog';
import InputTextComponent from '@/components/common/input-text/input-text';
import PaymentFlowsComponent from '@/components/payment-flows/paymentflows';
import { NewStreamLogic, PaymentGroupEnum, PaymentStreamType, TypeOfPaymentEnum } from '@/constants/fia-constants';
import { CostIncome, PropertyVersion, ShareOfPaymentStream, TypeOfPayment, User } from '@/models';
import { ValidationError } from '@/models/interfaces/ValidationError';
import { PaymentGroup } from '@/models/PaymentGroup';
import { CostIncomeCalculatedDatas } from '@/models/CalculatedData';
import dataService from '@/services/data-service';

@Component({
    components: {
        InputTextComponent,
        PaymentFlowsComponent,
        DialogComponent
    },
    computed: mapGetters({
        paymentGroupsList: "getPaymentGroupsNormal",
        user: "getUser",
        propertyVersion: "getPropertyVersion",
    })
})
export default class CostIncomeDetailsEditComponent extends BaseComponent {
    paymentGroupsList!: PaymentGroup[];
    user!: User;
    propertyVersion!: PropertyVersion;
    calculatedData: CostIncomeCalculatedDatas | null = null;

    @Prop()
    costIncome!: CostIncome;

    @Prop()
    complexErrors!: ValidationError[];

    @Prop() // Synced
    activeTab!: string;

    @Prop()
    reload!: boolean;

    typeOfPaymentsList: TypeOfPayment[] = [];
    activeTypeOfPaymentsList: TypeOfPayment[] = [];
    activePaymentGroupsList: PaymentGroup[] = [];
    paymentGroupId: number = -1;
    tab: string = "CostIncome";

    @Watch("costIncome", { immediate: true })
    contractWatcher() {
        if (!this.costIncome) return;
        this.initialize();
    } 

    @Watch("activeTab", { immediate: true })
    activeTabWatcher() {
        this.tab = this.activeTab;
    }

    @Watch("reload")
    reloadWatcher() {
        if (this.reload) {
            this.setCalculatedData();
        }
    }

    initialize() {
        this.typeOfPaymentsList = this.$store.getters.getActiveTypeOfPayments([this.costIncome.transaction.typeOfPaymentId]);
        let pt = this.typeOfPaymentsList.find(x => x.id === this.costIncome.transaction.typeOfPaymentId);
        this.paymentGroupId = pt ? pt.paymentGroupId : this.paymentGroupsList[0].id;

        // Filter all paymentgroups that contains no type of payment...
        this.activePaymentGroupsList = this.paymentGroupsList.filter(x => this.typeOfPaymentsList.map(t => t.paymentGroupId).includes(x.id));
        this.filterTypeList();
        this.setCalculatedData();
    }

    filterTypeList() {
        this.activeTypeOfPaymentsList = this.typeOfPaymentsList.filter(x => x.paymentGroupId == this.paymentGroupId);
    }

    paymentGroupChanged() {
        this.filterTypeList();
        
        const newStreamLogic: NewStreamLogic = this.user.userSettings.newStreamLogic;

        let typeOfPaymentId = -1;
        switch (newStreamLogic) {
            case NewStreamLogic.Finnish:
                switch (this.paymentGroupId) {
                    case PaymentGroupEnum.Vacancies:
                        typeOfPaymentId = TypeOfPaymentEnum.VacancyOffice;
                        break;
                    case PaymentGroupEnum.Investments:
                        typeOfPaymentId = TypeOfPaymentEnum.CapEx;
                        break;
                    default: 
                        typeOfPaymentId = this.getFirstTypeOfPayment();
                        break;
                }
                break;
            default:
                typeOfPaymentId = this.getFirstTypeOfPayment();
                break;
        }
        this.costIncome.transaction.typeOfPaymentId = typeOfPaymentId;
    }

    private getFirstTypeOfPayment() {
        return this.activeTypeOfPaymentsList.length > 0 ? this.activeTypeOfPaymentsList[0].id : -1;
    }

    hasPaymentError() {
        return this.getError(this.complexErrors, 'ci-payment');
    }
    
    tabClick(tab: any) {
        this.$emit('update:activeTab', tab.name);
    }

    async setCalculatedData() {
        this.calculatedData = null;

        const isVacancyPaymentType = this.typeOfPaymentsList.find(x => x.id == this.costIncome.transaction.typeOfPaymentId)?.paymentGroupId == PaymentGroupEnum.Vacancies;
        if (!this.costIncome ||
            !isVacancyPaymentType ||
            !this.costIncome.paymentStreams ||
            !this.costIncome.paymentStreams.some(p => p.typeOfPaymentStreamId == PaymentStreamType.ShareOfPaymentStream)) {
            return;
        }
        
        const streamTypeOfPaymentId = (<ShareOfPaymentStream>this.costIncome.paymentStreams[0]).typeOfPaymentId;
        dataService.getCostIncomeCalculatedDatas(this.propertyVersion.id, streamTypeOfPaymentId!).then(x => {
            this.calculatedData = x.data;
        });
    }
}