import { Component } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';

import BaseComponent from '@/components/base-component';
import { Database, PremisesType, ContractSearchRequest, User } from '@/models';
import { Customer, CountriesEnum } from '@/constants/fia-constants';
import dataService from '@/services/data-service';
import ContractSearchResultsComponent from '@/components/contract/contract-search-results/contract-search-results';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import { ContractSearchResponse } from "@/models/interfaces/response/ContractSearchResponse";
import AreaSelectComponent, { AreaSelection } from '@/components/common/area-select/area-select';

@Component({
    components: {
        YearMonthPickerComponent,
        ContractSearchResultsComponent,
        InputNumericComponent,
        AreaSelectComponent,
    },
    computed: mapGetters({
        user: "getUser",
        premisesTypes: "getPremisesTypes"
    })
})
export default class ContractSearchComponent extends BaseComponent {
    user!: User;
    premisesTypes!: PremisesType[];

    selectedArea: AreaSelection = { countries: [], regions: [], counties: [], subcounties: [] };
    selectedPremisesTypeIds: number[] = [];
    latestVersion: boolean = true;
    calcFrom: string = this.newIsoDateMonth();
    calcTo: string = this.newIsoDateMonth(true);
    tenantFilter: string = "";
    searching: boolean = false;
    regionsGrouped: any = {}
    countiesGrouped: any = {}
    subcountiesGrouped: any = {}
    fromArea: number | null = null
    toArea: number | null = null
    maxResultsErrorText: string = "";
    defaultCountryId: CountriesEnum = CountriesEnum.Sweden;

    mounted() {
        this.defaultCountryId = this.user.userSettings.defaultCountryId || CountriesEnum.Sweden;
    }

    async search() {
        this.searching = true;
        this.maxResultsErrorText = "";
        this.$emit('onSearchStarted');
        let request: ContractSearchRequest = {
            countryIds: this.selectedArea.countries,
            regionIds: this.selectedArea.regions,
            countyIds: this.selectedArea.counties,
            subcountyIds: this.selectedArea.subcounties,
            onlyLatestVersion: this.latestVersion,
            startDate: this.calcFrom,
            endDate: this.calcTo,
            tenantFilter: this.tenantFilter,
            premisesTypeIds: this.selectedPremisesTypeIds,
            fromArea: this.fromArea,
            toArea: this.toArea,
            maxRows: 20000
        }

        try {
            const result = await dataService.searchContracts(request).then(x => x.data);
            result.result.forEach(x => {
                x.startDate2 = x.startDate ? new Date(x.startDate) : null;
                x.endDate2 = new Date(x.endDate);
                x.marketRentDate2 = x.marketRentDate ? new Date(x.marketRentDate) : null;
            });
            if (result.moreRows) {
                this.maxResultsErrorText = this.translate('MoreSearchResults', { max: result.maxRows });
            }
            this.$emit('onSearchCompleted', result);
        } catch (error) {
            this.$emit('onSearchCompleted', <ContractSearchResponse>{ result: [], moreRows: false, maxRows: 0 });
            // TODO: handle error?
        } finally {
            this.searching = false;
        }
    }

    async clearSearch() {
        this.tenantFilter = "";
        this.selectedPremisesTypeIds = [];
        this.fromArea = null;
        this.toArea = null;
        this.calcFrom = this.newIsoDateMonth();
        this.calcTo = this.newIsoDateMonth(true);
        this.latestVersion = true;
        await (<any>this.$refs).areaSelect.reset();
    }

    areaChanged(area: AreaSelection) {
        this.selectedArea = area;
    }
}