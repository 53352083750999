import { Component, Watch, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";
import dataService from "@/services/data-service";
import { DevelopmentType, MarketRent, MarketType, PremisesType, PropertyVersion, Job } from "@/models";
import RentCompositionComponent from "@/components/web-reports/rent-composition/rent-composition";
import { EditMultipleMarketRentsTemplateDto } from "@/models/EditMultipleMarketRentsTemplate";
import { AggregateColumn } from '@syncfusion/ej2-vue-treegrid';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import { BusMessage, MultiMarketRentChangedMessage } from "@/models/messages/messages";
import { ProlongingType } from "@/constants/fia-constants";
import {BaseDataStatus} from "@/constants/fia-constants";
import moment from "moment";
import { ErrorHandling } from "@/helpers/error-handling";
import { SignalRMessage } from "@/models/interfaces/signalR/SignalRMessage";
import { MessageType } from "@/models/interfaces/signalR/MessageType";

@Component({
    computed: mapGetters({
        propertyVersion: "getPropertyVersion",
        currencyList: "getCurrencies",
        marketTypes: "getMarketTypes",
        premisesTypes: "getPremisesTypes",
        developmentTypes: "getDevelopmentTypes",
    }),
    components: {
        InputNumericComponent,
    }
})
export default class EditMultipleMarketRentsDialogComponent extends RentCompositionComponent {
    marketTypes!: MarketType[];
    premisesTypes!: PremisesType[];
    developmentTypes!: DevelopmentType[];
    propertyVersion!: PropertyVersion;

    loading = true;
    error: string | null = null;
    tenants: EditMultipleMarketRentsTemplateDto[] = [];
    defaultStartDate = this.newIsoDateMonth();
    
    signalRMsgHandler: any;

    @Prop()
    open!: boolean;

    @Watch("open")
    async openWatcher() {
        if (this.open) {
            await this.loadData();
        }
    }

    mounted() {
        this.signalRMsgHandler = (message: SignalRMessage) => this.onSignalRMessage(message);
        this.$signalrHub.$on("HubMessage", this.signalRMsgHandler);
    }

    beforeDestroy() {
        this.$signalrHub.$off("HubMessage", this.signalRMsgHandler);
    }

    async loadData() {
        this.loading = true;
        try {
            const tenants = await dataService.getEditMultipleMarketRentsTemplate(this.propertyVersion.id).then(x => x.data);
            
            // Initialize list properties only used client side.
            let lastContractId = -1;
            for (const tenant of tenants) {
                tenant.hideTenant = lastContractId === tenant.contractId;
                // Flag rows with the same tenant so we can hide duplicate tenant info in the list.
                lastContractId = tenant.contractId;
                // Set default error state.
                tenant.rentError = false;
            }

            this.tenants = tenants;
            const startDate = this.tenants.map(x => x.marketRent.startDate).find(x => x != null);
            this.defaultStartDate = startDate ? startDate : this.propertyVersion.calculationStart;
            this.error = null;
        } catch (error) {
            const errorText = ErrorHandling.GetErrorText(error)
            this.error = this.translate(errorText);
            this.tenants = [];
        } finally {
            this.loading = false;
            this.$nextTick(() => {
                const marketRentsTable = (<any> this).$refs.marketRentsTable;
                if (marketRentsTable) {
                    marketRentsTable.doLayout();
                }
            })
        }
    }

    onRentLevelChanged(row: EditMultipleMarketRentsTemplateDto, newValue: number) {
        if (!newValue || newValue == 0) {
            row.marketRent.rentLevel = 0;
            row.marketRent.marketTypeId = 0;
            row.rentError = false;
            return;
        }

        const identicalRow = this.tenants.find(t => t.rentCompositionRow != row.rentCompositionRow
            && t.rentCompositionRow.premisesTypeId == row.rentCompositionRow.premisesTypeId
            && t.marketRent.rentLevel == newValue);
        if (identicalRow) {
            console.debug("identical row found. Using same market rent")
            row.marketRent = identicalRow.marketRent;
            row.rentError = false;
            return;
        }

        const isAlreadyUnique = !this.tenants.some(t => t.rentCompositionRow != row.rentCompositionRow
            && t.marketRent.marketTypeId == row.marketRent.marketTypeId || row.marketRent.marketTypeId == 0);
        if (isAlreadyUnique ) {
            console.debug("Row is already unique")
            row.marketRent.rentLevel = newValue;
            row.rentError = false;
            return;
        }

        console.debug("Creating new market rent")
        const marketRent = this.newMarketRent(row.rentCompositionRow.premisesTypeId);
        if (marketRent) {
            row.marketRent = marketRent;
            row.marketRent.rentLevel = newValue;
            row.rentError = false;
        } else {
            row.marketRent.rentLevel = newValue;
            row.rentError = true;
        }
    }

    newMarketRent(premisesTypeId: number) : MarketRent | null {
        const usedMarketTypeIds = this.tenants.map(t => t.marketRent.marketTypeId);
        const map = this.premisesTypes
            .find(p => p.id == premisesTypeId)!.marketTypeDefaultValueMaps
            .find(m => !usedMarketTypeIds.includes(m.marketTypeId));

        let marketTypeId: number | null = null
        if (map) {
            marketTypeId = map.marketTypeId;
        } else {
            const fallbackMarketType = this.marketTypes.find(m => !m.inactive && !usedMarketTypeIds.includes(m.id));
            if (fallbackMarketType) {
                marketTypeId = fallbackMarketType.id;
            } else {
                return null;
            }
        }

        const developmentId = map ? map.developmentTypeId : this.developmentTypes[0].id;

        return <MarketRent>{
            propertyVersionId: this.propertyVersion.id,
            marketRentId: 0,
            marketTypeId: marketTypeId,
            developmentTypeId: developmentId,
            rentLevel: 0,
            startDate: this.defaultStartDate,
            index: 100,
            usedByCount: 0,
        }
    }

    save() {
        if (this.tenants.some(x => x.rentError)) {
            this.$notify.info({
                title: this.translate('ValidationErrors'),
                message: this.translate('ValidationErrorNavigation')
            });
            return;
        }

        this.loading = true;
        dataService.saveMultipleMarketRents(this.tenants)
        .then(async x => {
            this.$notify.success({
                title: '',
                message: this.translate('MarketRentsUpdated')
            });
            this.$emit('reload');
            await this.$store.dispatch("sendBusMessage", <BusMessage<MultiMarketRentChangedMessage>>{ type: "MultiMarketRentChanged" });
        }).catch(err => {
            console.warn(err);
            this.$notify.error({
                title: this.translate('Error'),
                message: this.translate('ErrorActionFailed')
            });
        }).finally(() => {
            this.loading = false;
            this.close();
        });
    }

    async calculate() {
        this.$store.dispatch("sendBusMessage", <BusMessage<MultiMarketRentChangedMessage>>{ type: "MultiMarketRentChanged" });
        this.loading = true;
        this.$notify.success({
            title: '',
            message: this.translate('CalculationStarted')
        });
    }

    onSignalRMessage(message: SignalRMessage): any {
        console.debug(`Calculation update - ${message.type}`);
        const job = JSON.parse(message.data) as Job;
        if (this.propertyVersion.id && job.identityId && this.propertyVersion.id.toString() != job.identityId.toString()) {
            return;
        }
        
        if (message.type === MessageType.JobFinished) {
            this.loadData();
            this.loading = false;
            return;
        }
        if (message.type === MessageType.JobFailed) {
            this.$notify.error({
                title: '',
                message: this.translate('CalculationError')
            });
            this.loading = false;
            return;
        }
    }

    onPaste(event:ClipboardEvent, tenant: EditMultipleMarketRentsTemplateDto) {
        if (!document.activeElement || !(<HTMLElement>document.activeElement).blur
            || !event.clipboardData || !event.clipboardData.getData('text')) {
            return;
        }

        (<HTMLElement>document.activeElement).blur();
        let pastedText = event.clipboardData.getData('text');
        const pastedRows = pastedText.split('\n').map(x => x.replace(/\s/g, ''));

        if (pastedRows.some(r => r.split('\t').length > 1)) {
            this.$notify.warning({
                title: this.translate('ValidationWarning'),
                message: this.translate('PasteErrorMultipleColumns')
            });
            return;
        }

        const selectedRowIdx = this.tenants.indexOf(tenant);
        for (let i = selectedRowIdx, j = 0; i < this.tenants.length && j < pastedRows.length; i++, j++) {
            let newVal = parseInt(pastedRows[j].trim(), 10);
            if (!isFinite(newVal)) {
                continue;
            }
            this.onRentLevelChanged(this.tenants[i], newVal);
        }
    }

    sqmFormatter(col: AggregateColumn, data: any) {
        const split = col.field.split('.');
        var field = split[split.length-1];
        let area = this.sum(this.tenants.map(x => x.rentCompositionRow.area));
        let value = null;
        switch (field) {
            case "rentSqm": value = this.formatNumber(this.decimals(this.sum(this.tenants.map(x => x.rentCompositionRow.rent)) * this.currencyRate / area, 0), 0);
                break;
            case "marketRentSqm": value = this.formatNumber(this.decimals(this.sum(this.tenants.map(x => x.rentCompositionRow.marketRent)) * this.currencyRate / area, 0), 0);
                break;
        }
        return value && isFinite(Number(value)) ? value : null;
    }

    getMarketTypeName(id: number) {
        const c = this.marketTypes.find(x => x.id == id);
        return c ? c.name : '';
    }

    anyWarnings() {
        return this.tenants.some(x => x.leaseAreaUsesOverrideValue);
    }

    getWarning(row: EditMultipleMarketRentsTemplateDto) : { class: string|undefined, text: string|undefined } {
        if (row.rentError) {
            return { class: 'error', text: this.translate('Warning_OutOfMarketTypes') };
        }
        if (row.leaseAreaUsesOverrideValue) {
            return { class: 'warning', text: this.translate('Validation_leaseAreaUsesOverrideAmount') };
        }
        return { class: undefined, text: undefined}
    }

    close() {
        this.tenants = [];
        this.error = null;
        this.$emit('close');
    }

    // Returns true if the contract is using market rent
    useMarketRent(prolongingTypeId: number): boolean {
        return prolongingTypeId === ProlongingType.UseMarketRent;
    }

    formatTenant(data: EditMultipleMarketRentsTemplateDto) {
        return data.hideTenant ? "" : data.tenant;
    }

    formatIdentifier(data: EditMultipleMarketRentsTemplateDto) {
        return data.hideTenant ? "" : data.propertyIdentifier;
    }

    // Return modified start date depending on if the contract is vacant
    formatStartDate(data: EditMultipleMarketRentsTemplateDto): string {
        if (data.hideTenant) {
            return '';
        }
        var statusId = data.rentCompositionRow.statusId;
        if(statusId === BaseDataStatus.Vacant || statusId === BaseDataStatus.VacantRefurbishmentsTakingPlace || 
           statusId === BaseDataStatus.VacantLongTerm || statusId === BaseDataStatus.VacantLongerTerm){
            return this.formatYearMonth(moment(data.rentCompositionRow.endDate).add(1, "month"));
        }
        return this.formatYearMonth(data.rentCompositionRow.startDate);
    }

    // Don't return end date if the contract is vacant
    formatEndDate(data: EditMultipleMarketRentsTemplateDto): string {
        if (data.hideTenant) {
            return '';
        }
        var statusId = data.rentCompositionRow.statusId;
        if(statusId === BaseDataStatus.Vacant || statusId === BaseDataStatus.VacantRefurbishmentsTakingPlace || 
           statusId === BaseDataStatus.VacantLongTerm || statusId === BaseDataStatus.VacantLongerTerm){
            return '';

        }
        return this.formatYearMonth(data.rentCompositionRow.endDate);
    }

    getSummaries(tableData: any): any {
        const data: EditMultipleMarketRentsTemplateDto[] = tableData.data;
        let areaSum = 0;
        let baseRentSum = 0;
        let rentExRecoveriesSum = 0;
        let heatSum = 0;
        let miscSum = 0;
        let conversionSum = 0;
        let discountSum = 0;
        let rentSum = 0;
        let marketRentSum = 0;

        for(const item of data) {
            const row = item.rentCompositionRow;
            areaSum += row.area || 0;
            baseRentSum += row.baseRent || 0;
            rentExRecoveriesSum += row.rentExRecoveries || 0;
            heatSum += row.heat || 0;
            miscSum += row.misc || 0;
            conversionSum += row.conversion || 0;
            discountSum += row.discount || 0;
            rentSum += row.rent || 0;
            marketRentSum += row.marketRent || 0;
        }

        return ["", "", "",
                this.tableFormat("area", areaSum),
                this.tableFormat("baseRent", baseRentSum),
                "", "",
                this.tableFormat("rentExRecoveries", rentExRecoveriesSum),
                this.tableFormat("heat", heatSum),
                this.tableFormat("misc", miscSum),
                this.tableFormat("conversion", conversionSum),
                this.tableFormat("discount", discountSum),
                this.tableFormat("rent", rentSum),
                "", "", "",
                this.tableFormat("marketRent", marketRentSum),
                "", ""
            ];
    }

    getRentChange(row: EditMultipleMarketRentsTemplateDto): string | number {
        if (!row.marketRent.rentLevel || row.marketRent.rentLevel === 0) {
            return "";
        }
        return this.decimals(row.marketRent.rentLevel - row.rentCompositionRow.marketRentSqm, 0)
    }
}