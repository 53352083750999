import { render, staticRenderFns } from "../../../../node_modules/element-ui/packages/select/src/select.vue?vue&type=template&id=2583192c&"
import script from "./el-select-extended.ts?vue&type=script&lang=js&"
export * from "./el-select-extended.ts?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports