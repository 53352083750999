import BaseComponent from "@/components/base-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import { BasicPercentageStream, VariablePercentage } from "@/models";
import InlineEdit from "@/helpers/inline-edit";
import { AxiosPromise, AxiosResponse } from "axios";
import { ValidationError } from "@/models/interfaces/ValidationError";
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import ValidationTableFeedbackComponent from '@/components/common/validation-table-feedback/validation-table-feedback';
import BaseValuesComponent from '@/components/payment-flows/base-values/base-values';

@Component({
    components: {
        YearMonthPickerComponent,
        InputNumericComponent,
        ValidationTableFeedbackComponent,
        BaseValuesComponent
    }
})
export default class BasicPercentageStreamComponent extends BaseComponent {
    @Prop()
    complexErrors!: ValidationError[];
    @Prop()
    stream!: BasicPercentageStream;
    @Prop()
    typeOfFlow!: string;
    
    variablePercentagesGrid: InlineEdit<VariablePercentage> | null = null;

    constructor() {
        super();
        this.variablePercentagesGrid = new InlineEdit<VariablePercentage>(this, "variablePercentagesTable", this.loadVariablePercentages, undefined, undefined, this.newVariablePercentage, undefined, this.hasNoErrors)
        this.variablePercentagesGrid.sync = true;
        this.variablePercentagesGrid.useConfirmDeleteDialog = false;
    }

    @Watch("stream", { immediate: true })
    streamWatcher() {
        if (!this.stream) return;
        
        this.variablePercentagesGrid!.reload()
    }

    loadVariablePercentages(): AxiosPromise<VariablePercentage[]> {
        return Promise.resolve(<AxiosResponse> { data: this.stream ? this.stream.variablePercentages : [] });
    }

    newVariablePercentage(): VariablePercentage {
        return <VariablePercentage> {
            id: 0,
            startDate: this.stream.startDate,
            percentage: 0.0
        }
    }
}