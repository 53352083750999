import BaseComponent from "@/components/base-component";
import { Component, Watch, Prop } from "vue-property-decorator";
import dataService from "@/services/data-service";
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import InputTextComponent from '@/components/common/input-text/input-text';
import { ValidationError } from '@/models/interfaces/ValidationError';
import validationService from '@/services/validation-service';
import { EditVersionDialogMode, DefaultDeductingExpenses } from '@/constants/fia-constants';
import { PropertyVersionSlim } from '@/models/interfaces/PropertyVersionSlim';
import { Property, County, Subcounty, Country } from '@/models';
import { CopyPropertyVersionRequest } from '@/models/interfaces/request/CopyPropertyVersionRequest';
import { PropertyLocation } from '@/models/interfaces/PropertyLocation';

@Component({
    components: {}
})
export default class LocationFormComponent extends BaseComponent {
    
    @Prop()
    property!: Property;
    @Prop({ type: Boolean, default: false })
    nestedDialog!: boolean;

    countyList: County[] = [];
    subcountyList: Subcounty[] = [];
    countryList: Country[] = [];
    unfilteredCountryList: Country[] = [];
    locationList: PropertyLocation[] = [];
    searchTextLocation: string = "";
    dialogVisible: boolean = false;

    @Watch('property')
    async propertyWatcher() {
        if (this.property) {
            await this.updateLocation();
        }
    }

    async mounted() {
        try {
            this.countryList = await dataService.getCountries(this.$store.getters.getUser.id, true).then(x => {
                this.unfilteredCountryList = [...x.data];
                return x.data;
            });

            this.updateLocation();
        } catch (error) {
        }
    }

    async selectLocation(item: PropertyLocation) {
        if (!this.property)
            return;

        this.property.subcountyId = item.subcountyId;
        this.property.viewCountyId = item.countyId;
        this.property.viewCountryId = item.countryId;
        await this.updateLocation();
        this.dialogVisible = false;
    }

    async updateLocation() {
        await this.updateCounties();
        await this.updateSubcounties();

        this.countryList = this.unfilteredCountryList.filter(x => !x.inActive || x.id === this.property.viewCountryId);
        this.countyList = this.countyList.filter(x => this.countryList.map(c => c.id).includes(x.countryId));
        this.subcountyList = this.subcountyList.filter(x => this.countyList.map(c => c.id).includes(x.countyId));
    }

    async countryChanged() {
        if (!this.property) return;
        await this.updateCounties();
        this.property!.viewCountyId = this.countyList[0].id;
        await this.updateSubcounties();
        this.property!.subcountyId = this.subcountyList[0].id;
    }

    async countyChanged() {
        if (!this.property) return;
        await this.updateSubcounties();
        this.property!.subcountyId = this.subcountyList[0].id;
    }

    async updateCounties() {
        if (!this.property) return;
        this.countyList = await dataService.getCountiesByCountry(this.property!.viewCountryId).then(x => x.data);
    }

    async updateSubcounties() {
        if (!this.property) return;
        this.subcountyList = await dataService.getSubcountiesByCountyId(this.property!.viewCountyId).then(x => x.data);
    }

    formatCountryId(row: PropertyLocation, column: any, cellValue: any, index: any) {
        return row.countryName;
    }

    formatCountyId(row: PropertyLocation, column: any, cellValue: any, index: any) {
        return row.countyName;
    }

    formatSubcountyId(row: PropertyLocation, column: any, cellValue: any, index: any) {
        return row.subcountyName;
    }

    async searchLocation() {
        try {
            this.locationList = await dataService.searchLocations(this.searchTextLocation).then(x => x.data);
        } catch (error) {
            return Promise.reject(new Error(""));
        }
    }

    async searchLocationRecent() {
        try {
            this.locationList = await dataService.getLatestLocations(12).then(x => x.data);
        } catch (error) {
            return Promise.reject(new Error(""));
        }
    }

    clearLocation() {
        this.searchTextLocation = "";
    }
}