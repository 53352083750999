import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import BaseComponent from '@/components/base-component';
import { ContractSearchListItem } from '@/models';
import { TreeGridPlugin, TreeGrid, Toolbar, Sort, VirtualScroll, Filter, ExcelExport, Edit, Freeze } from '@syncfusion/ej2-vue-treegrid';
import Vue from 'vue';
import { ContractSearchResponse } from "@/models/interfaces/response/ContractSearchResponse";
import DeleteBtnTplComponent from "./delete-btn-tpl";

Vue.use(TreeGridPlugin);
TreeGrid.Inject(Toolbar, Sort, VirtualScroll, Filter, ExcelExport, Edit, Freeze);

@Component({})
export default class ContractSearchResultsComponent extends BaseComponent {
    pageSettings: any = { pageSize: 30 };
    shortDateFormat: any = { type: "date", format: "yyyy-MM-dd" };
    excelFilter: any = { type: "Excel" };
    menuFilter: any = { type: "Menu" };
    barFilter: any = { type: "FilterBar" };
    filterSettings: any = { type: "Excel" };
    deleteRowTemplate: any = null;
    editSettings: any = { allowDeleting: true };
    selectedRow: ContractSearchListItem | null = null;
    comment: string = "";
    exporting = false;

    // Non-reactive properties
    constractSearchGridRef: any;

    @Prop({ default: false })
    searching!: boolean;

    @Prop({ default: <ContractSearchResponse>{ result: [], moreRows: false, maxRows: 0 } })
    searchResponse!: ContractSearchResponse;

    @Watch("searchResponse")
    searchResponseWatcher() {
        this.constractSearchGridRef.clearFiltering();
        this.constractSearchGridRef.goToPage(1);
    }

    mounted() {
        this.constractSearchGridRef = this.$refs.constractSearchGrid;

        this.deleteRowTemplate = () => {
            return {
                template: {
                    extends: DeleteBtnTplComponent,
                    methods: {
                        remove: this.removeListItem
                    }
                }
            }
        };
    }

    removeListItem(id: number): void {
        const row = this.searchResponse.result.find(x => x.id === id);
        if (!row) return;
        this.constractSearchGridRef.deleteRecord("id", row.id);
    }

    async exportToExcel() {
        this.exporting = true;
        const gridColumns = this.constractSearchGridRef.ej2Instances.grid.getColumns();
        const deleteRowColumn = gridColumns[3];
        const exportOptions = {
            theme: {
                header: {
                    fontName: 'Arial', fontSize: 9, bold: true, wrapText: true
                },
                record: {
                    fontName: 'Arial', fontSize: 9, bold: false
                }
            }
          }
        
        try {
            deleteRowColumn.visible = false;
            await this.constractSearchGridRef.excelExport(exportOptions);
        } catch (error) {
            throw error;
        } finally {
            deleteRowColumn.visible = true;
            this.exporting = false;
        }
    }

    canExportToExcel(): boolean {
        return !this.exporting && this.searchResponse && this.searchResponse.result.length > 0;
    }

    rowSelected(args: any) {
        this.selectedRow = args.data;
        this.comment = this.selectedRow ? (this.selectedRow.comment || "") : "";
    }

    excelQueryCellInfo(args: any) {
        if (args.value && args.value.length > 10 && args.column.field === "startDate" || args.column.field === "endDate" || args.column.field === "marketRentDate") {
            args.value = (args.value as string).substr(0, 10);
        }
    }

    excelHeaderQueryCellInfo(args: any) {
        args.style.hAlign = "left";

        const fieldName = args.gridCell.column.field;
        let columnWidth = "90";
        switch (fieldName) {
            case "propertyIdentifier":
            case "address":
            case "tenant":
                columnWidth = "200";
                break;
            case "passingRentExRecM2":
            case "marketValueM2":
                columnWidth = "115";
                break;
            case "marketRentDate2":
                columnWidth = "100";
                break;
            case "comment":
                columnWidth = "1000";
                break;
        }
        args.gridCell.column.width = columnWidth;
    }

    gridActionBegin(args: any) {
        if (args.requestType === "filterchoicerequest") {
            args.filterChoiceCount = this.searchResponse.maxRows;
        }
    }
}