import Vue from 'vue';
import Vuex from 'vuex';
import { MutationTree, ActionTree } from 'vuex';
import DataService from "@/services/data-service";
import { PropertyVersion, User, Property, TypeOfPayment, Database, Development, Portfolio } from "@/models";
import { BaseDataCache } from '@/models/interfaces/BaseDataCache';
import { BaseDataType, PaymentGroupEnum, AppRightEnum, appRightTopLevel } from '@/constants/fia-constants';
import { ValidationError } from '@/models/interfaces/ValidationError';
import { Inactivatable } from '@/models/interfaces/Inactivatable';
import { PropertyVersionSlim } from '@/models/interfaces/PropertyVersionSlim';
import { CopyPropertyVersionRequest } from '@/models/interfaces/request/CopyPropertyVersionRequest';
import { BusMessage, PropertyVersionUpdatedMessage, PropertyVersionCopiedMessage, PropertyVersionAddedMessage, VersionLoadedMessage, SearchedPropertiesFromMapMessage } from '@/models/messages/messages';
import { PremisesArea } from '@/models/PremisesArea';
import { PropertySearchRequest as PropertySearchRequest } from '@/models/PropertySearchRequest';

const defaultCache: BaseDataCache = {
    buildingRightPurposes: [],
    buildingRightUnits: [],
    currencies: [],
    developmentTypes: [],
    prognosisAlternatives: [],
    premisesTypes: [],
    premisesTypeGroups: [],
    marketTypes: [],
    paymentGroups: [],
    typeOfPayments: [],
    typeOfPaymentGroups: [],
    propertyTaxTypes: [],
    prognosisParameters: [],
    indexDenotations: [],
    indexClauses: [],
    ipd6: [],
    statuses: [],
    typeOfPaymentStreams: [],
    languages: [],
    taxTypeCodes: [],
    interestTypes: [],
    amortizationTypes: [],
    propertyDetailsExtraFieldTypes: [],
    environmentalCertificates: [],
    environmentalCertificateLevels: [],
}

Vue.use(Vuex);

interface State {
    connectionId: string,
    baseData: BaseDataCache,
    propertyId: number | null,
    portfolio: Portfolio | null,
    searchedPropertyIds: number[],
    searchingProperties: boolean,
    propertySearchRequest: PropertySearchRequest,
    propertyVersion: PropertyVersion | null,
    property: Property | null,
    user: User,
    lastUserSettings: string;
    propertyLoading: boolean,
    propertyVersionLoading: boolean,
    mapVisible: boolean,
    databases: Database[],

    currenciesLoaded: boolean,
    areasLoaded: boolean,
    premisesLoaded: boolean,
    developmentsLoaded: boolean,
    marketsLoaded: boolean,
    paymentsLoaded: boolean,
    taxesLoaded: boolean,
    prognosisLoaded: boolean,
    prognosisParametersLoaded: boolean,
    indexLoaded: boolean,
    indexClausesLoaded: boolean,
    ipdStatusesLoaded: boolean,
    ipd6Loaded: boolean,
    languagesLoaded: boolean,
    interestTypesLoaded: boolean,
    amortizationTypesLoaded: boolean,
    typeOfPaymentGroupsLoaded: boolean;
    propertyDetailsExtraFieldTypesLoaded: boolean;
    environmentalCertificatesLoaded: boolean,
    environmentalCertificateLevelsLoaded: boolean,

    tabLevel1: string,
    deletingPropertyVersion: boolean,
    busMessage: any;
}

const mutations: MutationTree<State> = {
    updateBaseData: (state, baseData: BaseDataCache) => {
        state.baseData = baseData;
    },
    // updateCurrencies: (state, currencies: Currency[]) => {
    //     state.baseData.currencies = currencies;
    // },
    // updateDevelopmentTypes: (state, developmentTypes: DevelopmentType[]) => {
    //     state.baseData.developmentTypes = developmentTypes;
    // },
    // updatePrognosisAlternatives: (state, prognosisAlternatives: PrognosisAlternative[]) => {
    //     state.baseData.prognosisAlternatives = prognosisAlternatives;
    // },
    // updatePremisesTypes: (state, premisesTypes: PremisesType[]) => {
    //     state.baseData.premisesTypes = premisesTypes;
    // },
    // updatePremisesTypeGroups: (state, premisesTypeGroups: PremisesTypeGroup[]) => {
    //     state.baseData.premisesTypeGroups = premisesTypeGroups;
    // },
    // updateMarketTypes: (state, marketTypes: MarketType[]) => {
    //     state.baseData.marketTypes = marketTypes;
    // },
    // updatePaymentGroups: (state, paymentGroups: PaymentGroup[]) => {
    //     state.baseData.paymentGroups = paymentGroups;
    // },
    // updateTypeOfPayments: (state, typeOfPayments: TypeOfPayment[]) => {
    //     state.baseData.typeOfPayments = typeOfPayments;
    // },
    // updatePropertyTaxTypes: (state, propertyTaxTypes: PropertyTaxType[]) => {
    //     state.baseData.propertyTaxTypes = propertyTaxTypes;
    // },
    // updatePrognosisParameters: (state, prognosisParameters: PrognosisParameter[]) => {
    //     state.baseData.prognosisParameters = prognosisParameters;
    // },
    // updateIndexDenotations: (state, indexDenotations: IndexDenotation[]) => {
    //     state.baseData.indexDenotations = indexDenotations;
    // },
    // updateIndexClauses: (state, indexClauses: IndexClause[]) => {
    //     state.baseData.indexClauses = indexClauses;
    // },
    // updateIpd6: (state, ipd6: Ipd6[]) => {
    //     state.baseData.ipd6 = ipd6;
    // },
    // updateStatuses: (state, statuses: Status[]) => {
    //     state.baseData.statuses = statuses;
    // },
    // updateTypeOfPaymentStreams: (state, typeOfPaymentStreams: TypeOfPaymentStream[]) => {
    //     state.baseData.typeOfPaymentStreams = typeOfPaymentStreams.filter(x => x.id !== PaymentStreamType.PropertyTaxStream); // Never use tax stream type
    // },
    // updateLanguages: (state, languages: Language[]) => {
    //     state.baseData.languages = languages;
    // },
    // updateTaxTypeCodes: (state, taxTypeCodes: TaxTypeCode[]) => {
    //     state.baseData.taxTypeCodes = taxTypeCodes;
    // },
    // updateInterestTypes: (state, interestTypes: InterestType[]) => {
    //     state.baseData.interestTypes = interestTypes;
    // },
    // updatePropertyDetailsExtraFieldTypes: (state, propertyDetailsExtraFieldTypes: PropertyDetailsExtraFieldType[]) => {
    //     state.baseData.propertyDetailsExtraFieldTypes = propertyDetailsExtraFieldTypes;
    // },
    // updateAmortizationTypes: (state, amortizationTypes: AmortizationType[]) => {
    //     state.baseData.amortizationTypes = amortizationTypes;
    // },

    setPropertyId: (state, id) => {
        state.propertyId = id;
    },
    setPortfolio: (state, portfolio) => {
        state.portfolio = portfolio;
    }, 
    setPropertyVersion: (state, version) => { 
        state.propertyVersion = version;
    },
    setVersionPremisesAreas: (state, premisesAreas: PremisesArea[]) => { 
        if (state.propertyVersion) {
            state.propertyVersion.premisesAreas = premisesAreas;
        }
    },
    setVersionLeaseArea: (state, leaseArea: number) => { 
        if (state.propertyVersion) {
            state.propertyVersion.leaseAreaSum = leaseArea;
        }
    },
    setProperty: (state, property) => {
        state.property = property;
    },
    setUser: (state, user: User) => {
        state.user = user;
        state.lastUserSettings = user.userSettings ? JSON.stringify(user.userSettings) : "";
    },
    setDatabases: (state, databases: Database[]) => {
        state.databases = databases;
    },
    propertyLoading: (state, loading) => {
        state.propertyLoading = loading;
    },
    propertyVersionLoading: (state, loading) => {
        state.propertyVersionLoading = loading;
    },
    setSearchedPropertyIds: (state, ids) => {
        state.searchedPropertyIds = ids;
    },
    searchingProperties: (state, searching) => {
        state.searchingProperties = searching;
    },
    setPropertySearchRequest: (state, request: PropertySearchRequest) => {
        state.propertySearchRequest = request;
    },
    setMapVisible: (state, visible) => {
        state.mapVisible = visible;
    },
    setDevelopmentsInVersion: (state, developments: Development[]) => {
        if (state.propertyVersion) {
            state.propertyVersion.developments = developments;
        }
    },
    setCurrenciesLoaded: (state, loaded: boolean) => {
        state.currenciesLoaded = loaded;
    },
    setEnvironmentalCertificatesLoaded: (state, loaded: boolean) => {
        state.environmentalCertificatesLoaded = loaded;
    },
    setEnvironmentalCertificateLevelsLoaded: (state, loaded: boolean) => {
        state.environmentalCertificateLevelsLoaded = loaded;
    },
    setAreasLoaded: (state, loaded: boolean) => {
        state.areasLoaded = loaded;
    },
    setPremisesLoaded: (state, loaded: boolean) => {
        state.premisesLoaded = loaded;
    },
    setDevelopmentsLoaded: (state, loaded: boolean) => {
        state.developmentsLoaded = loaded;
    },
    setMarketsLoaded: (state, loaded: boolean) => {
        state.marketsLoaded = loaded;
    },
    setPaymentsLoaded: (state, loaded: boolean) => {
        state.paymentsLoaded = loaded;
    },
    setTypeOfPaymentGroupsLoaded: (state, loaded: boolean) => {
        state.typeOfPaymentGroupsLoaded = loaded;
    },
    setTaxesLoaded: (state, loaded: boolean) => {
        state.taxesLoaded = loaded;
    },
    setPrognosisLoaded: (state, loaded: boolean) => {
        state.prognosisLoaded = loaded;
    },
    setPrognosisParametersLoaded: (state, loaded: boolean) => {
        state.prognosisParametersLoaded = loaded;
    },
    setIndexLoaded: (state, loaded: boolean) => {
        state.indexLoaded = loaded;
    },
    setIndexClausesLoaded: (state, loaded: boolean) => {
        state.indexClausesLoaded = loaded;
    },
    setIpdStatusesLoaded: (state, loaded: boolean) => {
        state.ipdStatusesLoaded = loaded;
    },
    setIpd6Loaded: (state, loaded: boolean) => {
        state.ipd6Loaded = loaded;
    },
    setLanguagesLoaded: (state, loaded: boolean) => {
        state.languagesLoaded = loaded;
    },
    setInterestTypesLoaded: (state, loaded: boolean) => {
        state.interestTypesLoaded = loaded;
    },
    setAmortizationTypesLoaded: (state, loaded: boolean) => {
        state.amortizationTypesLoaded = loaded;
    },
    

    setTabLevel1: (state, tabName: string) => {
        state.tabLevel1 = tabName;
    },
    setDeletingPropertyVersion: (state, deleting: boolean) => {
        state.deletingPropertyVersion = deleting;
    },
    updatePropertyVersion: (state, version: PropertyVersionSlim) => {
        if (state.propertyVersion) {
            state.propertyVersion.versionIdentifier = version.versionIdentifier;
            state.propertyVersion.calculationStart = version.calculationStart;
            state.propertyVersion.calculationEnd = version.calculationEnd;
            state.propertyVersion.locked = version.locked;
            state.propertyVersion.currencyId = version.currencyId;
        }
    },
    setConnectionId: (state, connectionId: string) => {
        state.connectionId = connectionId;
    },
    busMessage: (state, payload: any) => {
        state.busMessage = payload;
    }
}

const actions: ActionTree<State, any> = {
    updateCache: (context, type: BaseDataType) => {
        if (type === BaseDataType.All) {
            return DataService.getBaseData(context.state.user.languageId, true).then(x => {
                context.commit('updateBaseData', x.data);
            });
        }

        // let tasks: Promise<any>[] = [];
        // if (type === BaseDataType.Currencies) {
        //     tasks.push(DataService.getCurrencies(context.state.user.languageId, true).then(x => {
        //         context.commit('updateCurrencies', x.data);
        //     }));
        // }
        // if (type === BaseDataType.All || type === BaseDataType.DevelopmentTypes) {
        //     tasks.push(DataService.getDevelopmentTypes(context.state.user.languageId, true).then(x => {
        //         context.commit('updateDevelopmentTypes', x.data);
        //     }));
        // }
        // if (type === BaseDataType.All || type === BaseDataType.PrognosisAlternatives) {
        //     tasks.push(DataService.getPrognosisAlternatives(context.state.user.languageId, true).then(x => {
        //         context.commit('updatePrognosisAlternatives', x.data);
        //     }));
        // }
        // if (type === BaseDataType.All || type === BaseDataType.PremisesTypes) {
        //     tasks.push(DataService.getPremisesTypes(context.state.user.languageId, true).then(x => {
        //         context.commit('updatePremisesTypes', x.data);
        //     }));
        // }
        // if (type === BaseDataType.All || type === BaseDataType.MarketTypes) {
        //     tasks.push(DataService.getMarketTypes(context.state.user.languageId, true).then(x => {
        //         context.commit('updateMarketTypes', x.data);
        //     }));
        // }
        // if (type === BaseDataType.All || type === BaseDataType.PaymentGroups) {
        //     tasks.push(DataService.getPaymentGroups(context.state.user.languageId, true).then(x => {
        //         context.commit('updatePaymentGroups', x.data);
        //     }));
        // }
        // if (type === BaseDataType.All || type === BaseDataType.TypeOfPayments) {
        //     tasks.push(DataService.getTypeOfPayments(context.state.user.languageId, true).then(x => {
        //         context.commit('updateTypeOfPayments', x.data);
        //     }));
        // }
        // if (type === BaseDataType.All || type === BaseDataType.PropertyTaxTypes) {
        //     tasks.push(DataService.getPropertyTaxTypes(context.state.user.languageId, true).then(x => {
        //         context.commit('updatePropertyTaxTypes', x.data);
        //     }));
        // }
        // if (type === BaseDataType.All || type === BaseDataType.PrognosisParameters) {
        //     tasks.push(DataService.getPrognosisParameters(context.state.user.languageId, true).then(x => {
        //         context.commit('updatePrognosisParameters', x.data);
        //     }));
        // }
        // if (type === BaseDataType.All || type === BaseDataType.IndexDenotations) {
        //     tasks.push(DataService.getIndexDenotations(context.state.user.languageId, true).then(x => {
        //         context.commit('updateIndexDenotations', x.data);
        //     }));
        // }
        // if (type === BaseDataType.All || type === BaseDataType.IndexClauses) {
        //     tasks.push(DataService.getIndexClauses(context.state.user.languageId, true).then(x => {
        //         context.commit('updateIndexClauses', x.data);
        //     }));
        // }
        // if (type === BaseDataType.All || type === BaseDataType.Ipd6) {
        //     tasks.push(DataService.getIpd6(context.state.user.languageId, true).then(x => {
        //         context.commit('updateIpd6', x.data);
        //     }));
        // }
        // if (type === BaseDataType.All || type === BaseDataType.Statuses) {
        //     tasks.push(DataService.getStatuses(context.state.user.languageId, true).then(x => {
        //         context.commit('updateStatuses', x.data);
        //     }));
        // }
        // if (type === BaseDataType.All || type === BaseDataType.TypeOfPaymentStreams) {
        //     tasks.push(DataService.getTypeOfPaymnetStreams(context.state.user.languageId, true).then(x => {
        //         context.commit('updateTypeOfPaymentStreams', x.data);
        //     }));
        // }
        // if (type === BaseDataType.All || type === BaseDataType.Language) {
        //     tasks.push(DataService.getLanguages(context.state.user.languageId, true).then(x => {
        //         context.commit('updateLanguages', x.data);
        //     }));
        // }
        // if (type === BaseDataType.All || type === BaseDataType.TaxTypeCodes) {
        //     tasks.push(DataService.getTaxTypeCodes(context.state.user.languageId, true).then(x => {
        //         context.commit('updateTaxTypeCodes', x.data);
        //     }));
        // }

        // return Promise.all(tasks);
    },
    loadPropertyVersion: async (context, args: { id: number, source: string }) => {
        try {
            context.commit('propertyVersionLoading', true);
            let version = await DataService.getPropertyVersion(args.id).then(x => x.data);
            if (!version) {
                context.commit('setPropertyId', null);
            } 
            else if (context.state.propertyId !== version.propertyId)
            {
                context.commit('setPropertyId', version.propertyId);
            }
            context.commit('setPropertyVersion', version);
            context.commit("busMessage", <BusMessage<VersionLoadedMessage>>{ type: "VersionLoaded", data: { source: args.source } });
        } finally {
            context.commit('propertyVersionLoading', false);
        }
    },
    setSearchedProperties: async (context, args: { propertyIds: number[], source: string }) => {
        context.commit("busMessage", <BusMessage<SearchedPropertiesFromMapMessage>>{ type: "SearchedPropertiesFromMap", data: { propertyIds: args.propertyIds } });
    },
    loadVersionPremisesAreas: async (context, versionId: number) => {
        let premisesAreas = await DataService.getVersionPremisesAreas(versionId).then(x => x.data);
        context.commit("setVersionPremisesAreas", premisesAreas);
    },
    loadVersionLeaseArea: async (context, versionId: number) => { 
        let totalArea = await DataService.getVersionTotalLeaseArea(versionId).then(x => x.data);
        context.commit("setVersionLeaseArea", totalArea);
    },
    loadProperty: (context, id: number) => {
        context.commit('propertyLoading', true);
        return DataService.getProperty(id, true).then(x => {
            context.commit('setProperty', x.data);
            context.commit('propertyLoading', false);
        });
    },
    clearProperty: (context) => {
        context.commit('setProperty', null);
    },
    loadUser: async (context) => {
        const user = await DataService.getUser().then(x => x.data);
        const databases = await DataService.getDatabases().then(x => x.data);
        if (user && databases) {
            context.commit('setUser', user);
            context.commit('setDatabases', databases);
        } else {
            throw new Error("Unauthorized");
        }
    },
    setPropertyId: (context, id: number) => {
        context.commit('setPropertyId', id);
    },
    setPropertyVersion: (context, version: PropertyVersion) => {
        context.commit('setPropertyVersion', version);
    },
    setPortfolio: (context, portfolio: Portfolio | null) => {
        context.commit('setPortfolio', portfolio);
    },
    releaseLoading: (context) => {
        context.commit('contractLoading', false);
        context.commit('propertyVersionLoading', false);
        context.commit('propertyLoading', false);
    },
    setSearchedPropertyIds: (context, ids) => {
        context.commit('setSearchedPropertyIds', ids);
    },
    setSearchingProperties: (context, searching) => {
        context.commit('searchingProperties', searching);
    },
    setPropertySearchRequest: (context, request: PropertySearchRequest) => {
        context.commit('setPropertySearchRequest', request);
    },
    setMapVisible: (context, visible) => {
        context.commit('setMapVisible', visible);
    },
    updateContractComplexErrors: (context, errors: ValidationError[]) => {
        context.commit('setContractComplexErrors', errors);
    },
    setDevelopmentsInVersion: async (context, developments: Development[]) => {
        context.commit('setDevelopmentsInVersion', developments);
    },
    setCurrenciesLoaded: (context, active: boolean) => {
        context.commit('setCurrenciesLoaded', active);
    },
    setEnvironmentalCertificatesLoaded: (context, active: boolean) => {
        context.commit('setEnvironmentalCertificatesLoaded', active);
    },
    setEnvironmentalCertificateLevelsLoaded: (context, active: boolean) => {
        context.commit('setEnvironmentalCertificateLevelsLoaded', active);
    },
    setAreasLoaded: (context, active: boolean) => {
        context.commit('setAreasLoaded', active);
    },
    setPremisesLoaded: (context, active: boolean) => {
        context.commit('setPremisesLoaded', active);
    },
    setDevelopmentsLoaded: (context, active: boolean) => {
        context.commit('setDevelopmentsLoaded', active);
    },
    setMarketsLoaded: (context, active: boolean) => {
        context.commit('setMarketsLoaded', active);
    },
    setPaymentsLoaded: (context, active: boolean) => {
        context.commit('setPaymentsLoaded', active);
    },    
    setTypeOfPaymentGroupsLoaded: (context, active: boolean) => {
        context.commit('setTypeOfPaymentGroupsLoaded', active);
    },
    setTaxesLoaded: (context, active: boolean) => {
        context.commit('setTaxesLoaded', active);
    },
    setPrognosisLoaded: (context, active: boolean) => {
        context.commit('setPrognosisLoaded', active);
    },
    setPrognosisParametersLoaded: (context, active: boolean) => {
        context.commit('setPrognosisParametersLoaded', active);
    },
    setIndexLoaded: (context, active: boolean) => {
        context.commit('setIndexLoaded', active);
    },
    setIndexClausesLoaded: (context, active: boolean) => {
        context.commit('setIndexClausesLoaded', active);
    },
    setIpdStatusesLoaded: (context, active: boolean) => {
        context.commit('setIpdStatusesLoaded', active);
    },
    setIpd6Loaded: (context, active: boolean) => {
        context.commit('setIpd6Loaded', active);
    },
    setLanguagesLoaded: (context, active: boolean) => {
        context.commit('setLanguagesLoaded', active);
    },
    setInterestTypesLoaded: (context, active: boolean) => {
        context.commit('setInterestTypesLoaded', active);
    },
    setPropertyDetailsExtraFieldTypesLoaded: (context, active: boolean) => {
        context.commit('setPropertyDetailsExtraFieldTypesLoaded', active);
    },
    setAmortizationTypesLoaded: (context, active: boolean) => {
        context.commit('setAmortizationTypesLoaded', active);
    },

    
    setTabLevel1: (context, tabName: string) => {
        context.commit('setTabLevel1', tabName)
    },
    deletePropertyVersion: (context, versionId: number) => {
        context.commit('setPropertyVersion', null);
        context.commit('setDeletingPropertyVersion', true);
        return DataService.deletePropertyVersion(versionId).then(() => {
            context.commit('setDeletingPropertyVersion', false);
        }).catch(() => {
            context.commit('setDeletingPropertyVersion', false);
            throw new Error("An error occurred while deleting the property version.");
        });
    },
    updatePropertyVersion: (context, propertyVersion: PropertyVersionSlim) => {
        return DataService.updatePropertyVersion(propertyVersion).then(() => {
            context.commit('updatePropertyVersion', propertyVersion);
            context.commit('busMessage', <BusMessage<PropertyVersionUpdatedMessage>>{ type: "PropertyVersionUpdated", data: propertyVersion });
        }).catch(() => {
            throw new Error("An error occurred while updating the property version.");
        });
    },
    setConnectionId: (context, connectionId: string) => {
        context.commit('setConnectionId', connectionId)
    },
    addPropertyVersion: (context, propertyVersion: PropertyVersionSlim) => {
        return DataService.addPropertyVersion(propertyVersion).then(() => {
            context.commit('busMessage', <BusMessage<PropertyVersionAddedMessage>>{ type: "PropertyVersionAdded", data: propertyVersion });
        }).catch(() => {
            throw new Error("An error occurred while updating the property version.");
        });
    },
    copyPropertyVersion: (context, request: CopyPropertyVersionRequest) => {
        return DataService.copyPropertyVersion(request).then(() => {
            context.commit('busMessage', <BusMessage<PropertyVersionCopiedMessage>>{ type: "PropertyVersionCopied", data: { propertyId: request.targetPropertyId, propertyVersionId: request.sourcePropertyVersionId } });
        }).catch(() => {
            throw new Error("An error occurred while updating the property version.");
        });
    },
    sendBusMessage: (context, message: BusMessage<any>) => {
        context.commit("busMessage", message);
    },
    setUser: (context, user: User) => {
        context.commit('setUser', user);
    }
}

const state: State = {
    connectionId: '',
    baseData: defaultCache,
    propertyId: 0,
    portfolio: null,
    searchedPropertyIds: [],
    searchingProperties: false,
    propertySearchRequest: <PropertySearchRequest>{},
    propertyVersion: null,
    property: null,
    user: <User>{},
    lastUserSettings: '',
    propertyLoading: false,
    propertyVersionLoading: false,
    mapVisible: true,
    databases: [],

    currenciesLoaded: false,
    areasLoaded: false,
    premisesLoaded: false,
    developmentsLoaded: false,
    marketsLoaded: false,
    paymentsLoaded: false,
    taxesLoaded: false,
    prognosisLoaded: false,
    prognosisParametersLoaded: false,
    indexLoaded: false,
    indexClausesLoaded: false,
    ipdStatusesLoaded: false,
    ipd6Loaded: false,
    languagesLoaded: false,
    interestTypesLoaded: false,
    propertyDetailsExtraFieldTypesLoaded: false,
    amortizationTypesLoaded: false,
    typeOfPaymentGroupsLoaded: false,
    environmentalCertificatesLoaded: false,
    environmentalCertificateLevelsLoaded: false,


    tabLevel1: "propertyVersion",
    deletingPropertyVersion: false,
    busMessage: null
}

export default new Vuex.Store<State>({
    state,
    mutations,
    actions,
    getters: {
        getPropertyId: state => state.propertyId,
        getPortfolio: state => state.portfolio,
        getSearchedPropertyIds: state => state.searchedPropertyIds,
        getSearchingProperties: state => state.searchingProperties,
        getPropertySearchRequest: state => state.propertySearchRequest,
        getPropertyVersion: state => state.propertyVersion,
        getUser: state => state.user,
        getProperty: state => state.property,
        getPropertyLoading: state => state.propertyLoading,
        getPropertyVersionLoading: state => state.propertyVersionLoading,
        getMapVisible: state => state.mapVisible,
        getPropertyVersionActive: state => state.propertyVersion != null,
        getDeletingPropertyVersion: state => state.deletingPropertyVersion,
        getAppPermission: state => (appRight: AppRightEnum, dbId?: number) => {
            if (state.user.userGroup.isSysAdmin) return true;

            const userRight = state.user.userRights.find(x => x.groupRight.appRightId == appRight &&
                                                              x.groupRight.databaseId === (dbId === undefined ? state.user.databaseId : dbId));
            if (userRight) return userRight.granted;

            const groupRight = state.user.userGroup.groupRights.find(x => x.appRightId == appRight &&
                                                                          x.databaseId === (dbId === undefined ? state.user.databaseId : dbId));

            return groupRight ? groupRight.granted : false;                                                                        
        },
        getDatabases: state => getDatabases(state, appRightTopLevel),
        getDatabasesByAppRight: state => (appRights: AppRightEnum[]) => {
            return getDatabases(state, appRights);
        },
        getUserSettingsHasChanged: state => state.lastUserSettings !== JSON.stringify(state.user.userSettings),
        
        // Cached base data
        getBuildingRightPurposes: state => state.baseData.buildingRightPurposes,
        getBuildingRightUnits: state => state.baseData.buildingRightUnits,
        getCurrencies: state => state.baseData.currencies,
        getPaymentGroups: state => state.baseData.paymentGroups,
        getPaymentGroupsNormal: state => state.baseData.paymentGroups.filter(x => x.id !== PaymentGroupEnum.NetOperatingIncome && x.id !== PaymentGroupEnum.CashFlow),
        getStatuses: state => state.baseData.statuses,
        getIpd6: state => state.baseData.ipd6,
        getTypeOfPaymentStreams: state => state.baseData.typeOfPaymentStreams,
        getLanguages: state => state.baseData.languages,
        getTaxTypeCodes: state => state.baseData.taxTypeCodes,
        getPremisesTypeGroups: state => state.baseData.premisesTypeGroups,
        getAmortizationTypes: state => state.baseData.amortizationTypes,
        getInterestTypes: state => state.baseData.interestTypes,
        getPropertyDetailsExtraFieldTypes: state => state.baseData.propertyDetailsExtraFieldTypes,
        getEnvironmentalCertificates: state => state.baseData.environmentalCertificates,
        getEnvironmentalCertificateLevels: state => state.baseData.environmentalCertificateLevels,

        getIndexDenotations: state => state.baseData.indexDenotations,
        getMarketTypes: state => state.baseData.marketTypes,
        getPropertyTaxTypes: state => state.baseData.propertyTaxTypes,
        getDevelopmentTypes: state => state.baseData.developmentTypes,
        getIndexClauses: state => state.baseData.indexClauses,
        getTypeOfPayments: state => state.baseData.typeOfPayments,
        getPrognosisParameters: state => state.baseData.prognosisParameters,
        getPremisesTypes: state => state.baseData.premisesTypes,
        getPrognosisAlternatives: state => state.baseData.prognosisAlternatives,

        // Cached base data (items can be inactive)
        getActiveIndexDenotations: state => (include: number[] = []) => filter(state.baseData.indexDenotations, include),
        getActiveMarketTypes: state => (include: number[] = []) => filter(state.baseData.marketTypes, include),
        getActivePropertyTaxTypes: state => (include: number[] = []) => filter(state.baseData.propertyTaxTypes, include),
        getActiveDevelopmentTypes: state => (include: number[] = []) => filter(state.baseData.developmentTypes, include),
        getActiveIndexClauses: state => (include: number[] = []) => filter(state.baseData.indexClauses, include),
        getActiveTypeOfPayments: state => (include: number[] = [], paymentGroupId?: number) => filterTypeOfPayments(state.baseData.typeOfPayments, include, paymentGroupId),
        getActivePrognosisParameters: state => (include: number[] = []) => filter(state.baseData.prognosisParameters, include),
        getActivePremisesTypes: state => (include: number[] = []) => filter(state.baseData.premisesTypes, include),
        getActivePrognosisAlternatives: state => (include: number[] = []) => filter(state.baseData.prognosisAlternatives, include),

        getConnectionId: state => state.connectionId,
        getCurrenciesLoaded: state => state.currenciesLoaded,
        getAreasLoaded: state => state.areasLoaded,
        getPremisesLoaded: state => state.premisesLoaded,
        getDevelopmentsLoaded: state => state.developmentsLoaded,
        getMarketsLoaded: state => state.marketsLoaded,
        getPaymentsLoaded: state => state.paymentsLoaded,
        getTypeOfPaymentGroupsLoaded: state => state.typeOfPaymentGroupsLoaded,
        getTaxesLoaded: state => state.taxesLoaded,
        getPrognosisLoaded: state => state.prognosisLoaded,
        getPrognosisParametersLoaded: state => state.prognosisParametersLoaded,
        getIndexLoaded: state => state.indexLoaded,
        getIndexClausesLoaded: state => state.indexClausesLoaded,
        getIpdStatusesLoaded: state => state.ipdStatusesLoaded,
        getIpd6Loaded: state => state.ipd6Loaded,
        getLanguagesLoaded: state => state.languagesLoaded,
        getTabLevel1: state => state.tabLevel1,
        getBusMessage: state => state.busMessage,
        getEnvironmentalCertificatesLoaded: state => state.environmentalCertificatesLoaded,
        getEnvironmentalCertificateLevelsLoaded: state => state.environmentalCertificateLevelsLoaded,
    }
});

function guid(): string {
    function _p8(s: any) {
        var p = (Math.random().toString(16) + "000000000").substr(2, 8);
        return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
    }
    return _p8(undefined) + _p8(true) + _p8(true) + _p8(undefined);
}

function filter(data: Inactivatable[], include: number[] | number | null): Inactivatable[] {
    if (include == null)
        include = [];

    if (!Array.isArray(include))
        include = [include];

    let filtered = data.filter(x => (!x.inactive || ((<number[]>include).findIndex(y => y === x.id) != -1)));
    return filtered;
}

function filterTypeOfPayments(data: TypeOfPayment[], include: number[] | number | null, paymentGroupId?: number): TypeOfPayment[] {
    let result = filter(data, include) as TypeOfPayment[];

    if (paymentGroupId)
        return result.filter(x => x.paymentGroupId === paymentGroupId);

    return result;
}

function getDatabases(state: State, appRights?: AppRightEnum[] | undefined): Database[] {
    if (state.user.userGroup.isSysAdmin) return state.databases;

    const groupRights = appRights === undefined ? state.user.userGroup.groupRights : state.user.userGroup.groupRights.filter(gr => appRights.some(p => gr.appRightId === p));
    const databaseIds = [...new Set(groupRights.map(gr => gr.databaseId))]
    const databases = state.databases.filter(d => databaseIds.some(id => id === d.id))
    return databases;
}
