import { Component, Watch, Prop } from "vue-property-decorator";
import { mapGetters } from 'vuex'
import dataService from "@/services/data-service";
import { Contract, PropertyVersion, PercentageIncrementStream, IndexIncrementStream, Transaction } from "@/models";
import BaseComponent from "@/components/base-component";
import { ContractListItem } from "@/models/interfaces/ContractListItem";
import ContractGetComponent from '@/components/contract/contract-get/contract-get';
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import utils from '@/helpers/utils';
import { PaymentStreamType, ProlongingType, Ipd6Enum, PrognosisParameterEnum, TypeOfPaymentEnum, BaseDataStatus, PremisesTypesEnum } from '@/constants/fia-constants';
import validationService from '@/services/validation-service';
import { ValidationError } from '@/models/interfaces/ValidationError';
import { CopyContractRequest } from '@/models/interfaces/request/CopyContractRequest';
import ContractUpdateSelectedComponent from '@/components/contract/contract-update-selected/contract-update-selected';
import TableSelectExtension from '@/helpers/table-select-extension';
import { RemoveContractsRequest } from '@/models/interfaces/request/RemoveContractsRequest';
import { PropertySearchRequest } from '@/models/PropertySearchRequest';
import ValidationErrorComponent from '@/components/common/validation-error/validation-error';
import { ValidationRule } from '@/models/interfaces/ValidationRule';
import ContractDetailsEditComponent from '@/components/contract/contract-details-edit/contract-details-edit';
import ContractBulkEditComponent from '@/components/contract/contract-bulk-edit/contract-bulk-edit';
import { BusMessage, ReloadCostincomeListMessage } from "@/models/messages/messages";

@Component({
    components: {
        YearMonthPickerComponent,
        ContractGetComponent,
        ContractUpdateSelectedComponent,
        ValidationErrorComponent,
        ContractDetailsEditComponent,
        ContractBulkEditComponent: ContractBulkEditComponent
    },
    computed: mapGetters({
        propertyVersion: "getPropertyVersion",
        propertySearchRequest: "getPropertySearchRequest"
    })
})
export default class ContractEditComponent extends BaseComponent {
    propertyVersion!: PropertyVersion;
    propertySearchRequest!: PropertySearchRequest;
    currentPropertyVersion: number = -1;

    contracts: ContractListItem[] = [];
    contract: Contract | null = null;
    selectedContract: ContractListItem | null = null;
    selectedContracts: ContractListItem[] = [];
    selectedContractDates: string[] = [];
    editMultipleDialogVisible: boolean = false;
    getDialogVisible: boolean = false;
    contractRowClicks: number = 0;
    loading: boolean = false;
    saving: boolean = false;
    deleting: boolean = false;
    copying: boolean = false;
    listLoading: boolean = false;
    contractCloned: string = "";
    complexErrors: ValidationError[] = [];
    gettingContract: boolean = false;
    listIsFocused: boolean = false;
    select: TableSelectExtension = new TableSelectExtension(this, 'contractTable');
    prevScrollPosition: { x: number, y: number } = { x: 0, y: 0 };
    firstLoad: boolean = true;
    editMode: string = "1"; // Empty string for collapsed and "1" for expanded
    contractSearchText: string = "";
    filtering: boolean = false;
    contractSearch: any;
    contractTable: any;
    filterTimerHandle: any;
    contractTableRows: any;
    activeTab: string = "Contract";

    @Prop()
    reload!: boolean;

    @Watch("reload", { immediate: true })
    reloadWatcher() {
        if (this.reload) {
            if (this.currentPropertyVersion != this.propertyVersion!.id) {
                this.generateContractList(undefined, undefined);
            }
            if (this.contractTable) {
                this.contractTable.doLayout();
            }
        }
    }

    @Watch("propertyVersion") versionWatcher(newVersion: any, oldVersion: any) {
        if (this.currentPropertyVersion == this.propertyVersion!.id) return;
        if (this.reload) {
            this.generateContractList(newVersion, oldVersion);
        }
    }

    @Watch("contract", { deep: true })
    contractWatcher() {
        validationService.validateContract(this.contract, this.complexErrors);
    }

    @Watch("complexErrors")
    errorsWatcher() {
        this.$emit("isValid", this.complexErrors.filter(x => x.rule != ValidationRule.Warning).length === 0)
    }

    generateContractList(newVersion: any, oldVersion: any) {
        this.setContract(null);
        this.selectedContract = null;
        this.selectedContracts = [];
        if (newVersion != undefined && oldVersion != undefined)
            this.prevScrollPosition = oldVersion && newVersion.property.id === oldVersion.property.id ? this.getScrollPosition() : { x: 0, y: 0 };
        this.updateContractList();
        this.complexErrors = [];
        this.scrollRowIntoView("el-table__row");
        this.currentPropertyVersion = this.propertyVersion!.id;
    }

    mounted() {
        this.contractSearch = (<any>this.$refs).contractSearch;
        this.contractTable = (<any>this.$refs).contractTable;
    }

    async updateContractList() {
        if (this.propertyVersion) {
            try {
                this.listLoading = true;
                this.contracts = await dataService.getContractList(this.propertyVersion.id).then(x => x.data);
            }
            catch (error) {
                return Promise.reject(new Error(""));
            }
            finally {
                this.listLoading = false;
                this.scrollToPrevPosition();
                this.$nextTick(() => {
                    (<any> this).$refs.contractTable.doLayout();
                });
            }
        }
        else {
            this.contracts = [];
        }
        this.contractSearchText = "";
        this.contractTableRows = this.contractTable.$refs.bodyWrapper.getElementsByClassName('el-table__row');
        this.contractSearch.focus();
    }

    resetSelectedRow() {
        this.$nextTick(() => {
            this.contractTable.setCurrentRow(this.selectedContract);
        })
    }

    async contractRowClick(item: ContractListItem) {
        if (!this.selectedContract || item.id !== this.selectedContract.id) {
            if (this.loading) {
                this.resetSelectedRow();
                return;
            }
        }

        if (item != this.selectedContract) {
            if (this.contractDirty()) {
                if (!(await this.discardCurrentChanges())) {
                    this.resetSelectedRow();
                    return;
                }
            }

            this.selectedContract = item;
            await this.loadContract(item.id);
        }
    }

    async discardCurrentChanges(): Promise<boolean> {
        try {
            await this.confirm("ConfirmDiscardChanges");
            return true;
        } catch (error) {
            return false;
        }
    }

    async loadContract(id: number) {
        try {
            this.loading = true;
            const contract = await dataService.getContract(id).then(x => x.data);
            this.setContract(contract);
        } catch (error) {
            this.setContract(null);
            return Promise.reject(new Error(""));
        } finally {
            this.loading = false;
        }
    }

    setContract(contract: Contract | null) {
        this.contract = contract;
        this.contractCloned = contract ? JSON.stringify(this.contract) : "";
    }

    selection(items: ContractListItem[], item: ContractListItem) {
        this.select.select(items, item);
        this.selectedContracts = items;
    }

    contractDirty(): boolean {
        return this.contract !== null && JSON.stringify(this.contract) !== this.contractCloned;
    }

    undo() {
        if (this.contractIsNew()) {
            this.setContract(null);
            return;
        }
        if (this.contractDirty()) {
            this.confirm('ConfirmUndoChanges').then(() => {
                this.contract = JSON.parse(this.contractCloned);
            }).catch(() => { });
        }
    }

    async add() {
        if (this.contractDirty() && !(await this.discardCurrentChanges())) {
            return;
        }

        this.selectedContract = null;
        this.resetSelectedRow();
        this.activeTab = "Contract";
        this.setContract(this.createContract());
    }

    async copy() {
        if (!this.selectedContract) return;

        const request = <CopyContractRequest>{ contractIds: [this.contract!.id], propertyVersionId: this.propertyVersion!.id };
        this.copying = true;
        try {
            const contractId = await dataService.copyContract(request).then(x => x.data);
            await this.updateContractList();
            await this.updateVersionData();
            if (contractId !== 0) {
                this.selectListItem(contractId);
                await this.loadContract(contractId);
            }
        } catch (error) {
            return Promise.reject(new Error(""));
        } finally {
            this.copying = false;
        }
    }

    async remove() {
        this.confirm('ConfirmRemoveContracts').then(async () => {
            this.deleting = true;
            let ids = this.selectedContracts.length > 0 ? this.selectedContracts.map(x => x.id) : [this.selectedContract!.id];
            let req: RemoveContractsRequest = { contractIds: ids, propertyVersionId: this.propertyVersion!.id }

            try {
                await dataService.deleteContracts(req);
                for (let id of ids) {
                    let ix = this.contracts.findIndex(c => c.id === id);
                    if (ix !== -1) {
                        this.contracts.splice(ix, 1);
                    }
                }
                this.setContract(null);
                this.selectedContract = null;
                this.selectedContracts = [];
                await this.updateVersionData();
            } catch (error) {
                return Promise.reject(new Error(""));
            } finally {
                this.deleting = false;
            }
        }).catch(() => { });
    }

    async save() {
        if (!this.contract) return;

        try {
            this.saving = true;
            const contract = await dataService.saveContract(this.contract).then(x => x.data);
            await this.saved(contract);
        } catch (error) {
            return Promise.reject(new Error(""));
        } finally {
            this.saving = false;
        }
    }

    // NOTE: also called when saving an edited recovery in the recovery edit dialog.
    // The saved contract (received from backend) must replace the current in the view
    async saved(contract: Contract) {
        if (this.contractIsNew() && contract) {
            await this.updateContractList();
            this.selectListItem(contract.id);
        }
        else {
            this.updateListItemFromContract(contract);
        }

        this.setContract(contract);
        await this.updateVersionData();
    }

    // Updates the list item values with the saved contract values
    updateListItemFromContract(contract: Contract) {
        if (!this.selectedContract) return;

        const firstStream = contract.paymentStreams[0];
        let percentageStream: PercentageIncrementStream | null = null;
        let indexStream: IndexIncrementStream | null = null;
        if (utils.isPercentageIncrementStreamType(firstStream.typeOfPaymentStreamId)) {
            percentageStream = firstStream as PercentageIncrementStream;
        }

        if (firstStream.typeOfPaymentStreamId === PaymentStreamType.IndexIncrementStream) {
            indexStream = firstStream as IndexIncrementStream;
        }

        this.selectedContract.propertyIdentifier = contract.propertyIdentifier;
        this.selectedContract.tenant = contract.tenant;
        this.selectedContract.mainPremisesTypeId = contract.mainPremisesTypeId;
        this.selectedContract.area = contract.leaseAreas.map(x => x.contractArea).reduce((a, b) => a + b, 0);
        this.selectedContract.typeOfPaymentId = contract.transaction.typeOfPaymentId;
        this.selectedContract.startDate = contract.startDate;
        this.selectedContract.endDate = contract.endDate;
        this.selectedContract.toCalcEnd = contract.prolongingTypeId === ProlongingType.UntilCalculationEnd;
        this.selectedContract.firstIncrement = percentageStream ? percentageStream.firstIncrement : null;
        this.selectedContract.monthOfChange = percentageStream ? parseInt(this.formatMonth(percentageStream.monthOfChange), 10) : null;
        this.selectedContract.paymentFrequency = firstStream.paymentFrequency;
        this.selectedContract.inAdvance = firstStream.inAdvance;
        this.selectedContract.baseNumber = indexStream ? indexStream.baseNumber : null;
        this.selectedContract.startAmount = percentageStream ? percentageStream.startAmount : null;
        this.selectedContract.hasAdditions = contract.recoveries.length > 0;
    }

    selectListItem(contractId: number) {
        let item = this.contracts.find(x => x.id === contractId);

        if (item) {
            this.selectedContract = item;
            this.$nextTick(() => {
                this.contractTable.setCurrentRow(this.selectedContract);
                this.scrollRowIntoView("current-row");
            })
        }
    }

    createContract(): Contract {
        return <Contract>{
            id: 0,
            prolongingPrognosisId: null,  // NOTE: always null, not used
            tenant: "",
            startDate: this.propertyVersion!.calculationStart,
            endDate: this.propertyVersion!.calculationEnd,
            propertyIdentifier: "",
            prolongationMonths: null,
            terminationMonth: null,
            movingInDate: this.monthStart(),
            prolongingTypeId: ProlongingType.UseMarketRent,
            marketRentIndexPercent: 1,
            multipleStreams: false,
            vat: null,
            corporateIdentityNumber: "",
            chargedRent: 0,
            ipd6Id: Ipd6Enum.Other,
            mainPremisesTypeId: PremisesTypesEnum.Office,
            prognosisParameterId: PrognosisParameterEnum.Inflation,
            statusId: BaseDataStatus.Let,
            transaction: <Transaction>{
                propertyVersionId: this.propertyVersion!.id,
                typeOfPaymentId: TypeOfPaymentEnum.RentOffice
            },
            paymentStreams: [],
            leaseAreas: [ { 
                id: 0,
                contractId: 0,
                numberOf: 1,
                contractArea: 0,
                value: null,
                premisesTypeId: PremisesTypesEnum.Office,
                marketTypeId: null // Null because the type has no relation to type of premise.
            } ],
            recoveries: [],
            internalComment: '',
            greenDeal: null
        }
    }

    listKeyUp(event: any) {
        if (this.listIsFocused && (event.which === 40 || event.which == 38)) {
            let d = (event.which == 40) ? "down" : "up";
            this.nextContract(d);
            event.preventDefault();
            return false;
        }
    }

    updateListFocus(focus: boolean) {
        this.listIsFocused = focus;
    }

    // Select the next item in the list. 
    // NOTE: take sorting into account (use the tableData array on the data table component)
    async nextContract(direction: string) {
        if (!this.selectedContract) return;
        let currentIx = this.contractTable.tableData.findIndex((x: ContractListItem) => x.id === this.selectedContract!.id);
        if (currentIx === -1 ||
            direction === "up" && currentIx === 0 ||
            direction === "down" && currentIx === (this.contracts.length - 1)) {
            return;
        };

        let newContractListItem = this.contractTable.tableData[currentIx + (direction === "up" ? -1 : 1)];
        this.contractTable.setCurrentRow(newContractListItem);
        await this.contractRowClick(newContractListItem);
        this.scrollRowIntoView("current-row");
    }

    scrollRowIntoView(className: string) {
        this.$nextTick(() => {
            // TODO: first check if the item is in view?
            let row = this.contractTable.$el.querySelector("." + className);
            if (row) row.scrollIntoView(false);
        });
    }

    contractIsNew(): boolean {
        return this.contract !== null && this.contract.id === 0;
    }

    contractValid(): boolean {
        return this.complexErrors.filter(x => x.rule != ValidationRule.Warning).length === 0;
    }

    contractActive(): boolean {
        return this.contract !== null;
    }

    canCopy(): boolean {
        return this.contractActive() && !this.copying && !this.versionLocked() && !this.contractIsNew();
    }

    canEditMultiple() {
        return this.selectedContracts.length > 0 && !this.versionLocked();
    }

    canAdd() {
        return this.propertyVersion !== null && !this.versionLocked() && !this.contractIsNew();
    }

    canDelete(): boolean {
        return ((this.contractActive() && !this.contractIsNew()) || this.selectedContracts.length > 0) && !this.versionLocked();
    }

    canUndo() {
        return this.contractActive() && !this.versionLocked();
    }

    canSave() {
        return this.contractActive() && this.contractValid() && !this.versionLocked();
    }

    canGet(): boolean {
        return this.propertyVersion !== null && !this.gettingContract && !this.versionLocked();
    }

    editMultiple() {
        this.editMultipleDialogVisible = true;
    }

    async editMultipleDialogClosed(canceled: boolean) {
        if (!canceled) {
            await this.updateContractList();
            await this.updateVersionData();
            this.selectedContracts = [];

            if (this.contract) {
                this.selectListItem(this.contract.id);
                await this.loadContract(this.contract.id);
            }
            else {
                this.setContract(null);
            }
        }
    }

    getContracts() {
        this.getDialogVisible = true;
    }

    async getDialogClosed(canceled: boolean, selected: any) {
        if (!canceled && this.propertyVersion) {
            const request = <CopyContractRequest>{ contractIds: selected.contracts, propertyVersionId: this.propertyVersion.id };
            try {
                this.gettingContract = true;
                const newContractId = await dataService.copyContract(request).then(x => x.data);
                await this.updateContractList();
                await this.updateVersionData();
                if (newContractId !== 0) {
                    this.selectListItem(newContractId);
                    await this.loadContract(newContractId);
                }
            } catch (error) {
                return Promise.reject(new Error(""));
            } finally {
                this.gettingContract = false;
            }
        }
    }

    contractTableFormat(row: any, column: any, cellValue: any) {
        switch (column.property) {
            case "mainPremisesTypeId":
                return this.translateBaseData(cellValue, this.baseDataType.PremisesTypes);
            case "typeOfPaymentId":
                return this.translateBaseData(cellValue, this.baseDataType.TypeOfPayments);
            case "startDate":
            case "endDate":
            case "firstIncrement":
                return this.formatYearMonth(cellValue);
            case "paymentFrequency":
                return 12 / cellValue;
            case "indexPercentage":
                return this.formatPercentage(cellValue, 0, true);
            case "area":
            case "startAmount":
                return this.formatNumber(cellValue, 0);
            case "baseNumber":
                return this.formatNumber(cellValue, 2);
            case "toCalcEnd":
            case "inAdvance":
            case "hasAdditions":
                return cellValue === true ? this.formatBoolean(cellValue) : "";
        }
    }

    // Returns contract table current scroll position
    // TODO: move to table helper module
    getScrollPosition() {
        const tbCollection = this.contractTable.$el.getElementsByClassName("el-table__body-wrapper");
        if (tbCollection.length > 0) {
            const tb = tbCollection[0];
            return { x: tb.scrollLeft || 0, y: tb.scrollTop || 0 };
        }
        return { x: 0, y: 0 };
    }

    // Scrolls contract table to previous position if possible
    // TODO: move to table helper module
    scrollToPrevPosition() {
        const tbCollection = this.contractTable.$el.getElementsByClassName("el-table__body-wrapper");
        if (tbCollection.length > 0) {
            const tb = tbCollection[0];
            const ps = this.prevScrollPosition;
            let x = 0;
            let y = 0;

            if (tb.scrollWidth >= ps.x) {
                x = ps.x;
            } else if (tb.scrollWidth > 0 && tb.scrollWidth < ps.x) {
                x = tb.scrollWidth;
            }
            if (tb.scrollHeight >= ps.y) {
                y = ps.y;
            } else if (tb.scrollHeight > 0 && tb.scrollHeight < ps.y) {
                y = tb.scrollHeight;
            }
            tb.scrollTo(x, y);
        }
    }

    async updateVersionData() {
        await this.$store.dispatch("loadVersionPremisesAreas", this.propertyVersion!.id);
        await this.$store.dispatch("loadVersionLeaseArea", this.propertyVersion!.id);
        await this.$store.dispatch("sendBusMessage", <BusMessage<ReloadCostincomeListMessage>>{ type: "ReloadCostincomeListMessage", data: {} });
    }

    contractRowFormat(contractRow: {row: ContractListItem, ix: number}): string {
        if (!this.propertySearchRequest || !contractRow || !contractRow.row) 
            return "";

        const tenantsList = this.propertySearchRequest.tenants ? this.propertySearchRequest.tenants.split(",").map(x => x.trim()) : [];

        if ((!this.propertySearchRequest.premisesTypes || this.propertySearchRequest.premisesTypes.length === 0) && 
            !this.propertySearchRequest.fromArea && !this.propertySearchRequest.toArea && tenantsList.length === 0)
                return "";

        const tenant = contractRow.row.tenant.trim().toLowerCase();
        if ((!this.propertySearchRequest.premisesTypes || this.propertySearchRequest.premisesTypes.length === 0 || this.propertySearchRequest.premisesTypes!.some(x => x === contractRow.row.mainPremisesTypeId)) &&
            (!this.propertySearchRequest.fromArea || this.propertySearchRequest.fromArea <= contractRow.row.area) &&
            (!this.propertySearchRequest.toArea || this.propertySearchRequest.toArea >= contractRow.row.area) &&
            (tenantsList.length === 0 || tenantsList.some(x => tenant.includes(x)))) {
            return "search-match";
        }

        return "";
    }

    contractSearchTextInput() :void {
        if (!this.filtering) {
            clearTimeout(this.filterTimerHandle);
        }
        this.filterTimerHandle = setTimeout(() => {
            this.filtering = true;
            this.filterContractTable();
            this.filtering = false;
        }, 500);
    }

    filterContractTable() 
    {
        const filterText = this.contractSearchText.toLowerCase()
        for (const row of this.contractTableRows) {
            const id = row.cells[1].innerText.toLowerCase()
            const tenant = row.cells[2].innerText.toLowerCase()
            if (id.indexOf(filterText) > -1 || tenant.indexOf(filterText) > -1) {
                row.style.display = ''
            } else {
                row.style.display = 'none'
            }
        }
    }

    openEditMultipleContractsDialog() {
        (<any> this).$refs.contractBulkEditDialog.open();
    }
}