import BaseComponent from "@/components/base-component";
import DataService from "@/services/data-service";
import InlineEdit from "@/helpers/inline-edit";
import { Component, Prop, Watch } from "vue-property-decorator";
import { AxiosPromise } from "axios";
import { PrognosisParameter } from "@/models/PrognosisParameter";
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import { BaseDataType } from '@/constants/fia-constants';

@Component({
    components: {
        YearMonthPickerComponent,
        InputNumericComponent
    }
})
export default class PrognosisParameterComponent extends BaseComponent {
    prognosisParametersGrid: InlineEdit<PrognosisParameter>;

    @Prop()
    reload!: boolean;
    @Prop()
    readOnly!: boolean;

    @Watch("reload")
    reloadWatcher() {
        if (this.reload == true) {
            (<any> this).$refs.prognosisParametersTable.doLayout();
        }
    }

    constructor() {
        super();
        this.prognosisParametersGrid = new InlineEdit<PrognosisParameter>(this, "prognosisParametersTable", this.loadPrognosisParameters, this.savePrognosisParameter, this.deletePrognosisParameter, this.newPrognosisParameter, undefined, this.hasNoErrors);
        this.prognosisParametersGrid.onItemChanged = this.itemChanged;
        this.prognosisParametersGrid.readOnly = this.readOnly;
    }

    mounted() {
        this.prognosisParametersGrid.reload();
    }

    loadPrognosisParameters(): AxiosPromise<PrognosisParameter[]> {
        return DataService.getPrognosisParameters();
    }

    savePrognosisParameter(entity: PrognosisParameter): AxiosPromise<PrognosisParameter> {
        return DataService.savePrognosisParameter(entity);
    }

    deletePrognosisParameter(id: number): AxiosPromise<PrognosisParameter> {
        return DataService.deletePrognosisParameter(id);
    }

    newPrognosisParameter(): PrognosisParameter {
        return <PrognosisParameter> { 
            id: 0, 
            name: "", 
            inactive: false, 
            languageId: this.$store.getters.getUser.languageId 
        };
    }
    
    itemChanged() {
        this.$store.dispatch('updateCache', BaseDataType.PrognosisParameters);
    }
}