import BaseComponent from "@/components/base-component";
import DataService from "@/services/data-service";
import InlineEdit from "@/helpers/inline-edit";
import { Component, Watch, Prop } from "vue-property-decorator";
import { AxiosPromise } from "axios";
import { PropertyTaxType } from "@/models/PropertyTaxType";
import { PropertyTaxRate } from "@/models/PropertyTaxRate";
import YearMonthPickerComponent from "@/components/common/year-month-picker/year-month-picker";
import InputNumericComponent from "@/components/common/input-numeric/input-numeric";

@Component({
    components: {
        YearMonthPickerComponent: YearMonthPickerComponent,
        InputNumericComponent: InputNumericComponent
    }
})
export default class PropertyTaxBaseDataComponent extends BaseComponent {
    taxTypesGrid: InlineEdit<PropertyTaxType>;
    taxRatesGrid: InlineEdit<PropertyTaxRate>;
    selectedType: number = 1;

    @Prop()
    reload!: boolean;
    @Prop()
    readOnly!: boolean;

    @Watch("reload")
    reloadWatcher() {
        if (this.reload == true) {
            (<any> this).$refs.taxTypesTable.doLayout();
            (<any> this).$refs.taxRatesTable.doLayout();
        }
    }

    @Watch("taxTypesGrid.currentItem") currentItemPropertyTaxWatcher() {
        if (this.taxRatesGrid)
            this.taxRatesGrid.reload();
    }

    constructor() {
        super();
        this.taxTypesGrid = new InlineEdit<PropertyTaxType>(this, "taxTypesTable", this.loadTaxTypes, this.saveTaxType, this.deleteTaxType, this.newTaxType, undefined, this.hasNoErrors);
        this.taxTypesGrid.readOnly = this.readOnly;
        this.taxRatesGrid = new InlineEdit<PropertyTaxRate>(this, "taxRatesTable", this.loadTaxRates, this.saveTaxRate, this.deleteTaxRate, this.newTaxRate, undefined, this.hasNoErrors);
        this.taxRatesGrid.readOnly = this.readOnly;
    }

    mounted() {
        this.taxTypesGrid.reload();
        this.taxRatesGrid.reload();
    }

    loadTaxTypes(): AxiosPromise<PropertyTaxType[]> {
        return DataService.getPropertyTaxTypes();
    }

    saveTaxType(entity: PropertyTaxType): AxiosPromise<PropertyTaxType> {
        return DataService.savePropertyTaxType(entity);
    }

    deleteTaxType(id: number): AxiosPromise<PropertyTaxType> {
        return DataService.deletePropertyTaxType(id);
    }

    newTaxType(): PropertyTaxType {
        return <PropertyTaxType> { 
            id: 0, 
            name: "",
            residential: false, 
            inactive: false, 
            languageId: this.$store.getters.getUser.languageId 
        };
    }

    currentPropertyTaxTypeId(): number | null {
        return (this.taxTypesGrid && this.taxTypesGrid.currentItem) ? this.taxTypesGrid.currentItem.entity.id : null;
    }


    loadTaxRates(): AxiosPromise<PropertyTaxRate[]> {
        return DataService.getPropertyTaxRatesByType(this.currentPropertyTaxTypeId());
    }

    saveTaxRate(entity: PropertyTaxRate): AxiosPromise<PropertyTaxRate> {
        return DataService.savePropertyTaxRate(entity);
    }

    deleteTaxRate(id: number): AxiosPromise<PropertyTaxRate> {
        return DataService.deletePropertyTaxRate(id);
    }

    newTaxRate(): PropertyTaxRate {
        return <PropertyTaxRate> {
            id: 0,
            rate: 0,
            startTime: this.newIsoDateMonth(),
            propertyTaxTypeId: this.currentPropertyTaxTypeId()
        };
    }


    addNew() {
        switch (this.selectedType) {
            case 1:
                this.taxTypesGrid!.add();
                break;
            case 2:
                this.taxRatesGrid!.add();
                break;
        }
    }
}