import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { ValidationError } from "@/models/interfaces/ValidationError";

@Component({})
export default class InputTextComponent extends Vue {

    @Prop()
    value!: string;
    @Prop({ default: () => [], required: false })
    complexError!: ValidationError[];
    @Prop()
    focused!: boolean;
    @Prop()
    disabled!: boolean;
    @Prop({default: 'text'})
    type!: 'text' | 'textarea';
    @Prop({default: 3})
    rows!: number;
    @Prop({ default: '' })
    placeholder!: string;
    @Prop()
    onFocus!: (e: FocusEvent) => void;
    @Prop()
    onBlur!: (e: FocusEvent) => void;

    innerValue: any = "";
    timerActive: boolean = false;

    mounted() {
        this.innerValue = this.value;

        if (this.focused) {
            this.$nextTick(() => (<any>this.$refs.elinput).$el.children[0].focus());
        }
    }

    @Watch("value")
    valueWatcher() {
        this.innerValue = this.value;
    }

    input(data: any) {
        this.innerValue = data;

        if (this.timerActive === false) {
            setTimeout(() => {
                this.$emit("input", this.innerValue);
                this.timerActive = false;
            }, 300);

            this.timerActive = true;
        }
    }

    change(event: any) {
        this.innerValue = event.target.value;
        this.$emit("input", this.innerValue);
        this.$emit("change", this.innerValue);
    }

    errorText(): string {
        return this.hasErrors() ? this.complexError.map(x => "- " + x.error).join('\r\n') : "";
    }

    hasErrors(): boolean {
        return this.complexError.length > 0;
    }

    focusEvent($event: FocusEvent) {
        (<any>$event.target).select(); // ??
        this.$emit('focus', $event);
    }

    blurEvent($event: FocusEvent) {
        if (this.onBlur) {
            this.onBlur($event);
        }
    }
}
