import { Component, Prop, Watch } from "vue-property-decorator";
import { mapGetters } from 'vuex'
import { Currency } from "@/models";
import BaseComponent from '@/components/base-component';
import Vue from 'vue';
import { TreeGridPlugin, TreeGridComponent, Sort, Aggregate, TreeGrid, Toolbar, Resize, AggregateColumn, Column } from '@syncfusion/ej2-vue-treegrid';
import { BaseDataType, BaseDataStatus } from '@/constants/fia-constants';
import { Area } from '@/models/interfaces/web-report/Area';
import { isNumeric } from "@/helpers/numbers";
import { CurrencyHelper } from "@/helpers/currency";
import moment from "moment";

Vue.use(TreeGridPlugin);
TreeGrid.Inject(Resize, Toolbar, Sort, Aggregate);

export enum CurrencyUnit {
    Unit, Thousands, Sqm, PerSqm, Percentage
}

@Component({
    components: {

    },
    computed: mapGetters({})
})
export default class AreasComponent extends BaseComponent {
    
    loading: boolean = false;
    currencyUnit = CurrencyUnit;
    languageId: number = 0;

    @Prop()
    areasData!: Area[];
    @Prop()
    currency!: Currency;
    @Prop()
    currencyRate!: number;
    @Prop({default: true})
    isVersion!: boolean;

    async refresh() {
        let grid = (<TreeGridComponent> this.$refs.areasGrid);
        if (grid) {
            grid.refresh();
        } else {
            console.warn('$refs.areasGrid is undefined');
        }
    }

    mounted() {
        this.languageId = this.$store.getters.getUser.languageId;
    }

    gridDataBound(gridName: any) {
        console.log('Grid Data Bound');
    }

    rowDataBound() {
        console.log('Row Data Bound');
    }

    gridToolbarClick(args: any) {
        switch (args.item.id) {
            case 'excel': (<any>this.$refs.areasGrid).excelExport(); break;
        }
        console.log('Toolbar Clicked');
    }

    summaryRow() {
        return { template : Vue.component('maxTemplate', {
            template: `<span>Max: </span>`
        })};
    }

    tableFormatter(col: Column, data: any) {
        const split = col.field.split('.');
        var field = split[split.length-1];
        var value = split.reduce((a, b) => a[b], data);

        return this.tableFormat(field, value, false);
    }

    tableFormatterAggregate(col: AggregateColumn, data: any) {
        const split = col.columnName.split('.');
        const field = split[split.length-1];
        const value = data[col.columnName].Sum;
        const formattedValue = this.tableFormat(field, value, true);
        return formattedValue ? formattedValue : 0;
    }

    tableFormat(field: string, value: any, isAggregateCol: boolean = false) {

        switch (field) {
            case "passingRent":
            case "additions":
            case "marketRent":
                value *= this.currencyRate;
                value = this.formatNumber(this.decimals(value * 0.001, 0), 0); 
                if(value == 0)
                    value = "";
                break;
            case "passingRentSqm":
            case "additionsSqm":
            case "marketRentSqm":
            case "adminSqm":
            case "operationSqm":
            case "maintenanceSqm":
            case "miscSqm":                
            case "totalSqm":
                value *= isAggregateCol ? 1 : this.currencyRate;
                value = this.formatNumber(this.decimals(value, 0), 0);  
                if(value == 0)
                    value = "";
                break;
            case "areaTotal":
            case "areaVacant":
            case "nof":
                value = this.formatNumber(this.decimals(value, 0), 0);  
                if(value == 0)
                    value = "";
                break;
            case "premisesTypeId":
                if(value == - 1)
                    value = ""//Total (" + this.formatCurrency(CurrencyUnit.Thousands) +")";
                else
                    value = this.translateBaseData(value, BaseDataType.PremisesTypes); 
                break;

            default: value = value; break;
        }

        return value;
    }



    formatCurrency(currencyUnit: CurrencyUnit) {
        return CurrencyHelper.formatCurrency(this.currency, currencyUnit);
    }

    sum(map: any) {
        return map.reduce((a: number, b: number) => a + b, 0);
    }
}
