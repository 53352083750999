import { Component, Prop, Watch } from "vue-property-decorator";
import { mapGetters } from 'vuex'
import BaseComponent from '@/components/base-component';
import Vue from 'vue';
import { TreeGrid, Page, Toolbar } from '@syncfusion/ej2-treegrid';
import { TreeGridPlugin } from '@syncfusion/ej2-vue-treegrid';
import { WebReports } from "@/models/interfaces/web-report/WebReports";
import { WebReportKPIs } from "@/models/interfaces/web-report/WebReportKPIs";
import { Currency } from "@/models";
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import { PaymentGroupEnum, TypeOfPaymentEnum } from "@/constants/fia-constants";
import { isNumeric } from "@/helpers/numbers";
import { CurrencyHelper } from "@/helpers/currency";

Vue.use(TreeGridPlugin);
TreeGrid.Inject(Page, Toolbar);

export enum CurrencyUnit {
    Unit, Thousands, Sqm, PerSqm, Percentage
}
@Component({
    components: {
        InputNumericComponent
    },
    computed: mapGetters({})
})
export default class CashflowComponent extends BaseComponent {

    @Prop()
    cashflowData!: any;
    @Prop()
    reportData!: WebReports;
    @Prop()
    kpis!: WebReportKPIs;
    @Prop({default: 1})
    currencyRate!: number;
    @Prop()
    currency!: Currency | null;
    @Prop({ type: Boolean })
    computedRows!: boolean;
    
    loading: boolean = false;
    marketValueInput = 0;
    cashflowDataComputed: any = null;

    @Watch('reportData')
    reportDataWatcher() {
        this.setInitialMarketValue();
        this.refresh();
    }
    
    mounted() {
        this.setInitialMarketValue();
        this.refresh();
    }

    refresh() {
        const taxRecovery = this.sumByPaymentTypes(PaymentGroupEnum.Rents, [TypeOfPaymentEnum.TaxRecovery]);
        const tax = this.sumByPaymentTypes(PaymentGroupEnum.Costs, [TypeOfPaymentEnum.PropertyTaxIndustrial, TypeOfPaymentEnum.PropertyTaxFacilities]);
        const taxDistribution = this.createComputedRow(this.translate('TaxWithdrawal'), (row, col) => {
            row[col] = -parseFloat(((taxRecovery[col] / tax[col]) * 100).toFixed(2)) * 0.01;
        });
            
        this.cashflowDataComputed = [
            taxDistribution
        ].filter(x => Object.values(x).some(v => isNumeric(v)));
    }

    createComputedRow(name: string, action: (row: any, col: any) => void) {
        const row : any = {};
        for (const col of this.cashflowData.columns) {
            action(row, col);
        }
        row['name'] = name;
        return row;
    }

    sumByPaymentTypes(paymentGroup: PaymentGroupEnum, typeOfPaymentIds: number[]) {
        const result: any = {};
        const group = this.cashflowData.rows.filter((x:any) => x.partId == paymentGroup);
        if (group.length == 0) {
            return result;
        }
        group[0].subRows
            .filter((x:any) => typeOfPaymentIds.includes(x.typeOfPaymentId))
            .forEach((x:any) => {
                Object.keys(x).forEach(key => {
                    result[key] = !result[key] ? x[key] : (result[key] + x[key]);
                })
            });
        return result;
    }

    setInitialMarketValue() {
        if (this.reportData && this.reportData.calculationResult) {
            this.marketValueInput = this.round(this.reportData.calculationResult.presentValueSum);
        }
        const area = this.reportData.areas;
    }

    rowDataBound(args: any) {
        if(/*!args.data.subRows &&*/ args.data.index !== 0 && !args.data.parentItem){
            args.row.classList.add("bold");
        }
    }

    gridToolbarClick(args: any) {
        switch (args.item.cmd) {
            case 'excel': (<any>this.$refs.cashflowGrid).excelExport(); break;
            case 'expand': (<any> this.$refs.cashflowGrid).ej2Instances.expandAll(); break;
            case 'collapse': (<any> this.$refs.cashflowGrid).ej2Instances.collapseAll(); break;
        }
    }

    tableFormatNumber(field: any, row: any, col: any) {
        if (row.index === 0) {
            // Inflation row
            return row[field] ? this.formatPercentage(row[field], 1): '';
        } else if(field ==="firstYear"){
            return row[field] ? this.formatNumber(this.decimals(row[field], 0), 0): '';
        } else if(row.name && row.name.includes("%")){
            return row[field] ? this.formatPercentage(row[field].toFixed(3), 1): '';
        }
        row[field] *= this.currencyRate;
        return row[field] ? this.formatNumber(this.decimals(row[field] * 0.001, 0), 0): '';
    }

    computedTableFormatter(field: any, row: any, col: any) {
        return row[field] ? this.formatPercentage(row[field], 1) : '';
    }

    formatCurrency(currencyUnit: CurrencyUnit) {
        return CurrencyHelper.formatCurrency(this.currency, currencyUnit);
    }

    round(num: number) {
        return Math.round(num * 0.001);
    }

    getYieldYear1() {
        const marketValue = 1000 * this.marketValueInput;
        const noi = this.reportData.kpis.yieldYear1 / ((12 - new Date(this.reportData.calculationValue.startDate).getMonth()) / 12.0);
        const result = 100 * noi / marketValue;
        return result;
    }

    getInitialYield() {
        const marketValue = 1000 * this.marketValueInput;
        const noi = this.reportData.kpis.calInitialNet;
        const result = 100 * noi / marketValue;
        return result;0
    }

    getInitialYieldNoDiscounts() {
        const marketValue = 1000 * this.marketValueInput;
        const noi = this.reportData.kpis.calInitialNetNoDiscounts;
        const result = 100 * noi / marketValue;
        return result;0
    }

    getNormalizedYield() {
        const marketValue = 1000 * this.marketValueInput;
        const noi = this.reportData.kpis.calNetNoi;
        const inv = this.reportData.kpis.calInvestmentPresentValue;
        const adj = this.reportData.kpis.calNetRentAdjustment
        const result = 100 * noi / (marketValue+inv+adj);
        return result;
    }

    getVTB() {
        const marketValue = 1000 * this.marketValueInput;
        const tax = this.reportData.kpis.calTaxSum;
        const result = marketValue/tax;
        return result;
    }

    getGrossIncomeMultiplier() {
        const marketValue = 1000 * this.marketValueInput;
        const rent = this.reportData.kpis.calInitialRentWithDiscounts;
        const result = marketValue/rent;
        return result;
    }


    
    getMarketValueSqm() {
        let totalArea = this.reportData.calculationResult.areaTotal;
        totalArea = totalArea ? totalArea : 0;
        return (1000 * this.marketValueInput) / totalArea;
    }
}
