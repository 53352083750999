import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { Country, County, Subcounty } from '@/models';
import { Region } from '@/models/Region';
import Vue from 'vue';
import DataService from '@/services/data-service';

export interface AreaSelection {
    countries: number[],
    regions: number[],
    counties: number[],
    subcounties: number[]
}

@Component({
    components: {},
    computed: mapGetters({})
})
export default class AreaSelectComponent extends Vue {
    @Prop()
    defaultCountryId!: number;
    
    localValue: AreaSelection = <AreaSelection>{ countries: [], regions: [], counties: [], subcounties: [] };
    countries: Country[] = [];
    regions: Region[] = []
    counties: County[] = [];
    subcounties: Subcounty[] = [];
    regionsGrouped: any = {};
    countiesGrouped: any = {};
    subcountiesGrouped: any = {};

    async mounted() {
        this.countries = await DataService.getCountriesOptimized().then(x => x.data.filter(x => !x.inActive));
        this.reset();
    }

    async onSelectCountry() {
        for (let countryId of this.localValue.countries) {
            // Load regions for selected country
            if (!this.regions.some(region => region.countryId === countryId)) {
                this.regions = await DataService.getRegionsByCountryOptimized(countryId).then(x => this.regions.concat(x.data));
            }
            // Load all counties for selected country
            if (!this.counties.some(x => x.countryId === countryId)) {
                this.counties =  await DataService.getCountiesByCountryOptimized(countryId).then(x => this.counties.concat(x.data));
            }
        };

        // Regions grouping
        const selectableRegions = this.regions.filter(x => this.localValue.countries.includes(x.countryId));
        this.regionsGrouped = this.countries.filter(x => this.localValue.countries.includes(x.id)).map(country => {
            return { countryName: country.name, countryId: country.id, regions: selectableRegions.filter(x => x.countryId === country.id) };
        });
        this.localValue.regions = this.regions.filter(x => this.localValue.regions.includes(x.id)).filter(x => this.localValue.countries.includes(x.countryId)).map(x => x.id);
        await this.onSelectRegion();
    }

    async onSelectRegion() {
        // Determine if regions should be used
        const useRegions = this.localValue.regions.length > 0;

        // Counties grouping
        const selectableCounties = this.counties.filter(x => useRegions ? this.localValue.regions.includes(x.regionId) : this.localValue.countries.includes(x.countryId));
        this.countiesGrouped = this.regions.filter(x => useRegions ? this.localValue.regions.includes(x.id) : this.localValue.countries.includes(x.countryId)).map(region => {
            return { regionName: this.countries.find(x => x.id === region.countryId)!.name + " / " + region.name, counties: selectableCounties.filter(x => x.regionId === region.id) };
        });
        this.localValue.counties = this.counties.filter(x => this.localValue.counties.includes(x.id)).filter(x => useRegions ? this.localValue.regions.includes(x.regionId) : this.localValue.countries.includes(x.countryId)).map(x => x.id);
        await this.onSelectCounty();
    }

    async onSelectCounty() {
        for (let countyId of this.localValue.counties) {
            if (!this.subcounties.some(subcounty => subcounty.countyId == countyId)) {
                await DataService.getSubcountiesByCountyOptimized(countyId).then(subcounties => this.subcounties = this.subcounties.concat(subcounties.data));
            }
        };

        // Subcounties grouping
        const selectableSubcounties = this.subcounties.filter(x => this.localValue.counties.includes(x.countyId));
        this.subcountiesGrouped = this.counties.filter(x => this.localValue.counties.includes(x.id)).map(county => {
            return { countyName: this.countries.find(x => x.id === county.countryId)!.name + " / " + this.regions.find(x => x.id === county.regionId)!.name + " / " + county.name, subcounties: selectableSubcounties.filter(x => x.countyId === county.id) };
        });
        this.localValue.subcounties = this.subcounties.filter(x => this.localValue.subcounties.includes(x.id)).filter(x => this.localValue.counties.includes(x.countyId)).map(x => x.id);
        
        this.$emit("change", this.localValue);
    }

    onSelectSubcounty() {
        this.$emit("change", this.localValue);
    }

    async toggleAllRegionsInCountry(value: boolean, arg: any) {
        for (const region of arg.regions) {
            const ix = this.localValue.regions.findIndex(x => x === region.id);
            if (ix === -1) {
                if (value) {
                    this.localValue.regions.push(region.id);
                }
            } else {
                if (!value) {
                    this.localValue.regions.splice(ix, 1);
                }
            }
        }
        await this.onSelectRegion();
    }

    async toggleAllCountiesInRegion(value: boolean, arg: any) {
        for (const county of arg.counties) {
            const ix = this.localValue.counties.findIndex(x => x === county.id);
            if (ix === -1) {
                if (value) {
                    this.localValue.counties.push(county.id);
                }
            } else {
                if (!value) {
                    this.localValue.counties.splice(ix, 1);
                }
            }
        }
        await this.onSelectCounty();
    }

    toggleAllSubcountiesInCounty(value: boolean, arg: any) {
        for (const subcounty of arg.subcounties) {
            const ix = this.localValue.subcounties.findIndex(x => x === subcounty.id);
            if (ix === -1) {
                if (value) {
                    this.localValue.subcounties.push(subcounty.id);
                }
            } else {
                if (!value) {
                    this.localValue.subcounties.splice(ix, 1);
                }
            }
        }
        this.$emit("change", this.localValue);
    }

    async reset() {
        this.localValue.countries = this.countries.find(x => x.id === this.defaultCountryId) ? [this.defaultCountryId] : [];
        this.localValue.regions = [];
        this.localValue.counties = [];
        this.localValue.subcounties = [];
        await this.onSelectCountry();
    }
}
