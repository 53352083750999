import { AxiosPromise, AxiosResponse } from 'axios';
import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import ValidationTableFeedbackComponent from '@/components/common/validation-table-feedback/validation-table-feedback';
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import IpdReportsComponent from '@/components/ipd-reports/ipd-reports';
import InlineEdit from '@/helpers/inline-edit';
import TableSelectExtension from '@/helpers/table-select-extension';
import { Portfolio, VersionInPortfolioEdit, VersionPortfolio } from '@/models';
import { PropertySearchResultItem } from '@/models/interfaces/PropertySearchResultItem';
import { PropertyVersionSlim } from '@/models/interfaces/PropertyVersionSlim';
import { AddPortfolioDetailsRequest } from '@/models/interfaces/request/AddPortfolioDetailsRequest';
import { KeyValue } from '@/models/interfaces/request/KeyValue';
import { LockPortfolioVersionsRequest } from '@/models/interfaces/request/LockPortfolioVersionsRequest';
import { UpdateVersionsInPortfolioRequest } from '@/models/interfaces/request/UpdateVersionsInPortfolioRequest';
import { ValidationError } from '@/models/interfaces/ValidationError';
import { BusMessage, ReportAddedMessage } from '@/models/messages/messages';
import dataService from '@/services/data-service';
import validationService from '@/services/validation-service';
import { mapGetters } from 'vuex';
import InputTextComponent from '@/components/common/input-text/input-text';
import { CopyPortfolioVersionsRequest } from '@/models/interfaces/request/CopyPortfolioVersionsRequest';


@Component({
    components: {
        YearMonthPickerComponent,
        InputNumericComponent,
        IpdReportsComponent,
        ValidationTableFeedbackComponent,
        InputTextComponent 
    },
    computed: mapGetters({
        selectedPortfolio: "getPortfolio"
    })
})
export default class PortfolioEditComponent extends BaseComponent {
    selectedPortfolio!: Portfolio;
    
    portfolioVersionsGrid: InlineEdit<VersionPortfolio>;
    propertyList: PropertySearchResultItem[] = [];
    versionList: PropertyVersionSlim[] = [];
    selectedProperty = <PropertySearchResultItem>{};
    selectedVersion = <PropertyVersionSlim>{};
    selectedCollection = <VersionPortfolio>{};
    selectedVersionsInPortfolio: VersionPortfolio[] = [];
    updateVersionsValue: string = "";
    searchName: string = "";
    searchIdentifier: string = "";
    loading: boolean = false;
    selectedVersions: PropertyVersionSlim[] = [];
    saving: boolean = false;
    portfolioSource: KeyValue = { key: -1, value: "" };
    locking: boolean = false;
    pasting: boolean = false;
    deleting: boolean = false;
    updateSelectedLoading: boolean = false;
    ipdDialogVisible: boolean = false;
    propertyVersionList: PropertyVersionSlim[] = [];
    select: TableSelectExtension = new TableSelectExtension(this, 'portfolioEditTable');
    selectPortfolioVersions: TableSelectExtension = new TableSelectExtension(this, 'portfolioVersionsTable');
    complexErrors: ValidationError[] = [];
    latestVersion!: PropertyVersionSlim;

    constructor() {
        super();
        this.portfolioVersionsGrid = new InlineEdit<VersionPortfolio>(this, "portfolioVersionsTable", this.loadPortfolioVersions, undefined, undefined, this.newPortfolioVersions, undefined, this.hasNoErrors);
        this.portfolioVersionsGrid.sync = true;
        this.portfolioVersionsGrid.useConfirmDeleteDialog = false;
    }

    @Prop()
    active!: string

    @Watch("active")
    activeWatcher() {
        this.refreshTable();
    }

    @Watch("selectedPortfolio", { deep: false })
    async selectedPortfolioWatcher() {
        if (!this.selectedPortfolio) return;
        await this.portfolioVersionsGrid.reload();
        this.refreshTable();
    }

    @Watch("selectedPortfolio", { deep: true })
    async selectedPortfolioDeepWatcher() {
        validationService.validatePortfolio(this.selectedPortfolio, this.complexErrors);
    }

    mounted() {
        this.$on('ipd-reports-dialog-emit', () => {
            this.ipdDialogVisible = false;
        });
    }

    refreshTable() {
        this.$nextTick(() => {
            var tbl = (<any> this).$refs.portfolioVersionsTable;
            if (tbl) {
                (<any> this).$refs.portfolioVersionsTable.doLayout();
            }
        });
    }

    async loadPortfolio(portfolioId: number) {
        try {
            const portfolio = await dataService.getPortfolio(portfolioId, true).then(x => x.data);
            await this.$store.dispatch("setPortfolio", portfolio);
        } catch (error) {
            return Promise.reject(new Error(""));
        }
    }

    selection(items: PropertyVersionSlim[], item: PropertyVersionSlim) {
        this.select.select(items, item);
        this.selectedVersions = items;
    }

    selectionPortfolio(items: any, item: InlineEdit<VersionPortfolio>) {
        this.selectPortfolioVersions.select(items, item);
        this.selectedVersionsInPortfolio = items.map((x: any) => x.entity);
    }

    async updateSelectedPortfolioVersions() {
        this.updateSelectedLoading = true;
        let request: UpdateVersionsInPortfolioRequest = {
            versions: this.selectedVersionsInPortfolio.map(x => {
                let v: VersionInPortfolioEdit = {
                    id: x.id,
                    portfolioId: x.portfolioId,
                    propertyId: x.viewPropertyId,
                    propertyVersionId: x.propertyVersionId
                }
                return v;
            }),
            newVersionIdentifier: this.updateVersionsValue,
            portfolioId: this.selectedPortfolio!.id
        };

        try {
            await dataService.updateVersionsInPortfolio(request).then(async () => {
                await this.loadPortfolio(this.selectedPortfolio!.id);
                this.updateSelectedLoading = false;
            });
        }
        catch {
            this.updateSelectedLoading = false;
        }
    }

    loadPortfolioVersions(): AxiosPromise<VersionPortfolio[]> {
        return Promise.resolve(<AxiosResponse>{ data: this.selectedPortfolio!.propertyVersionPortfolios });
    }

    newPortfolioVersions() {
        return <VersionPortfolio>{};
    }

    async getVersions(propertyId: number) {
        try {
            this.propertyVersionList = await dataService.getVersionsByProperty(propertyId).then(x => x.data);
        } catch (error) {
            return Promise.reject(new Error(""));
        }
    }

    async editActive(row: any) {
        await this.getVersions(row.entity.viewPropertyId);
        this.portfolioVersionsGrid.edit(row);
        this.portfolioVersionsGrid.itemSelected(row);
    }

    async searchProperty(name: string, identifier: string) {
        try {
            this.propertyList = await dataService.searchProperties({name, identifier, page: 0, maxRows: 0}).then(x => x.data.properties);
        } catch (error) {
            return Promise.reject(new Error(""));
        }
    }

    async selectProperty(property: PropertySearchResultItem) {
        this.selectedProperty = property;
        this.selectedVersions = [];

        try {
            this.versionList = await dataService.getVersionsByProperty(property.id).then(x => x.data);
        } catch (error) {
            return Promise.reject(new Error(""));
        }
    }

    versionChanged(versionId: number) {
        if (!this.portfolioVersionsGrid.currentItem) return;
        let version = this.propertyVersionList.find(x => x.id === versionId);

        if (version)
            this.portfolioVersionsGrid.currentItem.entity.viewVersionIdentifier = version.versionIdentifier;
    }

    selectVersion(version: PropertyVersionSlim) {
        this.selectedVersion = version;
    }

    addVersions() {
        let versionsToAdd = this.selectedVersions.filter(x => this.selectedPortfolio!.propertyVersionPortfolios.findIndex(y => y.propertyVersionId === x.id) === -1);
        for (let version of versionsToAdd) {
            this.selectedPortfolio!.propertyVersionPortfolios.push(<VersionPortfolio>{
                id: this.getNewId(this.selectedPortfolio!.propertyVersionPortfolios),
                portfolioId: this.selectedPortfolio!.id,
                propertyVersionId: version.id,
                share: 1,
                viewPropertyId: version.propertyId,
                viewPropertyName: version.propertyName,
                viewPropertyIdentifier: version.propertyIdentifier,
                viewVersionIdentifier: version.versionIdentifier,
                viewPortfolioName: "",
                viewCountyName: "",
                viewLocked: version.locked,
                edit: false
            });
        }

        this.portfolioVersionsGrid.reload();
    }

    async addLatestVersion() {
        try {
            this.latestVersion = await dataService.getLatestPropertyVersionId(this.selectedProperty.id).then(x => x.data);
        } catch {
            this.$notify.error({
                title: this.translate('Error'),
                message: this.translate('NoLatestPropertyVersionAvailable')
            });
        } finally {
            if (this.latestVersion) {
                this.selectedPortfolio!.propertyVersionPortfolios.push(<VersionPortfolio>{
                    id: this.getNewId(this.selectedPortfolio!.propertyVersionPortfolios),
                    portfolioId: this.selectedPortfolio!.id,
                    propertyVersionId: this.latestVersion.id,
                    share: 1,
                    viewPropertyId: this.latestVersion.propertyId,
                    viewPropertyName: this.latestVersion.propertyName,
                    viewPropertyIdentifier: this.latestVersion.propertyIdentifier,
                    viewVersionIdentifier: this.latestVersion.versionIdentifier,
                    viewPortfolioName: "",
                    viewCountyName: "",
                    viewLocked: this.latestVersion.locked,
                    edit: false
                });
            }
            this.portfolioVersionsGrid.reload();
        }
    }

    async save() {
        if (!this.selectedPortfolio) return;

        this.saving = true;
        try {
            const portfolio = await dataService.savePortfolio(this.selectedPortfolio).then(x => x.data);
            await this.$store.dispatch("setPortfolio", portfolio);
        } catch (error) {
            return Promise.reject(new Error(""));
        } finally {
            this.saving = false;
        }
    }

    async undo() {
        if (this.portfolioIsNew()) {
            await this.$store.dispatch("setPortfolio", null);
            return;
        }

        try {
            await this.loadPortfolio(this.selectedPortfolio!.id);
        } catch (error) {
            return Promise.reject(new Error(""));
        }
    }

    remove() {
        this.confirm("ConfirmRemoveItem", "Confirm", "Ok", "Avbryt", "warning", [this.selectedPortfolio!.name]).then(async () => {
            try {
                this.deleting = true;
                await dataService.deletePortfolio(this.selectedPortfolio!.id);
                await this.$store.dispatch("setPortfolio", null);
            } catch (error) {
                return Promise.reject(new Error(""));
            } finally {
                this.deleting = false;
            }
        }).catch(() => {});
    }

    async addPortfolio() {
        const portfolio = {
            id: 0,
            name: "",
            propertyVersionPortfolios: [],
            estimatedMarketValue: null,
            calculationValue: null,
            calculationResult: null
        };

        await this.$store.dispatch("setPortfolio", portfolio);
        this.$nextTick(() => (<any>this.$refs.portfolioNameInput).$el.children[0].focus());
    }

    lockVersions() {
        let lock = this.lockEnabled();
        this.confirm(lock ? "ConfirmLockPortfolioVersions" : "ConfirmUnlockPortfolioVersions").then(async () => {
            try {
                this.locking = true;
                let request: LockPortfolioVersionsRequest = { portfolioId: this.selectedPortfolio!.id, unlock: !lock }; 
                await dataService.lockPortfolioVersions(request);
                await this.loadPortfolio(this.selectedPortfolio!.id)
            } catch (error) {
                return Promise.reject(new Error(""));
            } finally {
                this.locking = false;
            }
        }).catch(() => {});
    }

    copyVersions() {
        this.portfolioSource = { key: this.selectedPortfolio!.id, value: this.selectedPortfolio!.name };
        this.$message({
            message: this.translate("PortfolioVersionsCopyPrepared"),
            type: 'info'
        });
    }

    pasteVersions() {
        this.confirm("ConfirmPastePortfolioVersions", "Confirm", "Yes", "No", "warning", [this.portfolioSource!.value, this.selectedPortfolio!.name]).then(async () => {
            let request: CopyPortfolioVersionsRequest = {
                sourcePortfolioId: <number>this.portfolioSource!.key,
                targetPortfolioId: this.selectedPortfolio!.id
            };

            try {
                this.pasting = true;
                await dataService.copyPortfolioVersions(request);
                await this.loadPortfolio(this.selectedPortfolio!.id);
            } catch (error) {
                return Promise.reject(new Error(""));
            } finally {
                this.pasting = false;
            }
        }).catch(() => {});
    }

    async exportExcel() {
        let user = this.$store.getters.getUser;
        let request: AddPortfolioDetailsRequest = {
            languageId: user.languageId,
            identityId: this.selectedPortfolio!.id.toString(),
            show: true
        };

        try {
            await dataService.addPortfolioDetailsReport(request);
            await this.$store.dispatch("sendBusMessage", <BusMessage<ReportAddedMessage>>{ type: "ReportAdded" });
        } catch (error) {
            return Promise.reject(new Error(""));
        }
    }

    exportIPD() {
        this.ipdDialogVisible = true;
    }

    portfolioSelected(): boolean {
        return this.selectedPortfolio !== null;
    }
    
    propertySelected(): boolean {
        return this.selectedProperty.id !== undefined;
    }

    portfolioIsNew(): boolean {
        return this.portfolioSelected() && this.selectedPortfolio!.id < 1;
    }

    canLockVersions(): boolean {
        return this.portfolioSelected() && this.selectedPortfolio.propertyVersionPortfolios.length > 0 && !this.portfolioIsNew() && !this.locking;
    }

    canCopyVersions(): boolean {
        return this.portfolioSelected() && !this.portfolioIsNew() && !this.pasting;
    }

    canPasteVersions(): boolean {
        return this.portfolioSelected() && !this.portfolioIsNew() && this.portfolioSource.key != -1 && !this.pasting && this.selectedPortfolio!.id !== this.portfolioSource.key;
    }

    canExportExcel(): boolean {
        return this.portfolioSelected() && !this.portfolioIsNew();
    }

    canExportIPD(): boolean {
        return this.portfolioSelected() && !this.portfolioIsNew();
    }

    canAddPortfolio(): boolean {
        return !this.portfolioIsNew();
    }

    canUndo(): boolean {
        return this.portfolioSelected();
    }

    canSave(): boolean {
        if (this.complexErrors.length > 0) 
            return false;
        return this.portfolioSelected();
    }

    canRemove(): boolean {
        return this.portfolioSelected() && !this.portfolioIsNew();
    }

    canAddVersion(): boolean {
        return this.propertySelected();
    }

    canAddVersions(): boolean {
        return this.portfolioSelected() && this.selectedVersions.length > 0;
    }

    canUpdateVersions(): boolean {
        return !this.portfolioIsNew() && this.selectedVersionsInPortfolio.length > 0 && this.updateVersionsValue.length > 0;
    }

    getLockVersionsTitle(): string {
        return this.translate(this.lockEnabled() ? "LockVersions" : "UnlockVersions");
    }

    lockEnabled(): boolean {
        return this.selectedPortfolio !== null && this.selectedPortfolio.propertyVersionPortfolios.findIndex(x => x.viewLocked) === -1;
    }
}