import { AxiosPromise, AxiosResponse } from 'axios';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import BaseComponent from '@/components/base-component';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import InputTextComponent from '@/components/common/input-text/input-text';
import ValidationErrorComponent from '@/components/common/validation-error/validation-error';
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import { paymentFrequencies, TypeOfPaymentGroupEnum } from '@/constants/fia-constants';
import InlineEdit from '@/helpers/inline-edit';
import {
    DevelopmentType, PaymentGroup, PrognosisParameter, PropertyTax, PropertyTaxType,
    PropertyVersion, Tax, TaxValuationUnit, TypeOfPayment
} from '@/models';
import { ValidationError } from '@/models/interfaces/ValidationError';
import DataService from '@/services/data-service';
import validationService from '@/services/validation-service';

@Component({
    components: {
        YearMonthPickerComponent,
        InputNumericComponent,
        InputTextComponent,
        ValidationErrorComponent
    },
    computed: mapGetters({
        propertyVersion: "getPropertyVersion",
        paymentGroupList: "getPaymentGroupsNormal",
        taxTypeList: "getPropertyTaxTypes",
        developmentTypeList: "getDevelopmentTypes",
        prognosisParameterList: "getPrognosisParameters",
        paymentTypesList: "getTypeOfPayments"
    })
})
export default class PropertyTaxComponent extends BaseComponent {
    propertyVersion!: PropertyVersion;
    paymentGroupList!: PaymentGroup[];
    taxTypeList!: PropertyTaxType[];
    developmentTypeList!: DevelopmentType[];
    prognosisParameterList!: PrognosisParameter[];
    paymentTypesList!: TypeOfPayment[];

    taxesGrid: InlineEdit<Tax>;
    taxValuationsGrid: InlineEdit<TaxValuationUnit>;
    propertyTaxesGrid: InlineEdit<PropertyTax>;

    complexErrors: ValidationError[] = [];
    activeTypeOfPayments: TypeOfPayment[] = [];
    activePropertyTaxTypes: PropertyTaxType[] = [];
    activeDevelopmentTypes: DevelopmentType[] = [];
    activePrognosisParameters: PrognosisParameter[] = [];
    paymentGroupId: number | null = null;
    frequencyList = paymentFrequencies;
    frequency: number = 4;
    inAdvance: boolean = false;
    saving: boolean = false;

    currentPropertyVersion: number = -1;

    @Prop()
    reload!: boolean;

    @Watch("reload")
    reloadWatcher() {
        if (this.reload == true) {
            (<any> this).$refs.taxesTable.doLayout();
            (<any> this).$refs.taxValuationsTable.doLayout();
            (<any> this).$refs.propertyTaxesTable.doLayout();
            if (this.currentPropertyVersion != this.propertyVersion!.id) {
                this.taxesGrid.readOnly = this.versionLocked();
                this.taxValuationsGrid.readOnly = this.versionLocked();
                this.propertyTaxesGrid.readOnly = this.versionLocked();
                this.taxesGrid.reload();
                this.currentPropertyVersion = this.propertyVersion!.id;
            }
        }
    }

    @Watch("propertyVersion")
    propertyVersionWatcher() {
        if (!this.propertyVersion) return;
        if (this.currentPropertyVersion == this.propertyVersion!.id) return;
        if (this.reload == true) {
            this.taxesGrid.readOnly = this.versionLocked();
            this.taxValuationsGrid.readOnly = this.versionLocked();
            this.propertyTaxesGrid.readOnly = this.versionLocked();
            this.taxesGrid.reload();
            this.currentPropertyVersion = this.propertyVersion!.id;
        }
    }

    @Watch("taxesGrid.currentItem") currentTaxWatcher() {
        this.taxValuationsGrid.reload().then(() => {
            if (this.taxValuationsGrid.entities.length > 0) {
                this.frequency = this.taxValuationsGrid.entities[0].paymentFrequency;
                this.inAdvance = this.taxValuationsGrid.entities[0].inAdvance;
            }
        });
    }

    @Watch("taxValuationsGrid.currentItem") currentTaxValuationWatcher() {
        this.propertyTaxesGrid.reload();
    }

    @Watch("taxesGrid.entities", { deep: true })
    taxesWatcher() {
        validationService.validateTaxes(this.taxesGrid.entities, this.propertyVersion, this.complexErrors);
    }

    @Watch("complexErrors")
    errorsWatcher() {
        this.$emit("isValid", this.complexErrors.length === 0)
    }


    constructor() {
        super();
        this.taxesGrid = new InlineEdit<Tax>(this, "taxesTable", this.loadTaxes, undefined, undefined, this.newTax, this.editTax, this.hasNoErrors);
        this.taxValuationsGrid = new InlineEdit<TaxValuationUnit>(this, "taxValuationsTable", this.loadTaxValuations, undefined, undefined, this.newTaxValuation, this.editValuationUnit, this.hasNoErrors);
        this.propertyTaxesGrid = new InlineEdit<PropertyTax>(this, "propertyTaxesTable", this.loadPropertyTaxes, undefined, undefined, this.newPropertyTax, undefined, this.hasNoErrors);
        this.taxesGrid.sync = true;
        this.taxValuationsGrid.sync = true;
        this.propertyTaxesGrid.sync = true;
        this.taxesGrid.useConfirmDeleteDialog = false;
        this.taxValuationsGrid.useConfirmDeleteDialog = false;
        this.propertyTaxesGrid.useConfirmDeleteDialog = false;
    }
    mounted() {
        // this.taxesGrid.reload();
    }

    updateUnitItems() {
        for (let tv of this.taxValuationsGrid.entities) {
            tv.paymentFrequency = this.frequency;
            tv.inAdvance = this.inAdvance;
        }
    }

    paymentGroupChanged() {
        this.filterTypeOfPayments();
        if (this.activeTypeOfPayments.length > 0)
            this.taxesGrid.currentItem!.entity.typeOfPaymentId = this.activeTypeOfPayments[0].id;
    }

    filterTypeOfPayments() {
        if (this.taxesGrid.currentItem) {
            this.activeTypeOfPayments = this.paymentTypesList.filter(x => (!x.inactive || x.id === this.taxesGrid.currentItem!.entity.typeOfPaymentId) &&
                x.paymentGroupId === this.paymentGroupId);
            this.activeTypeOfPayments = this.activeTypeOfPayments.filter(x => x.typeOfPaymentGroupIds == null
                || x.typeOfPaymentGroupIds.some(t => t === TypeOfPaymentGroupEnum.Taxes) 
                || x.id == this.taxesGrid.currentItem!.entity.typeOfPaymentId);
        }
    }

    getPaymentGroupName(typeOfPaymentId: number): string {
        let paymentType = this.paymentTypesList.find(x => x.id === typeOfPaymentId);
        if (!paymentType) return "";
        let paymentGroup = this.paymentGroupList.find(x => x.id === paymentType!.paymentGroupId);
        return paymentGroup ? paymentGroup.name : "";
    }

    getPaymentGroupId(typeOfPaymentId: number): number | null {
        let paymentType = this.paymentTypesList.find(x => x.id === typeOfPaymentId);
        if (!paymentType) return null;
        return paymentType.paymentGroupId;
    }

    editTax(tax: Tax) {
        this.paymentGroupId = this.getPaymentGroupId(tax.typeOfPaymentId);
        this.filterTypeOfPayments();
    }

    editValuationUnit(taxValuationUnit: TaxValuationUnit) {
        this.activePropertyTaxTypes = this.taxTypeList.filter(x => (!x.inactive || x.id === taxValuationUnit.propertyTaxTypeId));
        this.activeDevelopmentTypes = this.developmentTypeList.filter(x => (!x.inactive || x.id === taxValuationUnit.developmentTypeId));
        this.activePrognosisParameters = this.prognosisParameterList.filter(x => (!x.inactive || x.id === taxValuationUnit.prognosisParameterId));

        this.activeDevelopmentTypes.push(<any>{ id: null, name: "" })
        this.activePrognosisParameters.push(<any>{ id: null, name: "" })
    }

    loadTaxes(): AxiosPromise<Tax[]> {
        return DataService.getTaxes(this.propertyVersion.id);
    }

    getTaxValuationsGridCurrentItemErrors(entityId: number): ValidationError[] {
        const taxesGridCurrentItem = this.taxesGrid.currentItem;
        if (taxesGridCurrentItem == null) {
            return [];
        }
        const refId = taxesGridCurrentItem.entity.id + "|" + 
                        entityId;
        return this.getError(this.complexErrors, ['tax2','tax3','tax4'], refId);
    }

    getTaxValuationsGridValuationUnitErrors(valuationEntityId: number): ValidationError[] {
        const taxesGridCurrentItem = this.taxesGrid.currentItem;
        if (taxesGridCurrentItem == null) {
            return [];
        }
        const refId = taxesGridCurrentItem.entity.id + "|" + 
                        valuationEntityId;
        return this.getError(this.complexErrors, 'valuationUnit', refId);
    }

    getPropertyTaxesGridBaseTaxErrors(baseTaxEntityId: number): ValidationError[] {
        const taxesGridCurrentItem          = this.taxesGrid.currentItem;
        const taxValuationsGridCurrentItem  = this.taxValuationsGrid.currentItem;
        if (taxesGridCurrentItem == null ||
            taxValuationsGridCurrentItem == null) {
            return [];
        }
        const refId = taxesGridCurrentItem.entity.id + '|' + 
                        taxValuationsGridCurrentItem.entity.id + '|' + 
                        baseTaxEntityId;
        return this.getError(this.complexErrors, 'baseTax', refId);
    }
    getPropertyTaxesGridConversionFactorErrors(conversionFactorEntityId: number): ValidationError[] {
        const taxesGridCurrentItem          = this.taxesGrid.currentItem;
        const taxValuationsGridCurrentItem  = this.taxValuationsGrid.currentItem;
        if (taxesGridCurrentItem == null || 
            taxValuationsGridCurrentItem == null) {
            return [];
        }
        const refId = taxesGridCurrentItem.entity.id + '|' + 
                        taxValuationsGridCurrentItem.entity.id + '|' + 
                        conversionFactorEntityId;
        return this.getError(this.complexErrors, 'conversionFactor', refId);
    }

    newTax() {
        let payments = this.paymentTypesList.filter(x => x.typeOfPaymentGroupIds.some(t => t === TypeOfPaymentGroupEnum.Taxes));
        let tp = payments.length > 0 ? payments[payments.length-1] : this.paymentTypesList.find(x => !x.inactive);
        return <Tax>{
            id: this.getNewId(this.taxesGrid.entities),
            propertyVersionId: this.propertyVersion.id,
            comment: "",
            typeOfPaymentId: tp!.id,
            taxValuationUnits: []
        }
    }

    loadTaxValuations(): AxiosPromise<TaxValuationUnit[]> {
        return Promise.resolve(<AxiosResponse>
            { data: 
                (this.taxesGrid.currentItem && 
                    this.taxesGrid.currentItem!.entity.taxValuationUnits) 
                ? this.taxesGrid.currentItem!.entity.taxValuationUnits 
                : [] 
        });
    }

    newTaxValuation() {
        let firstType = this.taxTypeList.find(x => !x.inactive);
        return <TaxValuationUnit>{
            id: this.getNewId(this.taxValuationsGrid.entities),
            valuationUnit: 0,
            propertyTaxTypeId: firstType!.id,
            developmentTypeId: null,
            prognosisParameterId: null,
            paymentFrequency: this.frequency,
            inAdvance: this.inAdvance,
            calculationType: false,
            propertyTaxes: []
        }
    }

    loadPropertyTaxes(): AxiosPromise<PropertyTax[]> {
        return Promise.resolve(<AxiosResponse>{
            data: 
                (this.taxValuationsGrid.currentItem && this.taxValuationsGrid.currentItem!.entity.propertyTaxes) 
                ? this.taxValuationsGrid.currentItem!.entity.propertyTaxes 
                : []
        });
    }

    newPropertyTax(): PropertyTax {
        return <PropertyTax>{
            id: this.getNewId(this.propertyTaxesGrid.entities),
            startDate: this.propertyTaxesGrid.entities.length === 0 ? this.propertyVersion.calculationStart : this.newIsoDateMonth(),
            baseTax: 0,
            conversionFactor: 0
        }
    }

    taxValid(): boolean {
        return this.complexErrors.length === 0;
    }

    save() {
        this.saving = true;
        DataService.saveTaxes(this.propertyVersion.id, this.taxesGrid.entities).then(x => {
            this.taxesGrid.reload(x.data); 
        }).catch(() => {
           return;
        }).finally(() => {
            this.saving = false;
        });
    }

    importFromDetails() {
        DataService.getTaxesByPropertyDetails(this.propertyVersion.property.id, this.propertyVersion.id).then(x => {
            x.data.forEach( t => {
                t.id = this.getNewId(this.taxesGrid.entities);
                this.taxesGrid.entities.push(t);
            });
            this.taxesGrid.reload(this.taxesGrid.entities); 
        }).catch(err => {
            console.error(err);
            this.$notify.error({
                title: this.translate('Error'),
                message: this.translate('ErrorActionFailed')
            });
           return;
        }).finally(() => {
            this.saving = false;
        });
    }

    parameterChanged() {
        this.taxValuationsGrid.currentItem!.entity.developmentTypeId = null;
    }

    developmentChanged() {
        this.taxValuationsGrid.currentItem!.entity.prognosisParameterId = null;
    }

    canSave(): boolean {
        return this.complexErrors.length === 0 && !this.versionLocked() && !this.taxesGrid.loadError;
    }

    canUndo(): boolean {
        return !this.versionLocked() && !this.taxesGrid.loadError;
    }

    canAddTaxRate(): boolean {
        return this.taxValuationsGrid.currentItem !== null && !this.versionLocked() && !this.taxesGrid.loadError;
    }

    canAddUnit(): boolean {
        return this.taxesGrid.currentItem !== null && !this.versionLocked() && !this.taxesGrid.loadError;
    }

    canAddTax(): boolean {
        return !this.versionLocked() && !this.taxesGrid.loadError;
    }
}