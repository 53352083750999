import { Component } from "vue-property-decorator";
import BaseComponent from "@/components/base-component";
import { Database } from "@/models/Database";
import dataService from "@/services/data-service";
import { PortfolioSearchResult } from "@/models/interfaces/PortfolioSearchResult";
import { Job } from "@/models/Job";
import { AppRightEnum } from '@/constants/fia-constants';
import { CopyPortfolioRequest } from '@/models/interfaces/request/CopyPortfolioRequest';
import { JobAddedMessage, BusMessage } from '@/models/messages/messages';
import { Portfolio } from "@/models";
import PortfolioSelectComponent from "../portfoliosearch/portfolio-select";

@Component({
    components: {
        PortfolioSelectComponent
    }
})
export default class PortfolioCopyComponent extends BaseComponent {
    portfolioList: PortfolioSearchResult[] = [];
    databaseList: Database[] = [];
    jobList: Job[] = [];
    selectedPortfolio: PortfolioSearchResult | null = null;
    selectedDatabaseId: number = -1;
    portfolioName: string = "";
    portfolioVersion: string = "";

    mounted() {
        this.databaseList = this.$store.getters.getDatabasesByAppRight([AppRightEnum.ImportExport_CopyPortfolioTo]);
        let userDatabase = this.$store.getters.getDatabases.find((x: Database) => x.id === this.$store.getters.getUser.databaseId);
        if (!this.databaseList.map(x => x.id).includes(userDatabase.id))
            this.databaseList.push(userDatabase);
        this.selectedDatabaseId = userDatabase.id;
    }

    copy() {
        this.confirm("ConfirmCopyPortfolio", "Confirm", "Ok", "Avbryt", "warning", [this.selectedPortfolio!.name]).then(async () => {
            let request: CopyPortfolioRequest = {
                name: this.portfolioName,
                versionIdentifier: this.portfolioVersion,
                portfolioId: this.selectedPortfolio!.id,
                targetDatabaseId: this.selectedDatabaseId
            }

            try {
                await dataService.copyPortfolio(request);
                await this.$store.dispatch("sendBusMessage", <BusMessage<JobAddedMessage>>{ type: "JobAdded" });
                this.$message({
                    message: this.translate('PortfolioCopyAddedMessage'),
                    type: 'success'
                });
            } catch (error) {
                return Promise.reject(new Error(""));
            }
        }).catch(() => {});
    }

    disableCopy(): boolean {
        if(!(<any>this).errors.any() && (this.selectedPortfolio != null) && (this.portfolioName.length > 0) && (this.portfolioVersion.length > 0))
            return false;
        return true;
    }

    onPortfolioSelected(portfolio: Portfolio) {
        this.selectedPortfolio = portfolio;
    }
}
