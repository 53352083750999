import BaseComponent from "@/components/base-component";
import { PropertyDetail, PropertyDetailsExtraField, PropertyDetailsExtraFieldType } from "@/models";
import { types } from "util";
import { Component, Prop } from "vue-property-decorator";
import { mapGetters } from 'vuex';
import InputTextComponent from '@/components/common/input-text/input-text';

@Component({
    components: {
        InputTextComponent
    },
    computed: mapGetters({
        busMessage: "getBusMessage"
    })
})
export default class PropertyDetailsExtraFieldComponent extends BaseComponent {

    @Prop({ default: null })
    extraFieldTypes!: PropertyDetailsExtraFieldType[];
    @Prop({ default: null })
    propertyDetails!: PropertyDetail;

    expandedPanels: number[] = []

    mounted() {
        this.extraFieldTypes.forEach((e, i) => {
            if (e.guiExpanded) {
                this.expandedPanels.push(e.id);
            }
        })
    }

    onTextInput(fieldTypeId: number, value: string) {
        let field = this.propertyDetails.propertyDetailsExtraFields.find(x => x.propertyDetailsExtraFieldTypeId == fieldTypeId);

        if (!field) {
            field = {
                id: 0,
                propertyDetailsId: this.propertyDetails.id,
                propertyDetailsExtraFieldTypeId: fieldTypeId,
                text: ''
            }

            this.propertyDetails.propertyDetailsExtraFields.push(field!);
        }

        field.text = value;
    }

    getValue(fieldTypeId: number) {
        const textField = this.propertyDetails.propertyDetailsExtraFields.find(x => x.propertyDetailsExtraFieldTypeId == fieldTypeId);
        return !textField ? '' : textField.text;
    }
}