import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
import { SelectItem } from "@/models/interfaces/SelectItem";
import { ValidationError } from "@/models/interfaces/ValidationError";
import { DateFormat, YearMonthFormat } from '@/constants/fia-constants';

@Component({})
export default class YearMonthPickerComponent extends Vue {
    @Prop()
    value!: string;
    @Prop({ default: false, type: Boolean, required: false })
    monthEnd!: boolean;
    @Prop()
    label!: string;
    @Prop({ default: 1980, type: Number, required: false })
    startYear!: number;
    @Prop({ default: 50, type: Number, required: false })
    futureYears!: number;
    @Prop({ default: false, type: Boolean, required: false })
    nullable!: boolean;
    @Prop({ default: false, type: Boolean, required: false })
    disabled!: boolean;

    @Prop()
    name!: string;

    @Prop({ default: () => [], required: false })
    complexError!: ValidationError[];

    years: SelectItem<number>[] = [];
    months: SelectItem<number>[] = [];
    selectedYear: number = -1;
    selectedMonth: number = -1;
    focusCount: number = 0;

    @Watch("value") dateWatcher(val: string) {
        this.setValue(val);
    }

    created() {
        if (this.nullable) {
            this.years.push({ id: -1, name: " " });
            this.months.push({ id: -1, name: " " });
        }

        let endYear = new Date().getFullYear() + this.futureYears;

        for (let year = this.startYear; year < endYear; year++) {
            this.years.push({ id: year, name: year.toString() });
        }

        for (let month = 1; month <= 12; month++) {
            this.months.push({ id: month, name: month.toString() });
        }

        this.setValue(this.value);
    }

    setValue(val: string) {
        let date = new Date(val);
        let validDate = this.isDate(date);
        this.selectedYear = validDate ? date.getFullYear() : -1;
        this.selectedMonth = validDate ? date.getMonth() + 1 : -1;
    }

    change(monthChange: boolean) {
        if (this.selectedYear !== -1 && this.selectedMonth === -1 && !monthChange) {
            this.selectedMonth = 1;
        }

        if (this.selectedYear === -1 || this.selectedMonth === -1) {
            this.selectedYear = -1;
            this.selectedMonth = -1;
            this.$emit("input", "");
            this.$emit("change", "");
        }
        else {
            let yearMonth = this.selectedYear.toString() + "-" + (this.selectedMonth < 10 ? "0" : "") + this.selectedMonth.toString();
            let daysInMonth = moment(yearMonth, YearMonthFormat).daysInMonth();
            let day = this.monthEnd ? daysInMonth : 1;
            let date = new Date(this.selectedYear, this.selectedMonth - 1, day);
            let newDate = moment(date).format(DateFormat);
            this.$emit("input", newDate);
            this.$emit("change", newDate);
        }
    }

    isDate(date: any): boolean {
        return date instanceof Date && !isNaN(date.valueOf());
    }

    errorText(): string {
        return this.hasErrors() ? this.complexError.map(x => "- " + x.error).join('\r\n') : "";
    }

    hasErrors(): boolean {
        return this.complexError.length > 0;
    }

    yearEnterPressed() {
        let yearSelect = (<any>this.$refs["year"]);
        let monthSelect = (<any>this.$refs["month"]);
        let year = yearSelect.query;
        if (this.years.findIndex(x => x.name === year.toString()) !== -1) {
            yearSelect.blur();
            this.selectedYear = parseInt(year, 10);
            this.change(false);
            monthSelect.focus();
        }
    }

    monthEnterPressed() {
        let monthSelect = (<any>this.$refs["month"]);
        let month = monthSelect.query;
        if (this.months.findIndex(x => x.name === month.toString()) !== -1) {
            // monthSelect.blur();
            this.selectedMonth = parseInt(month, 10);
            this.change(true);
        }
    }

}
