import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({})
export default class DeleteBtnTplComponent extends Vue {

  removeInternal() {
    // Workaround to get the primary key row id
    (this as any).remove((this as any)._data.data.id);
  }

}