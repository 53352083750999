import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import dataService from '@/services/data-service';
import { UpdateCostIncomesFirstIncrementRequest } from '@/models/interfaces/request/UpdateCostIncomesFirstIncrementRequest';
import { CostIncomeListItem } from '@/models/CostIncomeListItem';

enum Actions {
    FirstIncrement
}

@Component({
    components: {
        YearMonthPickerComponent
    }
})
export default class CostIncomeUpdateSelectedComponent extends BaseComponent {
    @Prop()
    open!: boolean;

    @Prop()
    costIncomes!: CostIncomeListItem[];

    visible: boolean = false;
    action: number = Actions.FirstIncrement;
    actions = Actions;
    firstIncrement: string = "";
    loading: boolean = false;

    @Watch("open")
    openWatcher() {
        if (this.open) {
            // Set defaults
            this.action = Actions.FirstIncrement;
            let f = this.costIncomes.map(x => x.firstIncrement != null ? x.firstIncrement : new Date().toDateString());
            this.firstIncrement = f.reduce((pre, cur) => Date.parse(pre) > Date.parse(cur) ? cur : pre);

            this.visible = true;
        }
    }

    cancel() {
        this.close(true);
    }

    close(canceled: boolean = false) {
        this.$emit("update:open", false);
        this.$emit("closed", canceled);
    }

    ok() {
        this.confirm('ConfirmUpdateSelectedCostIncomes').then(async () => {
            let request;
            try {
                this.loading = true;
                switch (this.action) {
                    case this.actions.FirstIncrement:
                        request = <UpdateCostIncomesFirstIncrementRequest>{ costIncomeIds: this.costIncomes.map(x => x.id), date: this.firstIncrement }
                        await dataService.updateCostIncomesFirstIncrement(request);
                        break;
                }
                this.close();
                this.loading = false;
            } catch (error) {
                this.loading = false;
                return Promise.reject(new Error(""));
            }
        }).catch(() => {
            return;
        });
    }

    valid() : boolean {
        switch (this.action) {
            case this.actions.FirstIncrement:
                return this.firstIncrement !== "";
        }

        return false;
    }
}