import "element-ui/lib/theme-chalk/index.css";
import 'element-ui/lib/theme-chalk/display.css';
import Vue from 'vue';
import AppComponent from './components/app/app';
import store from './store/store';
import VeeValidate from 'vee-validate';
import { Validator } from 'vee-validate';
import vuexI18n, { Translations } from 'vuex-i18n';
import { transEn } from './lang/en';
import { transSv } from './lang/sv';
import dataService from "./services/data-service";
import { User } from '@/models';
import { BaseDataType, LanguageEnum } from '@/constants/fia-constants';
import ElementUI from "element-ui";
import { router } from './router';
import ElSelectExtended from './components/common/el-select-extended/el-select-extended';
import signalrPlugin from './plugins/signalr-plugin';
import { L10n, loadCldr, setCulture } from '@syncfusion/ej2-base';
import { sv } from "./lang/syncfusion-sv";
import "@/filters";
// @ts-ignore
import localeEn from "element-ui/lib/locale/lang/en";
// @ts-ignore
import localeSv from "element-ui/lib/locale/lang/sv-SE";
import { MsalPlugin, MsalPluginOptions, msalPluginInstance } from "./plugins/msal-plugin";
import { LogLevel } from "@azure/msal-browser";
import { registerLicense } from '@syncfusion/ej2-base';
import paymentflowService from "./services/paymentflow-service";

loadCldr(
  require("cldr-data/main/sv/numbers.json"),
  require("cldr-data/main/sv/ca-gregorian.json"),
  require("cldr-data/supplemental/numberingSystems.json"),
  require("cldr-data/main/sv/timeZoneNames.json"),
  require('cldr-data/supplemental/weekData.json') // To load the culture based first day of week
);

// Vue.config.performance = true;
Vue.config.productionTip = false;

// Config for VeeValidate
const config = { fieldsBagName: 'vee-fields' };
const veeSv = require('vee-validate/dist/locale/sv');
const veeEn = require('vee-validate/dist/locale/en');

Vue.use(vuexI18n.plugin, store);
Vue.use(VeeValidate, config);

// Add localization files for vuexI18n plugin 
Vue.i18n.add('en', transEn);
Vue.i18n.add('sv', transSv);

// Add element UI localization files for vuexI18n plugin 
Vue.i18n.add('en', localeEn);
Vue.i18n.add('sv', localeSv);

const options: MsalPluginOptions = {
  clientId: process.env.VUE_APP_AD_CLIENT_ID || "",
  authority: "https://login.microsoftonline.com/" + process.env.VUE_APP_AD_TENANT || "",
  scopes: [process.env.VUE_APP_AD_SCOPE || ""],
  logLevel: LogLevel.Error
}

Vue.use(new MsalPlugin(), options)

// Wait for the redirect callback promise to resolve to determine if the user is logged in
// or needs to be redirected to the login page.
msalPluginInstance.handeRedirectPromise().then(authResult => {
  if (authResult || msalPluginInstance.isAuthenticated()) {
    startApp();
  } else {
    msalPluginInstance.signIn();
  }
}).catch(_ => {
  msalPluginInstance.signIn();
})

// Start the Vue app when the user info and cached base data has successfully been loaded
function startApp() {
  dataService.initialize();
  Vue.use(signalrPlugin);
  store.dispatch('loadUser').then(_ => {
  const user: User = store.getters.getUser;
    registerLicense(user.syncfusionKey);
    setLocalization(user.languageId);
    store.dispatch('updateCache', BaseDataType.All).then(_ => {
      new Vue({
        router,
        store,
        render: (h: any) => h(AppComponent),
      }).$mount('#app');
      
      paymentflowService.initialize();
    });
  }).catch(_ => {
    alert("Login failed, please check your permissions. You will now be redirected to the logout page.")
    msalPluginInstance.signOut();
  });
}

// Set localization (depending on the users settings)
function setLocalization(language: LanguageEnum) {
  let locale: Translations;
  switch (language) {
    case LanguageEnum.English:
      locale = localeEn;
      Vue.i18n.set('en');
      Validator.localize('en', veeEn);
      break;
    case LanguageEnum.Swedish:
    default:
      locale = localeSv;
      Vue.i18n.set('sv');
      Validator.localize('sv', veeSv);
      setCulture('sv');
      L10n.load(sv);
      break;
  }

  Vue.use(ElementUI, { locale });
  Vue.component('ElSelect', ElSelectExtended)
}