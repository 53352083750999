import BaseComponent from '@/components/base-component';
import { Prop, Component, Watch } from 'vue-property-decorator';
import ReportSettingsComponent from '@/components/calculation/report-settings/report-settings';
import { CalculationReportSettings } from '@/models/interfaces/CalculationState';
import DialogComponent from '@/components/common/dialog/dialog';
import { mapGetters } from 'vuex';
import { User } from '@/models';
import { AddMultipleVersionsCalculationRequest } from '@/models/interfaces/request/AddMultipleVersionsCalculationRequest ';
import { PropertyVersionSlim } from '@/models/interfaces/PropertyVersionSlim';
import dataService from '@/services/data-service';
import { BusMessage, CalcAddedMessage } from '@/models/messages/messages';
import { CalculationRequestReportSettings } from '@/models/interfaces/request/CalculationRequestReportSettings';
import { Customer } from '@/constants/fia-constants';

@Component({
    components: {
        ReportSettingsComponent,
        DialogComponent,
    },
    computed:
        mapGetters({
            user: "getUser",
        })
})
export default class ReportSettingsDialogComponent extends BaseComponent {

    @Prop() 
    isOpen!: boolean;
    @Prop()
    selectedVersions!: PropertyVersionSlim[];

    user!: User;
    calculationState: CalculationReportSettings = <CalculationReportSettings> {};

    @Watch("selectedVersions", { deep: true })
    selectedVersionsWatcher() {
        if (this.calculationState.currencyId == 0 && this.selectedVersions.length > 0)
            this.calculationState.currencyId = this.selectedVersions[0].currencyId;
    }

    mounted() {
        this.calculationState = {
            extensionStreamsAverageIncrement: this.monthlyIncrementEnabled(),
            rptCashFlow: true,
            rptRents: true,
            rptRentAdjColumn: false,
            rptRentTermProlColumn: false,
            rptGreenDeal: false,
            rptAreaAmount: this.user.customerId == 1?false:true,
            rptInternalComments: false,
            rptRentRebates: false,
            rptArea: false,
            rptDetailedStreams: false,
            rptInvestments: false,
            marketValue: null,
            transactionCosts: null,
            languageId: this.user ? this.user.languageId : 0,
            reportTemplateId: 0,
            currencyId: 0
        };
    }

    monthlyIncrementEnabled() {
        return true;
    }
    
    showMonthlyIncrement() {
        return this.user.customerId == Customer.CWFinland;
    }

    isAnyVersionLocked() {
        var result = this.selectedVersions.find(p => p.locked ) == undefined;
        return !result;
    }

    getLockedVersions() {
        return this.selectedVersions.filter(p => p.locked);
    }

    getLockedVersionIdentifiers() {
        var lockedVersions = this.getLockedVersions();
        var lockedVersionStrings: string[] = [];
        lockedVersions.forEach(p =>  lockedVersionStrings.push(p.propertyName + ", " + p.versionIdentifier));
        return lockedVersionStrings;
    }

    hasLockedVersions() {
        return this.getLockedVersions().length > 0;
    }
    
    cancel() {
        this.close();
    }

    close() {
        this.$emit("update:isOpen", false);
        this.$emit("closed");
    }

    calculateEnabled() {
        return !this.isAnyVersionLocked();
    }

    async calculate() {
        this.close();

        const state = this.calculationState;
        const settings: CalculationRequestReportSettings = {
            cashFlowReport: state.rptCashFlow,
            rentsReport: state.rptRents,
            rentsReportAdjColumn: state.rptRentAdjColumn,
            rentsReportGreenDealColumn: state.rptGreenDeal,
            rentsReportAreaAmountColumn: state.rptAreaAmount,
            rentsReportTerminationProlongingColumn: state.rptRentTermProlColumn,
            rentsReportInternalComments: state.rptInternalComments,
            rentRebatesReport: state.rptRentRebates,
            areaReport: state.rptArea,
            detailedStreamsReport: state.rptDetailedStreams,
            investmentReport: state.rptInvestments,
            languageId: state.languageId!,
            currencyId: state.currencyId ? state.currencyId : this.selectedVersions[0].currencyId,
            reportTemplateId: state.reportTemplateId,
            marketValue: state.marketValue ? state.marketValue : 0,
            transactionCosts: state.transactionCosts ? state.transactionCosts : 0,
        };

        const request: AddMultipleVersionsCalculationRequest = {
            ...settings,
            versionIds: this.selectedVersions.map(p => p.id),
            alwaysRecalculate: true,
            useSyncFusion: true,
            colorful: this.user!.userSettings.reportSettings.colorful,
            extensionStreamsAverageIncrement: state.extensionStreamsAverageIncrement,
            useWebJob: false,
            generateReport: true
        };

        let user = this.user!;
        try {
            if (this.$store.getters.getUserSettingsHasChanged) {
                user.userSettings = await dataService.saveUserSettings(user.userSettings).then(x => x.data);
            }
            await dataService.addMultiplePortfolioCalculations(request);
            await this.$store.dispatch("sendBusMessage", <BusMessage<CalcAddedMessage>>{ type: "CalcAdded" });
        } catch (error) {
            return Promise.reject(new Error("calculate"));
        } finally {
            state.rptInternalComments = false;
        }
    }
}